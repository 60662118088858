import { Grid } from "@mui/material";
import { InternalBanner } from "../common";
import Config from "../../config/app.config.json";
import { isTemplateExisits } from "../../utils/commonFunctions";
import styles from "./S1_Help.module.scss";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
type helpIframeProps = {
  strapiContent: any;
}
const Help = (props: helpIframeProps) => {
  const { strapiContent } = props;
  const { iframe_id, iframe_link, iframe_ttl } = strapiContent;
  return (
    <>
      {isTemplateExisits(Config.Help.Banner) && <InternalBanner
        pageName={"Help"}
        apiEndPoint={"helpIframeBanner"}
      />}
      <Grid
        container
        height={"100vh"}
        className={styles.help_page}
      >
        <iframe
          src={iframe_link}
          title={iframe_ttl}
          id={BrandNameUpdate(iframe_id)}
          height={"inherit"}
          width={"100%"}
        ></iframe>
      </Grid>
    </>
  )
}

export default Help
