import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust, LinearProgressBarCust } from "../../../widgets";
import Config from "../../../../config/env.config.json";
import styles from "./S2_ActivationStep4.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ActivationFailedModal } from "./ActivationFailedModal";
import { HelpSupportModal } from "../ActivationStep2/SupportModal";
import ActivationIsMnp from "./ActivationIsMnp";

const ActivationStep4 = (props: any) => {
  const {
    handleStep,
    currentLine,
    content,
    modalContent,
    showModal,
    openSupportDialog,
    activateBtnLd,
    showActionIsMnp,
    redirection,
    activateHandler,
    onCloseSupportDialog,
    openSupportModal,
    closeActivationFailModal,
    setPaymentDetails,
  } = props;

  const { step4, step_txt, sp4_title, sp4_main_title, c_sup_title } = content;
  const { isMNP, mnpInfo } = currentLine || {};
  const { oldZip, oldPassword, oldNumber, acctNum } = mnpInfo || {};
  const simID = localStorage.getItem("simId");
  const DataItem = (
    imgName: any,
    altName: string,
    labelText: string,
    labelVal: any
  ) => {
    return (
      <Box sx={{ width: "50%" }}>
        <Box
          className={styles.device_details}
          sx={{ m: { xs: " 10px 10px 10px 0px", sm: "15px 10px" } }}
        >
          <Box
            sx={{
              width: "23px",
              height: "23px",
              mr: "1rem",
              display: "flex",
              alignSelf: "center",
            }}
            component="img"
            src={imgName}
          />{" "}
          <Box>
            <Typography className={styles.sub_title}>{labelText}</Typography>
            <Typography className={styles.sub_description}>
              {labelVal}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  const backArrowFunction = () => {
    setPaymentDetails(3);
    handleStep(false);
  };
  return (
    <>
      <Box display="flex" alignItems="center" pt={{ xs: "25px", sm: "50px" }}>
        <ArrowBackIcon
          sx={{
            ml: { md: "30px", sm: "25px", xs: "4%" },
            mr: "20px",
            cursor: "pointer",
          }}
          className={styles.arrow_icon}
          onClick={() => (!showActionIsMnp ? backArrowFunction() : null)}
        />
        <Typography variant="h3" fontSize="30px !important">
          {sp4_main_title}
        </Typography>
      </Box>
      {showActionIsMnp ? (
        <ActivationIsMnp
          {...props}
          redirection={redirection}
          step4Content={content}
        />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
            }}
            pb={{ xs: "25px", sm: "50px" }}
          >
            <Box
              sx={{
                flexBasis: { md: "50%" },
                display: "flex",
                justifyContent: { xs: "center", lg: "start" },
                p: { md: "72px 0px 50px 100px", xs: "50px 20px 0px 20px" },
              }}
            >
              <Box>
                <Box
                  component="img"
                  sx={{ width: { xs: "100%", sm: "478px" } }}
                  src={content?.act_img?.data?.attributes?.url}
                ></Box>
              </Box>
            </Box>
            <Box
              sx={{
                flexBasis: { md: "50%" },
                p: {
                  md: "16px 0 0 96px",
                  sm: "30px 0 0 110px",
                  xs: " 30px 35px",
                },
              }}
            >
              <Typography
                className={styles.title}
                sx={{
                  fontSize: { xs: "22px", sm: "30px" },
                  m: " 20px 0 15px !important",
                  textAlign: { sm: "left", xs: "center" },
                }}
              >
                {sp4_title}
              </Typography>
              <Typography
                className={styles.description}
                sx={{
                  fontSize: { xs: "18px" },
                  textAlign: { sm: "left", xs: "center" },
                }}
              >
                {step_txt}
              </Typography>
              <Box
                sx={{
                  width: { md: "50%", sm: "400px", xs: "100%" },
                  m: "20px 0px 25px",
                }}
              >
                <LinearProgressBarCust value={100} variant={"determinate"} />
              </Box>

              <Typography mb="10px" className={styles.sub_heading}>
                {step4?.title}
              </Typography>
              <Typography
                className={styles.final_sub_heading}
                sx={{ width: { sm: "400px" }, pb: "20px" }}
              >
                {step4?.desc}
              </Typography>

              <Box
                className={styles.device_content}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexBasis: "50%",
                  width: { xs: "100%", sm: "75%" },
                }}
              >
                {DataItem(
                  step4?.dev_img?.data?.attributes?.url,
                  step4?.dev_img?.data?.attributes?.alternativeText,
                  step4?.dev,
                  currentLine?.model
                )}
                {DataItem(
                  step4?.devId_img?.data?.attributes?.url,
                  step4?.devId_img?.data?.attributes?.alternativeText,
                  step4?.devId,
                  currentLine?.imei
                )}
                {isMNP &&
                  DataItem(
                    step4?.cur_no_img?.data?.attributes?.url,
                    step4?.cur_no_img?.data?.attributes?.alternativeText,
                    step4?.cur_no,
                    oldNumber
                  )}
                {DataItem(
                  step4?.simId_img?.data?.attributes?.url,
                  step4?.simId_img?.data?.attributes?.alternativeText,
                  step4?.simId,
                  simID
                )}
                {isMNP &&
                  DataItem(
                    step4?.acc_num_img?.data?.attributes?.url,
                    step4?.acc_num_img?.data?.attributes?.alternativeText,
                    step4?.acc_num,
                    acctNum
                  )}

                {isMNP &&
                  DataItem(
                    step4?.pin_img?.data?.attributes?.url,
                    step4?.pin_img?.data?.attributes?.alternativeText,
                    step4?.pin,
                    oldPassword
                  )}

                {isMNP &&
                  DataItem(
                    step4?.zip_code_img?.data?.attributes?.url,
                    step4?.zip_code_img?.data?.attributes?.alternativeText,
                    step4?.zip_code,
                    oldZip
                  )}
              </Box>
              <Box
                mt="45px"
                display={"flex"}
                sx={{ justifyContent: { xs: "center", sm: "left" } }}
              >
                <ButtonCust
                  sx={{ width: "200px", mx: { xs: "auto", sm: "0px" } }}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={activateHandler}
                  loading={activateBtnLd}
                >
                  {step4?.act_btn}
                </ButtonCust>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {showModal && (
        <ActivationFailedModal
          showModal={showModal}
          openSupportModal={openSupportModal}
          closeActivationFailModal={closeActivationFailModal}
          content={content}
          currentLine={currentLine}
        />
      )}
      <HelpSupportModal
        open={openSupportDialog}
        onClose={onCloseSupportDialog}
        title={c_sup_title}
        showClose={false}
        showBackBtn={true}
        content={modalContent}
      />
    </>
  );
};

export default ActivationStep4;
