import EnvConfig from "./env.config.json";

const FirebaseDBURLs = {
  userWebJourney: EnvConfig.TENANT_ID + "/userWebJourneyData/",
  simCost: EnvConfig.TENANT_ID + "/perSimCost",
  shippingRates: EnvConfig.TENANT_ID + "/shippingRates",
  imeiCheckCoupon: EnvConfig.TENANT_ID + "/imeiCheckCoupon",
  source: EnvConfig.TENANT_ID + "/source/",
  operationStatus: EnvConfig.TENANT_ID + "/operationStatus/",
  operators: EnvConfig.TENANT_ID + "/operators/",
  profileImgUpload: EnvConfig.TENANT_ID + "/profile_pics/uploaded/",
  profileInfo: EnvConfig.TENANT_ID + "/profileInfo/",
};
export default FirebaseDBURLs;
