import { Typography, Popover } from "@mui/material";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
    content: any;
    popoverId: any;
    open: any;
    anchorEl: any;
    handleClose: any;
}
const AccountNumberPopover = (props: Props) => {
    const {
        content,
        popoverId,
        open,
        anchorEl,
        handleClose
    } = props;
  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {content?.map((item: any) => (
        <Typography
          color="var(--warm_grey)"
          fontSize="14px"
          p={1}
          id={item?.id}
        >
          {BrandNameUpdate(item?.text)}
        </Typography>
      ))}
    </Popover>
  );
}

export default AccountNumberPopover;
