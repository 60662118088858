import React from "react";
import { FormHelperText, InputBase, Paper, Box } from "@mui/material";
import { ButtonCust } from "../widgets";
import Config from "../../config/env.config.json";
import styles from "./InputBaseCust.module.scss";

interface InputBaseCustProps {
  submitHandler: any;
  submitClick: any;
  content: any;
  value: any;
  label: string;
  onChange: any;
  error: any;
  helperText: any;
}
export const InputBaseCust = (props: InputBaseCustProps) => {
  return (
    <Box>
      <Paper
        component="form"
        sx={{
          height: "55px",
          p: "2px 4px 2px 20px",
          display: "flex",
          alignItems: "center",
          width: { sm: "569px", xs: "fit-content" },
          borderRadius: "var(--input_base_border_radious)",
          boxShadow: "var(--lead_gen_input_box_shadow)"
        }}
        elevation={0}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            textAlign: "flex-start",
            fontFamily: "var(--font_family_Medium)",
          }}
          placeholder={props.content?.place_holder}
          inputProps={{ "aria-label": "Email", sx: {
            '&::placeholder': {
              fontSize: {xs:'12px', md:"16px"}
            },
          }, }}
          value={props.value}
          // label={props.label}
          onChange={props.onChange}
          error={props.error}
          // helperText={props.helperText}
        />
        {props.content?.button_text ? (
          <ButtonCust
            className={styles.lead_generate_t1}
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={(e: any) => props.submitHandler(e)}
            loading={props.submitClick}
            sx={{
              height: "45px !important",
              width: { xs: "125px", sm: "143px", lg: "187px" },
              "&:hover": {
                backgroundColor: "var(--primary_color) !important",
              },
            }}
          >
            {props.content?.button_text}
          </ButtonCust>
        ) : null}
      </Paper>
      <FormHelperText
        sx={{
          textAlign: "flex-start",
          fontFamily: "var(--font_family_Medium)",
          minHeight: "20px",
          paddingLeft: "16px",
        }}
        error={true}
      >
        {props.helperText}
      </FormHelperText>
    </Box>
  );
};

export default InputBaseCust;
