import { Grid, Typography, Box } from "@mui/material";
import { Divider } from "../../../widgets";
import { ButtonCust } from "../../../widgets/Button/ButtonCust";
import styles from "./S2_PlanCard.module.scss";
import { calculateCost, calculateUnlimitedCost, PlanProps } from "./PlanCard";
import Config from "../../../../config/env.config.json";
import unlimitedPlanDiscount from "../../../../config/discountCouponsUnlimited.json";
import normalPlanDiscount from "../../../../config/discountCouponsRegular.json";
import getSymbolFromCurrency from "currency-symbol-map";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

export const priceCalculationHandler: any = (data: any,selectedLine:any) => {
  let PlanDiscountCost, planPerLinesCost: any;
  if (data.isUnlimited) {
    const { planCost } = calculateUnlimitedCost(data, selectedLine);
    PlanDiscountCost =
      planCost -
      unlimitedPlanDiscount.data.discountInDollar -
      unlimitedPlanDiscount.data.secondaryDiscountInDollar *
      (selectedLine - 1);
  } else {
    const { planCost } = calculateCost(data, selectedLine);
    PlanDiscountCost =
      planCost -
      normalPlanDiscount.data.discountInDollar -
      normalPlanDiscount.data.secondaryDiscountInDollar * (selectedLine - 1);
  }
  planPerLinesCost = Math.ceil(PlanDiscountCost / selectedLine);
  return planPerLinesCost;
};
const T2_PlanCard = (props: PlanProps) => {
  const { plandata, selectedLine, planSelectionHandler, planContent } = props;
  let user: any = JSON.parse(localStorage.getItem("customerDetail") || "null");
  let isSimPaid = user?.simPaid;


  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-around",
        pt: "30px",
        pb: { xs: "5px", md: "30px" },
        flexWrap: "wrap",
      }}
    >
      {plandata?.length > 0 && plandata.map((data: any, index: number) => {
        const planPerLinesCost = priceCalculationHandler(data,selectedLine);
        const isCurrentPlan = user?.reachPlanId === data?.name;
        return (
          <Grid display={"flex"} justifyContent={"center"} item key={index}>
            <Grid
              className={
                isCurrentPlan ? styles.current_plan_card : styles.plan_card
              }
            >
              <Grid className={styles.plan_content}>
                {isCurrentPlan && (
                  <Typography className={styles.current_plan_tag}>
                    {planContent?.current_plan}
                  </Typography>
                )}
                <Box p="13px 13px 0">
                  <Typography className={styles.plan_header}>
                    {data.name}
                  </Typography>
                  <Box
                    className={styles.data_price_content}
                    alignItems="center"
                  >
                    {!data?.isUnlimited || !isSimPaid ? (
                      <>
                        <img
                          height={"18px"}
                          src={
                            data?.isUnlimited
                              ? planContent?.non_shareable_img?.data?.attributes
                                  ?.url
                              : planContent?.shareable_img?.data?.attributes
                                  ?.url
                          }
                          alt={
                            planContent?.non_shareable_img?.data?.attributes
                              ?.alternativeText
                          }
                        />
                        <Typography className={styles.data_pack}>
                          {data.planData}
                          {data.dataUnit}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography className={styles.unlimited_data_content}>
                          {planContent?.unl_plan_desc2}
                        </Typography>
                      </>
                    )}
                    {!isSimPaid && (
                      <Typography fontSize={"15px"} className={styles.sub_tag}>
                        -{" "}
                        {data?.isUnlimited
                          ? planContent?.per_line
                          : planContent?.shareable_text}
                      </Typography>
                    )}
                  </Box>
                  {!data.isUnlimited && isSimPaid && (
                    <Typography className={styles.acc_subText}>
                      {planContent?.shareable_text}
                    </Typography>
                  )}
                  {data.isUnlimited && (
                    <Typography
                      sx={{
                        mb: "8px !important",
                        display: "inline-block !important",
                      }}
                      className={
                        isSimPaid
                          ? styles.acc_unlimited_subText
                          : styles.unlimited_content
                      }
                    >
                      {planContent?.unl_plan_desc}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Box p="0px 13px">
                    <Divider
                      styles={{
                        m: " 10px auto 15px",
                        width: "85px !important",
                        height: "4px !important",
                        backgroundColor: "var(--dusty-orange) !important",
                      }}
                    />
                    <Box
                      className={styles.data_price_content}
                      alignItems={isSimPaid ? "flex-end" : "center"}
                    >
                      <Typography
                        className={
                          isSimPaid ? styles.acc_line_price : styles.line_price
                        }
                      >
                        {" "}
                        {getSymbolFromCurrency(data?.currency)}
                        {planPerLinesCost}
                      </Typography>
                      {isSimPaid ? (
                        <Typography className={styles.line_price_tag}>
                          {planContent?.tax_fees}
                        </Typography>
                      ) : (
                        <Typography
                          fontSize={"14px"}
                          className={styles.sub_tag}
                        >
                          {" "}
                          &nbsp;{planContent?.per_line}&nbsp;
                          {planContent?.per_month}
                        </Typography>
                      )}
                    </Box>
                    {!isSimPaid && (
                      <>
                        <Typography className={styles.plan_description}>
                          {BrandNameUpdate(planContent?.plan_desc)}
                        </Typography>
                        <Typography className={styles.additional_lines}>
                          {data?.isUnlimited
                            ? planContent?.unl_plan_desc_main
                            : `${
                                planContent?.addition_ln
                              }: ${getSymbolFromCurrency(data?.currency)}${
                                data.isUnlimited
                                  ? data.additionalLinePrice -
                                    unlimitedPlanDiscount?.data
                                      ?.secondaryDiscountInDollar
                                  : data.additionalLinePrice -
                                    normalPlanDiscount?.data
                                      ?.secondaryDiscountInDollar
                              } ${planContent?.upto} ${
                                data.maxLines - 1
                              } lines`}
                        </Typography>
                      </>
                    )}
                  </Box>
                  {!isSimPaid && (
                    <Box m="25px 0 6px" p="8px">
                      <ButtonCust
                        sx={{ width: "100%" }}
                        variantType={Config.SECONDARY_BUTTON_TYPE}
                        onClick={(e: any) => {
                          data.maxLines >= selectedLine
                            ? planSelectionHandler(data, "Select Plan")
                            : "";
                        }}
                      >
                        {planContent?.btn}
                      </ButtonCust>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            {/* } */}
          </Grid>
        );
      })}
    </Grid>
  );
};
export default T2_PlanCard;
