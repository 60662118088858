import { Box, CircularProgress, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import styles from "./S2_payNow.module.scss";
import envConfig from "../../../../config/env.config.json";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getIsCardExpired } from "../../../../utils/commonFunctions";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { ReachPlan } from "../../../../features/trackorder/trackorderSlice";
import { CardTypes } from "../../../../enums";
import PayNowPlan from "./PayNowPlan";

const ActivtionPayNow = (props: any) => {
  const {
    currentLine,
    payNowConfirm,
    payBtnLoader,
    manageCardFg,
    content,
    autoPayCard,
  } = props;
  const { monthInAdvanceData } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { reachPlan } = useAppSelector((state: any) => state.trackorder || {});
  const plan = reachPlan?.data?.data || {};
  const dispatch = useAppDispatch();
  let isCardExpired = false;
  const data: any = [
    {
      text: !currentLine.isPrimary
        ? "Additional line"
        : currentLine.isPrimary
        ? currentLine?.reachPlanDisplayName
        : "-",
      value: monthInAdvanceData?.amount ?? "-",
      id: 1,
    },
    {
      text: content?.pay_now?.discount_txt,
      value:
        monthInAdvanceData?.promoDiscount > 0
          ? monthInAdvanceData?.promoDiscount
          : null,
      id: 3,
    },
    {
      text: content?.pay_now?.fee_txt,
      value: monthInAdvanceData?.taxAmount ?? "-",
      id: 2,
    },
    {
      text: content?.pay_now?.due_txt,
      value: monthInAdvanceData?.amountToPay ?? "-",
      id: 4,
    },
  ];
  useEffect(() => {
    dispatch(
      ReachPlan({
        plan: currentLine?.reachPlanId,
      })
    );
  }, []);
  if (autoPayCard?.dateOfExpiry) {
    isCardExpired = getIsCardExpired(autoPayCard);
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
        }}
      >
        <Box
          className={styles.left_div}
          sx={{
            flexBasis: { md: "50%" },
            display: "flex",
            justifyContent: { xs: "center", lg: "start" },
            p: { md: "16px 0 16px" },
          }}
        >
          <Box sx={{ p: { md: "20px 80px", xs: "10px" } }}>
            <Box
              sx={{
                width: { xs: "100%", sm: "478px" },
                backgroundColor: "var(--pay_now_card)",
                textAlign: "center",
              }}
            >
              <Typography
                className={styles.selected_plan}
                sx={{
                  pt: { xs: "5px", sm: "34px" },
                  pb: { xs: "5px", sm: "25px" },
                  fontSize: { xs: "22px", sm: "30px" },
                }}
              >
                {content?.pay_now?.selected_txt}
              </Typography>
              <Box sx={{ mx: "32px" }}>
                <Box
                  className={styles.selected_plan_box}
                  sx={{
                    mx: "32px",
                    width: { xs: "100%", sm: "295px" },
                    maxWidth: "295px",
                  }}
                >
                  <Box
                    sx={{
                      padding: " 32px 21px 24px",
                      textAlign: "left !important",
                    }}
                  >
                    <PayNowPlan
                      currentLine={currentLine}
                      content={content}
                      plan={plan}
                    ></PayNowPlan>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={styles.right_div}
          sx={{
            flexBasis: { md: "50%" },
            p: { sm: "16px 40px 50px 56px", xs: "25px" },
          }}
        >
          <Box sx={{ textAlign: { xs: "left" }, width: { md: "80%" } }}>
            <Typography
              className={styles.title}
              sx={{ fontSize: { xs: "22px", sm: "30px" } }}
            >
              {content?.pay_now?.title}
            </Typography>
            <Typography
              className={styles.description}
              sx={{
                fontSize: { xs: "18px" },
                textAlign: { sm: "left", xs: "center" },
                fontFamily: "var(--font_family_Medium)",
                pb: "20px",
              }}
            >
              {content?.step_txt1}
            </Typography>
            <Typography
              className={styles.description}
              sx={{ fontSize: { xs: "15px", sm: "18px" } }}
            >
              {content?.pay_now?.desc}
            </Typography>
            {!monthInAdvanceData ? (
              <Box className={styles.loaderCheck} sx={{ marginBottom: "20px" }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {data.map((item: any, index: any) => {
                  return (
                    <>
                      {item?.id === 3 && item?.value <= 0 ? null : (
                        <>
                          <Box
                            className={
                              index == 3
                                ? styles.total_data_item
                                : styles.data_item
                            }
                          >
                            <Typography
                              className={styles.left_item}
                              sx={{ fontSize: { xs: "15px", sm: "18px" } }}
                            >
                              {item.text}
                            </Typography>
                            <Typography
                              className={styles.right_item}
                              sx={{ fontSize: { xs: "15px", sm: "18px" } }}
                            >
                              {item.id === 3
                                ? `-${GetCurrencyFixedToTwo(item.value)}`
                                : GetCurrencyFixedToTwo(item.value)}
                            </Typography>
                          </Box>
                          {(index == 0 || index == 1) && (
                            <Box className={styles.division}></Box>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
                <Box sx={{ mx: "20px" }}>
                  <Typography className={styles.card_desc} sx={{ py: "20px" }}>
                    {content?.pay_now?.info_txt}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      pb: "20px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{ width: "45px", height: "30px" }}
                        component="img"
                        alt={
                          content?.pay_now?.visa?.data?.attributes
                            ?.alternativeText
                        }
                        src={
                          autoPayCard?.type?.toUpperCase() === CardTypes?.VISA
                            ? content?.pay_now?.visa?.data?.attributes?.url
                            : autoPayCard?.type?.toUpperCase() ===
                              CardTypes?.MASTERCARD
                            ? content?.pay_now?.master_card?.data?.attributes
                                ?.url
                            : autoPayCard?.type?.toUpperCase() ===
                              CardTypes?.JCB
                            ? content?.pay_now?.jcb?.data?.attributes?.url
                            : autoPayCard?.type?.toUpperCase() ===
                              CardTypes?.AMERICANEXPRESS || autoPayCard?.type?.toUpperCase() ===
                              CardTypes?.AMEX
                            ? content?.pay_now?.american_ex?.data?.attributes
                                ?.url
                            : content?.pay_now?.discover?.data?.attributes?.url
                        }
                      />
                      <Typography
                        className={styles.card_num}
                        sx={{ fontSize: { xs: "13px", sm: "16px" } }}
                      >
                        {content?.pay_now?.num_mask}
                        {autoPayCard.ccNumberMask}
                      </Typography>
                    </Box>
                    <a
                      href="#"
                      className={styles.change_link}
                      onClick={manageCardFg}
                    >
                      {content?.pay_now?.chng_btn}
                    </a>
                  </Box>
                  <ButtonCust
                    sx={{ width: "100%" }}
                    variantType={envConfig.PRIMARY_BUTTON_TYPE}
                    loading={payBtnLoader}
                    disabled={isCardExpired}
                    onClick={payNowConfirm}
                  >
                    {content?.pay_now?.pay_btn}
                  </ButtonCust>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ActivtionPayNow;
