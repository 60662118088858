import { Grid, Box, Link } from "@mui/material";
import "./S1_DataUsage.scss";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { DataUsageInfoProvider } from "../../../dataUsageInfo";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import ApnNote from "../../../apnSettings/Apn_Note";

interface IDataUsage {
  content: any;
  isCardExpired?: any;
  data?: any;
}
const GAAndFBEventsHandler = (category: string, value: string) => {
  pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
    appName: EnvConfig.brand,
    screenName: gaScreenNames.signup,
    category: category,
    buttonValue: value,
  });
};
const DataUsage = ({ content, isCardExpired, data }: IDataUsage) => {
  return (
    <Grid
      display={"flex"}
      direction={"column"}
      alignItems={"center"}
      padding={"20px"}
      sx={{
        p: {
          xs: "calc(var(--mobile_section_padding)/2) 0 calc(var(--mobile_section_padding)/2)",
          sm: "calc(var(--ipad_section_padding)/2) 0 calc(var(--ipad_section_padding)/2)",
          md: "calc(var(--desktop_section_padding)/2) 0 calc(var(--desktop_section_padding)/2)",
        },
      }}
    >
      <DataUsageInfoProvider
        content={content}
        isCardExpired={isCardExpired}
        showSecondaryLinesUsage={false}
        showDataUsageForUnlimitedPack={true}
      />
      <Box mt="12px">
        <Link
          color={"var(--primary_color)"}
          underline="none"
          href={RoutingURLs.account}
          fontSize={"1rem"}
          fontWeight={"bold"}
          onClick={() =>
            GAAndFBEventsHandler(gaCategory.dataUsage, content?.view_details)
          }
        >
          {content?.view_details}
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "start" },
          marginTop: { xs: "20px" },
        }}
      >
        {EnvConfig?.IS_APN_SETTINGS_ENABLED && data?.os && (
          <ApnNote
            imeiData={{
              os: data?.os ?? "",
              osVer: data?.osVer ?? 0,
            }}
            isShowAsPill={true}
            currentLine={data}
          />
        )}
      </Box>
    </Grid>
  );
};

export default DataUsage;
