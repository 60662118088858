import Styles from "./IRUserList.module.scss";
import { Box, Typography, CircularProgress } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import Config from "../../../../config/env.config.json";

const IRUserList = (props: any) => {
  const { group, countryListHandler } = props;
  const {
    user_list_container,
    pack_active,
    sim_type,
    is_primary,
    user_name,
    user_num,
  } = Styles;
  const { user_data } = props.content;

  return (
    <>
      {group?.data?.length > 0 ? (
        group.data
          .filter((data: any) => data.status === 2)
          .sort(
            (a: any, b: any) =>
              new Date(a.createdDate).getTime() -
              new Date(b.createdDate).getTime()
          )
          .map((data: any) => {
            const fullName = `${data.firstName} ${data.lastName}`;
            return (
              <Box className={user_list_container} key={data.id}>
                <Box>
                  <Typography className={user_name} pb={1}>
                    {fullName}
                    {data.isPrimary && (
                      <Box component="span" className={is_primary}>
                        {user_data?.primary}
                      </Box>
                    )}
                    {data.isRoaming && (
                      <Box component="span" className={pack_active}>
                        {user_data?.pack_active}
                      </Box>
                    )}
                  </Typography>
                  <Typography className={user_num}>
                    {data && data.reachNumber ? (
                      <Box component="span">
                        {`(${data.reachNumber.slice(
                          0,
                          3
                        )}) ${data.reachNumber.slice(
                          3,
                          6
                        )}-${data.reachNumber.slice(6)} `}
                      </Box>
                    ) : data && data.mnpInfo?.oldNumber ? (
                      <Box component="span">
                        {`(${data.mnpInfo?.oldNumber.slice(
                          0,
                          3
                        )}) ${data.mnpInfo?.oldNumber.slice(
                          3,
                          6
                        )}-${data.mnpInfo?.oldNumber.slice(6)} `}
                      </Box>
                    ) : null}
                    <Box component="span" className={sim_type}>
                      {data.esim ? user_data?.esim : user_data?.sim}
                    </Box>
                  </Typography>
                </Box>
                <ButtonCust
                  variantType={Config.QUATERNARY_BUTTON_TYPE}
                  onClick={() => countryListHandler(data, fullName)}
                >
                  {user_data?.pur_pack}
                </ButtonCust>
              </Box>
            );
          })
      ) : (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default IRUserList;
