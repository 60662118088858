import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./PlanSection.module.scss";
import data from "../../../config/data.json";
import { sortByKey } from "../../../utils/commonFunctions/SortByKey";
import { PlanCard } from "./PlanCardsComponent";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PlanSectionContent } from "../../../features/strapi/homePageSlice";
import { LinesTab } from "./LinesTab";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import Config from "../../../config/env.config.json";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { Divider } from "../../widgets";
import { RoutingURLs } from "../../../config/RoutingURLs";
import CheckIcon from "@mui/icons-material/Check";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
  isSelected?: boolean;
};

export const PlanSection = (props: Props) => {
  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);
  let plandata =
    data && data.data && data.data.length > 0
      ? sortByKey(data && data.data, "planData")
      : [];
  const dispatch = useAppDispatch();
  const [cacheDataPlanSec, setCacheDataPlanSec] = useState<any>(null);
  const { planCardContent } = useAppSelector(
    (state: any) => state.strapi.homepage || {}
  );
  const { getCartItemsSuccess } = useAppSelector(
    (state: any) => state.shopWare
  );
  const navigate = useNavigate();
  const [selectedLine, setSelectedLine] = useState(1);

  useEffect(() => {
    getCache("PlanSecHome", PlanSectionContent, setCacheDataPlanSec, dispatch);
  }, []);

  useEffect(() => {
    if (getCartItemsSuccess?.lineItems) {
      lineUpdatefromCart(getCartItemsSuccess);
      let finalData: any = getCartItemsSuccess?.lineItems?.find(
        (data: any) => data?.payload?.planName
      );
      if (finalData) {
        let result: any = data.data.find(
          (val: any) => val.displayName === finalData.payload.planName
        );
        const plansToSet: any = {
          line: finalData.payload.quantity,
          plan: result,
        };
        localStorage.setItem(
          "reachSelectedPlanLine",
          finalData.payload.quantity
        );
        localStorage.setItem(
          "reachSelectedPlanDetail",
          JSON.stringify(plansToSet)
        );
      }
    }
  }, [getCartItemsSuccess]);

  useNonInitialEffect(() => {
    addMultipleCacheData("PlanSecHome", planCardContent);
  }, [planCardContent]);

  const lineTabChangeHandler = (val: number) => {
    setSelectedLine(val);
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.lineSelection,
      buttonValue: val,
    });
  };
  const lineUpdatefromCart = (cartData: any) => {
    let quantity = 0;
    cartData.lineItems.map((item: any) => {
      if (item.payload.planName) {
        quantity = quantity + parseInt(item?.payload?.quantity);
      }
    });
    if (quantity > 1) {
      setSelectedLine(quantity);
    }
  };
  const maxLinesArray = plandata.map((el: any) => {
    return el.maxLines;
  });
  const maxLinesCount = Math.max(...maxLinesArray);

  const {
    home_page_title,
    home_page_line,
    check_icon,
    icon_text_container,
    offer_text,
    home_page_subtitle,
  } = styles;
  const { isSelected } = props;
  let isSimPaid = details?.simPaid;
  const plansContent = planCardContent || cacheDataPlanSec;
  const postSimPurchaseLabel = plansContent?.post_heading?.replace(
    "{plan}",
    details?.reachPlanDisplayName
  );
  return plansContent && Object.keys(plansContent)?.length > 0 ? (
    <Box
      id="bestCellPhonePlan"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingY: Config.HIDE_LINE_SELECTION_IN_PURCHASE_JOURNEY
          ? { xs: "10px", sm: "20px" }
          : { xs: "50px", sm: "80px" },
        background: "var(--plan_section_bg_color)",
      }}
      className={
        location.pathname !== RoutingURLs.myAccount &&
        !Config.HIDE_LINE_SELECTION_IN_PURCHASE_JOURNEY
          ? `min_height_plan_section_${Config.DOMAIN?.toLowerCase()}`
          : ""
      }
    >
      {Config.HIDE_LINE_SELECTION_IN_PURCHASE_JOURNEY &&
      location.pathname !== RoutingURLs.myAccount ? null : (
        <Typography
          variant="h3"
          component={"h3"}
          sx={{
            borderLeft:
              planCardContent?.res?.show_vertical_title_line ||
              cacheDataPlanSec?.res?.show_vertical_title_line
                ? "6px solid var(--header_divider_color)"
                : "none",
            paddingLeft:
              planCardContent?.res?.show_vertical_title_line ||
              cacheDataPlanSec?.res?.show_vertical_title_line
                ? "20px"
                : "0px",
          }}
          className={home_page_title}
        >
          {BrandNameUpdate(
            isSimPaid
              ? postSimPurchaseLabel
              : isSelected
              ? cacheDataPlanSec
                ? cacheDataPlanSec?.selected_heading
                : planCardContent?.selected_heading
              : cacheDataPlanSec
              ? Config.HIDE_LINE_SELECTION_IN_PURCHASE_JOURNEY
                ? null
                : cacheDataPlanSec?.heading
              : Config.HIDE_LINE_SELECTION_IN_PURCHASE_JOURNEY
              ? null
              : planCardContent?.heading
          )}
        </Typography>
      )}
      <>
        {Config.IS_MYACCOUNT_OFFERS &&
        location.pathname === RoutingURLs.myAccount ? (
          <Box
            className={icon_text_container}
            m="20px 25px"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            {(cacheDataPlanSec
              ? cacheDataPlanSec?.my_acc_desc
              : planCardContent?.my_acc_desc
            )?.map((each: any, index: number) => {
              return (
                <Box display="flex" alignItems="center" pr="20px" key={index}>
                  <CheckIcon className={check_icon} />
                  <Typography className={offer_text}>{each?.item}</Typography>
                </Box>
              );
            })}
          </Box>
        ) : (Config.HIDE_LINE_SELECTION_IN_PURCHASE_JOURNEY &&
            location.pathname !== RoutingURLs.myAccount) ||
          cacheDataPlanSec?.res?.hide_horizontal_line ||
          planCardContent?.res?.hide_horizontal_line ? null : (
          <Divider styles={{ m: "20px auto 25px" }} />
        )}
      </>
      {(planCardContent?.sub_heading || cacheDataPlanSec?.sub_heading) &&
        location.pathname !== RoutingURLs.myAccount && (
          <Typography
            variant="h4"
            component={"h4"}
            className={home_page_subtitle}
            sx={{ mb: "10px" }}
          >
            {cacheDataPlanSec
              ? cacheDataPlanSec?.sub_heading
              : planCardContent?.sub_heading}
          </Typography>
        )}
      {isSimPaid ||
      maxLinesCount < 2 ||
      Config.HIDE_LINE_SELECTION_IN_PURCHASE_JOURNEY ? null : (
        <LinesTab
          value={selectedLine}
          handleChange={lineTabChangeHandler}
          totalCount={maxLinesCount}
          linesTabContent={
            cacheDataPlanSec
              ? cacheDataPlanSec?.lines_tab_component
              : planCardContent?.lines_tab_component
          }
        />
      )}
      <PlanCard
        plandata={plandata}
        navigate={navigate}
        isSimPaid={isSimPaid}
        planContent={cacheDataPlanSec ? cacheDataPlanSec : planCardContent}
        selectedLine={selectedLine}
        isSelected={isSelected}
      />
      {location.pathname === RoutingURLs.myAccount &&
      plansContent?.res?.note_desc ? (
        <Box
          sx={{
            fontSize: "14px",
            fontFamily: "var(--font_family_Medium)",
            width: { sm: "620px", xs: "100%" },
            padding: { xs: "10px" },
            textAlign: "center",
            lineHeight: 1.4,
          }}
          dangerouslySetInnerHTML={{
            __html: BrandNameUpdate(plansContent?.res?.note_desc),
          }}
        />
      ) : null}
    </Box>
  ) : null;
};
