import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchBrandsContent } from "../../../features/strapi/aboutUsSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const BrandsComponent = isTemplateExisits(Config.AboutUs.Brands)
  ? require(`./${Config.AboutUs.Brands.template}_Brands`).default
  : null;

export const Brands = () => {
  const [brandList, setBrandList] = useState<any>(null);
  const [cacheDataBrand, setCacheDataBrand] = useState<any>(null);
  const { brandsContent } = useAppSelector(
    (state: any) => state.strapi.aboutUs
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("BrandAboutUs", fetchBrandsContent, setCacheDataBrand, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("BrandAboutUs", brandsContent);
  }, [brandsContent]);
  
  return (
    <>
      {brandList || cacheDataBrand ? (
        <BrandsComponent
          data={
            cacheDataBrand
              ? cacheDataBrand.brand_images.data
              : brandList.brand_images.data
          }
          mobileBgImage={
            cacheDataBrand
              ? cacheDataBrand.mobile_newsroom_bg.data
              : brandList.mobile_newsroom_bg.data
          }
          desktopOrIPadBgImage={
            cacheDataBrand
              ? cacheDataBrand.desktop_ipad_bg.data
              : brandList.desktop_ipad_bg.data
          }
        />
      ) : null}
    </>
  );
};
