import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import Config from "../../config/app.config.json";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";

type InitialState = {
  helpSupportContent: any;
  errorHelpSupportContent: any;
};
const initialState: InitialState = {
  helpSupportContent: null,
  errorHelpSupportContent: null,
};

export const helpSupportStrapi = createAsyncThunk(
  "contactUsContent/helpSupportStrapi",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.ContactUs.HelpSupport.template}${StrapiApiEndPoints.helpSupport}`,
      isStrapiApi: true,
    });
  }
);

export const contactUsStrapi = createAsyncThunk(
  "contactUsContent/contactUsStrapi",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.ContactUs.HelpSupport.template}${StrapiApiEndPoints.contactUs}`,
      isStrapiApi: true,
    });
  }
);

const strapiHomeSlice = createSlice({
  name: "homeContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      helpSupportStrapi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.helpSupportContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorHelpSupportContent = null;
      }
    );
    builder.addCase(helpSupportStrapi.rejected, (state, action) => {
      state.helpSupportContent = null;
      state.errorHelpSupportContent =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default strapiHomeSlice.reducer;
