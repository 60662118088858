import { useState } from "react";
import { Grid, Box, Typography, Modal } from "@mui/material";
import styles from "./S1_MeetTheRecipients.module.scss";
import ReactPlayer from "react-player/youtube";
import { Close } from "@mui/icons-material";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../config/env.config.json";

export const T1_MeetTheRecipients = (props: any) => {
  const { content } = props;
  const [gbmodalShow, setgbmodalShow] = useState(false);
  const [gbmodalData, setgbmodalData] = useState({ v_url: "" });

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
  };

  const gbmodalHide = () => {
    setgbmodalShow(false);
  };

  const gbmodalHandler = (_: any, items: any) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.giveBack,
      category: gaCategory.meetTheRecipients,
      buttonValue: "recepients video",
    });
    setgbmodalShow(true);
    setgbmodalData(items);
  };

  return (
    <Grid
      container
      className={styles.meet_the_recipients}
      display="flex"
      justifyContent="space-around"
      sx={{
        p: {
          xs: "calc(var(--mobile_section_padding)) 0 calc(var(--mobile_section_padding)/2)",
          sm: "calc(var(--ipad_section_padding)) 0 calc(var(--ipad_section_padding)/2)",
          md: "calc(var(--desktop_section_padding)) 0 calc(var(--desktop_section_padding)/2)",
        },
      }}
    >
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">{content?.header || ""}</Typography>
        <Box
          className={styles.meet_the_recipients_line}
          sx={{ margin: "20px 0px" }}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Typography
          variant="body1"
          className={styles.meet_the_recipients_sub_heading}
        >
          {content?.sub_header || ""}
        </Typography>
      </Grid>
      <Grid item xs={12} className={styles.video_Cards_Wrap}>
        {content?.v_card.length > 0 &&
          content?.v_card.map((items: any, index: number) => {
            return (
              <Box
                key={index}
                className={`${styles.Video_card} ${styles.respcardmargin} ${styles.margin2}`}
              >
                <Box
                  component="img"
                  alt={items.v_img?.data?.attributes?.alternativeText}
                  src={items.v_img?.data?.attributes?.url}
                  width="100%"
                  height="172.562px"
                  onClick={(e: any) => gbmodalHandler(e, items)}
                />
                <Box className={styles.container}>
                  <Box className={styles.Card_Text}>{items.v_desc}</Box>
                </Box>
              </Box>
            );
          })}
      </Grid>
      {gbmodalShow && (
        <Modal
          open={gbmodalShow}
          onClose={() => gbmodalHide()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            <Box
              sx={{ ...style }}
              maxWidth={{ xs: "370px", lg: "800px", sm: "700px" }}
            >
              <Close
                onClick={gbmodalHide}
                fontSize="large"
                sx={{ color: "white", cursor: "pointer" }}
              />
              <ReactPlayer
                url={gbmodalData && gbmodalData.v_url}
                width="100%"
              />
            </Box>
          </>
        </Modal>
      )}
    </Grid>
  );
};
export default T1_MeetTheRecipients;
