import { Box, Typography } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import styles from "./ReccuringTopupFailedModal.module.scss";
import EnvConfig from "../../../../config/env.config.json";
import { useAppSelector } from "../../../../app/hooks";
import { CardTypes } from "../../../../enums";

type ReccuringTopupFailedProps= {
    reccuringFailOpen: boolean;
    handleManageCardOpen: any;
    handleClose: any;
    content: any;
    cardDetails: any;
}

const ReccuringTopupFailedModal = (props: ReccuringTopupFailedProps) => {
    const {reccuringFailOpen, handleManageCardOpen, handleClose, content, cardDetails} = props
    const { reccuringTopUpFailContent } = useAppSelector(
      (state: any) => state.strapi.account
    );
  return (
    <SimpleModal
      isShowModal={reccuringFailOpen}
      onCloseModal={handleClose}
      showClose={false}
      sx={{ width: { xs: "90%", sm: "60%", md: "80%", lg: "35%", xl: "35%" } }}
      style={{ borderRadius: "4px" }}
    >
      <Box textAlign="center" className={styles.topup_failed_dialog}>
        <Typography className={styles.title} pb="10px">
          {reccuringTopUpFailContent?.heading}
        </Typography>
        <Typography className={styles.sub_head} pt="10px">
          {reccuringTopUpFailContent?.description}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box component="img" src={
                  cardDetails && cardDetails.type.toUpperCase() === CardTypes.VISA
                    ? reccuringTopUpFailContent?.visa?.data?.attributes?.url
                    : cardDetails && cardDetails.type.toUpperCase() === CardTypes.JCB
                    ? reccuringTopUpFailContent?.jcb?.data?.attributes?.url
                    : cardDetails &&
                    cardDetails.type.toUpperCase() === CardTypes.AMERICANEXPRESS
                    ? reccuringTopUpFailContent?.ae?.data?.attributes?.url
                    : cardDetails &&
                    cardDetails.type.toUpperCase() === CardTypes.MASTERCARD
                    ? reccuringTopUpFailContent?.master_card?.data?.attributes?.url
                    : reccuringTopUpFailContent?.discover?.data?.attributes?.url
                } alt="visa_img"/>
          <Typography className={styles.card_num} p="20px 0 20px 0">
            {reccuringTopUpFailContent?.card_txt}{cardDetails?.ccNumberMask}
          </Typography>
        </Box>
        <ButtonCust variantType={EnvConfig.QUATERNARY_BUTTON_TYPE} onClick={handleManageCardOpen}>
          {reccuringTopUpFailContent?.btn_txt}
        </ButtonCust>
      </Box>
    </SimpleModal>
  );
};

export default ReccuringTopupFailedModal;
