import React from "react";
import { Box, Typography } from "@mui/material";
import { TextFieldCust, SelectCust, ButtonCust } from "../../../widgets";
import {
  numberAlphabetPattern,
  PhoneNoPattern,
} from "../../../../utils/regexPatterns";
import { displayPhoneNumberFormat } from "../../../../utils/commonFunctions";
import EnvConfig from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
  formik: any;
  content: any;
  operators: any;
  currentOperator: any;
  btnLoader: boolean;
  handleChangeCarrier: any;
  styles: any;
};

const CurrentNumberForm = (props: Props) => {
  const {
    formik,
    content,
    operators,
    currentOperator,
    btnLoader,
    handleChangeCarrier,
    styles,
  } = props;
  const { c_num } = content;
  return (
    <Box className={styles.form_container}>
      <Typography className={styles.form_title}>{c_num?.sub_title}</Typography>
      <Typography
        width={{ xs: "100%", sm: "80%", md: "50%" }}
        className={styles.form_description}
      >
        {BrandNameUpdate(c_num?.desc)}
      </Typography>
      <Box width={{ xs: "100%", sm: "80%", md: "65%" }}>
        <TextFieldCust
          name="phoneNumber"
          type="text"
          value={displayPhoneNumberFormat(formik.values.phoneNumber)}
          label={c_num?.ph_num_ph}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (
              (PhoneNoPattern.test(value) && Number(value) >= 0) ||
              value === ""
            ) {
              formik.handleChange(e);
            }
            if (value.length > 10) {
              const contactNumber: string = String(
                value.match(/\d/g)?.join("") || ""
              );
              e.target.value = contactNumber;
              formik.handleChange(e);
            }
          }}
          maxlength={10}
          sx={{ my: "10px" }}
          error={
            formik.touched?.phoneNumber && formik.errors?.phoneNumber
              ? true
              : false
          }
          helperText={formik.touched?.phoneNumber && formik.errors?.phoneNumber}
        />
        {operators && (
          <SelectCust
            value={formik.values.carrier}
            options={operators}
            name="carrier"
            placeholder={c_num?.carrier_ph}
            onChange={handleChangeCarrier}
            helperText={formik.touched?.carrier && formik.errors?.carrier}
            error={
              formik.touched?.carrier && formik.errors?.carrier ? true : false
            }
            formStyles={{ my: "10px" }}
          />
        )}
        {EnvConfig.SHOW_ACT_OTHER_CARRIER &&
          currentOperator?.label === "Others" && (
            <TextFieldCust
              name="otherCarrier"
              type="text"
              placeholder={c_num?.enter_carrier}
              value={formik.values.otherCarrier}
              label={c_num?.carr_ph}
              sx={{ my: "10px" }}
              maxlength={10}
              helperText={
                formik.touched?.otherCarrier && formik.errors?.otherCarrier
              }
              onChange={handleChangeCarrier}
            />
          )}
        <TextFieldCust
          name="zip"
          type="text"
          value={formik.values.zip}
          label={c_num?.zip_ph}
          onChange={(e) => {
            if (PhoneNoPattern.test(e.target.value) || e.target.value === "") {
              formik.handleChange(e);
            }
          }}
          sx={{ my: "10px" }}
          maxlength={5}
          error={formik.touched?.zip && formik.errors?.zip ? true : false}
          helperText={formik.touched?.zip && formik.errors?.zip}
        />
        <Typography className={styles.limit_text}>
          {formik.values?.zip?.length}/5
        </Typography>
        <TextFieldCust
          name="accNumber"
          type="text"
          value={formik.values.accNumber}
          label={
            formik.values.carrier
              ? `${formik.values.carrier} ${c_num?.acc_num_ph}`
              : `${c_num?.cur_txt} ${c_num?.acc_num_ph}`
          }
          onChange={(e) => {
            if (
              numberAlphabetPattern.test(e.target.value) ||
              e.target.value === ""
            ) {
              formik.handleChange(e);
            }
          }}
          sx={{ my: "10px" }}
          maxlength={20}
          error={
            formik.touched?.accNumber && formik.errors?.accNumber ? true : false
          }
          helperText={formik.touched?.accNumber && formik.errors?.accNumber}
        />
        <TextFieldCust
          name="password"
          type="text"
          value={formik.values.password}
          label={
            formik.values.carrier
              ? `${formik.values.carrier} ${c_num?.pin_ph}`
              : `${c_num?.cur_txt} ${c_num?.pin_ph}`
          }
          onChange={(e) => {
            if (
              numberAlphabetPattern.test(e.target.value) ||
              e.target.value === ""
            ) {
              formik.handleChange(e);
            }
          }}
          sx={{ my: "10px" }}
          maxlength={15}
          error={
            formik.touched?.password && formik.errors?.password ? true : false
          }
          helperText={formik.touched?.password && formik.errors?.password}
        />
        {currentOperator?.pinLabel && (
          <Typography className={styles.pin_label}>
            {currentOperator.pinLabel}
          </Typography>
        )}
      </Box>
      <ButtonCust
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        sx={{ width: "220px", mt: "20px" }}
        onClick={() => {
          formik.handleSubmit();
        }}
        loading={btnLoader}
      >
        {c_num?.next_btn}
      </ButtonCust>
    </Box>
  );
};
export default CurrentNumberForm;
