import { Box, Typography } from "@mui/material";
import { ButtonCust, Divider, SimpleModal } from "../../../widgets";
import EnvConfig from "../../../../config/env.config.json";

type Props = {
  closePayModal: any;
  modalData: any;
};
const PaymentSuccessModal = (props: Props) => {
  const { closePayModal, modalData } = props;
  const { btn, desc_text, title, m_img } = modalData || {};
  return (
    <SimpleModal
      onCloseModal={closePayModal}
      showClose={true}
      isShowModal={true}
      sx={{
        width: { xs: "90%", sm: "60%", md: "40%", lg: "35%" },
        borderRadius: "6px",
      }}
    >
      <Typography
        sx={{
          fontSize: 22,
          fontFamily: "var(--font_family_Bold)",
          color: "var(--text_color)",
          textAlign: "center",
          fontWeight: "var(--font_weight_4)",
        }}
      >
        {title}
      </Typography>
      <Divider styles={{ my: "15px" }} />
      <Box display="flex" justifyContent="center">
        <Box
          component="img"
          src={m_img?.data?.attributes?.url}
          alt={m_img?.data?.attributes?.alternativeText}
          mt="20px"
        />
      </Box>
      <Typography
        sx={{
          fontSize: 14,
          fontFamily: "var(--font_family_Medium)",
          color: "var(--text_color)",
          textAlign: "center",
          fontWeight: "var(--font_weight_2)",
          my: "25px",
        }}
      >
        {desc_text}
      </Typography>
      <Box justifyContent="center" display="flex">
        <ButtonCust
          variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
          onClick={closePayModal}
        >
          {btn}
        </ButtonCust>
      </Box>
    </SimpleModal>
  );
};

export default PaymentSuccessModal;
