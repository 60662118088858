import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  cardsData: any;
  errorCardsData: any;
  hsdData: any;
  errorHsdData: any;
};

const initialState: InitialState = {
  cardsData: null,
  errorCardsData: null,
  hsdData: null,
  errorHsdData: null,
};

// Generates pending, fulfilled and rejected action types
export const cardsContent = createAsyncThunk("settings/cardsContent", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Settings.SettingsCards.template}${StrapiApiEndPoints.settingsCards}`,
    isStrapiApi: true,
  });
});

export const hsdContent = createAsyncThunk("settings/hsdContent", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Settings.HSD.template}${StrapiApiEndPoints.settingsHsd}`,
    isStrapiApi: true,
  });
});

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      cardsContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.cardsData = action.payload?.data?.data?.attributes;
        state.errorCardsData = null;
      }
    );
    builder.addCase(cardsContent.rejected, (state, action) => {
      state.cardsData = null;
      state.errorCardsData = action.error || "Something went wrong";
    });
    builder.addCase(
      hsdContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.hsdData = action.payload?.data?.data?.attributes;
        state.errorHsdData = null;
      }
    );
    builder.addCase(hsdContent.rejected, (state, action) => {
      state.hsdData = null;
      state.errorHsdData = action.error || "Something went wrong";
    });
  },
});

export default settingsSlice.reducer;
