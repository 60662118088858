import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { appSectionContent } from "../../../features/strapi/homePageSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import EnvConfig from "../../../config/env.config.json";
import { CustomerStatus } from "../../../enums";

const AppSectionCmp = isTemplateExisits(Config.Home.AppSection)
  ? require(`./${Config.Home.AppSection.template}_AppSection`).default
  : null;

export const AppSection = () => {
  const {
    homepage: { appSection },
  } = useAppSelector((state: any) => state.strapi);
  const [cacheDataApp, setCacheDataApp] = useState<any>(null);
  const dispatch = useAppDispatch();
  const [displayAppSection, setDisplayAppSection] = useState<boolean>(true);
  useEffect(() => {
    getCache("AppSecHome", appSectionContent, setCacheDataApp, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("AppSecHome", appSection);
  }, [appSection]);

  const user = JSON.parse(localStorage.getItem("customerDetail") || "null");

  useNonInitialEffect(() => {
    if (EnvConfig.PAHOME_APP_SECTION_CONDITION_REQD) {
      if (
        user?.status !== CustomerStatus.ACTIVE &&
        user?.status !== CustomerStatus.SUSPENDED &&
        user?.status !== CustomerStatus.DISCONNECTED
      )
        setDisplayAppSection(true);
      else setDisplayAppSection(false);
    }
  }, [user]);

  return (
    <>
      {displayAppSection && AppSectionCmp && (
        <AppSectionCmp appSection={cacheDataApp ? cacheDataApp : appSection} />
      )}
    </>
  );
};
