import firebase from "./firebase";

const getAnonymousAuthToken: any = () => {
  return new Promise((resolve, reject) => {
    try {
      if (
        !(
          localStorage.getItem("accessToken") ||
          localStorage.getItem("currentuser")
        )
      ) {
        firebase
          .auth()
          .signInAnonymously()
          .then(async (user) => {
            await localStorage.setItem(
              "currentuser",
              JSON.stringify(user && user.user)
            );
            let user1: any = await localStorage.getItem("currentuser");
            user1 = JSON.parse(user1);
            let token =
              user1 &&
              user1.uid &&
              user1.stsTokenManager &&
              user1.stsTokenManager.accessToken;
            resolve(token);
            await localStorage.setItem("accessToken", token);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch {
      reject("api failed");
    }
  });
};

export default getAnonymousAuthToken;
