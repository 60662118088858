import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  signUpContent: any;
  errorsignUpContent: any;
};

const initialState: InitialState = {
  signUpContent: null,
  errorsignUpContent: null,
};

export const strapiSignUpContent = createAsyncThunk(
  "signup/signupContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.SignUp.SignUp.template}${StrapiApiEndPoints.signup}`,
      isStrapiApi: true,
    });
  }
);

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      strapiSignUpContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.signUpContent = action.payload?.data?.data?.attributes;
        state.errorsignUpContent = null;
      }
    );
    builder.addCase(
      strapiSignUpContent.rejected,
      (state: InitialState, action: any) => {
        state.signUpContent = null;
        state.errorsignUpContent = action.error || "Something went wrong";
      }
    );
  },
});

export default signupSlice.reducer;
