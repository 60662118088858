import { useEffect, useState } from "react";
import Config from "../../../../config/app.config.json";
import firebase from "../../../../adapters/firebase";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchDiscoverContent } from "../../../../features/strapi/discoverSlice";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
const DiscoverComponent = isTemplateExisits(Config.PostActivationHome.Discover)
  ? require(`./${Config.PostActivationHome.Discover.template}_PADiscover`)
      .default
  : null;

export const PADiscover = () => {
  const [discoverData, setDiscoverData] = useState([]);
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { discoverContent } = useAppSelector(
    (state: any) => state.strapi.discover
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
    getCache('DiscoverPAHome', fetchDiscoverContent, setCacheContent, dispatch)
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData('DiscoverPAHome', discoverContent)
  }, [discoverContent])

  const fetchData = async () => {
    const remoteConfig: any = firebase.remoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    await remoteConfig.fetchAndActivate();

    const all = remoteConfig.getValue("app_discover_section_all");
    setDiscoverData(JSON.parse(all._value));
  };

  return (cacheContent || discoverContent)&& discoverData.length > 0 && <DiscoverComponent content={cacheContent? cacheContent: discoverContent} discoverData={discoverData} />;
};
