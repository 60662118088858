import { Typography } from "@mui/material";
import { ButtonCust, Divider, SimpleModal } from "../../widgets";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AccountNumberPopover from "./AccountNumberPopover";
import EnvConfig from "../../../config/env.config.json";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { generateImageContainer } from "../../../utils/commonFunctions";

type Props = {
  content: any;
  popoverContent: any;
  openModal: boolean;
  onModalClose: any;
  eligibilityCheckCounter: any;
  tryAgainHandler: any;
  popoverId: any;
  open: any;
  anchorEl: any;
  handleOpenPopover: any;
  handleClosePopover: any;
  eligibilityCheckFromPromo?: any;
};
const HSDFailureModal = (props: Props) => {
  const {
    content,
    popoverContent,
    openModal,
    onModalClose,
    eligibilityCheckCounter,
    tryAgainHandler,
    popoverId,
    open,
    anchorEl,
    handleOpenPopover,
    handleClosePopover,
  } = props;
  const {
    ttl,
    sub_ttl,
    btn,
    count_btn,
    limit_txt,
    review_txt,
    acc_info_txt,
    icon,
  } = content;
  let button_text_with_counter = count_btn?.replace(
    "{count}",
    eligibilityCheckCounter
  );

  return (
    <SimpleModal
      isShowModal={openModal}
      onCloseModal={onModalClose}
      showClose={true}
      sx={{ width: { xs: "90%", sm: "65%", md: "55%", lg: "50%", xl: "35%" } }}
      style={{ padding: "40px" }}
    >
      <Typography
        sx={{
          mt: "16px",
          mb: "8px",
          fontSize: "26px",
          fontFamily: "var(--font_family_Semibold)",
          fontWeight: "var(--h4_font_weight)",
          textAlign: "center",
        }}
      >
        {ttl}
      </Typography>
      <Divider styles={{ mb: "16px" }} />
      {generateImageContainer({
        data: icon,
        attrs: { height: "30px", width: "30px" },
      })}
      <Typography
        color="var(--grey_shade)"
        fontSize="16px"
        fontFamily="var(--font_family_Medium)"
        textAlign="center"
        fontWeight="var( --h4_font_weight)"
        my="16px"
      >
        {BrandNameUpdate(sub_ttl)}
      </Typography>
      {eligibilityCheckCounter && eligibilityCheckCounter < 3 ? (
        <ButtonCust
          variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
          sx={{ width: "100%" }}
          onClick={tryAgainHandler}
        >
          {button_text_with_counter}
        </ButtonCust>
      ) : (
        <>
          {!props?.eligibilityCheckFromPromo && (
            <ButtonCust
              variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
              sx={{ width: "100%" }}
              onClick={onModalClose}
            >
              {btn}
            </ButtonCust>
          )}
          <Typography
            color="var(--cool_blue)"
            fontFamily="var(--font_family_Medium)"
            fontSize="16px"
            my="16px"
            fontWeight="var(--h4_font_weight)"
          >
            {limit_txt}
          </Typography>
          <Typography
            color="var(--grey_shade)"
            fontSize="14px"
            fontFamily="var(--font_family_Medium)"
            textAlign="center"
          >
            {review_txt}
            <br />
            <span
              style={{ color: "var(--primary_brand_color)", cursor: "pointer" }}
              onClick={handleOpenPopover}
            >
              {BrandNameUpdate(acc_info_txt)}
              <span style={{ position: "absolute" }}>
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </span>
            </span>
          </Typography>
          <AccountNumberPopover
            content={popoverContent}
            popoverId={popoverId}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClosePopover}
          />
        </>
      )}
    </SimpleModal>
  );
};

export default HSDFailureModal;
