import { isTemplateExisits } from "../../../../utils/commonFunctions";
import Config from "../../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateSecondaryUser } from "../../../../features/checkout/checkoutSlice";
import {
  activateLine,
  showPaySuccessModalFn,
} from "../../../../features/activation/activationSlice";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import EnvConfig from "../../../../config/env.config.json";
import PaymentSuccessModal from "./PaymentSuccessModal";

const Step4Component = isTemplateExisits(Config.Activation.ActivationFlow)
  ? require(`./${Config.Activation.ActivationStep4.template}_ActivationStep4`)
      .default
  : null;

export const ActivationStep4 = (props: any) => {
  const { handleStep, currentLine, content, modalContent } = props;
  const { pay_success } = content || {};
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [activateBtnLd, setActivateBtnLd] = useState(false);
  const [showActionIsMnp, setShowActionIsMnp] = useState(false);
  const navigate = useNavigate();
  const { showPaySuccessModal } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const simID = localStorage.getItem("simId");
  const { id, reachPlanId, imei, make, model, isMNP, mnpInfo, simId } =
    currentLine || {};
  const { operator, oldZip, oldPassword, oldNumber, acctNum } = mnpInfo || {};

  const openSupportModal = () => {
    setShowModal(false);
    setOpenSupportDialog(true);
  };

  const closeActivationFailModal = () => {
    setShowModal(false);
  };

  const onCloseSupportDialog = () => {
    setShowModal(true);
    setOpenSupportDialog(false);
  };
  const activateHandler = () => {
    setActivateBtnLd(true);
    const skipHoldVal =
      EnvConfig.IS_ACT_SKIP_HOLD_VALUE && isMNP ? true : false;
    const newNumPy: any = {
      customerId: id,
      reachPlanId: reachPlanId,
      iccId: simID || simId,
      imei: imei,
      make: make,
      model: model,
      skipHold: true,
      isPort: false,
    };
    const mnpPy = {
      ...newNumPy,
      skipHold: skipHoldVal,
      isPort: true,
      numberToPort: oldNumber,
      oldCarrier: operator,
      oldCarrierAccountNumber: acctNum,
      password: oldPassword,
      oldZip: oldZip,
      portUpdate: false,
    };
    dispatch(activateLine({ payload: isMNP ? mnpPy : newNumPy })).then(
      (res: any) => {
        activateLineResults(res);
      }
    );
  };
  const activateLineResults = (res: any) => {
    if (res?.payload?.status === "FAILURE" || !res?.payload) {
      setShowModal(true);
      setActivateBtnLd(false);
    } else if (res?.payload?.data?.status === "SUCCESS") {
      if (isMNP) {
        setShowActionIsMnp(true);
      } else {
        redirection();
      }
    } else {
      setActivateBtnLd(false);
    }
  };
  const redirection = () => {
    dispatch(updateSecondaryUser(null));
    navigate(RoutingURLs.postActivationHome);
    // localStorage.removeItem("secondaryCustomerDetail");
    localStorage.removeItem("simId");
  };
  const closePayModal = () => {
    dispatch(showPaySuccessModalFn(false));
  };
  return (
    <>
      <Step4Component
        currentLine={currentLine}
        content={content}
        modalContent={modalContent}
        handleStep={handleStep}
        redirection={redirection}
        activateHandler={activateHandler}
        onCloseSupportDialog={onCloseSupportDialog}
        openSupportModal={openSupportModal}
        closeActivationFailModal={closeActivationFailModal}
        showModal={showModal}
        openSupportDialog={openSupportDialog}
        activateBtnLd={activateBtnLd}
        showActionIsMnp={showActionIsMnp}
        {...props}
      />
      {showPaySuccessModal && (
        <PaymentSuccessModal
          closePayModal={closePayModal}
          modalData={pay_success}
        />
      )}
    </>
  );
};
