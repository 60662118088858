import { Grid, Typography, Avatar } from "@mui/material";
import styles from "../S1_ViewCredits.module.scss";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import EnvConfig from "../../../../../config/env.config.json";
import { v4 as uuid } from "uuid";

type Props = {
  setCurrentCard?: Function;
  viewMore: boolean;
  activatedUsers: any;
};

export const ActivatedReferralsListItem = ({
  viewMore,
  activatedUsers=[],
}: Props) => {
  let activatedList =
    viewMore &&
    activatedUsers?.length > EnvConfig?.NUMBER_OF_USERS_ACTIVATED_REFERRALS
      ? activatedUsers?.slice(0, EnvConfig?.NUMBER_OF_USERS_ACTIVATED_REFERRALS)
      : activatedUsers;

  return activatedList.map((item: any) => {
    return (
  <Grid key={uuid()} className={styles.activated_referrals_list_item}>
        <Avatar className={styles.avatar}>{item?.name && item?.name[0]}</Avatar>
        &ensp;
        <Typography className={styles.user_name}>{item?.name}</Typography>
        <Grid display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
        <Typography className={styles.referral_date} >
          {new Date(item?.startDate).toDateString().substring(3)}
        </Typography>
        <Typography className={styles.activated_referral_amount}>
            {GetCurrencyFixedToTwo(item?.maxDiscInDollar)}
          </Typography>
        </Grid>
        
      </Grid>
    );
  });
};
