import Config from "../../../../config/app.config.json";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchgivePlanContent } from "../../../../features/strapi/myAccountSlice";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";

const GivePlanComponent = isTemplateExisits(Config.MyAccount.App)
  ? require(`./${Config.MyAccount.App.template}_GivePlan`).default
  : null;
export type GivePlanProps = {
  content?: any;
};
export const GivePlan = () => {
  const [content, setContent] = useState<any>(null);
  const [cacheGivePlan, setCacheGivePlan] = useState<any>(null);
  const { givePlanContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("GivePlan-MyAccount", fetchgivePlanContent, setCacheGivePlan, dispatch);
  }, []);
  useNonInitialEffect(() => {
    setContent(givePlanContent);
    addMultipleCacheData("GivePlan-MyAccount", givePlanContent);
  }, [givePlanContent]);
  return content || cacheGivePlan ? (
    <GivePlanComponent content={cacheGivePlan ? cacheGivePlan : content} />
  ) : null;
};
