import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  imeiContent: any;
  errorImeiContent: any;
};

const initialState: InitialState = {
  imeiContent: null,
  errorImeiContent: null,
};

export const compatibilityContent = createAsyncThunk(
  "imeiCompatibility/compatibilityContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.ImeiCompatibilityCheck.CheckCompatability.template}${StrapiApiEndPoints.compatibilityCheck}`,
      isStrapiApi: true,
    });
  }
);

const imeiCompatibilitySlice = createSlice({
  name: "imeiCompatibility",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      compatibilityContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.imeiContent = action.payload?.data?.data?.attributes;
        state.errorImeiContent = null;
      }
    );
    builder.addCase(compatibilityContent.rejected, (state, action) => {
      state.imeiContent = null;
      state.errorImeiContent = action.error || "Something went wrong";
    });
  },
});

export default imeiCompatibilitySlice.reducer;
