import { useState } from "react";
import { useDispatch } from "react-redux";

export   const getMultipleCacheData = async (cacheNames:any) => {
    // if (typeof caches === 'undefined') return false;
  
    let cacheDataArray:any = null;
    // for (let i = 0; i < cacheNames.length; i++) {
  
      const cacheStorage = await caches.open(cacheNames.cacheName);
      const cachedResponse :any= await cacheStorage.match(cacheNames.url);

      // If no cache exists
      if (!cachedResponse || !cachedResponse.ok) {
        cacheDataArray = null
      } else {
        let data = await cachedResponse.json()
        cacheDataArray = data
      }
    // }
  
    // Putting commas in between caches data
    // to display properly
    return  cacheDataArray
  
  };