import { useEffect } from "react";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import { Grid, Typography, Box } from "@mui/material";
import EnvConfig from "../../../config/env.config.json";
import styles from "./S2_DetailsCoverage.module.scss";
import { DetailsCoverageProps } from "./DetailsCoverage";
function DetailsCheckCoverage(props: DetailsCoverageProps) {
  const { coverageCheckContent, buttonHandler, currentdetails } = props;
  const { heading, sub_heading, btn_value, iframeLink, iframeTitle } =
    coverageCheckContent;
  const { plum_details_coverage, iframeMapId } = styles;
  useEffect(() => {
    window.scroll(0, 0);
    let container = document.getElementById(iframeMapId);
    window.addEventListener(
      "message",
      function (e) {
        let message = e.data;
        if (message.map && message.height && container) {
          container.style.height = message.height + 350 + "px";
        }
      },
      false
    );
  }, []);
  return (
    <Box
      className={plum_details_coverage}
      display="flex"
      flexDirection="column"
    >
      <Box
        p={{ xs: "30px 15px", sm: "30px 60px" }}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems={{ xs: "center", sm: "space-between" }}
        sx={{ backgroundColor: "var(--gray_background)" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          mt={"20px"}
          sx={{
            textAlign: "center",
            width: { xs: "100%", sm: "50%", md: "100%" },
          }}
        >
          <Typography
            lineHeight={1.5}
            sx={{
              fontSize: "26px",
              fontFamily: "var(--font_family_Semibold)",
              mb: "5px",
            }}
          >
            {heading}
          </Typography>
          <Typography
            lineHeight={1.6}
            sx={{
              fontSize: "18px",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {sub_heading}
          </Typography>
        </Box>
        {!currentdetails?.simPaid && (
          <ButtonCust
            sx={{ width: "100px", mt: "20px" }}
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            onClick={(e) => {
              buttonHandler(e);
            }}
          >
            {btn_value}
          </ButtonCust>
        )}
      </Box>
      <Grid container textAlign="center">
        <iframe
          id={iframeMapId}
          src={iframeLink}
          title={iframeTitle}
          loading="lazy"
        ></iframe>
      </Grid>
    </Box>
  );
}
export default DetailsCheckCoverage;
