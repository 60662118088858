import { Box, Typography } from "@mui/material";
import { Divider, LazyLoadImageCust } from "../../widgets";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

export const T1_AppSection = (props: any) => {
  const { appSection } = props;
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  return (
    <Box
      sx={{
        p: {
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0 0",
          md: "calc(var(--desktop_section_padding)/2) 0 0",
        },
        minHeight: { xs: "1075px", sm: "675px", md: "355px" },
      }}
    >
      <Box pb="20px">
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {BrandNameUpdate(appSection?.title)}
        </Typography>
      </Box>
      <Divider />
      {(largeDesktop || desktop) && (
        <LazyLoadImageCust
          src={appSection?.image_web?.data?.attributes?.url || ""}
          alt={appSection?.image_web?.data?.attributes?.alternativeText || ""}
          width={"100%"}
          height={"100%"}
          style={{ mb: "40px" }}
        />
      )}
      {ipad && (
        <LazyLoadImageCust
          src={appSection?.image_tab?.data?.attributes?.url || ""}
          alt={appSection?.image_tab?.data?.attributes?.alternativeText || ""}
          width={"100%"}
          height={"100%"}
          style={{ mb: "40px" }}
        />
      )}
      {mobile && (
        <LazyLoadImageCust
          src={appSection?.image_mob?.data?.attributes?.url || ""}
          alt={appSection?.image_mob?.data?.attributes?.alternativeText || ""}
          width={"100%"}
          height={"100%"}
          style={{ mb: "40px" }}
        />
      )}
    </Box>
  );
};

export default T1_AppSection;
