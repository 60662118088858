import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ButtonCust, CustToolTip } from "../../../widgets";
import Config from "../../../../config/env.config.json";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { parseISO, format, parse } from "date-fns";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import styles from "./GlobalRoamingPack.module.scss";
import PaymentReviewCard from "../PaymentReviewCard/PaymentReviewCard";
import { getPlanTaxCharges } from "../../../../features/checkout/checkoutSlice";
import PaymentStatus from "../PaymentStatus/PaymentStatus";
import { PostActivationIRILDEnums } from "../../../../enums";
import {
  purchasePack,
  resetPurchasePack,
} from "../../../../features/services/servicesSlice";
import { getCC } from "../../../../features/account/accountSlice";

function GlobalRoamingPack(props: any) {
  const { content, fullName } = props.props;
  const {
    back_btn,
    GR_pack,
    termCondition,
    GR_icons,
    visa_card,
    calendarPen,
    calendar_data,
    carbon_edit,
    termTooltip,
    payment_review,
    success,
    failed,
    payment_status,
    back_arrow,
  } = content;
  const { setIsGlobalRoamingPack } = props;
  const { onClickSwitchTabs, setIsCountryList } = props.props;
  const availableOfferingsData = useAppSelector(
    (state) => state.services?.availableOfferings
  );

  const { purchasePackError, purchasePackSuccess, purchasePackLoading } =
    useAppSelector((state) => state.services);

  const { planTaxCharges, isLoadedPlanTaxCharges } = useAppSelector(
    (state: any) => state.checkout
  );

  const dispatch = useAppDispatch();
  const [isCalendarModal, setisCalendarModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [isPaymentReviewCard, setIsPaymentReviewCard] =
    useState<boolean>(false);
  const [autoRenewal, setAutoRenweal] = useState<boolean>(false);
  const [isTooltip, setIsTooltip] = useState<boolean>(false);
  const [selectedPack, setSelectedPack] = useState<any>(null);
  const [purchasePackStatus, setPurchasePackStatus] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<any>("");

  const customerDetailJSON = localStorage.getItem("customerDetail");
  const customerDetail = customerDetailJSON
    ? JSON.parse(customerDetailJSON)
    : null;

  const billingData = customerDetail.addresses.find(
    (item: any) => item.type === "billing"
  );

  const dateFormat: any = () => {
    const getLocalCountry: any = localStorage.getItem("postActivationDate");
    const parsedDate = parseISO(getLocalCountry); // Parse the ISO format date string
    const formattedDate = format(parsedDate, "MMMM d, yyyy");
    return formattedDate;
  };

  const onChangeAutoRenewal = () => {
    setAutoRenweal((prev) => !prev);
  };

  const country = localStorage.getItem("prRoamingCountry");
  const calanderOnChangeHandler = (e: any) => {
    const date = new Date(e);
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate: any = date.toLocaleDateString("en-US", options);
    setSelectedDate(formattedDate);
  };
  const formatDateForAPI = (e: any) => {
    if (e) {
      const parsedDate = parse(e, "MMMM dd, yyyy", new Date());
      const formatted = format(parsedDate, "yyyy-MM-dd");
      return formatted;
    }
    return;
  };

  const confirmPurchaseHandler = () => {
    setPurchasePackStatus(true);
    const customerId: any = localStorage.getItem("SingleLineData");
    const country: any = localStorage.getItem("prRoamingCountry");
    const payload = {
      offeringId: selectedPack.id,
      tentativeStartDate: localStorage.getItem("postActivationDate"),
      countries: [JSON.parse(country).code],
      autoRenew: autoRenewal,
      customerId: JSON.parse(customerId).id,
    };
    dispatch(purchasePack(payload));
  };

  const calanderSubmitHandler = () => {
    const formattedForApi: any = formatDateForAPI(selectedDate);
    localStorage.setItem("postActivationDate", formattedForApi);
    setIsPaymentReviewCard(false);
    setisCalendarModal(false);
  };

  const selectPackHandler = (data: any) => {
    const address = {
      name: `${customerDetail.firstName} ${customerDetail.lastName}`,
      address1: billingData?.address1,
      city: billingData?.city,
      country: billingData?.country,
      state: billingData?.state,
      type: "billing",
      zip: billingData?.zip,
    };

    const localData: any = {
      displayName: data.displayName,
      dataCost: data.addOnServices.data.cost,
      servicePlus: data.addOnServices.data.servicePulse,
      servicePulseUnit: data.addOnServices.data.servicePulseUnit,
      id: data.id,
    };
    setSelectedPack(localData);
    setDisplayName(data.displayName);
    setIsPaymentReviewCard(true);
    dispatch(
      getPlanTaxCharges({
        address,
        amount: data?.addOnServices?.data.cost,
        additionalLines: customerDetail?.totalActiveLines,
      })
    );
    dispatch(getCC({ customerId: customerDetail && customerDetail.id }));
  };

  const combinedSelectedPackReviewData = { ...selectedPack, ...payment_review };

  return (
    <>
      {purchasePackError && (
        <PaymentStatus
          icon={failed}
          title={payment_status.failedTitle}
          description={payment_status.failedDesc}
          button={payment_status.failedBtn}
          onClick={() => confirmPurchaseHandler()}
          variantType={Config.QUATERNARY_BUTTON_TYPE}
        />
      )}

      {purchasePackLoading && (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}

      {purchasePackSuccess && (
        <PaymentStatus
          icon={success}
          title={payment_status.successTitle}
          description={`${payment_status.successDesc} ${dateFormat()}.`}
          button={payment_status.successBtn}
          onClick={() => {
            setIsCountryList(false);
            onClickSwitchTabs(PostActivationIRILDEnums.tab_right);
            dispatch(resetPurchasePack());
            localStorage.removeItem("selectedPack");
            localStorage.removeItem("postActivationDate");
            localStorage.removeItem("prRoamingCountry");
            localStorage.removeItem("SingleLineData");
          }}
          variantType={Config.QUATERNARY_BUTTON_TYPE}
        />
      )}

      {!purchasePackStatus && (
        <Box
          m={{ xs: "auto", sm: "auto", md: "auto" }}
          width={{ xs: "auto", sm: "auto", md: "60%" }}
          className={styles.main_container}
        >
          <CustomDateCalendar
            onClick={calanderSubmitHandler}
            onChange={calanderOnChangeHandler}
            isCalanderModal={isCalendarModal}
            onCloseModal={setisCalendarModal}
            title={calendar_data.title}
            desc={calendar_data.desc}
            button={calendar_data.btn_2}
            variant={Config.QUATERNARY_BUTTON_TYPE}
          />
          <Typography className={styles.top_heading}>
            <Typography
              className={styles.back_arrow}
              onClick={() => setIsGlobalRoamingPack("")}
            >
              {/* Add your arrow icon here */}
              <img
                src={back_arrow?.data.attributes.url}
                alt={back_arrow?.data.attributes.alternativeText}
                width={17.495}
                height={14.586}
              />
              {back_btn}
            </Typography>
            <span style={{ margin: "auto" }}>
              {isPaymentReviewCard ? payment_review.title : GR_pack.title}
            </span>
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography className={styles.country_label}>
                <span
                  style={{ opacity: 0.7, fontWeight: "var(--font_weight_1)" }}
                >
                  {GR_pack.country}
                  <span style={{ marginRight: "1px" }}>: </span>
                </span>
                <span style={{ fontWeight: "var(--font_weight_5)" }}>
                  {JSON.parse(country || "{}").name}
                </span>
              </Typography>
              <Typography display={"flex"} justifyContent={"flex-start"}>
                <span className={styles.schedule}>{GR_pack.scheduleFor}</span>
                <span style={{ margin: "0 5px" }}>: </span>
                <span className={styles.selected_date}>
                  {selectedDate ? selectedDate : dateFormat()}
                </span>
                <img
                  style={{ marginLeft: "21px" }}
                  onClick={() => setisCalendarModal(true)}
                  src={calendarPen.data.attributes.url}
                />
              </Typography>
            </Box>
            <Box>
              <Typography className={styles.full_name}> {fullName}</Typography>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={3}>
            {/*Mapping the data  */}

            {!isPaymentReviewCard ? (
              availableOfferingsData.length === 0 ? (
                <Box textAlign={"center"}>
                  <CircularProgress sx={{ color: "var(--primary_color)" }} />
                </Box>
              ) : (
                availableOfferingsData.map((data: any) => {
                  return (
                    <Box key={data.id}>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography className={styles.display_name}>
                          <span>{data?.displayName}</span>
                          <span className={styles.display_name_service_unit}>
                            {" "}
                            {data.addOnServices.data.servicePulse}
                            {data.addOnServices.data.servicePulseUnit}
                          </span>
                        </Typography>
                        <Typography className={styles.data_cost}>
                          ${data.addOnServices.data.cost}.00
                        </Typography>
                      </Box>
                      <Box key={data.id} className={styles.card_sub_container}>
                        <Box textAlign={"center"}>
                          <Typography
                            className={styles.card_sub_container_title}
                          >
                            {GR_pack.data}{" "}
                            <img
                              alt={GR_icons.data[0].attributes.alternativeText}
                              src={GR_icons.data[0].attributes.url}
                            />
                          </Typography>
                          <Typography
                            className={styles.card_sub_container_value}
                          >
                            {data.data}GB
                          </Typography>
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ borderRightWidth: 2 }}
                        />
                        <Box textAlign={"center"}>
                          <Typography
                            className={styles.card_sub_container_title}
                          >
                            {GR_pack.voice}{" "}
                            <img
                              alt={GR_icons.data[1].attributes.alternativeText}
                              src={GR_icons.data[1].attributes.url}
                            />
                          </Typography>
                          <Typography
                            className={styles.card_sub_container_value}
                          >
                            {data.voice}mins
                          </Typography>
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ borderRightWidth: 2 }}
                        />
                        <Box textAlign={"center"}>
                          <Typography
                            className={styles.card_sub_container_title}
                          >
                            {GR_pack.sms}{" "}
                            <img
                              alt={GR_icons.data[2].attributes.alternativeText}
                              src={GR_icons.data[2].attributes.url}
                            />
                          </Typography>
                          <Typography
                            className={styles.card_sub_container_value}
                          >
                            {data.sms}
                          </Typography>
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ borderRightWidth: 2 }}
                        />
                        <Box textAlign={"center"}>
                          <Typography
                            className={styles.card_sub_container_title}
                          >
                            {GR_pack.mms}{" "}
                            <img
                              alt={GR_icons.data[3].attributes.alternativeText}
                              src={GR_icons.data[3].attributes.url}
                            />
                          </Typography>
                          <Typography
                            className={styles.card_sub_container_value}
                          >
                            {data.mms}
                          </Typography>
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ borderRightWidth: 2 }}
                        />
                        <Box textAlign={"center"}>
                          <Typography
                            className={styles.card_sub_container_title}
                          >
                            {GR_pack.validity}{" "}
                            <img
                              alt={GR_icons.data[4].attributes.alternativeText}
                              src={GR_icons.data[4].attributes.url}
                            />
                          </Typography>
                          <Typography
                            className={styles.card_sub_container_value}
                          >
                            {data.validityDays}{" "}
                            {data.validityDays === 1 ? "day" : "days"}
                          </Typography>
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ borderRightWidth: 2 }}
                        />
                        <ButtonCust
                          variantType={Config.QUATERNARY_BUTTON_TYPE}
                          onClick={() => selectPackHandler(data)}
                        >
                          {GR_pack.pack_btn}
                        </ButtonCust>
                      </Box>
                    </Box>
                  );
                })
              )
            ) : (
              <PaymentReviewCard
                onChangeAutoRenewal={onChangeAutoRenewal}
                autoRenewal={autoRenewal}
                isAutoRenewal={true}
                firstRowTitle={`${displayName} ${combinedSelectedPackReviewData.servicePlus}${combinedSelectedPackReviewData.servicePulseUnit}`}
                firstRowValue={combinedSelectedPackReviewData.dataCost}
                secondRowTitle={combinedSelectedPackReviewData.secondRow}
                secondRowValue={planTaxCharges}
                thirdRowTitle={combinedSelectedPackReviewData.thirdRow}
                mockCard={combinedSelectedPackReviewData.mockCard}
                loading={isLoadedPlanTaxCharges}
                autoRenewalTitle={
                  combinedSelectedPackReviewData?.autoRenewal.title
                }
                visa_card={visa_card?.data?.attributes}
                carbon_edit={carbon_edit.data.attributes}
                data={combinedSelectedPackReviewData}
                grPack={GR_pack}
                displayName={displayName}
                toggleTrueText={
                  combinedSelectedPackReviewData?.autoRenewal.toggleTrue
                }
                toggleFalseText={
                  combinedSelectedPackReviewData?.autoRenewal.toggleFalse
                }
                onClick={confirmPurchaseHandler}
                confirmBtn={combinedSelectedPackReviewData.cnfrmBtn}
                changeCardText={combinedSelectedPackReviewData.ChangeCard}
              />
            )}
          </Box>
          {!isPaymentReviewCard && (
            <Typography
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              className={styles.term_condition}
              sx={{ textAlign: "center" }}
            >
              {GR_pack.termsConditions}
              <CustToolTip
                title={
                  <Box>
                    <ol
                      style={{
                        padding: "10px 40px",
                        listStyleType: "decimal",
                      }}
                    >
                      {termTooltip?.map((list: any, index: any) => (
                        <li
                          style={{ padding: "5px", lineHeight: "20px" }}
                          key={index}
                          dangerouslySetInnerHTML={{ __html: list.label }}
                        ></li>
                      ))}
                    </ol>
                  </Box>
                }
                onClick={() => setIsTooltip((prev) => !prev)}
                placement="top"
                open={isTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
              >
                <img
                  src={termCondition.data.attributes.url}
                  alt={termCondition.data.attributes.alternativeText}
                  width={18}
                  height={18}
                  style={{ marginLeft: "5px" }}
                />
              </CustToolTip>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
}

export default GlobalRoamingPack;
