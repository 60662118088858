export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const namePattern = /^[A-Za-z ]*$/;
export const numberInStringPattern = /\d/;
export const passwordPattern = /^(?=.*\d)[\w@.!$%^*(<)>?/#&+-]{8,}$/;
export const PhoneNoPattern = /^[0-9]+$/;
export const numberAlphabetPattern = /^[a-zA-Z0-9]+$/;
export const digitsOnlyPattern = /^\d+$/;
export const digitsWithOptionalDecimalPattern = /^\d*\.?\d*$/;
export const addressDisplayPattern = /,(\s*\d{5})$/;
export const nameAllowSpace = /^[A-Za-z]+( [A-Za-z]+)*$/;