import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  phoneUnlockContent: any;
  errorPhoneUnlockContent: any;
  BFMAContent: any;
  errorBFMAContent: any;
  givePlanContent: any;
  errorGivePlanContent: any;
  BWMContent: any;
  errorBWMContent: any;
  UsageContent: any;
  errorDataUsageContent: any;
  outOfDataContent: any;
  errorOutOfDataContent: any;
  manageContent:any;
  errorManageContent:any;
};

const initialState: InitialState = {
  phoneUnlockContent: null,
  errorPhoneUnlockContent: null,
  BFMAContent: null,
  errorBFMAContent: null,
  givePlanContent: null,
  errorGivePlanContent: null,
  BWMContent: null,
  errorBWMContent: null,
  UsageContent: null,
  errorDataUsageContent: null,
  outOfDataContent: null,
  errorOutOfDataContent: null,
  manageContent:null,
  errorManageContent:null
};

// Generates pending, fulfilled and rejected action types
export const fetchPhoneModalContent = createAsyncThunk(
  "myAccountPage/fetchPhoneModalContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${StrapiApiEndPoints.phoneUnlockModal}`,
      isStrapiApi: true,
    });
  }
);

export const fetchBFContent = createAsyncThunk(
  "myAccountPage/fetchBFContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.MyAccount.BestFit.template}${StrapiApiEndPoints.mabf}`,
      isStrapiApi: true,
    });
  }
);

export const fetchgivePlanContent = createAsyncThunk(
  "myAccountPage/fetchgivePlanContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.MyAccount.GiveBack.template}${StrapiApiEndPoints.giveBack}`,
      isStrapiApi: true,
    });
  }
);
export const fetchBWMContent = createAsyncThunk(
  "myAccountPage/fetchBWMContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.MyAccount.App.template}${StrapiApiEndPoints.bwm}`,
      isStrapiApi: true,
    });
  }
);
export const dataUsageContent = createAsyncThunk(
  "myAccountPage/dataUsageContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.MyAccount.App.template}${StrapiApiEndPoints.dataUsage}`,
      isStrapiApi: true,
    });
  }
);

export const getOutOfDataContent = createAsyncThunk(
  "myAccountPage/getOutOfDataContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.OutOfData.template}${StrapiApiEndPoints.outOfData}`,
      isStrapiApi: true,
    });
  }
);

export const manageContentSlice = createAsyncThunk(
  "myAccountPage/manageContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.ManageLines.template}${StrapiApiEndPoints.manageLines}`,
      isStrapiApi: true,
    });
  }
);

const MyAccountSlice = createSlice({
  name: "myAccountPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchPhoneModalContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.phoneUnlockContent = action.payload.data.data.attributes;
        state.errorPhoneUnlockContent = null;
      }
    );
    builder.addCase(fetchPhoneModalContent.rejected, (state, action) => {
      state.phoneUnlockContent = null;
      state.errorPhoneUnlockContent = action.error || "Something went wrong";
    });
    builder.addCase(
      fetchBFContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.BFMAContent = action.payload;
        state.errorBFMAContent = null;
      }
    );
    builder.addCase(fetchBFContent.rejected, (state, action) => {
      state.BFMAContent = {};
      state.errorBFMAContent = action.error || "Something went wrong";
    });
    builder.addCase(
      fetchgivePlanContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.givePlanContent = action.payload?.data?.data?.attributes;
        state.errorGivePlanContent = null;
      }
    );
    builder.addCase(fetchgivePlanContent.rejected, (state, action) => {
      state.givePlanContent = {};
      state.errorGivePlanContent = action.error || "Something went wrong";
    });

    builder.addCase(
      fetchBWMContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.BWMContent = action.payload?.data?.data?.attributes;
        state.errorBWMContent = null;
      }
    );
    builder.addCase(fetchBWMContent.rejected, (state, action) => {
      state.BWMContent = {};
      state.errorBWMContent = action.error || "Something went wrong";
    });
    builder.addCase(
      dataUsageContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.UsageContent = action.payload?.data?.data?.attributes;
        state.errorDataUsageContent = null;
      }
    );
    builder.addCase(dataUsageContent.rejected, (state, action) => {
      state.UsageContent = {};
      state.errorDataUsageContent = action.error || "Something went wrong";
    });
    builder.addCase(
      getOutOfDataContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.outOfDataContent = action.payload?.data?.data?.attributes;
        state.errorOutOfDataContent = null;
      }
    );
    builder.addCase(getOutOfDataContent.rejected, (state, action) => {
      state.outOfDataContent = null;
      state.errorOutOfDataContent = action.error || "Something went wrong";
    });
    
    builder.addCase(manageContentSlice.fulfilled, (state, action: PayloadAction<any>) => {
      state.manageContent = action.payload?.data?.data?.attributes;
      state.errorManageContent = null;
    });
    builder.addCase(manageContentSlice.rejected, (state, action) => {
      state.manageContent ={};
      state.errorManageContent = action.error ||  "Something went wrong";
    });
  },
});

export default MyAccountSlice.reducer;
