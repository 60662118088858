import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiEndPoints from "../../config/ApiEndPoints";
import apiJunction from "../../adapters/api";

type InitialState = {
  profileDetails : {
    firstName: string;
    lastName: string;
    address: any;
    email: string;
    date: string;
    phoneNumber: string;
    newEmail: string;
    fullAddress: String;
    customerDetails: any;
  }
};
const initialState: InitialState = {
  profileDetails: {
    firstName: "",
    lastName: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    fullAddress: "",
    email: "",
    date: "",
    phoneNumber: "",
    newEmail: "",
    customerDetails: {}
  }
};

export const verifyCustomerEmail = createAsyncThunk(
  "profileDetails/verifyCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.customerDetails}${action.username}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
)

const profileDetailsSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    customerData: (state, action) => {
      state.profileDetails.firstName = action.payload.firstName;
      state.profileDetails.lastName = action.payload.lastName;
      state.profileDetails.phoneNumber = action.payload.phoneNumber;
      state.profileDetails.email = action.payload.email;
      state.profileDetails.newEmail = action.payload.newEmail;
      state.profileDetails.fullAddress = action.payload.fullAddress;
      state.profileDetails.address = action.payload.address;
      state.profileDetails.customerDetails = action.payload.customerDetails
    },
  },
});

export const { customerData } = profileDetailsSlice.actions;

export default profileDetailsSlice.reducer;