import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";
type InitialState = {
  walletData: any;
  errorWalletData: any;
};
const initialState: InitialState = {
  walletData: null,
  errorWalletData: null,
};
// Generates pending, fulfilled and rejected action types
export const walletContent = createAsyncThunk(
    "wallet/walletContent",
    () => {
      return apiJunction.makeRequest({
        method: "get",
        url: `/${Config.Settings.SettingsCards.template}${StrapiApiEndPoints.wallet}`,
        isStrapiApi: true,
      });
    }
);
const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
        walletContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.walletData = action.payload?.data?.data?.attributes;
        state.errorWalletData = null;
      }
    );
    builder.addCase(walletContent.rejected, (state, action) => {
      state.walletData = null;
      state.errorWalletData = action.error || "Something went wrong";
    });
  },
});
export default walletSlice.reducer;
