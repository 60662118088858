import { Box, Typography } from '@mui/material'
import { textAlign } from '@mui/system';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getDataUsageFailureContent } from '../../../features/strapi/postActivationHomeSlice';
import { addMultipleCacheData, getCache, useNonInitialEffect } from '../../../utils/commonFunctions';

type Props = {
  rowAlignment ?: boolean;
  textRightAlign ?: boolean;
  forLines?: boolean;
  alignItemsTop?: boolean
};

export const DataUsageFailure = ({rowAlignment=false,textRightAlign,forLines,alignItemsTop}:Props) => {
  const [cacheContent,setChacheContent] = useState(null);
  const {dataUsageFailure} = useAppSelector((state:any)=>state.strapi.postActivation || {})
   const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("dataUsageFailure",getDataUsageFailureContent , setChacheContent, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("dataUsageFailure", dataUsageFailure);
  }, [dataUsageFailure]);
  let {icon,dsc} = cacheContent || dataUsageFailure || {};
  return (
    <Box display="flex" flexDirection={rowAlignment?"row":"column"} justifyContent="center" alignItems={alignItemsTop?"flex-start":"center"} mb={forLines?"0px":"10px"}>
      <Box
        my = {forLines?"1px":"10px"}
        component="img"
        alt={"image"}
        height={forLines?"14px":"30px"}
        width={forLines?"14px":"30px"}
        src={icon?.data?.attributes?.url || ""}
      />
      <Box fontSize={forLines?"10px":"16px"} width={forLines?"142px":"270px"} fontWeight="var(--font_weight_5)" color="var(--grey_color_1)" fontFamily="var(--font_family_Bold)" textAlign={textRightAlign?"right":"center"}>
        <Box fontFamily="var(--font_family_Bold)">{dsc}</Box>
        </Box>
    </Box>
  );
}

