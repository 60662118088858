import { Box, Link, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CustomerStatus } from "../../enums";
import { customerDataCutOff } from "../../features/account/accountSlice";
import { convertDataFromBytes } from "../../utils/commonFunctions/ConvertDataFromBytes";
import { displayPhoneNumberFormat } from "../../utils/commonFunctions/ReusableFunctions";
import { ReachModalMain } from "../postActivation/Account/ReachModal/ReachModal";
import { NotificationCust } from "../widgets";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../Types/GoogleAnalytics";
import { DataUsageFailure } from "../widgets";
import ApnNote from "../apnSettings/Apn_Note";

interface ISecondaryLinesDataUsage {
  groupUser?: any;
  user?: any;
  isSharablePlan?: boolean | undefined;
  isCurrentDataUsage?: boolean;
  primaryCustomer: any;
  callBackGetCustomerGroup: any;
  content: any;
  isCardExpired: any;
  handleBuyData?: any;
}
enum DialogTypes {
  pauseData = "PAUSE_DATA",
  reEnableData = "RE_ENABLE_DATA",
}

export const SecondaryLinesDataUsage = ({
  groupUser,
  user,
  isSharablePlan,
  isCurrentDataUsage,
  primaryCustomer,
  callBackGetCustomerGroup,
  content,
  handleBuyData,
  isCardExpired,
}: ISecondaryLinesDataUsage) => {
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const { errorCustomerBillCycleDataUsage, customerBillCycleDataUsage } =
    useAppSelector((state: any) => state.customer);
  const [load, setLoad] = useState(false);
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<DialogTypes>(
    DialogTypes.pauseData
  );
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;
  const replaceNameInContent = (content: string) => {
    return content ? content.replace("{name}", user?.firstName) : "";
  };
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };
  const dispatch = useAppDispatch();
  const dialogContent =
    dialogType === DialogTypes.pauseData
      ? {
          title: replaceNameInContent(content?.p_title),
          desc: replaceNameInContent(content?.p_desc),
          btn_txt: content?.p_btn_txt,
          userIcon: content?.user_ico?.data?.attributes?.url,
        }
      : {
          title: replaceNameInContent(content?.re_title),
          desc: replaceNameInContent(content?.re_desc),
          btn_txt: content?.re_btn_txt,
          userIcon: content?.user_ico?.data?.attributes?.url,
        };
  const handleOpen = (dialogType: DialogTypes) => {
    setDisplayDialog(true);
    setDialogType(dialogType);
  };
  const handleClose = () => {
    setDisplayDialog(false);
    setDialogType(DialogTypes.pauseData);
  };
  const pauseDataHandler = () => {
    setLoad(true);
    GAAndFBEventsHandler(gaCategory.dataUsage, dialogContent?.btn_txt);
    dispatch(
      customerDataCutOff({
        dataCutOff: !groupUser.addedDataCutoff,
        primaryId: primaryCustomer.id,
        customerId: user?.userId,
      })
    )
      .then((response: any) => {
        if (response?.payload?.status === 200) {
          setLoad(false);
          handleClose();
          callBackGetCustomerGroup();
          NotificationCust({
            message: groupUser.addedDataCutoff
              ? replaceNameInContent(content?.dre_s_ms)
              : replaceNameInContent(content?.dp_s_ms),
            duration: 2000,
            type: "info",
            id: "DataCutOffNotifier",
          });
        } else {
          setLoad(false);
        }
      })
      .catch((err: Error) => {
        if (err) setLoad(false);
      });
  };
  const getDataUsagePercentage = () => {
    return (parseInt(user?.bytes) / parseInt(user?.maxBytes)) * 100 || 0;
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          direction: "row",
          justifyContent: "space-between",
          marginY: "20px",
        }}
        width={EnvConfig?.NO_EXTRA_LINES ? "60%" : "100%"}
      >
        <Box display={"flex"} textAlign={"left"}>
          {getDataUsagePercentage() >= 90 ? (
            <Box
              component="img"
              mr={"10px"}
              alignSelf={"center"}
              src={content?.alert_user_ico?.data?.attributes?.url}
              alt={content?.alert_user_ico?.data?.attributes?.alternativeText}
            />
          ) : (
            <Box
              component="img"
              mr={"10px"}
              alignSelf={"center"}
              src={content?.user_happy?.data?.attributes?.url}
              alt={content?.user_happy?.data?.attributes?.alternativeText}
            />
          )}
          <Box textAlign={"left"} fontSize={"14px"} lineHeight={"1.14"}>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "var(--font_family_Semibold)",
                lineHeight: "1.14",
                pb: "5px",
              }}
            >
              {user?.firstName}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "1.14",
                fontFamily: "var(--font_family_Medium)",
                color: "var(--grey_shade)",
                pb: "5px",
              }}
            >
              {user?.phoneNumber || user?.reachNumber
                ? displayPhoneNumberFormat(
                    user?.phoneNumber || user?.reachNumber
                  )
                : "-"}
            </Typography>
            <Box sx={{ fontWeight: "bold" }}>
              <Link
                underline="none"
                sx={{ p: 0, color: "var(--primary_color)" }}
              >
                {!isSharablePlan ? (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "1.14",
                        fontFamily: "var(--font_family_Medium)",
                        color: "var(--grey_shade)",
                        pb: "5px",
                      }}
                    >
                      {groupUser?.model}
                    </Typography>
                  </Box>
                ) : content?.act_txt_1 &&
                  getCustomer?.isPrimary &&
                  isCurrentDataUsage &&
                  [CustomerStatus.ACTIVE, CustomerStatus.HOTLINED].includes(
                    groupUser.status
                  ) ? (
                  <Box>
                    <img
                      src={content?.settings_ico?.data?.attributes?.url}
                      alt={
                        content?.settings_ico?.data?.attributes?.alternativeText
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "1.14",
                        display: "inline",
                        padding: "0px 3px",
                        cursor: "pointer",
                        fontWeight: "var(--font_weight_2)",
                        fontFamily: "var(--font_family_Semibold)",
                      }}
                      onClick={() => {
                        handleOpen(
                          groupUser?.addedDataCutoff
                            ? DialogTypes.reEnableData
                            : DialogTypes.pauseData
                        );
                        GAAndFBEventsHandler(
                          gaCategory.dataUsage,
                          groupUser?.addedDataCutoff
                            ? content?.act_txt_1
                            : content?.act_txt_2
                        );
                      }}
                    >
                      {groupUser?.addedDataCutoff
                        ? content?.act_txt_1
                        : content?.act_txt_2}
                    </Typography>
                  </Box>
                ) : null}
              </Link>
            </Box>
          </Box>
        </Box>

        <Box textAlign={"right"} alignSelf={"center"}>
          {errorCustomerBillCycleDataUsage ||
          (customerBillCycleDataUsage &&
            !Object.keys(customerBillCycleDataUsage).length) ? (
            <DataUsageFailure
              rowAlignment={true}
              textRightAlign={true}
              forLines={true}
              alignItemsTop={true}
            />
          ) : (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "var(--font_weight_1)",
                fontFamily: "var(--font_family_Bold)",
              }}
            >
              {isSharablePlan ? (
                <>
                  {convertDataFromBytes(user?.bytes)}
                  <br />
                  {primaryCustomer &&
                    groupUser?.addedDataCutoff &&
                    (groupUser.status === CustomerStatus.ACTIVE ||
                      groupUser.status === CustomerStatus.HOTLINED) && (
                      <Link
                        underline="none"
                        sx={{
                          color: "var(--honey)",
                          fontSize: "14px",
                          "&:hover": {
                            color: "var(--honey)",
                          },
                          fontFamily: "var(--font_family_Semibold)",
                        }}
                      >
                        {content?.dp}
                      </Link>
                    )}
                </>
              ) : (
                <>
                  {user?.bytes <= user?.maxBytes
                    ? `${convertDataFromBytes(
                        user?.bytes
                      )}/${convertDataFromBytes(user?.maxBytes)}`
                    : `${convertDataFromBytes(
                        user?.maxBytes
                      )}/${convertDataFromBytes(user?.maxBytes)}`}
                  {!isCardExpired &&
                    getDataUsagePercentage() >= 90 &&
                    currentdetails?.isPrimary &&
                    isCurrentDataUsage &&
                    (groupUser.status === CustomerStatus.ACTIVE ||
                      groupUser.status === CustomerStatus.HOTLINED) && (
                      <Typography
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "14px",
                          fontWeight: "var(--font_weight_2)",
                          textAlign: "right",
                          color: "var(--primary_color)",
                          cursor: "pointer",
                          marginTop: "5px",
                        }}
                        onClick={() => handleBuyData(groupUser?.id)}
                      >
                        {content?.bd_btn_txt}
                      </Typography>
                    )}
                </>
              )}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "start" },
          marginTop: { xs: "20px" },
        }}
      >
        {EnvConfig?.IS_APN_SETTINGS_ENABLED && groupUser?.os && (
          <ApnNote
            imeiData={{
              os: groupUser?.os ?? "",
              osVer: groupUser?.osVer ?? 0,
            }}
            isShowAsPill={true}
            currentLine={groupUser}
          />
        )}
      </Box>
      <ReachModalMain
        load={load}
        content={dialogContent}
        displayDialog={displayDialog}
        handleClose={handleClose}
        user={user}
        onClickButton={pauseDataHandler}
      />
    </>
  );
};
