import { Box, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import styles from "./S2_PaymentStatus.module.scss";
import EnvConfig from "../../../../config/env.config.json";
import { getResolutionDetails } from "../../../../utils/commonFunctions";

const ActivationPaymentStatus = (props: any) => {
  const { payLoader, isPaymentSuccess, handleNextStep, content } = props;
  const { mobile } = getResolutionDetails();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            flexBasis: { md: "50%" },
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            p: { md: "36px 0px 36px 80px", xs: "36px 20px 20px" },
          }}
        >
          <Box>
            <Box
              component="img"
              sx={{ width:  { xs: "100%", sm: `${isPaymentSuccess? "478px": "706px"}` }}}
              alt={content?.pay_in_prg?.data?.attributes?.alternativeText}
              src={
                (!mobile) ? (payLoader
                  ? content?.pay_in_prg?.data?.attributes?.url
                  : isPaymentSuccess
                  ? content?.pay_success?.data?.attributes?.url
                  : content?.pay_fail?.data?.attributes?.url) :
                  (payLoader
                    ? content?.pay_prg_m?.data?.attributes?.url
                    : isPaymentSuccess
                    ? content?.pay_success_m?.data?.attributes?.url
                    : content?.pay_fail_m?.data?.attributes?.url)
              }
            ></Box>
          </Box>
        </Box>
        <Box
          className={styles.right_div}
          sx={{
            flexBasis: { md: "50%" },
            p: { lg: "16px", sm: "40px", xs: "20px" },
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            {payLoader ? (
              <Box>
                <Box>
                  <Typography
                    className={styles.payment_status}
                    sx={{ fontSize: { xs: "22px", sm: "30px" } }}
                  >
                    {content?.pay_in_prg_txt}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography
                      className={styles.payment_description}
                      sx={{ fontSize: { xs: "15px", sm: "18px" } }}
                    >
                      {content?.pay_in_prg_desc}
                    </Typography>
                    <Box className={styles.loader_spin}></Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mb: { lg: "48px", xs: "32px" },
                  }}
                >
                  <Box
                    sx={{ height: "40px", width: "40px" }}
                    component="img"
                    src={
                      isPaymentSuccess
                        ? content?.pay_success_icon?.data?.attributes?.url
                        : content?.pay_fail_icon?.data?.attributes?.url
                    }
                  />
                  <Box mx="1.5rem">
                    <Typography
                      className={styles.payment_status}
                      sx={{ fontSize: { xs: "22px", sm: "30px" } }}
                    >
                      {isPaymentSuccess
                        ? content?.pay_success_txt
                        : content?.pay_fail_txt}
                    </Typography>
                    <Typography
                      className={styles.payment_description}
                      sx={{ fontSize: { xs: "15px", sm: "18px" } }}
                    >
                      {isPaymentSuccess
                        ? content?.pay_success_desc
                        : content?.pay_fail_desc}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "start" },
                  }}
                >
                  <ButtonCust
                    sx={{
                      width: { sm: "228px !important" },
                      fontWeight: "var(--font_weight_4) !important",
                      fontSize: {
                        sm: "20px !important",
                        xs: "15px !important",
                      },
                      height: "50px !important",
                    }}
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    onClick={handleNextStep}
                  >
                    {isPaymentSuccess ? content?.pay_success_btn : content?.pay_fail_btn}
                  </ButtonCust>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ActivationPaymentStatus;
