import { useEffect, useRef, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { HmTPContent } from "../../../features/strapi/homePageSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
export type HmtpProps = {
    content?: any;
};
const HmtpComponent = isTemplateExisits(Config.Home.TrustPilot)?
    require(`./${Config.Home.TrustPilot.template}_HomeTp`).default:null;

export const Hmtp = () => {
    const [ content, setContent] = useState<any>(null);
    const { tpContent } = useAppSelector(
        (state: any) => state.strapi.homepage
    );
    const dispatch = useAppDispatch();
    const initialRender1 = useRef(true);
    useEffect(() => {
        if (initialRender1.current) {
            initialRender1.current = false;
            dispatch(HmTPContent());
        } else {
        let content = tpContent?.data?.data?.attributes;
            setContent(content);
        }
    }, [tpContent]);
    return (
        content && <HmtpComponent content={content} />
    );
};