import { Box } from "@mui/material";
import { useLocation } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { RoutingURLs } from "../../config/RoutingURLs";
import { disconnectedProps } from "./Disconnected";
import styles from "./S1_Disconnected.module.scss";

const T1_Disconnected = (props: disconnectedProps) => {
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const {content} = props || {};
  const location = useLocation();
  const getPadding = () => {
    if (location.pathname.includes(RoutingURLs.account)) {
      return "0px"
    }else {
      return {
        xs: "calc(var(--mobile_section_padding)) 0 calc(var(--mobile_section_padding)/2)",
        sm: "calc(var(--ipad_section_padding)) 0 calc(var(--ipad_section_padding)/2)",
        md: "calc(var(--desktop_section_padding)) 0 calc(var(--desktop_section_padding)/2)"
      }
    }
  }
  return (
    <Box
      className={styles.disconnect_container}
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      justifyContent="center"
      p={getPadding}
    >
     {location.pathname.includes(RoutingURLs.postActivationHome) && <Box
        component="img"
        src={content?.cross_icon?.data?.attributes?.url}
        alt={content?.cross_icon?.data?.attributes?.alternativeText}
        sx={{ mb: "10px", width: "39px", height: "39px" }}
      />}
      <Box
        className={location.pathname.includes(RoutingURLs.account) ? styles.disconnect_text_account : styles.disconnect_text}
        dangerouslySetInnerHTML={{
          __html: `${getCustomer?.billDue ? content?.disconnected_txt1 : content?.disconnected_txt2}`,
        }}
      ></Box>
    </Box>
  );
};
export default T1_Disconnected;
