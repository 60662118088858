import { HomeBannerProps } from "./HomeBanner";
import { Box, Grid, Paper, Typography, Skeleton, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import Styles from "./S2_HomeBanner.module.scss";
import config from "../../../config/env.config.json";
import clsx from "clsx";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import { CustomerStatus as CS } from "../../../enums/CustomerStatus";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const HomeBannerT2 = (props: HomeBannerProps) => {
  const {
    heading,
    sub_heading,
    button_text,
    banner_web,
    banner_mobile,
    banner_ipad,
    button_text2,
    list_items,
    heading2,
    color_gradiant,
  } = props?.bannerContent || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  const { pageName } = props;
  const { content_carousel, ticks_main_div, list_item_txt, button2 } = Styles;
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const { status } = customerDetails || {};
  const grid_wrapper = {
    position: "absolute",
    textAlign: { xs: "center", sm: "center", md: "center" },
    color: { xs: "var(--primary_brand_color)", sm: "inherit" },
    transform: {
      xs: "translate(-50%, -50%)",
      sm: "translateY(-50%)",
      md: "translate(-50%, -50%)",
    },
    top: "50%",
    left: { xs: "50%", sm: "40px", md: "50%" },
  };
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: "relative",
      }}
    >
      {banner_web || banner_ipad || banner_mobile || color_gradiant ? (
        <>
          <Paper
            sx={{
              padding: 0,
              boxShadow: "unset",
              background: color_gradiant,
              minHeight: { xs: "300px", md: "300px" },
            }}
            elevation={0}
          />
          <Grid
            sx={{
              position: "absolute",
              textAlign: {
                // xs: "var(--main_banner_title_f_align_mobile)",
                xs: "center",
              },
            }}
            className={content_carousel}
          >
            <Grid item>
              <Box>
                {heading && (
                  <Typography
                    component="h1"
                    variant="h3"
                    gutterBottom
                    className="main_banner_title"
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(heading),
                    }}
                    sx={{color:"white"}}
                  />
                )}
                {heading2 && (
                  <Typography
                    component="h3"
                    variant="h3"
                    gutterBottom
                    className="main_banner_title"
                    sx={{color:"white"}}
                  >
                    {BrandNameUpdate(heading)}
                  </Typography>
                )}
                {sub_heading && (
                  <Typography
                    variant="h4"
                    component="h4"
                    className={clsx(ticks_main_div, "main_banner_sub_title")}
                    sx={{color:"white"}}
                  >
                    {BrandNameUpdate(sub_heading)}
                  </Typography>
                )}
                {list_items?.length > 0 ? (
                  <Box width={"100%"} pb={{ xs: "15px", md: "25px" }}>
                    {list_items.map((item: any, index: any) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            p: "10px 0px 0px",
                          }}
                        >
                          {item?.image?.data?.attributes?.url ? (
                            <Box
                              component={"img"}
                              alt={BrandNameUpdate(
                                item?.image?.data?.attributes?.alternativeText
                              )}
                              src={item?.image?.data?.attributes?.url}
                              sx={{ width: "16px" }}
                            />
                          ) : null}
                          <Box className={list_item_txt}>{item?.offer}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : null}
                {button_text && (
                  <ButtonCust
                    variantType={config.PRIMARY_BUTTON_TYPE}
                    onClick={props.onButton1Click}
                    sx={{ position: "relative" }}
                    disabled={
                      pageName === "PostActivationHome" &&
                      status !== CS.ACTIVE &&
                      status !== CS.SUSPENDED
                        ? true
                        : false
                    }
                  >
                    {button_text}
                  </ButtonCust>
                )}
                {button_text2 && (
                  <Box
                    className={button2}
                    sx={{
                      // display: "flex",
                      cursor: "pointer",
                      // justifyContent: { xs: "center" },
                      // width: { xs: "max-content" },
                      position: { sm: "relative" },
                      // left: { sm: "6%" },
                    }}
                  >
                    <Box
                      component="span"
                      sx={{ fontFamily: "inherit" }}
                      onClick={() => props.onButton2Click()}
                    >
                      {button_text2}
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: { xs: "500px", sm: "300px", md: "300px", lg: "500px" },
          }}
        >
          <Stack spacing={1} width="70%">
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
          </Stack>
        </Box>
      )}
    </Container>
  );
};

export default HomeBannerT2;
