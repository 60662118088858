import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import Config from "../../config/app.config.json";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";

type InitialState = {
  ISContent: any;
  errorISContent: any;
};
const initialState: InitialState = {
  ISContent: null,
  errorISContent: null,
};

// Generates pending, fulfilled and rejected action types
export const getISContent = createAsyncThunk(
  "internationalService/getStrapiContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${Config.InternationalServices.InternationalRoaming.template}${StrapiApiEndPoints.internationalServices}`,
      isStrapiApi: true,
    });
  }
);

const internationalService = createSlice({
  name: "internationalService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getISContent.rejected, (state, action) => {
      state.ISContent = null;
      state.errorISContent = action.error || "Something went wrong";
    });
    builder.addCase(getISContent.pending, () => {});
    builder.addCase(
      getISContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.ISContent = action.payload.data.data.attributes;
        state.errorISContent = null;
      }
    );
  },
});

export default internationalService.reducer;
