import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import {
  inviteFriends,
  inviteFriendsHandler,
  ButtonCust,
} from "../../../../widgets";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import Config from "../../../../../config/env.config.json";

type Props = {
  content: any;
};

export const TotalCredits = ({ content }: Props) => {
  let invite_text = content?.invite_txt
    ?.replace(
      "{referee_amount}",
      GetCurrencyFixedToTwo(Config.REFEREE_DISC_IN_DOLLAR)
    )
    .replace(
      "{referrer_amount}",
      GetCurrencyFixedToTwo(Config.REFERRER_DISC_IN_DOLLAR)
    );
  useEffect(() => {
    inviteFriends();
  }, []);
  return (
    <Grid
      item
      md={12}
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0px 0px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "var(--font_family_Medium)",
          fontWeight: "var(--font_weight_1)",
          fontSize: "14px",
          padding: "0px 0px 15px",
        }}
      >
        {content?.tc_ttl}
      </Typography>
      <Typography
        sx={{
          fontFamily: "var(--font_family)",
          fontWeight: "var(--font_weight_4)",
          fontSize: "26px",
          padding: "0px 0px 35px",
        }}
      >
        {content?.credits}
      </Typography>
      <Typography
        sx={{
          fontFamily: "var(--font_family_Semibold)",
          fontWeight: "var(--font_weight_2)",
          fontSize: "14px",
          padding: "0px 0px 25px",
        }}
      >
        {invite_text}
      </Typography>

      <ButtonCust
        variantType="primary_outlined_rounded"
        sx={{ width: "147px", height: "45px", padding: "13px 23px" }}
        onClick={() => inviteFriendsHandler()}
      >
        {content?.btn_txt}
      </ButtonCust>
    </Grid>
  );
};
