import Config from "../../config/app.config.json";
import { useState, useEffect } from "react";
import firebase from "../../adapters/firebase";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchDiscoverContent } from "../../features/strapi/discoverSlice";
import { GetCustomer } from "../../features/checkout/checkoutSlice";
import { sortByKey } from "../../utils/commonFunctions/SortByKey";
import { CustomerStatus } from "../../enums";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../config/env.config.json";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";

const DiscoverSection = isTemplateExisits(Config.Discover.DiscoverSection)
  ? require(`./${Config.Discover.DiscoverSection.template}_Discover`).default
  : null;

enum DiscoverCategories {
  Mobilehandset = "Mobile handset",
  SubscriptionServices = "Subscription Services",
  Other = "Other",
  SmartDevice = "Smart Device",
}

export type DiscoverCardProps = {
  discoverButtonHandler?: any;
  disabled?: any;
  discoverCardText?: any;
  value?: any;
  image: any;
  buttonText: any;
  name: string;
  url: string;
  title?: string;
  index?: number;
  content?: any;
};

export type DiscoverProps = {
  finalData?: any;
  content?: any;
  discoverButtonHandler: (url: string, value: any) => any;
};

export const DiscoverComponent = () => {
  const [all, setAll] = useState<any>([]);
  const [active, setActive] = useState<any>([]);
  const [inactive, setInactive] = useState<any>([]);
  const [inprogress, setInprogress] = useState<any>([]);
  const [prepurchase, setPrepurchase] = useState<any>([]);
  const [finalData, setFinalData] = useState<any>([]);
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { discoverContent } = useAppSelector(
    (state: any) => state.strapi.discover
  );

  const dispatch = useAppDispatch();

  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const { simPaid, status } = getCustomer || {};
  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.discover,
      category: categoryName,
      buttonValue: value,
    });
  };

  let mPrior: number = 0,
    sPrior: number = 0,
    oPrior: number = 0,
    dPrior: number = 0;
  let discoverData: any = [],
    mobileHandset: any = [],
    subscription: any = [],
    other: any = [],
    smartDevice: any = [];

  useEffect(() => {
    let user1: any = localStorage.getItem("currentuser");
    if (user1) {
      user1 = JSON.parse(user1);
      let email: string = user1 && user1.email ? user1.email : "";
      email && dispatch(GetCustomer({ username: encodeURIComponent(email) }));
    }
    fetchData();
    getCache("Discover", fetchDiscoverContent, setCacheContent, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("Discover", discoverContent);
  }, [discoverContent]);

  useEffect(() => {
    if (simPaid) {
      if (status === CustomerStatus.INACTIVE) {
        discoverData = all.concat(inactive);
      } else if (
        status === CustomerStatus.ACTIVATION_IN_PROGRESS ||
        status === CustomerStatus.PORTING_IN_PROGRESS
      ) {
        discoverData = all.concat(inprogress);
      } else if (status === CustomerStatus.ACTIVE) {
        discoverData = all.concat(active);
      }
    } else {
      discoverData = all.concat(prepurchase);
    }

    mobileHandset = discoverData.filter((item: any) => {
      if (item.category === DiscoverCategories.Mobilehandset) {
        mPrior = item.categoryPriority;
        return item;
      } else {
        return null;
      }
    });
    subscription = discoverData.filter((item: any) => {
      if (item.category === DiscoverCategories.SubscriptionServices) {
        sPrior = item.categoryPriority;
        return item;
      } else {
        return null;
      }
    });
    other = discoverData.filter((item: any) => {
      if (item.category === DiscoverCategories.Other) {
        oPrior = item.categoryPriority;
        return item;
      } else {
        return null;
      }
    });
    smartDevice = discoverData.filter((item: any) => {
      if (item.category === DiscoverCategories.SmartDevice) {
        dPrior = item.categoryPriority;
        return item;
      } else {
        return null;
      }
    });

    setFinalData([
      {
        title: DiscoverCategories.SmartDevice,
        priority: dPrior,
        length: smartDevice.length,
        data: smartDevice,
      },
      {
        title: DiscoverCategories.Other,
        priority: oPrior,
        length: other.length,
        data: other,
      },
      {
        title: DiscoverCategories.SubscriptionServices,
        priority: sPrior,
        length: subscription.length,
        data: subscription,
      },
      {
        title: DiscoverCategories.Mobilehandset,
        priority: mPrior,
        length: mobileHandset.length,
        data: mobileHandset,
      },
    ]);
  }, [all, active, inactive, inprogress, prepurchase, simPaid, status]);

  const fetchData = async () => {
    const remoteConfig: any = firebase.remoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    await remoteConfig.fetchAndActivate();

    const all = remoteConfig.getValue("app_discover_section_all");
    const active = remoteConfig.getValue("app_discover_section_active");
    const inactive = remoteConfig.getValue("app_discover_section_inactive");
    const inprogress = remoteConfig.getValue("app_discover_section_inprogress");
    const prepurchase = remoteConfig.getValue(
      "app_discover_section_prepurchase"
    );
    //Need to check below functionality in future if any requirement based on params
    // let searchParams = new URLSearchParams(this.props.location.search);
    // let id: any = searchParams.get('id');
    // if (id) {
    //     let paramsData: any = JSON.parse(all._value).find((item: any) => item.id === id);
    //     paramsData &&
    //         this.setState({
    //             paramsData: paramsData,
    //         });
    // }
    setAll(JSON.parse(all._value));
    setActive(JSON.parse(active._value));
    setInactive(JSON.parse(inactive._value));
    setInprogress(JSON.parse(inprogress._value));
    setPrepurchase(JSON.parse(prepurchase._value));
  };

  const discoverButtonHandler = (url: any, value: any) => {
    window.open(url);
    GAAndFBEventsHandler(gaCategory.discover, value);
  };

  const sortedFinalData = sortByKey(finalData, "priority", "length");

  return (
    (cacheContent || discoverContent) && (
      <DiscoverSection
        finalData={sortedFinalData}
        discoverButtonHandler={discoverButtonHandler}
        content={cacheContent ? cacheContent : discoverContent}
      />
    )
  );
};
