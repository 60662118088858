import { Box, Typography } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import styles from "./EsimPaymentSuccess.module.scss";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

const EsimPaymentSuccess = (props: any) => {
  const {
    open,
    onClose,
    activateUser,
    openSupportModal,
    actBtnLoader,
    content,
  } = props;
  const esimPayContent = content?.esim_pay_dlg;
  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      className={styles.esim_dialog}
      showClose={true}
      sx={{
        width: { xs: "95%", sm: "550px !important" },
        borderRadius: "4.8px",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography pb="20px" variant="h4">
          {esimPayContent?.hdg}
        </Typography>
        <img
          src={esimPayContent?.fail_icon?.data?.attributes?.url}
          alt={esimPayContent?.fail_icon?.data?.attributes?.alternativeText}
          style={{ width: "50px", height: "50px" }}
        />
        <Typography
          sx={{
            color: "var(--danger)",
            fontFamily: "var(--font_family_Medium)",
          }}
          textAlign="center"
          p="20px"
        >
          {BrandNameUpdate(esimPayContent?.sub_hdg)}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          pb="15px"
        >
          <Typography
            sx={{ fontFamily: "var(--font_family_Bold)", fontSize: "16px" }}
          >
            {esimPayContent?.status_txt}
          </Typography>
          <Typography
            sx={{
              color: "var(--danger)",
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {esimPayContent?.status}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          pb="25px"
        >
          <Typography
            sx={{ fontFamily: "var(--font_family_Bold)", fontSize: "16px" }}
          >
            {esimPayContent?.bill_date_txt}
          </Typography>
          <Typography>{esimPayContent?.date}</Typography>
        </Box>
        <ButtonCust
          style={{ marginBottom: "20px" }}
          onClick={activateUser}
          loading={actBtnLoader}
        >
          {esimPayContent?.retry}
        </ButtonCust>
        <Typography
          className={styles.customer_service_text}
          onClick={openSupportModal}
        >
          {esimPayContent?.contact}
        </Typography>
      </Box>
    </SimpleModal>
  );
};

export default EsimPaymentSuccess;
