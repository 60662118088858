import { useEffect, useState } from "react";
import Config from "../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../utils";
import { apnSettingsContent } from "../../features/strapi/apnSettingsSlice";
import { Skeleton } from "@mui/material";
import { getConfigKeys } from "../../features/activation/activationSlice";

const ApnNoteComponent = isTemplateExisits(Config.ApnSettings.ApnNote)
  ? require(`./${Config.ApnSettings.ApnNote.template}_Apn_Note`).default
  : null;

const ApnNote = ({
  imeiData,
  isShowAsPill,
  currentLine,
  index,
  closeCompatModal,
  closeCompImeiModal
}: {
  imeiData?: any;
  isShowAsPill: boolean;
  currentLine?: any;
  index?: number;
  closeCompatModal?: any;
  closeCompImeiModal?: any;
}) => {
  const [content, setContent] = useState<any>(null);
  const apnRes = useAppSelector(
    (state: any) => state?.strapi?.apnSettings || {}
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    getCache("apnSettingsContent", apnSettingsContent, setContent, dispatch);
  }, []);
  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("apnSettingsContent", apnRes);
  }, [apnRes]);

  if (apnRes.apnContentLoading) {
    return (
      <>
        <Skeleton variant="text" width={200} height={30} />
      </>
    );
  }

  return (
    <>
      <ApnNoteComponent
        imeiData={imeiData}
        content={content ? content : apnRes}
        isShowAsPill={isShowAsPill}
        currentLine={currentLine}
        index={index}
        closeCompatModal={closeCompatModal}
        closeCompImeiModal={closeCompImeiModal}
      />
    </>
  );
};
export default ApnNote;
