import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiEndPoints from "../../config/ApiEndPoints";
import apiJunction from "../../adapters/api";
import { IBillCyclePayload, ICustomerState } from "./Types";

const initialCustomerState: ICustomerState = {
  customerUsageData: null,
  errorCustomerUsageData: null,
  customerBillCycleDataUsage: null,
  errorCustomerBillCycleDataUsage: null,
};

export const getCustomerDataUsageById = createAsyncThunk(
  "customer/getCustomerDataUsageById",
  async (userId: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.currentDataUsage}${userId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerBillCycleDataUsage = createAsyncThunk(
  "customer/getCustomerBillCycleDataUsage",
  async (payload: IBillCyclePayload, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.billCycleDataUsage}${payload.userId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState: initialCustomerState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomerDataUsageById.pending, (state) => {
      state.customerUsageData = null;
      state.customerBillCycleDataUsage = null;
      state.errorCustomerUsageData = null;
    });
    builder.addCase(
      getCustomerDataUsageById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerUsageData = action.payload.data.data;
        state.errorCustomerUsageData = null;
      }
    );
    builder.addCase(getCustomerDataUsageById.rejected, (state, action) => {
      state.customerUsageData = null;
      state.errorCustomerUsageData = action.error || "Something went wrong";
    });
    builder.addCase(getCustomerBillCycleDataUsage.pending, (state) => {
      state.customerBillCycleDataUsage = null;
      state.errorCustomerUsageData = null;
    });
    builder.addCase(
      getCustomerBillCycleDataUsage.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerBillCycleDataUsage = action.payload.data.data;
        state.errorCustomerBillCycleDataUsage = null;
      }
    );
    builder.addCase(getCustomerBillCycleDataUsage.rejected, (state, action) => {
      state.customerBillCycleDataUsage = null;
      state.errorCustomerBillCycleDataUsage =
        action.error || "Something went wrong";
    });
  },
});

export default customerSlice.reducer;
