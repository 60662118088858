import { Box, Typography } from "@mui/material";
import {
  ButtonCust,
  LinearProgressBarCust,
  ManageCard,
  TextFieldCust,
} from "../../../widgets";
import Config from "../../../../config/env.config.json";
import styles from "./S2_ActivationStep3.module.scss";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ActivationPayNow from "./PayNow";
import ActivationPaymentStatus from "./PaymentStatus";
import { gaCategory } from "../../../../Types/GoogleAnalytics";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getCC } from "../../../../features/account/accountSlice";
import AddNewCardLoader from "./AddNewCardLoader";

const ActivationStep3 = (props: any) => {
  const {
    handleStep,
    currentLine,
    numChangeHandler,
    content,
    GAAndFBEventsHandler,
    formik,
    displayContent,
    loader,
    payNowConfirm,
    payLoader,
    handleBack,
    paymentSuccess,
    backToActivation,
    setPaymentDetails,
  } = props;
  const [showCardChangeModal, setShowCardChangeModal] = useState(false);
  const [showContent, setShowContent] = useState("main");
  const [displayAddCardLoader, setDisplayAddCardLoader] =
    useState<boolean>(false);

  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});
  const dispatch = useAppDispatch();
  const manageCardFg = () => {
    setShowContent("manage");
    const customerDetail: any = JSON.parse(
      localStorage?.getItem("customerDetail") || "null"
    );
    let addCardActivation = localStorage.getItem("addCardActivation");
    if (Config.DISPLAY_ADD_NEW_CARD_LOADER_DIALOG && addCardActivation) {
      setDisplayAddCardLoader(true);
      setTimeout(() => {
        dispatch(getCC({ customerId: customerDetail?.id }))
          .then(() => setDisplayAddCardLoader(false))
          .catch((err) => setDisplayAddCardLoader(false));
      }, 30000);
    }
  };
  useEffect(() => {
    if (displayContent === "main") {
      setShowContent("main");
    } else if (displayContent === "pay") {
      setShowContent("pay");
    } else if (displayContent === "paymentStatus" || paymentSuccess) {
      setShowContent("paymentStatus");
    }
  }, [displayContent]);

  const handleNextStep = () => {
    if (paymentSuccess) {
      handleStep(true);
      setPaymentDetails(5);
    } else {
      setShowContent("pay");
    }
  };
  return (
    <>
      <Box display="flex" alignItems="center" pt={{ xs: "25px", sm: "50px" }}>
        <ArrowBackIcon
          sx={{
            ml: { md: "30px", sm: "25px", xs: "4%" },
            mr: "20px",
            cursor: "pointer",
          }}
          className={styles.arrow_icon}
          onClick={handleBack}
        />
        <Typography variant="h3" fontSize="30px !important">
          {content?.sp3_main_title}
        </Typography>
      </Box>
      {showContent === "main" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column" },
          }}
        >
          <Box
            sx={{
              flexBasis: { md: "50%" },
              display: "flex",
              justifyContent: { xs: "center", md: "start" },
              p: { md: "72px 0px 50px 100px", xs: "50px 20px 0px 20px" },
            }}
          >
            <Box>
              <Box
                component="img"
                sx={{ width: { xs: "100%", sm: "478px" } }}
                src={content?.act_img?.data?.attributes?.url}
                alt={content?.act_img?.data?.attributes?.alternativeText}
              ></Box>
            </Box>
          </Box>
          <Box
            sx={{
              flexBasis: { md: "50%" },
              p: { sm: "16px 0 50px 96px", xs: "25px" },
            }}
          >
            <Typography
              className={styles.title}
              sx={{
                fontSize: { xs: "22px", sm: "30px" },
                m: " 20px 0 15px !important",
                textAlign: { sm: "left", xs: "center" },
              }}
            >
              {content?.sp3_title}
            </Typography>
            <Typography
              className={styles.description}
              sx={{
                fontSize: { xs: "18px" },
                textAlign: { sm: "left", xs: "center" },
                fontFamily: "var(--font_family_Medium)",
              }}
            >
              {content?.step_txt}
            </Typography>

            <Box
              sx={{
                width: { md: "50%", sm: "400px", xs: "100%" },
                m: "20px 0px 25px",
              }}
              className={styles.progress_bar_div}
            >
              <LinearProgressBarCust value={75} variant={"determinate"} />
            </Box>
            <>
              <Typography className={styles.sub_heading}>
                {content?.sim_check?.desc}
              </Typography>
              <Box sx={{ my: "20px", width: { xs: "100%", sm: "50%" } }}>
                <TextFieldCust
                  value={formik.values.simId}
                  name="simId"
                  onChange={(e: any) => numChangeHandler(e, formik)}
                  type={"text"}
                  label={content?.sim_check?.sim_ph}
                  maxlength={Config?.MAX_ALLOWED_SIM_NUMBER_LENGTH}
                  error={formik.errors?.simId ? true : false}
                  helperText={formik.errors?.simId}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter") {
                      formik.handleSubmit();
                    }
                  }}
                />
                <Typography className={styles.limit_div}>
                  {formik.values.simId?.length}/{Config?.MAX_ALLOWED_SIM_NUMBER_LENGTH}
                </Typography>
              </Box>
              <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  sx={{ my: "20px" }}
                  disabled={
                    formik.values.simId?.length === 0 ? true : !formik?.isValid
                  }
                  onClick={() => {
                    formik.handleSubmit();
                    GAAndFBEventsHandler(gaCategory.activation, "next");
                  }}
                  loading={loader}
                >
                  {content?.sim_check?.check_btn}
                </ButtonCust>
              </Box>
            </>
          </Box>
        </Box>
      )}
      {showContent === "pay" && (
        <ActivationPayNow
          payNowConfirm={payNowConfirm}
          payBtnLoader={payLoader}
          manageCardFg={manageCardFg}
          content={content}
          {...props}
        />
      )}
      {showContent === "paymentStatus" && (
        <ActivationPaymentStatus
          payLoader={payLoader}
          content={content}
          isPaymentSuccess={paymentSuccess}
          handleNextStep={handleNextStep}
        />
      )}
      {showContent === "manage" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
            }}
          >
            <Box
              sx={{
                flexBasis: { md: "50%" },
                display: "flex",
                justifyContent: { xs: "center", md: "start" },
                p: { md: "72px 0px 50px 100px", xs: "50px 20px 0px 20px" },
              }}
            >
              <Box>
                <Box
                  component="img"
                  sx={{ width: { xs: "100%", sm: "478px" } }}
                  src={content?.act_img?.data?.attributes?.url}
                  alt={content?.act_img?.data?.attributes?.alternativeText}
                ></Box>
              </Box>
            </Box>
            <Box sx={{ my: "20px", width: { xs: "100%", sm: "70%" } }}>
              <AddNewCardLoader
                display={displayAddCardLoader}
                content={content}
              />
              <ManageCard
                cardData={getCCDetails}
                showCardChangeModal={showCardChangeModal}
                setShowCardChangeModal={setShowCardChangeModal}
                isModalRequired={false}
                pageName={"activation"}
                isDescription={false}
                payType={true}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "var(--primary_color)",
                  fontFamily: " var(--font_family_Semibold)",
                  fontWeight: "var(--font_weight_4)",
                  fontSize: "16px",
                  marginTop: "25px",
                  pr: { md: "60px", xs: "40px" },
                }}
                onClick={backToActivation}
              >
                {content?.back_to}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ActivationStep3;
