import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { missionsContent } from "../../../features/strapi/aboutUsSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const MissionsComponent = isTemplateExisits(Config.AboutUs.Missions)
  ? require(`./${Config.AboutUs.Missions.template}_Missions`).default
  : null;
export type MissionProps = {
  content?: any;
};
export const Missions = () => {
  const [cacheMissions, setCacheMissions] = useState<any>(null);
  const { missionContent } = useAppSelector(
    (state: any) => state.strapi.aboutUs
  );
  const dispatch = useAppDispatch();  
  useEffect(() => {
    getCache("MissionsAboutUs", missionsContent, setCacheMissions, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData(
      "MissionsAboutUs",
      missionContent
    );
  }, [missionContent]);

  return (missionContent || cacheMissions) ? (
    <MissionsComponent content={cacheMissions ? cacheMissions : missionContent} />
  ) : null;
};
