import { Grid } from "@mui/material";
import { useRef, useEffect } from "react";
import { useScript } from "usehooks-ts";
declare global {
  interface Window {
    Trustpilot: any;
  }
}
export enum TrustPilotType {
  REVIEW,
  LIST,
  FOOTER,
}
export const TrustPilot = (props: any) => {
  const trustBoxRef = useRef(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, []);
  return (
    <Grid container>
      <Grid item xs={12}>
        {props.type === TrustPilotType.REVIEW && (
          <>
            <div
              ref={trustBoxRef}
              className="trustpilot-widget firstTrustpilot"
              data-locale="en-US"
              data-template-id="539adbd6dec7e10e686debee"
              data-businessunit-id="5ed6777fd223df0001eabe5e"
              data-style-height="500px"
              data-style-width="100%"
              data-theme="light"
              data-stars="1,2,3,4,5"
              data-review-languages="en"
            >
              <a
                href="https://www.trustpilot.com/review/reachmobile.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trustpilot
              </a>
            </div>

            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="56278e9abfbbba0bdcd568bc"
              data-businessunit-id="5ed6777fd223df0001eabe5e"
              data-style-height="52px"
              data-style-width="100%"
            >
              <a
                href="https://www.trustpilot.com/review/reachmobile.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trustpilot
              </a>
            </div>
          </>
        )}
        {props.type === TrustPilotType.LIST && (
          <div
            ref={trustBoxRef} // We need a reference to this element to load the TrustBox in the effect.
            className="trustpilot-widget"
            id="trustpilot_widget"
            data-locale="en-US"
            data-template-id="54ad5defc6454f065c28af8b"
            data-businessunit-id="5ed6777fd223df0001eabe5e"
            data-style-height="240px"
            data-style-width="100%"
            data-theme="dark"
            data-stars="4,5"
            data-review-languages="en"
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-allow-robots="true"
          >
            <a
              href="https://www.trustpilot.com/review/example.com"
              target="_blank"
              rel="noopener"
            >
              {" "}
              Trustpilot
            </a>
          </div>
        )}
        {props.type === TrustPilotType.FOOTER && (
          <div
            ref={trustBoxRef}
            id="footerTrustpilot"
            className="trustpilot-widget footerTrustpilot"
            data-locale="en-US"
            data-template-id="5419b732fbfb950b10de65e5"
            data-businessunit-id="5ed6777fd223df0001eabe5e"
            data-style-height="24px"
            data-style-width="100%"
            data-theme="light"
            // style={{ display: 'none' }}
          >
            <a
              href="https://www.trustpilot.com/review/reachmobile.com"
              rel="noopener noreferrer"
              target="_blank"
              // rel="noopener"
            >
              Trustpilot
            </a>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
