import { Box, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import styles from "./S2_ActivationIsMnp.module.scss";
import Config from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

const ActivationIsMnp = (props: any) => {
  const { currentLine, redirection, step4Content = {} } = props;
  const { btn, content, subTitle1, subTitle2, title } =
    step4Content?.act_isMNP || {};
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            flexBasis: { md: "50%" },
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            p: { md: "72px 0px 50px 100px", xs: "50px 20px 0px 20px" },
          }}
        >
          <Box>
            <Box
              component="img"
              sx={{ width: { xs: "100%", sm: "478px" } }}
              src={step4Content?.act_img?.data?.attributes?.url}
              alt={step4Content?.act_img?.data?.attributes?.alternativeText}
            ></Box>
          </Box>
        </Box>
        <Box
          className={styles.right_div}
          sx={{
            flexBasis: { md: "50%" },
            p: { sm: "16px 0 50px 96px", xs: "20px 35px" },
          }}
        >
          <Box sx={{ textAlign: { md: "left", xs: "center" } }}>
            <Typography
              className={styles.title}
              sx={{
                fontSize: { xs: "22px", sm: "30px" },
                m: { sm: " 20px 0 15px !important", xs: "25px autopx 0px" },
              }}
            >
              {title}
            </Typography>
            <Typography
              className={styles.description}
              sx={{
                fontSize: { xs: "15px", sm: "18px" },
                m: { sm: "5px 0px 25px", xs: "20px 20px 25px" },
              }}
            >
              {subTitle1} {currentLine?.firstName}
              {BrandNameUpdate(subTitle2)}
            </Typography>
          </Box>
          <Box className={styles.step_container}>
            {content?.map((data: any, index: number) => {
              return (
                <Box display={"flex"} pt={"20px"} key={index}>
                  <Box className={styles.step_icon} sx={{ mr: "16px" }}>
                    <Box
                      component={"img"}
                      src={data?.icon?.data?.attributes?.url}
                      alt={data?.icon?.data?.attributes?.alternativeText}
                      sx={{ height: "23px", width: "23px" }}
                    />
                  </Box>
                  <Box className={styles.step_content} sx={{ sm: "16px" }}>
                    <Typography className={styles.step_title} mb="2px">
                      {data?.title}
                    </Typography>
                    <Typography
                      className={styles.step_description}
                      sx={{ width: { sm: "85%" } }}
                    >
                      {BrandNameUpdate(data?.content)}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
            <Box sx={{ py: "30px", textAlign: { xs: "center", sm: "left" } }}>
              <ButtonCust
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => redirection()}
              >
                {btn}
              </ButtonCust>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ActivationIsMnp;
