import { Box, Typography } from "@mui/material";
import styles from "./IRPurchaseHistory.module.scss";

const IRNoOrderHistory = (props: any) => {
  const { no_order_img, no_order_text } = props.content;
  const { no_order_icon } = styles;
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        className={no_order_icon}
        src={no_order_img?.data?.attributes?.url}
      ></Box>
      <Typography
        component="div"
        sx={{
          color: "var(--text-color)",
          fontFamily: "var(--font_family_Medium)",
          fontSize: "46px",
          fontStyle: "normal",
          fontWeight: "var(--font_weight_5)",
          lineHeight: "26px",
          margin: "5px",
          opacity: "0.3",
        }}
      >
        {no_order_text}
      </Typography>
    </Box>
  );
};

export default IRNoOrderHistory;
