import { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CustomerStatus } from "../../../enums";
import { getActivationModals } from "../../../features/strapi/postActivationHomeSlice";
import { NotificationCust } from "../../widgets";

export const UserStatusNotifications = () => {
  const dispatch = useAppDispatch();
  const { activationModals } = useAppSelector(
    (state: any) => state.strapi.postActivation || {}
  );
  const { disconnect1, disconnect2 } = activationModals?.notifications || {}
  useEffect(() => {
    dispatch(getActivationModals());
  }, [])
  const user = JSON.parse(localStorage.getItem('customerDetail') || "null");
  useEffect(() => {
    if (user && activationModals) {
      if (user?.status === CustomerStatus.SUSPENDED  || user?.status === CustomerStatus.HOTLINED) {
        NotificationCust({
          message: activationModals?.notifications?.suspended,
          type: "danger",
          duration: 5000,
          id: "suspendedUser",
          showIcon: true,
        });
      }
      if (user?.status === CustomerStatus.DISCONNECTED) {
        NotificationCust({
          message: `${user.billDue
            ? disconnect1
            : disconnect2
            }`,
          type: "danger",
          duration: 5000,
          id: "disconnecteduser",
          showIcon: true,
        });
      }
    }
    return (() => {
      Store.removeNotification("suspendedUser")
      Store.removeNotification("disconnecteduser")

    })
  }, [activationModals])
  return <></>
}