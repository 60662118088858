import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

type InitialState = {
  settingsData:any;
  errorSettingsData:any;
  languageData:any;
  errorLanguageData:any;
  passwordData:any;
  errorPasswordData:any;
  dataSaverData:any;
  errorDataSaverData:any;
  wifiUpdateData : any;
  errorWifiUpdateData : any;
};
const initialState: InitialState = {
  settingsData : null,
  errorSettingsData: null,
  languageData : null,
  errorLanguageData : null,
  passwordData : null,
  errorPasswordData : null,
  dataSaverData : null,
  errorDataSaverData : null,
  wifiUpdateData : null,
  errorWifiUpdateData : null
};

export const getSettings = createAsyncThunk(
  "settings/getSettings",
  async (customerID: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.getSettings}${customerID}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateLanguage = createAsyncThunk(
    "settings/updateLanguage",
    async (action: any, { rejectWithValue }) => {
      try {
        const response = await apiJunction.makeRequest({
          method: "put",
          url: `${ApiEndPoints.updateLanguage}${action.customerId}`,
          body: action.data,
        });
        return response;
      } catch (err: any) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const resetPassword = createAsyncThunk(
    "settings/resetPassword",
    async (action: any, { rejectWithValue }) => {
      try {
        const response = await apiJunction.makeRequest({
          method: "put",
          url: `${ApiEndPoints.voicemailResetPassword}${action.customerId}`,
          body: action.data,
        });
        return response;
      } catch (err: any) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const dataSaver = createAsyncThunk(
    "settings/dataSaver",
    async (action: any, { rejectWithValue }) => {
      try {
        const response = await apiJunction.makeRequest({
          method: "put",
          url: `${ApiEndPoints.dataSaver}${action.customerId}`,
          body: action.data,
        });
        return response;
      } catch (err: any) {
        return rejectWithValue(err.response.data);
      }
    }
  );
  export const wifiUpdate = createAsyncThunk(
    "settings/wifiUpdate",
    async (action: any, { rejectWithValue }) => {
      try {
        const response = await apiJunction.makeRequest({
          method: "put",
          url: `${ApiEndPoints.wifiUpdate}${action.customerId}`,
          body: action.data,
        });
        return response;
      } catch (err: any) {
        return rejectWithValue(err.response.data);
      }
    }
  );

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.settingsData = action.payload.data;
        state.errorSettingsData = null;
      }
    );
    builder.addCase(getSettings.rejected, (state, action) => {
      state.settingsData = null;
      state.errorSettingsData = action.payload || "Something went wrong";
    });
    builder.addCase(
        updateLanguage.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.languageData = action.payload.data;
          state.errorLanguageData = null;
        }
      );
      builder.addCase(updateLanguage.rejected, (state, action) => {
        state.languageData = null;
        state.errorLanguageData = action.payload || "Something went wrong";
      });
      builder.addCase(
        resetPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.passwordData = action.payload.data;
          state.errorPasswordData = null;
        }
      );
      builder.addCase(resetPassword.rejected, (state, action) => {
        state.passwordData = null;
        state.errorPasswordData = action.payload || "Something went wrong";
      });
      builder.addCase(
        dataSaver.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.dataSaverData = action.payload.data;
          state.errorDataSaverData = null;
        }
      );
      builder.addCase(dataSaver.rejected, (state, action) => {
        state.dataSaverData = null;
        state.errorDataSaverData = action.payload || "Something went wrong";
      });
      builder.addCase(
        wifiUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.wifiUpdateData = action.payload.data;
          state.errorWifiUpdateData = null;
        }
      );
      builder.addCase(wifiUpdate.rejected, (state, action) => {
        state.wifiUpdateData = null;
        state.errorWifiUpdateData = action.payload || "Something went wrong";
      });
  },
});

export default settingsSlice.reducer;
