import { useEffect, useState } from "react";
import Config from "../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { privacyContent } from "../../features/strapi/privacySlice";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";

const PrivacyComponent = isTemplateExisits(Config.Privacy.Policies)
  ? require(`./${Config.Privacy.Policies.template}_privacy`).default
  : null;

export type privacyProps = {
  content?: any;
};

export const PrivacyPolicy = () => {
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { privacyPolicyContent } = useAppSelector(
    (state: any) => state.strapi.privacy
  );
  const dispatch = useAppDispatch();
  useEffect(()=>{
    getCache('Privacy', privacyContent, setCacheContent, dispatch)
  },[])

  useNonInitialEffect(() => {
    addMultipleCacheData('Privacy',  privacyPolicyContent?.data?.data?.attributes)
  }, [ privacyPolicyContent])
  
  return ( cacheContent || privacyPolicyContent) && <PrivacyComponent content={cacheContent?cacheContent: privacyPolicyContent?.data?.data?.attributes} />;
};
