import { useState, useEffect, useRef } from "react";
import { UpdateCardDialog } from "../../postActivation/Billing/ManageCards/UpdateCardDialog";
import { SelectCardDialog } from "../../SelectCardDialog";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { manageCard } from "../../../features/strapi/manageCardSlice";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import {
  autoPayCreditCard,
  deleteCreditCard,
  manageCardsData,
  updateCardExpiry,
} from "../../../features/billing/billingSlice";
import { getCC } from "../../../features/account/accountSlice";
import { SimpleModal } from "../Modal/Modal";
import styles from "./ManageCards.module.scss";
import { useLocation, useNavigate } from "react-router";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { NotificationCust } from "../NotificationCust";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { ButtonCust } from "../Button";
import { Divider } from "../Divider";
import EnvConfig from "../../../config/env.config.json";
import { Typography, Box } from "@mui/material";
import { setCardAsDefault } from "../../../features/billing/billingSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions";
import AppConfig from "../../../config/app.config.json";
import { SwitchCust } from "../SwitchCust";
import PlanData from "../../../config/data.json";
import { CurrentPlanCharges, OperationInProcessState } from "../../../enums";
import { selectCardDialog } from "../../../features/strapi/billingSlice";
import AutoPayCard from "../../checkout/AutoPayCard/AutoPayCard";
import { getAutoPayCContent } from "../../../features/strapi/checkoutSlice";
import { GetCustomer } from "../../../features/checkout/checkoutSlice";

const CardChangeModal = isTemplateExisits(AppConfig.ManageCards)
  ? require(`./${AppConfig.ManageCards.template}_CardChangeModal`).default
  : null;

type Props = {
  cardData?: any;
  payType?: boolean;
  isDescription?: boolean;
  setShowCardChangeModal?: any;
  showCardChangeModal?: any;
  isModalRequired?: any;
  setComparePlansDialog?: any;
  ProceedPayment?: any;
  activeTab?: any;
  setBuyDataDialog?: any;
  setSelectedCard?: any;
  pageName?: string;
  ChangePlanDetails?: any;
  selectedPlan?: any;
  selectedLine?: any;
  isPayConfirm?: boolean;
  activationOptional?: boolean;
  isAutoPay?: boolean;
  getSelectedCard?: any;
};

export const ManageCard = (props: Props) => {
  const dispatch = useAppDispatch();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [toggleAutoPay, setToggleAutoPay] = useState(true);
  const [isSaveCard, setIsSaveCard] = useState(true);
  const [defaultFlag, setDefaultFlag] = useState("");
  const [display, setDisplay] = useState(false);
  const [dialogHeading, setDialogHeading] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState(false);
  const [selectCardData, setSelectCardData] = useState<any>(null);
  const [manageCardDetails, setManageCardDetails] = useState<any>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { type4Tax } = useAppSelector((state: any) => state.account);
  const [addCardDisplay, setAddCardDisplay] = useState(false);
  const [toggle, settoggle] = useState(true);
  const [selectCardCacheContent, setSelectCardCacheContent] =
    useState<any>(null);
  const [autoPayCacheContent, setAutoPayCacheContent] = useState();

  const { manageCardContent } = useAppSelector(
    (state: any) => state.strapi.manageCard
  );
  const { autoPayContent } = useAppSelector(
    (state: any) => state.strapi.checkout
  );
  const autoPayContentState = autoPayCacheContent
    ? autoPayCacheContent
    : autoPayContent;
  let customerObj: any = localStorage.getItem("customerDetail");
  customerObj = JSON.parse(customerObj);
  const [displayAutoPay, setDisplayAutoPay] = useState(false);
  const handleDisplay = (toggle: any) => {
    setToggleAutoPay(toggle);
    setDisplayAutoPay(true);
    selectCloseHandler();
  };
  const handleSmoothPayClose = () => {
    setDisplayAutoPay(true);
    setAddCardDisplay(false);
  };
  const closeDialog = () => {
    setDisplayAutoPay(false);
  };
  const closeNewCardDialog = () => {
    setAddCardDisplay(false);
  };

  const onChangeAutoPayment = (e: any) => {
    setToggleAutoPay(e.target.checked);
    settoggle(e.target.checked);
    if (e.target.checked) {
      setIsSaveCard(true);
    }
  };
  const onChangeSaveCard = (e: any) => {
    if (!toggleAutoPay) {
      setIsSaveCard(e.target.checked);
    }
  };

  const {
    cardData,
    payType,
    isDescription,
    setShowCardChangeModal,
    showCardChangeModal,
    isModalRequired,
    setComparePlansDialog,
    ProceedPayment,
    activeTab,
    setBuyDataDialog,
    setSelectedCard,
    pageName,
    ChangePlanDetails,
    selectedPlan,
    selectedLine,
    isPayConfirm,
    activationOptional,
    isAutoPay,
    getSelectedCard,
  } = props;
  const {
    creditCardData,
    setCardAsDefaultData,
    errorSetCardAsDefaultData,
    paymentData,
  } = useAppSelector((state: any) => state.billing);
  const { selectCardDialogContent } = useAppSelector(
    (state: any) => state.strapi.billing
  );
  const closeHandler = () => {
    setOpenUpdate(false);
  };
  const openHandler = (each: any) => {
    setOpenUpdate(true);
    setSelectCardData(each);
    setShowCardChangeModal(false);
    setSelectedCard && setSelectedCard(each);
  };
  const selectCloseHandler = () => {
    setDisplay(false);
  };
  const ccNumberMasked = creditCardData
    ? creditCardData?.ccNumberMask
    : selectCardData?.ccNumberMask;
  let default_card_msg = (
    selectCardDialogContent || selectCardCacheContent
  )?.default_crd?.replace("{ccNumberMask}", ccNumberMasked);
  const selectOpenHandler = (val: any, each: any, defaultCard?: boolean) => {
    isModalRequired && setShowCardChangeModal(false);
    getSelectedCard && getSelectedCard(each);
    setDisplay(true);
    setDialogHeading(val);
    setDefaultFlag(val);
    setSelectCardData(each);
    setSelectedCard && setSelectedCard(each);
    if (defaultCard) {
      dispatch(
        manageCardsData({
          ...creditCardData,
          ccuuid: each?.uuid,
          setAsDefault: true,
          removeCreditCard: false,
          ccNumberMask: each?.ccNumberMask,
        })
      );
    } else {
      dispatch(
        manageCardsData({
          ...creditCardData,
          ccuuid: each?.uuid,
          setAsDefault: false,
          removeCreditCard: true,
          ccNumberMask: each?.ccNumberMask,
        })
      );
    }
  };
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const getCardsInOrder = (data: any) => {
    return data?.slice().sort((a: any) => (a?.isAutoPay ? -1 : 1));
  };
  const xDescription: any = {
    bill: paymentData?.[0]?.billId,
    cardSaveOptions: {
      saveCard: true,
      autoPay: toggleAutoPay,
      defaultCard: toggleAutoPay,
    },
  };
  let taxAmount: any;
  paymentData &&
    paymentData[0]?.billing?.map((ele: any) => {
      if (
        ele?.title?.toLowerCase() ===
        CurrentPlanCharges?.TAXES_AND_FEE?.toLowerCase()
      ) {
        taxAmount = ele?.amount;
      }
    });
  useEffect(() => {
    getCache("ManageCards", manageCard, setManageCardDetails, dispatch);
    getCache(
      "selectCardDialog",
      selectCardDialog,
      setSelectCardCacheContent,
      dispatch
    );
    if (EnvConfig.IS_AUTOPAY_ENABLED) {
      getCache(
        "autoPayContentCheckout",
        getAutoPayCContent,
        setAutoPayCacheContent,
        dispatch
      );
    }
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("selectCardDialog", selectCardDialogContent);
  }, [selectCardDialogContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("ManageCards", manageCardContent);
  }, [manageCardContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("autoPayContentCheckout", autoPayContent);
  }, [autoPayContent]);
  useNonInitialEffect(() => {
    // update credit card
    if (creditCardData?.isUpdateCard) {
      const selectedCardData = cardData.find((card: any) => {
        return card.uuid === creditCardData?.ccuuid;
      });
      dispatch(
        updateCardExpiry({
          cardId: creditCardData?.ccuuid,
          expiryDate: creditCardData?.expiryDate,
          autoPay: selectedCardData?.isAutoPay,
          defaultCard: selectedCardData?.isDefault,
        })
      )
        .then((res: any) => {
          if (res?.payload?.status === 200) {
            dispatch(getCC({ customerId: customerObj?.id }));
          }
        })
        .catch(() => {
          dispatch(
            manageCardsData({
              ...creditCardData,
              errorExpiryDate: "",
              isUpdateCard: false,
              ccuuid: null,
              expiryDate: null,
              ccNumberMask: "",
            })
          );
        });
    }
    // set as default (autopay) credit card
    else if (creditCardData?.setAsDefault && creditCardData?.isLoading) {
      dispatch(autoPayCreditCard({ cardId: creditCardData?.ccuuid }))
        .then((res: any) => {
          if (res?.payload?.status === 200) {
            dispatch(getCC({ customerId: customerObj?.id }));
          }
          dispatch(
            manageCardsData({
              ...creditCardData,
              ccuuid: null,
              isLoading: false,
              removeCreditCard: false,
              setAsDefault: false,
              ccNumberMask: "",
            })
          );
        })
        .catch(() => {
          dispatch(
            manageCardsData({
              ...creditCardData,
              ccuuid: null,
              isLoading: false,
              removeCreditCard: false,
              setAsDefault: false,
              ccNumberMask: "",
            })
          );
        });
    }
    // remove credit card
    else if (creditCardData?.removeCreditCard && creditCardData?.isLoading) {
      dispatch(deleteCreditCard({ cardId: creditCardData?.ccuuid }))
        .then((res: any) => {
          if (res?.payload?.status === 200) {
            dispatch(getCC({ customerId: customerObj?.id }));
          }
        })
        .catch(() => {
          dispatch(
            manageCardsData({
              ...creditCardData,
              ccuuid: null,
              isLoading: false,
              removeCreditCard: false,
              setAsDefault: false,
              ccNumberMask: "",
            })
          );
        });
    }
  }, [creditCardData]);

  const handleSetDefault = () => {
    setDefaultLoading(true);
    if (manageCardContent?.set_default === defaultFlag) {
      dispatch(
        setCardAsDefault({
          custId: (selectCardData as any)?.uuid,
          isAutoPay: toggleAutoPay,
          isDefault: toggleAutoPay ? 1 : 0,
          status: 0,
        })
      )
        .then((response: any) => {
          if (response.payload.status === 200) {
            dispatch(getCC({ customerId: customerObj?.id }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (formRef.current) {
      formRef.current.submit();
    } else {
      return;
    }
  };

  const addNewCardHandler = () => {
    if (pageName === "account") {
      setShowCardChangeModal(false);
      let data: any;
      if (type4Tax) {
        data = type4Tax;
      } else {
        let local: any = localStorage.getItem("tax");
        local = JSON.parse(local);
        data = local;
      }
      if (activeTab === 2) {
        localStorage.setItem("addCardAccount", "true");
        localStorage.setItem("activeTab", activeTab);
        localStorage.setItem("tax", JSON.stringify(data));
        localStorage.setItem("selectedLine", selectedLine);
      } else if (activeTab === 1) {
        let dataPlan: any;
        if (localStorage.getItem("amount")) {
          let localPlan: any = localStorage.getItem("amount");
          localPlan = JSON.parse(localPlan);
          dataPlan = localPlan;
        } else {
          dataPlan = ChangePlanDetails;
        }
        localStorage.setItem("addCardAccount", "true");
        localStorage.setItem("activeTab", activeTab);
        localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
        localStorage.setItem("amount", JSON.stringify(dataPlan));
      }
    } else if (pageName === "billing") {
      setDefaultFlag(CurrentPlanCharges.ADD_CARD_BILLING);
      localStorage.setItem("addCardBilling", "true");
      localStorage.setItem("customerBill", JSON.stringify(paymentData));
    } else if (pageName === "addlines") {
      localStorage.setItem("addCardManageLines", "true");
    } else if (pageName === "activation") {
      if (activationOptional) {
        localStorage.setItem("oneTimeactivation", "true");
      } else {
        localStorage.setItem("addCardActivation", "true");
      }
    }

    if (EnvConfig.IS_AUTOPAY_ENABLED) {
      setAddCardDisplay(true);
    } else if (formRef.current) {
      setIsLoading(true);
      formRef.current.submit();
    }
  };

  const ManageCardCloseHandler = () => {
    setShowCardChangeModal(false);
    let activeTabSection: any = localStorage.getItem("activeTab");
    let activeSection = JSON.parse(activeTabSection);
    setShowCardChangeModal(false);
    if (pageName === "account") {
      if (activeSection) {
        if (Number(activeSection) === 1) {
          setComparePlansDialog(true);
        } else if (Number(activeSection) === 2) {
          setBuyDataDialog(true);
        }
      } else {
        if (activeTab === 1) {
          setComparePlansDialog(true);
        } else if (activeTab === 2) {
          setBuyDataDialog(true);
        }
      }
    }
  };
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  let transId: any = urlSearchParams.get("transId");
  let resCode: any = urlSearchParams.get("x_response_code");
  useNonInitialEffect(() => {
    const { add_card_noti, add_card_fail, pay_success } =
      manageCardContent || manageCardDetails || {};
    let customerBill: any = localStorage.getItem("customerBill");
    customerBill = customerBill ? JSON.parse(customerBill) : null;
    if (location.pathname === RoutingURLs.billing && transId) {
      NotificationCust({
        message: add_card_noti,
        type: "info",
        duration: 5000,
        id: "AddNewCardSuccess",
        showIcon: true,
      });
      navigate(RoutingURLs.billing);
    } else if (location.pathname === RoutingURLs.billing && resCode) {
      NotificationCust({
        message: add_card_fail,
        type: "danger",
        duration: 5000,
        id: "AddNewCardFailure",
        showIcon: true,
      });
    } else if (
      location.pathname === RoutingURLs.billing &&
      transId &&
      paymentData[0]?.billPaid !== customerBill[0]?.billPaid
    ) {
      NotificationCust({
        message: pay_success,
        type: "info",
        duration: 5000,
        id: "BillPaidSuccess",
        showIcon: true,
      });
      navigate(RoutingURLs.billing);
    }
  }, [manageCardContent, paymentData]);

  useNonInitialEffect(() => {
    setDisplayAutoPay(false);
    setDefaultLoading(false);
    if (setCardAsDefaultData) {
      NotificationCust({
        message: default_card_msg,
        type: "info",
        duration: 5000,
        id: "AddNewCardSuccess",
        showIcon: true,
      });
    } else if (errorSetCardAsDefaultData) {
      NotificationCust({
        message: (selectCardDialogContent || selectCardCacheContent)
          ?.default_crd_fail,
        type: "danger",
        duration: 5000,
        id: "AddNewCardFailure",
        showIcon: true,
      });
    }
  }, [errorSetCardAsDefaultData, setCardAsDefaultData]);

  const refreshCustomer = () => {
    let loggedUser: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    if (loggedUser?.emailId) {
      dispatch(
        GetCustomer({
          username: encodeURIComponent(loggedUser?.emailId),
        })
      );
    }
  };
  useEffect(() => {
    let timer: any;
    if (
      getCustomer?.operationsInProcess.includes(
        OperationInProcessState.paymentCallback
      )
    ) {
      setIsLoading(true);
      timer = setInterval(() => {
        refreshCustomer();
      }, 5000);
    } else {
      setIsLoading(false);
    }
    return () => {
      clearInterval(timer);
    };
  }, [getCustomer]);
  const selectCardBackhandler = () => {
    setDisplay(false);
    setShowCardChangeModal(true);
  };

  const plans: any = PlanData;
  const partnerId: any = plans?.data?.[0]?.ptnrTntId || "";
  return (
    <>
      <form
        ref={formRef}
        method="post"
        name="addCardForm"
        id="addCardForm"
        action={EnvConfig.ADD_CARD_ADDRESS}
      >
        <input
          type="hidden"
          name="emailId"
          value={getCustomer?.emailId ? getCustomer.emailId : ""}
        />
        <input
          type="hidden"
          name="redirectUrl"
          value={
            pageName === "addlines"
              ? `${window.location.origin}/account`
              : `${window.location.origin}/${pageName}`
          }
        />
        {partnerId ? (
          <input type="hidden" name="tenant" value={partnerId} />
        ) : null}
        {EnvConfig.IS_AUTOPAY_ENABLED && (
          <>
            <input type="hidden" name="xDescription" value={xDescription} />
            <input
              type="hidden"
              name="amount"
              value={paymentData?.[0]?.amountToPay}
            />
            <input type="hidden" name="taxAmount" value={taxAmount} />
            <input
              type="hidden"
              name="taxId"
              value={paymentData?.[0]?.biilId}
            />
            <input type="hidden" name="saveCard" value={String(isSaveCard)} />
            <input type="hidden" name="autoPay" value={String(toggleAutoPay)} />
            <input
              type="hidden"
              name="defaultCard"
              value={String(toggleAutoPay)}
            />
          </>
        )}
      </form>
      {isModalRequired ? (
        showCardChangeModal && (
          <SimpleModal
            isShowModal={showCardChangeModal}
            onCloseModal={() => {
              ManageCardCloseHandler();
            }}
            showClose={true}
            style={{ borderRadius: "0.3rem" }}
            sx={{
              width: { xs: "90%", sm: "500px" },
              minWidth: { xs: "90%", sm: "500px" },
              maxWidth: "500px",
              borderRadius: "4.8px",
            }}
            className={styles.manage_cards_dialog}
          >
            <CardChangeModal
              manageCardDetails={
                manageCardDetails ? manageCardDetails : manageCardContent
              }
              isDescription={isDescription}
              getCustomer={getCustomer}
              cardData={cardData}
              payType={payType}
              selectOpenHandler={selectOpenHandler}
              openHandler={openHandler}
              addNewCardHandler={addNewCardHandler}
              getCardsInOrder={getCardsInOrder}
            />
          </SimpleModal>
        )
      ) : (
        <CardChangeModal
          manageCardDetails={
            manageCardDetails ? manageCardDetails : manageCardContent
          }
          isDescription={isDescription}
          getCustomer={getCustomer}
          cardData={cardData}
          payType={payType}
          selectOpenHandler={selectOpenHandler}
          openHandler={openHandler}
          addNewCardHandler={addNewCardHandler}
          getCardsInOrder={getCardsInOrder}
          isLoading={isLoading}
        />
      )}
      {openUpdate && (
        <UpdateCardDialog
          display={openUpdate}
          closeHandler={closeHandler}
          cardData={selectCardData}
          dialogData={manageCardDetails ? manageCardDetails : manageCardContent}
        />
      )}
      {display && (
        <SelectCardDialog
          open={display}
          handleClose={selectCloseHandler}
          cardHeading={dialogHeading}
          cardData={selectCardData}
          dialogData={manageCardDetails ? manageCardDetails : manageCardContent}
          payConfirmHandler={isPayConfirm}
          ProceedPayment={ProceedPayment}
          setShowCardChangeModal={setShowCardChangeModal}
          pageName={pageName}
          backHandler={selectCardBackhandler}
          handleDisplay={handleDisplay}
          selectCardDialogContent={
            selectCardDialogContent
              ? selectCardDialogContent
              : selectCardCacheContent
          }
          isAutoPay={isAutoPay}
          payType={payType}
        />
      )}
      <SimpleModal
        isShowModal={displayAutoPay}
        onCloseModal={closeDialog}
        className={styles.auto_pay_dialog}
        showClose={true}
        sx={{
          borderRadius: "4.8px",
          maxWidth: { xs: "97% !important", sm: "600px !important" },
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" p="20px">
          <Typography className={styles.dialog_heading} mb="10px">
            {toggleAutoPay
              ? manageCardDetails
                ? manageCardDetails?.auto_py_cnsnt
                : manageCardContent?.auto_py_cnsnt
              : manageCardDetails
              ? manageCardDetails?.fle_cnsnt
              : manageCardContent?.fle_cnsnt}
          </Typography>
          <Divider styles={{ margin: "10px 0 25px" }} />
          <Typography className={styles.dialog_desc}>
            {toggleAutoPay
              ? manageCardDetails
                ? manageCardDetails?.ato_cnsnt_dsc
                : manageCardContent?.ato_cnsnt_dsc
              : manageCardDetails
              ? manageCardDetails?.fle_cnsnt_dsc
              : manageCardContent?.fle_cnsnt_dsc}
          </Typography>
          <Box display="flex" alignItems="center" m="20px auto">
            <ButtonCust
              style={{ marginRight: "7px" }}
              onClick={closeDialog}
              variantType={EnvConfig.SECONDARY_BUTTON_TYPE}
            >
              {manageCardDetails
                ? manageCardDetails?.cnc_btn
                : manageCardContent?.cnc_btn}
            </ButtonCust>
            <ButtonCust
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              style={{ marginLeft: "7px" }}
              onClick={handleSetDefault}
              loading={defaultLoading}
            >
              {manageCardDetails
                ? manageCardDetails?.cnt_btn
                : manageCardContent?.cnt_btn}
            </ButtonCust>
          </Box>
        </Box>
      </SimpleModal>
      <SimpleModal
        isShowModal={addCardDisplay}
        onCloseModal={closeNewCardDialog}
        className={styles.auto_pay_dialog}
        showClose={true}
        sx={{
          borderRadius: "4.8px",
          maxWidth: { xs: "97% !important", sm: "550px !important" },
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography className={styles.dialog_heading} mb="10px">
            {manageCardDetails
              ? manageCardDetails?.manage_cards
              : manageCardContent?.manage_cards}
          </Typography>
          <Divider styles={{ margin: "10px 0 25px" }} />

          <Typography className={styles.smooth_tranc_text}>
            {location.pathname === RoutingURLs.billing
              ? manageCardDetails
                ? manageCardDetails?.smooth_tranc
                : manageCardContent?.smooth_tranc
              : manageCardDetails
              ? manageCardDetails?.enable_autopay
              : manageCardContent?.enable_autopay}
          </Typography>
          <Box>
            <AutoPayCard
              isAutoPay={toggleAutoPay}
              isSaveCard={isSaveCard}
              onChangeAutoPayment={onChangeAutoPayment}
              onChangeSaveCard={onChangeSaveCard}
              autoPayContentState={autoPayContentState}
              extraStyle={styles.auto_pay_card}
              saveCardDisable={
                location.pathname === RoutingURLs.billing ? true : false
              }
            />
          </Box>
          <ButtonCust
            onClick={handleSmoothPayClose}
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            sx={{
              alignSelf: "center",
              width: "64px",
            }}
          >
            {manageCardDetails
              ? manageCardDetails?.confirm
              : manageCardContent?.confirm}
          </ButtonCust>
        </Box>
      </SimpleModal>
    </>
  );
};
