import { PlanFeaturesProps } from "./PlanFeatures";
import styles from "./S1_PlanFeature.module.scss";
import { Box, Grid, Typography } from "@mui/material";
const PlanFeaturesComponent = (props: PlanFeaturesProps) => {
  return (
    <Grid
      container
      display="flex"
      justifyContent="space-around"
      sx={{
        p: {
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        },
        minHeight: { xs: "774px", sm: "532px", md: "310px" },
      }}
      className={styles.plan_features}
    >
      {props?.featureContent &&
        props.featureContent?.plan_feature.map((content: any) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            textAlign="center"
            key={content.title}
            alignItems="stretch"
            sx={{ marginBottom: { md: "0px", xs: "31px" }, px: "20px" }}
          >
            <Box
              sx={{ marginBottom: { xs: "31px" } }}
              component="img"
              alt={content?.image?.data?.attributes?.alternativeText}
              src={content?.image?.data?.attributes?.url || ""}
              className={styles.plan_features_image}
              height="78px"
              width="78px"
            ></Box>
            <Typography
              variant="body1"
              className={styles.plan_features_title}
              sx={{ marginBottom: "9px" }}
            >
              {content.title}
            </Typography>
            <Typography
              variant="body2"
              className={styles.plan_features_sub_title}
              sx={{ marginBottom: "0px" }}
            >
              {content.sub_title}
            </Typography>
          </Grid>
        ))}
    </Grid>
  );
};
export default PlanFeaturesComponent;
