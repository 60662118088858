import { Button } from "@mui/material";
import styles from "./HeaderItem.module.scss";

interface IHeaderNavigation {
  children: any;
  onClickItem: any;
  showActiveIndicator?: boolean;
}
export const HeaderNavigateItem = (props: IHeaderNavigation) => {
  const { children, onClickItem, showActiveIndicator } = props;
  return (
    <div
      className={
        showActiveIndicator
          ? `${styles.item_container} ${styles.active_header_item}`
          : styles.item_container
      }
    >
      <Button
        aria-controls="simple-menu"
        className={styles.header_btn_txt}
        aria-haspopup="true"
        onClick={onClickItem}
      >
        {children}
      </Button>
    </div>
  );
};
