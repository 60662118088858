import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { ButtonCust } from "../../../widgets/Button/ButtonCust";
import { calculateCost, calculateUnlimitedCost, PlanProps } from "./PlanCard";
import styles from "./S10_PlanCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useLocation } from "react-router";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { scroller } from "react-scroll";

const T10_PlanCard = (props: PlanProps) => {
  const {
    plandata,
    planContent,
    navigate,
    borderColorAdd,
    tagLabel,
    tagBgColor,
    tagTxtColor,
    selectedLine,
    isSelected,
    planSelectionHandler,
  } = props;

  const onViewDetails = (_: any) => {
    navigate(RoutingURLs.cellPhonePlans);
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }, 100);
  };

  const location = useLocation();

  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    current_plan,
    fade_out,
    save_money_area,
    plan_page_offer,
    hover_color,
    apply_border123,
    home_page_plan_card,
    tag,
    title,
    save_money_img,
    plan_cost_data,
    cost,
    cost1,
  } = styles;
  const {
    button_text,
    per_money_text,
    save_money,
    view_details_text,
    shareable_image,
    icon_offers,
    s_plan_img,
    u_plan_img,
    s_p_text,
    u_p_text,
    hide_feature_item,
  } = planContent || {};

  return planContent && Object.keys(planContent || {}).length > 0 ? (
    <Box className={clsx(cards_div, plans_div)}>
      {plandata.length > 0 &&
        plandata.map((el: any, index: number) => {
          const { planCost } = el?.unlimited
            ? calculateUnlimitedCost(el, selectedLine, false)
            : calculateCost(el, selectedLine);
          const isCurrentPlan =
            details?.reachPlanDisplayName === el?.displayName;
          return (
            <Box
              id={`home${index}`}
              key={index}
              className={clsx(
                home_page_plan_card,
                plan_page_plan_card,
                borderColorAdd ? borderColorAdd : "",
                isCurrentPlan ? current_plan : ""
              )}
            >
              <Box className={styles.top_container}>
                {isCurrentPlan && (
                  <Box sx={{ marginBottom: "8px" }}>
                    {!tagLabel && (
                      <Box
                        className={tag}
                        sx={{
                          backgroundColor: tagBgColor
                            ? tagBgColor
                            : "var(--primary_background)",
                          color: tagTxtColor ? tagTxtColor : "var(--white)",
                        }}
                      >
                        {planContent?.current_plan}
                      </Box>
                    )}
                  </Box>
                )}
                <Box
                  // display="flex"
                  alignItems={"center"}
                  // justifyContent={"space-between"}
                >
                  <Box className={title}>{el.displayName}</Box>
                  {planContent?.res?.isShowPlanData?<Box
                    sx={{
                      fontWeight: 700,
                      lineHeight: "26px",
                      justifyContent: "center",
                      padding: "10px 0px 20px 0px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "26px",
                      }}
                    >
                      {el?.planData}
                    </Box>
                    <Box
                      sx={{
                        fontSize: "18px",
                        lineHeight: "26px",
                      }}
                    >
                      {el?.dataUnit}
                    </Box>
                  </Box>:null}

                  {/* <Box
                    sx={{
                      color: "var(--grey33-color)",
                      fontSize: "15px",
                      fontWeight: "var(--h4_font_weight)",
                    }}
                  >
                    {el?.isUnlimited ? (
                      <>&nbsp;{u_p_text}</>
                    ) : (
                      <>&nbsp;{s_p_text}</>
                    )}
                  </Box> */}
                </Box>
                {planContent?.res?.c_desc ? (
                  <Typography
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontSize: "14px",
                      textAlign: "center",
                      color: "#797979",
                    }}
                  >
                    {planContent?.res?.c_desc}
                  </Typography>
                ) : null}
                <Box
                  className={save_money_area}
                  style={{
                    opacity: el.isBestFitAllowed ? 1 : 0.2,
                    display: "none",
                  }}
                >
                  <Box
                    component={"img"}
                    src={shareable_image?.data?.attributes?.url}
                    alt={shareable_image?.data?.attributes?.alternativeText}
                    className={save_money_img}
                  />
                  {save_money}
                </Box>
                <Box className={plan_cost_data}>
                  <Box component={"span"} className={cost}>
                    {GetCurrencyFixedToTwo(
                      planCost ? planCost : 0,
                      el.currency ? el.currency : Config.CURRENCY
                    )}
                  </Box>
                  {planContent?.per_money_text ? (
                    <Box component={"span"} className={cost1}>
                      {planContent?.per_money_text}
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <Box className={styles.bottom_container}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: "30px",
                    minHeight: "var(--plan_card_bottom_section_min_height)"
                      ? "var(--plan_card_bottom_section_min_height)"
                      : "135px",
                  }}
                >
                  {planContent?.icon_offers &&
                    planContent?.icon_offers.map(
                      (item: any, featureIndex: any) => {
                        const hideItem = hide_feature_item?.find(
                          (sItem: any) =>
                            sItem?.name?.toLowerCase() ===
                            item?.offer?.toLowerCase()
                        );
                        return !hideItem?.indexes?.includes(index) ||
                          !hideItem?.name === item?.offer?.toLowerCase() ? (
                          <Box
                            key={featureIndex}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              p: "10px 0px 0px",
                            }}
                          >
                            <Box>
                              <Box
                                component={"img"}
                                alt=""
                                src={item?.image?.data?.attributes?.url}
                              />
                            </Box>
                            <Box className={plan_page_offer}>{item?.offer}</Box>
                          </Box>
                        ) : null;
                      }
                    )}
                </Box>
                {location.pathname !== RoutingURLs.myAccount && (
                  <Box
                    className={el.maxLines >= selectedLine ? " " : fade_out}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: "10px",
                    }}
                  >
                    <ButtonCust
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      className={el.maxLines >= selectedLine ? " " : fade_out}
                      onClick={(e: any) => {
                        el.maxLines >= selectedLine
                          ? planSelectionHandler(el, button_text)
                          : "";
                      }}
                    >
                      {button_text}
                    </ButtonCust>
                  </Box>
                )}
                {planContent?.res?.p_discount ? (
                  <Typography
                    sx={{
                      mt:
                        location.pathname !== RoutingURLs.myAccount
                          ? "10px"
                          : "0px",
                      fontStyle: "italic",
                      fontFamily: "var(--font_family_Medium)",
                      fontSize: "14px",
                      textAlign: "center",
                      color: "#797979",
                      lineHeight: "22px",
                      width: "217px",
                    }}
                  >
                    {planContent?.res?.p_discount}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          );
        })}
    </Box>
  ) : null;
};

export default T10_PlanCard;
