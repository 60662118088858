export const mapTemplatesToPage: any = (pageObj: object): Array<any> =>
  Object.entries(pageObj)
    .filter((item: any) => {
      return item[1].template;
    })
    .map((item) => {
      return { ...item[1], component: item[0] };
    })
    .sort((a, b) => a.position - b.position);

export const isTemplateExisits = (templateObj: any) => {
  return templateObj.template;
};
