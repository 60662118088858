import { Box, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PhoneNoPattern } from "../../../utils/regexPatterns";
import { SwitchCust, TextFieldCust } from "../../widgets";
import styles from "./SimInHand.module.scss";
import { setSIMVerificationState } from "../../../features/checkout/checkoutSlice";
import EnvConfig from "../../../config/env.config.json";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

type SimInHandProps = {
  simId: any;
  onSubmitSimHandler: any;
  setSimId: any;
  simInHand: any;
  setSimInHand: any;
  simInHandContentState: any;
};

const SimInHand = (props: SimInHandProps) => {
  const {
    simId,
    onSubmitSimHandler,
    setSimId,
    simInHand,
    setSimInHand,
    simInHandContentState,
  } = props;
  const {
    isPendingSIMVerification,
    errorInSIMVerification,
    isSIMVerificationSuccess,
  } = useAppSelector((state: any) => state.checkout);
  const {
    s_h_ttl,
    sim_ph,
    sim_id_lngth,
    f_msg,
    s_msg,
    info,
    arr_dis,
    arr_enbl,
    sim_info,
    err_rfrsh,
    res,
  } = simInHandContentState || {};
  const dispatch = useAppDispatch();
  const currentUser = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const onChangeSimId = (e: any) => {
    if (PhoneNoPattern.test(e.target.value) || e.target.value === "") {
      setSimId(e.target.value);
      dispatch(
        setSIMVerificationState({
          error: false,
          isSuccess: false,
        })
      );
    }
  };
  const onChangeSimInHand = (e: any) => {
    setSimInHand(e.target.checked);
    dispatch(
      setSIMVerificationState({
        error: false,
        isSuccess: false,
      })
    );
  };
  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.inner_container}>
          <Box display="flex" alignItems="center" marginBottom="10px">
            <SwitchCust
              styles={{ mr: "0px" }}
              checked={simInHand}
              handleSwitchToggle={onChangeSimInHand}
              name="simInHand"
            />
            <Box>{BrandNameUpdate(s_h_ttl)}</Box>
            &nbsp;
            <Box className={styles.info}>
              <Box
                component="img"
                src={sim_info?.data?.attributes?.url}
                mt="5px"
              ></Box>
              <div className={styles.info_tooltip_div}>
                <div className={styles.info_tooltip}>{info}</div>
                <div className={styles.arrow_down}></div>
              </div>
            </Box>
          </Box>
          {(isSIMVerificationSuccess && simInHand) ||
          (simInHand && currentUser?.simId && currentUser?.isSimInHand) ? (
            <>
              <Box className={styles.success_msg}>{s_msg}</Box>
              {res?.is_sh_result_line_required ? (
                <Box sx={{margin: "16px 16px 0px 3px"}}>
                  <hr style={{border: "solid 1px #bbbbbb"}}/>
                </Box>
              ) : null}
              {res?.sh_result_node_desc ? (
                  <Box sx={{margin: "16px 0px 0px 3px"}} className={styles.success_msg}>
                    {res?.sh_note_txt}
                    {res?.sh_result_node_desc}
                  </Box>
              ) : null}
            </>
          ) : simInHand ? (
            <>
              <Box
                sx={{ width: { xs: "100%", md: "70%" } }}
                className={styles.input_container}
              >
                <TextFieldCust
                  value={simId}
                  name="inHandSimid"
                  onChange={onChangeSimId}
                  label={sim_ph}
                  maxlength={EnvConfig.MAX_ALLOWED_SIM_NUMBER_LENGTH}
                  sx={{ height: "50px" }}
                  error={
                    errorInSIMVerification &&
                    simId.length === Number(sim_id_lngth)
                  }
                />
                {isPendingSIMVerification ? (
                  <Box className={styles.simid_loader}>
                    <CircularProgress sx={{ color: "var(--white)" }} />
                  </Box>
                ) : (
                  <Box
                    component="img"
                    onClick={
                      simId.length === Number(sim_id_lngth)
                        ? onSubmitSimHandler
                        : null
                    }
                    src={
                      errorInSIMVerification &&
                      simId.length === Number(sim_id_lngth)
                        ? err_rfrsh?.data?.attributes.url
                        : simId.length === Number(sim_id_lngth)
                        ? arr_enbl?.data?.attributes?.url
                        : arr_dis?.data?.attributes?.url
                    }
                  />
                )}
              </Box>
              <Box
                display="flex"
                justifyContent={
                  errorInSIMVerification &&
                  simId?.length === Number(sim_id_lngth)
                    ? "space-between"
                    : "flex-end"
                }
                sx={{ width: { xs: "100%", md: "70%" } }}
              >
                {errorInSIMVerification &&
                  simId?.length === Number(sim_id_lngth) && (
                    <Box className={styles.failure_msg}>{f_msg}</Box>
                  )}
                <Box className={styles.simid_length}>
                  {isSIMVerificationSuccess
                    ? ""
                    : `${simId?.length ? simId?.length : 0}/${sim_id_lngth}`}
                </Box>
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default SimInHand;
