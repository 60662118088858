import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import ENVConfig from "../../../config/env.config.json";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import isEmpty from "lodash/isEmpty";
import { EsimCoverageModalContent } from "../../../features/strapi/homePageSlice";
import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
} from "../../../utils/commonFunctions";
const EsimCoverageModalComponent = isTemplateExisits(
  Config.Home.EsimCoverageCheck
)
  ? require(`./${Config.Home.EsimCoverageCheck.template}_EsimCoverageModal`)
      .default
  : null;

type EsimCoverageProps = {
  coverageModal?: any;
  closeModalHandler?: any;
  checkAddress?: any;
  coverageError?: any;
  coverageMessage?: any;
  coverageAddress?: any;
  checkAddressHandler?: any;
  handleSelect?: any;
  signal4G?: any;
  signal5G?: any;
  checkCoverageHandler?: any;
  checkDisableHandler?: any;
  coverageLoading?: any;
  checkAgain?: any;
  setCoverageModal?: any;
  setShowCompatibilityModal?: any;
};
export interface EsimCoverageComponentProps extends EsimCoverageProps {
  isSuccess?: any;
  fourGlineWidth?: any;
  fiveGlineWidth?: any;
  fourGlineColor?: any;
  fiveGlineColor?: any;
  setIsSuccess?: any;
  esimCoverageContent?: any;
  imeiCheckHandler?: any;
}

export const EsimCoverageModal = (props: EsimCoverageProps) => {
  const { coverageStatus } = useAppSelector((state: any) => state.homepage);
  const [fourGlineWidth, setFourGLineWidth] = useState<string>("100%");
  const [fiveGlineWidth, setFiveGLineWidth] = useState<string>("100%");
  const [fourGlineColor, setFourGLineColor] = useState<string>(
    "var(--primary_brand_color)"
  );
  const [fiveGlineColor, setFiveGLineColor] = useState<string>(
    "var(--primary_brand_color)"
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [showCheckIMEIModal, setShowCheckIMEIModal] = useState(false);
  const dispatch = useAppDispatch();
  const [cacheEsimCoverage, setCacheEsimCoverage] = useState<any>(null);
  const { esimCoverageContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );

  const CoverageStatus = coverageStatus?.data?.data;
  useEffect(() => {
    setIsSuccess(false);
    getCache(
      "EsimCoverageModal",
      EsimCoverageModalContent,
      setCacheEsimCoverage,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("EsimCoverageModal", esimCoverageContent?.attributes);
  }, [esimCoverageContent]);

  useNonInitialEffect(() => {
    if (!isEmpty(coverageStatus)) {
      setIsSuccess(true);
    }
    if (CoverageStatus?.signal4g === "great") {
      setFourGLineWidth("100%");
      setFourGLineColor("var(--primary_brand_color)");
    } else if (CoverageStatus?.signal4g === "good") {
      setFourGLineWidth("80%");
      setFourGLineColor("var(--primary_brand_color)");
    } else if (CoverageStatus?.signal4g === "fair") {
      setFourGLineWidth("60%");
      setFourGLineColor("var(--primary_brand_color)");
    } else if (CoverageStatus?.signal4g === "poor") {
      setFourGLineWidth("40%");
      setFourGLineColor("var(--note_danger)");
    } else {
      setFourGLineWidth("100%");
      setFourGLineColor("var(--note_danger)");
    }
    if (CoverageStatus?.signal5g === "great") {
      setFiveGLineWidth("100%");
      setFiveGLineColor("var(--primary_brand_color)");
    } else if (CoverageStatus?.signal5g === "good") {
      setFiveGLineWidth("80%");
      setFiveGLineColor("var(--primary_brand_color)");
    } else if (CoverageStatus?.signal5g === "fair") {
      setFiveGLineWidth("60%");
      setFiveGLineColor("var(--primary_brand_color)");
    } else if (CoverageStatus?.signal5g === "poor") {
      setFiveGLineWidth("40%");
      setFiveGLineColor("var(--note_danger)");
    } else {
      setFiveGLineWidth("100%");
      setFiveGLineColor("var(--note_danger)");
    }
  }, [coverageStatus]);

  const imeiCheckHandler = () => {
    props.setCoverageModal(false);
    if (props.signal4G) {
      props.setShowCompatibilityModal(true);
    }
  };

  return (
    <>
      {(esimCoverageContent || cacheEsimCoverage) && (
        <EsimCoverageModalComponent
          {...props}
          esimCoverageContent={
            cacheEsimCoverage
              ? cacheEsimCoverage
              : esimCoverageContent?.attributes
          }
          isSuccess={isSuccess}
          fourGlineWidth={fourGlineWidth}
          fiveGlineWidth={fiveGlineWidth}
          fourGlineColor={fourGlineColor}
          fiveGlineColor={fiveGlineColor}
          setIsSuccess={setIsSuccess}
          imeiCheckHandler={imeiCheckHandler}
        />
      )}
    </>
  );
};
