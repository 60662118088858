import EnvConfig from "../../config/env.config.json";

export function updateToHubspot(event: Event, email: string) {
  var isTestObj: any = {};
  isTestObj.is_test = EnvConfig?.IS_HUBSPOT_TEST_ENV;
  isTestObj.is_qa = EnvConfig?.IS_HUBSPOT_QA_ENV;
  var hubObj = { email: email };
  var hubPropObj = Object.assign({}, hubObj, isTestObj);
  var _hsq = ((window as any)._hsq = (window as any)._hsq || []);
  _hsq.push(["identify", hubPropObj]);
  _hsq.push(["trackPageView", "Home"]);
  if (event) {
    _hsq.push([
      "trackEvent",
      {
        id: event,
      },
    ]);
  }
}
