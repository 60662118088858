import { Box } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import EnvConfig from "../../../../config/env.config.json";
import { brandNames } from "../../../../enums";
import { useAppSelector } from "../../../../app/hooks";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import { shopWareRedirectionHandler } from "../../../../utils";

type Props = {
  modalFg: boolean;
  closeHandler?: any;
  content: any;
};

export function IMEIFailureModal(props: Props) {
  const { modalFg, closeHandler, content } = props;
  const { title, desc, try_again, shop_btn_txt } = content?.imei_fail_md || {};
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const getButtonType = () => {
    switch (EnvConfig.DOMAIN) {
      case brandNames.REACH:
        return EnvConfig.PRIMARY_BUTTON_TYPE;
      case brandNames.WOW:
        return "primary_contained_rounded";
      default:
        return EnvConfig.PRIMARY_BUTTON_TYPE;
    }
  };
  return (
    <SimpleModal
      isShowModal={modalFg}
      onCloseModal={closeHandler}
      showClose={true}
      sx={{ width: { xs: "95%", sm: "60%", md: "55%", lg: "50%", xl: "45%" } }}
      style={{ borderRadius: "4px" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        pt={0.5}
        alignItems="center"
      >
        <Box
          sx={{
            fontSize: "22px",
            color: "var(--text_color)",
            fontWeight: "var(--font_weight_4)",
          }}
        >
          {title}
        </Box>
        <Box
          pt={2}
          sx={{
            fontSize: "14px",
            color: "var(--text_color)",
            textAlign: "center",
          }}
        >
          {BrandNameUpdate(desc)}
        </Box>
        <Box
          pt={1}
          sx={{
            fontSize: "14px",
            color: "var(--primary_color)",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={closeHandler}
        >
          {try_again}
        </Box>
        {shop_btn_txt && (
          <Box pt={3}>
            <ButtonCust
              variantType={getButtonType()}
              onClick={() => {
                if (EnvConfig.IS_SHOPWARE_ENABLED) {
                  shopWareRedirectionHandler(EnvConfig?.SHOP_DOMAIN, "home");
                } else {
                  window.open(globalVal?.shopPhone, "_blank");
                }
              }}
            >
              {shop_btn_txt}
            </ButtonCust>
          </Box>
        )}
      </Box>
    </SimpleModal>
  );
}
