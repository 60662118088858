import { Box, Typography } from "@mui/material";
import React from "react";
import { ButtonCust } from "../../../widgets";
import styles from "./PaymentStatus.module.scss";

const PaymentStatus = (props: any) => {
  const { title, description, button, onClick, variantType, icon, isWidth } =
    props;
  return (
    <Box className={styles.main_container}>
      <img
        src={icon?.data?.attributes.url}
        alt={icon?.data?.attributes.alternativeText}
        width={40}
        height={40}
      />
      <Typography className={styles.title}>{title}</Typography>
      <Typography
        className={styles.description}
        sx={{
          width: isWidth ? "750px" : "500px",
        }}
      >
        {description}
      </Typography>
      <Box m={"auto"}>
        <ButtonCust
          variantType={variantType}
          onClick={onClick}
          sx={{
            width: "400px",
          }}
        >
          {button}
        </ButtonCust>
      </Box>
    </Box>
  );
};

export default PaymentStatus;
