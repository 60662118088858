import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./S1_ActivationStep1.module.scss";
import { useAppSelector } from "../../../../app/hooks";
import { IMEIFailureModal } from "./IMEIFailureModal";
import {
  TextFieldCust,
  LinearProgressBarCust,
  ButtonCust,
  ManageCard,
} from "../../../widgets";
import { gaCategory } from "../../../../Types/GoogleAnalytics";
import OneTimeCharge from "./OneTimeCharge";
import { AddShippingAddressDialog } from "../../Account/AddLinesDialog/AddShippingAddressDialog/AddShippingAddressDialog";
import { ChangeLinePreferenceDialog } from "../../../ChangeLinePreferenceDialog/ChangeLinePreferenceDialog";
import { SimTypes } from "../../../../features/checkout/Types";
import Config from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

const ActivationStep1 = (props: any) => {
  const {
    handleStep,
    currentLine,
    numChangeHandler,
    content,
    simPrefenceContent,
    GAAndFBEventsHandler,
    kitBasePrice,
    sendSimOption,
    deviceComp,
    setDeviceComp,
    currentModal,
    setCurrentModal,
    formik,
    btnLoader,
    setBtnLoader,
    modalStatus,
    setModalStatus,
    imei
  } = props;
  const provideEdit: boolean = currentLine?.esim ? true : false;
  const onTimeActivation = localStorage.getItem("oneTimeactivation");
  const getLatestAdd: any = JSON?.parse(
    localStorage.getItem("latestAddress") || "null"
  );
  const [addressData, setAddressData] = useState<any>(() => getLatestAdd);
  const [simType, setSimType] = useState<SimTypes>(SimTypes.sim);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});

  const { imei_view, Iemi_con, imei_form, sp1_title, step_txt, msgs } =
    content || {};

  const changeModal = (val?: string) => {
    setCurrentModal(val ?? "");
  };
  const closeOneTimeCharge = () => {
    changeModal();
    setAddressData(null);
    localStorage.removeItem("latestAddress");
    onTimeActivation && localStorage.removeItem("oneTimeactivation");
    localStorage.removeItem("oneTimeChargeDeliveryType");
  };
  const showCardChangeModalHandler = () => {
    changeModal("oneTimeChrg");
  };
  const ProceedPayment = () => {};
  const changeCard = (obj?: any) => {
    if (obj) {
      localStorage.setItem("latestAddress", JSON.stringify(obj));
      setAddressData(obj);
    }
    changeModal("cardsModal");
  };
  const changeAddress = (obj?: any) => {
    obj && setAddressData(obj);
    changeModal("addressDetails");
  };
  const SaveAddress = (e: any) => {
    changeModal("oneTimeChrg");
    e?.billingAddress && setAddressData(e.billingAddress);
  };
  const chooseModalResult = () => {
    if (simType === SimTypes.sim) {
      changeModal("oneTimeChrg");
    } else {
      handleStep(true);
      changeModal();
    }
    setSimType(SimTypes.eSim);
  };

  return (
    <Box className={styles.step1_main}>
      <Box className={styles.heading_container}>
        <ArrowBackIcon
          sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
          className={styles.arrow_icon}
          onClick={() => handleStep(false)}
        />
        <Typography className={styles.title_main}>{sp1_title}</Typography>
      </Box>
      <Box className={styles.stepper_content}>
        <Typography className={styles.stepper_txt}>{step_txt}</Typography>
        <Box width={{ xs: "90%", sm: "50%", md: "35%" }} mb="25px">
          <LinearProgressBarCust value={25} variant={"determinate"} />
        </Box>
      </Box>
      {!deviceComp ? (
        <>
          <Box width={{ xs: "90%", sm: "65%" }} m="0 auto 40px">
            <Box
              className={styles.inner_container}
              p={{ xs: "30px 15px", sm: "30px 50px" }}
            >
              <Grid item md={5.5}>
                <Typography className={styles.activation_heading}>
                  {BrandNameUpdate(imei_form?.desc)}
                </Typography>
              </Grid>
              <Box
                className={styles.activation_iemi_number}
                width={{
                  md: "50%",
                  sm: "70%",
                  xs: "95%",
                }}
              >
                <TextFieldCust
                  value={formik.values.imei}
                  name="imei"
                  onChange={(e: any) => numChangeHandler(e, formik)}
                  onBlur={formik.handleBlur}
                  type={"text"}
                  label={imei_form?.txt_ph}
                  maxlength={15}
                  error={formik.errors?.imei ? true : false}
                  helperText={formik.errors?.imei}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter") {
                      formik.handleSubmit();
                    }
                  }}
                />
                <Typography className={styles.activation_limit_div}>
                  {formik.values.imei.length}/15
                </Typography>
              </Box>
              <Box className={styles.activation_btn}>
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  disabled={
                    formik.values?.imei.length === 0 ? true : !formik?.isValid
                  }
                  onClick={() => formik.handleSubmit()}
                  loading={btnLoader}
                >
                  {imei_form?.check_btn}
                </ButtonCust>
              </Box>
              <Grid item>
                <Typography className={styles.activation_paid_desc}>
                  {imei_form?.note}
                </Typography>
              </Grid>
            </Box>
          </Box>
          <Box className={styles.activation_bottom}>
            <Box>
              <Typography
                className={styles.activation_bottom_text1}
                sx={{ mb: "5px", fontFamily: " var(--font_family_Semibold)" }}
              >
                {Iemi_con?.title}
              </Typography>
              <Typography
                className={styles.activation_bottom_text1}
                sx={{ fontFamily: " var(--font_family_Medium)" }}
              >
                {Iemi_con?.desc}
              </Typography>
            </Box>

            <Box
              sx={{
                margin: "20px 0 50px 0",
              }}
              width={{ xs: "90%", sm: "fit-content" }}
            >
              {Iemi_con?.iemiContent.map((data: any) => {
                return (
                  <Box
                    className={styles.activation_version_container}
                    mt={"20px"}
                    key={data.desc1}
                  >
                    <img
                      width={23}
                      height={23}
                      className={styles.os_img}
                      src={data.img?.data?.attributes?.url}
                      alt={data.img?.data?.attributes?.alternativeText}
                    />
                    <Box>
                      <Typography className={styles.os_text_1}>
                        {data.desc1}
                      </Typography>
                      <Typography className={styles.os_text_2}>
                        {data.desc2}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </>
      ) : (
        <Box width={{ xs: "90%", sm: "48%" }} m="0 auto 40px">
          <Box
            className={styles.step1_container}
            p={{ xs: "30px 15px", sm: "30px 50px" }}
          >
            <>
              <Typography className={styles.step1_heading}>
                {imei_view?.desc}
              </Typography>
              <Box className={styles.device_text_container}>
                <Box>
                  <Box
                    className={styles.device_container}
                    alignItems={{ xs: "center" }}
                  >
                    <Box
                      component="img"
                      className={styles.device_icon}
                      src={imei_view?.dev_img?.data?.attributes?.url}
                      alt={
                        imei_view?.dev_img?.data?.attributes?.alternativeText
                      }
                    />
                    <Box className={styles.text_container}>
                      <Typography className={styles.text1}>
                        {imei_view?.device}
                      </Typography>
                      <Typography className={styles.text2}>
                        {currentLine?.model}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    className={styles.device_container}
                    alignItems={{ xs: "center" }}
                  >
                    <Box
                      component="img"
                      className={styles.device_icon}
                      src={imei_view?.dev_id_img?.data?.attributes?.url}
                      alt={
                        imei_view?.dev_id_img?.data?.attributes?.alternativeText
                      }
                    />
                    <Box className={styles.text_container}>
                      <Typography className={styles.text1}>
                        {imei_view?.deviceID}
                        {provideEdit && (
                          <Box
                            component="span"
                            className={styles.edit_text}
                            onClick={() => {
                              setDeviceComp(false);
                              GAAndFBEventsHandler(
                                gaCategory.activation,
                                imei_view?.edd_txt
                              );
                            }}
                          >
                            {imei_view?.edd_txt}
                          </Box>
                        )}
                      </Typography>
                      <Typography className={styles.text2}>
                        {currentLine?.imei}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <ButtonCust
                variantType={Config.PRIMARY_BUTTON_TYPE}
                sx={{ mt: "25px" }}
                onClick={() => {
                  handleStep(true),
                    GAAndFBEventsHandler(
                      gaCategory.activation,
                      imei_view?.nxt_btn
                    );
                }}
              >
                {imei_view?.nxt_btn}
              </ButtonCust>
              {Config.SHOW_ACT_IMEI_EDIT_BTN && (
                <Box
                  component="span"
                  className={styles.edit_text}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    setDeviceComp(false);
                    GAAndFBEventsHandler(
                      gaCategory.activation,
                      imei_view?.edd_txt
                    );
                  }}
                >
                  {imei_view?.edd_txt}
                </Box>
              )}
            </>
          </Box>
        </Box>
      )}
      {modalStatus && (
        <IMEIFailureModal
          modalFg={modalStatus}
          closeHandler={() => setModalStatus(false)}
          content={content}
        />
      )}
      {currentModal === "oneTimeChrg" && (
        <OneTimeCharge
          onClose={closeOneTimeCharge}
          changeCard={changeCard}
          changeAddress={changeAddress}
          latestAddress={addressData}
          loaderHandler={setBtnLoader}
          btnLoader={btnLoader}
          currentLine={currentLine}
          content={content}
          sendSimOptions={sendSimOption}
          kitBasePrice={kitBasePrice}
        />
      )}
      {currentModal === "cardsModal" && (
        <ManageCard
          cardData={getCCDetails}
          showCardChangeModal={true}
          setShowCardChangeModal={showCardChangeModalHandler}
          isModalRequired={true}
          pageName={"activation"}
          isDescription={false}
          payType={false}
          ProceedPayment={ProceedPayment}
          isPayConfirm={true}
          setSelectedCard={setSelectedCard}
          activationOptional={true}
        />
      )}
      {currentModal === "addressDetails" && (
        <AddShippingAddressDialog
          open={true}
          onClose={SaveAddress}
          content={content}
          updateAddress={(e: any) => {
            SaveAddress(e);
          }}
          pageName="activation"
        />
      )}

      {currentModal === "chooseType" && (
        <ChangeLinePreferenceDialog
          open={true}
          onClose={closeOneTimeCharge}
          content={simPrefenceContent}
          onConfirm={chooseModalResult}
          simType={simType}
          setSimType={setSimType}
          pageName={"activation"}
          simCost={kitBasePrice+ (currentLine?.selectedShippingMethod?.shippingAmount || 0)}
          isApnNote={true}
          imei={imei}
        />
      )}
    </Box>
  );
};
export default ActivationStep1;
