import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  globalVal: any;
  errorGlobalVal: any;
};

const initialState: InitialState = {
  globalVal: null,
  errorGlobalVal: null,
};

export const globalValContent = createAsyncThunk(
  "globalValues/globalValContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.globalValues}`,
      isStrapiApi: true,
    });
  }
);
const globalValueSlice = createSlice({
  name: "globalValues",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      globalValContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.globalVal = action.payload?.data?.data?.attributes;
        state.errorGlobalVal = null;
        localStorage.setItem(
          "globalValues",
          JSON.stringify(action.payload?.data?.data?.attributes)
        );
      }
    );
    builder.addCase(globalValContent.rejected, (state, action) => {
      state.globalVal = null;
      state.errorGlobalVal = action.error || "Something went wrong";
    });
  },
});

export default globalValueSlice.reducer;
