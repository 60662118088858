
import Config from "../../../config/app.config.json";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { explorePlansStrapiContent } from "../../../features/strapi/homePageSlice";
import { useEffect, useState } from "react";
import { getCache, useNonInitialEffect, addMultipleCacheData } from "../../../utils/commonFunctions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
const ExplorePlansComponent = isTemplateExisits(Config.Home.ExplorePlans)
  ? require(`./${Config.Home.ExplorePlans.template}_ExplorePlans`).default
  : null;
export type explorePlanProps = {
   content? :any ;
}
 
  
export const ExplorePlans = () => {
  const [cacheDataExplorePlans, setCacheDataExplorePlans] = useState<any>(null);
  useEffect(() => {
    getCache("ExplorePlansHome", explorePlansStrapiContent,  setCacheDataExplorePlans, dispatch);
  }, []);
  
  const dispatch = useAppDispatch();
  const { explorePlansContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  
  useNonInitialEffect(() => {
    addMultipleCacheData("ExplorePlansHome", explorePlansContent);
  }, [explorePlansContent]);

  
  return (
    <ExplorePlansComponent content={cacheDataExplorePlans ? cacheDataExplorePlans : explorePlansContent}/>
  );
};