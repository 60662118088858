import { Grid } from "@mui/material";
import styles from "../ReviewPlansDetails/S1_ReviewPlanDetails.module.scss";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";

type Props = {
  estimatedMonthlyBillAmount?: any;
  totalDueAmount?: any;
  content?: any;
  simInHandCheck: any;
};

export const MIASection = (props: Props) => {
  const {
    estimatedMonthlyBillAmount,
    totalDueAmount,
    content,
    simInHandCheck,
  } = props;
  const { your_line } = styles;
  return (
    <Grid
      item
      container
      display="flex"
      sx={{
        justifyContent: { sm: "space-between", xs: "initial", ms: "initial" },
        border: "1px dashed #313131",
        borderRadius: "5px",
        p: "10px",
        m: "20px 0",
      }}
    >
      <Grid item container xs={12} sx={{ pl: "10px" }} className={your_line}>
        <Grid item xs={8} textAlign="left">
          {content?.MIA_l1}
        </Grid>
        <Grid item xs={4} textAlign="right">
          {estimatedMonthlyBillAmount !== 0
            ? GetCurrencyFixedToTwo(estimatedMonthlyBillAmount)
            : GetCurrencyFixedToTwo(0)}
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{ pl: "10px" }} className={your_line}>
        <Grid item xs={8} textAlign="left">
          {content?.MIA_l2}
        </Grid>
        <Grid item xs={4} textAlign="right">
          {simInHandCheck
            ? GetCurrencyFixedToTwo(0)
            : totalDueAmount !== 0
            ? GetCurrencyFixedToTwo(totalDueAmount)
            : GetCurrencyFixedToTwo(0)}
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{
          pl: "10px",
          mt: "10px",
          fontSize: "14px",
          fontFamily: "var(--font_family_Semibold)",
        }}
      >
        <Grid item xs={8} textAlign="left" sx={{ fontFamily: "inherit" }}>
          {content?.MIA_l3}
        </Grid>
        <Grid item xs={4} textAlign="right" sx={{ fontFamily: "inherit" }}>
          {simInHandCheck
            ? GetCurrencyFixedToTwo(estimatedMonthlyBillAmount)
            : GetCurrencyFixedToTwo(
                estimatedMonthlyBillAmount + totalDueAmount
              )}
        </Grid>
      </Grid>
    </Grid>
  );
};
