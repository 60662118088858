import { useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  Divider,
  SliderCust,
  PopperCust,
  ButtonCust,
  TextFieldCust,
} from "../../widgets";
import { useFormik } from "formik";
import { string, object } from "yup";
import {
  emailPattern,
  digitsWithOptionalDecimalPattern,
} from "../../../utils/regexPatterns";
import { PopperContent } from "./PopperContent";
import { formSubmit } from "./formSubmit";
import styles from "./S1_ComparePlans.module.scss";
import EnvConfig from "../../../config/env.config.json";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import getSymbolFromCurrency from "currency-symbol-map";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import plandata from "../../../config/data.json";

type Props = {
  comparePlanFormValues: any;
  submitHandler: any;
  userDetails: any;
  comparePlansData: any;
};
const imgId = "qsMark";

const T1_ComparePlans = (props: Props) => {
  const {
    comparePlanFormValues,
    submitHandler,
    userDetails,
    comparePlansData,
  } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openFg, setOpenFg] = useState<boolean>(false);
  const [amountError, setAmountError] = useState<any>(null);
  const {
    heading,
    sub_heading,
    sec_header1,
    sec_header2,
    sec_header3,
    num_of_lines,
    gb_label,
    gb_placeholder,
    gb_note,
    gb_units,
    c_amt_label,
    c_amt_placeholder,
    email_label,
    email_placeholder,
    email_note,
    name_label,
    name_placeholder,
    btn_name,
  } = comparePlansData;
  const {
    v_email_req,
    v_email_pat,
    v_c_amt_req,
    v_c_amt_min,
    v_c_amt_max,
    v_gb_req,
  } = comparePlansData;
  const qsMarkImg = {
    img: comparePlansData?.qs_mark?.data?.attributes?.url,
    alt: BrandNameUpdate(comparePlansData?.qs_mark?.data?.attributes?.alternativeText),
  };
  const {
    compare_plans,
    label_text,
    section_header,
    line_break,
    input_grp_bill,
    input_group_gb,
    bill_cal_popper,
    qs_mark_size,
  } = styles;
  const maxLinesArray = plandata?.data?.map((el: any) => {
    return el.maxLines;
  });
  const maxLinesCount = Math.max(...maxLinesArray);
  const handleChange = () => {
    formik.handleSubmit();
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.BillCalculator,
      category: gaCategory.comparePlans,
      buttonValue: btn_name,
    });
  };

  const closePopper = () => {
    setAnchorEl(null);
    setOpenFg(false);
  };
  const handlePopper = (e: any) => {
    setAnchorEl(e.target);
    setOpenFg(true);
  };
  const validateBillCalSchema = object({
    dataUsage: string().required(v_gb_req),
    currentAmount: string().required(v_c_amt_req),
    mailID: string().required(v_email_req).matches(emailPattern, v_email_pat),
  });
  const checkHandler = () => {
    formSubmit(submitHandler, formik, userDetails);
  };

  const formik: any = useFormik({
    initialValues: comparePlanFormValues,
    validationSchema: validateBillCalSchema,
    onSubmit: () => {
      checkHandler();
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const sectionBrk = () => {
    return <Box className={line_break} sx={{ mt: 1.5 }}></Box>;
  };
  const numberRestriction = (e: any) => {
    let { value, name } = e.target;
    value = value.replaceAll(getSymbolFromCurrency(EnvConfig.CURRENCY), "");
    if (
      (digitsWithOptionalDecimalPattern.test(value) && Number(value) >= 0) ||
      value === ""
    ) {
      e.target.value = value;
      formik.handleChange(e);
    }
    if (name === "currentAmount") {
      if (parseInt(value) === 0) {
        setAmountError(v_c_amt_min);
      } else if (parseInt(value) > 9999) {
        setAmountError(v_c_amt_max);
      } else {
        setAmountError(null);
      }
    }
  };

  return (
    <>
      <Box>
        <Grid
          container
          display="flex"
          flexDirection="column"
          px={{ md: 20, xs: 3, sm: 6 }}
          className={compare_plans}
          onKeyPress={(e: any) => {
            if (e.key === "Enter" && amountError === null) {
              handleChange();
            }
          }}
        >
          <Box textAlign="center" style={{ padding: "20px 0px 10px" }}>
            <h3>{heading}</h3>
          </Box>
          <Box className="description_text" textAlign="center" pb={1.5}>
            {BrandNameUpdate(sub_heading)}
          </Box>
          <Divider />

          {sec_header1 && (
            <>
              <Box className={section_header} pt={2}>
                {sec_header1}
              </Box>
              {sectionBrk()}
              <Grid
                item
                container
                display="flex"
                justifyContent="space-between"
                pt={{ xs: 3, sm: 4 }}
              >
                <Grid item className={label_text} sm={4} md={5} xs={12}>
                  {num_of_lines}
                </Grid>
                <Grid item sm={6} md={4} xs={12} pt={{ xs: 3, sm: 0 }}>
                  <SliderCust
                    minValue={1}
                    maxValue={maxLinesCount}
                    defaultVal={formik.values.rangeSlider}
                    changeHandler={formik.handleChange}
                    name="rangeSlider"
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Box className={section_header}>{sec_header2}</Box>
          {sectionBrk()}
          <Grid
            container
            item
            display="flex"
            justifyContent="space-between"
            py={{ xs: 3, sm: 4 }}
          >
            <Grid item sm={4} md={5} xs={12}>
              <Box className={label_text}>
                {gb_label}
                <Box
                  pl={0.5}
                  component="img"
                  src={qsMarkImg.img}
                  alt={qsMarkImg.alt}
                  onClick={(e: any) => handlePopper(e)}
                  id={imgId}
                  className={qs_mark_size}
                ></Box>
              </Box>
              <Box
                className={label_text}
                dangerouslySetInnerHTML={{
                  __html: gb_note,
                }}
              ></Box>
            </Grid>

            <Grid
              item
              className={input_grp_bill}
              sm={6}
              md={4}
              xs={12}
              display="flex"
              pt={{ xs: 1, sm: 0 }}
            >
              <TextFieldCust
                name="dataUsage"
                value={formik.values.dataUsage}
                label={gb_placeholder}
                onChange={(e: any) => numberRestriction(e)}
                helperText={
                  formik.touched?.dataUsage && formik.errors?.dataUsage
                }
                onBlur={formik.handleBlur}
              />
              <Box className={input_group_gb}>{gb_units}</Box>
            </Grid>
          </Grid>
          <Box className={section_header}>{sec_header3}</Box>
          {sectionBrk()}
          <Grid
            container
            item
            display="flex"
            justifyContent="space-between"
            pt={{ xs: 3, sm: 4 }}
          >
            <Grid item className={label_text} sm={4} md={5} xs={12}>
              {c_amt_label}
            </Grid>
            <Grid
              item
              className={label_text}
              sm={6}
              md={4}
              xs={12}
              pt={{ xs: 1, sm: 0 }}
            >
              <TextFieldCust
                name="currentAmount"
                value={`${
                  formik.values.currentAmount &&
                  getSymbolFromCurrency(EnvConfig.CURRENCY)
                }${formik.values.currentAmount}`}
                label={c_amt_placeholder}
                onChange={(e: any) => numberRestriction(e)}
                helperText={
                  formik.touched?.currentAmount
                    ? amountError
                      ? amountError
                      : formik.errors?.currentAmount
                    : ""
                }
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            display="flex"
            justifyContent="space-between"
            pt={{ xs: 3, sm: 4 }}
          >
            <Grid item sm={4} md={5} xs={12}>
              <Box className={label_text}>{email_label}</Box>
              <Box
                className={label_text}
                dangerouslySetInnerHTML={{
                  __html: email_note,
                }}
              ></Box>
            </Grid>
            <Grid item sm={6} md={4} xs={12} pt={{ xs: 1, sm: 0 }}>
              <TextFieldCust
                name="mailID"
                value={formik.values.mailID}
                type={"email"}
                label={email_placeholder}
                onChange={formik.handleChange}
                helperText={
                  formik.touched?.mailID ? formik.errors?.mailID : null
                }
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            display="flex"
            justifyContent="space-between"
            pt={{ xs: 3, sm: 4 }}
          >
            <Grid className={label_text} item sm={4} md={5} xs={12}>
              {name_label}
            </Grid>

            <Grid item sm={6} md={4} xs={12} pt={{ xs: 1, sm: 0 }}>
              <TextFieldCust
                name="userName"
                value={formik.values.userName}
                label={name_placeholder}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid item display="flex" justifyContent="center" py={5}>
            <Box>
              <ButtonCust
                sx={{ width: { xs: "200px", sm: "300px", md: "400px" } }}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={handleChange}
                disabled={!formik.isValid || amountError !== null}
              >
                {btn_name}
              </ButtonCust>
            </Box>
          </Grid>
          <Box className={bill_cal_popper}>
            <PopperCust
              open={openFg}
              anchorEl={anchorEl}
              id={imgId}
              placement={"bottom"}
            >
              <PopperContent
                closePopper={closePopper}
                comparePlansData={comparePlansData}
              />
            </PopperCust>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default T1_ComparePlans;
