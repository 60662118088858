import { useEffect, useState } from "react";
import Config from "../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { serviceContent } from "../../features/strapi/serviceSlice";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
const ServicesComponent = isTemplateExisits(
  Config.PostActivationServices.ServicesDetails
)
  ? require(`./${Config.PostActivationServices.ServicesDetails.template}_Services`)
      .default
  : null;

export const Services = () => {
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { serviceData, errorServicesData } = useAppSelector(
    (state: any) => state.strapi.services
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache('Services', serviceContent, setCacheContent, dispatch)
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData('Services', serviceData)
  }, [serviceData]);
  return (cacheContent || serviceData) ? (
    <ServicesComponent content={cacheContent? cacheContent: serviceData} />
  ) : errorServicesData ? (
    <div>{errorServicesData}</div>
  ) : null;
};
