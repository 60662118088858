import { Box, Grid, Typography } from "@mui/material";
import styles from "./ActivationIsMnp.module.scss";
import { ButtonCust } from "../../../widgets";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import EnvConfig from "../../../../config/env.config.json";

const ActivationIsMnp = (props: any) => {
  const { currentLine, redirection, step4Content = {} } = props;
  const { btn, content, subTitle1, subTitle2, title } =
    step4Content?.act_isMNP || {};
  return (
    <>
      <Box className={styles.activationIsMnpMainContainer}>
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          flexDirection={"row"}
          className={styles.heading_container}
        >
          <Typography className={styles.heading}>{title}</Typography>
        </Box>
        <Typography className={styles.sub_heading} mb={"20px"}>
          {subTitle1} {currentLine?.firstName} {BrandNameUpdate(subTitle2)}
        </Typography>
        <Box
          className={styles.activation_is_mnp_container}
          sx={{
            padding: { xs: "15px 30px", sm: "30px 50px" },
            width: {
              xs: "90%",
              sm: "90%",
              md: "65% !important",
            },
          }}
        >
          <Grid container>
            {content?.map((data: any, index: any) => {
              return (
                <Grid
                  key={index}
                  item
                  mb={"25px"}
                  container
                  xs={12}
                  sm={6}
                  display={"flex"}
                  justifyContent="flex-start"
                >
                  <Grid
                    item
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    flexDirection="column"
                  >
                    <img
                      src={data?.icon?.data?.attributes?.url}
                      className={styles.img_Icon}
                      alt={data?.icon?.data?.attributes?.alternativeText}
                    />
                    <Typography
                      flex="1"
                      className={styles.title}
                      sx={{
                        flex: {
                          sm: 4,
                          md: 3,
                        },
                      }}
                    >
                      {data?.title}
                    </Typography>
                    <Typography
                      flex="1"
                      className={styles.subtitle}
                      sx={{
                        flex: {
                          sm: 5,
                          md: 3,
                          lg: 6,
                        },
                      }}
                    >
                      {BrandNameUpdate(data?.content)}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <ButtonCust
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            onClick={() => redirection()}
          >
            {btn}
          </ButtonCust>
        </Box>
      </Box>
    </>
  );
};
export default ActivationIsMnp;
