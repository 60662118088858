import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RoutingURLs } from "../config/RoutingURLs";
import Config from "../config/env.config.json";

const ProtectedRoutes = () => {
  const location = useLocation();
  let user: any = localStorage.getItem("currentuser");
  const currentuser: any = user ? JSON.parse(user) : null;
  let ssoCustomerDetails: any = null;
  if (Config?.IS_SSO_LOGIN) {
    ssoCustomerDetails = localStorage.getItem("ssoCustomerDetails");
    ssoCustomerDetails = JSON.parse(ssoCustomerDetails);
  }
  return (currentuser && !currentuser.isAnonymous) ||
    (ssoCustomerDetails && ssoCustomerDetails.firstName) ? (
    <Outlet />
  ) : (
    <Navigate to={RoutingURLs.login} replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
