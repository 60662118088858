import { Box, Typography } from "@mui/material";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
  c_num: any;
  styles: any;
  setOpenSupportDialog: any;
};

const PinHelperText = (props: Props) => {
  const { c_num, styles, setOpenSupportDialog } = props;
  return (
    <>
      <Box mb="20px">
        <Typography className={styles.btm_title} sx={{ mb: "5px" }}>
          {c_num?.pin}
        </Typography>
        <Typography className={styles.btm_description}>
          {BrandNameUpdate(c_num?.pin_txt)}
        </Typography>
      </Box>
      <Box>
        <Typography className={styles.btm_title} sx={{ mb: "5px" }}>
          {c_num?.car_info}
        </Typography>
        <Typography className={styles.btm_description}>
          {c_num?.car_info_txt}&nbsp;
          <span
            className={styles.btm_title}
            style={{ color: "var(--dusk)", cursor: "pointer" }}
            onClick={() => setOpenSupportDialog(true)}
          >
            {c_num?.help_txt}
          </span>
        </Typography>
      </Box>
    </>
  );
};
export default PinHelperText;
