import { Grid, Skeleton } from "@mui/material";
import styles from "../S1_ViewCredits.module.scss";

export const SkeletonComp = () => {
  return (
    <Grid
      container
      gap="20px"
      className={styles.cards_container}
      p={{ xs: "0", sm: "0 20px" }}
    >
      <Grid container item gap={"20px"} className={styles.first_row}>
        <Grid
          container
          item
          xs={11}
          sm={5.75}
          gap={"15px"}
          py="70px"
          className={styles.card_item}
        >
          <Skeleton
            variant="rounded"
            animation="wave"
            width="30%"
            height={20}
            sx={{ borderRadius: "10px" }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width="45%"
            height={20}
            sx={{ borderRadius: "20px" }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width="40%"
            height={40}
            sx={{ borderRadius: "20px" }}
          />
        </Grid>
        <Grid
          container
          item
          xs={11}
          sm={5.75}
          gap={"15px"}
          paddingY="70px"
          className={styles.card_item}
        >
          <Skeleton
            variant="rounded"
            animation="wave"
            width="30%"
            height={20}
            sx={{ borderRadius: "10px" }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width="45%"
            height={20}
            sx={{ borderRadius: "20px" }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width="40%"
            height={40}
            sx={{ borderRadius: "20px" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
