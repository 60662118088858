import React from "react";
import { ButtonCust, TextFieldCust } from "../widgets";
import { LoginProps } from ".";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  Container,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import EnvConfig from "../../config/env.config.json";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";

const LoginT1 = (props: LoginProps) => {
  const {
    email,
    password,
    emailError,
    passwordError,
    onChangeHandler,
    handleToggle,
    isLoginClicked,
    handleLogIn,
    onGoogleLogin,
    onAppleLogin,
    onFacebookLogin,
    handleEnter,
    content,
    showSignUp,
  } = props;
  const {
    heading_logo,
    heading_login,
    login_logos,
    email_label_login,
    pwd_label,
    btn_login,
    forgot_pwd,
    no_account1,
    no_account2,
  } = content;
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const VisibilityOffIcon = content?.v_off_img?.data?.attributes?.url;
  const VisibilityOnIcon = content?.v_on_img?.data?.attributes?.url;
  const VisibilityOffAltText = BrandNameUpdate(content?.v_off_img?.data?.attributes?.alternativeText);
  const VisibilityOnAltText = BrandNameUpdate(content?.v_on_img?.data?.attributes?.alternativeText);
  const getLogo = (index: number) => login_logos?.data[index]?.attributes?.url;
  const getAltText = (index: number) =>
    login_logos?.data[index]?.attributes?.alternativeText;
  const Cursor = "pointer";
  return (
    <Box onKeyDown={handleEnter}>
      <Typography
        component={"h5"}
        variant={"h5"}
        fontSize="20px"
        fontFamily={"var(--font_family_Bold)"}
        pt={{ xs: "25px", sm: 0 }}
        pb={{ xs: "25px", sm: "15px" }}
        textAlign={{ xs: "center", sm: "unset" }}
        display={"flex"}
        justifyContent={{ xs: "center", sm: "unset" }}
        fontWeight={"var(--font_weight_4)"}
      >
        {heading_logo?.data?.attributes?.url && (
          <Box
            component="img"
            alt={heading_logo?.data?.attributes?.alternativeText}
            src={heading_logo?.data?.attributes?.url}
            width="26px"
            height="26px"
            mr="10px"
          />
        )}
        {BrandNameUpdate(heading_login)}
      </Typography>

      <Divider
        sx={{
          bgcolor: "var(--ford-grey-color)",
          opacity: "0.5",
        }}
      />
      {EnvConfig?.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA && (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          pt={{ xs: 0, sm: 3 }}
        >
          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(0)}
            src={getLogo(0)}
            width="162px"
            height="50px"
            onClick={onAppleLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(1)}
            src={getLogo(1)}
            width="162px"
            height="50px"
            onClick={onFacebookLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(2)}
            src={getLogo(2)}
            width="162px"
            height="50px"
            onClick={onGoogleLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(3)}
            src={getLogo(3)}
            width="fit-content"
            height="50px"
            onClick={onAppleLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(4)}
            src={getLogo(4)}
            width="fit-content"
            height="50px"
            onClick={onFacebookLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(5)}
            src={getLogo(5)}
            width="fit-content"
            height="50px"
            onClick={onGoogleLogin}
          />
        </Stack>
      )}

      <Container
        disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}
        sx={{
          minWidth: EnvConfig?.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA
            ? "372px"
            : "0px",
        }}
      >
        {EnvConfig?.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA && (
          <Divider
            sx={{
              "&.MuiDivider-root": {
                "&::before, &::after": {
                  borderTop: `dotted 1px var(--ford-grey-color)`,
                },
              },
              padding: "20px 0px 0px",
            }}
          >
            <Typography
              component={"div"}
              fontFamily={"var(--font_family_Bold)"}
            >
              or
            </Typography>
          </Divider>
        )}
        <Grid container spacing={2} pt="25px">
          <Grid item xs={12} sm={6}>
            <TextFieldCust
              value={email}
              placeholder={BrandNameUpdate(email_label_login)}
              name="email"
              onChange={onChangeHandler}
              error={emailError !== "" ? true : false}
              helperText={emailError ? emailError : ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldCust
              value={password}
              placeholder={pwd_label}
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={onChangeHandler}
              error={passwordError !== "" ? true : false}
              helperText={passwordError ? passwordError : ""}
              endAdornment={
                EnvConfig.ENABLE_PASSWORD_VISIBILITY &&
                (VisibilityOffIcon || VisibilityOnIcon) && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Tooltip title={content?.helper_txt?.off}>
                          <img
                            src={VisibilityOffIcon}
                            alt={VisibilityOffAltText}
                            style={{ width: "22px", height: "18px" }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title={content?.helper_txt?.on}>
                          <img
                            src={VisibilityOnIcon}
                            alt={VisibilityOnAltText}
                            style={{ width: "22px", height: "18px" }}
                          />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonCust
            variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
            sx={{ my: "25px" }}
            loading={!isLoginClicked}
            onClick={isLoginClicked ? () => handleLogIn() : () => null}
          >
            {btn_login}
          </ButtonCust>
        </Box>
        <Typography textAlign={"center"}>
          <Typography
            component={"span"}
            onClick={handleToggle}
            sx={{
              cursor: Cursor,
              fontSize: "14px",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--primary_color)",
              fontWeight: "var(--font_weight_2)",
            }}
          >
            {forgot_pwd}
          </Typography>
        </Typography>

        <Typography
          component={"div"}
          textAlign="center"
          fontSize={"14px"}
          pt="15px"
          fontFamily={"var(--font_family_Semibold)"}
        >
          {no_account1}&nbsp;
          <Typography
            component={"span"}
            fontSize={"inherit"}
            color={"var(--primary_color)"}
            fontFamily={"inherit"}
            fontWeight="var(--font_weight_2)"
            sx={{ cursor: Cursor }}
            onClick={showSignUp}
          >
            {no_account2}
          </Typography>
        </Typography>
      </Container>
    </Box>
  );
};

export default LoginT1;
