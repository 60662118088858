import { useState, useEffect } from "react";
import styles from "./S1_ReviewPlanDetails.module.scss";
import { Typography, Grid, Box } from "@mui/material";
import { OfferCodeCmp } from "../../common";
import { PlanChangeDialog } from "../../PlanSelection";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAdditionalLineCharges,
  getLineCharges,
  getPlanTaxableAmount,
} from "./BillService";
import firebase from "../../../adapters/firebase";
import {
  getOneTimeShippingTaxCharges,
  getOneTimeSimTaxCharges,
  getPlanTaxCharges,
  resetCoupon,
  resetOneTimeShippingTaxCharges,
  resetOneTimeSimTaxCharges,
  resetPlanTaxCharges,
  updateCouponDetails,
} from "../../../features/checkout/checkoutSlice";
import { addressType, SimTypes } from "../../../features/checkout/Types";
import {
  isInValidAddressInfo,
  isInvalid,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { NotificationCust } from "../../widgets/NotificationCust/NotificationCust";
import EnvConfig from "../../../config/env.config.json";
import { CouponTypes } from "../../../features/checkout/CouponTypes";
import { MIASection } from "./MIASection";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import FirebaseDBURLs from "../../../config/FirebaseDBURLs";
import { checkCoverage } from "../../../features/homepage/homepageSlice";

const UNLIMITED_PLAN_DISCOUNT_COUPON = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../config/discountCouponsUnlimited.json")
  : null;
const SHARABLE_PLAN_DISCOUNT_COUPON = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../config/discountCouponsRegular.json")
  : null;
type Props = {
  formik: any;
  shipmentPlans: any;
  content: any;
  simPrefenceContent?: any;
  simPreference: any;
  setSimPreference: any;
  simInHandCheck: any;
  simId?: number | string;
  isAllowedMultipleLineSelectionForSIMInHand?: any;
  checkoutPageOpsMessages?: any;
};

export const costCalculation = (
  data: any = null,
  planDetails: any,
  content: any
) => {
  let couponData: any = data ? data : null;
  let coupontype: any = CouponTypes;
  let plan: any = planDetails?.selectedPlan;
  let line: number = planDetails.numberOfLines;
  let basePrice: number = plan && plan.baseLinePrice;
  let additionalPrice: number = getAdditionalLineCharges(planDetails);
  let totalPrice: number = basePrice + additionalPrice;
  let discount: number = 0;
  let primaryDiscountAmount = 0;
  let secondaryDiscountAmount = 0;
  /**
   * The brand which is having fixed discounts for unlimited and shared plans.
   * */
  if (couponData && EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE && plan?.name) {
    if (plan?.name === EnvConfig.SPECIAL_DISCOUNT_PLAN_NAME) {
      primaryDiscountAmount = couponData?.discountInDollar || 0;
      if (line > 1) {
        secondaryDiscountAmount =
          (line - 1) * couponData?.secondaryDiscountInDollar || 0;
      }
    } else {
      primaryDiscountAmount = plan?.isUnlimited
        ? UNLIMITED_PLAN_DISCOUNT_COUPON?.data?.discountInDollar
        : SHARABLE_PLAN_DISCOUNT_COUPON?.data?.discountInDollar;
      secondaryDiscountAmount = plan?.isUnlimited
        ? (line - 1) *
          UNLIMITED_PLAN_DISCOUNT_COUPON?.data?.secondaryDiscountInDollar
        : (line - 1) *
          SHARABLE_PLAN_DISCOUNT_COUPON?.data?.secondaryDiscountInDollar;
    }
  }
  if (couponData && !EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE) {
    if (couponData && couponData.expired) {
      NotificationCust({
        message: content?.invalid_coupon_err,
        type: "danger",
        duration: 2000,
        id: "CouponExpired",
        showIcon: true,
      });
    } else {
      localStorage.setItem("couponDetails", JSON.stringify(couponData));
      if (coupontype[couponData && couponData.type]?.type === "PROMO") {
        //promo
        if (couponData && couponData.discountInDollar) {
          if (totalPrice > couponData.discountInDollar) {
            discount = couponData.discountInDollar;
          } else {
            discount = totalPrice;
          }
        }
        if (couponData && couponData.planDiscount) {
          discount += (couponData.planDiscount * basePrice) / 100;
          if (couponData && couponData.secondaryDiscount) {
            discount += (couponData.secondaryDiscount * additionalPrice) / 100;
          }
          if (
            couponData.maxDiscountInDollar &&
            discount > couponData.maxDiscountInDollar
          ) {
            discount = couponData.maxDiscountInDollar;
          }
        }
      } else if (
        coupontype[couponData && couponData.type]?.type === "REFERRAL"
      ) {
        //referal
        let coupon1: any = { couponCode: couponData.coupon, type: 3 };
        let coupon2: any = { couponCode: couponData.coupon };

        localStorage.setItem("reachPurhcaseReferralUserCoupon", coupon1);
        localStorage.setItem("reachPurhcaseUserCoupon", coupon2);
        if (couponData && couponData.refereeDiscInDollar) {
          discount = couponData.refereeDiscInDollar;
        } else {
          if (couponData && couponData.refereeDiscount) {
            discount = (couponData.refereeDiscount * basePrice) / 100;
          }
          // if (couponData && couponData.maxDiscountInDollar) {
          //   discount = Math.min(couponData.maxDiscountInDollar, discount);
          // }
          if (
            couponData.maxDiscountInDollar &&
            discount > couponData.maxDiscountInDollar
          ) {
            discount = couponData.maxDiscountInDollar;
          }
        }
      } else {
        if (couponData && couponData?.planDiscount) {
          discount += (couponData.planDiscount * basePrice) / 100;
          if (couponData && couponData.secondaryDiscount) {
            discount += (couponData.secondaryDiscount * additionalPrice) / 100;
          }
        }
      }
    }
  }

  return {
    discountAmount: discount,
    totalCost: totalPrice,
    basePrice: basePrice,
    additionalPrice: additionalPrice,
    primaryDiscount: primaryDiscountAmount,
    secondaryDiscount: secondaryDiscountAmount,
    totalWithDiscount: EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
      ? totalPrice - primaryDiscountAmount - secondaryDiscountAmount
      : totalPrice - discount,
  };
};

const T1_ReviewPlan = ({
  formik,
  shipmentPlans,
  content,
  simPrefenceContent,
  simPreference,
  setSimPreference,
  simInHandCheck,
  simId,
  isAllowedMultipleLineSelectionForSIMInHand,
  checkoutPageOpsMessages,
}: Props) => {
  const [simCharges, setSimCharges] = useState(0);
  const [physicalSimPrefCharges, setPhysicalSimPrefCharges] = useState(0);
  const dispatch = useAppDispatch();
  const {
    planDetails,
    planTaxCharges,
    oneTimeSIMTaxCharges,
    oneTimeShippingTaxCharges,
    errorgetOneTimeSimTaxCharges,
    errorOneTimeShippingTaxCharges,
    coupon,
    errorCoupon,
    errorUpdateCustomerSource,
  } = useAppSelector((state: any) => state.checkout);
  const [planTaxAmount, setPlanTaxAmount] = useState(0);
  let location: any = new URLSearchParams(document.location.search);
  const [appliedCouponCharges, setAppliedCouponCharges] = useState<number>(0);
  const [primaryDiscount, setPrimaryDiscount] = useState<number>(0);
  const [secondaryDiscount, setSecondaryDiscount] = useState<number>(0);
  const [showSimChangePrefernceDialog, setShowSimChangePrefernceDialog] =
    useState(false);
  const [eSimKitBasePrice, setESimKitBasePrice] = useState(0);
  const [eSimSecondarySimPrice, setESimSecondarySimPrice] = useState(0);
  const [welcomeKitCost, setWelcomeKitCost] = useState(0);
  useEffect(() => {
    getSIMCharges();
  }, [planDetails.numberOfLines, simPreference, simInHandCheck, simId]);
  const getShipmentPlanByLabel = (label: string) => {
    return shipmentPlans.find((sPlan: any) => sPlan.value === label);
  };
  const shipmentType =
    getShipmentPlanByLabel(formik.values.shipmentPlan)?.name || "";
  let shipmentCharges = getShipmentPlanByLabel(
    formik.values.shipmentPlan
  )?.shippingAmount;
  let taxableAmount = getPlanTaxableAmount(planDetails);
  let simPreferenceStorage: any = JSON.parse(
    localStorage.getItem("simPreference") || "[]"
  );
  const isEsimSelected =
    simPreferenceStorage.length > 0 &&
    simPreferenceStorage.every((data: any) => data.value === SimTypes.eSim);
  const isPhysicalSelected =
    simPreferenceStorage.length > 0 &&
    simPreferenceStorage.every((data: any) => data.value === SimTypes.sim);

  const numOfEsims = simPreferenceStorage?.filter(
    (data: any) => data?.value === SimTypes.eSim && !data?.isPrimaryLine
  ).length;

  const numOfPsims = simPreferenceStorage?.filter(
    (data: any) => data?.value === SimTypes.sim && !data?.isPrimaryLine
  ).length;

  const totalSimLines = simPreferenceStorage?.filter(
    (data: any) => data?.value === SimTypes.sim
  ).length;

  const totalEsimLines = simPreferenceStorage?.filter(
    (data: any) => data?.value === SimTypes.eSim
  ).length;

  const alleSimCompatible: boolean = simPreferenceStorage.every(
    (sim: any) => sim?.value === SimTypes.eSim
  );
  /**
   * When number of physical sims are more then one when in hand case showing shipment charges as 0
   * else SIM charges will be applicable as usual.
   */
  if (simInHandCheck && simId) {
    if (EnvConfig.IS_ESIM_SUPPORTED && totalSimLines <= 1) {
      shipmentCharges = 0;
    } else if (planDetails?.numberOfLines <= 1) {
      shipmentCharges = 0;
    }
  }
  /**
   * To fetch plan tax charges need billing address and customer fname and lname is mandatory
   */
  useEffect(() => {
    fetchBillingCalculations();
  }, [
    formik.values.billingAddress,
    planDetails,
    appliedCouponCharges,
    taxableAmount,
    coupon,
  ]);
  const fetchBillingCalculations = async () => {
    if (
      appliedCouponCharges >= 0 ||
      primaryDiscount >= 0 ||
      secondaryDiscount >= 0
    ) {
      taxableAmount = getPlanTaxableAmount(planDetails);
      if (coupon) {
        const costCalculationData: any = costCalculation(
          coupon,
          planDetails,
          content
        );
        taxableAmount = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
          ? taxableAmount -
            costCalculationData.primaryDiscount -
            costCalculationData.secondaryDiscount
          : taxableAmount - (costCalculationData?.discountAmount || 0);
        setAppliedCouponCharges(costCalculationData?.discountAmount);
        setPrimaryDiscount(costCalculationData?.primaryDiscount);
        setSecondaryDiscount(costCalculationData?.secondaryDiscount);
        !isInValidAddressInfo(formik.values.billingAddress) && dispatch(checkCoverage(formik.values.billingAddress)).then((res: any)=>{
          if(res?.payload?.status === 200){
            localStorage.setItem("isValidZip", res?.payload?.data?.data?.isValid)
            getPlanTaxInfo(taxableAmount);
          }
        }) 
      } else {
        !isInValidAddressInfo(formik.values.billingAddress) && dispatch(checkCoverage(formik.values.billingAddress)).then((res: any)=>{
          if(res?.payload?.status === 200){
            localStorage.setItem("isValidZip", res?.payload?.data?.data?.isValid)
            getPlanTaxInfo(taxableAmount);
          }
        }) 
      }
    }
  };
  const resetPlanTaxInfo = () => {
    setTimeout(() => {
      setPlanTaxAmount(0);
      dispatch(resetPlanTaxCharges());
    }, 1000);
    setPlanTaxAmount(0);
    dispatch(resetPlanTaxCharges());
  };
  const getPlanTaxInfo = async (taxableAmountValue: number) => {
    if (
      !isInValidAddressInfo(formik.values.billingAddress) &&
      taxableAmountValue > 0
    ) {
      try {
        const response = await dispatch(
          getPlanTaxCharges({
            address: {
              ...formik.values.billingAddress,
              name: `${formik.values.firstName} ${formik.values.lastName}`,
            },
            reachPlanId: planDetails?.selectedPlan?.name,
            amount: taxableAmountValue,
          })
        );
        if (response?.payload?.status === 200) {
          setPlanTaxAmount(response?.payload?.data?.data?.totalTax);
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    } else {
      resetPlanTaxInfo();
    }
  };

  useEffect(() => {
    let shippingAddressInfo = formik.values.shippingAddress;
    if (formik.values.isShippingSameAsBilling) {
      shippingAddressInfo = {
        ...formik.values.billingAddress,
        type: addressType.shipping,
      };
    }
    if (
      !isInValidAddressInfo(shippingAddressInfo) &&
      !isInvalid(formik.values.shipmentPlan) &&
      simCharges >= 0 &&
      shipmentCharges >= 0
    ) {
      fetchOneTimeTaxCharges(shippingAddressInfo);
    }
  }, [
    formik.values.shipmentPlan,
    formik.values.isShippingSameAsBilling,
    formik.values.billingAddress,
    formik.values.shippingAddress,
    simCharges,
    shipmentType,
    simPreference,
    planDetails.numberOfLines,
    simInHandCheck,
  ]);
  const fetchOneTimeTaxCharges = (address: any) => {
    let shipmentAmountCost = EnvConfig.INSTANT_DELIVERY_VIA_EMAIL_CHARGES;
    const isPrimaryLineWithSIMInHand =
      simInHandCheck &&
      simId &&
      ((EnvConfig.IS_ESIM_SUPPORTED && totalSimLines === 1) ||
        (!EnvConfig.IS_ESIM_SUPPORTED && planDetails.numberOfLines === 1));
    if (EnvConfig.IS_ESIM_SUPPORTED && isEsimSelected) {
      // to do shipment charges when all selected sims are eSim type
    }
    if (simCharges > 0 && !isPrimaryLineWithSIMInHand) {
      dispatch(
        getOneTimeSimTaxCharges({
          address: {
            ...address,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          amount: simCharges,
        })
      );
    } else {
      dispatch(resetOneTimeSimTaxCharges());
    }
    if (shipmentCharges > 0) {
      if (
        (EnvConfig.IS_ESIM_SUPPORTED && alleSimCompatible) ||
        isPrimaryLineWithSIMInHand
      ) {
        dispatch(resetOneTimeShippingTaxCharges());
      } else {
        dispatch(
          getOneTimeShippingTaxCharges({
            address: {
              ...address,
              name: `${formik.values.firstName} ${formik.values.lastName}`,
            },
            amount: !isEsimSelected ? shipmentCharges : 0,
          })
        );
      }
    } else {
      dispatch(resetOneTimeShippingTaxCharges());
    }
  };
  /**
   * Error handling for one-time tax calculation get api failure
   */
  useEffect(() => {
    if (
      (errorgetOneTimeSimTaxCharges &&
        errorgetOneTimeSimTaxCharges?.status === "FAILURE") ||
      (errorOneTimeShippingTaxCharges &&
        errorOneTimeShippingTaxCharges?.status === "FAILURE")
    ) {
      if (errorgetOneTimeSimTaxCharges?.resCode === 100001) {
        NotificationCust({
          message: content?.ot_tax_add_err,
          type: "danger",
          duration: 0,
          id: "addressFailure",
          showIcon: true,
        });
      }
    }
  }, [errorgetOneTimeSimTaxCharges, errorOneTimeShippingTaxCharges]);

  const getSIMCharges = async () => {
    const perCost: any = await firebase.database().ref(FirebaseDBURLs.simCost);
    let kitBasePrice = 0;
    let secondarySimPrice = 0;
    perCost.on("value", (snapshot: any) => {
      const data = snapshot.val();
      // physical sim cost calculations
      kitBasePrice =
        data &&
        data.USA &&
        ((100 - data.USA.discountPctg) * data.USA.cost) / 100;
      secondarySimPrice = kitBasePrice;
      if (data && data.USA && data.USA.subsequentCost) {
        secondarySimPrice =
          ((100 - data.USA.discountPctg) * data.USA.subsequentCost) / 100;
      }
      let simPref: any = JSON.parse(
        localStorage.getItem("simPreference") || "null"
      );

      let totalCost =
        (simInHandCheck && simId ? 0 : kitBasePrice) +
        secondarySimPrice * (planDetails.numberOfLines - 1);
      if (EnvConfig.IS_ESIM_SUPPORTED && simPref) {
        let numberOfPhysicalSIMs = simPref.filter((simItem: any) => {
          return simItem.value === SimTypes.sim;
        }).length;
        let numberOfPreferncePhysicalSIMs = simPreference.filter(
          (simItem: any) => {
            return simItem.value === SimTypes.sim;
          }
        ).length;
        const totalCostOfPreferedPhysicalSIMs =
          (simInHandCheck && simId ? 0 : kitBasePrice) +
          Number(secondarySimPrice * (numberOfPreferncePhysicalSIMs - 1));
        setPhysicalSimPrefCharges(Number(totalCostOfPreferedPhysicalSIMs));
        totalCost =
          (simInHandCheck && simId ? 0 : kitBasePrice) +
          secondarySimPrice * (numberOfPhysicalSIMs - 1);
      }
      setSimCharges(totalCost);
      // eSim cost calculations
      if (EnvConfig.IS_ESIM_SUPPORTED) {
        let eSimKitBasePrice = 0;
        let eSimSecondarySimPrice = 0;
        eSimKitBasePrice =
          data &&
          data.USA &&
          ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimCost) / 100;
        eSimSecondarySimPrice = eSimKitBasePrice;
        if (data && data.USA && data.USA.eSimSubsequentCost) {
          eSimSecondarySimPrice =
            ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimSubsequentCost) /
            100;
        }
        setESimKitBasePrice(eSimKitBasePrice);
        setESimSecondarySimPrice(eSimSecondarySimPrice);
        setWelcomeKitCost(totalCost);
      }
    });
  };
  useEffect(() => {
    if (coupon) {
      const costCalculationData = costCalculation(coupon, planDetails, content);
      setAppliedCouponCharges(costCalculationData?.discountAmount);
      setPrimaryDiscount(costCalculationData?.primaryDiscount);
      setSecondaryDiscount(costCalculationData?.secondaryDiscount);
    }
  }, [coupon, planDetails]);
  useEffect(() => {
    let couponDetails = localStorage.getItem("couponDetails");
    if (couponDetails && !coupon) {
      couponDetails = JSON.parse(couponDetails);
      dispatch(updateCouponDetails(couponDetails));
    }
  }, []);
  /**
   * Error notifications handlers
   */
  useEffect(() => {
    if (errorCoupon) {
      NotificationCust({
        message: errorCoupon,
        type: "danger",
        duration: 2000,
        id: "CouponFailureNotifier",
        showIcon: true,
      });
    }
  }, [errorCoupon]);
  useEffect(() => {
    if (location?.message && location?.code) {
      NotificationCust({
        message: location?.message,
        type: "danger",
        duration: 2000,
        id: "PaymentGatewayFailure",
        showIcon: true,
      });
    }
  }, [location]);
  useEffect(() => {
    if (errorUpdateCustomerSource) {
      NotificationCust({
        message: content.user_upd_err,
        type: "danger",
        duration: 2000,
        id: "UserUpdateFailure",
        showIcon: true,
      });
    }
  }, [errorUpdateCustomerSource]);
  const lineCharges = getLineCharges(planDetails);
  const additionalLineCharges = getAdditionalLineCharges(planDetails);
  const planChargesTaxAmount = planTaxAmount || 0;
  const estimatedMonthlyBillAmount = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
    ? taxableAmount + planChargesTaxAmount - primaryDiscount - secondaryDiscount
    : taxableAmount + planChargesTaxAmount - appliedCouponCharges;
  let eSimCostPerLine: number = 0;
  if (EnvConfig.IS_ESIM_SUPPORTED) {
    eSimCostPerLine =
      eSimKitBasePrice + eSimSecondarySimPrice * (totalEsimLines - 1);
  }
  let totalDueAmount: number = 0;
  const oneTimeTotalTaxChargesAmount: number =
    (oneTimeSIMTaxCharges?.totalTax || 0) +
    (oneTimeShippingTaxCharges?.totalTax || 0);
  if (EnvConfig.IS_ESIM_SUPPORTED) {
    totalDueAmount =
      !isEsimSelected && isPhysicalSelected
        ? parseFloat(
            (
              oneTimeTotalTaxChargesAmount +
              welcomeKitCost +
              shipmentCharges
            ).toFixed(2)
          )
        : isEsimSelected && !isPhysicalSelected
        ? parseFloat(
            (oneTimeTotalTaxChargesAmount + eSimCostPerLine).toFixed(2)
          )
        : parseFloat(
            (
              eSimCostPerLine +
              oneTimeTotalTaxChargesAmount +
              welcomeKitCost +
              shipmentCharges
            ).toFixed(2)
          );
  } else {
    totalDueAmount =
      simCharges + shipmentCharges + oneTimeTotalTaxChargesAmount;
  }
  const handleCouponRemove = () => {
    setAppliedCouponCharges(0);
    setPrimaryDiscount(0);
    setSecondaryDiscount(0);
    localStorage.removeItem("couponDetails");
    dispatch(resetCoupon());
  };
  const personIcon = content?.person_ico?.data?.attributes?.url;
  const personIcon_alt = content?.person_ico?.data?.attributes?.alternativeText;
  const giftBoxIcon = content?.gift_box_ico?.data?.attributes?.url;
  const giftBoxIcon_alt =
    content?.gift_box_ico?.data?.attributes?.alternativeText;
  const openSimChangePrefernceDialog = () => {
    setShowSimChangePrefernceDialog(true);
  };
  const onCloseSimChangePrefernceDialog = () => {
    setShowSimChangePrefernceDialog(false);
  };
  const getPrimaryUserSimType = () => {
    return simPreferenceStorage[0]?.value === SimTypes.eSim
      ? `(${getLabel(simPrefenceContent?.esim_txt)})`
      : `(${getLabel(simPrefenceContent?.sim_txt)})`;
  };
  const getLabel = (text: string, value: number = 0) => {
    return text + (value > 1 ? "s" : "");
  };
  // welcome kits count
  let totalNoOfWelcomeKits = 0;
  if (simInHandCheck && simId) {
    if (EnvConfig.IS_ESIM_SUPPORTED) {
      if (totalSimLines > 1) {
        totalNoOfWelcomeKits = totalSimLines - 1;
      } else {
        totalNoOfWelcomeKits = 0;
      }
    } else {
      if (planDetails.numberOfLines > 1) {
        totalNoOfWelcomeKits = planDetails.numberOfLines - 1;
      } else {
        totalNoOfWelcomeKits = 0;
      }
    }
  } else {
    if (EnvConfig.IS_ESIM_SUPPORTED) {
      totalNoOfWelcomeKits = totalSimLines;
    } else {
      totalNoOfWelcomeKits = planDetails.numberOfLines - 1;
    }
  }
  // To show banner at offer info
  // const textBannerImg = "https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/rectangle_d43e16f69d.webp"// content?.text_banner?.data?.attributes?.url;
  return (
    <Grid
      container
      className={`${styles.Review_plan} checkout_review_plan_card`}
      sx={{ p: { sm: "30px 15px 20px", xs: "25px 15px" } }}
    >
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: { md: "14px", sm: "15px", xs: "10px" },
            px: { xs: 0, md: "40px" },
          }}
          className={styles.review_header}
        >
          {content?.title}
        </Typography>
      </Grid>
      <Grid
        item
        container
        display="flex"
        sx={{
          justifyContent: { sm: "space-between", xs: "initial", ms: "initial" },
          px: { xs: 0, md: "40px" },
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={12}
          sx={{ pr: { sm: "13px", md: "0px", xs: "0px" } }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              sx={{ mb: { md: "12px", xs: "10px" } }}
              className={styles.review_sub_header}
            >
              {content?.r_sub_h1}
            </Typography>
            <Grid
              item
              container
              xs={12}
              className={styles.change_plan_container}
              sx={{ mb: { md: "9px", sm: "27px", xs: "22px" } }}
            >
              <PlanChangeDialog
                content={content}
                simPrefenceContent={simPrefenceContent}
                showSimChangePrefernceDialog={showSimChangePrefernceDialog}
                onCloseSimChangePrefernceDialog={
                  onCloseSimChangePrefernceDialog
                }
                simPreference={simPreference}
                setSimPreference={setSimPreference}
                simCharges={physicalSimPrefCharges + shipmentCharges}
                isAllowedMultipleLineSelectionForSIMInHand={
                  isAllowedMultipleLineSelectionForSIMInHand
                }
              />
            </Grid>
          </Grid>
          {EnvConfig.IS_BONUS_DATA &&
          !planDetails?.selectedPlan?.isUnlimited &&
          (content?.promo_txt1 || content?.promo_txt2) ? (
            <Grid
              item
              xs={12}
              textAlign="left"
              sx={{ mb: { md: "15px", xs: "20px" } }}
            >
              <>
                {planDetails.numberOfLines <= 2 ? (
                  <Typography
                    variant="body1"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: content?.promo_txt1 }}
                    className={styles.offer_details}
                  ></Typography>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <img
                        src={giftBoxIcon}
                        alt={giftBoxIcon_alt}
                        width="42"
                        height="30"
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      component="p"
                      className={styles.offer_details}
                      dangerouslySetInnerHTML={{
                        __html: content?.promo_txt2,
                      }}
                    ></Typography>
                  </Box>
                )}{" "}
              </>
            </Grid>
          ) : null}
          {EnvConfig.IS_ESIM_SUPPORTED ? (
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              lineHeight={1.86}
            >
              <Grid
                item
                xs={8}
                textAlign="left"
                className={styles.change_line_lable}
              >
                {simPrefenceContent?.line_pre}
              </Grid>
              <Grid
                item
                xs={4}
                className={styles.change_pre_link}
                onClick={openSimChangePrefernceDialog}
              >
                {simPrefenceContent?.change_pre}
              </Grid>
            </Grid>
          ) : null}
          <Grid item container xs={12} className={styles.your_line}>
            <Grid item xs={8} textAlign="left">
              {content?.due_l1}
              {EnvConfig.IS_ESIM_SUPPORTED ? (
                <span className={styles.primary_line_type}>
                  &nbsp;{getPrimaryUserSimType()}
                </span>
              ) : null}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {GetCurrencyFixedToTwo(lineCharges)}
            </Grid>
          </Grid>
          {coupon &&
          primaryDiscount > 0 &&
          EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE ? (
            <Grid item container xs={12} className={styles.tax_and_fee}>
              <Grid
                item
                xs={8}
                textAlign="left"
                style={{ color: "var(--primary_brand_color)" }}
              >
                {CouponTypes[coupon?.type].message}
              </Grid>
              <Grid
                item
                xs={4}
                textAlign="right"
                style={{ color: "var(--primary_brand_color)" }}
              >
                {primaryDiscount !== 0
                  ? `-${GetCurrencyFixedToTwo(primaryDiscount)}`
                  : "-"}
              </Grid>
            </Grid>
          ) : null}
          {additionalLineCharges !== 0 ? (
            <Grid item container xs={12} className={styles.additional_lines}>
              <Grid item xs={8} textAlign="left">
                {content?.due_l2}&nbsp;({planDetails.numberOfLines - 1}){" "}
                {EnvConfig.IS_ESIM_SUPPORTED ? (
                  <span className={styles.primary_line_type}>
                    (
                    {numOfEsims > 0 &&
                      numOfEsims +
                        " " +
                        getLabel(simPrefenceContent?.esim_txt, numOfEsims)}
                    {numOfEsims > 0 && numOfPsims > 0 && " + "}
                    {numOfPsims > 0 &&
                      numOfPsims +
                        " " +
                        getLabel(simPrefenceContent?.sim_txt, numOfPsims)}
                    )
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={4} textAlign="right">
                {GetCurrencyFixedToTwo(additionalLineCharges)}
              </Grid>
            </Grid>
          ) : null}
          {planDetails.numberOfLines - 1 >= 1 &&
          coupon &&
          secondaryDiscount >= 0 &&
          EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE ? (
            <Grid item container xs={12} className={styles.tax_and_fee}>
              <Grid
                item
                xs={8}
                textAlign="left"
                style={{ color: "var(--primary_brand_color)" }}
              >
                {CouponTypes[coupon?.type].message}
              </Grid>
              <Grid
                item
                xs={4}
                textAlign="right"
                style={{ color: "var(--primary_brand_color)" }}
              >
                {secondaryDiscount !== 0
                  ? `-${GetCurrencyFixedToTwo(secondaryDiscount)}`
                  : "-"}
              </Grid>
            </Grid>
          ) : null}
          <Grid item container xs={12} className={styles.tax_and_fee}>
            <Grid item xs={8} textAlign="left">
              {content?.due_l3}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {GetCurrencyFixedToTwo(planTaxAmount) || "-"}
            </Grid>
          </Grid>
          {coupon &&
          appliedCouponCharges > 0 &&
          !EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE ? (
            <Grid item container xs={12} className={styles.tax_and_fee}>
              <Grid
                item
                xs={8}
                textAlign="left"
                style={{ color: "var(--primary_brand_color)" }}
              >
                {CouponTypes[coupon?.type].message}
              </Grid>
              <Grid
                item
                xs={4}
                textAlign="right"
                style={{ color: "var(--primary_brand_color)" }}
              >
                {appliedCouponCharges !== 0
                  ? `-${GetCurrencyFixedToTwo(appliedCouponCharges)}`
                  : "-"}
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            container
            xs={12}
            className={styles.estimated_bill}
            sx={{ mb: { md: "10px", sm: "11px", xs: "16px" } }}
          >
            <Grid item xs={8} textAlign="left">
              {content?.due_l4}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {GetCurrencyFixedToTwo(estimatedMonthlyBillAmount)}
            </Grid>
          </Grid>
          {(content?.p_p1 || content?.p_p2) && (
            <Grid
              item
              container
              xs={12}
              className={styles.charge_per_person}
              sx={{ mb: { md: "14px", xs: "15px" } }}
            >
              <Box display="flex" justifyContent="left" alignItems="center">
                {personIcon ? (
                  <Box
                    component={"img"}
                    alt={personIcon_alt}
                    width="20px"
                    height="20px"
                    sx={{ mr: "11px" }}
                    src={personIcon}
                  />
                ) : null}
                <Box>
                  {content?.p_p1} $
                  {Math.ceil(
                    estimatedMonthlyBillAmount / planDetails.numberOfLines
                  )}{" "}
                  {content?.p_p2}
                </Box>
              </Box>
            </Grid>
          )}
          {EnvConfig.IS_OFFER_CODE_DISCOUNT_APPLICABLE ? (
            <Grid
              item
              xs={12}
              className={styles.offer_code_section}
              sx={{ mt: "14px", mb: "15px" }}
            >
              {EnvConfig?.IS_FIXED_PROMO_DISCOUNT ? (
                <Box className={styles.promo_txt_container}>
                  <Box className={styles.referal_text}>
                    {content?.savings_txt}
                  </Box>
                  <Box className={styles.referal_text_light}>
                    {CouponTypes[coupon && coupon?.type]?.message}{" "}
                    {coupon && coupon?.name} {content?.applied_txt}
                  </Box>
                </Box>
              ) : (
                <OfferCodeCmp
                  content={content}
                  removeCouponCallback={handleCouponRemove}
                  appliedCouponAmount={appliedCouponCharges}
                />
              )}
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={12}
          sx={{ pl: { sm: "13px", md: "0px", xs: "0px" } }}
        >
          <Typography
            variant="body2"
            component="p"
            sx={{ mb: "12px" }}
            className={styles.one_time_charges}
          >
            {content?.r_sub_h2}
          </Typography>
          {EnvConfig.IS_ESIM_SUPPORTED &&
          (totalEsimLines > 1 ||
            (totalEsimLines >= 1 && totalSimLines >= 1)) ? (
            <Grid item container xs={12} className={styles.welcome_kit_charge}>
              <Grid item xs={9.5} textAlign="left">
                {simPrefenceContent?.kit_sent_bill_txt} ({totalEsimLines})
              </Grid>
              <Grid item xs={2.5} textAlign="right">
                {GetCurrencyFixedToTwo(eSimCostPerLine)}
              </Grid>
            </Grid>
          ) : null}
          {EnvConfig.IS_ESIM_SUPPORTED &&
          totalEsimLines === 1 &&
          totalSimLines < 1 ? (
            <Grid item container xs={12} className={styles.welcome_kit_charge}>
              <Grid item xs={9.5} textAlign="left">
                {simPrefenceContent?.digital_kit}
              </Grid>
              <Grid item xs={2.5} textAlign="right">
                {GetCurrencyFixedToTwo(eSimCostPerLine)}
              </Grid>
            </Grid>
          ) : null}
          {EnvConfig.IS_ESIM_SUPPORTED && totalSimLines === 0 ? null : (
            <Grid item container xs={12} className={styles.welcome_kit_charge}>
              <Grid item xs={9.5} textAlign="left">
                {content?.otc_l1}{" "}
                {totalNoOfWelcomeKits > 0 ? `(${totalNoOfWelcomeKits})` : null}
              </Grid>
              <Grid item xs={2.5} textAlign="right">
                {GetCurrencyFixedToTwo(simCharges)}
              </Grid>
            </Grid>
          )}
          <Grid item container xs={12} className={styles.shipping_charge}>
            <Grid item xs={9.5} textAlign="left">
              {EnvConfig.IS_ESIM_SUPPORTED && isEsimSelected
                ? simPrefenceContent?.instant_del
                : shipmentType
                ? `${shipmentType} ${content?.s_o_c_l || ""}`
                : null}
            </Grid>
            <Grid item xs={2.5} textAlign="right">
              {EnvConfig.IS_ESIM_SUPPORTED && isEsimSelected
                ? GetCurrencyFixedToTwo(
                    EnvConfig.INSTANT_DELIVERY_VIA_EMAIL_CHARGES
                  )
                : shipmentCharges
                ? GetCurrencyFixedToTwo(shipmentCharges)
                : GetCurrencyFixedToTwo(0)}{" "}
            </Grid>
          </Grid>
          <Grid item container xs={12} className={styles.tax_and_fee}>
            <Grid item xs={9.5} textAlign="left">
              {content?.otc_l2}
            </Grid>
            <Grid item xs={2.5} textAlign="right">
              {oneTimeSIMTaxCharges?.totalTax >= 0 ||
              oneTimeShippingTaxCharges?.totalTax >= 0
                ? GetCurrencyFixedToTwo(oneTimeTotalTaxChargesAmount)
                : GetCurrencyFixedToTwo(0)}{" "}
            </Grid>
          </Grid>
          <Grid item container xs={12} className={styles.total_due}>
            <Grid item xs={8} textAlign="left">
              {content?.otc_l3}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {totalDueAmount >= 0
                ? GetCurrencyFixedToTwo(totalDueAmount)
                : "-"}
            </Grid>
          </Grid>
          {(content?.otc_note_txt || content?.otc_note_desc) && (
            <Grid item container xs={12} className={styles.total_due}>
              {content?.otc_note_txt && (
                <Grid item xs={12} textAlign="left">
                  <Typography
                    variant="body2"
                    component="p"
                    className={styles.note_header}
                    sx={{ my: "10px" }}
                  >
                    {content?.otc_note_txt}
                  </Typography>
                </Grid>
              )}
              {content?.otc_note_desc && (
                <Grid item xs={12} textAlign="left">
                  <Typography
                    variant="body2"
                    component="li"
                    className={styles.note_description}
                    sx={{ mb: "5px" }}
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(content?.otc_note_desc),
                    }}
                  />
                </Grid>
              )}
              {EnvConfig?.IS_MSG_CENTER_ENABLED && (
                <>
                  {checkoutPageOpsMessages.map((opsMessage: any) => {
                    return (
                      <Typography key={opsMessage.id} component={"div"}>
                        <Typography
                          variant="body2"
                          component="li"
                          sx={{
                            mb: "5px",
                            fontSize: "14px",
                            color: "var(--text_color)",
                          }}
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              mb: "5px",
                              fontFamily: "var(--font_family_Medium)",
                            }}
                          >
                            {opsMessage.messageType}:{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              mb: "5px",
                              fontFamily: "var(--font_family_Medium)",
                            }}
                          >
                            {opsMessage.message}
                          </Typography>
                        </Typography>
                      </Typography>
                    );
                  })}
                </>
              )}
            </Grid>
          )}
          {(content?.otc_descl || content?.otc_dscl_d) && (
            <Grid item container xs={12} className={styles.total_due}>
              {content?.otc_descl && (
                <Grid item xs={12} textAlign="left">
                  <Typography
                    variant="body2"
                    component="li"
                    className={styles.note_header}
                    sx={{ my: "10px" }}
                  >
                    {content?.otc_descl}
                  </Typography>
                </Grid>
              )}
              {content?.otc_dscl_d && (
                <Grid item xs={12} textAlign="left">
                  <Typography
                    variant="body2"
                    component="li"
                    className={styles.note_description}
                    sx={{ mb: "5px" }}
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(content?.otc_dscl_d),
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        {EnvConfig.IS_MIA_CHECKOUT && (
          <MIASection
            content={content}
            totalDueAmount={totalDueAmount}
            estimatedMonthlyBillAmount={estimatedMonthlyBillAmount}
            simInHandCheck={simInHandCheck}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default T1_ReviewPlan;
