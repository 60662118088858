import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import styles from "./MultiTab.module.scss";
type TabsInterface = {
  tabsHandler: Function;
  tabsContent: any;
  activeTab: Number;
};
export const MultiTabCmp = (props: TabsInterface) => {
  const [tab, setTab] = useState(props.tabsContent);
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    props.tabsHandler(newValue);
  };
  return (
    <Grid container className={styles.tab_container}>
      <Tabs
        value={Number(props.activeTab)}
        onChange={handleChange}
        sx={{ width: "100%", mx: "auto" }}
      >
        {tab.map((item: any) => (
          <Tab
            key={item.id}
            value={item.id}
            label={item.name}
            className={styles.tab_text}
            sx={{ width: "50%" }}
          />
        ))}
      </Tabs>
    </Grid>
  );
};
