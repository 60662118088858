import { CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { ButtonCust, SwitchCust } from "../../../widgets";
import styles from "./PaymentReviewCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useAppSelector } from "../../../../app/hooks";

function PaymentReviewCard(props: any) {
  const {
    visa_card,
    firstRowTitle,
    firstRowValue,
    secondRowTitle,
    secondRowValue,
    loading,
    isAutoRenewal,
    thirdRowTitle,
    autoRenewalTitle,
    toggleTrueText,
    toggleFalseText,
    confirmBtn,
    carbon_edit,
    changeCardText,
    btnLoading,
  } = props;

  const { getCCDetails } = useAppSelector((state: any) => state?.account);

  return (
    <>
      {!loading ? (
        <Box className={styles.main_container}>
          <Box className={styles.rows}>
            <Typography className={styles.displayPack}>
              {firstRowTitle}
            </Typography>
            <Typography className={styles.displayPack}>
              {GetCurrencyFixedToTwo(firstRowValue)}
            </Typography>
          </Box>
          <Divider />
          <Box className={styles.rows}>
            <Typography className={styles.displayPack}>
              {secondRowTitle}
            </Typography>
            <Typography className={styles.displayPack}>
              {GetCurrencyFixedToTwo(secondRowValue)}
            </Typography>
          </Box>
          <Divider />
          <Box className={styles.rows}>
            <Typography className={styles.displayPack}>
              {thirdRowTitle}
            </Typography>
            <Typography className={styles.displayPack}>
              {GetCurrencyFixedToTwo(firstRowValue + secondRowValue)}
            </Typography>
          </Box>
          {isAutoRenewal ? <Divider /> : null}
          {isAutoRenewal ? (
            <Box className={styles.auto_renual}>
              <Typography className={styles.displayPack}>
                {autoRenewalTitle}
              </Typography>

              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                className={styles.toggleText}
              >
                {" "}
                <SwitchCust
                  checked={props.autoRenewal}
                  handleSwitchToggle={props.onChangeAutoRenewal}
                  name="autoRenewal"
                />
                {props.autoRenewal ? (
                  <Typography className={styles.toggleText}>
                    {toggleTrueText}
                  </Typography>
                ) : (
                  <Typography className={styles.toggleText}>
                    {toggleFalseText}
                  </Typography>
                )}
              </Box>
            </Box>
          ) : null}
          {isAutoRenewal ? <Divider /> : null}
          <Box className={styles.main_card}>
            <Box className={styles.card}>
              <Box
                className={styles.displayPack}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <img src={visa_card.url} alt={visa_card.alternativeText} />
                <Typography className={styles.displayPack}>
                  {`${props?.mockCard}${
                    getCCDetails && getCCDetails[0]?.ccNumberMask
                  }`}
                </Typography>
              </Box>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => alert("coming soon")}
                className={styles.changeCard}
              >
                {changeCardText}
                <img
                  src={carbon_edit?.url}
                  alt={carbon_edit?.alternativeText}
                />
              </Typography>
            </Box>
            <ButtonCust
              variantType={Config.QUATERNARY_BUTTON_TYPE}
              onClick={props.onClick}
            >
              {btnLoading ? <CircularProgress /> : confirmBtn}
            </ButtonCust>
          </Box>
        </Box>
      ) : (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}
    </>
  );
}

export default PaymentReviewCard;
