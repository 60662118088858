import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { trackOrderShipment } from "../../../../features/trackorder/trackorderSlice";

type Props = {
  children?: any;
};
export const MyAccountWrapper = createContext<any>(null);
export const MyAccountWrapperProvider = (props: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { trackOrderStatus } = useAppSelector((state: any) => state.trackorder);
  const [isdelivered, setIsdelivered] = useState<boolean>();
  useEffect(() => {
    let customerData: any = localStorage.getItem("customerDetail");
    customerData = JSON.parse(customerData);
    if (location.state) {
      let data: any = location.state;
      let trackId =
      data && data.shipmentInfo && Object.keys(data.shipmentInfo)[0];
      if (trackId) {
        dispatch(
          trackOrderShipment({
            poll: true,
            orderId: trackId,
          })
        );
      } else {
        setIsdelivered(false);
      }
    } else {
      let trackId =
        customerData &&
        customerData.shipmentInfo &&
        Object.keys(customerData.shipmentInfo)[0];
      if (trackId) {
        dispatch(
          trackOrderShipment({
            poll: true,
            orderId: trackId,
          })
        );
      } else {
        setIsdelivered(false);
      }
    }
  }, []);

  useEffect(() => {
    if (trackOrderStatus?.status === "SUCCESS") {
      let trackData = trackOrderStatus?.data;
      if (trackData?.orderStatus !== "DELIVERED") {
        setIsdelivered(false);
      } else if (trackData?.orderStatus === "DELIVERED") {
        setIsdelivered(true);
      } else {
        setIsdelivered(true);
      }
    }
  }, [trackOrderStatus]);
  return (
    <MyAccountWrapper.Provider value={{ isdelivered }}>
      {props.children}
    </MyAccountWrapper.Provider>
  );
};
