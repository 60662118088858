import { Button, ButtonProps, CircularProgress } from "@mui/material";
import styles from "./ButtonCust.module.scss";

interface TButtonProps extends ButtonProps {
  variant?: any;
  className?: any;
  variantType?: string;
  loading?: boolean;
  children?: any;
}

export function ButtonCust({
  variant = "outlined",
  className,
  variantType,
  loading,
  ...props
}: TButtonProps) {
  // Reach:
  // Primary:secondary_transparent_brand_rounded
  // Secondary:secondary_contained_rounded
  // Tertiary:NA

  // Wowmobile:
  // Primary:primary_contained_square
  // Secondary:secondary_transparent_brand_square
  // Tertiary:secondary_transparent_white_square

  // Brandmobile:
  // Primary:primary_contained_rounded
  // Secondary:NA
  // Tertiary:NA

  // Ghostmobile:
  // Primary:primary_contained_rounded
  // Secondary:NA
  // Tertiary:NA

  // Nextlink:
  // Primary:primary_outlined_rounded
  // Secondary:primary_contained_rounded
  // Tertiary:NA

  //Button Types:
  // primary_outlined_rounded
  // primary_outlined_square
  // primary_contained_rounded
  // primary_contained_square
  // secondary_contained_rounded
  // secondary_contained_square
  // secondary_transparent_brand_square
  // custom_secondary_transparent_brand_square
  // custom_secondary_transparent_brand_rounded
  // custom_secondary_transparent_white_square
  // secondary_transparent_white_rounded

  const getButtonVariantClass = (variantTypeName: string | undefined) => {
    switch (variantTypeName) {
      case "primary_outlined_rounded": {
        return styles.custom_primary_outlined_rounded;
      }
      case "primary_outlined_square": {
        return styles.custom_primary_outlined_square;
      }
      case "primary_contained_rounded": {
        return styles.custom_primary_contained_rounded;
      }
      case "primary_contained_square": {
        return styles.custom_primary_contained_square;
      }
      case "secondary_contained_rounded": {
        return styles.custom_secondary_contained_rounded;
      }
      case "secondary_contained_square": {
        return styles.custom_secondary_contained_square;
      }
      case "secondary_transparent_brand_square": {
        return styles.custom_secondary_transparent_brand_square;
      }
      case "secondary_transparent_brand_rounded": {
        return styles.custom_secondary_transparent_brand_rounded;
      }
      case "secondary_transparent_white_square": {
        return styles.custom_secondary_transparent_white_square;
      }
      case "secondary_transparent_white_rounded": {
        return styles.custom_secondary_transparent_white_rounded;
      }
      default: {
        return styles.custom_primary_outlined_rounded;
      }
    }
  };
  return (
    <Button
      {...props}
      className={`${className} ${
        props.disabled
          ? `${getButtonVariantClass(variantType)} ${
              styles.custom_disabled_button
            }`
          : getButtonVariantClass(variantType)
      }`}
      onClick={!loading ? props?.onClick : (e) => e.preventDefault()}
    >
      {loading ? <CircularProgress /> : props.children}
    </Button>
  );
}
