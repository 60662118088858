import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
import { fetchBFContent } from "../../../../features/strapi/myAccountSlice";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
const BFMyAccount = isTemplateExisits(Config.MyAccount.BestFit)
  ? require(`./${Config.MyAccount.BestFit.template}_BestFit`).default
  : null;

export type BFMyAccountProps = {
  content?: any;
};

export const MABF = () => {
  const [content, setContent] = useState<any>(null);
  const [cacheMABF, setCacheMABF] = useState<any>(null);
  const { BFMAContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  const dispatch = useAppDispatch();
  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
      getCache("MABF-MyAccount", fetchBFContent, setCacheMABF, dispatch);
    } else {
      let content = BFMAContent?.data?.data?.attributes;
      setContent(content);
      addMultipleCacheData("MABF-MyAccount", BFMAContent?.data?.data?.attributes);
    }
  }, [BFMAContent]);
  return content || cacheMABF ? (
    <BFMyAccount content={cacheMABF ? cacheMABF : content} />
  ) : null;
};
