import { Box, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import { explorePlanProps } from "./ExplorePlans";
import styles from "./S2_ExplorePlans.module.scss";
import ENVConfig from "../../../config/env.config.json";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const ExplorePlansComponent=( props :explorePlanProps)=>{
  const {content} = props;
    const handleExplorePlans = () => {
      window.open(content?.explore_url, "_blank")
    }
    return (
      <Box minHeight={{ xs: "390px", sm: "250px", md: "305px" }}>
        <Box
          p={{
            xs: "calc(var(--mobile_section_padding)/2) 0 70px",
            sm: "calc(var(--ipad_section_padding)/2) 0 70px",
            md: "calc(var(--desktop_section_padding)/2) 0 100px",
          }}
        >
          <Box
            className={styles.explore_plans_banner}
            sx={{
              p: { sm: "25px 50px", xs: "25px 35px" },
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { md: "space-between", xs: "center" },
            }}
          >
            <Box
              sx={{
                textAlign: { sm: "start", xs: "center" },
                px: "12px",
                width: { md: "58.8%" },
              }}
            >
              <Typography
                className={styles.explore_title}
                sx={{ fontSize: { md: "26px", xs: "22px" } }}
              >
                {BrandNameUpdate(content?.title)}
              </Typography>
              <Typography
                className={styles.explore_subtitle}
                sx={{
                  fontSize: { md: "18px", xs: "14px" },
                  pt: { sm: 0, xs: "20px" },
                }}
              >
                {BrandNameUpdate(content?.description)}
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"} sx={{ py: "30px" }}>
              <ButtonCust
                variantType={ENVConfig.TERTIARY_BUTTON_TYPE}
                sx={{
                  alignSelf: "center",
                  fontFamily: " var(--font_family_Semibold) !important",
                }}
                onClick={handleExplorePlans}
              >
                {content?.explore}
              </ButtonCust>
            </Box>
          </Box>
        </Box>
      </Box>
    );
}

export default ExplorePlansComponent;