import { Box } from "@mui/material";
import { PostActivationIRILDEnums } from "../../../enums";
import clsx from "clsx";
import styles from "./SwitchTabs.module.scss";

const SwitchTabs = (props: any) => {
  const { title_left, title_right, onClickSwitchTabs, showPurchaseHistory } = props;
  const { switchTabContainer, tabInactive, tabActive, switchTab} = styles;
  return (
    <Box
      sx={{
        padding: "5px",
        margin: "30px 0",
        borderRadius: "4px",
        display: "flex",
        gap: "10px",
      }}
      className={switchTabContainer}
    >
      <Box
        className={clsx(showPurchaseHistory ? tabInactive : tabActive, switchTab)}
        sx={{ flex: "1"}}
        onClick={() => onClickSwitchTabs(PostActivationIRILDEnums.tab_left)}
      >
        {title_left}
      </Box>
      <Box
        className={clsx(showPurchaseHistory ? tabActive : tabInactive, switchTab)}
        sx={{ flex: "1" }}
        onClick={() => onClickSwitchTabs(PostActivationIRILDEnums.tab_right)}
      >
        {title_right}
      </Box>
    </Box>
  );
};

export default SwitchTabs;
