import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { bestFitFeatureContent } from "../../../features/strapi/bestFitSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const BestFitFeaturesComponent = isTemplateExisits(
  Config.BestFit.BestFitFeatures
)
  ? require(`./${Config.BestFit.BestFitFeatures.template}_BestFitFeatures`)
      .default
  : null;
export type BestFitFeaturesProps = {
  featureContent?: any;
};
export const BestFitFeatures = () => {
  const [cacheDataBestFitFeatures,setCacheDataBestFitFeatures]= useState<any>(null)
  const { bestFitFeatures } = useAppSelector(
    (state: any) => state.strapi.bestfit
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("FeaturesBestFit",bestFitFeatureContent,setCacheDataBestFitFeatures,dispatch)
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData('FeaturesBestFit', bestFitFeatures)
  }, [bestFitFeatures])
  return <BestFitFeaturesComponent featureContent={ cacheDataBestFitFeatures? cacheDataBestFitFeatures : bestFitFeatures} />;
};
