import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import styles from "./S1_ViewCredits.module.scss";
import { AvailableCredit } from "./ReferralCards/AvailableCredit";
import { EarnedCredits } from "./ReferralCards/EarnedCredits";
import { ActivatedReferrals } from "./ReferralCards/ActivatedReferrals";
import { PendingCredit } from "./ReferralCards/PendingCredit";
import { TotalCredits } from "./ReferralCards/TotalCredits";
import { ReferralCreditsHeader } from "./ReferralCards/ReferralCreditsHeader";
import { ScrollToTop } from "../../../../utils/commonFunctions/ScrollToTop";

type Props = {
  setCurrentSection: Function;
  referralCredits: any;
  totalCreditsCardContent: any;
  referralCreditsCardsContent: any;
  pendingCreditCardsContent: any;
};
interface ReferralStatus {
  activated: any;
  inviteAccepted: any;
}
const ViewCreditsComponent = ({
  setCurrentSection,
  referralCredits,
  totalCreditsCardContent,
  referralCreditsCardsContent,
  pendingCreditCardsContent,
}: Props) => {
  const [currentCard, setCurrentCard] = useState<string>("");
  const [activated, setActivated] = useState<any>([]);
  const [remaining, setRemaining] = useState<any>(0);
  const [total, setTotal] = useState<any>(0);
  const [inviteAccepted, setInviteAccepted] = useState<any>([]);

  useEffect(() => {
    if (referralCredits?.data?.hasOwnProperty("customers")) {
      const { remaining, total }: any = referralCredits?.data?.discountInDollar;
      setRemaining(remaining);
      setTotal(total);
      for (const value of Object.values(referralCredits?.data?.customers)) {
        let referralStatus = value as ReferralStatus;
        if (referralStatus?.activated) {
          setActivated((prevState: any) => [
            ...prevState,
            ...referralStatus?.activated,
          ]);
        }
        if (referralStatus?.inviteAccepted) {
          setInviteAccepted((prevState: any) => [
            ...prevState,
            ...referralStatus?.inviteAccepted,
          ]);
        }
      };
      setCurrentCard("ReferralCards");
    }
  }, []);

  let email = activated?.map((item: any) => item.emailId);
  email = new Set(email);
  let pendingList = [];
  pendingList = inviteAccepted?.filter((item: any) => !email.has(item.emailId));
  switch (currentCard) {
    case "ReferralCards":
      return (
        <Box
        sx={{
          p:{
            xs: "var(--mobile_section_padding) 0",
            sm: "var(--ipad_section_padding) 0",
            md: "var(--desktop_section_padding) 0",
          }
        }}
        >
          <ScrollToTop />
          <ReferralCreditsHeader
            setCard={setCurrentSection}
            cardName={"DataDetails"}
            content={totalCreditsCardContent}
          />
          <Grid container gap="20px" className={styles.cards_container}>
            <Grid container item gap={"20px"} className={styles.first_row}>
              <AvailableCredit
                content={referralCreditsCardsContent}
                availableCredits={remaining}
              />
              <EarnedCredits
                content={referralCreditsCardsContent}
                earnedCredits={total}
              />
            </Grid>
            <Grid container item gap={"20px"} className={styles.second_row}>
              <ActivatedReferrals
                setCurrentCard={setCurrentCard}
                currentCard={currentCard}
                content={referralCreditsCardsContent}
                activatedUsers={activated}
              />
              <PendingCredit
                setCurrentCard={setCurrentCard}
                currentCard={currentCard}
                content={pendingCreditCardsContent}
                pendingUsers={pendingList}
              />
            </Grid>
          </Grid>
        </Box>
      );
    case "PendingCreditsList":
      return (
        <Box
          sx={{
            p:{
              xs: "var(--mobile_section_padding) 0",
              sm: "var(--ipad_section_padding) 0",
              md: "var(--desktop_section_padding) 0",
            }
          }}
        >
          <ReferralCreditsHeader
            setCard={setCurrentCard}
            cardName={"ReferralCards"}
            content={totalCreditsCardContent}
          />
          <ScrollToTop />
          <PendingCredit
            setCurrentCard={setCurrentCard}
            currentCard={currentCard}
            content={pendingCreditCardsContent}
            pendingUsers={pendingList}
          />
        </Box>
      );
    case "ActivatedCreditsList":
      return (
        <Box
          sx={{
            p:{
              xs: "var(--mobile_section_padding) 0",
              sm: "var(--ipad_section_padding) 0",
              md: "var(--desktop_section_padding) 0",
            }
          }}
        >
          <ReferralCreditsHeader
            setCard={setCurrentCard}
            cardName={"ReferralCards"}
            content={totalCreditsCardContent}
          />
          <ScrollToTop />
          <ActivatedReferrals
            setCurrentCard={setCurrentCard}
            currentCard={currentCard}
            content={referralCreditsCardsContent}
            activatedUsers={activated}
          />
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            p:{
              xs: "var(--mobile_section_padding) 0",
              sm: "var(--ipad_section_padding) 0",
              md: "var(--desktop_section_padding) 0",
            }
          }}
        >
          <ScrollToTop />
          <ReferralCreditsHeader
            setCard={setCurrentSection}
            cardName={"DataDetails"}
            content={totalCreditsCardContent}
          />
          <TotalCredits content={totalCreditsCardContent} />
        </Box>
      );
  }
};

export default ViewCreditsComponent;
