import { Box, Typography } from "@mui/material";
import { ButtonCust, Divider } from "../../../widgets";
import styles from "./S3_PlanCard.module.scss";
import EnvConfig from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { clsx } from "clsx";
import { calculateCost, calculateUnlimitedCost, PlanProps } from "./PlanCard";
import { RoutingURLs } from "../../../../config/RoutingURLs";

const T3_PlanCard = (props: PlanProps) => {
  const { plandata, planContent, selectedLine, planSelectionHandler } = props;

  let user: any = JSON.parse(localStorage.getItem("customerDetail") || "null");
  let isSimPaid = user?.simPaid;

  return (
    <Box
      className={styles.plan_card_container}
      pb={
        location.pathname.includes(RoutingURLs.myAccount)
          ? { xs: "5px", sm: "5px", md: "30px" }
          : { xs: "var(--mobile_section_padding)", sm: "var(--ipad_section_padding)", md: "calc(var(--desktop_section_padding) - 30px)" }
      }
    >
      {plandata?.length > 0 &&
        plandata?.map((each: any, index: number) => {
          const { planPerLinesCost } = each?.unlimited
            ? calculateUnlimitedCost(each, selectedLine)
            : calculateCost(each, selectedLine);
          const isCurrentPlan = user?.reachPlanId === each?.name;
          return (
            <Box
              className={clsx(
                styles.plan_card,
                isCurrentPlan && styles.selected_plan_card
              )}
              key={index}
            >
              {isCurrentPlan && (
                <Typography className={styles.current_plan_tag} p="5px 10px">
                  {planContent?.current_plan}
                </Typography>
              )}
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography className={styles.plan_heading}>
                  {each.name}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box
                    src={
                      each?.isUnlimited
                        ? planContent?.non_shareable_img?.data?.attributes?.url
                        : planContent?.shareable_img?.data?.attributes?.url
                    }
                    alt={
                      planContent?.non_shareable_img?.data?.attributes
                        ?.alternativeText
                    }
                    component="img"
                    mr="5px"
                    height="17px"
                  />
                  <Typography
                    className={styles.plan_text1}
                    display="flex"
                    alignItems="center"
                  >
                    {each.planData}
                    {each.dataUnit}
                    <span className={styles.plan_span_text}>
                      &nbsp;-{" "}
                      {each?.isUnlimited
                        ? planContent?.per_ln_txt
                        : planContent?.sharable_txt}
                    </span>
                  </Typography>
                </Box>
                <Divider
                  styles={{
                    margin: "10px auto 15px",
                  }}
                />
                <Typography
                  className={styles.plan_text2}
                  fontSize={isSimPaid ? "24px" : "32px"}
                  display="flex"
                  alignItems="center"
                >
                  {GetCurrencyFixedToTwo(
                    planPerLinesCost ? planPerLinesCost : 0
                  )}
                  <sub>
                    <Box
                      component="span"
                      sx={{
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Medium)",
                        lineHeight: "1.5",
                      }}
                    >
                      &nbsp;
                      {isSimPaid
                        ? planContent?.tax_nd_fee
                        : planContent?.per_ln_month}
                    </Box>
                  </sub>
                </Typography>
                {!isSimPaid && (
                  <>
                    <Box
                      className={styles.plan_text3}
                      dangerouslySetInnerHTML={{
                        __html: planContent?.plan_desc1?.replace(
                          "{0}",
                          GetCurrencyFixedToTwo(each.additionalLinePrice)
                        ),
                      }}
                    ></Box>
                    <ButtonCust
                      sx={{
                        m: "20px 0 8px",
                        fontFamily: "var(--font_family_Medium) !important",
                      }}
                      fullWidth={true}
                      variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                      onClick={(e: any) => {
                        each.maxLines >= selectedLine
                          ? planSelectionHandler(each, "Select Plan")
                          : "";
                      }}
                    >
                      {planContent?.btn}
                    </ButtonCust>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};
export default T3_PlanCard;
