import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { planFeaturesContent } from "../../../features/strapi/homePageSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
export type PlanFeaturesProps = {
  featureContent?: any;
};
const PlanFeaturesComponent = isTemplateExisits(Config.Home.PlanFeatures)
  ? require(`./${Config.Home.PlanFeatures.template}_PlanFeatures`).default
  : null;

export const PlanFeatures = () => {
  const { featuresContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const [cacheDataFeatures, setCacheDataFeatures] = useState<any>(null)
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache('PlanFeatHome', planFeaturesContent, setCacheDataFeatures, dispatch)
  }, []);


  useNonInitialEffect(() => {
    addMultipleCacheData('PlanFeatHome', featuresContent)
  }, [featuresContent])

  return (
    PlanFeaturesComponent?<PlanFeaturesComponent featureContent={cacheDataFeatures ? cacheDataFeatures : featuresContent} />:null
  );
};
