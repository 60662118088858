import { Grid, Typography } from "@mui/material";
import styles from "../S1_ViewCredits.module.scss";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ActivatedReferralsListItem } from "./ActivatedReferralsListItem";
import EnvConfig from "../../../../../config/env.config.json";
type Props = {
  setCurrentCard: Function;
  currentCard: string;
  content: any;
  activatedUsers: any;
};
export const ActivatedReferrals = ({
  setCurrentCard,
  currentCard,
  content,
  activatedUsers=[],
}: Props) => {
  return (
    <Grid
      container
      item
      width={
        currentCard === "ReferralCards"
          ? { xs: "92%", sm: "48%" }
          : { xs: "100%" }
      }
      display="flex"
      justifyContent="center"
      className={styles.card_item}
    >
      <Typography
        className={styles.card_heading}
        fontWeight="var(--font_weight_2)"
        fontFamily="var(--font_family_Medium)"
      >
        {content?.ar_ttl}
      </Typography>
      {activatedUsers?.length !== 0 ? (
        <ActivatedReferralsListItem
          viewMore={currentCard === "ReferralCards" ? true : false}
          activatedUsers={activatedUsers}
        />
      ) : (
        <Typography>{content?.no_content}</Typography>
      )}
      {activatedUsers?.length >
      EnvConfig?.NUMBER_OF_USERS_ACTIVATED_REFERRALS ? (
        <Typography
          className={styles.view_more}
          display={currentCard === "ReferralCards" ? "block" : "none"}
          onClick={() => setCurrentCard("ActivatedCreditsList")}
        >
          {content?.ar_btn_txt}
          <ArrowRightIcon sx={{ mt: "0px", height: "36px" }} />
        </Typography>
      ) : (
        ""
      )}
    </Grid>
  );
};
