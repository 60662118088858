import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwitchTabs from "../SwitchTabs";
import { PostActivationIRILDEnums } from "../../../../enums";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import UsageHistory from "./UsageHistory/UsageHistory";
import { WalletHistory } from "./WalletHistory";

const T1_WalletHistory = (props: any) => {
  const {
    content,
    lineDataForWallet,
    setIsViewHistory,
    handleBillCycleChange,
  } = props;

  const { back_txt, back_arrow, title, usage_history, transaction_history } =
    props.content;
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const onClickSwitchTabs = (type: any) => {
    if (type === PostActivationIRILDEnums.tab_left) {
      setShowTransactionHistory(false);
    } else {
      setShowTransactionHistory(true);
    }
  };
  return (
    <>
      <Box
        sx={{
          margin: { xs: "auto", sm: "auto", md: "auto" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "space-between",
          width: { xs: "auto", sm: "auto", md: "60%" },
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "30px",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& span": {
              fontFamily: "var(--font_family_Medium) !important",
              fontWeight: "var(--font_weight_5)",
            },
          }}
        >
          <span
            style={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              gap: "8px",
              left: "103px",
              cursor: "pointer",
              fontWeight: "var(--font_weight_3)",
            }}
            onClick={() => setIsViewHistory(false)}
          >
            <img
              src={back_arrow?.data?.attributes.url}
              alt={back_arrow?.data?.attributes.alternativeText}
              width={17.495}
              height={14.586}
            />
            {back_txt}
          </span>
          <span style={{ margin: "auto" }}>{title}</span>
        </Typography>
        <SwitchTabs
          title_left={usage_history}
          title_right={transaction_history}
          onClickSwitchTabs={onClickSwitchTabs}
          showPurchaseHistory={showTransactionHistory}
        />
        {showTransactionHistory ? (
          <TransactionHistory
            lineDataForWallet={lineDataForWallet}
            content={content}
            handleBillCycleChange={handleBillCycleChange}
          />
        ) : (
          <UsageHistory
            lineDataForWallet={lineDataForWallet}
            content={content}
            handleBillCycleChange={handleBillCycleChange}
          />
        )}
      </Box>
    </>
  );
};

export default T1_WalletHistory;
