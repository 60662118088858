import Config from "../../../config/app.config.json";
import { useEffect, useState } from "react";
import { negResultBottomStrapi } from "../../../features/strapi/billCalculatorSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const CompareNegResultBottom = isTemplateExisits(
  Config.BillCalculator.NegResultBottom
)
  ? require(`./${Config.BillCalculator.NegResultBottom.template}_NegResultBottom`)
      .default
  : null;

type Props = {
  pageHandler: any;
};
export const NegResultBottom = (props: Props) => {
  const { pageHandler } = props;
  const [cacheNegResultBottom, setCacheNegResultBottom] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { negResultBottomData } = useAppSelector(
    (state: any) => state.strapi.billCalculator
  );
  useEffect(() => {
    getCache(
      "NegResultBottomBillCalculator",
      negResultBottomStrapi,
      setCacheNegResultBottom,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData(
      "NegResultBottomBillCalculator",
      negResultBottomData
    );
  }, [negResultBottomData]);

 
  return (negResultBottomData || cacheNegResultBottom) ? (
    <CompareNegResultBottom
      content={cacheNegResultBottom ? cacheNegResultBottom : negResultBottomData}
      pageHandler={pageHandler}
    />
  ) : null;
};
