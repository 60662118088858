import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  missionContent: any;
  errorMissionContent: any;
  brandsContent: any;
  errorBrandsContent: any;
  letterContent: any;
  errorLetterContent: any;
  teamIntroContent: any;
  errorTeamIntroContent: any;
  teamFeedBackContent: any;
  errorTeamFeedBackContent: any;
};

const initialState: InitialState = {
  missionContent: null,
  errorMissionContent: null,
  brandsContent: null,
  errorBrandsContent: null,
  letterContent: null,
  errorLetterContent: null,
  teamIntroContent: null,
  errorTeamIntroContent: null,
  teamFeedBackContent: null,
  errorTeamFeedBackContent: null,
};

export const missionsContent = createAsyncThunk(
  "aboutUs/missionsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.AboutUs.Missions.template}${StrapiApiEndPoints.missionList}`,
      isStrapiApi: true,
    });
  }
);

export const fetchBrandsContent = createAsyncThunk(
  "aboutUs/brandsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.AboutUs.Brands.template}${StrapiApiEndPoints.brandList}`,
      isStrapiApi: true,
    });
  }
);

export const ceoLetterContent = createAsyncThunk(
  "aboutUs/ceoLetterContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.AboutUs.Letter.template}${StrapiApiEndPoints.ceoLetter}`,
      isStrapiApi: true,
    });
  }
);

export const meetTeamContent = createAsyncThunk(
  "aboutUs/teamIntroContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.AboutUs.TeamIntro.template}${StrapiApiEndPoints.teamIntro}`,
      isStrapiApi: true,
    });
  }
);

export const teamsFeedbackContent = createAsyncThunk(
  "aboutUs/teamsFeedbackContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.AboutUs.TeamFeedback.template}${StrapiApiEndPoints.teamFeedBack}`,
      isStrapiApi: true,
    });
  }
);

const aboutUSlice = createSlice({
  name: "aboutUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      missionsContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.missionContent = action.payload?.data?.data?.attributes;
        state.errorMissionContent = null;
      }
    );
    builder.addCase(missionsContent.rejected, (state, action) => {
      state.missionContent = null;
      state.errorMissionContent = action.error || "Something went wrong";
    });
    builder.addCase(fetchBrandsContent.fulfilled, (state, action) => {
      state.brandsContent = action.payload?.data?.data?.attributes;
      state.errorBrandsContent = null;
    });
    builder.addCase(fetchBrandsContent.rejected, (state, action) => {
      state.brandsContent = null;
      state.errorBrandsContent = action.error || "Something went wrong";
    });
    builder.addCase(
      ceoLetterContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.letterContent = action.payload?.data?.data?.attributes;
        state.errorLetterContent = null;
      }
    );
    builder.addCase(ceoLetterContent.rejected, (state, action) => {
      state.letterContent = null;
      state.errorLetterContent = action.error || "Something went wrong";
    });
    builder.addCase(
      meetTeamContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.teamIntroContent = action.payload?.data?.data?.attributes;
        state.errorTeamIntroContent = null;
      }
    );
    builder.addCase(meetTeamContent.rejected, (state, action) => {
      state.teamIntroContent = null;
      state.errorTeamIntroContent = action.error || "Something went wrong";
    });
    builder.addCase(
      teamsFeedbackContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.teamFeedBackContent = action.payload?.data?.data?.attributes;
        state.errorTeamFeedBackContent = null;
      }
    );
    builder.addCase(teamsFeedbackContent.rejected, (state, action) => {
      state.teamFeedBackContent = null;
      state.errorTeamFeedBackContent = action.error || "Something went wrong";
    });
  },
});

export default aboutUSlice.reducer;
