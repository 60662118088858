import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
    primaryValuesData: any;
    errorPrimaryValuesData: any;
};

const initialState: InitialState = {
    primaryValuesData: null,
    errorPrimaryValuesData: null
};

// Generates pending, fulfilled and rejected action types
export const primaryValuesContent = createAsyncThunk(
    "primaryValues/primaryValuesContent",
    () => {
        return apiJunction.makeRequest({
            method: "get",
            url: `/${Config.AboutUs.Values.template}${StrapiApiEndPoints.primaryValues}`,
            isStrapiApi: true,
        });
    }
);

const strapiPrimaryValuesSlice = createSlice({
    name: "primaryValues",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(
            primaryValuesContent.fulfilled,
            (state: InitialState, action: PayloadAction<any>) => {
                state.primaryValuesData = action?.payload?.data?.data?.attributes;
                state.errorPrimaryValuesData = null;
            }
        );
        builder.addCase(primaryValuesContent.rejected, (state: InitialState, action: any) => {
            state.primaryValuesData = null;
            state.errorPrimaryValuesData = action.error || "Something went wrong";
        });
    },
});

export default strapiPrimaryValuesSlice.reducer;