import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import HmBestFitComponent from "./T1_HmBestFit";
import { homeBestfitContent } from "../../../features/strapi/homePageSlice";
import { getMultipleCacheData } from "../../../utils/commonFunctions/getMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";

export const HmBestFit = () => {
  const { hmBestfitContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const [cacheDataBest, setCacheDataBest] = useState<any>(null)
  const dispatch = useAppDispatch();

  useEffect(() => {
    getCache('HmBestFit',homeBestfitContent,setCacheDataBest,dispatch)
  }, [])
 
  useNonInitialEffect(() => {
    addMultipleCacheData('HmBestFit',hmBestfitContent)
  }, [hmBestfitContent])

  return (
  <HmBestFitComponent hmBestfitContent={cacheDataBest ? cacheDataBest : hmBestfitContent} />
  );
};
