import { Box, Typography } from "@mui/material";
import {
  ButtonCust,
  LinearProgressBarCust,
  TextFieldCust,
} from "../../../widgets";
import EnvConfig from "../../../../config/env.config.json";
import styles from "./S2_ActivationStep2.module.scss";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrentNumberForm from "./CurrentNumberForm";
import PinHelperText from "./PinHelperText";
import AccountPinHelperText from "./AccountPinHelperText";
import { gaCategory } from "../../../../Types/GoogleAnalytics";
import { useAppSelector } from "../../../../app/hooks";

export const ActivationStep2 = (props: any) => {
  const {
    content,
    GAAndFBEventsHandler,
    currentOperator,
    zipcodeChangeHandler,
    zipCodeformik,
    setOpenSupportDialog,
    currentNum,
    clickHandlerForPortNum,
    newNumHandler,
    handleBack,
  } = props;
  const [newNumber, setNewNumber] = useState<any>(false);

  const { sp2_title, sp2_main_title, c_num, step_txt, num_pref } = content;
  const { zipCodeStatus, zipCodeLoader } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { desc, zc_ph, zc_btn, msgs } = content?.zp_md;
  const { isValid } = zipCodeStatus || {};
  return (
    <>
      <Box display="flex" alignItems="center" pt={{ xs: "25px", sm: "50px" }}>
        <ArrowBackIcon
          sx={{
            ml: { md: "30px", sm: "25px", xs: "4%" },
            mr: "20px",
            cursor: "pointer",
          }}
          className={styles.arrow_icon}
          onClick={handleBack}
        />
        <Typography variant="h3" fontSize="30px !important">
          {sp2_main_title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
        }}
        className={styles.activation_step2_main}
      >
        <Box
          sx={{
            flexBasis: { md: "50%" },
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            p: { md: "72px 0px 50px 100px", xs: "50px 20px 0px 20px" },
          }}
        >
          <Box>
            <Box
              component="img"
              sx={{ width: { xs: "100%", sm: "478px" } }}
              src={content?.act_img?.data?.attributes?.url}
              alt={content?.act_img?.data?.attributes?.alternativeText}
            />
          </Box>
        </Box>
        <Box
          sx={{
            flexBasis: { md: "50%" },
            p: { sm: "16px 0 0 96px", xs: "20px" },
          }}
        >
          <Box>
            <Typography
              className={styles.title}
              sx={{
                fontSize: { xs: "22px", sm: "30px" },
                m: " 20px 0 15px !important",
                textAlign: { sm: "left", xs: "center" },
              }}
            >
              {currentNum ? c_num?.title : sp2_title}
            </Typography>
            <Typography
              className={styles.description}
              sx={{
                fontSize: { xs: "18px" },
                textAlign: { sm: "left", xs: "center" },
              }}
            >
              {step_txt}
            </Typography>
            <Box
              sx={{
                width: { md: "50%", sm: "400px", xs: "100%" },
                m: "20px 0px 25px",
              }}
            >
              <LinearProgressBarCust value={50} variant={"determinate"} />
            </Box>
          </Box>
          {!currentNum ? (
            <>
              {!newNumber && !currentNum ? (
                /* step 2 option selection */
                <>
                  <Typography className={styles.sub_title}>
                    {num_pref?.desc}
                  </Typography>
                  <ButtonCust
                    sx={{ mb: "16px" }}
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    onClick={() => {
                      // setviewCurrentCarrier(true);
                      clickHandlerForPortNum();
                    }}
                  >
                    {num_pref?.c_num_btn}
                  </ButtonCust>
                  <Typography className={styles.description_sub}>
                    {num_pref?.txt1}
                  </Typography>
                  <ButtonCust
                    sx={{ mb: "16px" }}
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    onClick={() => {
                      setNewNumber(true);
                      newNumHandler();
                      GAAndFBEventsHandler(
                        gaCategory.activation,
                        num_pref?.new_num_btn
                      );
                    }}
                  >
                    {num_pref?.new_num_btn}
                  </ButtonCust>
                  <Typography className={styles.description_sub}>
                    {num_pref?.txt2}
                  </Typography>
                </>
              ) : (
                <Box
                  className={styles.number_preference}
                  sx={{ textAlign: { xs: "center", sm: "left" } }}
                  pb={{ xs: "25px", md: "50px" }}
                >
                  <Typography className={styles.sub_title}>{desc}</Typography>
                  <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                    <TextFieldCust
                      name="zipCode"
                      type="text"
                      placeholder={zc_ph}
                      maxlength={5}
                      onChange={(e) => zipcodeChangeHandler(e)}
                      value={zipCodeformik.values.zipCode}
                      error={
                        zipCodeformik.errors?.zipCode || isValid === false
                          ? true
                          : false
                      }
                      helperText={
                        zipCodeformik.errors?.zipCode ||
                        (isValid === false && msgs?.invalid_msg)
                      }
                      onKeyUp={(e: any) => {
                        if (e.key === "Enter") {
                          zipCodeformik.handleSubmit();
                        }
                      }}
                    />
                    <Typography className={styles.limit_div}>
                      {zipCodeformik.values.zipCode?.length}/5
                    </Typography>
                  </Box>
                  <Box>
                    <ButtonCust
                      variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                      onClick={() => {
                        zipCodeformik.handleSubmit();
                        GAAndFBEventsHandler(
                          gaCategory.activation,
                          "Check ZIP Code"
                        );
                      }}
                      disabled={
                        zipCodeformik.values?.zipCode.length === 0
                          ? true
                          : !zipCodeformik?.isValid
                      }
                      loading={zipCodeLoader}
                    >
                      {zc_btn}
                    </ButtonCust>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <>
              <Box className={styles.cur_carrier_leftdiv} pb="30px">
                <CurrentNumberForm styles={styles} {...props} />
              </Box>
              {currentNum && !currentOperator?.acctNumMsg && (
                <Box width={{ xs: "100%", sm: "70%" }} textAlign="left">
                  <PinHelperText
                    c_num={c_num}
                    styles={styles}
                    setOpenSupportDialog={setOpenSupportDialog}
                  />
                </Box>
              )}
              {currentNum && (
                <Box width={{ xs: "100%", sm: "70%" }} textAlign="left">
                  <AccountPinHelperText
                    currentOperator={currentOperator}
                    styles={styles}
                    setOpenSupportDialog={setOpenSupportDialog}
                    {...props}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ActivationStep2;
