import { Box, Grid, Rating } from "@mui/material";
import { ButtonCust, Share } from "../../widgets";
import Config from "../../../config/env.config.json";
import styles from "./DiscoverCard.module.scss";
import { DiscoverCardProps } from "../Discover";

export const DiscoverCard = (discoverData: DiscoverCardProps) => {
  let props = discoverData;

  return (
    <>
      <Grid
        item
        md={3}
        xs={6}
        sm={4}
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Box
          className={styles.discover_card}
          sx={{
            fontFamily: "var(--font_family_Semibold)",
            color: "var(--text_color)",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            px: { xs: "10px", sm: "10px" },
            py: { xs: "10px", sm: "20px" },
          }}
          key={props.index}
        >
          <Box textAlign={{ xs: "center" }}>
            <Box
              mt="5px"
              component="img"
              src={props.image}
              alt="discover"
              className={
                props.title === "Mobile handset"
                  ? styles.discoverCardImg
                  : styles.discoverCardImgSubs
              }
            />
          </Box>
          <Box
            textAlign="center"
            className={
              props.value
                ? styles.dicoverCardContentWrapper + " " + styles.DiscoverWrap
                : styles.dicoverCardContentWrapper
            }
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              fontSize: "16px",
              color: "var(--text_color)",
              mt: "10px",
              width: "100%",
            }}
          >
            <Box className={styles.DiscoverCardText}>
              {props.discoverCardText ? props.discoverCardText : null}
            </Box>
          </Box>
          <Box
            textAlign="center"
            sx={{
              pt: "5px",
              height: "36px",
              width: "100%",
            }}
          >
            {props.value ? (
              <Rating
                name="read-only"
                value={props.value}
                precision={0.5}
                readOnly
              />
            ) : null}
          </Box>

          <Share
            share_text={props.content?.share_text}
            share_icon={props.content?.share_icon?.data?.attributes?.url}
            invite_friends_text={props.content?.popover_title}
            facebook_icon={props.content?.fb_logo?.data?.attributes?.url}
            twitter_icon={props.content?.twitter_logo?.data?.attributes?.url}
            mail_icon={props.content?.mail_logo?.data?.attributes?.url}
            facebook_label={props.content?.fb_text}
            twitter_label={props.content?.twitter_text}
            mail_label={props.content?.mail_text}
            facebook_action={props}
            twitter_action={props}
            email_action={props}
            Share_Icon_alt={
              props.content?.share_icon?.data?.attributes?.alternativeText
            }
            mail_icon_alt={
              props.content?.mail_logo?.data?.attributes?.alternativeText
            }
            facebook_icon_alt={
              props.content?.fb_logo?.data?.attributes?.alternativeText
            }
            twitter_icon_alt={
              props.content?.twitter_logo?.data?.attributes?.alternativeText
            }
          />
          <Box
            textAlign="center"
            sx={{
              flexWrap: "wrap",
              mt: "15px",
              height: "50px",
              width: "100%",
            }}
          >
            <ButtonCust
              variantType={Config.PRIMARY_BUTTON_TYPE}
              className={styles.button_text}
              onClick={
                props.disabled ? () => null : props.discoverButtonHandler
              }
            >
              {props.buttonText}
            </ButtonCust>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
