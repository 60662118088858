import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { primaryValuesContent } from "../../../features/strapi/primaryValuesSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const PrimaryValuesComponent = isTemplateExisits(Config.AboutUs.Values)
  ? require(`./${Config.AboutUs.Values.template}_PrimaryValues`).default
  : null;

export const PrimaryValues = () => {
  const [cachePrimaryValues, setCachePrimaryValues] = useState<any>(null);
  const { primaryValuesData, errorPrimaryValuesData } = useAppSelector(
    (state: any) => state.strapi.PrimaryValues
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("PrimaryValuesAboutUs", primaryValuesContent, setCachePrimaryValues, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("PrimaryValuesAboutUs", primaryValuesData);
  }, [primaryValuesData]);

  return (primaryValuesData || cachePrimaryValues) ? (
    <PrimaryValuesComponent
      content={cachePrimaryValues ? cachePrimaryValues : primaryValuesData}
    />
  ) : errorPrimaryValuesData ? (
    <div>{errorPrimaryValuesData}</div>
  ) : null;
};
