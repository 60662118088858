import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { Divider } from "../../../widgets";

type Props = {
  content: any;
  display: any;
};
const AddNewCardLoader = ({ content, display }: Props) => {
  const { card_ld_ttl, card_ld_dsc } = content;
  return (
    <Dialog scroll="body" open={display} fullWidth maxWidth="sm">
      <DialogContent>
        <Typography
          sx={{
            fontFamily: "var(--font_family_Bold)",
            fontWeight: "var(--font_weight_5)",
            fontSize: "26px",
            textAlign: "center",
            pb: "16px",
          }}
        >
          {card_ld_ttl}
        </Typography>
        <Divider />
        <Box display="flex" justifyContent="center" my="20px">
          <CircularProgress
            sx={{ color: "var(--primary_color)" }}
            size="60px"
          />
        </Box>
        <Typography
          sx={{
            fontFamily: "var(--font_family_Medium)",
            fontWeight: "var(--font_weight_1)",
            fontSize: "16px",
            pb: "10px",
            textAlign: "center",
          }}
        >
          {card_ld_dsc}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewCardLoader;
