import { Grid, Typography, Box } from "@mui/material";
import styles from "./ComparePlanDialog.module.scss";
import { Divider, SimpleModal, ButtonCust } from "../../../widgets";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import EnvConfig from "../../../../config/env.config.json";
import { CardTypes } from "../../../../enums";
const wowUnlimitedCoupons = EnvConfig?.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../../config/discountCouponsUnlimited.json")
  : null;
const wowRegularCoupons = EnvConfig?.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../../config/discountCouponsRegular.json")
  : null;

type comparePlanDialogProps = {
  onClose: any;
  open: boolean;
  currentPlan: any;
  totalActivitedUsers: any;
  selectedPlan: any;
  confirmPlanChangeHandler: any;
  newBillStartMonth: any;
  mainCard: any;
  updateLaterHandler: any;
  planChangeLoader: any;
  cardChangeHandler: any;
  isCardExpired: any;
  ChangePlanDetails: any;
  content?: any;
  cppdContent?: any;
  discount?: any;
};

let currentdetails: any = localStorage.getItem("customerDetail");
currentdetails = JSON.parse(currentdetails);

export const ComparePlanDialog = (props: comparePlanDialogProps) => {
  const {
    open,
    onClose,
    currentPlan,
    totalActivitedUsers,
    selectedPlan,
    confirmPlanChangeHandler,
    newBillStartMonth,
    mainCard,
    updateLaterHandler,
    planChangeLoader,
    cardChangeHandler,
    isCardExpired,
    ChangePlanDetails,
    content,
    cppdContent,
    discount,
  } = props;
  const {
    change_plan,
    current_plan,
    new_plan,
    note,
    billing_cyle_note,
    upgrade_balance,
    plan_review,
    diff_amount,
    tax_fees,
    line_charges,
    update_now,
    card_num,
    update_later,
    card_charged,
    for_text,
    updte_now,
    pln_upgrde,
    update_txt,
    savings_txt,
    savings_txt2,
  } = content?.compare_plan;
  const {
    planDiffAmount,
    diffTax,
    planUpgradeBalance,
    currentPlanCost,
    newPlanCost,
  } = ChangePlanDetails;
  const paymentModalData = [
    {
      text: diff_amount,
      price: planDiffAmount,
    },
    { text: tax_fees, price: diffTax },
    {
      text: upgrade_balance,
      price: planUpgradeBalance,
    },
  ];
  let savings_text =
    savings_txt2 &&
    savings_txt2
      ?.replace(
        "{couponName}",
        selectedPlan?.isUnlimited
          ? wowUnlimitedCoupons?.data?.name
          : wowRegularCoupons?.data?.name
      )
      ?.replace("{discount}", GetCurrencyFixedToTwo(discount));
  return (
    <>
      <SimpleModal
        isShowModal={open}
        onCloseModal={onClose}
        showClose={true}
        sx={{
          width: "500px !important",
          p: "16px",
          borderRadius: "4.8px",
          maxWidth: { xs: "95%", sm: "500px" },
          top: EnvConfig?.SHOW_UPDATE_LATER_AND_NOW_TEXT ? "65%" : "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className={styles.payment_modal}
      >
        <Grid
          className={styles.inner_wrapper}
          sx={{
            p: { sm: "30px 30px 0px 30px", xs: "30px 0px 0px 0px" },
          }}
        >
          <Typography className={styles.modal_header}>{change_plan}</Typography>
          <Divider styles={{ mb: "30px" }} />
          <Grid className={styles.current_plan}>
            <Typography className={styles.plan_header}>
              {current_plan}
            </Typography>
            <Grid className={styles.plan_sub_content}>
              <Typography>
                {currentPlan}&nbsp;{for_text}&nbsp;{totalActivitedUsers}{" "}
              </Typography>
              <Typography>{GetCurrencyFixedToTwo(currentPlanCost)}</Typography>
            </Grid>
          </Grid>
          <hr />
          <Grid className={styles.new_plan}>
            <Typography className={styles.plan_header}>{new_plan}</Typography>
            <Grid className={styles.plan_sub_content}>
              <Typography>
                {" "}
                {selectedPlan?.displayName}&nbsp;{for_text}&nbsp;
                {totalActivitedUsers}{" "}
              </Typography>
              <Typography> {GetCurrencyFixedToTwo(newPlanCost)}</Typography>
            </Grid>
          </Grid>
          <hr />
          {paymentModalData.map((data: any) =>
            data.text === "Plan Upgrade Balance" ? (
              <>
                <Grid
                  className={styles.plan_sub_content}
                  sx={{ backgroundColor: "var(--white_1)", p: "15px 5px 3px" }}
                >
                  <Typography
                    sx={{ color: "var(--primary_brand_color) !important" }}
                  >
                    {data.text}
                  </Typography>
                  <Typography
                    sx={{ color: "var(--primary_brand_color) !important" }}
                  >
                    {data?.price}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid className={styles.plan_sub_content}>
                  <Typography>{data.text} </Typography>
                  <Typography>{data.price}</Typography>
                </Grid>
              </>
            )
          )}
          <Box className={styles.modal_content2}>
            {EnvConfig?.SHOW_UPDATE_LATER_AND_NOW_TEXT && (
              <Typography
                className={styles.account_note}
                color="var(--text_color)"
                fontFamily="var(--font_family_Semibold) !important"
              >
                <Box component="span" fontWeight="bold">
                  {updte_now}&nbsp;
                </Box>
                {pln_upgrde}&nbsp;
                <Box component="span" fontWeight="bold">
                  {planUpgradeBalance}&nbsp;
                </Box>
                {update_txt}
              </Typography>
            )}
            {billing_cyle_note || plan_review ? (
              <Typography
                className={styles.account_note}
                fontFamily="var(--font_family_Semibold) !important"
              >
                <Box component="span" fontWeight="bold">
                  {note}&nbsp;
                </Box>
                {billing_cyle_note}
                {billing_cyle_note && (
                  <Box component="span" fontWeight="bold">
                    ( {newBillStartMonth} )&nbsp;
                  </Box>
                )}
                {plan_review}
                <Box component="span" fontWeight="bold">
                  {billing_cyle_note
                    ? GetCurrencyFixedToTwo(newPlanCost)
                    : `(${planUpgradeBalance})`}
                  &nbsp;
                </Box>
                {line_charges}
              </Typography>
            ) : null}
            <Typography>{card_charged}</Typography>
            <Box className={styles.card_details} py="10px">
              <img
                src={
                  mainCard && mainCard.type.toUpperCase() === CardTypes.VISA
                    ? cppdContent?.visa_img?.data?.attributes?.url
                    : mainCard && mainCard.type.toUpperCase() === CardTypes.JCB
                    ? cppdContent?.jcb_crd_img?.data?.attributes?.url
                    : mainCard &&
                      (mainCard.type.toUpperCase() === CardTypes.AMERICANEXPRESS || mainCard.type.toUpperCase() === CardTypes.AMEX)
                    ? cppdContent?.ae_card_img?.data?.attributes?.url
                    : mainCard &&
                      mainCard.type.toUpperCase() === CardTypes.MASTERCARD
                    ? cppdContent?.master_crd?.data?.attributes?.url
                    : cppdContent?.discover?.data?.attributes?.url
                }
                alt={cppdContent?.visa_img?.data?.attributes?.alternativeText}
              />
              <Typography px="10px">
                {card_num}
                {mainCard?.ccNumberMask}
              </Typography>
              <Typography
                sx={{
                  color: "var(--dusk)",
                  cursor: "pointer",
                  fontFamily: "var(--font_family_Bold) !important",
                }}
                onClick={cardChangeHandler}
              >
                {cppdContent?.buy_data?.change_text}
              </Typography>
            </Box>
            {currentdetails?.isWowHSDCustomer && (
              <Grid
                border="1px dashed var(--text_color)"
                borderRadius="5px"
                p="10px 65px 10px 10px"
                mb="14px"
              >
                <Typography
                  textAlign="left"
                  fontFamily="var(--font_family_Medium)"
                  color="var(--primary_brand_color)"
                  fontSize="14px"
                >
                  {savings_txt}
                  <br />
                  {savings_text}
                </Typography>
              </Grid>
            )}
            <Grid container className={styles.button_wrapper} my="10px">
              <Grid item xs={6}>
                <Typography
                  className={styles.update_later}
                  onClick={isCardExpired ? null : updateLaterHandler}
                >
                  {update_later}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ButtonCust
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  sx={{ minWidth: "100% !important" }}
                  onClick={() => {
                    confirmPlanChangeHandler("now");
                  }}
                  loading={planChangeLoader}
                  disabled={isCardExpired}
                >
                  {update_now}
                </ButtonCust>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </SimpleModal>
    </>
  );
};
