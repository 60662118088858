import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import styles from "./AutoPayCard.module.scss";
import { SwitchCust } from "../../widgets";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import { getAutoPayCContent } from "../../../features/strapi/checkoutSlice";
import { RoutingURLs } from "../../../config/RoutingURLs";
import clsx from "clsx";

interface AutoPayCardProps {
  autoPayContentState?: any;
  isAutoPay?: boolean;
  isSaveCard?: boolean;
  onChangeAutoPayment?: any;
  onChangeSaveCard?: any;
  containerBorder?: boolean;
  note?: boolean;
  extraStyle?: string;
  saveCardDisable?: boolean;
}
export const AutoPayCard = (props: AutoPayCardProps) => {
  const {
    autoPayContentState,
    isAutoPay,
    isSaveCard,
    onChangeAutoPayment,
    onChangeSaveCard,
    containerBorder,
    note,
    extraStyle,
    saveCardDisable,
  } = props;
  return (
    <>
      <Box
        style={{
          border: containerBorder ? "1px dashed var(--text_color)" : "none",
        }}
        className={clsx(styles.auto_pay_card, extraStyle || {})}
      >
        <Box className={styles.auto_pay_container}>
          <Box className={styles.heading}>{autoPayContentState?.title}</Box>
          <Box display="flex" alignItems="center" margin="2px 0 10px">
            <SwitchCust
              checked={Boolean(isAutoPay)}
              handleSwitchToggle={onChangeAutoPayment}
              name="sameAddress"
            />
            <Box className={styles.label}>{autoPayContentState?.label1}</Box>
          </Box>
          <Box display="flex" alignItems="center">
            <SwitchCust
              checked={Boolean(isSaveCard)}
              handleSwitchToggle={onChangeSaveCard}
              name="sameAddress"
              isDisabled={saveCardDisable || false}
            />
            <Box className={styles.label}>{autoPayContentState?.label2}</Box>
            <span
              className={styles.link_text}
              onClick={() => window.open(RoutingURLs.terms, "_blank")}
            >
              &nbsp; {autoPayContentState?.t_c_nav_item}
            </span>
          </Box>
        </Box>
      </Box>

      {note && (
        <Box className={styles.note_container}>
          <Box className={styles.heading}>{autoPayContentState?.note_txt}</Box>
          <Box className={styles.note_err_desc}>
            {isSaveCard && isAutoPay ? (
              <Box sx={{ color: "var(--text_color)" }}>
                {autoPayContentState?.en_a_p_note}
              </Box>
            ) : !isSaveCard && !isAutoPay ? (
              autoPayContentState?.dis_s_c_note
            ) : isSaveCard && !isAutoPay ? (
              autoPayContentState?.dis_a_p_note
            ) : null}
          </Box>
        </Box>
      )}
    </>
  );
};

export default AutoPayCard;
