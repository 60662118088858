import { useEffect } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { teamsFeedbackContent } from "../../../features/strapi/aboutUsSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import React from "react";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";

const TeamFeedbackComponent = isTemplateExisits(Config.AboutUs.TeamFeedback)
  ? require(`./${Config.AboutUs.TeamFeedback.template}_TeamFeedback`).default
  : null;
export type TeamFeedBackProps = {
  teamFeedBackContent?: any;
};

export const TeamFeedback = () => {
  const [content, setContent] = React.useState<any>(null);
  const [cacheTeamFeedBack, setCacheTeamFeedBack] = React.useState<any>(null);

  const { teamFeedBackContent } = useAppSelector(
    (state: any) => state.strapi.aboutUs
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache(
      "TeamFeedBackAboutUs",
      teamsFeedbackContent,
      setCacheTeamFeedBack,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    setContent(teamFeedBackContent);
    addMultipleCacheData("TeamFeedBackAboutUs", teamFeedBackContent);
  }, [teamFeedBackContent]);

  return teamFeedBackContent || cacheTeamFeedBack ? (
    <TeamFeedbackComponent
      teamFeedBackContent={
        cacheTeamFeedBack ? cacheTeamFeedBack : teamFeedBackContent
      }
    />
  ) : null;
};
