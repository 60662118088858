import styles from "./S1_Discover.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { DiscoverProps } from "./Discover";
import { DiscoverCard } from "./DiscoverCard/DiscoverCard";

const DiscoverCmp = (props: DiscoverProps) => {
  const { finalData, content, discoverButtonHandler } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: { xs: "50px", sm: "45px" },
        }}
      >
        <Typography
          variant="h3"
          component={"h3"}
          className={styles.discover_page_title}
        >
          {content?.title}
        </Typography>
        <Box className={styles.discover_page_line}></Box>

        {finalData &&
          finalData.map((item: any, index1: number) => {
            return (
              item.data &&
              item.data.length > 0 && (
                <Box key={index1}>
                  <Box
                    textAlign="center"
                    sx={{
                      fontFamily: "var(--font_family_Bold)",
                      fontSize: "16px",
                      color: "var(--text_color)",
                      width: "100%",
                      mb: "5px",
                    }}
                  >
                    {item.title}
                  </Box>
                  <Grid>
                    <Grid
                      container
                      flexDirection="row"
                      justifyContent={{
                        xs: "flex-start",
                        sm: "center",
                        md: "center",
                      }}
                      flexWrap="wrap"
                      padding={{
                        xs: "0 10px",
                        sm: "10px 25px",
                        lg: "0px 180px 40px",
                        md: "0 60px 40px",
                      }}
                    >
                      {item.data.map((it: any, index: number) => {
                        return (
                          <DiscoverCard
                            key={index}
                            index={index}
                            name={it.title}
                            image={it.itemImage}
                            discoverCardText={it.title}
                            value={it.rating ? it.rating : ""}
                            buttonText={it.cta && it.cta.text}
                            url={it.cta && it.cta.action}
                            title={item.title}
                            content={content}
                            discoverButtonHandler={() =>
                              discoverButtonHandler(
                                it.cta && it.cta.action,
                                it.cta.text
                              )
                            }
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                </Box>
              )
            );
          })}
      </Box>
    </>
  );
};
export default DiscoverCmp;
