import { validateEmail } from "../../../utils/commonFunctions/ValidateEmail";
import { ButtonCust, TextFieldCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { Box, Grid } from "@mui/material";
import styles from "./S1_LeadGeneration.module.scss";
import { LeadGenProps } from "./LeadGeneration";

const LeadGenerationComponent = (props: LeadGenProps) => {
  const {
    heading,
    sub_heading,
    place_holder,
    button_text,
    success_text,
    intouch_text,
    check_icon,
  } = props.leadContent || {};
  return (
    <Box sx={{ minHeight: { xs: "366px", sm: "332px", md: "316px" } }}>
      <Grid
        container
        className={styles.lead_generation}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: 8.75,
        }}
      >
        <Box
          data-testid="title"
          className={styles.lead_generation_title}
          sx={{ fontSize: { xs: "22px", sm: "26px" } }}
        >
          {heading}
        </Box>
        <Box
          className={styles.lead_generation_subtitle}
          sx={{ mt: 2, fontSize: { xs: "16px", sm: "20px" } }}
        >
          {sub_heading}
        </Box>
        <Box sx={{ my: 2.5 }} className={styles.lead_generation_line}></Box>
        {props.emailSharedCoverage ? (
          <>
            <Grid
              container
              xs={11}
              sm={6}
              md={4.5}
              lg={3.8}
              xl={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={styles.email_success}
              >
                <Box
                  component={"img"}
                  sx={{ width: "20px", height: "20px", mx: 0.5 }}
                  src={check_icon?.data?.attributes?.url}
                  alt={check_icon?.data?.attributes?.alternativeText}
                />
                <Box
                  className={styles.email_success_text}
                  sx={{ fontSize: "18px" }}
                >
                  {success_text}
                </Box>
              </Box>
              <Box
                className={styles.keep_in_touch_text}
                sx={{ fontSize: "14px", textAlign: "center" }}
              >
                {intouch_text}
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              alignItems: { xs: "center", sm: "start" },
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                props.submitHandler(e);
              }
            }}
          >
            <Grid
              sx={{
                mr: { sm: 2 },
                mb: { xs: 2, md: 0 },
                width: { xs: "90%", sm: "442px" },
              }}
            >
              <TextFieldCust
                value={props.email}
                label={place_holder}
                onChange={props.emailHandler}
                error={
                  (props.email.length >= 1 && !validateEmail(props.email)) ||
                  (props.submitBtnClick && !props.email) ||
                  (props.submitBtnClick &&
                    props.email !== "" &&
                    !validateEmail(props.email))
                }
                helperText={
                  (props.email.length >= 1 || props.submitBtnClick) &&
                  props.helperTextHandler()
                }
              />
            </Grid>
            <Box>
              <ButtonCust
                className={styles.lead_generate_t1}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={(e: any) => props.submitHandler(e)}
                loading={props.submitClick}
              >
                {button_text}
              </ButtonCust>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LeadGenerationComponent;
