import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ulPromoPlanFeaturesContent } from "../../../features/strapi/homePageSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";

const PlanFeaturesComponent = isTemplateExisits(Config.ULPromo.PlanFeatures)
  ? require(`../../home/PlanFeatures/${Config.ULPromo.PlanFeatures.template}_PlanFeatures`)
      .default
  : null;

export const ULPromoPlanFeature = () => {
  const { ulPromoFeaturesContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const [cacheDataFeatures, setCacheDataFeatures] = useState<any>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache(
      "ULPlanFeatHome",
      ulPromoPlanFeaturesContent,
      setCacheDataFeatures,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("ULPlanFeatHome", ulPromoFeaturesContent);
  }, [ulPromoFeaturesContent]);

  return cacheDataFeatures || ulPromoFeaturesContent ? (
    <PlanFeaturesComponent
      featureContent={
        cacheDataFeatures ? cacheDataFeatures : ulPromoFeaturesContent
      }
    />
  ) : null;
};
