import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { meetTheRecipientsContent } from "../../../features/strapi/giveBackslice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
const MeetTheRecipientsComponent = isTemplateExisits(
  Config.GiveBack.MeetReceipents
)
  ? require(`./${Config.GiveBack.MeetReceipents.template}_MeetTheRecipients`)
      .default
  : null;

export const MeetTheRecipients = () => {
  const [cacheDataGbmeetRecipients, setCacheDataGbmeetRecipients] = useState<any>(null);
  const { meetTheRecipients } = useAppSelector(
    (state: any) => state.strapi.giveBack
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("MeetRecipientsGiveBack", meetTheRecipientsContent, setCacheDataGbmeetRecipients, dispatch)
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData('MeetRecipientsGiveBack', meetTheRecipients)
  }, [meetTheRecipients])
  return (cacheDataGbmeetRecipients || meetTheRecipients) && <MeetTheRecipientsComponent content={cacheDataGbmeetRecipients ? cacheDataGbmeetRecipients : meetTheRecipients} />;
};
