import { Box, Typography } from "@mui/material";
import {
  ButtonCust,
  LinearProgressBarCust,
  TextFieldCust,
} from "../../../widgets";
import Config from "../../../../config/env.config.json";
import styles from "./S2_ActivationStep1.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IMEIFailureModal } from "./IMEIFailureModal";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

const ActivationStep1 = (props: any) => {
  const {
    handleStep,
    currentLine,
    numChangeHandler,
    content,
    deviceComp,
    setDeviceComp,
    formik,
    btnLoader,
    modalStatus,
    setModalStatus,
  } = props;

  return (
    <>
      <Box display="flex" alignItems="center" pt={{ xs: "25px", sm: "50px" }}>
        <ArrowBackIcon
          sx={{
            ml: { md: "30px", sm: "25px", xs: "4%" },
            mr: "20px",
            cursor: "pointer",
          }}
          className={styles.arrow_icon}
          onClick={() => handleStep(false)}
        />
        <Typography variant="h3" fontSize="30px !important">
          {content?.sp1_main_title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            flexBasis: { md: "50%" },
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            p: { md: "72px 0px 50px 100px", xs: "50px 20px 0px 20px" },
          }}
        >
          <Box>
            <Box
              component="img"
              sx={{ width: { xs: "100%", sm: "478px" } }}
              src={content?.act_img?.data?.attributes?.url}
              alt={content?.act_img?.data?.attributes?.alternativeText}
            ></Box>
          </Box>
        </Box>
        <Box
          sx={{
            flexBasis: { md: "50%" },
            p: { sm: "16px 0 50px 96px", xs: "25px" },
          }}
        >
          <Typography
            className={styles.title}
            sx={{
              fontSize: { xs: "22px", sm: "30px" },
              m: " 20px 0 15px !important",
              textAlign: { sm: "left", xs: "center" },
            }}
          >
            {content?.sp1_title}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "18px" },
              textAlign: { sm: "left", xs: "center" },
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {content?.step_txt}
          </Typography>

          <Box
            sx={{
              width: { md: "50%", sm: "400px", xs: "100%" },
              m: "20px 0px 25px",
            }}
            className={styles.progress_bar_div}
          >
            <LinearProgressBarCust value={25} variant={"determinate"} />
          </Box>
          {!deviceComp ? (
            <>
              <Typography className={styles.sub_heading}>
                {BrandNameUpdate(content?.imei_form?.desc)}
              </Typography>
              <Box sx={{ my: "20px", width: { xs: "100%", sm: "50%" } }}>
                <TextFieldCust
                  value={formik.values.imei}
                  name="imei"
                  onChange={(e: any) => numChangeHandler(e, formik)}
                  type={"text"}
                  label={content?.imei_form?.txt_ph}
                  maxlength={15}
                  error={formik.errors?.imei ? true : false}
                  helperText={formik.errors?.imei}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter") {
                      formik.handleSubmit();
                    }
                  }}
                />
                <Typography className={styles.limit_div}>
                  {formik.values.imei?.length}/15
                </Typography>
              </Box>
              <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  sx={{ my: "20px" }}
                  disabled={
                    formik.values?.imei.length === 0 ? true : !formik?.isValid
                  }
                  onClick={() => formik.handleSubmit()}
                  loading={btnLoader}
                >
                  {content?.imei_form?.check_btn}
                </ButtonCust>
              </Box>
              <Typography className={styles.italic_text}>
                {content?.imei_form?.note}
              </Typography>
              <Box className={styles.inner_text} my="20px">
                <Typography className={styles.text1}>
                  {content?.Iemi_con?.title}
                </Typography>
                <Typography className={styles.text2}>
                  {content?.Iemi_con?.desc}
                </Typography>
              </Box>
              <Box
                className={styles.imei_content}
                sx={{ flexDirection: "column" }}
              >
                {content?.Iemi_con?.iemiContent?.map(
                  (item: any, index: number) => {
                    return (
                      <Box className={styles.device_details} key={index}>
                        <Box
                          width="19px"
                          height="23px"
                          mr="1rem"
                          component="img"
                          src={item?.img?.data?.attributes?.url}
                          alt={item?.img?.data?.attributes?.alternativeText}
                        />
                        <Box sx={{ mb: "20px", ml: "10px" }}>
                          <Typography className={styles.sub_title}>
                            {item?.desc1}
                          </Typography>
                          <Typography className={styles.sub_description}>
                            {item?.desc2}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                )}
              </Box>
            </>
          ) : (
            <>
              <Box
                className={styles.device_content}
                sx={{
                  width: { xs: "100%", sm: "400px", md: "65%" },
                  justifyContent: "space-between",
                }}
              >
                <Box className={styles.device_details} m="5px">
                  <Box
                    width="19px"
                    height="21px"
                    mr="1rem"
                    mt="3px"
                    lineHeight={"1.5"}
                    component="img"
                    src={content?.imei_view?.dev_img?.data?.attributes?.url}
                    alt={
                      content?.imei_view?.dev_img?.data?.attributes
                        ?.alternativeText
                    }
                  />
                  <Box>
                    <Typography className={styles.sub_title}>
                      {content?.imei_view?.device}
                    </Typography>
                    <Typography className={styles.sub_description}>
                      {currentLine?.model}
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.device_details} m="5px">
                  <Box
                    width="19px"
                    height="19px"
                    mr="1rem"
                    mt="3px"
                    component="img"
                    src={content?.imei_view?.dev_id_img?.data?.attributes?.url}
                    alt={
                      content?.imei_view?.dev_id_img?.data?.attributes
                        ?.alternativeText
                    }
                  />
                  <Box>
                    <Typography className={styles.sub_title}>
                      {content?.imei_view?.deviceID}
                    </Typography>
                    <Typography className={styles.sub_description}>
                      {currentLine?.imei}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                mt="25px"
                display={"flex"}
                sx={{
                  justifyContent: { xs: "center", sm: "left" },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <ButtonCust
                  sx={{ width: "200px", mx: { xs: "auto", sm: "0px" } }}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={() => handleStep(true)}
                >
                  {content?.imei_view?.nxt_btn}
                </ButtonCust>
                <ButtonCust
                  sx={{
                    m: { sm: "0px 0px 0px 25px", xs: "25px auto 0px" },
                    width: "200px",
                  }}
                  onClick={() => setDeviceComp(false)}
                  variantType={Config.SECONDARY_BUTTON_TYPE}
                >
                  {content?.imei_view?.edd_txt}
                </ButtonCust>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {modalStatus && (
        <IMEIFailureModal
          modalFg={modalStatus}
          closeHandler={() => setModalStatus(false)}
          content={content}
        />
      )}
    </>
  );
};

export default ActivationStep1;
