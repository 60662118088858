import { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { PlansContainer } from "../../../PlanSelection";
import Config from "../../../../config/env.config.json";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import styles from "./S1_Cpbdp.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { BuyDataDialog } from "../BuyDataDialog";
import { InProgressNote } from "../InProgressNote/InProgressNote";
import { ChangePlanProceedDialog } from "../CPPD/Cppd";
import { DataPurchaseDialog } from "../DataPurchaseDialog";
import { ComparePlanDialog } from "../ComparePlanDialog";
import { TopUpModal } from "../TopUpModal";
import { BuyDataDropDown } from "../BuyDataDropDown/BuyDataDropDown";
import { ChangePlanDialogue } from "../ChangePlanDialogue";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import {
  ManageCard,
  IncDecButtonCmp,
  MultiTabCmp,
  ButtonCust,
} from "../../../widgets";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import { MultipleTopUpComp } from "../MultipleTopUpComp/MultipleTopUpComp";
import { getCache } from "../../../../utils";
import { PlanSectionContent } from "../../../../features/strapi/homePageSlice";
import { getConfigKeys } from "../../../../features/activation/activationSlice";
const wowUnlimitedCoupons = Config?.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../../config/discountCouponsUnlimited.json")
  : null;
const wowRegularCoupons = Config?.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../../config/discountCouponsRegular.json")
  : null;

const incDecCustomStyles = {
  inputStyles: {
    color: "var(--black)",
    fontWeight: "var(--font_weight_2)",
    fontFamily: "var(--font_family_Semibold)",
    fontSize: "17px",
    margin: "0px 20px",
  },
  buttonStyles: {
    fontFamily: "var(--font_family_Bold)",
    fontWeight: "var(--font_weight_2)",
    fontSize: "17px",
  },
};
type T1CpbdpProps = {
  setCurrentSection: Function;
  content: any;
  tabsHandler: any;
  activeTab: any;
  tabsContent: any;
  plans: any;
  selectedPlan: any;
  handlePlanSelection: any;
  isTopUpInitiated: boolean;
  isPlanChangeInitiated: boolean;
  SelectPlanAndProceedHandler: any;
  unlimited: boolean;
  group: any;
  selectedLine: any;
  setSelectedLine: any;
  topupPulseDetails: any;
  countValue: any;
  countHandler: any;
  proceedButtonHandler: any;
  tax4Loader: any;
  isGetTopUpStatusClicked: boolean;
  isGetPlanChangeStatusClicked: boolean;
  getTopUpUpdatedStatus: any;
  getPlanChangeUpdatedStatus: any;
  viewPurchaseHistoryTopup: boolean;
  setdataPurchaseDialog: any;
  open1: boolean;
  setOpen1: boolean;
  lowerPlanSelected: boolean;
  sharedPlanSelected: boolean;
  unlimitedPlanSelected: boolean;
  buyDataDialog: boolean;
  setBuyDataDialog: any;
  confirmPurchaseHandler: any;
  totalTax: any;
  amount: any;
  customerTopUpLoader: any;
  mainCard: any;
  comparePlansDialog: boolean;
  setComparePlansDialog: any;
  dataPurchaseDialog: boolean;
  groupTopUp: any;
  dialogData: any;
  setIsOperationInitiated: any;
  isOperationInitiated: boolean;
  ChangePlanProceedHandler: any;
  comparePlansDialogLater: boolean;
  setComparePlansDialogLater: any;
  user: any;
  currentPlanCost: any;
  currentPlanTax: any;
  newBillStartMonth: any;
  newBillEndMonth: any;
  newPlanCost: any;
  newPlanTax: any;
  confirmPlanChangeHandler: any;
  updateLaterHandler: any;
  planChangeLoader: boolean;
  cardChangeHandler: any;
  showCardChangeModal: boolean;
  setShowCardChangeModal: any;
  setSelectedCard: any;
  isCardExpired: any;
  ProceedPayment?: any;
  cppdContent?: any;
  topUpPulse?: any;
  dataPackPlan?: any;
  setDataPackPlan?: any;
};
const ChangePlanOrBuyDataPackCmp = (props: T1CpbdpProps) => {
  const {
    setCurrentSection,
    content,
    tabsHandler,
    activeTab,
    tabsContent,
    plans,
    selectedPlan,
    handlePlanSelection,
    isTopUpInitiated,
    isPlanChangeInitiated,
    SelectPlanAndProceedHandler,
    unlimited,
    group,
    selectedLine,
    setSelectedLine,
    topupPulseDetails,
    countValue,
    countHandler,
    proceedButtonHandler,
    tax4Loader,
    isGetTopUpStatusClicked,
    isGetPlanChangeStatusClicked,
    getTopUpUpdatedStatus,
    getPlanChangeUpdatedStatus,
    viewPurchaseHistoryTopup,
    setdataPurchaseDialog,
    buyDataDialog,
    setBuyDataDialog,
    confirmPurchaseHandler,
    mainCard,
    comparePlansDialog,
    setComparePlansDialog,
    dataPurchaseDialog,
    isOperationInitiated,
    setIsOperationInitiated,
    comparePlansDialogLater,
    setComparePlansDialogLater,
    currentPlanCost,
    currentPlanTax,
    newPlanCost,
    newPlanTax,
    updateLaterHandler,
    user,
    confirmPlanChangeHandler,
    planChangeLoader,
    cardChangeHandler,
    isCardExpired,
    cppdContent,
    topUpPulse,
    dataPackPlan,
    setDataPackPlan,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [discount, setDiscount] = useState(0);
  const [totalActivitedUsers, settotalActivitedUsers] = useState<number>(1);

  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);
  const currentBill1 = new Date(user && user?.activationDate)
    .toString()
    .slice(4, 10);
  const currentBill2 =
    user && user.billEndDate
      ? new Date(user.billEndDate).toString().slice(4, 10)
      : new Date(Date.now()).toString().slice(4, 10);
  const newBillStartMonth = user?.billGenerationDate
    ? new Date(user?.billGenerationDate).toString().slice(4, 10)
    : "-";
  let newUpdatedMonth = new Date(user?.billGenerationDate);
  newUpdatedMonth.setMonth(newUpdatedMonth?.getMonth() + 1);
  newUpdatedMonth.setDate(newUpdatedMonth?.getDate() - 1);
  const newBillEndMonth = newUpdatedMonth
    ? new Date(newUpdatedMonth)?.toString().slice(4, 10)
    : "-";
  const newBillDate = new Date(user && user?.billGenerationDate)
    .toString()
    .slice(4, 10);

  const { getCCDetails } = useAppSelector((state: any) => state.account);
  const [cacheDataPlanSec, setCacheDataPlanSec] = useState<any>(null);
  const { planCardContent } = useAppSelector(
    (state: any) => state.strapi.homepage || {}
  );
  useEffect(() => {
    getCache("PlanSecHome", PlanSectionContent, setCacheDataPlanSec, dispatch);
  }, []);
  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);

  let ChangePlanDetails: any = {
    planDiffAmount: GetCurrencyFixedToTwo(newPlanCost - currentPlanCost),
    diffTax: GetCurrencyFixedToTwo(newPlanTax),
    planUpgradeBalance: GetCurrencyFixedToTwo(
      Number(newPlanCost - currentPlanCost) + Number(newPlanTax)
    ),
    currentPlanCost: currentPlanCost,
    newPlanCost: newPlanCost,
  };
  let PlanStorage: any = localStorage.getItem("amount");
  let PlanStorageDetails: any = JSON.parse(PlanStorage);
  let selectedLocalPlan: any = localStorage.getItem("selectedPlan");
  let localStoredPlan: any =
    typeof selectedLocalPlan === "undefined"
      ? {}
      : JSON.parse(selectedLocalPlan);
  let localSelectedLine: any = localStorage.getItem("selectedLine");
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };

  useEffect(() => {
    let totalActiveLine: any = group?.data?.filter(
      (x: any) => x.status === 2
    ).length;
    settotalActivitedUsers(totalActiveLine);
    setDiscount(
      currentdetails.isWowHSDCustomer
        ? selectedPlan?.isUnlimited
          ? wowUnlimitedCoupons?.data?.discountInDollar +
            wowUnlimitedCoupons?.data?.secondaryDiscountInDollar *
              (totalActivitedUsers - 1)
          : wowRegularCoupons?.data?.discountInDollar +
            wowRegularCoupons?.data?.secondaryDiscountInDollar *
              (totalActivitedUsers - 1)
        : 0
    );
  }, [selectedPlan]);
  return (
    cppdContent &&
    content && (
      <Grid
        container
        sx={{
          py: {
            xs: "var(--mobile_section_padding)",
            sm: "var(--ipad_section_padding)",
            md: "var(--desktop_section_padding)",
          },
          px: "15px",
        }}
      >
        <Grid
          item
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Box
            onClick={() => setCurrentSection("DataDetails")}
            sx={{ cursor: "pointer" }}
          >
            <ArrowBackIcon
              className={styles.arrow_icon}
              sx={{ position: "absolute", top: "0", left: "0" }}
            />
          </Box>

          <Typography
            fontFamily="var(--font_family_Bold)"
            fontWeight="900"
            lineHeight="1.1"
            fontSize="20px"
            m="10px"
          >
            {content?.title}
          </Typography>

          <Grid item width="100%">
            <Grid
              item
              className={styles.tabs_container}
              sx={{ mx: "auto", display: "flex", justifyContent: "center" }}
            >
              <MultiTabCmp
                tabsHandler={tabsHandler}
                activeTab={activeTab}
                tabsContent={tabsContent}
              />
            </Grid>
            {activeTab === tabsContent[0].id ? (
              <Grid item>
                <Grid
                  item
                  pt={2}
                  className={styles.plans_container}
                  sx={{ mx: "auto" }}
                >
                  <PlansContainer
                    plans={plans}
                    selectedPlan={selectedPlan}
                    activePlanName={user?.reachPlanId}
                    handlePlanSelection={handlePlanSelection}
                    currentPlanBorder={true}
                    content={content}
                    user={user}
                    planContent={cacheDataPlanSec ? cacheDataPlanSec: planCardContent}
                    SelectPlanAndProceedHandler = {SelectPlanAndProceedHandler}
                    isTopUpInitiated={isTopUpInitiated}
                    isPlanChangeInitiated={isPlanChangeInitiated}
                  />
                </Grid>
                {isTopUpInitiated && (
                  <Box
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      fontSize: "14px",
                      m: "30px 0 20px",
                      textAlign: "center",
                    }}
                  >
                    {content?.inProTopUpNote2}
                  </Box>
                )}
                {isPlanChangeInitiated && (
                  <>
                    <InProgressNote
                      isGetStatusClicked={isGetPlanChangeStatusClicked}
                      getUpdatedStatus={getPlanChangeUpdatedStatus}
                      inProgressNote={content?.inProPlanChangeNote}
                      refreshIcon1={content?.refresh_icon}
                      refreshIcon2={content?.refresh_icon_prog}
                    />
                  </>
                )}
                {currentdetails?.wowHsdRequestedState ===
                  ("ADD_IN_PROGRESS" || "APPLIED") && (
                  <Box className={styles.hsd_message}>
                    <span>{content?.note_txt}&nbsp;</span>
                    {currentdetails?.wowHsdRequestedState ===
                    "ADD_IN_PROGRESS" ? (
                      <>
                        {content?.pricing_exclude}&nbsp;
                        <span>{GetCurrencyFixedToTwo(discount)}</span>&nbsp;
                        {content?.pricing_exclude2}
                      </>
                    ) : currentdetails?.wowHsdRequestedState === "APPLIED" ? (
                      <>{content?.pricing_include}</>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
                {isCardExpired && (
                  <Box className={styles.account_card_expiry_message}>
                    <span>{content?.note_txt} </span>
                    {content?.note_msg}
                    <span
                      className={styles.account_update_expiry_card}
                      onClick={() => {
                        navigate(RoutingURLs.billing);
                      }}
                    >
                      {content?.update_txt}{" "}
                    </span>
                    {content?.note_msg1}
                  </Box>
                )}
                {!isPlanChangeInitiated && (
                  <Grid
                    item
                    margin="0px auto"
                    display="flex"
                    justifyContent="center"
                    padding="10px 0px 0px"
                  >
                    <ButtonCust
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      onClick={SelectPlanAndProceedHandler}
                      disabled={isTopUpInitiated || !selectedPlan}
                    >
                      {content?.cp_btn_txt}
                    </ButtonCust>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid
                item
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="space-between"
                px="20px"
              >
                <Grid
                  className={styles.tabs_container}
                  sx={{ mx: "auto" }}
                  mt={2}
                >
                  {unlimited && (
                    <BuyDataDropDown
                      group={group}
                      selectedLine={
                        localSelectedLine ? localSelectedLine : selectedLine
                      }
                      setSelectedLine={setSelectedLine}
                    />
                  )}
                  &ensp;
                  {Config?.FIXED_BUY_DATA_PACK &&
                    !Config?.MULTIPLE_DATA_TOP_UP_REQUIRED && (
                      <Grid display="flex" justifyContent="center">
                        <Box className={styles.buy_data}>
                          {topupPulseDetails?.pulse}GB
                        </Box>
                      </Grid>
                    )}
                  {!Config?.FIXED_BUY_DATA_PACK &&
                    !Config?.MULTIPLE_DATA_TOP_UP_REQUIRED && (
                      <IncDecButtonCmp
                        unit="GB"
                        maxValue={
                          topupPulseDetails?.limit * topupPulseDetails?.pulse
                        }
                        minValue={0.5}
                        step={0.5}
                        value={countValue}
                        countHandler={countHandler}
                        customStyles={incDecCustomStyles}
                        isUnitPlural={false}
                      />
                    )}
                  {Config?.MULTIPLE_DATA_TOP_UP_REQUIRED &&
                    !Config?.FIXED_BUY_DATA_PACK && (
                      <MultipleTopUpComp
                        dataPackPlan={dataPackPlan}
                        topUpPulse={topUpPulse}
                        setDataPackPlan={setDataPackPlan}
                        data_unit={content?.data_unit}
                      />
                    )}
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  textAlign="center"
                  lineHeight="1.5"
                >
                  {!Config?.MULTIPLE_DATA_TOP_UP_REQUIRED && (
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        fontWeight: "var(--font_weight_2)",
                        paddingTop: "12px",
                        pb: "25px",
                        fontSize: "14px",
                      }}
                      className={styles.data_pack}
                    >
                      {content?.data_cost}
                      {Config?.FIXED_BUY_DATA_PACK ||
                      Config?.MULTIPLE_DATA_TOP_UP_REQUIRED
                        ? GetCurrencyFixedToTwo(
                            (topupPulseDetails?.pulseCost *
                              topupPulseDetails?.pulse) /
                              topupPulseDetails?.pulse
                          )
                        : GetCurrencyFixedToTwo(
                            (topupPulseDetails?.pulseCost * countValue) /
                              topupPulseDetails?.pulse
                          )}
                      &nbsp;(
                      {GetCurrencyFixedToTwo(topupPulseDetails?.pulseCost)}/
                      {topupPulseDetails?.pulse}GB)
                    </Typography>
                  )}
                  {content?.dataAvailaibility && (
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                        p: "0px 0px 25px",
                        fontSize: "13px",
                        color: "var(--text_color_4)",
                      }}
                    >
                      <span className={styles.data_note}>
                        {content?.note_text}
                      </span>
                      {content?.dataAvailaibility}
                    </Typography>
                  )}
                  {isCardExpired && (
                    <Box className={styles.account_card_expiry_message}>
                      <span>{content?.note_txt} </span>
                      {content?.note_msg}
                      <span
                        className={styles.account_update_expiry_card}
                        onClick={() => {
                          navigate(RoutingURLs.billing);
                        }}
                      >
                        {content?.update_txt}{" "}
                      </span>
                      {content?.note_msg1}
                    </Box>
                  )}
                  {isPlanChangeInitiated && (
                    <>
                      <Box
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "14px",
                          m: "30px 0 20px",
                          textAlign: "center",
                        }}
                      >
                        {content?.inProPlanChangeNote2}
                      </Box>
                    </>
                  )}
                  {!isTopUpInitiated && (
                    <Grid
                      item
                      margin="0px auto auto"
                      display="flex"
                      justifyContent="center"
                      width="20%"
                      sx={{ pb: "25px" }}
                    >
                      <ButtonCust
                        variantType={Config.PRIMARY_BUTTON_TYPE}
                        onClick={proceedButtonHandler}
                        loading={tax4Loader}
                        disabled={
                          isPlanChangeInitiated
                            ? true
                            : !unlimited
                            ? false
                            : selectedLine
                            ? false
                            : true
                        }
                      >
                        {content?.bdp_btn_txt}
                      </ButtonCust>
                    </Grid>
                  )}
                  {isTopUpInitiated && (
                    <InProgressNote
                      isGetStatusClicked={isGetTopUpStatusClicked}
                      getUpdatedStatus={getTopUpUpdatedStatus}
                      inProgressNote={content?.inProTopUpNote}
                      refreshIcon1={content?.refresh_icon}
                      refreshIcon2={content?.refresh_icon_prog}
                    />
                  )}
                  {viewPurchaseHistoryTopup && (
                    <Box display="flex" justifyContent="center">
                      <Typography
                        sx={{
                          fontFamily: "var(--font_family_Bold)",
                          color: "var(--primary_color)",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setdataPurchaseDialog(true);
                          GAAndFBEventsHandler(
                            gaCategory.buyDataPack,
                            content?.viewPurchaseHistory
                          );
                        }}
                      >
                        {content?.viewPurchaseHistory}
                        <ArrowRightIcon
                          sx={{ color: "var(--primary_color)" }}
                        />
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <ChangePlanProceedDialog {...props} />
        </Grid>
        <BuyDataDialog
          open={buyDataDialog}
          onClose={() => setBuyDataDialog(false)}
          onSubmit={confirmPurchaseHandler}
          dataAdded={
            Config?.FIXED_BUY_DATA_PACK || Config?.MULTIPLE_DATA_TOP_UP_REQUIRED
              ? topupPulseDetails?.pulse
              : countValue
          }
          {...props}
        />
        <ComparePlanDialog
          open={comparePlansDialog}
          onClose={() => {
            setComparePlansDialog(false);
          }}
          planChangeLoader={planChangeLoader}
          currentPlan={user?.reachPlanDisplayName}
          totalActivitedUsers={user?.totalActiveLines}
          ChangePlanDetails={
            PlanStorageDetails ? PlanStorageDetails : ChangePlanDetails
          }
          confirmPlanChangeHandler={confirmPlanChangeHandler}
          newBillStartMonth={newBillStartMonth}
          mainCard={mainCard}
          updateLaterHandler={updateLaterHandler}
          cardChangeHandler={cardChangeHandler}
          isCardExpired={isCardExpired}
          selectedPlan={localStoredPlan ? localStoredPlan : selectedPlan}
          content={content}
          cppdContent={cppdContent}
          discount={discount}
        />
        <ChangePlanDialogue
          open={comparePlansDialogLater}
          onClose={() => {
            setComparePlansDialogLater(false);
          }}
          currentBill1={currentBill1}
          currentBill2={currentBill2}
          currentPlan={user?.reachPlanDisplayName}
          totalActivitedUsers={user?.totalActiveLines}
          currentPlanCost={
            PlanStorageDetails
              ? PlanStorageDetails?.currentPlanCost
              : currentPlanCost
          }
          currentPlanTax={currentPlanTax}
          newBillStartMonth={newBillStartMonth}
          newBillEndMonth={newBillEndMonth}
          selectedPlan={localStoredPlan ? localStoredPlan : selectedPlan}
          newPlanCost={
            PlanStorageDetails ? PlanStorageDetails?.newPlanCost : newPlanCost
          }
          newPlanTax={newPlanTax}
          newBillDate={newBillDate}
          confirmPlanChangeHandler={confirmPlanChangeHandler}
          planChangeLoader={planChangeLoader}
          content={content}
          discount={discount}
        />
        <DataPurchaseDialog
          open={dataPurchaseDialog}
          onClose={() => setdataPurchaseDialog(false)}
          {...props}
        />
        {Config?.SHOW_TOP_MODALS && isOperationInitiated && (
          <TopUpModal
            display={isOperationInitiated}
            closeHandler={() => setIsOperationInitiated(false)}
            {...props}
          />
        )}
        {
          <ManageCard
            cardData={getCCDetails}
            ChangePlanDetails={ChangePlanDetails}
            isModalRequired={true}
            payType={false}
            isDescription={false}
            pageName={"account"}
            {...props}
            isPayConfirm={true}
          />
        }
      </Grid>
    )
  );
};

export default ChangePlanOrBuyDataPackCmp;
