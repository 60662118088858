import { isTemplateExisits } from "../../../../utils/commonFunctions";
import Config from "../../../../config/app.config.json";
import { useEffect } from 'react'

const InitialComponent = isTemplateExisits(Config.Activation.InitialActivation)
  ? require(`./${Config.Activation.InitialActivation.template}_InitialActivation`)
    .default
  : null;

  export const InitialActivation = (props: any) => {
  const { handleStep, currentLine, content, updatingCustomer } = props;
  useEffect(() => {
    localStorage.removeItem("oneTimeChargeDeliveryType");
  }, []);
  return (
    <InitialComponent
      currentLine={currentLine}
      content={content}
      updatingCustomer={updatingCustomer}
      handleStep={handleStep}
    />
  );
};