import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "./S4_PlanCard.module.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ButtonCust, Divider } from "../../../widgets";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { calculateCost, calculateUnlimitedCost, PlanProps } from "./PlanCard";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import clsx from "clsx";
const T4_PlanCard = (props: PlanProps) => {
  const {
    isSelected,
    planSelectionHandler,
    selectedLine,
    planContent,
    plandata,
    isSimPaid
  } = props;

  const [clickedIndex, setClickedIndex] = React.useState<any>([]);
  const [Hover, setHover] = React.useState(false);
  const [position, setHoverPosition] = React.useState("");

  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);

  const navigate = useNavigate();

  const onViewDetails = (_: any) => {
    navigate(RoutingURLs.cellPhonePlans);
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }, 100);
  };

  const handleClick = (index: any) => () => {
    setClickedIndex((state: any) => ({
      ...state, // <-- copy previous state
      [index]: !state[index], // <-- update value by index key
    }));
  };

  const handleMouseEnter = (index: any) => {
    setHoverPosition(index);
    setHover(true);
  };

  return (
    <Box className={styles.plan_card_main_container} id="bestCellPhonePlan">
      {plandata?.length > 0 &&
        plandata?.map((item: any, index: any) => {
          const { planPerLinesCost, planCost } = item?.unlimited
            ? calculateUnlimitedCost(item, selectedLine, false)
            : calculateCost(item, selectedLine, false);
          const isCurrentPlan = details?.reachPlanDisplayName === item?.name;
          return (
            <Box>
              <Box
                key={index}
                className={clsx(
                  styles.next_link_plan,
                  styles.home_plans,
                  position === index
                    ? isSelected && Hover
                      ? styles.tag_border
                      : Hover
                      ? styles.on_hover
                      : ""
                    : "",
                  isCurrentPlan ? styles.tag_border : ""
                )}
                p={isCurrentPlan ? "11px 20px" : "20px"}
                onMouseOver={() => {
                  if (isSelected) handleMouseEnter(index);
                }}
                onMouseLeave={() => {
                  if (isSelected) setHover(false);
                }}
              >
                {isCurrentPlan && <Typography className={styles.current_plan_tag}>{planContent?.current_plan}</Typography>}
                <Typography
                  className={styles.plan_name}
                  textAlign="center"
                  sx={{
                    fontFamily: isSelected
                      ? "var(--font_family_Bold)"
                      : "var(--font_family_Semibold) !important",
                  }}
                >
                  {item.name}
                </Typography>
                {!isSelected &&
                  (!item.isUnlimited ? (
                    <Typography className={styles.plan_type}>
                      {planContent?.sharable_txt}
                    </Typography>
                  ) : (
                    <Typography height={isSimPaid ? "auto":"24px"}  className={styles.plan_type}>{isSimPaid ? planContent?.unl_per_ln_txt : ""}</Typography>
                  ))}
                <Divider
                  styles={{
                    margin: isSelected ? "20px auto 10px" : "20px auto",
                    backgroundColor:
                      !isSelected && "var(--grey_shade) !important",
                  }}
                />
                {isSelected && (
                  <Box display="flex" alignItems="center" m="5px 0">
                    <Typography className={styles.cell_phone_plan} mr="5px">
                      {item?.planData}
                      {item?.dataUnit}
                      {item?.isUnlimited ? "+" : ""}
                    </Typography>

                    <Typography className={styles.plan} display="flex">
                      {item?.isUnlimited
                        ? planContent?.non_sharable_txt
                        : `- ${planContent?.sharable_txt}`}
                    </Typography>
                  </Box>
                )}
                <Typography
                  display="flex"
                  alignItems="center"
                  className={styles.plan_price}
                  justifyContent={isSelected ? "" : "center"}
                >
                  {GetCurrencyFixedToTwo(planPerLinesCost)}&nbsp;
                  <sub
                    className={styles.tax_text}
                    style={{ fontSize: isSelected ? "12px" : "14px" }}
                  >
                    {isSelected
                      ? planContent?.per_ln_per_mon
                      : `${planContent?.per_month_txt} ${planContent?.tax_nd_fee}`}
                  </sub>
                </Typography>

                {!isSelected && location.pathname !== RoutingURLs.myAccount && (
                  <Typography
                    className={styles.view_details}
                    textAlign="center"
                    width="100%"
                    onMouseOver={() => handleMouseEnter(index)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => onViewDetails(item)}
                    m="10px 0 0"
                  >
                    {planContent?.view_details_txt}
                  </Typography>
                )}
                {isSelected && (
                  <>
                    <Box className={styles.plan_card_data_container}>
                      {planContent?.icon_offers?.map(
                        (data: any, index: number) => {
                          return (
                            <Box className={styles.icon_offers} key={index}>
                              <Box
                                component={"img"}
                                src={data?.image?.data?.attributes?.url}
                                alt={data?.image?.data?.attributes?.alternativeText}
                              ></Box>
                              <Typography className={styles.offer_text}>
                                {data?.offer}
                              </Typography>
                            </Box>
                          );
                        }
                      )}
                      {clickedIndex[index] && (
                        <>
                          {planContent?.more_icon_offers?.map(
                            (data: any, index: number) => {
                              return (
                                <Box
                                  className={styles.icon_offers}
                                  key={`more_${index}`}
                                >
                                  <Box
                                    component={"img"}
                                    src={data?.image?.data?.attributes?.url}
                                    alt={data?.image?.data?.attributes?.alternativeText}
                                  ></Box>
                                  <Typography className={styles.offer_text}>
                                    {data?.offer?.replace(
                                      "{0}",
                                      item?.maxLines
                                    )}
                                  </Typography>
                                </Box>
                              );
                            }
                          )}
                        </>
                      )}
                      <Box
                        className={styles.plan_card_view_more}
                        onClick={handleClick(index)}
                      >
                        <Typography className={styles.view_text}>
                          {clickedIndex[index]
                            ? planContent?.view_less_txt
                            : planContent?.view_more_txt}
                        </Typography>
                        {clickedIndex[index] ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      p="20px 0"
                      justifyContent="center"
                    >
                      <Typography className={styles.plan_name}>
                        {GetCurrencyFixedToTwo(planCost)}
                      </Typography>
                      <Typography
                        className={styles.plan_data_text}
                        m="11px 0 7px 4px"
                      >
                        {planContent?.per_month_lines?.replace(
                          "{0}",
                          selectedLine
                        )}
                        {selectedLine > 1 ? "s" : ""}&nbsp;
                        {planContent?.tax_nd_fee}
                      </Typography>
                    </Box>
                    <ButtonCust
                      onClick={(e: any) => {
                        item.maxLines >= selectedLine
                          ? planSelectionHandler(
                              item,
                              planContent?.select_plan_btn
                            )
                          : "";
                      }}
                    >
                      {planContent?.select_plan_btn}
                    </ButtonCust>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};
export default T4_PlanCard;
