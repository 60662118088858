import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { privacyProps } from "./Privacy";
import styles from "./S2_privacy.module.scss";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";

const PrivacyComponent = (props: privacyProps) => {
  const { content } = props;
  return (
    <Box
      className={styles.privacy_main_container}
    >
      <Box className={styles.privacy_content}>
        <Typography className={styles.privacy_title}>
          {content?.title}
        </Typography>
        <Box className={styles.privacy_line} />
        <Box
          className={clsx(styles.privacy_text, styles.privacy_top_para)}
          dangerouslySetInnerHTML={{
            __html: BrandNameUpdate(content?.description),
          }}
        />
        {content &&
          content?.privacy_content.length > 0 &&
          content?.privacy_content.map((items: any, index: string) => {
            return (
              <Box key={index}>
                <Typography className={styles.privacy_heading}>
                  {index + 1}. {BrandNameUpdate(items?.heading)}
                </Typography>
                <Typography className={styles.privacy_heading}>
                  {BrandNameUpdate(items?.sub_heading)}
                </Typography>
                <Box
                  className={styles.privacy_text}
                  dangerouslySetInnerHTML={{
                    __html: BrandNameUpdate(items?.description),
                  }}
                />
                <Typography className={styles.privacy_heading}>
                  {BrandNameUpdate(items?.sub_heading1)}
                </Typography>
                <Box
                  className={styles.privacy_text}
                  dangerouslySetInnerHTML={{
                    __html: BrandNameUpdate(items?.description1),
                  }}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default PrivacyComponent;
