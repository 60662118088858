import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { bestFitSavingsContent } from "../../../features/strapi/bestFitSlice";
import { useNavigate } from "react-router-dom";
import EnvConfig from "../../../config/env.config.json";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { RoutingURLs } from "../../../config/RoutingURLs";

const BillSavingsComponent = isTemplateExisits(Config.BestFit.BestFitSavings)
  ? require(`./${Config.BestFit.BestFitSavings.template}_BillSavings`).default
  : null;
export type BestFitSavingsProps = {
  savingContent?: any;
  getStartedHandler?: any;
};
export const BillSavings = () => {
  const { bestFitSavings } = useAppSelector(
    (state: any) => state.strapi.bestfit
  );
  const [cacheDataBestFitBillSavings, setCacheDataBestFitBillSavings] =
    useState<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getStartedHandler = () => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.bestFit,
      category: gaCategory.billCalculator,
      buttonValue: bestFitSavings?.button_1,
    });
    navigate(RoutingURLs.billCalculator);
  };
  useEffect(() => {
    getCache(
      "BillSavingsBestFit",
      bestFitSavingsContent,
      setCacheDataBestFitBillSavings,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("BillSavingsBestFit", bestFitSavings);
  }, [bestFitSavings]);
  return (
    <BillSavingsComponent
      savingContent={
        cacheDataBestFitBillSavings
          ? cacheDataBestFitBillSavings
          : bestFitSavings
      }
      getStartedHandler={getStartedHandler}
    />
  );
};
