import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { ButtonCust } from "../../widgets";
import styles from "./HelpCenter.module.scss";
import EnvConfig from "../../../config/env.config.json";

type Props = {
  content: any;
};
const HelpCenterM = (props: Props) => {
  const navigate = useNavigate();
  const { content } = props;
  const { help_center_ttl, help_center_desc, visit_btn } = content;
  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      sx={{
        alignItems: "center",
        backgroundColor: "var(--white)",
        height: "394px",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography className={styles.heading}>{help_center_ttl}</Typography>
      <Typography
        className={styles.text}
        sx={{ width: { xs: "92%", md: "57%", sm: "73%" } }}
        fontFamily="var(--font_family_Medium)"
      >
        {help_center_desc}
      </Typography>
      {visit_btn?<ButtonCust onClick={() => navigate(RoutingURLs.help)} variantType={EnvConfig.PRIMARY_BUTTON_TYPE}>
        {visit_btn}
      </ButtonCust>:null}
    </Grid>
  );
};

export default HelpCenterM;
