import styles from "./S2_Services.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../widgets";
import EnvConfig from "../../config/env.config.json";
import { useEffect, useState } from "react";
import { customerGroup } from "../../features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PAInternationalRoaming } from "./PAInternationalRoaming/PAInternationalRoaming";
import { PostActivationIRILDEnums } from "../../enums";
import { PAInternationalCalling } from "./PAInternationalCalling/PAInternationalCalling";
import ConfigJSON from "../../config/env.config.json";
import {
  getCustomerBillCycleDataUsage,
  getCustomerDataUsageById,
} from "../../features/customer/customerSlice";
import { addDays, format, parseISO, subDays, subMonths } from "date-fns";
import { getUTCDate, sortObjectByDates } from "../../utils/commonFunctions";

type Props = {
  content: any;
};

const ServiceComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const [internationalService, setInternationalService] = useState("");
  const { content } = props;
  const { services_card, services_icon } = content;
  const [displayStartDate, setDisplayStartDate] = useState<any>();
  const [displayEndDate, setDisplayEndDate] = useState<any>();
  const [consolidatedBillCycyle, setConsolidatedBillCycle] = useState<any>();
  const [totalNumberOfKeys, setTotalNumberOfKeys] = useState<number>(0);
  const [selectedDateKeyIndex, setSelectedDateKeyIndex] = useState<number>(0);
  const [dataUsageInfo, setDataUsageInfo] = useState<any>();
  const { customerUsageData, customerBillCycleDataUsage } = useAppSelector(
    (state: any) => state.customer
  );

  const { service_main, service_header_text, service_Desc_text } = styles;
  let customerStoreData: any = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const customerId = customerStoreData?.isPrimary
    ? customerStoreData?.id
    : customerStoreData?.parentId;



  const handleBillCycleChange = (type: string) => {
    let newSelectedKey: number = selectedDateKeyIndex;
    if (type === "decrement") {
      newSelectedKey = selectedDateKeyIndex - 1;
    } else {
      newSelectedKey = selectedDateKeyIndex + 1;
    }
    setSelectedDateKeyIndex(newSelectedKey);
    handleDateKeyChange(newSelectedKey);
  };

  const handleDateKeyChange = (newSelectedKey: number) => {
    let newEndDate: any = Object.keys(consolidatedBillCycyle)[newSelectedKey];
    let newStartDate = null;
    if (!ConfigJSON.FIXED_DAYS_BILLING) {
      newStartDate = addDays(subMonths(parseISO(newEndDate), 1), 1);
    } else {
      newStartDate = addDays(
        parseISO(newEndDate),
        ConfigJSON.NUMBER_OF_BILLING_DAYS_FOR_BILL_CYCLE
      ); // config need to be tested for plum
    }
    if (newSelectedKey === totalNumberOfKeys - 1) {
      // For handling current details bill cycle dates
      setDisplayStartDate(parseISO(customerStoreData?.billStartDate));
    } else {
      setDisplayStartDate(newStartDate);
    }
    setDisplayStartDate(newStartDate);
    setDisplayEndDate(parseISO(newEndDate));
    setDataUsageInfo(consolidatedBillCycyle[newEndDate]);
  };

  function formatDateToMMDDYYYY(date: any) {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  }

  localStorage.setItem(
    "StartEndDate",
    JSON.stringify({ displayStartDate, displayEndDate, totalNumberOfKeys })
  );



  const isValidBillCyclePayload = () => {
    return (
      customerStoreData?.id &&
      customerStoreData.nextBillingDate &&
      customerStoreData?.activationDate
    );
  };

  const requestOverallBillCycleDataUsage = (cdrCurrentDataUsageData: any) => {
    if (isValidBillCyclePayload()) {
      const startDate = parseISO(customerStoreData?.activationDate);
      const endDate = parseISO(customerStoreData?.billStartDate);
      fetchBillCycleDataUsage(
        getUTCDate(startDate),
        getUTCDate(endDate),
        cdrCurrentDataUsageData
      );
    }
  };

  const fetchBillCycleDataUsage = (
    startDate: string,
    endDate: string,
    cdrCurrentDataUsageData: any
  ) => {
    const customerId = customerStoreData?.isPrimary
      ? customerStoreData?.id
      : customerStoreData?.parentId;
    const payload = {
      userId: customerId,
      startDate,
      endDate,
    };
    dispatch(getCustomerBillCycleDataUsage(payload)).then(
      (cdrOverallResponse) => {
        if (cdrOverallResponse?.payload?.status === 200) {
          fetchDataUsageDetails(
            cdrCurrentDataUsageData,
            cdrOverallResponse?.payload?.data?.data
          );
        }
      }
    );
  };

  const fetchDataUsageDetails = (
    customerCurrentUsageData: any,
    customerOverallDataUsage: any
  ) => {
    const initialDisplayStartDate = parseISO(customerStoreData?.billStartDate);
    let initialDisplayEndDate = parseISO(customerStoreData?.nextBillingDate);
    initialDisplayEndDate = subDays(initialDisplayEndDate, 1);
    setDisplayStartDate(formatDateToMMDDYYYY(initialDisplayStartDate));
    setDisplayEndDate(formatDateToMMDDYYYY(initialDisplayEndDate));
    const formatedEndDate = getUTCDate(
      parseISO(customerStoreData?.billEndDate)
    );
    let latestRecordInCurrentDataUsage = {
      [formatedEndDate]: customerCurrentUsageData?.[formatedEndDate],
    };
    if (!customerCurrentUsageData?.[formatedEndDate]) {
      latestRecordInCurrentDataUsage = {};
    }
    const completeBillCycleDataUsage = {
      ...customerOverallDataUsage,
      ...latestRecordInCurrentDataUsage,
    };
    const sortedDataUsage: any = sortObjectByDates(completeBillCycleDataUsage);
    setConsolidatedBillCycle(sortedDataUsage);
    setTotalNumberOfKeys(Object.keys(sortedDataUsage).length);
    setSelectedDateKeyIndex(Object.keys(sortedDataUsage).length - 1);
    const endDate = subDays(parseISO(customerStoreData.nextBillingDate), 1);
    const uDataInfo = sortedDataUsage[getUTCDate(endDate)];
    setDataUsageInfo(uDataInfo);
  };

  useEffect(() => {
    dispatch(getCustomerDataUsageById(customerId)).then(
      (cdrCurrentResponse: any) => {
        // setLoadingData(false);
        fetchDataUsageDetails(customerUsageData, customerBillCycleDataUsage);
        requestOverallBillCycleDataUsage(
          cdrCurrentResponse?.payload?.data.data
        );
      }
    );
  }, [customerId]);

  useEffect(() => {
    dispatch(
      customerGroup({
        groupId: customerStoreData && customerStoreData.groupId,
      })
    );
  }, []);
  return (
    <Grid
      container
      px={3}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      gap="60px"
      m="70px 0px"
    >
      {!internationalService ? (
        services_card.title.map((title: any, idx: number) => {
          if (
            (!EnvConfig.ENABLE_IR_SERVICES &&
              services_card.title[0] === title) ||
            (!EnvConfig.ENABLE_ILD_SERVICES && services_card.title[1] === title)
          )
            return;
          else
            return (
              <Grid
                item
                sm={2.6}
                xs={12}
                className={service_main}
                key={services_card.title + idx}
              >
                <Box
                  component="img"
                  src={services_icon?.data[idx]?.attributes?.url}
                  alt={services_icon?.data[idx]?.attributes?.alternativeText}
                  sx={{
                    mb: "20px",
                    width: "80px",
                    height: "80px",
                  }}
                />
                <Box
                  className={service_header_text}
                  pb={3}
                  textAlign={{ xs: "center" }}
                >
                  {title}
                  <Box sx={{ pt: { lg: 3, sm: 3, xs: 3 } }}>
                    <Typography className={service_Desc_text}>
                      {services_card.desc[idx]}
                    </Typography>
                  </Box>
                </Box>
                <ButtonCust
                  variantType={EnvConfig.QUATERNARY_BUTTON_TYPE}
                  className={styles.btn_cmp}
                  onClick={() =>
                    setInternationalService(
                      idx === 0
                        ? PostActivationIRILDEnums.roaming
                        : PostActivationIRILDEnums.calling
                    )
                  }
                >
                  {services_card.btn[idx]}
                </ButtonCust>
              </Grid>
            );
        })
      ) : internationalService === PostActivationIRILDEnums.roaming ? (
        <PAInternationalRoaming
          setInternationalService={setInternationalService}
        />
      ) : internationalService === PostActivationIRILDEnums.calling ? (
        <PAInternationalCalling
          setInternationalService={setInternationalService}
          handleBillCycleChange={handleBillCycleChange}
        />
      ) : null}
    </Grid>
  );
};
export default ServiceComponent;
