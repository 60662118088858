import { Box, Dialog, styled } from "@mui/material";
import { Close } from "@mui/icons-material";

type IVideoDialogProps = {
  children?: any;
  open: boolean;
  onClose?: any;
  videoUrl: string;
  sx?: any;
  closeIconsx?: any;
};
const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    margin: "0px",
    maxWidth: "95%",
  },
}));
export const VideoDialogCust = (props: IVideoDialogProps) => {
  return (
    <StyledDialog open={props.open} maxWidth={false} onClose={props.onClose}>
      <Box
        sx={{
          width: "800px",
          p: { xs: "25px", sm: "32px" },
          borderRadius: 1,
          ...props?.sx,
        }}
        maxWidth={{ xs: "calc(100% - 50px)", sm: "500px", lg: "800px" }}
      >
        <Close
          onClick={props.onClose}
          sx={{
            opacity: 0.5,
            position: "absolute",
            top: "5px",
            left: "5px",
            cursor: "pointer",
            fontSize: "20px",
            ...props?.closeIconsx,
          }}
        />
        {props?.children}
        <video
          autoPlay={true}
          controls={true}
          src={props?.videoUrl}
          width="100%"
        ></video>
      </Box>
    </StyledDialog>
  );
};
