import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { bestFitPotentialSavingsContent } from "../../../features/strapi/bestFitSlice";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../config/env.config.json";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { RoutingURLs } from "../../../config/RoutingURLs";

const BestFitSavingsComponent = isTemplateExisits(Config.BestFit.BestFitSavings)
  ? require(`./${Config.BestFit.BestFitSavings.template}_BestFitSavings`)
      .default
  : null;
export type BestFitPotentialSavingsProps = {
  content?: any;
  explorePlansHandler?: any;
  isButtonRequired?: boolean;
};
type BestFitSavingsProps = {
  isButtonRequired?: boolean;
};
export const BestFitSavings = (props: BestFitSavingsProps) => {
  const { bestFitPSavingsContent } = useAppSelector(
    (state: any) => state.strapi.bestfit
  );
  const [cacheDataBestFitSavings, setCacheDataBestFitSavings] =
    useState<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const explorePlansHandler = () => {
    navigate(RoutingURLs.default);
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -85,
        smooth: true,
      });
    }, 1500);
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.bestFit,
      category: gaCategory.plan,
      buttonValue: bestFitPSavingsContent?.button_text,
    });
  };

  useEffect(() => {
    getCache(
      "SavingsBestFit",
      bestFitPotentialSavingsContent,
      setCacheDataBestFitSavings,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("SavingsBestFit", bestFitPSavingsContent);
  }, [bestFitPSavingsContent]);
  return (
    <BestFitSavingsComponent
      content={
        cacheDataBestFitSavings
          ? cacheDataBestFitSavings
          : bestFitPSavingsContent
      }
      explorePlansHandler={explorePlansHandler}
      isButtonRequired={props.isButtonRequired}
    />
  );
};
export default BestFitSavings;
