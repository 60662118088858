import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { gbTpContent } from "../../../features/strapi/giveBackslice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
export type GbtpProps = {
    content?: any;
};
const GbtpComponent = isTemplateExisits(Config.GiveBack.TrustPilot)?
    require(`./${Config.GiveBack.TrustPilot.template}_Gbtp`).default:null;

export const Gbtp = () => {
    const [cacheDataGivebackTp, setCacheDataGivebackTp] = useState<any>(null)
    const { giveBackTPContent } = useAppSelector(
        (state: any) => state.strapi.giveBack
    );
    const dispatch = useAppDispatch();
    useEffect(() => {
      getCache("TpGiveBack", gbTpContent, setCacheDataGivebackTp, dispatch)
    }, []);
    useNonInitialEffect(() => {
      addMultipleCacheData('TpGiveBack', giveBackTPContent)
    }, [giveBackTPContent])
    return (
        (giveBackTPContent || cacheDataGivebackTp ) && <GbtpComponent content={cacheDataGivebackTp ? cacheDataGivebackTp : giveBackTPContent} />
    );
};