import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/env.config.json";
import AddCredits from "./ILDAddCredit/AddCredits/AddCredits";
import TermsAndCondition from "../IRILDComponents/TermsAndCondition/TermsAndCondition";
import ViewRates from "../IRILDComponents/ViewRates/ViewRates";
import AddCreditPayment from "./ILDAddCredit/AddCreditPayment/AddCreditPayment";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import PaymentStatus from "../IRILDComponents/PaymentStatus/PaymentStatus";
import { purchase } from "../../../features/wallet/walletSlice";
import { getILDCredits } from "../../../features/services/servicesSlice";
import ILDUserList from "./ILDUserList/ILDUserList";
import { WalletHistory } from "../IRILDComponents/WalletHistory/WalletHistory";

const T1_PAInternationalCalling = (props: any) => {
  const dispatch = useAppDispatch();
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});
  const { content, setInternationalService, handleBillCycleChange } = props;
  const {
    title,
    back_icon,
    terms,
    view_rates,
    back_txt,
    rates_note,
    terms_icon,
    right_arrow,
    success_icon,
    payment_status,
    failed_icon,
  } = content;

  const { group } = useAppSelector((state: any) => state.account);
  const [openModal, setOpenModal] = useState(false);
  const [isViewRates, setIsViewRates] = useState(false);
  const [isViewHistory, setIsViewHistory] = useState(false);
  const [lineDataForWallet, setLineDataForWallet] = useState<any>(null);
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const [userLineData, setUserLineData] = useState<any>(null);
  const [isCreditPayment, setIsCreditPayment] = useState(false);
  const [isCreditPaymentSuccess, setIsCreditPaymentSuccess] = useState(false);
  const [isCreditPaymentFailed, setIsCreditPaymentFailed] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [creditAmount, setcreditAmount] = useState(
    Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
  );

  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;

  const handleAddCreditCTA = (data: any) => {
    setUserLineData(data);
    setOpenCreditModal(true);
  };

  useEffect(() => {
    dispatch(getConfigKeys());
    dispatch(
      getILDCredits({
        groupId: currentdetails && currentdetails.groupId,
      })
    );
  }, []);

  useEffect(() => {
    setcreditAmount(Number(configKeys?.ILD_INCR_CREDIT_AMOUNT));
  }, [configKeys]);

  const handleConfirmCreditPayment = () => {
    let payload = {
      primaryId: currentdetails.id,
      customerCreditMap: {
        [userLineData.id]: Number(creditAmount),
      },
      ccUUID: getCCDetails[0].uuid,
      totalAmount: Number(creditAmount),
      creditType: "ILD",
      enableFeature: false,
    };
    setIsLoader(true);
    dispatch(purchase({ payload }))
      .then((data) => {
        setIsLoader(false);
        setIsCreditPayment(false);
        setcreditAmount(Number(configKeys?.ILD_INCR_CREDIT_AMOUNT));
        if (data.payload.status === 200) {
          setIsCreditPaymentSuccess(true);
          dispatch(
            getILDCredits({
              groupId: currentdetails && currentdetails.groupId,
            })
          );
        } else {
          setIsCreditPaymentFailed(true);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        setIsCreditPaymentFailed(true);
        setcreditAmount(Number(configKeys?.ILD_INCR_CREDIT_AMOUNT));
      });
  };
  return (
    content? <>
      <TermsAndCondition
        content={content}
        setOpenModal={setOpenModal}
        openModal={openModal}
      />
      {isViewHistory ? (
        <WalletHistory
          setIsViewHistory={setIsViewHistory}
          lineDataForWallet={lineDataForWallet}
          handleBillCycleChange={handleBillCycleChange}
        />
      ) : isViewRates ? (
        <ViewRates setIsViewRates={setIsViewRates} content={content} />
      ) : isCreditPayment ? (
        <AddCreditPayment
          content={content}
          totalCost={creditAmount}
          userLineData={userLineData}
          setIsCreditPayment={setIsCreditPayment}
          isLoader={isLoader}
          onClick={handleConfirmCreditPayment}
        />
      ) : isCreditPaymentSuccess ? (
        <PaymentStatus
          icon={success_icon}
          title={payment_status.successTitle}
          description={payment_status.successDesc}
          button={payment_status.successBtn}
          onClick={() => {
            setIsCreditPaymentSuccess(false);
          }}
          variantType={Config.QUATERNARY_BUTTON_TYPE}
        />
      ) : isCreditPaymentFailed ? (
        <PaymentStatus
          icon={failed_icon}
          title={payment_status.failedTitle}
          description={payment_status.failedDesc}
          button={payment_status.failedBtn}
          onClick={() => {
            setIsCreditPaymentFailed(false);
          }}
          isWidth={true}
          variantType={Config.QUATERNARY_BUTTON_TYPE}
        />
      ) : (
        <Box
          sx={{
            margin: { xs: "auto", sm: "auto", md: "auto" },
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "space-between",
            width: { xs: "auto", sm: "auto", md: "60%" },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "30px",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& span": {
                fontFamily: "var(--font_family_Medium) !important",
                fontWeight: "var(--font_weight_5)",
              },
            }}
          >
            <Box
              component="span"
              style={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                gap: "8px",
                left: "103px",
                cursor: "pointer",
                fontWeight: "var(--font_weight_3)",
              }}
              onClick={() => setInternationalService("")}
            >
              <img
                src={back_icon?.data.attributes.url}
                alt={back_icon?.data.attributes.alternativeText}
                width={17.495}
                height={14.586}
              />
              {back_txt}
            </Box>
            <Box component="span" style={{ margin: "auto" }}>
              {title}
            </Box>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "20px",
              color: "var(--primary_color)",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              mb: "19px",
            }}
            onClick={() => setOpenModal(true)}
          >
            <Box component="span">{terms}</Box>
            <Box
              component="span"
              sx={{
                mt: "3px",
              }}
            >
              <Box
                component="img"
                src={terms_icon?.data?.attributes.url}
                alt={terms_icon?.data?.attributes.alternativeText}
              />
            </Box>
          </Typography>

          <ILDUserList
            group={group}
            content={content}
            handleAddCreditCTA={handleAddCreditCTA}
            setLineDataForWallet={setLineDataForWallet}
            setIsViewHistory={setIsViewHistory}
          />

          <Box>
            <Box
              sx={{
                textAlign: "center",
                fontSize: "14px",
                color: "var(--text_color)",
                margin: "30px 0 18px",
              }}
            >
              {rates_note}
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                color: "var(--primary_color)",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                mb: "19px",
              }}
            >
              <Box
                onClick={() => {
                  setIsViewRates(true);
                }}
                component="span"
              >
                {view_rates}
              </Box>
              <Box
                component="span"
                sx={{
                  mt: "2px",
                }}
              >
                <Box
                  component="img"
                  src={right_arrow?.data?.attributes.url}
                  alt={right_arrow?.data?.attributes.alternativeText}
                  width={17.495}
                  height={14.586}
                />
              </Box>
            </Typography>
          </Box>
        </Box>
      )}
      <AddCredits
        content={content}
        userLineData={userLineData}
        setOpenCreditModal={setOpenCreditModal}
        openCreditModal={openCreditModal}
        setIsCreditPayment={setIsCreditPayment}
        creditAmount={creditAmount}
        setcreditAmount={setcreditAmount}
        configKeys={configKeys}
      />
    </>:null
  );
};

export default T1_PAInternationalCalling;
