import { InternalBannerProps } from "./InternalBanner";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";

const InternalBannerT2 = (props: InternalBannerProps) => {
  const { heading, sub_heading, color_gradiant } =
    props?.bannerContent || {};

  const background_box = {
    display: { xs: "block", sm: "none" },
    width: "100%",
    minHeight:"60px",
    backgroundColor: "var(--primary_brand_color)",
    opacity: 0.2,
  };

  const grid_wrapper = {
    position: "absolute",
    textAlign: { xs: "center", sm: "left", md: "center" },
    color: { xs: "var(--primary_brand_color)", sm: "inherit" },
    transform: {
      xs: "translate(-50%, -50%)",
      sm: "translateY(-50%)",
      md: "translate(-50%, -50%)",
    },
    top: "50%",
    left: { xs: "50%", sm: "40px", md: "50%" },
  };

  const { largeDesktop, desktop, ipad } = getResolutionDetails();
  return (
    props?.bannerContent && (
      <Container maxWidth={false} disableGutters sx={{ position: "relative" }}>
        <Paper
          sx={{
            padding: 0,
            boxShadow: "unset",
            background: color_gradiant,
            minHeight: { sm: "300px", md: "200px" },
          }}
        ></Paper>
        <Box sx={background_box} />
        <Grid sx={grid_wrapper}>
          <Grid item>
            <Box>
              {heading && (
                <Typography
                  component="h1"
                  variant="h3"
                  gutterBottom
                  sx={{
                    margin: { xs: 0 },
                    width: { xs: "80vw", sm: "50vw", lg: "55vw" },
                    color:"white"
                  }}
                  className="internal_banner_title"
                  dangerouslySetInnerHTML={{
                    __html: heading,
                  }}
                ></Typography>
              )}
              {sub_heading && (
                <Typography
                  variant="h4"
                  component="h4"
                  className={"main_banner_sub_title"}
                  sx={{
                    margin: "14px 0px 25px 0px",
                    display: { xs: "none", sm: "block" },
                    width: { xs: "80vw", sm: "50vw", lg: "55vw" },
                    color:"white"
                  }}
                >
                  {sub_heading}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  );
};

export default InternalBannerT2;
