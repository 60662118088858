import { ByodProps } from "./Byod";
import styles from "./S1_Byod.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import EnvConfig from "../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { useNavigate } from "react-router-dom";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import clsx from "clsx";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { LazyLoadImageCust } from "../../widgets";
import { useAppSelector } from "../../../app/hooks";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const ByodComponent = (props: ByodProps) => {
  const {
    title,
    description,
    button_1,
    button_2,
    image,
    shop_new_phones_link,
    redirect_url,
    is_url_new_tab
  } = props?.byodContent || {};
  const navigate = useNavigate();
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.plan,
      category: category,
      buttonValue: value,
    });
  };

  return (
    props?.byodContent && (
      <Grid
        container
        className={clsx(
          styles.byod,
          location.pathname !== RoutingURLs.myAccount &&
            `min_height_byod_section_${EnvConfig.DOMAIN?.toLowerCase()}`
        )}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0",
            sm: "calc(var(--ipad_section_padding)/2) 0",
            md: "calc(var(--desktop_section_padding)/2) 0",
          },
          flexDirection: "var(--byod_sections_order)",
          px: { xs: 0, md: "35px" },
        }}
      >
        <Grid
          item
          md={6}
          className={styles.content_section}
          sx={{
            m: "20px 0",
            px: { xs: "35px", md: 0 },
            display: { xs: "flex", md: "block" },
            justifyContent: { md: "space-around", xs: "center" },
            alignItems: "center",
            flexDirection: { xs: "column" },
          }}
          order={{ xs: 3, md: 2 }}
        >
          <Typography
            variant="h3"
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            {BrandNameUpdate(title)}
          </Typography>
          <Grid item xs={12}>
            <Box
              className={styles.byod_line}
              sx={{ m: { sm: "20px 0" }, my: 2.5 }}
            ></Box>
          </Grid>
          <Typography
            variant="body1"
            className="description_text"
            sx={{ textAlign: { xs: "center", md: "left" } }}
            mb={{ sm: "25px" }}
          >
            {BrandNameUpdate(description)}
          </Typography>
          <Grid
            item
            container
            xs={12}
            className={styles.btn_section}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", md: "start" },
            }}
          >
            <Box
              sx={{ mr: { sm: "20px" }, my: { xs: "10px", lg: "0" } }}
              display="flex"
              justifyContent="center"
            >
              {button_1 && (
                <ButtonCust
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    if (EnvConfig.BYOD_REDIRECTION_CHECK_COMPATIBILITY) {
                      navigate(shop_new_phones_link);
                    } else {
                      props.onImeiCheckClick();
                    }
                    GAAndFBEventsHandler(
                      gaCategory.networkCompatible,
                      button_1
                    );
                  }}
                  className={styles.btn_cmp1}
                >
                  {button_1}
                </ButtonCust>
              )}
            </Box>
            <Box
              sx={{ mr: { sm: "20px", md: "0" }, my: { xs: "10px", lg: "0" } }}
              display="flex"
              justifyContent="center"
            >
              {button_2 && (
                <ButtonCust
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    if(redirect_url){
                      window.open(
                        redirect_url,
                        is_url_new_tab ? "_blank" : "_self"
                      );
                    }else{
                    props.onShopNewPhonesClick(globalVal?.shopPhone);
                    GAAndFBEventsHandler(
                      gaCategory.networkCompatible,
                      button_2
                    );
                    }
                  }}
                  className={styles.btn_cmp2}
                >
                  {button_2}
                </ButtonCust>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item md={6} order={{ XS: 2, md: 3 }}>
          <LazyLoadImageCust
            alt={image?.data?.attributes?.alternativeText}
            src={image?.data?.attributes?.url || ""}
            classNames={styles.byod_home_img}
          />
        </Grid>
      </Grid>
    )
  );
};
export default ByodComponent;
