import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

type InitialState = {
  walletData: any;
  errorWalletData: any;
  romingData: any;
  errorRomingData: any;
  callingData: any;
  errorCallingData: any;
  purchaseData: any;
  errorPurchaseData: any;
  purchaseHistoryData: any;
  errorPurchaseHistoryData: any;
  updateRoamingData: any;
  errorUpdateRoamingData: any;
};
const initialState: InitialState = {
  walletData: null,
  errorWalletData: null,
  romingData: {},
  errorRomingData: null,
  callingData: {},
  errorCallingData: null,
  purchaseData: null,
  errorPurchaseData: null,
  purchaseHistoryData: null,
  errorPurchaseHistoryData: null,
  updateRoamingData: null,
  errorUpdateRoamingData: null,
};

export const getWallet = createAsyncThunk(
  "wallet/getWallet",
  async (customerID: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.customerDataUsage}${customerID}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getIR = createAsyncThunk("wallet/getIR", async () => {
  try {
    const response = await apiJunction.makeRequest({
      method: "get",
      url: `${ApiEndPoints.internationalRoming}`,
    });
    return response;
  } catch (err: any) {
    return err.response.data;
  }
});

export const getIC = createAsyncThunk("wallet/getIC", async () => {
  try {
    const response = await apiJunction.makeRequest({
      method: "get",
      url: `${ApiEndPoints.internationalCalling}`,
    });
    return response;
  } catch (err: any) {
    return err.response.data;
  }
});

export const getPurchaseHistory = createAsyncThunk(
  "wallet/getPurchaseHistory",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.walletPurchaseHistory}${action.payload.customerId}?creditType=${action.payload.creditType}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`,
        body: action.data,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const purchase = createAsyncThunk(
  "wallet/purchase",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.walletPurchase}`,
        body: action.payload,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRoaming = createAsyncThunk(
  "wallet/updateRoaming",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.updateRoaming}`,
        body: action.payload,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getWallet.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.walletData = action.payload.data;
        state.errorWalletData = null;
      }
    );
    builder.addCase(getWallet.rejected, (state, action) => {
      state.walletData = null;
      state.errorWalletData = action.payload || "Something went wrong";
    });
    builder.addCase(getIR.fulfilled, (state, action: PayloadAction<any>) => {
      state.romingData = action.payload.data;
      state.errorRomingData = null;
    });
    builder.addCase(getIR.rejected, (state, action) => {
      state.romingData = null;
      state.errorRomingData = action.payload || "Something went wrong";
    });
    builder.addCase(getIC.fulfilled, (state, action: PayloadAction<any>) => {
      state.callingData = action.payload.data;
      state.errorCallingData = null;
    });
    builder.addCase(getIC.rejected, (state, action) => {
      state.callingData = null;
      state.errorCallingData = action.payload || "Something went wrong";
    });
    builder.addCase(purchase.fulfilled, (state, action: PayloadAction<any>) => {
      state.purchaseData = action.payload.data;
      state.errorPurchaseData = null;
    });
    builder.addCase(purchase.rejected, (state, action) => {
      state.purchaseData = null;
      state.errorPurchaseData = action.payload || "Something went wrong";
    });
    builder.addCase(
      getPurchaseHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.purchaseHistoryData = action.payload.data;
        state.errorPurchaseHistoryData = null;
      }
    );
    builder.addCase(getPurchaseHistory.rejected, (state, action) => {
      state.purchaseHistoryData = null;
      state.errorPurchaseHistoryData = action.payload || "Something went wrong";
    });
    builder.addCase(
      updateRoaming.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateRoamingData = action.payload.data;
        state.errorUpdateRoamingData = null;
      }
    );
    builder.addCase(updateRoaming.rejected, (state, action) => {
      state.updateRoamingData = null;
      state.errorUpdateRoamingData = action.payload || "Something went wrong";
    });
  },
});

export default walletSlice.reducer;
