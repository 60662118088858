import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import {
  getCustomerHeaderContent,
  strapiHeaderContent,
} from "../../../features/strapi/componentSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import firebase from "../../../adapters/firebase";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import { resetDataGetCustomer } from "../../../features/checkout/checkoutSlice";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import { getCartItems } from "../../../features/shopWare/shopWareSlice";
const HeaderComponent = isTemplateExisits(Config.Header)
  ? require(`./${Config.Header.template}_Header`).default
  : null;

export type HeaderProps = {
  cacheHeader?: any;
  cacheCustomHeader?: any;
  logoutHandler?: any;
};
export const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { commonPage } = useAppSelector((state: any) => state.strapi);
  const { headerContent, customerHeaderContent } = commonPage;
  const [cacheHeader, setCacheHeader] = useState<any>(null);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [cacheCustomHeader, setCacheCustomHeader] = useState<any>(null);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const [cookie, setCookie, removeCookie] = useCookies(["ssoAccessToken"]);
  useEffect(() => {
    getCache("Header", strapiHeaderContent, setCacheHeader, dispatch);
    getCache(
      "customHeader",
      getCustomerHeaderContent,
      setCacheCustomHeader,
      dispatch
    );
  }, []);
  useEffect(() => {
    EnvConfig?.HIDE_HEADER_ROUTES.map((route: any) => {
      if (route === window.location.pathname) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    });
  }, [window.location.pathname]);
  useNonInitialEffect(() => {
    addMultipleCacheData("Header", headerContent);
  }, [headerContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("customHeader", customerHeaderContent);
  }, [customerHeaderContent]);

  const logoutHandler = (redirectURL?: string) => {
    if (EnvConfig.IS_SSO_LOGIN) {
      localStorage.clear();
      removeCookie("ssoAccessToken");
      window.location.href = `${process.env.REACT_APP_SSO_LOGOUT_URL}`;
      localStorage.setItem("globalValues", JSON.stringify(globalVal));
      navigate(redirectURL ? redirectURL : RoutingURLs.home);
    } else {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.clear();
          if (EnvConfig?.IS_SHOPWARE_ENABLED) {
            Cookies.remove("swContextToken");
            dispatch(getCartItems());
            navigate(redirectURL ? redirectURL : RoutingURLs.home);
          } else {
            navigate(redirectURL ? redirectURL : RoutingURLs.home);
          }
          localStorage.setItem("globalValues", JSON.stringify(globalVal));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    HeaderComponent &&
    showHeader &&
    (cacheHeader || headerContent) &&
    (cacheCustomHeader || customerHeaderContent) && (
      <HeaderComponent
        cacheHeader={cacheHeader ? cacheHeader : headerContent}
        cacheCustomHeader={
          cacheCustomHeader ? cacheCustomHeader : customerHeaderContent
        }
        logoutHandler={logoutHandler}
      />
    )
  );
};
