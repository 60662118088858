import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  networkCoverageContent: any;
  errorNetworkContent: any;
  ulPromoNetworkCoverageContent: any;
  ulPromoErrorNetworkContent: any;
  hmBestfitContent: any,
  errorHmBestfitContent: any,
  leadGenContent: any;
  errorLeadGenContent: any;
  BannerContent: any;
  errorBannerContent: any;
  brandFeatureContent: any;
  errorBrandFeature: any;
  planCardContent: any;
  errorPlanCardContent: any;
  appSection: any;
  errorAppSectionContent: any;
  featuresContent: any;
  errorFeaturesContent: any;
  ulPromoFeaturesContent: any;
  ulPromoErrorFeaturesContent: any;
  byodContent: any;
  errorByodContent: any;
  purchaseContent: any;
  errorPurchaseContent: any;
  tpContent: any;
  errorTpContent: any;
  esimCoverageContent: any;
  errorEsimCoverageContent: any;
  esimIMEIContent: any;
  errorEsimIMEIContent: any;
  explorePlansContent: any;
  errorExplorePlansContent: any;
  ulPromoAppSection:any;
  errorUlPromoAppSection:any;
  customerSupportContent:any;
  errorCustomerSupportContent: any;
};

const initialState: InitialState = {
  networkCoverageContent: null,
  errorNetworkContent: null,
  ulPromoNetworkCoverageContent: null,
  ulPromoErrorNetworkContent: null,
  hmBestfitContent: null,
  errorHmBestfitContent: null,
  leadGenContent: null,
  errorLeadGenContent: null,
  BannerContent: null,
  errorBannerContent: null,
  brandFeatureContent: null,
  errorBrandFeature: null,
  planCardContent: null,
  errorPlanCardContent: null,
  featuresContent: null,
  errorFeaturesContent: null,
  ulPromoFeaturesContent: null,
  ulPromoErrorFeaturesContent: null,
  appSection: null,
  errorAppSectionContent: null,
  byodContent: null,
  errorByodContent: null,
  purchaseContent: null,
  errorPurchaseContent: null,
  tpContent: null,
  errorTpContent: null,
  esimCoverageContent: null,
  errorEsimCoverageContent: null,
  esimIMEIContent: null,
  errorEsimIMEIContent: null,
  explorePlansContent: null,
  errorExplorePlansContent: null,
  ulPromoAppSection:null,
  errorUlPromoAppSection:null,
  customerSupportContent: null,
  errorCustomerSupportContent: null,
};

// Generates pending, fulfilled and rejected action types
export const checkCoverageContent = createAsyncThunk(
  "homeContent/checkCoverageContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.CheckCoverage.template}${StrapiApiEndPoints.networkCoverage}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const ulPromoCheckCoverageContent = createAsyncThunk(
  "homeContent/ulPromoCheckCoverageContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.ULPromo.CheckCoverage.template}${StrapiApiEndPoints.networkCoverage}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const homeBestfitContent = createAsyncThunk(
  "homeContent/hmBestfit",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.BestFitSection.template}${StrapiApiEndPoints.hmBestfit}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const leadGenerationContent = createAsyncThunk(
  "homeContent/leadGeneration",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.LeadGeneration.template}${StrapiApiEndPoints.leadGeneration}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const brandFeaturesContent = createAsyncThunk(
  "homeContent/brandFeature",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.BrandFeatures.template}${StrapiApiEndPoints.brandFeature}`,
      isStrapiApi: true,
    });
  }
);
// Generates pending, fulfilled and rejected action types of app section
export const appSectionContent = createAsyncThunk(
  "homeContent/appSectionContent",
  () => {
      return apiJunction.makeRequest({
        method: "get",
        url: `/${Config.Home.AppSection.template}${StrapiApiEndPoints.appSection}`,
        isStrapiApi: true,
      });
    } 
);

export const ulPromoAppSectionContent = createAsyncThunk(
  "homeContent/ulPromoAppSectionContent",
  () => {
      return apiJunction.makeRequest({
        method: "get",
        url: `/${Config.ULPromo.AppSection.template}${StrapiApiEndPoints.appSection}`,
        isStrapiApi: true,
      });
    } 
);
// Generates pending, fulfilled and rejected action types
export const PlanSectionContent = createAsyncThunk(
  "homeContent/PlanSectionContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.Plans.template}${StrapiApiEndPoints.planCard}`,
      isStrapiApi: true,
    });
  }
);
export const PurchaseJourneyContent = createAsyncThunk(
  "homeContent/PurchaseJourneyContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.purchaseFlow}`,
      isStrapiApi: true,
    });
  }
);
export const EsimCoverageModalContent = createAsyncThunk(
  "homeContent/EsimCoverageModalContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.EsimCoverageCheck.template}${StrapiApiEndPoints.esimCoverageModal}`,
      isStrapiApi: true,
    });
  }
);
export const EsimIMEIModalContent = createAsyncThunk(
  "homeContent/EsimIMEIModalContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.EsimImeiCheck.template}${StrapiApiEndPoints.esimIMEIModal}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const planFeaturesContent = createAsyncThunk(
  "homeContent/planFeatures",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.PlanFeatures.template}${StrapiApiEndPoints.planFeatures}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const ulPromoPlanFeaturesContent = createAsyncThunk(
  "homeContent/ulPromoPlanFeaturesContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.ULPromo.PlanFeatures.template}${StrapiApiEndPoints.planFeatures}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const byodStaticContent = createAsyncThunk("homeContent/byod", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Home.BYOD.template}${StrapiApiEndPoints.byod}`,
    isStrapiApi: true,
  });
});

// Generates pending, fulfilled and rejected action types
export const getCustomerSupportContent = createAsyncThunk("homeContent/getCustomerSupportContent", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Home.CustomerService.template}${StrapiApiEndPoints.customerService}`,
    isStrapiApi: true,
  });
});

// Generates pending, fulfilled and rejected action types
export const HmTPContent = createAsyncThunk("homeContent/HmTPContent", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Home.TrustPilot.template}${StrapiApiEndPoints.tpHome}`,
    isStrapiApi: true,
  });
});

export const explorePlansStrapiContent = createAsyncThunk(
  "homeContent/ExplorePlansContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Home.ExplorePlans.template}${StrapiApiEndPoints.explorePlans}`,
      isStrapiApi: true,
    });
  }
);

const strapiHomeSlice = createSlice({
  name: "homeContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      checkCoverageContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.networkCoverageContent = action.payload?.data?.data?.attributes;
        state.errorNetworkContent = null;
      }
    );
    builder.addCase(checkCoverageContent.rejected, (state, action) => {
      state.networkCoverageContent = null;
      state.errorNetworkContent = action.error || "Something went wrong";
    });
    builder.addCase(
      ulPromoCheckCoverageContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.ulPromoNetworkCoverageContent = action.payload?.data?.data?.attributes;
        state.ulPromoErrorNetworkContent = null;
      }
    );
    builder.addCase(ulPromoCheckCoverageContent.rejected, (state, action) => {
      state.ulPromoNetworkCoverageContent = null;
      state.ulPromoErrorNetworkContent = action.error || "Something went wrong";
    });

    builder.addCase(
      homeBestfitContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.hmBestfitContent = action.payload?.data?.data?.attributes;
        state.errorHmBestfitContent = null;
      }
    );
    builder.addCase(homeBestfitContent.rejected, (state, action) => {
      state.hmBestfitContent = null;
      state.errorHmBestfitContent = action.error || "Something went wrong";
    });

    builder.addCase(
      leadGenerationContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadGenContent = action.payload?.data?.data?.attributes;
        state.errorLeadGenContent = null;
      }
    );
    builder.addCase(leadGenerationContent.rejected, (state, action) => {
      state.leadGenContent = null;
      state.errorLeadGenContent = action.error || "Something went wrong";
    });
    builder.addCase(
      appSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.appSection = action.payload?.data?.data?.attributes;
        state.errorAppSectionContent = null;
      }
    );
    builder.addCase(appSectionContent.rejected, (state, action) => {
      state.appSection = null;
      state.errorAppSectionContent = action.error || "Something went wrong";
    });
    builder.addCase(
      ulPromoAppSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.ulPromoAppSection= action.payload?.data?.data?.attributes;
        state.errorUlPromoAppSection = null;
      }
    );
    builder.addCase(ulPromoAppSectionContent.rejected, (state, action) => {
      state.ulPromoAppSection = null;
      state.errorUlPromoAppSection = action.error || "Something went wrong";
    });
    builder.addCase(
      PlanSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.planCardContent = action.payload?.data?.data?.attributes;
        state.errorPlanCardContent = null;
      }
    );
    builder.addCase(PlanSectionContent.rejected, (state, action) => {
      state.planCardContent = null;
      state.errorPlanCardContent = action.error || "Something went wrong";
    });
    builder.addCase(
      planFeaturesContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.featuresContent = action.payload?.data?.data?.attributes || null;
        state.errorFeaturesContent = null;
      }
    );
    builder.addCase(planFeaturesContent.rejected, (state, action) => {
      state.featuresContent = null;
      state.errorFeaturesContent = action.error || "Something went wrong";
    });
    builder.addCase(
      ulPromoPlanFeaturesContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.ulPromoFeaturesContent = action.payload?.data?.data?.attributes || null;
        state.ulPromoErrorFeaturesContent = null;
      }
    );
    builder.addCase(ulPromoPlanFeaturesContent.rejected, (state, action) => {
      state.ulPromoFeaturesContent = null;
      state.ulPromoErrorFeaturesContent = action.error || "Something went wrong";
    });
    builder.addCase(
      brandFeaturesContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.brandFeatureContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorBrandFeature = null;
      }
    );
    builder.addCase(brandFeaturesContent.rejected, (state, action) => {
      state.brandFeatureContent = null;
      state.errorBrandFeature =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      byodStaticContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.byodContent = action?.payload?.data?.data?.attributes || null;
        state.errorByodContent = null;
      }
    );
    builder.addCase(byodStaticContent.rejected, (state, action) => {
      state.byodContent = null;
      state.errorByodContent = action.error || "Something went wrong";
    });
    builder.addCase(
      getCustomerSupportContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerSupportContent = action?.payload?.data?.data?.attributes || null;
        state.errorCustomerSupportContent = null;
      }
    );
    builder.addCase(getCustomerSupportContent.rejected, (state, action) => {
      state.customerSupportContent = null;
      state.errorCustomerSupportContent = action.error || "Something went wrong";
    });
    builder.addCase(
      PurchaseJourneyContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.purchaseContent = action.payload;
        state.errorPurchaseContent = null;
      }
    );
    builder.addCase(PurchaseJourneyContent.rejected, (state, action) => {
      state.purchaseContent = null;
      state.errorPurchaseContent = action.error || "Something went wrong";
    });
    builder.addCase(
      HmTPContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.tpContent = action.payload || null;
        state.errorTpContent = null;
      }
    );
    builder.addCase(HmTPContent.rejected, (state, action) => {
      state.tpContent = null;
      state.errorTpContent = action.error || "Something went wrong";
    });
    builder.addCase(
      EsimCoverageModalContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.esimCoverageContent = action.payload.data.data;
        state.errorEsimCoverageContent = null;
      }
    );
    builder.addCase(EsimCoverageModalContent.rejected, (state, action) => {
      state.esimCoverageContent = null;
      state.errorEsimCoverageContent = action.error || "Something went wrong";
    });
    builder.addCase(
      EsimIMEIModalContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.esimIMEIContent = action.payload.data.data;
        state.errorEsimIMEIContent = null;
      }
    );
    builder.addCase(EsimIMEIModalContent.rejected, (state, action) => {
      state.esimIMEIContent = null;
      state.errorEsimIMEIContent = action.error || "Something went wrong";
    });
    builder.addCase(explorePlansStrapiContent.fulfilled, (state, action) => {
      state.explorePlansContent = action?.payload?.data?.data?.attributes;
      state.errorExplorePlansContent = null;
    });
    builder.addCase(explorePlansStrapiContent.rejected, (state, action) => {
      state.explorePlansContent = null;
      state.errorExplorePlansContent = action.error || "Something went wrong";
    });
  },
});

export default strapiHomeSlice.reducer;
