import { addMultipleCacheData, isTemplateExisits, useNonInitialEffect, getCache } from "../../../utils/commonFunctions";
import Config from "../../../config/app.config.json";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { cardsContent } from "../../../features/strapi/settingsSlice";
const SettingsCardsComponent = isTemplateExisits(Config.Settings.SettingsCards)
  ? require(`./${Config.Settings.SettingsCards.template}_SettingsCards`).default
  : null;

export const SettingsCards = ()=>{
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { cardsData } = useAppSelector((state: any) => state.strapi.settings);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("SettingsCards", cardsContent, setCacheContent, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("SettingsCards", cardsData);
  }, [cardsData]);
    return (
      (cacheContent || cardsData) &&
        <SettingsCardsComponent content={cacheContent?cacheContent:cardsData}/>
    )
};
