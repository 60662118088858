export const addMultipleCacheData = async (
  cacheName: any,
  cacheContent: any
) => {
  const CacheToBeStored = [
    {
      cacheName: cacheName,
      cacheData: { content: cacheContent, date: new Date() },
      url: window.location.origin,
    },
  ];

  for (var i = 0; i < CacheToBeStored.length; i++) {
    // Converting our response into Actual Response form
    const data = new Response(JSON.stringify(CacheToBeStored[i].cacheData));

    if ("caches" in window) {
      // Opening given cache and putting our data into it
      var cache = await caches.open(CacheToBeStored[i].cacheName);
      cache.put(CacheToBeStored[i].url, data);
    }
  }
};
