export const CustomerStatus = {
  INACTIVE: 0,
  ACTIVATION_IN_PROGRESS: 1,
  ACTIVE: 2,
  SUSPENDED: 3,
  DISCONNECTED: 4,
  HOTLINED: 5,
  REJECTED: 6,
  DISABLED: 7,
  ACTIVATION_FAILED: 8,
  PORTING_IN_PROGRESS: 9,
  PORTING_FAILED: 10,
  PORT_CANCELLED: 11
};

export const ActivationStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  NOT_STARTED: "NOT_STARTED",
  COMPLETED: "COMPLETED"
}