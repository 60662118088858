import EnvConfig from "../../../../config/env.config.json";
import { Box, Typography } from "@mui/material";
import Styles from "./ULPromoPlanCard.module.scss";
import { ButtonCust, Divider } from "../../../widgets";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { HSD } from "../../../../components";

type Props = {
  name?: string;
  planGB?: string;
  planText?: string;
  currency?: string;
  cost?: any;
  noLines?: any;
  secondCost?: any;
  buttonHandler?: any;
  disabled?: any;
  discountSecondCost?: any;
  discountfirstcost?: any;
  content?: any;
  eligibilityCheckFromPromo?: boolean;
  setEligibilityCheckFromPromo?: any;
};
export const ULPromoPlanCard = (props: Props) => {
  const location = useLocation();
  const {
    disabled,
    name,
    buttonHandler,
    planGB,
    discountfirstcost,
    cost,
    discountSecondCost,
    secondCost,
    noLines,
    planText,
    currency,
    content,
    eligibilityCheckFromPromo,
    setEligibilityCheckFromPromo,
  } = props;
  const {
    all_plan_cards,
    home_page_title,
    buttons_div,
    card_items,
    wrap_line,
    first_wrapper,
    cost_data,
    card_cost,
    card_cost1,
    text_space,
    span_color,
    second_cost_data,
    card_cost2,
    GB_wrap,
    card_data,
    card_GB,
    card_text,
    no_contract_text,
    no_contract,
    features_div,
    feature_title,
    features_wrap,
    features_inner_wrap,
    terms_offer,
  } = Styles;
  const {
    baseline_price,
    add_line_price,
    per_month_first,
    add_line_avail,
    per_mon_addline_tax,
    discount_text,
    per_mon_num_tax,
    per_mon_numLines,
    per_mon_line,
    per_mon_tax,
    button_text,
    plan_details,
    features_1,
    speed_icon,
    features_2,
    perline,
    features_title,
    terms_text,
  } = content || {};

  return (
    <Box sx={{ opacity: disabled ? 0.5 : 1 }} className={all_plan_cards}>
      <Typography variant="h3" component={"h3"} className={home_page_title}>
        {name}
      </Typography>
      <Divider styles={{ m: "20px auto 25px" }} />
      <Box className={card_items}>
        <Box className={first_wrapper}>
          {location.pathname === RoutingURLs?.ulPromo ? (
            <>
              {" "}
              <Box className={cost_data}>
                <Box component={"span"} className={card_cost}>
                  {`${currency}${baseline_price}`}
                  {""}
                </Box>
                <Box component={"span"} className={card_cost1}>
                  {per_month_first}
                </Box>
              </Box>
              <Box className={second_cost_data}>
                {noLines === 1 ? (
                  <>
                    <Box component={"span"} className={card_cost1}>
                      {add_line_avail}{" "}
                    </Box>
                    <Box
                      component={"span"}
                      className={card_cost}
                    >{`${currency}${30}`}</Box>
                    <Box component={"span"} className={span_color}>
                      {perline}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      component={"span"}
                      className={card_cost}
                    >{`${currency}${30}`}</Box>
                    <Box component={"span"} className={card_cost1}>
                      {" "}
                      {per_mon_addline_tax}
                    </Box>
                  </>
                )}
                <Box className={card_cost2}>
                  {BrandNameUpdate(discount_text)}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box className={cost_data}>
                <Box component={"span"} className={card_cost}>{`${currency}${
                  discountfirstcost ? discountfirstcost : 0
                }`}</Box>
                <Box component={"span"} className={card_cost1}>
                  {per_mon_tax}
                </Box>
                <Box component={"span"} className={text_space}>
                  <s>{`${currency}${cost ? cost : 0}`}</s>
                  {perline}
                </Box>
              </Box>

              <Box className={second_cost_data}>
                <Box component={"span"} className={card_cost}>{`${currency}${
                  discountSecondCost ? discountSecondCost : 0
                }`}</Box>
                <Box component={"span"} className={card_cost1}>
                  {per_mon_numLines}&nbsp; {noLines}&nbsp;{per_mon_num_tax}
                </Box>
                <Box
                  component={"span"}
                  className={clsx(span_color, text_space)}
                >
                  <s>{`${currency}${secondCost ? secondCost : 0}`}</s>
                  {per_mon_numLines}&nbsp; {noLines} &nbsp;{per_mon_line}
                </Box>
                <Box className={card_cost2}>
                  {BrandNameUpdate(discount_text)}
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box className={wrap_line}></Box>

        <Box className={GB_wrap}>
          <Box className={card_data}>
            <Box className={card_GB}>{planGB}</Box>
            <Box className={card_text}>
              <img src={speed_icon?.data?.attributes?.url} alt="" />
              {planText && ` ${planText}`}
            </Box>
          </Box>
          {plan_details?.map((el: any) => {
            return (
              <Box className={no_contract}>
                <img
                  src={el?.image?.data?.attributes.url}
                  alt={el?.alt_image_text}
                />
                <Box component={"span"} className={no_contract_text}>
                  {el?.alt_image_text}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box className={wrap_line}></Box>

        <Box className={features_div}>
          <Box className={feature_title}>{features_title}</Box>
          <Box className={features_wrap}>
            <Box className={features_inner_wrap}>
              <Box className={features_inner_wrap}>
                {features_1?.map((el: any) => (
                  <Box component={"span"}>
                    <img
                      src={el?.image?.data?.attributes.url}
                      alt={el?.alt_image_text}
                    />
                    {el?.alt_image_text}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={features_inner_wrap}>
              <Box className={features_inner_wrap}>
                {features_2?.map((el: any) => (
                  <Box component={"span"}>
                    <img
                      src={el?.image?.data?.attributes.url}
                      alt={el?.alt_image_text}
                    />
                    {el?.alt_image_text}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={buttons_div}>
        <ButtonCust
          variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
          onClick={disabled ? () => null : buttonHandler}
        >
          {button_text}
        </ButtonCust>
      </Box>
      <Box className={terms_offer}>
        <Box
          component={"span"}
          sx={{ cursor: "pointer" }}
          onClick={() =>
            window.open(RoutingURLs?.unlimitedBundleTerms, "_blank")
          }
        >
          {terms_text}
        </Box>
      </Box>
      {eligibilityCheckFromPromo && (
        <HSD
          eligibilityCheckFromPromo={eligibilityCheckFromPromo}
          setEligibilityCheckFromPromo={setEligibilityCheckFromPromo}
        />
      )}
    </Box>
  );
};
