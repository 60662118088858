import { Box, Typography } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { Divider } from "../../../widgets";

import { PayNowPlanProps } from "./PayNowPlan";

type Props = PayNowPlanProps;

function PayNowPlan(props: Props) {
  const { content, plan, currentLine } = props;

  return (
    <>
      <Typography
        variant="h4"
        component="div"
        sx={{ my: 1 }}
        style={{
          fontSize: "22px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          fontFamily: "var(--font_family_Semibold)",
        }}
      >
        {currentLine?.reachPlanDisplayName}
      </Typography>
      <Divider
        styles={{
          m: "2px auto 15px",
          height: "4px !important",
        }}
      />

      <Typography
        sx={{ my: 1 }}
        style={{
          fontSize: "14px",
          fontFamily: "var(--font_family_Semibold)",
        }}
      >
        {plan?.isUnlimited
          ? content?.pay_now?.plan_desc
          : content.pay_now.unl_plan_desc}
      </Typography>
      <Box sx={{ m: "20px 0px 20px 10px" }}>
        <Typography
          component="span"
          variant="h4"
          sx={{ my: 1, mt: 2 }}
          style={{ fontSize: "24px", fontWeight: "600" }}
        >
          {plan &&
            GetCurrencyFixedToTwo(
              !currentLine?.isPrimary
                ? plan?.additionalLinePrice
                : plan?.baseLinePrice
            )}
        </Typography>
        <Typography
          component="span"
          sx={{ my: 1, mt: 2 }}
          style={{
            fontSize: "12px",
            fontFamily: "var(--font_family_Semibold)",
            textAlign: "center",
          }}
        >
          {content?.pay_now?.per_line}
        </Typography>
        <Typography
          component="span"
          sx={{ my: 1, mt: 2 }}
          style={{
            fontSize: "12px",
            fontFamily: "var(--font_family_Semibold)",
            textAlign: "center",
            color: "var(--primary_brand_color)",
          }}
        >
          &nbsp;{content?.pay_now?.tax_fee}
        </Typography>
      </Box>

      <Typography
        component="div"
        sx={{ my: 1 }}
        style={{
          fontSize: "14px",
          marginTop: "15px",
          marginBottom: "5px",
          fontFamily: "var(--font_family_Semibold)",
        }}
      >
        {content?.pay_now?.common_txt?.replace(
          "{pp}",
          GetCurrencyFixedToTwo(plan?.additionalLinePrice)
        )}
      </Typography>
    </>
  );
}

export default PayNowPlan;
