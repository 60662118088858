import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { byodStaticContent } from "../../../features/strapi/homePageSlice";
import { PurchaseFlow } from "../PlanCard/PlanCardsComponent/PurchaseFlow/PurchaseFlow";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";

export type ByodProps = {
  byodContent?: any;
  onImeiCheckClick: () => void;
  onShopNewPhonesClick: (url: string | null) => void;
};
const ByodComponent = isTemplateExisits(Config.Home.BYOD)
  ? require(`./${Config.Home.BYOD.template}_Byod`).default
  : null;

export const Byod = () => {
  const [purchaseJourney, setPurchaseJourney] = useState(false);
  const [coverageModal, setCoverageModal] = useState(false);
  const [showCompatibilityModal, setShowCompatibilityModal] = useState(false);
  const [coverageError, setCoverageError] = useState(false);
  const [coverageMessage, setCoverageMessage] = useState("");
  const [finalSuccessModal, setFinalSuccessModal] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [emailCompatability, setEmailCompatability] = useState("");
  const [imeiNumber, setImeiNumber] = useState("");
  const { byodContent } = useAppSelector((state: any) => state.strapi.homepage);
  const dispatch = useAppDispatch();
  const [cacheDataByod, setCacheDataByod] = useState<any>(null);
  useEffect(() => {
    getCache("ByodHome", byodStaticContent, setCacheDataByod, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("ByodHome", byodContent);
  }, [byodContent]);

  const onImeiCheckClick = () => {
    setEmailCompatability("");
    setImeiNumber("");
    setCoverageError(false);
    setFinalSuccessModal(false);
    setIsFail(false);
    setCoverageMessage("");
    setPurchaseJourney(true);
    setShowCompatibilityModal(true);
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.networkCompatible,
      buttonValue: cacheDataByod
        ? cacheDataByod?.button_1
        : byodContent?.button_1,
    });
  };
  const onShopNewPhonesClick = (url: string) => {
    window.open(url, "_blank");
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.phones,
      buttonValue: cacheDataByod
        ? cacheDataByod?.button_2
        : byodContent?.button_2,
    });
  };
  return (
    <>
      <ByodComponent
        byodContent={cacheDataByod ? cacheDataByod : byodContent}
        onImeiCheckClick={onImeiCheckClick}
        onShopNewPhonesClick={onShopNewPhonesClick}
      />
      {purchaseJourney && (
        <PurchaseFlow
          isBYOD={true}
          coverageModal={coverageModal}
          setCoverageModal={setCoverageModal}
          showCompatibilityModal={showCompatibilityModal}
          setShowCompatibilityModal={setShowCompatibilityModal}
          coverageError={coverageError}
          setCoverageError={setCoverageError}
          coverageMessage={coverageMessage}
          setCoverageMessage={setCoverageMessage}
          finalSuccessModal={finalSuccessModal}
          setFinalSuccessModal={setFinalSuccessModal}
          isFail={isFail}
          setIsFail={setIsFail}
          emailCompatability={emailCompatability}
          setEmailCompatability={setEmailCompatability}
          imeiNumber={imeiNumber}
          setImeiNumber={setImeiNumber}
          homeCompatibilityComp={true}
        />
      )}
    </>
  );
};
