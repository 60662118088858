export function BrandNameUpdate(data: string) {
  let globalVal: any = localStorage.getItem("globalValues");
  globalVal = JSON.parse(globalVal);
  let ReplaceBrandString = data
    ?.replace(/brandNameLong/g, globalVal?.long_name || "")
    ?.replace(/brandNameCapsLong/g, globalVal?.long_capital_name || "")
    ?.replace(/brandNameCapsShort/g, globalVal?.short_capital_name || "")
    ?.replace(/brandNameLowerLong/g, globalVal?.long_name_lowercase || "")
    ?.replace(/brandNameShort/g, globalVal?.short_name || "")
    ?.replace(/brandLegalEmail/g, globalVal?.legal_email || "")
    ?.replace(/brandEmail/g, globalVal?.reachUrl || "")
    ?.replace(/brandContactEmail/g, globalVal?.email || "")
    ?.replace(/brandPhoneNumber/g, globalVal?.phoneNumber || "")
    ?.replace(/brandSupportStartTime/g, globalVal?.supportStartTime || "")
    ?.replace(/brandSupportEndTime/g, globalVal?.supportEndTime || "")
    ?.replace(/brandSupportTimeZone/g, globalVal?.supportTimeZone || "")
    ?.replace(/brandSupportStartDay/g, globalVal?.supportStartDay || "")
    ?.replace(/brandSupportEndDay/g, globalVal?.supportEndDay || "")
    ?.replace(/brandGracePeriodDetails/g, globalVal?.brand_grace_period || "")
    ?.replace(/brandGiveBackPercent/g, globalVal?.brand_giveback_percent || "")
    ?.replace(
      /brandHowGiveBackWorks/g,
      globalVal?.brand_how_giveback_works || ""
    )
    ?.replace(/brandNetworkAdvertising/g, globalVal?.network_advertising || "")
    ?.replace(/brandAboutUsInfo/g, globalVal?.about_ads_info || "")
    ?.replace(/brandAPreferenceManager/g, globalVal?.preference_manager || "")
    ?.replace(/brandHelpCenterLink/g, globalVal?.helpCenter || "")
    ?.replace(/brandBlogLink/g, globalVal?.blogURL || "")
    ?.replace(/brandCareersLink/g, globalVal?.careersUrl || "")
    ?.replace(/brandShopPhonesLink/g, globalVal?.shopPhone || "")
    ?.replace(/brandAssuranceCardLink/g, globalVal?.assuranceCardUrl || "")
    ?.replace(/brandHelpPageLink/g, globalVal?.help_page || "")
    ?.replace(/poweredByBrandLongName/g, globalVal?.powered_by_long_name || "")
    ?.replace(/poweredByBrandShortName/g, globalVal?.powered_by_short_name || "")
    ?.replace(/brandCommunicationMail/g, globalVal?.brand_coms_mail || "");

  return ReplaceBrandString;
}
export const modelMapper: { [key: string]: any } = {
  "A1203": "iphone",
  "A1241": "iPhone 3G",
  "A1324": "iPhone 3G",
  "A1303": "iPhone 3GS",
  "A1332": "iPhone 4",
  "A1349": "iPhone 4",
  "A1387": "iPhone 4S",
  "A1431": "iPhone 4S",
  "A1428": "iPhone 5",
  "A1429": "iPhone 5",
  "A1442": "iPhone 5",
  "A1456": "iPhone 5c",
  "A1532": "iPhone 5c",
  "A1507": "iPhone 5c",
  "A1516": "iPhone 5c",
  "A1526": "iPhone 5c",
  "A1529": "iPhone 5c",
  "A1453": "iPhone 5s",
  "A1533": "iPhone 5s",
  "A1457": "iPhone 5s",
  "A1518": "iPhone 5s",
  "A1528": "iPhone 5s",
  "A1530": "iPhone 5s",
  "A1549": "iPhone 6",
  "A1586": "iPhone 6",
  "A1589": "iPhone 6",
  "A1522": "iPhone 6 Plus",
  "A1524": "iPhone 6 Plus",
  "A1593": "iPhone 6 Plus",
  "A1633": "iPhone 6s",
  "A1688": "iPhone 6s",
  "A1691": "iPhone 6s",
  "A1700": "iPhone 6s",
  "A1634": "iPhone 6s Plus",
  "A1687": "iPhone 6s Plus",
  "A1690": "iPhone 6s Plus",
  "A1699": "iPhone 6s Plus",
  "A1662": "iPhone SE",
  "A1723": "iPhone SE",
  "A1724": "iPhone SE",
  "A1660": "iPhone 7",
  "A1779": "iPhone 7",
  "A1780": "iPhone 7",
  "A1778": "iPhone 7",
  "A1661": "iPhone 7 Plus",
  "A1785": "iPhone 7 Plus",
  "A1786": "iPhone 7 Plus",
  "A1784": "iPhone 7 Plus",
  "A1863": "iPhone 8",
  "A1906": "iPhone 8",
  "A1907": "iPhone 8",
  "A1905": "iPhone 8",
  "A1864": "iPhone 8 Plus",
  "A1898": "iPhone 8 Plus",
  "A1899": "iPhone 8 Plus",
  "A1897": "iPhone 8 Plus",
  "A1865": "iPhone X",
  "A1902": "iPhone X",
  "A1901": "iPhone X"
}

export function getLastCharacter(str: string, n: number) { 
  let newString = str?.substring(str.length - n) 
  return newString || '';
} 
export function ModelNameUpdate(data: string, model: string) {
  let ReplaceModelString = data?.replace(/modelName/g, modelMapper[model]);
  return ReplaceModelString;
}