import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { getIRContent } from "../../../features/strapi/serviceSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
const PAInternationalRoamingComp = isTemplateExisits(
  Config.PostActivationServices.PAInternationalRoaming
)
  ? require(`./${Config.PostActivationServices.PAInternationalRoaming.template}_PAInternationalRoaming`)
      .default
  : null;

type Props = {
  setInternationalService: any;
};
export const PAInternationalRoaming = (props: Props) => {
  const { setInternationalService } = props;
  const dispatch = useAppDispatch();
  const { IRContent } = useAppSelector((state) => state.strapi.services);
  const [cacheDataRoaming, setCacheDataRoaming] = useState<any>(null);

  useEffect(() => {
    getCache("PostActRoaming", getIRContent, setCacheDataRoaming, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("PostActRoaming", IRContent);
  }, [IRContent]);

  return cacheDataRoaming || IRContent ? (
    <PAInternationalRoamingComp
      content={cacheDataRoaming ? cacheDataRoaming : IRContent}
      setInternationalService={setInternationalService}
    />
  ) : null;
};
