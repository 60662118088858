import { Typography } from "@mui/material";
import { ButtonCust, Divider, SimpleModal } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { generateImageContainer } from "../../../utils/commonFunctions";

type Props = {
  content: any;
  openModal: any;
  closeModal: any;
  btnHandler: any;
  openFormModal: any;
  isCheckEligibilityClicked: boolean;
  eligibilityCheckFromPromo?: any;
};
const HSDFailureMatchFoundModal = (props: Props) => {
  const {
    content,
    openModal,
    closeModal,
    btnHandler,
    openFormModal,
    isCheckEligibilityClicked,
    eligibilityCheckFromPromo,
  } = props;
  const { ttl, sub_ttl, btn, icon, acc_info_txt } = content;
  return (
    <SimpleModal
      isShowModal={openModal}
      onCloseModal={closeModal}
      showClose={true}
      sx={{ width: { xs: "90%", sm: "65%", md: "55%", lg: "40%", xl: "35%" } }}
      style={{ padding: "40px" }}
    >
      <Typography
        sx={{
          mb: "10px",
          fontSize: "26px",
          fontFamily: "var(--font_family_Semibold)",
          fontWeight: "var(--h4_font_weight)",
          textAlign: "center",
          lineHeight: "1.2",
        }}
      >
        {ttl}
      </Typography>
      <Divider styles={{ mb: "16px" }} />
      {generateImageContainer({
        data: icon,
        attrs: { height: "30px", width: "30px" },
      })}
      <Typography
        color="var(--grey_shade)"
        fontSize="16px"
        fontFamily="var(--font_family_Medium)"
        textAlign="center"
        fontWeight="var( --h4_font_weight)"
        my="15px"
      >
        {BrandNameUpdate(sub_ttl)}
      </Typography>
      {!eligibilityCheckFromPromo && (
        <ButtonCust
          variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
          sx={{ width: "100%" }}
          onClick={btnHandler}
          loading={isCheckEligibilityClicked}
        >
          {btn}
        </ButtonCust>
      )}
      <Typography
        color="var(--primary_brand_color)"
        fontSize="16px"
        mt="16px"
        fontFamily="var(--font_family_Medium)"
        fontWeight="var( --h4_font_weight)"
        textAlign="center"
        sx={{ cursor: "pointer" }}
        onClick={() => openFormModal()}
      >
        {acc_info_txt}
      </Typography>
    </SimpleModal>
  );
};

export default HSDFailureMatchFoundModal;
