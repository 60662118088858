import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";
import { GetCustomer } from "../checkout/checkoutSlice";
import EnvConfig from "../../config/env.config.json";
type InitialState = {
  imeiData: any;
  errorImeiData: any;
  imeiNumber: string;
  zipCodeStatus: any;
  zipCodeError: any;
  configKeys: any;
  zipcodeNum: string;
  simIdCheckData: any;
  errorSimIdCheckData: any;
  zipCodeLoader: boolean;
  simID: string;
  activateLineStatus: any;
  monthInAdvanceData: any;
  errorMonthInAdvance: any;
  monthInAdvancePostData: any;
  errorMonthInAdvancePostData: any;
  activationStatus: any;
  errorActivationStatus: any;
  activateLineError: any;
  portData: any;
  errorPortData: any;
  portResultData: any;
  errorPortResultData: any;
  displaySaveCardAlertDialog: boolean;
  enablePayForm: boolean;
  showPayForm: boolean;
  showPaySuccessModal: boolean;
};
const initialState: InitialState = {
  imeiData: null,
  errorImeiData: null,
  imeiNumber: "",
  zipCodeStatus: null,
  zipCodeError: null,
  configKeys: null,
  zipcodeNum: "",
  simIdCheckData: null,
  errorSimIdCheckData: null,
  zipCodeLoader: false,
  simID: "",
  activateLineStatus: null,
  monthInAdvanceData: null,
  errorMonthInAdvance: null,
  monthInAdvancePostData: null,
  errorMonthInAdvancePostData: null,
  activationStatus: null,
  errorActivationStatus: null,
  activateLineError: null,
  portData: null,
  errorPortData: null,
  portResultData: null,
  errorPortResultData: null,
  displaySaveCardAlertDialog: false,
  enablePayForm: false,
  showPayForm: false,
  showPaySuccessModal: false,
};

export const getImeiCheck = createAsyncThunk(
  "activation/imeiCheck",
  async (action: any, { rejectWithValue }) => {
    const { payload } = action;

    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.checkCompatibility}/${payload.imei}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
export const zipcodeAvaliability = createAsyncThunk(
  "activation/zipcodeAvaliability",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: EnvConfig?.CHECK_ZIP_CODE_WITH_ID
          ? `${ApiEndPoints.StateCityByZipcode}${action.zipcode}?customerId=${action.customerId}` 
          : `${ApiEndPoints.StateCityByZipcode}${action.zipcode}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getConfigKeys = createAsyncThunk(
  "activation/configKeys",
  async () => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.configKeys}`,
      });
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const SIMIdCheck = createAsyncThunk(
  "activation/SIMIdCheck",
  async (action: any, { rejectWithValue }) => {
    const { payload } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.SIMIdCheck}${payload.customerId}/${payload.iccid}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
export const activateLine = createAsyncThunk(
  "activation/activateLine",
  async (action: any, { rejectWithValue }) => {
    const { payload } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.activateLine}`,
        body: payload,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const monthInAdvance = createAsyncThunk(
  "activation/monthInAdvance",
  async (action: any, { rejectWithValue }) => {
    const { payload } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.monthInAdvance}${payload.customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const monthInAdvancePost = createAsyncThunk(
  "activation/monthInAdvancePost",
  async (action: any, { rejectWithValue }) => {
    const { payload } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.monthInAdvancePost}${payload.customerId}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const activationStatusCheck = createAsyncThunk(
  "activation/statusCheck",

  async (action: any, { dispatch, rejectWithValue }) => {
    const { payload } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.activationStatusCheck}${payload.customerId}`,
      });
      let currentUser: any = localStorage.getItem("currentuser");
      currentUser = JSON.parse(currentUser);
      if (response?.status === 200) {
        dispatch(
          GetCustomer({
            username: encodeURIComponent(currentUser?.email),
          })
        );
        return response;
      }
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const port = createAsyncThunk(
  "activation/port",
  async (action: any, { rejectWithValue }) => {
    const { payload } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.port}${payload.countryCode}/${payload.number}`,
        body: {},
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const portResult = createAsyncThunk(
  "activation/portResult",
  async (action: any, { rejectWithValue }) => {
    const { payload } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.portResult}${payload.data}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const resendEsimKit = createAsyncThunk(
  "activation/resendEsimKit",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.resendEsimKit}${action.customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const activationSlice = createSlice({
  name: "activation",
  initialState,
  reducers: {
    refreshZipcode: (state) => {
      state.zipCodeStatus = null;
    },
    refreshImeiData: (state) => {
      state.imeiData = null;
    },
    clearOldLineData: (state) => {
      state.monthInAdvanceData = null;
    },
    showSaveCardAlertDialog: (state, action) => {
      state.displaySaveCardAlertDialog = action?.payload;
    },
    enablePayFormFn: (state, action) => {
      state.enablePayForm = action?.payload;
    },
    showPayFormFn: (state, action) => {
      state.showPayForm = action?.payload;
    },
    showPaySuccessModalFn: (state, action) => {
      state.showPaySuccessModal = action?.payload;
    },
    clearStepsData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getImeiCheck.pending, (state, action: any) => {
      state.imeiNumber = action?.meta?.arg?.payload?.imei || "";
      state.imeiData = null;
    });
    builder.addCase(
      getImeiCheck.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.imeiData = action.payload?.data?.data || null;
        state.errorImeiData = null;
      }
    );
    builder.addCase(getImeiCheck.rejected, (state, action) => {
      state.imeiData = null;
      state.errorImeiData = action.payload;
    });
    builder.addCase(zipcodeAvaliability.pending, (state, action: any) => {
      state.zipcodeNum = action?.meta?.arg?.zipcode || "";
      state.zipCodeLoader = true;
    });
    builder.addCase(
      zipcodeAvaliability.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.zipCodeStatus = action.payload?.data?.data || null;
        state.zipCodeError = null;
        state.zipCodeLoader = false;
      }
    );
    builder.addCase(zipcodeAvaliability.rejected, (state, action) => {
      state.zipCodeStatus = null;
      state.zipCodeError = action.payload;
      state.zipCodeLoader = false;
    });
    builder.addCase(
      getConfigKeys.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.configKeys = action.payload?.data?.data || null;
      }
    );
    builder.addCase(getConfigKeys.rejected, (state, action) => {
      state.configKeys = action.payload;
    });
    builder.addCase(SIMIdCheck.pending, (state, action: any) => {
      state.simID = action?.meta?.arg?.payload?.iccid || "";
    });

    builder.addCase(
      SIMIdCheck.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.simIdCheckData = action.payload?.data?.data || null;
        state.errorSimIdCheckData = null;
      }
    );
    builder.addCase(SIMIdCheck.rejected, (state, action) => {
      state.simIdCheckData = null;
      state.errorSimIdCheckData = action?.payload || action?.error || action;
    });

    builder.addCase(
      activateLine.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activateLineStatus = action.payload?.data?.data || null;
      }
    );
    builder.addCase(activateLine.rejected, (state, action) => {
      state.activateLineError = action?.payload;
    });
    builder.addCase(
      monthInAdvance.fulfilled,

      (state, action: PayloadAction<any>) => {
        state.monthInAdvanceData = action.payload?.data?.data || null;
        state.errorMonthInAdvance = null;
      }
    );
    builder.addCase(monthInAdvance.rejected, (state, action) => {
      state.monthInAdvanceData = null;
      state.errorMonthInAdvance = action.payload;
    });
    builder.addCase(
      monthInAdvancePost.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.monthInAdvancePostData = action.payload?.data?.data || null;
        state.errorMonthInAdvancePostData = null;
      }
    );
    builder.addCase(monthInAdvancePost.rejected, (state, action) => {
      state.monthInAdvancePostData = null;
      state.errorMonthInAdvancePostData = action.payload;
    });
    builder.addCase(activationStatusCheck.pending, (state) => {
      state.activationStatus = null;
    });
    builder.addCase(
      activationStatusCheck.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activationStatus = action.payload?.data?.data || null;
      }
    );

    builder.addCase(activationStatusCheck.rejected, (state, action) => {
      state.activationStatus = null;
      state.errorActivationStatus = action.payload;
    });
    builder.addCase(port.rejected, (state, action) => {
      state.portData = null;
      state.errorPortData = action.payload;
    });
    builder.addCase(port.pending, (state, action: any) => {
      state.portData = null;
      state.errorPortData = action.payload;
    });
    builder.addCase(portResult.rejected, (state, action) => {
      state.portResultData = null;
      state.errorPortResultData = action.payload;
    });
    builder.addCase(portResult.pending, (state, action: any) => {
      state.portResultData = null;
      state.errorPortResultData = action.payload;
    });
  },
});

export const {
  refreshZipcode,
  clearStepsData,
  refreshImeiData,
  showSaveCardAlertDialog,
  enablePayFormFn,
  showPayFormFn,
  showPaySuccessModalFn,
  clearOldLineData,
} = activationSlice.actions;

export default activationSlice.reducer;
