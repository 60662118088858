import { useEffect, useState } from "react";
import { Box, Slider } from "@mui/material";
import "./SliderCust.scss";
interface obj {
  value: number;
  label: number;
}
export const SliderCust = (props: any) => {
  const { minValue, maxValue, defaultVal, changeHandler, name } = props;
  const [marking, setmarking] = useState<any>([]);
  useEffect(() => {
    let i: number;
    let markings: object[] = [];
    for (i = minValue; i <= maxValue; i++) {
      const eachMark: obj = {
        value: i,
        label: i,
      };
      markings.push(eachMark);
    }
    setmarking(markings);
  }, []);

  return (
    <>
      <Box>
        <Slider
          className={"slider_cust"}
          name={name}
          defaultValue={defaultVal}
          key={`slider-${defaultVal}`}
          min={minValue}
          max={maxValue}
          step={1}
          valueLabelDisplay="on"
          marks={marking}
          onChange={(e) => {
            changeHandler(e);
          }}
        />
      </Box>
    </>
  );
};
