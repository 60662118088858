import Config from "../../../../config/app.config.json";
import { isTemplateExisits } from "../../../../utils/commonFunctions";

const PaymentStatusComp = isTemplateExisits(Config.Activation.PaymentStatus)
  ? require(`./${Config.Activation.PaymentStatus.template}_PaymentStatus`)
      .default
  : null;

export default function ActivationPaymentStatus(props: any) {
  return <PaymentStatusComp {...props} />;
}
