import React, { useEffect, useRef, useState } from "react";
import { LinesTab } from "../../home/PlanCard/LinesTab";
import { Box, Typography } from "@mui/material";
import Styles from "./ULPromoPlan.module.scss";
import { Divider } from "../../widgets";
import {
  addMultipleCacheData,
  getCache,
  sortByKey,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import data from "../../../config/data.json";
import { ULPromoPlanCard } from "./ULPromoPlanCard/ULPromoPlanCard";
import unlimitedPlanDiscount from "../../../config/discountCouponsUnlimited.json";
import normalPlanDiscount from "../../../config/discountCouponsRegular.json";
import getSymbolFromCurrency from "currency-symbol-map";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PlanSecContent } from "../../../features/strapi/landingPageSlice";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { LandingPromo } from "../../../features/checkout/checkoutSlice";

export const ULPromoPlan = () => {
  const [selectedLine, setSelectedLine] = useState(1);
  const { landingPlanContent } = useAppSelector(
    (state: any) => state.strapi.landingPage
  );
  const { landingPromoPlan } = useAppSelector((state: any) => state.checkout);
  const discountDollar = useRef(0);
  const secondaryDiscountDollar = useRef(0);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [cacheLandingPlan, setCacheLandingPlan] = useState<any>(null);
  const [PromoPlanData, setPromoPlanData] = useState<any>(null);
  const [eligibilityCheckFromPromo, setEligibilityCheckFromPromo] =
    useState<any>(false);
  const lineTabChangeHandler = (val: number) => {
    setSelectedLine(val);
    // pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
    //   appName: Config.brand,
    //   screenName: gaScreenNames.home,
    //   category: gaCategory.lineSelection,
    //   buttonValue: val,
    // });
  };
  const { home_page_title } = Styles;
  let plandata =
    data && data.data && data.data.length > 0
      ? sortByKey(data && data.data, "planData")
      : [];

  useEffect(() => {
    if (
      location.pathname.includes(RoutingURLs?.basicLanding) ||
      location.pathname.includes(RoutingURLs?.moderateLanding) ||
      location.pathname.includes(RoutingURLs?.peoplesPlanLanding)
    ) {
      discountDollar.current = normalPlanDiscount.data.discountInDollar;
      secondaryDiscountDollar.current =
        normalPlanDiscount.data.secondaryDiscountInDollar;
    } else if (location.pathname.includes(RoutingURLs?.allInPlanLanding)) {
      discountDollar.current = unlimitedPlanDiscount.data.discountInDollar;
      secondaryDiscountDollar.current =
        unlimitedPlanDiscount.data.secondaryDiscountInDollar;
    }
    dispatch(LandingPromo());
    getCache("LandingPage", PlanSecContent, setCacheLandingPlan, dispatch);
  }, []);

  useNonInitialEffect(() => {
    setPromoPlanData(landingPromoPlan);
  }, [landingPromoPlan]);
  useNonInitialEffect(() => {
    addMultipleCacheData("LandingPage", landingPlanContent);
  }, [landingPlanContent]);

  const selectPlanHandler = (e: any, data: any) => {
    setEligibilityCheckFromPromo(true);
    localStorage.setItem("reachSelectedPlanLine", JSON.stringify(selectedLine));
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify({
        line: selectedLine,
        plan: data,
      })
    );
  };

  let cardData = plandata.filter((item: any) => {
    if (location.pathname.includes(RoutingURLs?.basicLanding)) {
      return item.name === "Basic 1GB";
    } else if (location.pathname.includes(RoutingURLs?.moderateLanding)) {
      return item.name === "Moderate 3GB";
    } else if (location.pathname.includes(RoutingURLs?.peoplesPlanLanding)) {
      return item.name === "People's Choice 8GB";
    } else if (location.pathname.includes(RoutingURLs?.allInPlanLanding)) {
      return item.name === "All-in Unlimited 20GB";
    } else return null;
  });

  let el: any =
    location.pathname === RoutingURLs?.ulPromo
      ? PromoPlanData
      : cardData && cardData.length > 0
      ? cardData[0]
      : null;
  let secondCost: any = el && el.baseLinePrice;
  let discountSecondCost: any = secondCost - discountDollar.current;

  if (
    el &&
    el.addLineSplitPrice &&
    selectedLine > 1 &&
    selectedLine <= el.maxLines &&
    el.baseLinePrice &&
    el.addLineSplitPrice[selectedLine - 1]
  ) {
    secondCost =
      el.baseLinePrice +
      el.addLineSplitPrice[selectedLine - 1] * (selectedLine - 1);
    discountSecondCost =
      secondCost -
      discountDollar.current -
      secondaryDiscountDollar.current * (selectedLine - 1);
  } else if (
    el &&
    el.additionalLinePrice &&
    el.baseLinePrice &&
    selectedLine > 1 &&
    selectedLine <= el.maxLines
  ) {
    secondCost = el.baseLinePrice + el.additionalLinePrice * (selectedLine - 1);
    discountSecondCost =
      secondCost -
      discountDollar.current -
      secondaryDiscountDollar.current * (selectedLine - 1);
  } else if (selectedLine > 1 && selectedLine > el.maxLines) {
    secondCost = "--";
    discountSecondCost = "--";
  }

  const maxLinesArray = plandata.map((el: any) => {
    return el.maxLines;
  });
  const maxLinesCount = Math.max(...maxLinesArray);
  let content: any = cacheLandingPlan ? cacheLandingPlan : landingPlanContent;
  const { title, unl_plan_text, share_plan_text, lines_tab } = content || {};
  return (
    <Box
      id="bestCellPhonePlan"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: { xs: "50px", sm: "70px" },
        mb: { xs: "50px", sm: "50px" },
      }}
    >
      <Typography variant="h3" component={"h3"} className={home_page_title}>
        {title}
      </Typography>
      <Divider styles={{ m: "20px auto 25px" }} />
      {maxLinesCount < 2 ? null : (
        <LinesTab
          value={selectedLine}
          handleChange={lineTabChangeHandler}
          totalCount={maxLinesCount}
          linesTabContent={lines_tab}
        />
      )}
      <ULPromoPlanCard
        content={content}
        currency={getSymbolFromCurrency(el?.currency)}
        disabled={el?.maxLines < selectedLine ? true : false}
        name={el?.displayName?.replace("The", "")}
        planGB={
          el?.isUnlimited
            ? `${el?.planData}${el?.dataUnit}+`
            : `${el?.planData}${el?.dataUnit}`
        }
        buttonHandler={(e: any) => selectPlanHandler(e, el)}
        discountfirstcost={
          discountSecondCost !== "--"
            ? Math.ceil(discountSecondCost / selectedLine)
            : "--"
        }
        cost={secondCost !== "--" ? Math.ceil(secondCost / selectedLine) : "--"}
        discountSecondCost={
          discountSecondCost !== "--" ? discountSecondCost : "--"
        }
        secondCost={secondCost !== "--" ? Math.ceil(secondCost) : "--"}
        noLines={selectedLine}
        planText={el && el.isUnlimited ? unl_plan_text : share_plan_text}
        setEligibilityCheckFromPromo={setEligibilityCheckFromPromo}
        eligibilityCheckFromPromo={eligibilityCheckFromPromo}
      />
    </Box>
  );
};
