import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IRILDServices } from "./../internationalservices/IRILDServices";
import { GetCurrencyFixedToTwo } from "../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { bannerHandler } from "../../features/strapi/serviceSlice";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../config/env.config.json";
import { getUTCDate, useNonInitialEffect } from "../../utils/commonFunctions";
import { parseISO } from "date-fns";
import { dataUsageByDate } from "../../features/services/servicesSlice";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../config/RoutingURLs";
import { getIC, getIR } from "../../features/wallet/walletSlice";
import { CustomerStatus } from "../../enums";

const ServicesComponent = (props: any) => {
  const dispatch = useAppDispatch();
  const { servicesBanner1 } = useAppSelector((state) => state.strapi.services);
  const { callingData, romingData } =
    useAppSelector((state) => state.wallet) || {};
  const { content } = props;
  const [activeButton, setActiveButton] = useState<string>("call");
  const [selectedItem, setSelectedItem] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.internationalServices,
      category: categoryName,
      buttonValue: value,
    });
  };
  useEffect(() => {
    dispatch(getIC());
    dispatch(getIR());
    let customerStoreData: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    let customerId = customerStoreData?.id;
    if (customerStoreData?.status === CustomerStatus?.ACTIVE) {
      const formatedStartDate = getUTCDate(
        parseISO(customerStoreData?.billStartDate)
      );
      const formatedEndDate = getUTCDate(
        parseISO(customerStoreData?.billEndDate)
      );
      let payload = {
        customerId,
        formatedStartDate,
        formatedEndDate,
      };
      dispatch(dataUsageByDate(payload))
        .then((response) => {
          setLoading(false);
          const { ild, ir } = response?.payload?.data?.data?.[0] || {};
          if (ild.remainingCredit > 0 || ir.remainingCredit > 0) {
            navigate(RoutingURLs.wallet);
          }
        })
        .catch((err) => {
          if (err) {
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }
  }, []);
  const convertToString = (arr: any) => {
    let convertedStr;
    if (arr.length > 1) {
      convertedStr = arr
        .slice(0, arr.length - 1)
        .map((item: number) => GetCurrencyFixedToTwo(item));
      return (convertedStr = [
        ...convertedStr,
        ` or ${GetCurrencyFixedToTwo(arr[arr.length - 1])}`,
      ].join(","));
    } else {
      return (convertedStr = arr
        .map((item: number) => GetCurrencyFixedToTwo(item))
        .join(""));
    }
  };
  let callingAddOn = callingData.hasOwnProperty("data")
    ? Object.values(callingData?.data)
    : [];
  let roamingAddOn = romingData.hasOwnProperty("data")
    ? Object.values(romingData?.data)
    : [];
  let callingCredits = convertToString(callingAddOn);
  let roamingCredits = convertToString(roamingAddOn);

  const onClickButton = (btnType: string) => {
    dispatch(bannerHandler(!servicesBanner1));
    GAAndFBEventsHandler(gaCategory.internationalServices, btnType);
    setActiveButton(btnType);
    setSelectedItem(null);
  };
  return (
    <Box margin="0 15px">
      {loading ? (
        <Grid
          width="100%"
          height="500px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Grid>
      ) : (
        <IRILDServices
          onClickButton={onClickButton}
          activeButton={activeButton}
          ISContent={content}
          callingCredits={callingCredits}
          romingCredits={roamingCredits}
        />
      )}
    </Box>
  );
};

export default ServicesComponent;
