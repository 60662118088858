import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DiscoverCard } from "../../../discover/DiscoverCard/DiscoverCard";
import styles from "./S1_PADiscover.module.scss";
import { Box, Grid } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import { RoutingURLs } from "../../../../config/RoutingURLs";
export const T1_PADiscover = (props: any) => {
  const navigate = useNavigate();
  const { content, discoverData } = props;
  const [num, setNum] = useState(5);
  const [discoverList, setDiscoverList] = useState<any>([]);
  const [next, setNext] = useState(0);
  const handlePrevDiscover = () => {
    if (next !== 0) {
      setNext(next - 1);
    }
  };
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.postActivationHome,
      category: category,
      buttonValue: value,
    });
  };
  const discoverButtonHandler = (url: any, item: any) => {
    GAAndFBEventsHandler(gaCategory.discover, item.cta.text);
    window.open(url);
  };
  const handleNextDiscover = () => {
    setNext(next + 1);
  };
  const checkScreenWidth = () => {
    if (window.screen.width <= 600) {
      setNum(1);
    } else if (window.screen.width <= 800) {
      setNum(3);
    } else if (window.screen.width <= 1280) {
      setNum(4);
    } else if (window.screen.width <= 1600) {
      setNum(5);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", checkScreenWidth, false);
  }, []);
  useEffect(() => {
    checkScreenWidth();
  }, [window.screen]);
  useEffect(() => {
    for (let i = 0; i < discoverData.length; i++) {
      let subarr = [];
      for (let j = 0; j < num; j++) {
        subarr.push(discoverData[(i + j) % discoverData.length]);
      }

      if (
        next >= 0 &&
        JSON.stringify(subarr[0]) ===
          JSON.stringify(discoverData[next % discoverData.length])
      ) {
        setDiscoverList(subarr);
      }
    }
  }, [next, discoverData, num]);

  return (
    <Grid
      container
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        p: {
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        },
      }}
    >
      <Box className={styles.Para_Heading1}>{content?.title || "-"}</Box>
      <Box className={styles.post_activation_discover_card}>
        {discoverList?.length && (
          <Box className={styles.prev_next_btn} onClick={handlePrevDiscover}>
            <ChevronLeftOutlinedIcon />
          </Box>
        )}
        {discoverList &&
          discoverList?.map((it: any, index: number) => {
            return (
              <DiscoverCard
                key={index}
                index={index}
                name={it.title}
                image={it.itemImage}
                discoverCardText={it.title}
                value={it.rating ? it.rating : ""}
                buttonText={it.cta && it.cta.text}
                url={it.cta && it.cta.action}
                title={content}
                content={props.content}
                discoverButtonHandler={() =>
                  discoverButtonHandler(it.cta && it.cta.action, it)
                }
              />
            );
          })}
        {discoverList?.length && (
          <Box className={styles.prev_next_btn} onClick={handleNextDiscover}>
            <ChevronRightOutlinedIcon />
          </Box>
        )}
      </Box>
      <Box
        onClick={() => {
          navigate(RoutingURLs.discover);
          GAAndFBEventsHandler(gaCategory.discover, content?.vm || "-");
        }}
        className={styles.view_more_post}
      >
        {content?.vm || "-"}
      </Box>
    </Grid>
  );
};
export default T1_PADiscover;
