import styles from "./S1_BillSavings.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets";
import config from "../../../config/env.config.json";
import { BestFitSavingsProps } from "./BillSavings";
const BillSavingsComponent = (props: BestFitSavingsProps) => {
  const { title, description, button_1, image } = props?.savingContent || {};
  const { getStartedHandler } = props;
  return (
    props?.savingContent && (
      <Grid
        container
        className={styles.billSavings}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0 calc(var(--mobile_section_padding))",
            sm: "calc(var(--ipad_section_padding)/2) 0 calc(var(--ipad_section_padding))",
            md: "calc(var(--desktop_section_padding)/2) 0 calc(var(--desktop_section_padding))",
          },
        }}
      >
        <Grid item={true} md={6}>
          <Box
            component="img"
            alt="feature"
            src={image?.data?.attributes?.url || ""}
            className={styles.savings_image}
            width={"100%"}
          ></Box>
        </Grid>
        <Grid
          item
          md={6}
          className={styles.content_section}
          sx={{
            m: { md: "20px 0", sm: "50px", xs: "20px 0 50px 0" },
            display: { sm: "flex", md: "block" },
            justifyContent: { md: "space-around", xs: "center" },
            alignItems: "center",
            flexDirection: { xs: "column" },
            p: { md: "0px 50px" },
            textAlign: { xs: "center", md: "start" },
          }}
        >
          <Typography
            sx={{ fontFamily: "var(--font_family_Bold) !important" }}
            variant="h3"
          >
            {title || ""}
          </Typography>
          <Grid item xs={12}>
            <Box
              className={styles.best_fit_savings_line}
              sx={{ m: {xs: "20px auto", sm: "20px 0" }}}
            ></Box>
          </Grid>
          <Typography
            className="description_text"
            variant="body1"
            sx={{
              mb: { sm: "25px" },
              fontFamily: "var(--font_family_Medium) !important",
            }}
          >
            {description || ""}
          </Typography>
          {button_1 && (
            <Grid item xs={12} className={styles.btn_section}>
              <Grid
                item
                xs={12}
                sx={{
                  p: { sm: "0 15px", md: "0px", xs: "20px 0px 5px" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <ButtonCust
                  variantType={config.PRIMARY_BUTTON_TYPE}
                  className={styles.btn_cmp}
                  onClick={getStartedHandler}
                >
                  {button_1}
                </ButtonCust>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  );
};
export default BillSavingsComponent;
