import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import SwitchTabs from "../IRILDComponents/SwitchTabs";
import { PostActivationIRILDEnums } from "../../../enums";
import CountryList from "../IRILDComponents/CountryList/CountryList";
import IRPurchaseHistory from "./IRPurchaseHistory/IRPurchaseHistory";
import IRUserList from "./IRUserList/IRUserList";

const T1_PAInternationalRoaming = (props: any) => {
  const { content, setInternationalService } = props;
  const { title, user_data, back_arrow } = content;

  const { group } = useAppSelector((state: any) => state.account);
  const [showPurchaseHistory, setShowPurchaseHistory] = useState(false);
  const [isCountryList, setIsCountryList] = useState<boolean>(false);
  const [fullName, setFullName] = useState(false);
  const [isDataBoostJourney, setIsDataBoostJourney] = useState(false);
  const [rescheduleSuccess, setRescheduleSuccess] = useState(false);

  const onClickSwitchTabs = (type: any) => {
    if (type === PostActivationIRILDEnums.tab_left) {
      setShowPurchaseHistory(false);
    } else {
      setShowPurchaseHistory(true);
    }
  };

  const countryListHandler = (data: any, name: any) => {
    localStorage.setItem("SingleLineData", JSON.stringify(data));
    setIsCountryList(true);
    setFullName(name);
  };

  return (
    <>
      {!isCountryList ? (
        <Box
          sx={{
            margin: { xs: "auto", sm: "auto", md: "auto" },
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "space-between",
            width: { xs: "auto", sm: "auto", md: "60%" },
          }}
        >
          {!isDataBoostJourney && !rescheduleSuccess ? (
            <>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "30px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& span": {
                    fontFamily: "var(--font_family_Medium) !important",
                    fontWeight: "var(--font_weight_5)",
                  },
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    gap: "8px",
                    left: "103px",
                    cursor: "pointer",
                    fontWeight: "var(--font_weight_3)",
                  }}
                  onClick={() => setInternationalService("")}
                >
                  <img
                    src={back_arrow?.data.attributes.url}
                    alt={back_arrow?.data.attributes.alternativeText}
                    width={17.495}
                    height={14.586}
                  />
                  {user_data?.back}
                </span>
                <span style={{ margin: "auto" }}>{title}</span>
              </Typography>
              <SwitchTabs
                title_left={user_data?.lines}
                title_right={user_data?.pur_history1}
                onClickSwitchTabs={onClickSwitchTabs}
                showPurchaseHistory={showPurchaseHistory}
              />{" "}
            </>
          ) : null}

          {showPurchaseHistory ? (
            <IRPurchaseHistory
              isDataBoostJourney={isDataBoostJourney}
              setIsDataBoostJourney={setIsDataBoostJourney}
              onClickSwitchTabs={onClickSwitchTabs}
              rescheduleSuccess={rescheduleSuccess}
              setRescheduleSuccess={setRescheduleSuccess}
            />
          ) : (
            <IRUserList
              content={content}
              group={group}
              countryListHandler={countryListHandler}
            />
          )}
        </Box>
      ) : (
        <CountryList
          content={content}
          fullName={fullName}
          setIsCountryList={setIsCountryList}
          onClickSwitchTabs={onClickSwitchTabs}
        />
      )}
    </>
  );
};

export default T1_PAInternationalRoaming;
