import clsx from "clsx";
import styles from "./MultipleTopUpComp.module.scss";
import { Box, CircularProgress } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useAppSelector } from "../../../../app/hooks";

type MultipleTopUpCompProps = {
  setDataPackPlan: any;
  topUpPulse: any;
  dataPackPlan: any;
  data_unit: string;
};
export const MultipleTopUpComp = (props: MultipleTopUpCompProps) => {
  const { topUpPulseLoader } = useAppSelector((state: any) => state.account);
  const { topUpPulse, setDataPackPlan, dataPackPlan, data_unit } = props;
  const { account_buy_add, selected } = styles;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
      {topUpPulseLoader ? (
        <CircularProgress sx={{ color: "var(--primary_color)", mb: "10px" }} />
      ) : (
        Array.isArray(topUpPulse) &&
        topUpPulse.length > 0 &&
        topUpPulse?.map((pulseItem: any, idx: any) => {
          return (
            <Box
              key={idx}
              sx={{ cursor: "pointer" }}
              className={clsx(
                account_buy_add,
                dataPackPlan?.pulse === pulseItem?.pulse ? selected : ""
              )}
              onClick={() => {
                setDataPackPlan(pulseItem);
              }}
            >
              {pulseItem && pulseItem?.pulse}
              {data_unit} |{" "}
              {GetCurrencyFixedToTwo(pulseItem && pulseItem?.pulseCost)}
            </Box>
          );
        })
      )}
    </Box>
  );
};
