import Config from "../../config/app.config.json";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { fetchDeviceCheckContent } from "../../features/strapi/deviceConfirmSlice";
import { addMultipleCacheData, useNonInitialEffect } from "../../utils/commonFunctions";

export type DeviceCheckProps = {
  content?: any;
};
const DeviceCheckTemplate = isTemplateExisits(Config.DeviceCheckConfirm)
  ? require(`./${Config.DeviceCheckConfirm.template}_DeviceCheckConfirm`).default
  : null;

export const DeviceCheckConfirmComponent = () => {
    const { deviceConfirmContent } = useAppSelector((state: any) => state.strapi.deviceConfirmCheck);
    const [cacheDeviceConfimCheck, setCacheDeviceConfimCheck] = useState<any>(null);
    const dispatch = useAppDispatch();
    useEffect(() => {
      getCache("DeviceConfirmCheck", fetchDeviceCheckContent, setCacheDeviceConfimCheck, dispatch)
    }, []);
    useNonInitialEffect(() => {
      addMultipleCacheData('DeviceConfirmCheck', deviceConfirmContent)
    }, [deviceConfirmContent])

    return (
        (cacheDeviceConfimCheck || deviceConfirmContent) && (
            <DeviceCheckTemplate content={cacheDeviceConfimCheck ? cacheDeviceConfimCheck : deviceConfirmContent} />
        )
    );
};
