import { useEffect, useState } from "react";
import { strapiFooterContent } from "../../../features/strapi/componentSlice";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { gaEventTypes, gaScreenNames } from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { useNavigate } from "react-router-dom";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";

const FooterComponent = isTemplateExisits(Config.Footer)
  ? require(`./${Config.Footer.template}_Footer`).default
  : null;
export const Footer = () => {
  const dispatch: any = useAppDispatch();
  const [isPostActive, setPostActive] = useState<boolean>(false);
  const [cacheFooter, setCacheFooter] = useState<any>(null);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const navigate = useNavigate();
  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);
  const { footerContent = {} } = useAppSelector(
    (state) => state?.strapi?.commonPage
  );
  const { assuranceCardUrl = "", footerText } = footerContent || {};
  useEffect(() => {
    if (
      customerDetail &&
      customerDetail?.simPaid &&
      customerDetail?.status === 2
    ) {
      setPostActive(true);
    }
  }, [customerDetail]);
  useEffect(() => {
    getCache("Footer", strapiFooterContent, setCacheFooter, dispatch);
  }, []);

  useEffect(() => {
    EnvConfig?.HIDE_FOOTER_ROUTES.map((route: any) => {
      if (route === window.location.pathname) {
        setShowFooter(false);
      } else {
        setShowFooter(true);
      }
    });
  }, [window.location.pathname]);

  useNonInitialEffect(() => {
    addMultipleCacheData("Footer", footerContent);
  }, [footerContent]);

  const handleAssuranceCard = () => {
    let assuranceCardURL: any = assuranceCardUrl;
    window.open(assuranceCardURL, "assurance_card", "height=450,width=450");
  };
  const GAAndFBEventsHandler = (category: string, actionType: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: category,
      buttonValue: actionType,
    });
  };
  const onClickHandler = (
    navigationPath: string,
    redirectionPage: string,
    gaName: string,
    is_gaEventReqd: boolean
  ) => {
    if (redirectionPage === "_self") {
      navigate(navigationPath);
    } else {
      window.open(navigationPath, redirectionPage);
    }
    GAAndFBEventsHandler(footerText, gaName);
    is_gaEventReqd &&
      gtmTagManager({
        event: gaName,
        click_section: footerText,
      });
  };
  if ((cacheFooter || footerContent) && FooterComponent && showFooter) {
    return (
      <FooterComponent
        isPostActive={isPostActive}
        handleAssuranceCard={handleAssuranceCard}
        footerContent={cacheFooter ? cacheFooter : footerContent}
        GAAndFBEventsHandler={GAAndFBEventsHandler}
        onClickHandler={onClickHandler}
        customerDetail={customerDetail}
      />
    );
  } else {
    return null;
  }
};
