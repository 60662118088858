import { Box, Grid, Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  generateImageContainer,
  getResolutionDetails,
} from "../../../utils/commonFunctions";
import styles from "./S2_Footer.module.scss";
import applicationData from "../../../../package.json";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { scroller } from "react-scroll";

type Props = {
  isFixed?: boolean;
  customerDetail: any;
  footerContent: any;
  onClickHandler: any;
};

const FooterComponent = (props: Props) => {
  const location = useLocation();
  const { customerDetail, footerContent, onClickHandler } = props;
  const {
    footer_t2,
    links_grid,
    links,
    sub_links,
    disclaimer_text,
    copyright_text,
    version_indicator,
    new_tag,
    link_item,
  } = styles;

  const {
    footerListDetails = [],
    copyright,
    disclaimer,
    logo_desktop,
    logo_tab,
    logo_mobile,
    showDisclaimer,
    newText,
    showVersion,
  } = footerContent || {};

  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  const footerList = (val: any) => {
    const returnSec = (
      <Box
        className={sub_links}
        mt={{ xs: "10px", lg: "0px" }}
        key={val?.keyProp}
        role="button"
      >
        {val.name}
        {val.isNew && (
          <span className={`${new_tag} px-1 mx-1 rounded-1`}>{newText}</span>
        )}
      </Box>
    );
    const resultSet = (
      <>
        {val.isLink ? (
          <Link
            onClick={() =>
              onClickHandler(
                BrandNameUpdate(val.navigationPath),
                val.redirectionPage,
                val.gaName,
                val.is_gaEventReqd
              )
            }
            underline="none"
          >
            {returnSec}
          </Link>
        ) : (
          <Link
            onClick={() => {
              if (val?.keyProp === 1) {
                onClickHandler(
                  BrandNameUpdate(val.navigationPath),
                  val?.redirectionPage,
                  val?.gaName,
                  val?.is_gaEventReqd
                );
                setTimeout(function () {
                  scroller.scrollTo("bestCellPhonePlan", {
                    duration: 1000,
                    delay: 0,
                    offset: -100,
                    smooth: true,
                  });
                }, 1000);
              } else {
                onClickHandler(
                  BrandNameUpdate(val.navigationPath),
                  val.redirectionPage,
                  val.gaName,
                  val?.is_gaEventReqd
                );
              }
            }}
            rel="noreferrer"
            underline="none"
          >
            {returnSec}
          </Link>
        )}
      </>
    );
    return (
      <>
        <div className={link_item} key={val?.keyProp}>
          {resultSet}
        </div>
      </>
    );
  };
  const currentYear = new Date().getFullYear();

  return location.pathname === RoutingURLs?.checkout ? (
    <></>
  ) : (
    <Grid
      container
      display={{ xs: "flex" }}
      flexDirection={{ xs: "column" }}
      position="static"
      className={footer_t2}
      minHeight={{ xs: "356px", sm: "270px" }}
    >
      <Grid
        container
        item
        p={{ xs: "30px", sm: "50px 25px 25px 25px", md: "50px 25px 10px 50px" }}
        className={links_grid}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Grid
          item
          xs={12}
          sm={4.5}
          lg={2.5}
          display="flex"
          alignItems={{ xs: "center", md: "flex-end" }}
          justifyContent="flex-start"
          mb={{ xs: "23px", sm: "0px" }}
          className="footer_logo_alignment"
        >
          {(largeDesktop || desktop) &&
            generateImageContainer({
              data: logo_desktop,
              attrs: {
                height: "45px",
                width: "186px",
              },
            })}
          {ipad &&
            generateImageContainer({
              data: logo_tab,
              attrs: {
                height: "35px",
                width: "132px",
              },
            })}
          {mobile &&
            generateImageContainer({
              data: logo_mobile,
              attrs: {
                height: "35px",
                width: "132px",
              },
            })}
        </Grid>
        <Grid
          item
          xs={12}
          sm={7.5}
          lg={9.5}
          pr={{ xs: "0", lg: "15%" }}
          className={links}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={{
            xs: "space-between",
            sm: "flex-start",
            md: "space-between",
          }}
          flexWrap="wrap"
        >
          {footerListDetails?.map((val: any, index: number) => {
            if (index === 0) {
              return customerDetail?.simPaid ? null : footerList(val);
            } else return footerList(val);
          })}
        </Grid>
      </Grid>
      <Grid item className={copyright_text} mb="40px">
        {BrandNameUpdate(copyright)?.replace("{year}", String(currentYear))}
      </Grid>
      {showDisclaimer && (
        <Grid
          item
          className={disclaimer_text}
          p={{ xs: "0 25px 40px 25px", md: "0 25px 40px 50px" }}
        >
          {BrandNameUpdate(disclaimer)}
        </Grid>
      )}
      {showVersion && (
        <Grid
          item
          className={version_indicator}
        >{`v ${applicationData?.version}`}</Grid>
      )}
    </Grid>
  );
};

export default FooterComponent;
