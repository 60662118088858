import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import styles from "./WalletCard.module.scss";
import { useAppSelector } from "../../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

const WalletCard = (props: any) => {
  const { lineDataForWallet: data } = props;

  const { getILDCreditsData } = useAppSelector(
    (state: any) => state?.services || {}
  );

  const {
    user_list_container,
    sim_type,
    is_primary,
    user_name,
    user_num,
    view_history_cta,
  } = styles;

  const { isPrimary, wallet_bal } = props.content;

  const fullName = data?.firstName + " " + data?.lastName;

  return (
    <Box className={user_list_container}>
      <Box
        p={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography className={user_name} pb={1}>
            {fullName}
            {data?.isPrimary && (
              <span className={is_primary}>({isPrimary})</span>
            )}{" "}
          </Typography>
          <Typography className={user_num}>
            {data?.reachNumber ? (
              <span>
                {`(${data?.reachNumber.slice(0, 3)}) ${data?.reachNumber.slice(
                  3,
                  6
                )}-${data.reachNumber.slice(6)} `}
              </span>
            ) : data?.mnpInfo?.oldNumber ? (
              <span>
                {`(${data?.mnpInfo?.oldNumber.slice(
                  0,
                  3
                )}) ${data?.mnpInfo?.oldNumber.slice(
                  3,
                  6
                )}-${data?.mnpInfo?.oldNumber.slice(6)} `}
              </span>
            ) : null}
            <span className={sim_type}>{data?.esim ? "esim" : "sim"}</span>
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "var(--font_weight_5)" }}
          >
            <span style={{ color: "var(--primary_color)" }}>
              {wallet_bal}:{" "}
            </span>
            <span>
              {GetCurrencyFixedToTwo(
                (getILDCreditsData &&
                  getILDCreditsData[data.id]?.availableCredit) ||
                  0
              )}
              /
              {GetCurrencyFixedToTwo(
                (getILDCreditsData &&
                  getILDCreditsData[data.id]?.purchaseCredit) ||
                  0
              )}
            </span>{" "}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={2} sx={{ textAlign: "center" }}>
        {props.children}
      </Box>
    </Box>
  );
};

export default WalletCard;
