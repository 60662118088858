import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";

type MenuProps = {
  statuslist: any;
  handleMenuItemClick: any;
  handleClose: any;
  menuAnchorEl: any;
  handleClickListItem: any;
  selectedIndex: number;
  menuOpen: boolean;
  selectedIcon: any;
  uparrowIcon: any;
  downarrowIcon: any;
};

export default function DropDownMenu(props: MenuProps) {
  return (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: "background.paper" }}
      >
        <ListItem
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={props.menuOpen ? "true" : undefined}
          onClick={props.handleClickListItem}
        >
          <ListItemText
            secondary={props.statuslist[props.selectedIndex]?.name}
            sx={{
              color: "var(--primary_color)",
              "& .MuiListItemText-secondary": {
                color: "var(--secondary_color)",
              },
            }}
          />
          <Box
            component="img"
            src={
              props.menuOpen
                ? props.uparrowIcon?.data?.attributes?.url
                : props.downarrowIcon?.data?.attributes?.url
            }
            sx={{
              margin: "10px",
            }}
          ></Box>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={props.menuAnchorEl}
        open={props.menuOpen}
        onClose={props.handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "var(--black)",
            color: "var(--white)",
          },
        }}
      >
        {props.statuslist.map((option: any, index: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
            }}
            key={option.name + index}
          >
            <MenuItem
              key={option.name + index}
              selected={index === props.selectedIndex}
              onClick={(event) =>
                props.handleMenuItemClick(event, index, option)
              }
            >
              <ListItemIcon
                sx={{
                  "& .MuiListItemIcon-root": {
                    minWidth: "20px",
                  },
                }}
              >
                <Box
                  component="img"
                  src={
                    index === props.selectedIndex
                      ? props.selectedIcon?.data?.attributes?.url
                      : null
                  }
                ></Box>
              </ListItemIcon>
              {option.name}
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </div>
  );
}
