import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { ApnSettingsModal } from "./Apn_Settings_Modal";
import { ButtonCust, SimpleModal } from "../widgets";
import ApnNote from "./Apn_Note";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RoutingURLs } from "../../config/RoutingURLs";
import CloseIcon from "@mui/icons-material/Close";
import { updateCheckOutIMEIState } from "../../features/checkout/checkoutSlice";
const T1_Apn_Note = ({
  imeiData,
  content,
  isShowAsPill,
  currentLine,
  index,
  closeCompatModal,
  closeCompImeiModal
}: {
  imeiData?: any;
  content: any;
  isShowAsPill?: boolean;
  currentLine?: any;
  index?: number;
  closeCompatModal?: any;
  closeCompImeiModal?: any;
}) => {
  const { configKeys } = useAppSelector((state: any) => state?.activation);

  const [isShowModal, setIsShowModal] = useState(false);
  const { apnRes, apnImages } = content;
  const [showNote, setShowNote] = useState<boolean>(false);

  let user1: any = localStorage.getItem("currentuser");
  let customer: any = localStorage.getItem("customerDetail");
  user1 = JSON.parse(user1);
  customer = JSON.parse(customer);
  let checkoutPage = (location.pathname === RoutingURLs?.myAccount)|| (location.pathname === RoutingURLs?.account) || (location.pathname === RoutingURLs?.postActivationHome);
  let imeiDataFinal =
    imeiData?.os && imeiData?.osVer
      ? { os: imeiData?.os, osVer: imeiData?.osVer }
      : { os: currentLine?.os, osVer: currentLine?.osVer };
  const readMoreUrl = configKeys?.APN_READ_MORE_LINK ?? "";
  const message =
    imeiDataFinal && imeiDataFinal.os === "ios" && imeiDataFinal.osVer < 17
      ? apnRes?.noteBodyIos17LowerModel
      : imeiDataFinal &&
        imeiDataFinal.os === "ios" &&
        imeiDataFinal.osVer === 17
      ? apnRes?.noteBodyIos17
      : checkoutPage
      ? apnRes?.noteAndroidPreActivation
      : apnRes?.noteBodyAndroid;
  const isAndroid =
    imeiDataFinal && imeiDataFinal.os && imeiDataFinal.os === "android";
  const isAndroidOrIos17 =
    isAndroid ||
    (imeiDataFinal &&
      imeiDataFinal.os &&
      imeiDataFinal.os === "ios" &&
      imeiDataFinal.osVer === 17);
  const dispatch = useAppDispatch();
  const closeModal = (index: any) => {
    dispatch(
      updateCheckOutIMEIState({
        type: "isShowModal",
        value: false,
        index,
      })
    );
  };
  let isCloseIconPreActivation = typeof index === "number" && index >= 0;

  return (
    <>
      {!isShowAsPill ? (
        imeiData?.isShowModal || !showNote ? (
          <Box
            sx={{
              background: "var(--apn_background_color)",
              padding: "8px 8px 8px 9px",
              margin: "0",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              fontStyle: "normal",
              lineHeight: "17px",
              fontStretch: "normal",
              letterSpacing: "normal",
            }}
          >
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "start",
              }}
            >
              <img
                src={apnImages?.imp_note_img?.data?.attributes?.url}
                height={30}
                width={30}
                style={{ marginTop: "8px" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "8px 8px 4px 8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: { xs: "16px", sm: "14px" },
                    lineHeight: "21px",
                    color: "var(--black)",
                    fontWeight: "var(--font_weight_2)",
                    textAlign: "left"
                  }}
                >
                  {apnRes?.noteHeading}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "12px" },
                    color: "var(--black)",
                    marginTop: "4px",
                    lineHeight: "22px",
                    fontFamily: "var(--font_family_Regular)",
                    fontWeight: "var(--font_weight_0)",
                    textAlign: "left"
                  }}
                >
                  {message}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "16px",
                    color: "var(--primary_color)",
                    margin: "14px 0px 1px",
                    letterSpacing: "0px",
                    cursor: "pointer",
                    lineHeight: isAndroidOrIos17
                      ? "18px"
                      : !isShowAsPill
                      ? "18px"
                      : "21px",
                    fontWeight: "var(--font_weight_2)",
                    textAlign: "left"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      (!isAndroidOrIos17 || isAndroid) &&
                      apnRes?.andriodViewGuidelines
                    ) {
                      setIsShowModal(true);
                       closeCompatModal?.();
                    } else {
                      window.open(readMoreUrl, "_blank");
                    }
                  }}
                >
                  {isAndroidOrIos17 && !isAndroid
                    ? apnRes?.apn_activation_cta_text
                    : !apnRes?.andriodViewGuidelines
                    ? apnRes?.apn_activation_cta_text
                    : apnRes?.view_guidelines_cta}
                </Typography>
              </Box>
              <Box>
                {isCloseIconPreActivation && (
                  <CloseIcon
                    sx={{
                      width: "15px",
                      height: "15px",
                      m: "8px 2px 0px 0px",
                      cursor: "pointer",
                      color: "var(--black)",
                    }}
                    onClick={() => closeModal(index)}
                  />
                )}
              </Box>
            </Box>

            {isShowModal ? (
              <ApnSettingsModal
                isShowModal={isShowModal}
                onCloseModal={(e: any) => {
                  e.stopPropagation();
                  setIsShowModal(false);
                  closeCompImeiModal?.()
                }}
                isAndroid={isAndroid}
                currentLine={currentLine}
              />
            ) : null}
          </Box>
        ) : null
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            margin: "6px 0px 8px",
          }}
        >
          <ButtonCust
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              setShowNote(true);
            }}
            startIcon={
              <img src={apnImages?.imp_note_dark_img?.data?.attributes?.url} />
            }
            variantType="apn_note_rounded"
            sx={{
              height: "2.3125em !important",
              minWidth: "160px !important",
              padding: "8px 16px !important",
              fontWeight: "var(--font_weight_2) !important",
              backgroundColor: "var(--apn_background_color) !important",
              "& .MuiButton-startIcon": {
                marginRight: "6px !important",
              },
              color: "var(--apn_text_color) !important",
              border: "0.0625rem solid var(--apn_text_color) !important"
            }}
          >
            {apnRes?.apn_activation_text}
          </ButtonCust>
        </Box>
      )}

      {showNote && (
        <SimpleModal
          isShowModal={showNote}
          styleIcon={{ top: "-14px", right: "0", position: "absolute", marginTop: "15px" }}
          icon={apnImages?.apn_activation_close_modal_img.data.attributes.url}
          isRight={true}
          onCloseModal={(e: any) => {
            e.stopPropagation();
            setShowNote(false);
          }}
          showClose={true}
          sx={{
            width: {
              xs: "95%",
              sm: "70%",
              md: "80%",
              lg: "30%",
              xl: "32%",
            },
            top: {xs: "35%", md: "35% !important"} ,
            maxWidth: "480px",
          }}
          style={{ borderRadius: "4px", padding: "8px" }}
        >
          <ApnNote
            imeiData={imeiData}
            isShowAsPill={false}
            currentLine={currentLine}
          />
        </SimpleModal>
      )}
    </>
  );
};
export default T1_Apn_Note;
