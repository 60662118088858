import styles from "./S1_HowItWorks.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { hiwProps } from "./HowItWorks";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import { Divider } from "../../widgets";
export const T1_HowItWorks = (props: hiwProps) => {
  const { title, image_web, image_ipad, image_mobile } = props.content || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  return (
    <Grid
      container
      className={styles.how_it_works}
      sx={{
        p: {
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0 0",
        },
      }}
    >
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">{title || ""}</Typography>
      </Grid>
      <Divider styles={{mt: "20px"}}/>
      <Grid item xs={12}>
        {(largeDesktop || desktop) &&
          generateImageContainer({
            data: image_web,
            attrs: { height: "auto", width: "100%" },
          })}
        {ipad &&
          generateImageContainer({
            data: image_ipad,
            attrs: { height: "auto", width: "100%" },
          })}
        {mobile &&
          generateImageContainer({
            data: image_mobile,
            attrs: { height: "auto", width: "100%" },
          })}
      </Grid>
    </Grid>
  );
};

export default T1_HowItWorks;
