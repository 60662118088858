import Config from "../../config/app.config.json";
import { isTemplateExisits } from "../../utils/commonFunctions";

const DisconnectedComp = isTemplateExisits(
  Config.PostActivationHome.Disconnected
)
  ? require(`./${Config.PostActivationHome.Disconnected.template}_Disconnected`)
      .default
  : null;

export type disconnectedProps = {
  content: any;
};

export const Disconnected = (props: disconnectedProps) => {
  return DisconnectedComp ? (
    <DisconnectedComp content={props?.content} />
  ) : (
    <></>
  );
};
