import Config from "../../../../config/app.config.json";
import { isTemplateExisits } from "../../../../utils/commonFunctions";

type Props = {};
const MnpComp = isTemplateExisits(Config.Activation.ActivaionIsMnp)
  ? require(`./${Config.Activation.ActivaionIsMnp.template}_ActivationIsMnp`)
      .default
  : null;

export default function ActivationIsMnp(props: Props) {
  return <MnpComp {...props} />;
}
