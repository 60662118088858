import { useEffect, useState } from "react";
import Config from "../../../../config/app.config.json";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getReferralCredits } from "../../../../features/account/accountSlice";
import {
  pendingCreditContent,
  referralCreditsContent,
  totalCreditsContent,
} from "../../../../features/strapi/postActivationAccountSlice";
import { SkeletonComp } from "./ReferralCards/SkeletonComp";
import { ReferralCreditsHeader } from "./ReferralCards/ReferralCreditsHeader";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";

const ViewCreditsComponent = isTemplateExisits(Config.Account.InviteAddCredits)
  ? require(`./${Config.Account.InviteAddCredits.template}_ViewCredits`).default
  : null;
export type ViewCreditsComponentProps = {
  content?: any;
  setCurrentSection: Function;
};

export const ViewCredits = ({
  setCurrentSection,
}: ViewCreditsComponentProps) => {
  const [totalCreditsCardContent, setTotalCreditsCardContent] =
    useState<any>(null);
  const [referralCreditsCardsContent, setReferralCreditsCardsContent] =
    useState<any>(null);
  const [pendingCreditCardsContent, setPendingCreditCardsContent] =
    useState<any>(null);

  const { totalCreditsCard, referralCreditsCards, pendingCreditCard } =
    useAppSelector((state: any) => state.strapi.postActivationAccount);
  const { referralCredits } = useAppSelector((state: any) => state.account);

  const dispatch = useAppDispatch();

  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);

  useEffect(() => {
    getCache('CreditsTotaAccount', totalCreditsContent, setTotalCreditsCardContent, dispatch)
    getCache('CreditsReferralAccount', referralCreditsContent, setReferralCreditsCardsContent, dispatch)
    getCache('CreditsPendingAccount', pendingCreditContent, setPendingCreditCardsContent, dispatch)
    dispatch(
      getReferralCredits({
        groupId: currentdetails?.groupId,
      })
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData('CreditsTotaAccount', totalCreditsCard)
  }, [totalCreditsCard]);
  useNonInitialEffect(() => {
    addMultipleCacheData('CreditsReferralAccount', referralCreditsCards)
  }, [referralCreditsCards]);
  useNonInitialEffect(() => {
    addMultipleCacheData('CreditsPendingAccount', pendingCreditCard)
  }, [pendingCreditCard]);

  return (totalCreditsCardContent || totalCreditsCard) &&
    (referralCreditsCardsContent || referralCreditsCards) &&
    (pendingCreditCardsContent || pendingCreditCard) &&
    referralCredits?.status ? (
    <ViewCreditsComponent
      setCurrentSection={setCurrentSection}
      referralCredits={referralCredits}
      totalCreditsCardContent={totalCreditsCardContent? totalCreditsCardContent: totalCreditsCard}
      referralCreditsCardsContent={referralCreditsCardsContent? referralCreditsCardsContent: referralCreditsCards}
      pendingCreditCardsContent={pendingCreditCardsContent? pendingCreditCardsContent: pendingCreditCard}
    />
  ) : (
    <>
      <ReferralCreditsHeader content={totalCreditsCardContent? totalCreditsCardContent: totalCreditsCard} />
      <SkeletonComp />
    </>
  );
};
