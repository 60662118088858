import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  loginContent: any;
  errorLoginContent: any;
};

const initialState: InitialState = {
  loginContent: null,
  errorLoginContent: null,
};

export const strapiLoginContent = createAsyncThunk(
  "signin/signinContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Login.Login.template}${StrapiApiEndPoints.login}`,
      isStrapiApi: true,
    });
  }
);

const signinSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      strapiLoginContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.loginContent = action.payload?.data?.data?.attributes;
        state.errorLoginContent = null;
      }
    );
    builder.addCase(
      strapiLoginContent.rejected,
      (state: InitialState, action: any) => {
        state.loginContent = null;
        state.errorLoginContent = action.error || "Something went wrong";
      }
    );
  },
});

export default signinSlice.reducer;
