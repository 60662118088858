import { isTemplateExisits } from "../../../../utils/commonFunctions";
import Config from "../../../../config/app.config.json";
import firebase from "../../../../adapters/firebase";
import { useEffect, useState } from "react";
import { NotificationCust } from "../../../widgets";
import { string, object } from "yup";
import { useFormik } from "formik";
import {
  getConfigKeys,
  port,
  portResult,
  refreshZipcode,
  zipcodeAvaliability,
} from "../../../../features/activation/activationSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import envConfig from "../../../../config/env.config.json";
import { HelpSupportModal } from "./SupportModal";
import FirebaseDBURLs from "../../../../config/FirebaseDBURLs";

interface formikintitaltypes {
  phoneNumber: string;
  carrier: string;
  zip: string;
  accNumber: string;
  password: string;
}
interface zipCodeformikintitaltypes {
  zipCode: string;
}
const ActivationStep2Comp = isTemplateExisits(Config.Activation.ActivationStep2)
  ? require(`./${Config.Activation.ActivationStep2.template}_ActivationStep2`)
      .default
  : null;
export const ActivationStep2 = (props: any) => {
  const dispatch = useAppDispatch();
  const {
    currentLine,
    gaHandler,
    content,
    handleStep,
    updatingCustomer,
    numChangeHandler,
  } = props;
  const [operators, setOperators] = useState<any>([]);
  const [currentOperator, setCurrentOperator] = useState<any>({});
  const [btnLoader, setBtnLoader] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [currentNum, setCurrentNum] = useState(false);

  const { zipCodeStatus } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { isValid } = zipCodeStatus || {};
  const {
    oldNumber,
    operator: mnpOperator,
    oldZip,
    acctNum,
    oldPassword,
  } = currentLine?.mnpInfo || {};
  const initialCarrierInfo: formikintitaltypes = {
    phoneNumber: oldNumber || "",
    carrier: mnpOperator || "",
    zip: oldZip || "",
    accNumber: acctNum || "",
    password: oldPassword || "",
  };

  const { msgs, c_sup_title } = content;
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const carrierList = await firebase.database().ref(FirebaseDBURLs.operators);
    carrierList.on("value", (snapshot: any) => {
      const data = snapshot.val();
      if (data) {
        let data1: any = [];
        data.map((el: any) => {
          return data1.push({
            ...el,
            value: el.displayName,
            label: el.displayName,
          });
        });
        data1.push({
          value: "current",
          label: "Others",
        });
        setOperators(data1);
        if (currentLine?.mnpInfo?.operator) {
          data1?.forEach((operator: any) => {
            if (currentLine?.mnpInfo?.operator === operator.value) {
              setCurrentOperator(operator);
            }
          });
        }
      }
    });
  };
  const getNotification = (id: string, msg: string) => {
    return NotificationCust({
      message: msg,
      type: "danger",
      duration: 5000,
      id: id,
      showIcon: true,
    });
  };
  const carrierSchema = object({
    phoneNumber: string()
      .trim()
      .required(msgs?.ph_empty)
      .min(10, msgs?.ph_valid),
    carrier: string().trim().required(msgs?.op_msg),
    zip: string()
      .trim()
      .required(msgs?.zip_empty)
      .test(
        "len",
        msgs?.zip_valid,
        (val) => val?.length === 5 && parseInt(val) > 0
      ),
    accNumber: string().trim().required(msgs?.acc_empty),
    password: string().trim().required(msgs?.pin_empty),
  });
  //port number form
  const formik = useFormik({
    initialValues: initialCarrierInfo,
    validationSchema: carrierSchema,
    onSubmit: (values: any) => {
      setBtnLoader(true);
      const py: any = {
        countryCode: envConfig.COUNTRY_CODE,
        number: values.phoneNumber,
        isPrimary: currentLine?.isPrimary,
      };

      gaHandler("Current Carrier Information");
      dispatch(port({ payload: py })).then((res) => {
        if (res?.payload?.status === 200) {
          const payload: any = {
            data: res?.payload?.data?.data,
          };
          dispatch(portResult({ payload: payload })).then((res) => {
            setBtnLoader(false);
            if (res?.payload?.status === 200) {
              handleStep(true);
              updatingCustomer({
                mnpInfo: {
                  oldNumber: values.phoneNumber,
                  operator:
                    values.carrier === "current" &&
                    envConfig.SHOW_ACT_OTHER_CARRIER
                      ? values.otherCarrier
                      : values.carrier === "current"
                      ? "Others"
                      : values.carrier,
                  oldZip: values.zip,
                  acctNum: values.accNumber,
                  oldPassword: values.password,
                },
              });
            } else {
              getNotification("activationInvalidNumberFail", msgs?.port_noti);
            }
          });
        } else {
          setBtnLoader(false);
          if (res?.payload?.status === "FAILURE") {
            if (res?.payload?.resCode === 40093 || res?.payload?.resCode === 40061) {
              getNotification("activationPostError", msgs?.num_noti);
            } else if (res?.payload?.resCode === 40227) {
              getNotification("activationPostError", res?.payload?.message);
            } else {
              getNotification("activationPostErrorDefault", msgs?.fail_noti);
            }
          }
        }
      });
    },
    enableReinitialize: true,
  });
  const handleChangeCarrier = (e: any) => {
    formik.handleChange(e);
    operators?.forEach((operator: any) => {
      if (e.target.value === operator.value) {
        setCurrentOperator(operator);
      }
    });
  };
  const zipcodeCheckHandler = (values: any) => {
    dispatch(zipcodeAvaliability({ zipcode: values?.zipCode, customerId: currentLine?.id })).then(
      (res: any) => {
        if (res.payload?.data?.data?.isValid === true) {
          handleStep(true);
          dispatch(getConfigKeys());
          updatingCustomer({ zipcode: values?.zipCode });
        }
    });
  };

  const numberFieldData: zipCodeformikintitaltypes = {
    zipCode: currentLine?.zipcode || "",
  };
  const zipcodeSchema = object({
    zipCode: string().trim().min(5, msgs?.zip_valid),
  });
  const zipCodeformik = useFormik({
    initialValues: numberFieldData,
    validationSchema: zipcodeSchema,
    onSubmit: (values: any) => {
      zipcodeCheckHandler(values);
    },
    enableReinitialize: true,
  });
  const zipcodeChangeHandler = (e: any) => {
    numChangeHandler(e, zipCodeformik);
    isValid === false && dispatch(refreshZipcode());
  };

  const clickHandlerForPortNum = () => {
    setCurrentNum(true);
    gaHandler("Keep My Current Number");
    updatingCustomer({
      isMNP: true,
    });
  };
  const newNumHandler = () => {
    dispatch(refreshZipcode());
    updatingCustomer({ isMNP: false });
  };
  const handleBack = () => {
    if (currentNum) {
      setCurrentNum(false);
    } else {
      handleStep(false);
    }
  };

  return (
    <>
      <ActivationStep2Comp
        operators={operators}
        currentOperator={currentOperator}
        btnLoader={btnLoader}
        formik={formik}
        handleChangeCarrier={handleChangeCarrier}
        zipcodeCheckHandler={zipcodeCheckHandler}
        zipCodeformik={zipCodeformik}
        zipcodeChangeHandler={zipcodeChangeHandler}
        setOpenSupportDialog={setOpenSupportDialog}
        handleBack={handleBack}
        clickHandlerForPortNum={clickHandlerForPortNum}
        currentNum={currentNum}
        newNumHandler={newNumHandler}
        {...props}
      />
      <HelpSupportModal
        open={openSupportDialog}
        onClose={() => setOpenSupportDialog(false)}
        title={c_sup_title}
        showClose={true}
        showBackBtn={false}
        content={content}
      />
    </>
  );
};
