import styles from "./S1_OurPartners.module.scss";
import { Grid, Box, Typography, Link } from "@mui/material";
import { ourPartnerProps } from "./OurPartners";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../config/env.config.json";

const OurPartners = (props: ourPartnerProps) => {
  const { heading1, ptr_list } = props.content || {};
  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.giveBack,
      category: categoryName,
      buttonValue: value,
    });
  };
  return (
    <Grid
      container
      className={styles.our_partners}
      display="flex"
      justifyContent="space-around"
      sx={{
        p: {
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/4) 0",
        },
      }}
    >
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">{heading1 || ""}</Typography>
        <Box
          className={styles.our_partners_line}
          sx={{ margin: "20px 0px 30px" }}
        />
      </Grid>
      {ptr_list &&
        ptr_list.map((partner: any, brand_description: any) => (
          <Grid
            item
            key={brand_description}
            xs={12}
            className={styles.partner_content}
            sx={{
              mb: "40px",
              p: {
                md: "0px 73px 0px 75px",
                sm: "0px 35px 0px 33px",
                xs: "0px 21px 0px 18px",
              },
            }}
            textAlign="center"
          >
            <Box
              sx={{ marginBottom: { xs: "20px" }, width: "120px" }}
              component="img"
              alt={partner?.logo?.data?.attributes?.alternativeText}
              height="auto"
              width="120px"
              src={partner?.logo?.data?.attributes?.url}
              className={styles.plan_features_image}
            ></Box>
            <Typography variant="body1" className={styles.partner_description}>
              <span>{partner?.name}&nbsp;</span>
              {partner.description}
            </Typography>
            <Link
              rel="noopener noreferrer"
              href={partner.link}
              underline="none"
              target="_blank"
              className={styles.content_link}
              onClick={() => {
                GAAndFBEventsHandler(
                  gaCategory.ourPartners,
                  partner?.name + partner?.know_more
                );
              }}
            >
              {partner.know_more}
            </Link>
          </Grid>
        ))}
    </Grid>
  );
};

export default OurPartners;
