import { Fragment } from "react";
import { Box, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";

type Props = {
  closePopper: any;
  comparePlansData: any;
};

export const PopperContent = (props: Props) => {
  const { closePopper, comparePlansData } = props;
  const { popper_header, popper_desc } = comparePlansData;
  return (
    <Fragment>
      <Grid
        container
        sx={{
          background: "var(--white)",
          width: { xs: "90%" },
          overflowWrap: "break-word",
          border: "1px solid rgba(0,0,0,.2)",
          borderRadius: "4px",
        }}
        textAlign="center"
        mx = "auto"
      >
        <Box pl={0.5}>
          <Close
            sx={{ width: "15px", cursor: "pointer" }}
            onClick={closePopper}
          />
        </Box>
        <Box p={2} pt={0}>
          <Box
            fontWeight={"var(--font_weight_1)"}
            fontFamily={"var(--font_family_Bold)"}
            mb={1}
          >
            {popper_header}
          </Box>
          <Box sx={{ fontSize: "14px" }}>{popper_desc}</Box>
        </Box>
      </Grid>
    </Fragment>
  );
};
