import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./S1_InitialActivation.module.scss";
import { ActivationStatus } from "../../../../enums";
import Config from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import clsx from "clsx";

const T1_InitialActivation = (props: any) => {
  const { handleStep, currentLine, content, updatingCustomer } = props;
  const { extraLines } = currentLine;
  const letsGoHandler = () => {
    if (currentLine?.esim) {
      updatingCustomer({
        activationJourneyStatus: ActivationStatus.IN_PROGRESS,
      });
    }
    handleStep(true);
  };
  const {
    intro_title,
    intro_desc1,
    intro_desc11,
    intro_desc12,
    intro_desc2,
    intro_pnts,
    intro_btn,
    tick_icon,
    esim_points,
  } = content || {};
  const noOfLines = extraLines + 1;
  const introPoints = currentLine?.esim ? esim_points : intro_pnts;
  const disableBack =
    currentLine?.isSimInHand &&
    currentLine?.isPrimary &&
    !Config?.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED;
  return (
    <>
      <Box className={styles.activation_main}>
        <Box className={styles.activation_header}>
          <ArrowBackIcon
            sx={{ ml: { md: "30px", sm: "25px", xs: "25px" } }}
            className={
              disableBack
                ? clsx(styles.disable_icon, styles.arrow_icon)
                : styles.arrow_icon
            }
            onClick={() => (disableBack ? null : handleStep(false))}
          />
          <Typography className={styles.title_main}>
            {BrandNameUpdate(intro_title)}
          </Typography>
        </Box>
        <Box className={styles.initial_activation_main}>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} textAlign="center">
              {intro_desc1 ? (
                <Typography
                  sx={{ display: { xs: "block", sm: "inline" } }}
                  className={styles.title_main_activation}
                >
                  {intro_desc1}
                  <Box component="span">{noOfLines}</Box>
                  {noOfLines === 1 ? intro_desc11 : intro_desc12}
                </Typography>
              ) : null}
              <Typography
                sx={{ display: { xs: "block", sm: "inline" } }}
                className={styles.title_main_activation}
              >
                {intro_desc2}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={styles.activation_body_content}
            mt={{ xs: "20px" }}
          >
            {introPoints?.map((data: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={6}
                  lg={3}
                  className={styles.activation_info}
                  key={data.id}
                >
                  <img
                    className={styles.check_icon}
                    src={tick_icon?.data?.attributes?.url}
                    alt={tick_icon?.data?.attributes?.alternativeText}
                  />
                  <Typography className={styles.activation_body_text}>
                    {BrandNameUpdate(data.desc)}
                  </Typography>
                </Grid>
              );
            })}
            <Box className={styles.btn}>
              <ButtonCust
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => letsGoHandler()}
              >
                {intro_btn}
              </ButtonCust>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default T1_InitialActivation;
