import { Box } from "@mui/material";
import clsx from "clsx";
import { ButtonCust } from "../../../widgets/Button/ButtonCust";
import { calculateCost, calculateUnlimitedCost, PlanProps } from "./PlanCard";
import styles from "./T5_PlanCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useLocation } from "react-router";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { scroller } from "react-scroll";

const T5_PlanCard = (props: PlanProps) => {
  const {
    plandata,
    planContent,
    navigate,
    borderColorAdd,
    tagLabel,
    tagBgColor,
    tagTxtColor,
    selectedLine,
    isSelected,
    planSelectionHandler,
  } = props;

  const onViewDetails = (_: any) => {
    navigate(RoutingURLs.cellPhonePlans);
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }, 100);
  };

  const location = useLocation();

  const mouseHoverHandler = (index: any) => {
    let parent: any = document.getElementById(`home${index}`);
    parent.setAttribute(
      "class",
      `${home_page_plan_card} ${
        borderColorAdd ? apply_border123 : ""
      } ${hover_color}`
    );
  };
  const mouseOutHandler = (index: any) => {
    let parent: any = document.getElementById(`home${index}`);
    parent.setAttribute(
      "class",
      `${home_page_plan_card} ${borderColorAdd ? apply_border123 : ""}`
    );
  };
  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    current_plan,
    separator,
    fade_out,
    save_money_area,
    plan_page_offer,
    hover_color,
    apply_border123,
    view_detail,
    home_page_plan_card,
    tag,
    title,
    save_money_img,
    plan_cost_data,
    cost,
    cost1,
  } = styles;
  const {
    button_text,
    per_month_text,
    save_money,
    view_details_text,
    shareable_image,
    icon_offers,
  } = planContent || {};

  return (
    <>
      <Box className={clsx(cards_div, isSelected ? plans_div : "")}>
        {plandata.length > 0 &&
          plandata.map((el: any, index: number) => {
            const { planCost } = el?.unlimited
              ? calculateUnlimitedCost(el, selectedLine)
              : calculateCost(el, selectedLine);
            const isCurrentPlan =
              details?.reachPlanDisplayName === el?.displayName;
            return (
              <Box
                id={`home${index}`}
                key={index}
                className={clsx(
                  home_page_plan_card,
                  isSelected ? plan_page_plan_card : "",
                  borderColorAdd ? borderColorAdd : "",
                  isCurrentPlan ? current_plan : ""
                )}
              >
                {isCurrentPlan && (
                  <>
                    {!tagLabel && (
                      <Box
                        className={tag}
                        sx={{
                          backgroundColor: tagBgColor
                            ? tagBgColor
                            : "var(--primary_background)",
                          color: tagTxtColor ? tagTxtColor : "#fff",
                        }}
                      >
                        {planContent?.current_plan}
                      </Box>
                    )}
                  </>
                )}
                <Box className={title}>{el.displayName}</Box>
                <Box className={separator}></Box>
                <Box
                  className={save_money_area}
                  style={{
                    opacity: el.isBestFitAllowed ? 1 : 0.2,
                    display: "none",
                  }}
                >
                  <Box
                    component={"img"}
                    src={shareable_image?.data?.attributes?.url}
                    alt={shareable_image?.data?.attributes?.alternativeText}
                    className={save_money_img}
                  />
                  {save_money}
                </Box>
                <Box className={plan_cost_data}>
                  <Box component={"span"} className={cost}>
                    {GetCurrencyFixedToTwo(
                      planCost ? planCost : 0,
                      el.currency ? el.currency : "usd"
                    )}
                  </Box>
                  <Box component={"span"} className={cost1}>
                    {per_month_text}
                  </Box>
                </Box>
                {!isSelected && location.pathname !== RoutingURLs.myAccount && (
                  <Box
                    className={view_detail}
                    sx={{ color: "var(--primary_brand_color)" }}
                    onClick={() => onViewDetails(el)}
                    onMouseOver={() => mouseHoverHandler(index)}
                    onMouseOut={() => mouseOutHandler(index)}
                  >
                    {view_details_text}
                  </Box>
                )}
                {isSelected && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mb: "30px",
                      }}
                    >
                      {icon_offers &&
                        icon_offers.map((item: any, index: any) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                p: "10px 0px 0px",
                              }}
                            >
                              <Box>
                                <Box
                                  component={"img"}
                                  alt=""
                                  src={item?.image?.data?.attributes?.url}
                                  sx={{ width: "16px" }}
                                />
                              </Box>
                              <Box className={plan_page_offer}>
                                {item?.offer}
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                    <Box
                      className={el.maxLines >= selectedLine ? " " : fade_out}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "10px",
                      }}
                    >
                      <ButtonCust
                        variantType={Config.PRIMARY_BUTTON_TYPE}
                        className={el.maxLines >= selectedLine ? " " : fade_out}
                        onClick={(e: any) => {
                          el.maxLines >= selectedLine
                            ? planSelectionHandler(el, button_text)
                            : "";
                        }}
                      >
                        {button_text}
                      </ButtonCust>
                    </Box>
                  </>
                )}
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default T5_PlanCard;
