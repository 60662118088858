import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { helpContent } from "../../../features/strapi/helpSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const HelpContentComponent = isTemplateExisits(Config.HelpCenter.HelpContent)
  ? require(`./${Config.HelpCenter.HelpContent.template}_HelpContent`).default
  : null;

export const HelpContent = () => {
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { helpData } = useAppSelector((state: any) => state.strapi.help);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("HelpPage", helpContent, setCacheContent, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("HelpPage", helpData);
  }, [helpData]);
  const onClickHandler = (
    _: any,
    navigationPath?: string,
    redirectionPage?: string
  ) => {
    navigationPath && window.open(navigationPath, redirectionPage);
  };

  return (
    (helpData || cacheContent) && (
      <HelpContentComponent
        helpContent={cacheContent ? cacheContent : helpData}
        onClickHandler={onClickHandler}
      />
    )
  );
};
