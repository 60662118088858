import { CircularProgress, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonCust } from "../../../../widgets";
import styles from "./AddCreditPayment.module.scss";
import Config from "../../../../../config/env.config.json";
import { useAppSelector } from "../../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import PaymentReviewCard from "../../../IRILDComponents/PaymentReviewCard/PaymentReviewCard";

function AddCreditPayment(props: any) {
  const { planTaxCharges, isLoadedPlanTaxCharges } = useAppSelector(
    (state: any) => state.checkout
  );
  const { getCCDetails } = useAppSelector((state: any) => state.account);

  const { content, totalCost, userLineData, setIsCreditPayment, isLoader } =
    props;

  const { visa_card, change_card, back_icon, back_txt, title, credit_payment } =
    content;

  const {
    taxAndFees,
    nameTxt,
    totalDue,
    btnTxt,
    mockCard,
    changeCardTxt,
    creditTitle,
  } = credit_payment;

  const { user_name_db } = styles;

  return (
    <>
      <Box
        sx={{
          width: "55%",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "30px",
            "& span": {
              fontFamily: "var(--font_family_Medium) !important",
              fontWeight: "var(--font_weight_5)",
            },
          }}
        >
          <Box
            component="span"
            style={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              gap: "8px",
              left: "103px",
              cursor: "pointer",
              fontWeight: "var(--font_weight_3)",
            }}
            onClick={() => setIsCreditPayment(false)}
          >
            <img
              src={back_icon?.data.attributes.url}
              alt={back_icon?.data.attributes.alternativeText}
              width={17.495}
              height={14.586}
            />
            {back_txt}
          </Box>
          <Box component="span" style={{ margin: "auto" }}>
            {title}
          </Box>
        </Typography>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "30px",
            mt: "40px",
          }}
        >
          <Typography
            component="div"
            sx={{
              fontSize: "24px",
              color: "var(--tab_color_2)",
              fontWeight: "var(--font_weight_5)",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {creditTitle}
          </Typography>
          <Typography component="div" className={user_name_db}>
            {userLineData?.firstName} {userLineData?.lastName}
          </Typography>
        </Box>

        <PaymentReviewCard
          firstRowTitle={nameTxt}
          firstRowValue={totalCost}
          secondRowTitle={taxAndFees}
          secondRowValue={planTaxCharges}
          thirdRowTitle={totalDue}
          mockCard={mockCard}
          visa_card={visa_card?.data.attributes}
          confirmBtn={btnTxt}
          carbon_edit={change_card?.data?.attributes}
          totalCost={totalCost}
          changeCardText={changeCardTxt}
          onClick={props.onClick}
          loading={isLoadedPlanTaxCharges}
          btnLoading={isLoader}
        />
      </Box>
    </>
  );
}

export default AddCreditPayment;
