import { Box, CircularProgress, Typography } from "@mui/material";
import { ButtonCust, Divider, SimpleModal } from "../../widgets";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./CustomDateCalendar.scss";

const CustomDateCalendar = (props: any) => {
  return (
    <SimpleModal
      isShowModal={props.isCalanderModal}
      onCloseModal={() => props.onCloseModal(false)}
      showClose={true}
      sx={{ top: "20px", borderRadius: "6px", width: "60%" }}
    >
      <Box className="container">
        <Typography className="cal_title">{props.title}</Typography>
        <Divider />
        <Typography className="desc">{props.desc}</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            disablePast
            showDaysOutsideCurrentMonth
            onChange={props.onChange}
            className="dateCalendar"
            sx={{
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "var(--primary_color)",
                },
              },
              "& .MuiPickersYear-yearButton": {
                "&.Mui-selected": {
                  backgroundColor: "var(--primary_color)",
                },
              },
            }}
          />
        </LocalizationProvider>
        <ButtonCust
          sx={{ width: "70%" }}
          variantType={props.variant}
          onClick={props.onClick}
        >
          {props.rescheduleFuturePackPending ? (
            <CircularProgress />
          ) : (
            props.button
          )}
        </ButtonCust>
      </Box>
    </SimpleModal>
  );
};

export default CustomDateCalendar;
