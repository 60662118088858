export enum gaEventTypes {
  click = "click",
  page_view = "page_view",
  event = "event",
  hover = "hover",
}
export enum gaBrandNames {
  reach = "Reach",
  wow = "Wow",
  brand = "Brand",
  ghost = "Ghost",
}
export enum gaScreenNames {
  home = "Home",
  phones = "Phones",
  plan = "Plan",
  bestFit = "bestfit",
  contactUs = "Contact Us",
  BillCalculator = "Bill Calculator",
  coverageCheck = "Coverage Check",
  giveBack = "Give Back",
  internationalServices = "International Services",
  trackOrder = "Track Order",
  imeiCompatibilityCheck = "Imei Cmpatibility Check",
  login = "Login",
  signup = "Signup",
  discover = "Discover",
  aboutUs = "About Us",
  privacy = "Privacy",
  activation = "Activation",
  checkout = "Checkout",
  helpCenter = "helpCenter",
  help = "help",
  postActivationHome = "postActivationHome",
  account = "account",
  billing = "Billing",
  profile = "profile",
  reviews = "reviews",
  services = "services",
  terms = "terms",
  secondaryUserHome = "secondaryUserHome",
  activateEsim = "ActivateEsim",
  settings = "settings",
  wallet = "wallet",
  ulTerms = "UlTerms",
}
export enum gaCategory {
  leadGeneration = "generate_lead",
  checkCoverage = "coverage_check",
  imeiCheck = "IMEI_check",
  plan = "plans",
  lineSelection = "line_selection",
  data = "data",
  brandFeatures = "brand_features",
  networkCompatible = "network_compatible",
  phones = "phones",
  comparePlans = "compare_phone_plans",
  phoneCompatibility = "phone_compatibility",
  helpSupport = "help_support",
  help = "help",
  detailCoverage = "detail_coverage",
  billCalculator = "bill_calculator",
  meetTheRecipients = "meet_the_recipients",
  internationalServices = "international_services",
  activation = "activation",
  networkCoverage = "network_coverage",
  bestFit = "bestfit",
  CheckCompatibility = "compatibility_check",
  whereWeGive = "where_we_give",
  footer = "footer",
  login = "click_login",
  signup = "click_signup",
  forgotPassword = "click_forgot_password",
  primaryValues = "primary_values",
  discover = "discover",
  share = "share",
  privacy = "privacy",
  support = "support",
  swiftPurchase = "swift_purchase",
  purchaseFlow = "purchase_flow",
  userStories = "user_stories",
  donations = "donations",
  dataUsage = "data_usage",
  manageLines = "manage_lines",
  paymentHistory = "payment_history",
  detailedPaymentHistory = "detailed_payment_history",
  autoPaymentDetails = "auto_payment_details",
  currentPlan = "current_plan",
  changePlan = "change_plan",
  buyDataPack = "buy_data_pack",
  editProfile = "edit_profile",
  ourPartners = "our_partners",
  wallet = "wallet",
  purchaseInitiated = "purchase_initiated",
  planCardSelected = "plan_card_selected",
  puchaseFailed = "puchase_failed",
  puchaseSuccess = "puchase_success",
}
