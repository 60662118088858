import { Box } from "@mui/material";
import { LazyLoadImageCust } from "../../components";
import { BrandNameUpdate } from "./BrandNameUpdate";
interface DisplayTableProps {
  data?: any;
  attrs?: any;
  overrides?: any;
  className?: any;
  onClick?: any;
  mulData?: any;
}

export const generateImageContainer = (props: DisplayTableProps) => {
  const { data, attrs, overrides, className, onClick, mulData } = props;
  return (
    <LazyLoadImageCust
      src={
        mulData ? mulData?.attributes?.url : data?.data?.attributes?.url || data
      }
      alt={
        mulData
          ? BrandNameUpdate(mulData?.attributes?.alternativeText)
          : BrandNameUpdate(data?.data?.attributes?.alternativeText) ||
            BrandNameUpdate(data?.attributes?.alternativeText)
      }
      width={attrs?.width}
      height={attrs?.height}
      style={overrides?.sx}
      classNames={className}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          return false;
        }
      }}
    />
  );
};
