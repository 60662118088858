import { Box, Typography, Grid } from "@mui/material";
import styles from "./ActivationStatusSuccess.module.scss";
import config from "../../../../config/env.config.json";
import { useState } from "react";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import { useNavigate } from "react-router-dom";
import { VideoDialogCust, SimpleModal, ButtonCust } from "../../../widgets";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useAppSelector } from "../../../../app/hooks";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import planData from "../../../../config/data.json";

type Props = {
  closeHandler: any;
  modalData: any;
  customerDetails?: any;
  videoFlag?: boolean;
};

export function ActivationStatusSuccess(props: Props) {
  const { closeHandler, modalData, customerDetails, videoFlag } = props;
  const { title, btnText, subtext, video_txt, video_link, video_img } =
    modalData || {};
  const { group } = useAppSelector((state: any) => state.account);
  let navigate = useNavigate();
  const [gbmodalShow, setgbmodalShow] = useState(false);
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);
  const gbmodalHide = () => {
    setgbmodalShow(false);
  };
  const gbmodalHandler = (_: any) => {
    GAAndFBEventsHandler(gaCategory.activation, "Watch video");
    setgbmodalShow(true);
  };
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: config.brand,
      screenName: gaScreenNames.activation,
      category: category,
      buttonValue: value,
    });
  };
  const activatedCustomers = group?.data?.filter(
    (el: any) => el?.activationStatus
  );

  const maxLines = Math.max(...planData?.data?.map((item) => item.maxLines));

  return (
    <>
      {gbmodalShow ? (
        <VideoDialogCust
          open={gbmodalShow}
          onClose={() => gbmodalHide()}
          videoUrl={video_link}
        />
      ) : (
        <SimpleModal
          onCloseModal={closeHandler}
          showClose={true}
          isShowModal={true}
          sx={{
            width: { xs: "95%", sm: "50%", md: "50%", lg: "40%" },
            borderRadius: "6px",
          }}
        >
          <Grid
            container
            display="flex"
            flexDirection="column"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            className={styles.ac_status_succes}
          >
            <Typography variant="body2" className={styles.title}>
              {BrandNameUpdate(title)}
            </Typography>
            <Typography variant="body2" className={styles.subtitle}>
              {subtext}
            </Typography>
            {config?.IS_WATCH_VIDEO_ACTIVATION_REQD && videoFlag && (
              <Box
                sx={{
                  lineHeight: "1.8",
                  letterSpacing: "normal",
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--primary_color)",
                  fontSize: "16px",
                  fontWeight: "var(--font_weight_2)",
                  width: "fit-content",
                  cursor: "pointer",
                }}
                margin="15px 0"
                onClick={(e: any) => gbmodalHandler(e)}
              >
                <Box
                  component="img"
                  mb="-4px"
                  src={video_img?.data?.attributes?.url}
                  alt={video_img?.data?.attributes?.alternativeText}
                  className={styles.planImg}
                />
                <Box component="span" ml="5px">
                  {video_txt}
                </Box>
              </Box>
            )}
            {activatedCustomers?.length < customerDetails?.noOfSimsOrdered &&
              maxLines > 1 &&
              customerDetails?.isPrimary && (
                <Box className={styles.button_contact}>
                  <ButtonCust
                    variantType={config.PRIMARY_BUTTON_TYPE}
                    onClick={() => navigate(RoutingURLs.account)}
                  >
                    {btnText}
                  </ButtonCust>
                </Box>
              )}
          </Grid>
        </SimpleModal>
      )}
    </>
  );
}
