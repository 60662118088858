import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import styles from "../S1_ViewCredits.module.scss";
import config from "../../../../../config/env.config.json";
import {
  inviteFriends,
  inviteFriendsHandler,
  ButtonCust,
} from "../../../../widgets";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

type Props = {
  content: any;
  availableCredits: any;
};
export const AvailableCredit = ({ content, availableCredits }: Props) => {
  useEffect(() => {
    inviteFriends();
  }, []);
  return (
    <Grid item xs={11} sm={5.75} className={styles.card_item}>
      <Typography className={styles.card_heading}>{content?.ac_ttl}</Typography>
      <Typography className={styles.card_sub_heading}>
        {content?.ac_txt}
      </Typography>
      <Typography className={styles.credit_amount}>
        {GetCurrencyFixedToTwo(availableCredits)}
      </Typography>
      <ButtonCust
        variantType={config.PRIMARY_BUTTON_TYPE}
        onClick={() => inviteFriendsHandler()}
      >
        {content?.ac_btn_txt}
      </ButtonCust>
    </Grid>
  );
};
