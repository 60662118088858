import { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { useAppDispatch } from "../../../app/hooks";
import { eligibilityCheckApi } from "../../../features/homepage/homepageSlice";
import { ButtonCust, Divider, SimpleModal, TextFieldCust } from "../../widgets";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AccountNumberPopover from "./AccountNumberPopover";
import { string, object } from "yup";
import { useFormik } from "formik";
import EnvConfig from "../../../config/env.config.json";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
  content: any;
  popoverContent: any;
  openModal: boolean;
  wowCustomerData: any;
  setHsdCheckFormModal: any;
  setWowCustomerData: any;
  initialWowCustomerData: any;
  setIsCheckEligibilityClicked: any;
  selectedPlan: any;
  currentuser: any;
  popoverId: any;
  open: any;
  anchorEl: any;
  handleOpenPopover: any;
  handleClosePopover: any;
  eligibilityCheckFromPromo?: any;
  setEligibilityCheckFromPromo?: any;
};
const HSDCheckFormModal = (props: Props) => {
  const {
    content,
    popoverContent,
    openModal,
    wowCustomerData,
    setHsdCheckFormModal,
    setWowCustomerData,
    initialWowCustomerData,
    setIsCheckEligibilityClicked,
    selectedPlan,
    currentuser,
    popoverId,
    open,
    anchorEl,
    handleOpenPopover,
    handleClosePopover,
    eligibilityCheckFromPromo,
    setEligibilityCheckFromPromo,
  } = props;

  const {
    acc_num_err1,
    acc_num_err2,
    zip_err1,
    zip_err2,
    ttl,
    sub_ttl,
    acc_num_label,
    zip_label,
    last_name_label,
    last_name_err,
    find_text,
    acc_num_text,
    btn_text,
  } = content;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const OnCloseModal = () => {
    setHsdCheckFormModal(false);
    setEligibilityCheckFromPromo(false);
    if (isAuthenticatedUser) {
      setWowCustomerData((prevState: any) => ({
        ...prevState,
        wowAccountNumber: "",
        wowAccountServiceZipcode: "",
      }));
    } else {
      setWowCustomerData({
        ...initialWowCustomerData,
      });
    }
  };

  const handleInputChange = (e: any) => {
    setWowCustomerData((prevProps: any) => ({
      ...prevProps,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    const selectedPlanStore = JSON.parse(
      localStorage.getItem("reachSelectedPlanDetail") || "null"
    );
    setIsLoading(true);
    setIsCheckEligibilityClicked(true);
    let data = {
      zipCode: wowCustomerData.wowAccountServiceZipcode,
      accountNum: wowCustomerData.wowAccountNumber,
      lastName: wowCustomerData.wowCustomerLastName,
      isUnlimited:
        selectedPlan?.isUnlimited || selectedPlanStore?.plan?.isUnlimited
          ? 1
          : 0,
      planName: selectedPlan?.name || selectedPlanStore?.plan?.name,
    };
    dispatch(eligibilityCheckApi({ data }));
  };

  const validateCheckFormSchema = object({
    wowAccountNumber: string()
      .trim()
      .required(BrandNameUpdate(acc_num_err1))
      .test(
        "len",
        BrandNameUpdate(acc_num_err2),
        (val: any) => val?.length === 4
      ),
    wowAccountServiceZipcode: string()
      .trim()
      .required(BrandNameUpdate(zip_err1))
      .test("len", BrandNameUpdate(zip_err2), (val: any) => val?.length === 5),
    wowCustomerLastName: eligibilityCheckFromPromo
      ? string().trim().required(BrandNameUpdate(last_name_err))
      : string(),
  });
  const formik: any = useFormik({
    initialValues: wowCustomerData,
    validationSchema: validateCheckFormSchema,
    validateOnChange: true,
    onSubmit: () => {
      handleSubmit();
    },
    enableReinitialize: true,
  });
  let isAuthenticatedUser = true;
  if (currentuser?.isAnonymous) {
    isAuthenticatedUser = false;
  } else if (currentuser?.isAnonymous === undefined) {
    isAuthenticatedUser = false;
  }
  useEffect(() => {
    if (!isAuthenticatedUser) {
      setWowCustomerData({
        ...initialWowCustomerData,
      });
    }
  }, [isAuthenticatedUser]);

  return (
    <>
      <SimpleModal
        isShowModal={openModal}
        onCloseModal={OnCloseModal}
        showClose={true}
        sx={{
          width: { xs: "90%", sm: "65%", md: "55%", lg: "40%", xl: "35%" },
          top: { xs: "10%" },
        }}
      >
        <Typography
          sx={{
            mt: "24px",
            mb: "10px",
            fontSize: "26px",
            fontFamily: "var(--font_family_Semibold)",
            fontWeight: "var(--h4_font_weight)",
            textAlign: "center",
          }}
        >
          {BrandNameUpdate(ttl)}
        </Typography>
        <Divider />
        <Typography
          sx={{
            my: "15px",
            textAlign: "center",
            fontSize: "18px",
            fontFamily: "var(--font_family_Medium)",
            fontWeight: "var(--h4_font_weight)",
            color: "var(--grey_shade)",
          }}
        >
          {BrandNameUpdate(sub_ttl)}
        </Typography>
        <TextFieldCust
          name="wowAccountNumber"
          onChange={(e: any) => {
            if (
              (/^[0-9 ]+$/.test(e.target.value) || e.target.value === "") &&
              e.target.value.length <= 4
            ) {
              formik.handleChange;
              handleInputChange(e);
            }
          }}
          value={formik.values.wowAccountNumber}
          placeholder={BrandNameUpdate(acc_num_label)}
          error={formik.errors?.wowAccountNumber ? true : false}
          helperText={<>{formik.errors?.wowAccountNumber}</>}
        />
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          mb="16px"
          mt="4px"
        >
          <Typography
            color="var(--grey_shade)"
            fontSize="14px"
            fontFamily="var(--font_family_Medium)"
          >
            {find_text}&nbsp;
            <span
              style={{ color: "var(--primary_brand_color)", cursor: "pointer" }}
              onClick={handleOpenPopover}
            >
              {acc_num_text}
              <span style={{ position: "absolute" }}>
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </span>
            </span>
          </Typography>
        </Box>
        <TextFieldCust
          name="wowAccountServiceZipcode"
          onChange={(e: any) => {
            if (
              (/^[0-9 ]+$/.test(e.target.value) || e.target.value === "") &&
              e.target.value.length <= 5
            ) {
              formik.handleChange;
              handleInputChange(e);
            }
          }}
          value={formik.values.wowAccountServiceZipcode}
          placeholder={BrandNameUpdate(zip_label)}
          error={formik.errors?.wowAccountServiceZipcode ? true : false}
          helperText={<>{formik.errors?.wowAccountServiceZipcode}</>}
          sx={{ mb: "16px" }}
        />
        <TextFieldCust
          name="wowCustomerLastName"
          placeholder={BrandNameUpdate(last_name_label)}
          disabled={eligibilityCheckFromPromo ? false : true}
          sx={{ mb: "16px" }}
          value={formik.values.wowCustomerLastName}
          error={formik.errors?.wowCustomerLastName ? true : false}
          helperText={<>{formik.errors?.wowCustomerLastName}</>}
          onChange={(e: any) => {
            if (
              (/^[a-zA-Z ]*$/.test(e.target.value) || e.target.value === "") &&
              eligibilityCheckFromPromo
            ) {
              formik.handleChange;
              handleInputChange(e);
            }
          }}
        />
        <ButtonCust
          variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
          sx={{ width: "100%" }}
          onClick={formik.handleSubmit}
          loading={isLoading}
        >
          {btn_text}
        </ButtonCust>
      </SimpleModal>
      <AccountNumberPopover
        content={popoverContent}
        popoverId={popoverId}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClosePopover}
      />
    </>
  );
};

export default HSDCheckFormModal;
