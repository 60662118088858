import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import Config from "../../config/app.config.json";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";

type InitialState = {
  addressInfoContent: any;
  errorAddressInfoContent: any;
  reviewContent: any;
  errorReviewContent: any;
  simPreferenceContent: any;
  errorSimPreferenceContent: any;
  checkoutStepperContent: any;
  errorCheckoutStepperContent: any;
  autoPayContent: any;
  errorAutoPayContentContent: any;
  loadPage: any;
  errorLoadPage: any;
  simInHandContent: any;
  simInHandContentErr: any;
  addLineChangeCardContent: any;
  addLineChangeCardContentErr: any;
  zipcodeContent: any;
  zipcodeContentErr: any;
};
const initialState: InitialState = {
  addressInfoContent: null,
  errorAddressInfoContent: null,
  reviewContent: null,
  errorReviewContent: null,
  simPreferenceContent: null,
  errorSimPreferenceContent: null,
  checkoutStepperContent: null,
  errorCheckoutStepperContent: null,
  autoPayContent: null,
  errorAutoPayContentContent: null,
  loadPage: null,
  errorLoadPage: null,
  simInHandContent: null,
  simInHandContentErr: null,
  addLineChangeCardContent: null,
  addLineChangeCardContentErr: null,
  zipcodeContent: null,
  zipcodeContentErr: null,
};
export const loaderPageContent = createAsyncThunk(
  "checkoutContent/loaderPageContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.loaderPage}`,
      isStrapiApi: true,
    });
  }
);
export const getAddressInfoContent = createAsyncThunk(
  "checkoutContent/getAddressInfoContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Checkout.AddressInfo.template}${StrapiApiEndPoints.addressInfo}`,
      isStrapiApi: true,
    });
  }
);
export const getAutoPayCContent = createAsyncThunk(
  "checkoutContent/getAutoPayContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.autoPayC}`,
      isStrapiApi: true,
    });
  }
);

export const getReviewContent = createAsyncThunk(
  "checkoutContent/getReviewContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Checkout.ReviewPlanDetails.template}${StrapiApiEndPoints.reviewPlan}`,
      isStrapiApi: true,
    });
  }
);
export const getAddLine_ChangePlanContent = createAsyncThunk(
  "checkoutContent/AddLine_ChangePlan",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Checkout.AddLine_ChangePlan.template}${StrapiApiEndPoints.addLine_ChangePlan}`,
      isStrapiApi: true,
    });
  }
);

export const getSimPrefernceContent = createAsyncThunk(
  "checkoutContent/getSIMPrefernceContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Checkout.ChangeLinePreference.template}${StrapiApiEndPoints.simPreference}`,
      isStrapiApi: true,
    });
  }
);
export const checkoutStepper = createAsyncThunk(
  "checkoutContent/checkoutStepper",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.checkoutStepper}`,
      isStrapiApi: true,
    });
  }
);

export const getSimInHandContent = createAsyncThunk(
  "checkoutContent/simInHand",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.simInHand}`,
      isStrapiApi: true,
    });
  }
);

export const getZipcodeContent = createAsyncThunk(
  "checkoutContent/zipcodeContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.zipcode_validation}`,
      isStrapiApi: true,
    });
  }
);

const checkoutSlice = createSlice({
  name: "checkoutContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAddressInfoContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addressInfoContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorAddressInfoContent = null;
      }
    );
    builder.addCase(getAddressInfoContent.rejected, (state, action) => {
      state.addressInfoContent = null;
      state.errorAddressInfoContent =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getAutoPayCContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.autoPayContent = action?.payload?.data?.data?.attributes || null;
        state.errorAutoPayContentContent = null;
      }
    );
    builder.addCase(getAutoPayCContent.rejected, (state, action) => {
      state.autoPayContent = null;
      state.errorAutoPayContentContent =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getReviewContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reviewContent = action?.payload?.data?.data?.attributes || null;
        state.errorReviewContent = null;
      }
    );
    builder.addCase(getReviewContent.rejected, (state, action) => {
      state.reviewContent = null;
      state.errorReviewContent =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getSimPrefernceContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.simPreferenceContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorSimPreferenceContent = null;
      }
    );
    builder.addCase(getSimPrefernceContent.rejected, (state, action) => {
      state.simPreferenceContent = null;
      state.errorSimPreferenceContent =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      checkoutStepper.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.checkoutStepperContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorCheckoutStepperContent = null;
      }
    );
    builder.addCase(checkoutStepper.rejected, (state, action) => {
      state.checkoutStepperContent = null;
      state.errorCheckoutStepperContent =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      loaderPageContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loadPage = action?.payload?.data?.data?.attributes || null;
        state.errorLoadPage = null;
      }
    );
    builder.addCase(loaderPageContent.rejected, (state, action) => {
      state.loadPage = null;
      state.errorLoadPage = action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getSimInHandContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.simInHandContent =
          action?.payload?.data?.data?.attributes || null;
        state.simInHandContentErr = null;
      }
    );
    builder.addCase(getSimInHandContent.rejected, (state, action) => {
      state.simInHandContent = null;
      state.simInHandContentErr =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getAddLine_ChangePlanContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addLineChangeCardContent =
          action?.payload?.data?.data?.attributes || null;
        state.addLineChangeCardContentErr = null;
      }
    );
    builder.addCase(getAddLine_ChangePlanContent.rejected, (state, action) => {
      state.addLineChangeCardContent = null;
      state.addLineChangeCardContentErr =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getZipcodeContent.fulfilled,
      (state, action) => {
        state.zipcodeContent =
          action?.payload?.data?.data?.attributes || null;
        state.zipcodeContentErr = null;
      }
    );
    builder.addCase(getZipcodeContent.rejected, (state, action) => {
      state.zipcodeContent = null;
      state.zipcodeContentErr =
        action?.error?.message || "Something went wrong";
    });
  }
});

export default checkoutSlice.reducer;
