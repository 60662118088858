import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { reviewContent } from "../../../features/strapi/reviewsSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const FeedbackComponent = isTemplateExisits(Config.Reviews.Feedback)
  ? require(`./${Config.Reviews.Feedback.template}_feedback`).default
  : null;
export type feedbackProps = {
  content?: any;
};
export const Feedback = () => {
  const [cacheReviews, setCacheReviews] = useState<any>(null);
  const { feedbackContent } = useAppSelector(
    (state: any) => state.strapi.reviews
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("LetterAboutUs", reviewContent, setCacheReviews, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData(
      "LetterAboutUs",
      feedbackContent
    );
  }, [feedbackContent]);
 
  return (feedbackContent || cacheReviews) ? (
    <FeedbackComponent content={cacheReviews ? cacheReviews : feedbackContent} />
  ) : null;
};
