import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  serviceData: any;
  errorServiceData: any;
  servicesBanner1?: boolean;
  IRContent: any;
  errorIRContent: any;
  ILDContent: any;
  errorILDContent: any;
  IRPurchaseHistoryContent: any;
  errorIRPurchaseHistoryContent: any;
  walletHistoryContent:any;
  errorWalletHistoryContent:any
};

const initialState: InitialState = {
  serviceData: null,
  errorServiceData: null,
  servicesBanner1: true,
  IRContent: null,
  errorIRContent: null,
  ILDContent: null,
  errorILDContent: null,
  IRPurchaseHistoryContent: null,
  errorIRPurchaseHistoryContent: null,
  walletHistoryContent:null,
  errorWalletHistoryContent:null
};

// Generates pending, fulfilled and rejected action types
export const serviceContent = createAsyncThunk("service/serviceContent", () => {
  if (Config.PostActivationServices.ServicesDetails.template === "T2") {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PostActivationServices.ServicesDetails.template}${StrapiApiEndPoints.postActServices}`,
      isStrapiApi: true,
    });
  } else {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PostActivationServices.ServicesDetails.template}${StrapiApiEndPoints.services}`,
      isStrapiApi: true,
    });
  }
});

export const getIRContent = createAsyncThunk(
  "internationalroaming/getIRContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${Config.PostActivationServices.PAInternationalRoaming.template}${StrapiApiEndPoints.postActRoaming}`,
      isStrapiApi: true,
    });
  }
);

export const getIRPurchaseHistoryContent = createAsyncThunk(
  "services/getIRPurchaseHistoryContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${Config.PostActivationServices.IRPurchaseHistory.template}${StrapiApiEndPoints.irPurchaseHistory}`,
      isStrapiApi: true,
    });
  }
);
export const getWalletHistoryContent = createAsyncThunk(
  "services/getWalletHistoryContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${Config.PostActivationServices.WalletHistory.template}${StrapiApiEndPoints.walletHistory}`,
      isStrapiApi: true,
    });
  }
);


export const getILDContent = createAsyncThunk(
  "internationalcalling/getILDContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${Config.PostActivationServices.PAInternationalCalling.template}${StrapiApiEndPoints.postActCalling}`,
      isStrapiApi: true,
    });
  }
);

const strapiServiceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    bannerHandler: (state, action) => {
      state.servicesBanner1 = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      serviceContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.serviceData = action.payload?.data?.data?.attributes;
        state.errorServiceData = null;
      }
    );
    builder.addCase(
      serviceContent.rejected,
      (state: InitialState, action: any) => {
        state.serviceData = null;
        state.errorServiceData = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      getIRContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.IRContent = action.payload?.data?.data?.attributes;
        state.errorIRContent = null;
      }
    );
    builder.addCase(
      getIRContent.rejected,
      (state: InitialState, action: any) => {
        state.IRContent = null;
        state.errorIRContent = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      getIRPurchaseHistoryContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.IRPurchaseHistoryContent = action.payload?.data?.data?.attributes;
        state.errorIRPurchaseHistoryContent = null;
      }
    );
    builder.addCase(
      getIRPurchaseHistoryContent.rejected,
      (state: InitialState, action: any) => {
        state.IRPurchaseHistoryContent = null;
        state.errorIRPurchaseHistoryContent =
          action.error || "Something went wrong";
      }
    );
    builder.addCase(
      getILDContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.ILDContent = action.payload?.data?.data?.attributes;
        state.errorILDContent = null;
      }
    );
    builder.addCase(
      getILDContent.rejected,
      (state: InitialState, action: any) => {
        state.ILDContent = null;
        state.errorILDContent = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      getWalletHistoryContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.walletHistoryContent = action.payload?.data?.data?.attributes;
        state.errorWalletHistoryContent = null;
      }
    );
    builder.addCase(
      getWalletHistoryContent.rejected,
      (state: InitialState, action: any) => {
        state.walletHistoryContent = null;
        state.errorWalletHistoryContent = action.error || "Something went wrong";
      }
    );
  },
});

export const { bannerHandler } = strapiServiceSlice.actions;

export default strapiServiceSlice.reducer;
