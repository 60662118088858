import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  apnRes: any;
  apnImages: any;
  errorApnRes: any;
  apnContentLoading: boolean;
};

const initialState: InitialState = {
  apnRes: null,
  apnImages: null,
  errorApnRes: null,
  apnContentLoading: false,
};

export const apnSettingsContent = createAsyncThunk(
  "apnSettings/apnSettingsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${Config.ApnSettings.ApnNote.template}${StrapiApiEndPoints.apnSetting}`,
      isStrapiApi: true,
    });
  }
);
const apnSettingsSlice = createSlice({
  name: "apnSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      apnSettingsContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.apnRes = action.payload?.data?.data?.attributes?.res;
        state.apnImages = action.payload?.data?.data?.attributes;
        state.errorApnRes = null;
        state.apnContentLoading = false;
      }
    );
    builder.addCase(apnSettingsContent.rejected, (state, action) => {
      state.apnRes = null;
      state.errorApnRes = action.error || "Something went wrong";
      state.apnContentLoading = false;
    });
    builder.addCase(apnSettingsContent.pending, (state, action) => {
      state.apnContentLoading = true;
    });
  },
});

export default apnSettingsSlice.reducer;
