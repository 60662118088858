import { Grid, Typography } from "@mui/material";
import EnvConfig from "../../../../config/env.config.json";
import {
  ButtonCust,
  Divider,
  NotificationCust,
  SimpleModal,
} from "../../../widgets";
type Props = {
  language: string;
  commonModal: boolean;
  onClose: Function;
  content?: any;
  modalType: string;
  modalValue: any;
  customerId?: any;
  isLoading: boolean;
  setIsLoading: Function;
  customerNum?: any;
  wifiAddress?: any;
};
import {
  dataSaver,
  resetPassword,
  updateLanguage,
  wifiUpdate,
} from "../../../../features/settings/settingsSlice";
import { useAppDispatch } from "../../../../app/hooks";
import { SettingsCardsEnum } from "../../../../enums";

const CommonModal = (props: Props) => {
  const {
    commonModal,
    onClose,
    content,
    modalValue,
    modalType,
    customerId,
    isLoading,
    setIsLoading,
    customerNum,
    wifiAddress,
  } = props;
  const dispatch = useAppDispatch();
  const selectLanguage = () => {
    let payload = {
      customerId,
      data: {
        language: modalValue === SettingsCardsEnum.English ? "EN" : "ES",
      },
    };
    dispatch(updateLanguage(payload));
  };

  const handlePassword = () => {
    let payload = {
      customerId,
      data: {
        phoneNo: customerNum.substr(-4),
      },
    };
    dispatch(resetPassword(payload));
  };
  const handleDataSaver = () => {
    let payload = {
      customerId,
      data: {
        enable: modalValue === SettingsCardsEnum.Enable ? true : false,
      },
    };
    dispatch(dataSaver(payload));
  };
  const handleWifiUpdate = () => {
    let zip = wifiAddress?.zip.slice(0, 5);
    let payload = {
      customerId,
      data: {
        enable: false,
        e911Address: { ...wifiAddress, zip },
      },
    };
    dispatch(wifiUpdate(payload));
  };
  const handleClick = () => {
    setIsLoading(true);
    if (modalType === SettingsCardsEnum.LANGUAGE) {
      selectLanguage();
    }
    if (modalType === SettingsCardsEnum.PASSWORD) {
      handlePassword();
    }
    if (modalType === SettingsCardsEnum.DATA_SAVER) {
      handleDataSaver();
    }
    if (modalType === SettingsCardsEnum.WIFI_CALLING) {
      handleWifiUpdate();
    }
    if (modalType === SettingsCardsEnum.ENABLE_WIFI) {
      onClose(false);
      setIsLoading(false);
      NotificationCust({
        message: modalValue,
        type: "info",
        duration: 2000,
        id: "WifiEnableSettings",
        showIcon: true,
      });
    }
  };
  const handleClose = () => {
    setIsLoading(false);
    onClose(false);
    if (modalType === SettingsCardsEnum.ENABLE_WIFI) {
      NotificationCust({
        message: modalValue,
        type: "info",
        duration: 2000,
        id: "LanguageUpdateSettings",
        showIcon: true,
      });
    }
  };
  return (
    <>
      <SimpleModal
        isShowModal={commonModal}
        onCloseModal={handleClose}
        showClose={true}
        sx={{
          width: { xs: "90%", sm: "60%", md: "80%", lg: "35%", xl: "35%" },
        }}
      >
        <Grid
          container
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Typography
            sx={{
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_4)",
              fontSize: "22px",
              pb: "16px",
            }}
          >
            {content?.ttl}
          </Typography>
          <Divider styles={{ mb: "20px" }}></Divider>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_1)",
              fontSize: "14px",
              pb: "20px",
            }}
          >
            {`${content?.dsc}`}
            {modalType === SettingsCardsEnum.LANGUAGE ? ` ${modalValue}.` : ""}
          </Typography>
          <ButtonCust
            onClick={handleClick}
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            loading={isLoading}
          >
            {content?.btn}
          </ButtonCust>
        </Grid>
      </SimpleModal>
    </>
  );
};

export default CommonModal;
