import { useEffect, useState } from "react";
import Config from "../../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { donationsContent } from "../../../../features/strapi/postActivationHomeSlice";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
const DonationsComponent = isTemplateExisits(Config.PostActivationHome.Donations)?
    require(`./${Config.PostActivationHome.Donations.template}_Donations`).default:null;

export const Donations = () => {
    const [cacheContent, setCacheContent] = useState<any>(null);
    const { donations } = useAppSelector(
        (state: any) => state.strapi?.postActivation
    );
    const dispatch = useAppDispatch();
    useEffect(() => {
        getCache('DonationsPAHome', donationsContent, setCacheContent, dispatch)
    }, []);

    useNonInitialEffect(() => {
        addMultipleCacheData('DonationsPAHome', donations)
    }, [donations])

    return (cacheContent || donations) && <DonationsComponent content={cacheContent? cacheContent: donations} />;
};
