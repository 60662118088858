import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./S1_ShippingAddress.module.scss";
import {
  ButtonCust,
  LinearProgressBarCust,
  SelectCust,
} from "../../../../widgets";
import Config from "../../../../../config/env.config.json";
import AddIcon from "@mui/icons-material/Add";
import { AddShippingAddressDialog } from "../AddShippingAddressDialog/AddShippingAddressDialog";
import { ShippingComponentProps } from "./ShippingAddress";

const ShippingComponent = (props: ShippingComponentProps) => {
  const {
    shippingAddress,
    sendSimOptions,
    setShipPlan,
    shipPlan,
    updateAddress,
    secondaryLineAddress,
    checkHandler,
    loader,
    handleLinesTab,
    content,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeSimOptions = (e: any) => {
    sendSimOptions?.forEach((operator: any) => {
      if (e.target.value === operator.value) {
        setShipPlan(operator);
      }
    });
  };
  const openHandleModal = (_: any) => {
    setOpenDialog(true);
  };
  const getSecondaryAddress = () => {
    const { address1, city, zip, state } = secondaryLineAddress
      ? secondaryLineAddress?.billingAddress
      : shippingAddress;

    return `${address1}, ${city}, ${zip}, ${state}`;
  };

  let currentUser: any = localStorage.getItem("customerDetail");
  currentUser = JSON.parse(currentUser);
  return (
    content && (
      <>
        <Grid container padding="20px">
          <Box>
            <ArrowBackIcon
              sx={{ ml: { md: "30px", sm: "10px", xs: "20px" }, width: "18px" }}
              className={styles.arrow_icon}
              onClick={() => handleLinesTab(1)}
            />
          </Box>
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              className={styles.title_main}
              sx={{
                fontSize: "22px",
                fontFamily: "var(--font_family_Bold)",
                lineHeight: 1,
                fontWeight: "var(--page_header_font_weight)",
              }}
            >
              {content?.choose_add}
            </Typography>
            <Typography className={styles.stepper_txt}>
              {content?.step}
            </Typography>
            <Box width={{ xs: "90%", sm: "50%", md: "35%" }}>
              <LinearProgressBarCust value={100} variant={"determinate"} />
            </Box>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "24px 0",
              }}
            >
              <Box
                sx={{
                  fontFamily: "var(--font-family-medium)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_1)",
                  color: "var(--text_color)",
                }}
              >
                {content?.where_to_ship}
              </Box>
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                  pt: "20px",
                  color: "var(--text_color)",
                  textAlign: "center",
                  border: "1px solid rgb(0,0,0,0.24)",
                  borderRadius: "6px",
                  marginTop: "10px",
                  padding: "10px 26px",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "14px",
                    fontWeight: "var(--font_weight_2)",
                    mb: "-14px",
                  }}
                >
                  {secondaryLineAddress
                    ? secondaryLineAddress.firstName
                    : currentUser?.firstName}
                </Box>
                <br />
                {getSecondaryAddress()}
              </Box>
              <Box
                sx={{
                  fontFamily: "var(--font_family_Bold)",
                  fontSize: "16px",
                  fontWeight: "var(--font_weight_5)",
                  color: "var(--primary_color)",
                  pt: "10px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  verticalAlign: "middle",
                  marginLeft: "92px",
                  marginBottom: "5px",
                }}
                onClick={openHandleModal}
              >
                <Box
                  component="span"
                  sx={{
                    fontSize: "25px",
                    mr: "5px",
                    fontFamily: "var(--font_family_Bold)",
                  }}
                >
                  <AddIcon />
                </Box>
                {content?.Add_new_add}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                  padding: "12px 0 10px 0",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Bold)",
                    fontSize: "14px",
                    fontWeight: "var(--font_weight_4)",
                    // textAlign:"left"
                  }}
                >
                  {content?.ship_option}
                </Box>
                <Box margin="10px 0 20px 0">
                  {content?.select_ship && (
                    <Box
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        fontSize: "15px",
                        fontWeight: "var(--font_weight_2)",
                        margin: "10px 0 5px 0",
                        color: "var(--title-color_1)",
                      }}
                    >
                      {content?.select_ship}
                    </Box>
                  )}
                  <SelectCust
                    options={sendSimOptions}
                    value={shipPlan?.value}
                    onChange={handleChangeSimOptions}
                  />
                </Box>
                <Box
                  sx={{
                    fontFamily: "var(--font-family-medium)",
                    fontSize: "14px",
                    fontWeight: "var(--font_weight_1)",
                    color: "var(--text_color)",
                    pt: "12px",

                    textAlign: "center",
                  }}
                >
                  {content?.card_charge}
                </Box>
              </Box>
              <Box>
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={checkHandler}
                  loading={loader}
                >
                  {content?.check}
                </ButtonCust>
              </Box>
              <AddShippingAddressDialog
                content={content}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                updateAddress={(e: any) => {
                  updateAddress(e);
                  setOpenDialog(false);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    )
  );
};

export default ShippingComponent;
