import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
    ourPartners: any;
    errorOurPartners: any;
    meetTheRecipients: any;
    errorMeetTheRecipients: any;
    howItWorks: any;
    errorHowItWorks: any;
    giveBackTPContent: any;
    errorGiveBackTPContent: any;
};

const initialState: InitialState = {
    ourPartners: null,
    errorOurPartners: null,
    meetTheRecipients: null,
    errorMeetTheRecipients: null,
    howItWorks: null,
    errorHowItWorks: null,
    giveBackTPContent: null,
    errorGiveBackTPContent: null
};

// Generates pending, fulfilled and rejected action types
export const ourPartnersContent = createAsyncThunk(
    "giveBack/ourPartnersContent",
    () => {
      return apiJunction.makeRequest({
        method: "get",
        url: `/${Config.GiveBack.PartnersList.template}${StrapiApiEndPoints.ourPartners}`,
        isStrapiApi: true,
      });
    }
);
export const meetTheRecipientsContent = createAsyncThunk(
  "giveBack/meetTheRecipientsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.GiveBack.MeetReceipents.template}${StrapiApiEndPoints.meetTheRecipients}`,
      isStrapiApi: true,
    });
  }
);

export const howItWorksContent = createAsyncThunk(
  "giveBack/howItWorksContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.GiveBack.HowItWorks.template}${StrapiApiEndPoints.howItWorks}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const gbTpContent = createAsyncThunk(
  "giveBack/gbTpContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.GiveBack.TrustPilot.template}${StrapiApiEndPoints.tpGiveBack}`,
      isStrapiApi: true,
    });
  }
);

const giveBackSlice = createSlice({
    name: "giveBack",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(ourPartnersContent.fulfilled, (state, action: PayloadAction<any>) => {
        state.ourPartners = action.payload?.data?.data?.attributes;
        state.errorOurPartners = null;
      });
      builder.addCase(ourPartnersContent.rejected, (state, action) => {
        state.ourPartners = null;
        state.errorOurPartners = action.error || "Something went wrong";
      });
      builder.addCase(meetTheRecipientsContent.fulfilled, (state, action: PayloadAction<any>) => {
        state.meetTheRecipients = action.payload?.data?.data?.attributes;
        state.errorMeetTheRecipients = null;
      });
      builder.addCase(meetTheRecipientsContent.rejected, (state, action) => {
        state.meetTheRecipients = null;
        state.errorMeetTheRecipients = action.error || "Something went wrong";
      })
      builder.addCase(howItWorksContent.fulfilled, (state, action: PayloadAction<any>) => {
        state.howItWorks = action.payload?.data?.data?.attributes;
        state.errorHowItWorks = null;
      });
      builder.addCase(howItWorksContent.rejected, (state, action) => {
        state.howItWorks = null;
        state.errorHowItWorks = action.error || "Something went wrong";
      });
      builder.addCase(gbTpContent.fulfilled, (state, action: PayloadAction<any>) => {
        state.giveBackTPContent = action.payload?.data?.data?.attributes;
        state.errorGiveBackTPContent = null;
      });
      builder.addCase(gbTpContent.rejected, (state, action) => {
        state.giveBackTPContent = null;
        state.errorGiveBackTPContent = action.error || "Something went wrong";
      });
    },
});

export default giveBackSlice.reducer;
