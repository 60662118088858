import { useEffect, useState } from "react";
import Config from "../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { getOutOfDataContent } from "../../features/strapi/myAccountSlice";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";

const OutOfDataComponent = isTemplateExisits(Config.Account.OutOfData)
  ? require(`./${Config.Account.OutOfData.template}_OutOfData`).default
  : null;
export type OutOfDataProps = {
  content?: any;
  open: boolean;
  closeDialog: any;
  handleSubmit: any;
};
export type ModalProps = {
  open: boolean;
  closeDialog?: any;
  handleSubmit?: any;
};

export const OutOfData = (props: ModalProps) => {
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { outOfDataContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache('OutOfData', getOutOfDataContent, setCacheContent, dispatch)
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData('OutOfData', outOfDataContent)
  }, [outOfDataContent]);
  return (cacheContent || outOfDataContent) && <OutOfDataComponent content={cacheContent? cacheContent: outOfDataContent} {...props} />;
};
