import firebase from "../adapters/firebase";
import { RoutingURLs } from "../config/RoutingURLs";
export const logout = async (redirectURL?: string) => {
  return await firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.clear();
      window.open(redirectURL? redirectURL : RoutingURLs.default, "_self");
    })
    .catch((err) => {
      console.log(err);
    });
};
