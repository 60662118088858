import EnvConfig from "../../config/env.config.json";
declare global {
  interface Window {
    gtag: any;
  }
}
export function pushTrackingEventsToGAAndFB(
  event: string,
  eventName: string,
  eventInfo: {
    appName: any;
    screenName: any;
    category?: any;
    buttonValue?: any;
  }
) {
  try {
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      window.gtag(event, eventName, {
        app_name: eventInfo.appName,
        screen_name: eventInfo.screenName,
        category: eventInfo.category || "",
        button_value: eventInfo.buttonValue || "",
      });
  } catch (_e) {
    return null;
  }
}
