import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { SimpleModal } from "../../../widgets";
import styles from "./DataPurchaseDialog.module.scss";

type DataPurchaseProps = {
  open: boolean;
  onClose: any;
  cppdContent?: any;
  groupTopUp: any;
  group: any;
};
//data from api
export const DataPurchaseDialog = (props: DataPurchaseProps) => {
  const { open, onClose, cppdContent, groupTopUp, group } = props;
  const { data_used, purchase_history, users_line } =
    cppdContent?.purchase_dialog;
  return (
    <>
      <SimpleModal
        isShowModal={open}
        onCloseModal={onClose}
        showClose={true}
        className={styles.data_purchase_history_dialog}
        sx={{
          borderRadius: "4.8px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "600px !important",
          maxWidth: { xs: "95% !important" },
        }}
      >
        <Box className={styles.data_purchase_history_main}>
          <Typography className={styles.data_purchase_history_heading}>
            {purchase_history}
          </Typography>
          <Box className={styles.data_purchase_history_container}>
            {groupTopUp &&
              groupTopUp.PURCHASED &&
              groupTopUp.PURCHASED.length > 0 &&
              groupTopUp.PURCHASED.map((el: any, index: number) => {
                let name = group?.data?.find(
                  (n: any) => n.id === el.customerId
                );
                return (
                  <Box
                    className={styles.data_purchase_history_card}
                    key={index}
                  >
                    <Typography className={styles.data_purchase_card_details}>
                      {name && name.firstName}
                      {users_line}
                    </Typography>
                    <Typography
                      className={clsx(
                        styles.data_purchase_card_details,
                        styles.data_and_date_sm
                      )}
                    >
                      {el && el.totalData}
                      {data_used}
                    </Typography>
                    <Typography
                      className={clsx(
                        styles.data_purchase_card_details,
                        styles.data_and_date_sm
                      )}
                    >
                      {el &&
                        el.topupDate &&
                        new Date(el.topupDate).toLocaleString("en-us", {
                          month: "short",
                        })}{" "}
                      {el && el.topupDate && new Date(el.topupDate).getDate()},{" "}
                      {el &&
                        el.topupDate &&
                        new Date(el.topupDate).getFullYear()}
                    </Typography>
                    <Box className={styles.data_and_date_xs}>
                      <Typography className={styles.data_purchase_card_details}>
                        {el && el.totalData}
                        {data_used}
                      </Typography>
                      <Typography className={styles.data_purchase_card_details}>
                        {el &&
                          el.topupDate &&
                          new Date(el.topupDate).toLocaleString("en-us", {
                            month: "short",
                          })}{" "}
                        {el && el.topupDate && new Date(el.topupDate).getDate()}
                        ,{" "}
                        {el &&
                          el.topupDate &&
                          new Date(el.topupDate).getFullYear()}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </SimpleModal>
    </>
  );
};
