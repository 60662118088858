import { Box, Grid } from "@mui/material";
import styles from "./S1_Brands.module.scss";

export type imageAttributes = {
  id: number;
  attributes: {
    name: string;
    ext: string;
    formats: string;
    alternativeText?: null | string;
    caption?: null | string;
    createdAt: string;
    updatedAt: string;
    hash: string;
    width: number;
    height: number;
    mime: string;
    previewUrl?: null | string;
    provider: string;
    provider_metadata?: null;
    size: number;
    url?: string;
  };
};

interface PartnerComponentProps {
  data: Array<imageAttributes>;
  mobileBgImage: imageAttributes;
  desktopOrIPadBgImage: imageAttributes;
}

const PartnerComponent = (props: PartnerComponentProps) => {
  const { data, mobileBgImage, desktopOrIPadBgImage } = props;

  return (
    <Box
      sx={{
        p:{
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        },
        margin: "0px",
      }}
    >
      <Grid
        container
        className={styles.aboutus_partners_block}
        sx={{
          background: {
            sm: `url(${desktopOrIPadBgImage.attributes.url}) no-repeat center`,
            xs: `url(${mobileBgImage.attributes.url}) no-repeat center`,
          },
          width: "100%",
          backgroundSize: { xs: "contain", sm: "contain" },
        }}
      >
        <Grid
          item
          xs={12}
          className={styles.image_wrapper}
          p={{ md: 3 }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          {data.map((image: imageAttributes, index: number) => {
            return (
              <Grid
                item
                key={image.id}
                xs={12}
                sm={4}
                display="flex"
                justifyContent="center"
                sx={{
                  paddingBottom: { xs: "8rem", sm: "0rem", md: "1rem" },
                }}
                className={
                  index === 0
                    ? styles.brand_imnage_la
                    : index === 2
                    ? styles.brand_imnage_ra
                    : ""
                }
              >
                <Box
                  component="img"
                  alt={image.attributes?.alternativeText || ""}
                  height="37px"
                  width="auto"
                  src={image.attributes?.url || ""}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerComponent;
