import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  privacyPolicyContent: any;
  errorPrivacyPolicyContent: any;
};

const initialState: InitialState = {
  privacyPolicyContent: null,
  errorPrivacyPolicyContent: null,
};

// Generates pending, fulfilled and rejected action types
export const privacyContent = createAsyncThunk("privacy", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Privacy.Policies.template}${StrapiApiEndPoints.privacy}`,
    isStrapiApi: true,
  });
});

const strapiPrivacySlice = createSlice({
  name: "privacy",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      privacyContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.privacyPolicyContent = action.payload;
        state.errorPrivacyPolicyContent = null;
      }
    );
    builder.addCase(
      privacyContent.rejected,
      (state: InitialState, action: any) => {
        state.privacyPolicyContent = null;
        state.errorPrivacyPolicyContent = action.error || "Something went wrong";
      }
    );
  },
});

export default strapiPrivacySlice.reducer;
