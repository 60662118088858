import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { profileContent } from "../../../features/strapi/profileSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const ProfileComponent = isTemplateExisits(Config.Profile.ProfileDetailsForm)
  ? require(`./${Config.Profile.ProfileDetailsForm.template}_ProfileDetails`)
      .default
  : null;

export const ProfileDetails = () => {
  const [cacheDataProfile, setCacheDataProfile] = useState<any>(null);
    const { profileDetailsContent } = useAppSelector((state: any) => state.strapi.profile);
    const dispatch = useAppDispatch();
    useEffect(() => {
      getCache("Profile", profileContent, setCacheDataProfile, dispatch)
    }, []);
    useNonInitialEffect(() => {
      addMultipleCacheData('Profile', profileDetailsContent)
    }, [profileDetailsContent])
  return (cacheDataProfile || profileDetailsContent) && <ProfileComponent content={cacheDataProfile? cacheDataProfile: profileDetailsContent} />;
};
