import { useState } from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./S1_ActivationStep2.module.scss";
import { LinearProgressBarCust, ButtonCust } from "../../../widgets";
import { NewNumberModal } from "./NewNumberModal";
import { gaCategory } from "../../../../Types/GoogleAnalytics";
import CurrentNumberForm from "./CurrentNumberForm";
import PinHelperText from "./PinHelperText";
import AccountPinHelperText from "./AccountPinHelperText";
import Config from "../../../../config/env.config.json";

const ActivationStep2 = (props: any) => {
  const {
    currentLine,
    content,
    GAAndFBEventsHandler,
    operators,
    currentOperator,
    zipcodeCheckHandler,
    setOpenSupportDialog,
    clickHandlerForPortNum,
    currentNum,
    handleBack,
    newNumHandler,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const { sp2_title, c_num, step_txt, num_pref } = content;
  return (
    <Box className={styles.step2_main}>
      <Box className={styles.heading_container}>
        <ArrowBackIcon
          sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
          className={styles.arrow_icon}
          onClick={handleBack}
        />
        <Typography className={styles.title_main}>
          {currentNum ? c_num.title : sp2_title}
        </Typography>
      </Box>
      <Box className={styles.stepper_content}>
        <Typography className={styles.stepper_txt}>{step_txt}</Typography>
        <Box width={{ xs: "90%", sm: "50%", md: "35%" }} mb="25px">
          <LinearProgressBarCust value={50} variant={"determinate"} />
        </Box>
      </Box>
      <Box width={{ xs: "90%", sm: "48%" }} m="0 auto 40px">
        <Box
          className={styles.inner_container}
          p={{ xs: "30px 15px", sm: "30px 50px" }}
        >
          {!currentNum ? (
            /* step 2 option selection */
            <>
              <Typography className={styles.card_title}>
                {num_pref?.desc}
              </Typography>
              <Box mb="16px" display="flex" justifyContent="center">
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  sx={{ width: "220px" }}
                  onClick={() => {
                    clickHandlerForPortNum();
                    GAAndFBEventsHandler(
                      gaCategory.activation,
                      num_pref?.c_num_btn
                    );
                  }}
                >
                  {num_pref?.c_num_btn}
                </ButtonCust>
              </Box>
              <Typography className={styles.card_description} mb="30px">
                {num_pref?.txt1}
              </Typography>
              <Box mb="16px" display="flex" justifyContent="center">
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  sx={{ width: "220px" }}
                  onClick={() => {
                    setOpenDialog(true);
                    newNumHandler(),
                      GAAndFBEventsHandler(
                        gaCategory.activation,
                        num_pref?.new_num_btn
                      );
                  }}
                >
                  {num_pref?.new_num_btn}
                </ButtonCust>
              </Box>
              <Typography className={styles.card_description}>
                {num_pref?.txt2}
              </Typography>
            </>
          ) : (
            <CurrentNumberForm styles={styles} {...props} />
          )}
        </Box>
      </Box>
      {currentNum && !currentOperator?.acctNumMsg && (
        <Box width={{ xs: "90%", sm: "50%" }} m="0 auto">
          <PinHelperText
            c_num={c_num}
            styles={styles}
            setOpenSupportDialog={setOpenSupportDialog}
          />
        </Box>
      )}
      {currentNum && (
        <Box width={{ xs: "90%", sm: "50%" }} m="0 auto">
          <AccountPinHelperText
            currentOperator={currentOperator}
            styles={styles}
            setOpenSupportDialog={setOpenSupportDialog}
            {...props}
          />
        </Box>
      )}
      <NewNumberModal
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        content={content}
        zipCodeformik={props.zipCodeformik}
        zipcodeChangeHandler={props.zipcodeChangeHandler}
        {...props}
      />
    </Box>
  );
};
export default ActivationStep2;
