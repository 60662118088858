export const getLineCharges = (planDetails: any) => {
  if (planDetails?.numberOfLines && planDetails.selectedPlan?.baseLinePrice) {
    return (
      Number(planDetails.selectedPlan.baseLinePrice)
    );
  } else {
    return 0;
  }
};

export const getAdditionalLineCharges = (planDetails: any) => {
  const additionalNumberOfLines = planDetails.numberOfLines - 1
  if (
    planDetails?.numberOfLines > 1 &&
    planDetails?.selectedPlan?.additionalLinePrice >= 0
  ) {
    if(planDetails?.selectedPlan?.addLineSplitPrice){
      let additionalLinePrices: number = 0;
      const { addLineSplitPrice }: { addLineSplitPrice: object } = planDetails?.selectedPlan;
      for (let index = 0; index < additionalNumberOfLines; index++) {
        additionalLinePrices =
          additionalLinePrices + Object.values(addLineSplitPrice)[index];
      }
      return additionalLinePrices;
    }
    else{
      return (
        Number(additionalNumberOfLines) *
        Number(planDetails?.selectedPlan?.additionalLinePrice)
      );
    }
  } else {
    return 0;
  }
};

export const getPlanTaxableAmount =(planDetails:any)=>{
  return getLineCharges(planDetails)+getAdditionalLineCharges(planDetails)
}

