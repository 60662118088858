import { Box, Typography } from "@mui/material";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import styles from "./S5_privacy.module.scss";
type Props = {
  content: any;
};
const T5_Privacy = (props: Props) => {
  const { content } = props;
  return (
    <Box className={styles.privacy_main_container}>
      <Box className={styles.privacy_content}>
        <Typography variant="h4" className={styles.privacy_title}>
          {BrandNameUpdate(content?.main_title)}
        </Typography>
        <Typography variant="h4" className={styles.privacy_title}>
          {BrandNameUpdate(content?.title)}
        </Typography>
        <Box className={styles.privacy_line} />
        <Box
          className={styles.privacy_text + " " + styles.privacy_top_para}
          dangerouslySetInnerHTML={{
            __html: BrandNameUpdate(content?.privacy_top),
          }}
        />
        {content &&
          content?.privacy_content.length > 0 &&
          content?.privacy_content.map(
            (
              items: {
                heading: any;
                description: any;
              },
              index: number
            ) => {
              return (
                <Box key={index}>
                  <Typography variant="h3" className={styles.privacy_heading}>
                    {index + 1}. {BrandNameUpdate(items?.heading)}
                  </Typography>
                  <Box
                    className={styles.privacy_text}
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(items?.description),
                    }}
                  />
                </Box>
              );
            }
          )}
      </Box>
    </Box>
  );
};
export default T5_Privacy;
