import styles from "./S1_Letter.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { LetterProps } from "./Letter";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
const LetterComponent = (props: LetterProps) => {
  const {
    title,
    description,
    image_desktop,
    image_ipad,
    image_mobile,
    ceo_name,
  } = props.content || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  return (
    props.content && (
      <Grid
        container
        className={styles.letter_grid_container}
        sx={{
          p:{
            xs: "20px",
            sm: "25px",
            md: "50px",
          },
          margin: { 
            md: "calc(var(--desktop_section_padding)/2) 60px 0px",
            sm: "calc(var(--ipad_section_padding)/2) 40px 0", 
            xs: "calc(var(--mobile_section_padding)/2) 0 0",},
          flexDirection: { md: "row", xs: "column" },
          width: "fit-content",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          md={7}
          xs={12}
          className={styles.letter_text_grid}
          sx={{
            alignItems: {
              lg: "flex-start",
              xs: "center",
            },
            p: {xs:"20px", sm: "0 30px 0 0"},
          }}
        >
          <Typography variant="h6" className={styles.letter_title}>
            {title || ""}
          </Typography>
          <Box className={styles.letter_title_line}></Box>
          <Typography
            textAlign={{
              md: "left",
              sm: "center",
              xs: "center",
            }}
            className={styles.letter_description}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></Typography>
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
          className={styles.ceo_image_grid}
          sx={{
            px: { xs: "12px", md: "0px" },
            width: "fit-content",
            alignSelf: "center",
          }}
        >
          {(largeDesktop || desktop) &&
            generateImageContainer(
              {data:image_desktop})}
          {ipad &&
            generateImageContainer(
              {data:image_ipad,
                attrs: { height: "445px", width: "428px" }}
            )}
          {mobile &&
            generateImageContainer(
             {data:image_mobile,
              attrs: { height: "303px", width: "316px" }}
            )}
          <Typography
            sx={{
              display: { sm: "none", md: "block" },
            }}
            className={styles.ceo_name}
            dangerouslySetInnerHTML={{
              __html: ceo_name,
            }}
          ></Typography>
        </Grid>
      </Grid>
    )
  );
};
export default LetterComponent;
