import { Box, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import styles from "./S2_InitialActivation.module.scss";
import Config from "../../../../config/env.config.json";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import ENVConfig from "../../../../config/env.config.json";
import { brandNames } from "../../../../enums";

const T2_InitialActivation = (props: any) => {
  const { handleStep, content, currentLine } = props;
  const noOfLines = currentLine?.extraLines + 1;
  return (
    <>
      <Box display="flex" alignItems="center" pt={{ xs: "25px", sm: "50px" }}>
        <ArrowBackIcon
          sx={{
            ml: { md: "30px", sm: "25px", xs: "4%" },
            mr: "20px",
            cursor: "pointer",
          }}
          onClick={() => handleStep(false)}
        />
        <Typography variant="h3" fontSize="30px !important">
          {content?.main_title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", sm: "column", xs: "column-reverse" },
        }}
      >
        <Box
          sx={{
            flexBasis: { md: "50%" },
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            p: { md: "72px 0px 50px 100px", xs: "50px 20px 0px 20px" },
          }}
        >
          <Box>
            <Box
              component="img"
              sx={{ width: { xs: "100%", sm: "478px" } }}
              src={content?.act_img?.data?.attributes?.url}
              alt={content?.act_img?.data?.attributes?.alternativeText}
            />
          </Box>
        </Box>
        <Box
          className={styles.right_div}
          sx={{
            flexBasis: { md: "50%" },
            p: { sm: "16px 0 50px 96px", xs: "25px" },
          }}
        >
          <Box sx={{ textAlign: { md: "left", sm: "center", xs: "left" } }}>
            <Typography
              className={styles.title}
              sx={{
                fontSize: { xs: "22px", sm: "30px" },
                m: { xs: " 20px 0 15px !important" },
              }}
            >
              {currentLine?.isPrimary
                ? content?.intro_title
                : BrandNameUpdate(content?.intro_title2)?.replace(
                    "{name}",
                    currentLine?.firstName
                  )}
            </Typography>
            {
              <Typography
                className={styles.description}
                sx={{ fontSize: { xs: "15px", sm: "18px" } }}
              >
                {currentLine?.isPrimary
                  ? ENVConfig.DOMAIN !== brandNames.BRAND
                    ? content?.intro_desc?.replace("{0}", noOfLines)
                    : null
                  : BrandNameUpdate(content?.intro_desc2)?.replace(
                      "{name}",
                      currentLine?.firstName
                    )}
              </Typography>
            }
          </Box>
          <Box className={styles.step_container} mt={5}>
            {content?.intro_pnts?.map((data: any, index: number) => {
              return (
                <Box display={"flex"} pb={"24px"} key={index}>
                  <Box
                    className={styles.step_icon}
                    sx={{ mr: { xs: "24px", sm: "48px" } }}
                  >
                    <Box
                      component={"img"}
                      src={data?.icon?.data?.attributes?.url}
                      sx={{ height: "38px" }}
                      alt={data?.icon?.data?.attributes?.alternativeText}
                    />
                    {ENVConfig.DOMAIN !== brandNames.BRAND && (
                      <Box
                        className={`${index !== 3 && styles?.step_icon_line}`}
                      ></Box>
                    )}
                  </Box>
                  <Box className={styles.step_content} sx={{ sm: "16px" }}>
                    <Typography
                      className={styles.step_title}
                      sx={{
                        mt: data?.desc ? "0px" : "12px",
                        mb: data?.desc ? "0.5rem" : "0px",
                      }}
                    >
                      {data?.title}
                    </Typography>
                    <Typography
                      className={styles.step_description}
                      sx={{ width: { sm: "80%" } }}
                    >
                      {BrandNameUpdate(data?.desc)}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
            <Box sx={{ pt: "10px", textAlign: { xs: "center", sm: "left" } }}>
              <ButtonCust
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => handleStep(true)}
              >
                {currentLine?.isPrimary ? content?.intro_btn : `Let's Go`}
              </ButtonCust>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default T2_InitialActivation;
