import { Typography } from "@mui/material";
import styles from "./S2_payNow.module.scss";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import { useAppSelector } from "../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { PayNowPlanProps } from "./PayNowPlan";

type Props = PayNowPlanProps;

function PayNowPlan(props: Props) {
  const { content, plan, currentLine } = props;
  const { monthInAdvanceData } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  return (
    <>
      <Typography className={styles.select_title}>
        {currentLine?.reachPlanDisplayName}
      </Typography>
      <Typography className={styles.select_desc}>
        {BrandNameUpdate(content?.pay_now?.plan_desc)}
      </Typography>
      <Typography className={styles.select_price}>
        {GetCurrencyFixedToTwo(
          monthInAdvanceData?.promoDiscount
            ? (!currentLine?.isPrimary
                ? plan?.additionalLinePrice
                : plan?.baseLinePrice) - monthInAdvanceData?.promoDiscount
            : !currentLine.isPrimary
            ? plan?.additionalLinePrice
            : plan?.baseLinePrice
        )}
        <span className={styles.sub_tag}>{content?.pay_now?.per_line}</span>
      </Typography>
      <Typography className={styles.sharable}>
        -{" "}
        {plan?.isUnlimited
          ? content?.pay_now?.non_shareable_txt
          : content?.pay_now?.shareable_txt}
      </Typography>
    </>
  );
}

export default PayNowPlan;
