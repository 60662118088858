import { GbtpProps } from "./Gbtp";
import styles from "./S1_Gbtp.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { TrustPilot, TrustPilotType } from "../../common";
const GbtpComponent = (props: GbtpProps) => {
  const { heading } = props?.content || {};
  return (
    <Box
      sx={{
        p:{
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        }
      }}
    >
      <Grid
        container
        className={styles.gbtp}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          item
          xs={12}
          className={styles.trsut_pilot_content}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: "50px",
          }}
        >
          <Box>
            <Typography
              variant="body1"
              textAlign="center"
              fontSize={{ xs: "22px", sm: "26px" }}
              className={styles.trsut_pilot_content_title}
            >
              {heading || ""}
            </Typography>
          </Box>
          <Box sx={{ my: 2.5 }} className={styles.trsut_pilot_line}></Box>
        </Grid>
        <Grid item xs={12}>
          <TrustPilot type={TrustPilotType.LIST} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default GbtpComponent;
