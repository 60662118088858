import styles from "./S1_ContactUs.module.scss";
import { ButtonCust } from "../widgets";
import Config from "../../config/env.config.json";
import { Box, Grid, Link } from "@mui/material";
import { formatPhoneNumber } from "../../utils/commonFunctions/ReusableFunctions";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
type Props = {
  strapiContent: any;
  onClickHandler: any;
  strapiGlobalValues: any;
};

const ContactUsComponent = (props: Props) => {
  const { strapiContent, onClickHandler, strapiGlobalValues } = props;
  const {
    page_header,
    help_center,
    support,
    address_details,
    chat_icon,
    mail_icon,
    phone_icon,
    logo,
  } = strapiContent;
  const { phoneNumber, email, partner_email1, partner_email2 } =
    strapiGlobalValues;
  const { hc_header, hc_description, hc_button } = help_center || {};
  const { navigationPath, page, text } = hc_button || {};
  const { s_header1, s_header2, chat_text, timings, email_brand_text } =
    support || {};
  const ChatIcon = {
    img: chat_icon?.data?.attributes?.url,
    alt: chat_icon?.data?.attributes?.alternativeText,
  };
  const phoneIcon = {
    img: phone_icon?.data?.attributes?.url,
    alt: phone_icon?.data?.attributes?.alternativeText,
  };
  const emailIcon = {
    img: mail_icon?.data?.attributes?.url,
    alt: mail_icon?.data?.attributes?.alternativeText,
  };
  const addressLogo = {
    img: logo?.data?.attributes?.url,
    alt: logo?.data?.attributes?.alternativeText,
  };
  const {
    help_support,
    line,
    help_center_main,
    support_main,
    support_header_text,
    help_center_header,
    links,
    chat_icon_size,
    support_time,
    contact_address_text,
    email_icon_size,
    phone_icon_size,
  } = styles;

  const linkRender = (
    hRef: any,
    textValue: any,
    gaText?: any,
    phoneFg?: boolean
  ) => {
    return (
      <Link
        href={hRef}
        rel="noopener"
        className={links}
        underline="none"
        fontSize="14px"
        onClick={(e: any) => (gaText ? onClickHandler(gaText, e) : null)}
      >
        {phoneFg ? formatPhoneNumber(textValue) : textValue}
      </Link>
    );
  };
  const emailIConRender = () => {
    return (
      <Box
        component="img"
        src={emailIcon.img}
        alt={emailIcon.alt}
        height="17px"
        width="23px"
        className={email_icon_size}
        mr={0.7}
      ></Box>
    );
  };
  return (
    <Grid container className={help_support}>
      <Grid
        container
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: "#ececec",
          px: { xs: 1.8, sm: 2, md: 2.5, lg: 4, xl: 0 },
          minWidth: {
            xs: "308px",
            sm: "601px",
            md: "900px",
            lg: "1025px",
          },
          minHeight: {xs: "1155.76px", md: "733.11px"}
        }}
        pt={{ md: 5, xs: 15 }}
      >
        <Grid
          item
          sx={{
            minWidth: { xs: "308px", sm: "601px", md: "900px", lg: "1025px" },
            minHeight: {xs: "60.9px"}
          }}
        >
          <Box textAlign={{ xs: "center" }}>
            <h3>{page_header}</h3>
          </Box>
          <Box
            className={line}
            mb={2.5}
            mt={1}
            mx="auto"
            sx={{ width: 80, height: 3 }}
          ></Box>
        </Grid>
        <Grid
          container
          sx={{
            px: { xs: 2, sm: 10, md: 8, lg: 14 },
            pt: { xs: 3, sm: 3.5, md: 6 },
            minWidth: { xs: "308px", sm: "601px", md: "900px", lg: "1025px" },
          }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Grid
            item
            md={5.8}
            xs={12}
            className={help_center_main}
            p={3}
            mb={{ xs: 3.7, md: 0 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minWidth: { xs: "300px", sm: "441px", md: "373px", lg: "479px" },
              minHeight: { xs: "269.17px"}
            }}
          >
            <Box className={help_center_header} pb={0.6} sx={{minHeight: { xs: "25.49px"}}}>
              {" "}
              {BrandNameUpdate(hc_header)}
            </Box>
            <Box sx={{minHeight: { md: "55.2px"}}}>{BrandNameUpdate(hc_description)}</Box>
            {text?<Box>
              <ButtonCust
                sx={{ width: "200px", mt: 1, minHeight: { xs: "45px"} }}
                variantType={Config.SECONDARY_BUTTON_TYPE}
                onClick={(e: any) =>
                  onClickHandler("Visit Help Center", e, navigationPath, page)
                }
              >
                {text}
              </ButtonCust>
            </Box>:null}
          </Grid>
          <Grid
            item
            md={5.8}
            xs={12}
            className={support_main}
            fontSize="14px"
            p={3}
            sx={{
              minWidth: { xs: "300px", sm: "441px", md: "373px", lg: "479px" },
              minHeight: { xs: "269.17px"}
            }}
          >
            <Grid item className={support_header_text} pb={0.6}>
              {s_header1}
            </Grid>

            <Grid
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Grid item>
                <Box
                  component="img"
                  src={ChatIcon.img}
                  alt={ChatIcon.alt}
                  height="24px"
                  width="24px"
                  className={chat_icon_size}
                  mr={0.7}
                ></Box>
                <Box component="span">
                  {linkRender("#hs-chat-open", chat_text, chat_text)}
                </Box>
              </Grid>
              <Grid item>
                <Box
                  component="img"
                  src={phoneIcon.img}
                  alt={phoneIcon.alt}
                  height="20px"
                  width="20px"
                  mr={0.7}
                  className={phone_icon_size}
                ></Box>
                <Box component="span">
                  {linkRender(
                    `tel:${phoneNumber}`,
                    phoneNumber,
                    phoneNumber,
                    true
                  )}
                </Box>
                <Box className={support_time}>{BrandNameUpdate(timings)}</Box>
              </Grid>
            </Grid>
            <Grid item>
              {emailIConRender()}
              <Box component="span">
                {linkRender(`mailto:${email}`, email, email)}
              </Box>
            </Grid>
            <Grid item className={support_header_text} pb={0.6}>
              {s_header2}
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Grid item>
                {emailIConRender()}
                <Box component="span">
                  {linkRender(
                    `mailto:${partner_email1}`,
                    partner_email1,
                    BrandNameUpdate(email_brand_text)
                  )}
                </Box>
              </Grid>
              <Grid item>
                {emailIConRender()}

                <Box component="span">
                  {linkRender(
                    `mailto:${partner_email2}`,
                    partner_email2,
                    BrandNameUpdate(email_brand_text)
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={10}
          pb={5}
          sx={{
            minWidth: { xs: "308px", sm: "601px", md: "900px", lg: "1025px" },
            minHeight: { xs: "315px"}
          }}
        >
          <Box
            component="img"
            src={addressLogo.img}
            alt={addressLogo.alt}
            height="112px"
            width="122px"
          ></Box>
          {address_details?.map((items: any) => {
            return (
              <Box key={items.id} className={contact_address_text}>
                {BrandNameUpdate(items.line)}
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUsComponent;
