import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
    termsData: any;
    errorTermsData: any;
};

const initialState: InitialState = {
    termsData: null,
    errorTermsData: null
};

// Generates pending, fulfilled and rejected action types
export const termsContent = createAsyncThunk(
    "terms/termsContent",
    () => {
        return apiJunction.makeRequest({
            method: "get",
            url: `/${Config.Terms.TermsOfUse.template}${StrapiApiEndPoints.terms}`,
            isStrapiApi: true,
        });
    }
);

const strapiTermsSlice = createSlice({
    name: "terms",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(
            termsContent.fulfilled,
            (state: InitialState, action: PayloadAction<any>) => {
                state.termsData = action?.payload?.data?.data?.attributes;
                state.errorTermsData = null;
            }
        );
        builder.addCase(termsContent.rejected, (state: InitialState, action: any) => {
            state.termsData = null;
            state.errorTermsData = action.error || "Something went wrong";
        });
    },
});

export default strapiTermsSlice.reducer;