import { Box, Grid, Typography, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import styles from "./S1_UserStories.module.scss";
import { Share } from "../../../widgets";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { StoriesProps } from "./UserStories";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../../config/env.config.json";
import {
  gaScreenNames,
  gaEventTypes,
  gaCategory,
} from "../../../../Types/GoogleAnalytics";
import { generateImageContainer } from "../../../../utils/commonFunctions/generateImageTemplate";
import { getResolutionDetails } from "../../../../utils/commonFunctions/ReusableFunctions";

const UserStoriesComponent = (props: StoriesProps) => {
  const { nxt_i, pre_i, stry } = props.content;
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [cardIndex, setCardIndex] = useState<any>("");
  const [nextCard, setNextCard] = useState<number>(0);
  const { mobile } = getResolutionDetails();
  const showModal = (index: any, storyTitle: any) => {
    GAAndFBEventsHandler(gaCategory.userStories, storyTitle);
    setCardIndex(index);
    setDisplayModal(true);
  };
  const hideModal = () => {
    setDisplayModal(false);
  };
  const handlePrev = () => {
    if (nextCard !== 0) {
      setNextCard(nextCard - 1);
    } else {
      setNextCard(stry?.length - 1);
    }
  };
  const handleNext = () => {
    setNextCard(nextCard + 1);
    if (stry?.length - 1 === nextCard) {
      setNextCard(0);
    }
  };
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.postActivationHome,
      category: category,
      buttonValue: value,
    });
  };
  const facebookURL = {
    url: `https://www.facebook.com/dialog/share?app_id=2511315725603016&display=popup&href=${
      stry && stry[cardIndex]?.img
    }&quote=${stry && stry[cardIndex]?.ttl}${encodeURIComponent(
      stry && stry[cardIndex]?.mdl?.des
    )}`,
  };
  const twitterURL = {
    url: `https://twitter.com/intent/tweet?&text=${
      stry && stry[cardIndex]?.ttl
    } ${stry && stry[cardIndex]?.img?.data?.attributes?.url} ${
      stry && stry[cardIndex]?.ttl
    } ${encodeURIComponent(stry && stry[cardIndex]?.mdl?.des)}`,
  };
  const emailAction = {
    url: "https://reachmobile.com",
    subject: stry && stry[cardIndex]?.ttl,
    body: `${stry && stry[cardIndex]?.img?.data?.attributes?.url} ${
      stry && stry[cardIndex]?.ttl
    } ${encodeURIComponent(stry && stry[cardIndex]?.mdl?.des)}`,
  };
  return (
    <>
      <Grid
        container
        p="10px"
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        sx={{
          display: { xs: "none", sm: "flex" },
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0 calc(var(--mobile_section_padding))",
            sm: "calc(var(--ipad_section_padding)/2) 0 calc(var(--ipad_section_padding))",
            md: "calc(var(--desktop_section_padding)/2) 0 calc(var(--desktop_section_padding))",
          },
        }}
      >
        {stry?.map((story: any, index: any) => (
          <Grid
            key={index}
            item
            md={2.5}
            sm={5}
            xs={9}
            sx={{
              position: "relative",
              mt: { sm: "30px", md: "0px" },
            }}
            display="flex"
            flexDirection="column"
          >
            <Box
              component="img"
              src={story?.img?.data?.attributes?.url}
              alt={story?.img?.data?.attributes?.alternativeText}
              sx={{ cursor: "pointer", borderRadius: "4px" }}
              onClick={() => {
                showModal(index, story?.ttl);
              }}
            ></Box>
            <Typography
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              fontWeight="bold"
              className={styles.user_name_text}
              onClick={() => {
                showModal(index, story?.ttl);
              }}
            >
              {story?.ttl}
              <ArrowRightIcon sx={{ mt: "0px", height: "36px" }} />
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        p="10px"
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        {mobile &&
          generateImageContainer({
            data: pre_i,
            overrides: { sx: { cursor: "pointer" } },
            onClick: handlePrev,
          })}
        {stry?.map((story: any, index: any) => {
          if (index === nextCard) {
            return (
              <Grid
                key={index}
                item
                md={2.5}
                sm={5}
                xs={9}
                sx={{
                  position: "relative",
                  mt: { sm: "30px", md: "0px" },
                }}
                display="flex"
                flexDirection="column"
              >
                <Box
                  component="img"
                  src={story?.img?.data?.attributes?.url}
                  alt={story?.img?.data?.attributes?.alternativeText}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    showModal(index, story?.ttl);
                  }}
                ></Box>
                <Typography
                  textAlign="center"
                  width="100%"
                  fontWeight="bold"
                  className={styles.user_name_text}
                  onClick={() => {
                    showModal(index, story?.ttl);
                  }}
                >
                  {story.ttl}&nbsp;
                  <Box
                    component="img"
                    src={""}
                    alt={""}
                    sx={{ cursor: "pointer" }}
                  ></Box>
                </Typography>
              </Grid>
            );
          }
        })}
        {mobile &&
          generateImageContainer({
            data: nxt_i,
            overrides: { sx: { cursor: "pointer" } },
            onClick: handleNext,
          })}
      </Grid>
      {displayModal && (
        <Dialog
          scroll="body"
          open={displayModal}
          sx={{
            margin: "0px",
            left: 10,
            top: 50,
          }}
        >
          <Close
            sx={{
              float: "left",
              height: "15px",
              width: "15px",
              cursor: "pointer",
              m: "10px",
            }}
            onClick={hideModal}
          />
          <Grid
            container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="10px"
            className={styles.dialog_container}
          >
            <Box
              component="img"
              src={stry && stry[cardIndex]?.mdl?.img?.data?.attributes?.url}
              maxWidth={{ xs: "280px", sm: "100%" }}
              height={{ xs: "127px", sm: "170px" }}
            ></Box>
            <Typography className={styles.user_title}>
              {stry && stry[cardIndex]?.mdl?.tl}
            </Typography>
            <Typography className={styles.user_description} maxWidth="400px">
              {stry && stry[cardIndex]?.mdl?.des}
            </Typography>
          </Grid>
          <Grid item p="12px" borderTop="1px solid var(--card-color)">
            <Share
              share_text={stry[cardIndex]?.mdl?.shr_t}
              share_icon={stry[cardIndex]?.mdl?.shr_i?.data?.attributes?.url}
              invite_friends_text={stry[cardIndex]?.mdl?.if_t}
              facebook_icon={stry[cardIndex]?.mdl?.fb_i?.data?.attributes?.url}
              twitter_icon={stry[cardIndex]?.mdl?.tw_i?.data?.attributes?.url}
              mail_icon={stry[cardIndex]?.mdl?.ma_i?.data?.attributes?.url}
              facebook_label={stry[cardIndex]?.mdl?.fb_l}
              twitter_label={stry[cardIndex]?.mdl?.tw_l}
              mail_label={stry[cardIndex]?.mdl?.ma_l}
              facebook_action={facebookURL}
              twitter_action={twitterURL}
              email_action={emailAction}
            />
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default UserStoriesComponent;
