import { LinearProgress, Box } from "@mui/material";
import "./LinearProgressBarCust.scss";

type progressBarProps = {
  variant: any;
  value: number;
};

export const LinearProgressBarCust = (props: progressBarProps) => {
  return (
    <Box className="linear_progress_bar_main">
      <LinearProgress value={props?.value} variant={props?.variant} />
    </Box>
  );
};
