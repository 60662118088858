import { Box, Checkbox, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ButtonCust, SelectCust, SimpleModal } from "../../../widgets";
import styles from "./OneTimeCharge.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { type4TaxCalculation } from "../../../../features/account/accountSlice";
import { shipmentOrder } from "../../../../features/trackorder/trackorderSlice";
import { useNavigate } from "react-router-dom";
import { UpdateCustomerSource } from "../../../../features/checkout/checkoutSlice";
import { CardTypes } from "../../../../enums";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import EnvConfig from "../../../../config/env.config.json";

type Props = {
  onClose: any;
  changeCard: any;
  changeAddress: any;
  latestAddress: any;
  loaderHandler: any;
  btnLoader: boolean;
  currentLine: any;
  content: any;
  kitBasePrice: any;
  sendSimOptions: any;
};
const { header_txt, card_section, main_class } = styles;
function OneTimeCharge(props: Props) {
  const {
    onClose,
    changeCard,
    changeAddress,
    latestAddress,
    loaderHandler,
    btnLoader,
    currentLine,
    content,
    sendSimOptions,
    kitBasePrice,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let customerDetails: any = localStorage.getItem("customerDetail");
  let storedDeliveryType = localStorage.getItem("oneTimeChargeDeliveryType");
  customerDetails = customerDetails ? JSON.parse(customerDetails) : null;
  const [sendSimOption, setSimOption] = useState(
    sendSimOptions?.[0] ? sendSimOptions : []
  );
  const { getCCDetails, type4Tax, tax4Loader } = useAppSelector(
    (state: any) => state?.account || {}
  );
  const {
    title,
    card_txt,
    change_add,
    change_txt,
    confirm_pay,
    tax_txt,
    total_txt,
    wel_kit,
    chkbox_txt,
    err_msg,
    visa,
    american_exp,
    num_mask,
    discover,
    master,
    jcb,
    res,
  } = content?.tm_chgs || {};

  const { creditCardData } = useAppSelector(
    (state: any) => state?.billing || {}
  );
  const { ccuuid } = creditCardData;

  const { totalTax } = type4Tax || {};

  const currentCard = getCCDetails?.filter((val: any) =>
    ccuuid ? val?.uuid === ccuuid : val?.isAutoPay === true
  );
  const [deiveryType, setDeliveryType] = useState<any>(null);
  const [currentDelivery, setCurrentDelivery] = useState<any>(null);
  const [deliveryCost, setDeliveryCost] = useState<any>(null);
  const [tick, setTick] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [addressData, setAddressData] = useState<any>(
    latestAddress ? latestAddress : {}
  );

  const [chargeData, setChargeData] = useState([
    { label: wel_kit, value: 0, id: 1 },
    { id: 2 },
    { label: tax_txt, value: totalTax, id: 3 },
    { label: total_txt, value: 0, id: 4 },
  ]);

  useEffect(() => {
    latestAddress === null &&
      setAddressData({ ...customerDetails?.addresses[1] });
    fetchData();
  }, [kitBasePrice]);
  useEffect(() => {
    const newAddress: any = {
      address1: latestAddress?.address1,
      city: latestAddress?.city,
      country: latestAddress?.country,
      zip: latestAddress?.zip,
      state: latestAddress?.state,
    };
    latestAddress && setAddressData({ ...addressData, ...newAddress });
    if (latestAddress) {
      setTick(true);
    } else {
      setTick(false);
    }
  }, [latestAddress]);
  useEffect(() => {
    const totalDueAmt = chargeData?.[0]?.value + totalTax + deliveryCost;
    const resetData: any = [...chargeData];
    resetData[2].value = totalTax;
    resetData[3].value = totalDueAmt;
    setChargeData(resetData);
  }, [totalTax, deliveryCost]);

  useEffect(() => {
    const firstOb: any = sendSimOption?.[0];
    firstOb &&
      callTaxCalculation(
        storedDeliveryType ? storedDeliveryType : firstOb?.value
      );
  }, [sendSimOption]);
  const fetchData = async () => {
    let firstObj: any;

    firstObj = sendSimOptions?.[0];
    setSimOption(sendSimOptions);
    setDeliveryType(firstObj?.value);
    setCurrentDelivery(firstObj);

    const resetData: any = [...chargeData];
    resetData[0].value = kitBasePrice;
    setChargeData(resetData);
    setDeliveryCost(firstObj?.cost);
  };
  const onChangeHandler = (e: any) => {
    localStorage.setItem("oneTimeChargeDeliveryType", e);
    setDeliveryType(e);
    callTaxCalculation(e);
  };
  const callTaxCalculation = (selectedServiceType: any) => {
    let x = sendSimOption?.filter((vals: any) => {
      return selectedServiceType === vals?.serviceType;
    });
    setCurrentDelivery(x);
    const cost: any = x?.[0] || null;
    const payload = {
      address: { ...customerDetails?.addresses[1], type: "type1" },
      amount: cost?.shippingAmount,
      type: 1,
    };
    delete payload.address.phone;
    setDeliveryCost(cost?.shippingAmount);
    dispatch(type4TaxCalculation(payload));
  };
  const confirmToPay = () => {
    let estimatedDeliveryDate = new Date();
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 4);
    const payload: any = {
      cId: customerDetails.id,
      reachCarrierId: currentDelivery?.[0]?.reachCarrierId,
      deliveryDays: currentDelivery?.[0]?.deliveryDays,
      serviceType: currentDelivery?.[0]?.serviceType,
      serviceCode: currentDelivery?.[0]?.serviceCode,
      carrierCode: currentDelivery?.[0]?.carrierCode,
      shipmentType: "PHYSICAL",
      category: "SIM",
      shippingAmount: currentDelivery?.[0]?.cost,
      estimatedDeliveryDate: estimatedDeliveryDate,
      taxAmount: totalTax,
      taxId: type4Tax?.taxId,
      taxInfo: type4Tax,
      totalAmount: totalTax + currentDelivery?.[0]?.cost || 0,
      ccUUID: currentCard?.[0]?.uuid,
      shipTo: {
        city: addressData?.city,
        country: customerDetails?.addresses?.[0]?.country,
        name: customerDetails?.addresses?.[0]?.name,
        phone: customerDetails?.addresses?.[0]?.phone,
        postalCode: addressData?.zip,
        residential: "false",
        state: addressData?.state,
        street1: addressData?.address1,
      },
      items: [
        {
          quantity: 1,
          unitPrice: 0,
        },
      ],
    };
    dispatch(shipmentOrder(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        loaderHandler(false);
        const py = {
          activationJourneyStatus: "IN_PROGRESS",
          esim: false,
          physicalSimsOrdered: 1,
          simType: "PHYSICAL",
        };
        dispatch(
          UpdateCustomerSource({
            customerId: currentLine.id,
            data: py,
          })
        );

        setTimeout(() => {
          navigate(RoutingURLs.trackOrder);
        }, 1000);
      } else {
        setErrorMsg(true);
      }
      loaderHandler(false);
    });
    loaderHandler(true);
  };
  const cardType = currentCard?.[0]?.type?.toUpperCase();
  let imageUrl = "";
  let altText = "";

  if (cardType === CardTypes?.VISA) {
    imageUrl = visa?.data?.attributes?.url;
    altText = visa?.data?.attributes?.alternativeText;
  } else if (cardType === CardTypes?.MASTERCARD) {
    imageUrl = master?.data?.attributes?.url;
    altText = master?.data?.attributes?.alternativeText;
  } else if (cardType === CardTypes?.JCB) {
    imageUrl = jcb?.data?.attributes?.url;
    altText = jcb?.data?.attributes?.alternativeText;
  } else if (
    cardType === CardTypes?.AMERICANEXPRESS ||
    cardType === CardTypes?.AMEX
  ) {
    imageUrl = american_exp?.data?.attributes?.url;
    altText = american_exp?.data?.attributes?.alternativeText;
  } else if (cardType === CardTypes?.DISCOVER) {
    imageUrl = discover?.data?.attributes?.url;
    altText = discover?.data?.attributes?.alternativeText;
  }
  return (
    <SimpleModal
      isShowModal={true}
      onCloseModal={onClose}
      showClose={true}
      className={styles.otc}
      sx={{
        maxWidth: { xs: "95%", sm: "500px", md: "600px" },
      }}
    >
      <Grid
        container
        item
        display="flex"
        flexDirection="column"
        className={main_class}
      >
        <Grid item xs={12}>
          <Box className={header_txt}> {title}</Box>
          <Box
            className={styles.line}
            mb={2.5}
            mt={1}
            mx="auto"
            sx={{ width: 80, height: 5 }}
          ></Box>
        </Grid>
        <Grid item display="flex" flexDirection="column" marginTop={2}>
          {chargeData?.map((each: any) => {
            const { id } = each;
            return (
              <>
                <Box
                  className={styles.row}
                  display="flex"
                  width={{ sm: "88%" }}
                  justifyContent="space-between"
                  alignItems="center"
                  p="12px"
                  key={id}
                >
                  {id !== 2 ? (
                    <>
                      <Typography className={styles.row_text}>
                        {each.label}
                      </Typography>
                      <Typography className={styles.row_value}>
                        {tax4Loader && id > 1 ? (
                          <Skeleton style={{ width: "50px" }} />
                        ) : (
                          id > 1 && GetCurrencyFixedToTwo(each.value)
                        )}
                        {id <= 1 && GetCurrencyFixedToTwo(each.value)}
                      </Typography>
                    </>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      className={styles.selectotc}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <SelectCust
                        formStyles={{
                          width: "67%",
                        }}
                        options={sendSimOption}
                        value={
                          storedDeliveryType ? storedDeliveryType : deiveryType
                        }
                        onChange={(e: any) => {
                          onChangeHandler(e?.target?.value);
                        }}
                      />
                      <Typography className={styles.row_value}>
                        {GetCurrencyFixedToTwo(deliveryCost)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            );
          })}
        </Grid>
        <Grid
          item
          xs={12}
          className={card_section}
          sx={{
            px: {
              xs: 0,
              sm: 2,
            },
          }}
          textAlign="center"
        >
          <Typography p={1} className={styles.card_text}>
            {card_txt}
          </Typography>
          {
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={styles.image_text_container}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={altText}
                    style={{ width: "42px", height: "27px" }}
                  />
                ) : null}
                <Box sx={{ wordBreak: "break-all" }}>
                  <Typography
                    className={styles.card_text}
                    pl="10px"
                    fontFamily="var(--font_family_Medium)"
                  >
                    {num_mask} {currentCard?.[0]?.ccNumberMask}
                  </Typography>
                </Box>
                <Typography
                  className={styles.card_text}
                  sx={{
                    color: "var(--primary_color) !important",
                    cursor: "pointer",
                    fontSize: "16px !important",
                  }}
                  ml="10px"
                  onClick={() => changeCard(addressData)}
                >
                  {change_txt}
                </Typography>
              </Box>
            </>
          }
          <Box
            sx={{
              px: { xs: 0, sm: 4 },
            }}
          >
            <Box py={"14px"} className={styles.shipping_text}>
              {latestAddress ? null : (
                <Checkbox
                  color="primary"
                  onChange={() => {
                    setTick(!tick);
                  }}
                />
              )}{" "}
              {latestAddress && res?.ship_lbl ? res?.ship_lbl : chkbox_txt}
            </Box>
            <Box
              display={{ xs: "block", md: "flex" }}
              marginLeft={{ md: "10px" }}
              flexDirection={{ xs: "row" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{ textDecoration: "underline" }}
                mb={0.5}
                fontSize="14px"
                className={styles.address_text}
              >
                {addressData?.address1}
                {", "}
                {addressData?.city}
                {", "}
                {addressData?.state}
                {", "}
                {addressData?.zip}
              </Box>
              {latestAddress ? null : (
                <Box
                  sx={{
                    color: "var(--primary_color)",
                    cursor: "pointer",
                    fontFamily: "var(--font_family_Bold)",
                    fontWeight: "var(--font_weight_0)",
                    paddingLeft: "10px",
                  }}
                  onClick={() => changeAddress(addressData)}
                >
                  {change_add}
                </Box>
              )}
            </Box>
            <ButtonCust
              sx={{ m: "20px 0 30px 0", padding: "13px 23px" }}
              onClick={confirmToPay}
              disabled={!tick || tax4Loader}
              loading={btnLoader}
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            >
              {confirm_pay}
            </ButtonCust>
          </Box>
          {errorMsg && (
            <Box
              sx={{
                color: " var(--danger)",
                textAlign: "center",
                fontFamily: "var(--font_family_Bold)",
              }}
            >
              {err_msg}
            </Box>
          )}
        </Grid>
      </Grid>
    </SimpleModal>
  );
}

export default OneTimeCharge;
