import { isTemplateExisits } from "../../../../utils/commonFunctions";
import Config from "../../../../config/app.config.json";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

export type NLProps = {
  NLContent?: any;
  plandata?: any;
  priceCalculationHandler?: any;
  selectedLine?: any;
  tabValue?: any;
  maxShipvalue?: number;
  minShipvalue?: number;
  showNutritionLabels?: any;
  setShowNutritionLabels?: any;
  setTabValue?: any;
  planCost?: any;
  index?: any;
  handleAnswer?: any;
  showAnswer?: any;
  nlPlan?: any;
  isheading?: any;
};
const NutritionLabelComponent = isTemplateExisits(Config.Home.NutritionLabel)
  ? require(`./${Config.Home.NutritionLabel.template}_NutritionLabel`).default
  : null;

export const NutritionLabel = (props: NLProps) => {
  const { NLContent, plandata, priceCalculationHandler, selectedLine, planCost, index, showAnswer, handleAnswer, isheading } = props;
  const { coupon, getAppData } = useAppSelector((state: any) => state.checkout);

  const [minShipvalue, setMinShipvalue] = useState(0);
  const [maxShipvalue, setMaxShipvalue] = useState(0);
  const [showNutritionLabels, setShowNutritionLabels] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState("Breezeline Start");

  //app data useffect

  useEffect(() => {
    var shipData = getAppData?.data?.data?.shippingRates;
    if (shipData) {
      let minValue = Math.min(
        ...shipData.map((obj: any) => obj.shippingAmount)
      );
      let maxValue = Math.max(
        ...shipData.map((obj: any) => obj.shippingAmount)
      );
      setMinShipvalue(minValue);
      setMaxShipvalue(maxValue);
    }
  }, [getAppData]);
  return (
    <NutritionLabelComponent
      NLContent={NLContent}
      plandata={plandata}
      priceCalculationHandler={priceCalculationHandler}
      selectedLine={selectedLine}
      tabValue={tabValue}
      setTabValue={setTabValue}
      maxShipvalue={maxShipvalue}
      minShipvalue={minShipvalue}
      showNutritionLabels={showNutritionLabels}
      setShowNutritionLabels={setShowNutritionLabels}
      planCost={planCost}
      index={index}
      showAnswer={showAnswer}
      handleAnswer={handleAnswer}
      isheading={isheading}
    />
  );
};
