import { Box, Typography } from "@mui/material";
import { Divider } from "../../widgets";
import styles from "./S2_AppSection.module.scss";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const WowServices = (props: any) => {
  const { appSection } = props;
  return (
    appSection && (
      <Box
        className={styles.content_wrapper}
        sx={{
          mx: "16px",
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0",
            sm: "calc(var(--ipad_section_padding)/2) 0",
            md: "calc(var(--desktop_section_padding)/2) 0",
          },
          minHeight: { xs: "800px", sm: "850px", md: "505px" },
        }}
      >
        <Box
          className={styles.discover_left}
          sx={{ flexBasis: { md: "50%" }, p: "0 12px 0px 12px" }}
        >
          <Typography className={styles.discover_header}>
            {appSection?.title}
          </Typography>
          <Box display={"flex"} justifyContent={"flex-start"}>
            <Divider
              styles={{
                m: " 20px 0px 25px",
                width: "85px !important",
                height: "4px !important",
                backgroundColor: "var(--dusty-orange) !important",
              }}
            />
          </Box>

          <Typography className={styles.discover_subtitle}>
            {appSection?.sub_title}
          </Typography>
          <Box className={styles.step_container}>
            {appSection?.steps?.map((data: any, index: number) => {
              return (
                <Box display={"flex"} pb={"24px"} key={index}>
                  <Box
                    className={styles.step_icon}
                    sx={{ mr: { xs: "24px", sm: "48px" } }}
                  >
                    <Box
                      component={"img"}
                      src={data?.image?.data?.attributes?.url}
                      sx={{ height: "38px" }}
                      alt={data?.image?.data?.attributes?.alternativeText}
                    />
                  </Box>
                  <Box className={styles.step_content} sx={{ sm: "16px" }}>
                    <Typography className={styles.step_title}>
                      {data?.title}
                    </Typography>
                    <Box
                      className={styles.step_description}
                      dangerouslySetInnerHTML={{
                        __html: BrandNameUpdate(data?.description),
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          className={styles.discover_right}
          sx={{
            flexBasis: { md: "50%" },
            display: "flex",
            justifyContent: { xs: "center", md: "end" },
            px: "16px",
            mt: { xs: "50px", md: " 0px" },
          }}
        >
          <Box sx={{ pr: { lg: "65px" } }}>
            <Box
              component="img"
              sx={{
                height: { xs: "300px", sm: "400px", md: "553px", lg: "500px " },
              }}
              src={appSection?.image?.data?.attributes?.url}
              alt={appSection?.image?.data?.attributes?.alternativeText}
            ></Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default WowServices;
