import { Box, Paper } from "@mui/material";
import { ReactNode } from "react";
import ENVConfig from "../../../config/env.config.json";
import styles from "./LoginAndSignUp.module.scss";

type Props = { children: ReactNode };

export const LoginAndSignUp = (props: Props) => {
  const { children } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        background: {
          xs: "unset",
          sm: "linear-gradient( var(--white) 50%, var(--login-signup-color) 50.5%)",
        },
        width: "100%",
      }}
    >
      <Paper
        sx={{
          padding: { xs: "10px 0", sm: "40px 50px" },
          margin: { xs: "0", sm: "10% 0" },
          boxShadow: {
            xs: "unset",
            sm: "0 0 3px 0 rgba(174, 174, 174, 0.81)",
          },
        }}
        className={`${ENVConfig.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA ? styles.login_singup_main_1: styles.login_singup_main_2}`}
      >
        {children}
      </Paper>
    </Box>
  );
};
