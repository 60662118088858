import { Box } from "@mui/system";
import { useRef, useEffect } from "react";
import { useAppSelector } from "../../../../app/hooks";
import envConfig from "../../../../config/env.config.json";
import { RoutingURLs } from "../../../../config/RoutingURLs";

type Props = {
  currentLine: any;
  isSaveCard: boolean;
  isAutoPay: boolean;
};

function PaymentForm(props: Props) {
  const { currentLine, isAutoPay, isSaveCard } = props;
  const formRef = useRef<HTMLFormElement>(null);
  const { showPayForm } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  useEffect(() => {
    showPayForm && proceedToPaymentHandler();
  }, [showPayForm]);

  const proceedToPaymentHandler = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  return (
    <Box>
      <form
        ref={formRef}
        method="post"
        name="paymentForm"
        id="paymentForm"
        action={envConfig.CHECKOUT_PAYMENT}
      >
        <input
          type="hidden"
          name="emailId"
          value={currentLine?.emailId ?? ""}
        />
        <input
          type="hidden"
          name="redirectUrl"
          value={`${window.location.origin}${RoutingURLs.activation}`}
        />
        {envConfig?.TENANT_ID ? (
          <>
            <input type="hidden" name="tenant" value={envConfig?.TENANT_ID} />
            <input type="hidden" name="saveCard" value={String(isSaveCard)} />
            <input type="hidden" name="autoPay" value={String(isAutoPay)} />
            <input type="hidden" name="defaultCard" value={String(isAutoPay)} />
          </>
        ) : null}
      </form>
    </Box>
  );
}

export default PaymentForm;
