import { Typography } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { generateImageContainer } from "../../../utils/commonFunctions";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
  content: any;
  openModal: boolean;
  onModalClose: any;
  btnHandler: any;
  eligibilityCheck: any;
  isCheckEligibilityClicked: boolean;
};
const HSDSuccessModal = (props: Props) => {
  const {
    content,
    openModal,
    onModalClose,
    btnHandler,
    isCheckEligibilityClicked,
  } = props;
  const { ttl, sub_ttl, btn, icon } = content;
  return (
    <SimpleModal
      isShowModal={openModal}
      onCloseModal={onModalClose}
      showClose={false}
      sx={{ width: { xs: "90%", sm: "65%", md: "55%", lg: "40%", xl: "35%" } }}
    >
      {generateImageContainer({
        data: icon,
        attrs: { height: "30px", width: "30px" },
      })}
      <Typography
        variant="h5"
        sx={{
          mb: "8px",
          fontSize: "20px",
          fontFamily: "var(--font_family_Medium)",
          fontWeight: "var(--h4_font_weight)",
        }}
      >
        {ttl}
      </Typography>
      <Typography
        sx={{
          my: "8px",
          textAlign: "center",
          fontSize: "16px",
          color: "var(--grey_shade)",
          fontFamily: "var(--font_family_Medium)",
          fontWeight: "var(--h4_font_weight)",
        }}
      >
        {BrandNameUpdate(sub_ttl)}
      </Typography>
      <ButtonCust
        variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
        sx={{ width: "100%" }}
        onClick={() => btnHandler()}
        loading={isCheckEligibilityClicked}
      >
        {btn}
      </ButtonCust>
    </SimpleModal>
  );
};

export default HSDSuccessModal;
