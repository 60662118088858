import { Box, Link, Typography } from "@mui/material";
import styles from "./SupportModal.module.scss";
import { SimpleModal } from "../../../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { brandNames } from "../../../../enums";
import envConfig from "../../../../config/env.config.json";
import { useAppSelector } from "../../../../app/hooks";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import { RoutingURLs } from "../../../../config/RoutingURLs";

type IsupportModalProps = {
  open: boolean;
  onClose: any;
  title: string;
  showClose: boolean;
  showBackBtn: boolean;
  content: any;
};

export const HelpSupportModal = (props: IsupportModalProps) => {
  const { open, onClose, title, showClose, showBackBtn, content } = props;
  const [closeBtn, setCloseBtn] = useState(showClose);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { chat_txt, day_tm, chat_img, call_img, mail_img, week_tm } =
    content?.sup_md;
  const data = [
    {
      title: chat_txt,
      subtitle: day_tm,
      img: chat_img?.data?.attributes?.url,
      link: RoutingURLs.chatbot,
      rel: "noopener noreferrer",
    },
    {
      title: globalVal?.phoneNumber,
      subtitle: BrandNameUpdate(week_tm),
      img: call_img?.data?.attributes?.url,
      link: `tel:${globalVal?.phoneNumber}`,
    },
  ];
  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={closeBtn}
      sx={{
        borderRadius: "4.8px",
        maxWidth: { xs: "95%", sm: "500px", md: "640px" },
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      className={styles.help_modal_main}
    >
      <Box className={styles.dialog_main}>
        <Box className={styles.back_arrow}>
          {showBackBtn ? (
            <ArrowBackIcon className={styles.arrow_icon} onClick={onClose} />
          ) : null}
        </Box>
        <Typography className={styles.dialog_activation_modal_heading}>
          {title}
        </Typography>
        <Box className={styles.dialog_activation_modal_divider}></Box>
        <Box
          className={styles.support_center_row}
          justifyContent={{
            xs: "start !important",
            sm: "center !important",
          }}
          marginLeft={{ xs: "15px" }}
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
        >
          {data.map((item: any) => {
            return (
              <Box
                className={styles.support_individual_row}
                justifyContent={{
                  xs: "start !important",
                  sm: "center !important",
                }}
                key={item.title}
              >
                {envConfig.SHOW_ACT_SUPPORT_MODAL_IMAGES && (
                  <img className={styles.sub_title_img} src={item.img} alt="" />
                )}
                <Box className={styles.subheading}>
                  <Link
                    href={item.link  === RoutingURLs.chatbot ? undefined : item.link}
                    onClick={() => {
                      if (item.link === RoutingURLs.chatbot) {
                        window.open(RoutingURLs.chatbot, "_self");
                        setTimeout(() => {
                          window.history.replaceState(null, "", location.pathname);
                        }, 1000);
                      } else {
                        return false;
                      }
                    }}
                    className={styles.dialog_sub_title_one}
                    underline="none"
                    rel={item.rel ? item.rel : null}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {item.title}
                  </Link>
                  <Typography className={styles.dialog_sub_title_two}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            );
          })}
           <Box
          className={styles.dialog_support_bottom_row}
          justifyContent={{ xs: "start !important", sm: "center !important" }}
        >
          {envConfig.SHOW_ACT_SUPPORT_MODAL_IMAGES && (
            <img
              className={styles.sub_title_img}
              src={mail_img?.data?.attributes?.url}
              alt={mail_img?.data?.attributes?.alternativeText}
            />
          )}
          <Box height={"24px"}>
            <a
              href={`mailto:${globalVal?.email}`}
              className={styles.mail_color}
            >
              {globalVal?.email}
            </a>
          </Box>
        </Box>
        </Box>
      </Box>
    </SimpleModal>
  );
};
