import { Grid, Typography } from "@mui/material";
import { OutOfDataProps } from "./OutOfData";
import { ButtonCust, NotificationCust, SimpleModal } from "../widgets";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { Store } from "react-notifications-component";
const OutOfDataComponent = (props: OutOfDataProps) => {
  const [load, setLoad] = useState(false);
  const {
    title,
    sub_title,
    questions,
    link_text,
    link_path,
    btn_txt,
    notifi_msg1,
    notifi_msg2,
  } = props.content || {};
  const { open, closeDialog, handleSubmit } = props || {};
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  useEffect(() => {
    if (
      getCustomer?.isDataCutoff === true &&
      getCustomer?.addedDataCutoff === false
    ) {
      NotificationCust({
        message: getCustomer?.reachPlanChangeDate ? notifi_msg1 : notifi_msg2,
        type: "info",
        showIcon: true,
        duration: 0,
        id: "OUTOFDATANOTIFIER",
      });
    } else {
      Store.removeNotification("OUTOFDATANOTIFIER");
    }
  }, [getCustomer?.isDataCutoff]);
  return (
    props.content && (
      <SimpleModal
        isShowModal={open}
        onCloseModal={closeDialog}
        showClose={true}
        sx={{marginTop:"30px"}}
      >
        <Grid
          container
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "var(--font_weight_4)",
              fontFamily: " var(--font_family_Medium)",
              mb: "20px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              margin: "0px 25px 25px",
              fontWeight: "var(--font_weight_0)",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {sub_title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              margin: "0px 25px 25px",
              fontWeight: "var(--font_weight_0)",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {questions} &nbsp;
            <span
              style={{
                color: "var(--dusk)",
                fontWeight: "900",
                fontFamily: "var(--font_family_Medium)",
                cursor: "pointer",
              }}
              onClick={() => window.open(link_path, "_self")}
            >
              {link_text}
            </span>
          </Typography>
          <ButtonCust
            variantType="primary_outlined_rounded"
            loading={load}
            onClick={() => {
              setTimeout(() => {
                handleSubmit();
                setLoad(false);
              }, 2000);
              setLoad(true);
            }}
          >
            {btn_txt}
          </ButtonCust>
        </Grid>
      </SimpleModal>
    )
  );
};
export default OutOfDataComponent;
