import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { isTemplateExisits } from "../../../../utils/commonFunctions";
import Config from "../../../../config/app.config.json";
import EnvConfig from "../../../../config/env.config.json";
import { getCC } from "../../../../features/account/accountSlice";
import firebase from "../../../../adapters/firebase";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getImeiCheck } from "../../../../features/activation/activationSlice";
import { countryList } from "../../../../config/USAStateList";
import { NotificationCust } from "../../../widgets";
import FirebaseDBURLs from "../../../../config/FirebaseDBURLs";
import { getInTouchApi } from "../../../../features/homepage/homepageSlice";
import {
  BrandNameUpdate,
  getLastCharacter,
  modelMapper,
} from "../../../../utils/commonFunctions/BrandNameUpdate";

interface formikintitaltypes {
  imei: string;
}
const ActivationStep1Comp = isTemplateExisits(Config.Activation.ActivationStep1)
  ? require(`./${Config.Activation.ActivationStep1.template}_ActivationStep1`)
      .default
  : null;
export const ActivationStep1 = (props: any) => {
  const { currentLine, content, gaHandler, handleStep, updatingCustomer } =
    props;
  const dispatch = useAppDispatch();
  const onTimeActivation = localStorage.getItem("oneTimeactivation");

  const [kitBasePrice, setKitBasePrice] = useState<any>(null);
  const [sendSimOption, setSimOption] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [deviceComp, setDeviceComp] = useState(
    currentLine?.imei ? true : false
  );
  const [currentModal, setCurrentModal] = useState(
    onTimeActivation ? "oneTimeChrg" : ""
  );
  const { imeiData, imeiNumber } = useAppSelector(
    (state: any) => state?.activation || {}
  );

  const { msgs } = content || {};
  const { isValid, model, make } = imeiData || {};

  const customerDetails: any = JSON.parse(
    localStorage?.getItem("customerDetail") || "null"
  );
  useEffect(() => {
    const customerStoreData: any = JSON.parse(
      localStorage?.getItem("customerDetail") || "null"
    );
    if (customerStoreData)
      dispatch(getCC({ customerId: customerStoreData?.id }));
    fetchData();
  }, []);
  useEffect(() => {
    const modelN = getLastCharacter(model, 5);
    if (
      isValid &&
      make &&
      model &&
      currentLine.esim !== true 
      // &&
      // EnvConfig.IS_IMEI_DEVICE_MODEL_NEG_CHECK_ENABLED &&
      // !modelMapper[modelN]
    ) {
      setDeviceComp(true);
    }
  }, [imeiData, isValid]);
  const fetchData = async () => {
    let firstObj: any;
    const shippingRate: any = await firebase
      .database()
      .ref(FirebaseDBURLs.shippingRates);
    shippingRate.on("value", (snapshot: any) => {
      const data = snapshot.val();
      let sendSimOptions: any = [];
      data?.map((el: any) => {
        return sendSimOptions.push({
          label: `${el?.name} - $${el?.shippingAmount}`,
          value: el?.serviceType,
          cost: el?.shippingAmount,
          ...el,
        });
      });
      firstObj = sendSimOptions?.[0];
      setSimOption(sendSimOptions);
    });

    const perCost: any = await firebase.database().ref(FirebaseDBURLs.simCost);
    let kitBasePrice = 0;

    perCost.on("value", (snapshot: any) => {
      const data = snapshot.val();
      kitBasePrice =
        data?.USA && ((100 - data.USA.discountPctg) * data.USA.cost) / 100;
      setKitBasePrice(kitBasePrice);
    });
  };
  const hubspotPy = {
    emailId: currentLine?.emailId,
    hubspotMap: {
      device_imei: imeiNumber,
      device_make: make,
      device_model: model,
      website_imei_incompatibility_reason: "",
      is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
      is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
    },
  };
  const activationStep1Schema = object({
    imei: string().min(15, msgs?.valid_msg),
  });
  const initialformstep1data: formikintitaltypes = {
    imei: currentLine?.imei || "",
  };
  const formik = useFormik({
    initialValues: initialformstep1data,
    validationSchema: activationStep1Schema,
    onSubmit: (values: any) => {
      setBtnLoader(true);
      const py: any = {
        countryCode: countryList?.[0]?.value?.toLowerCase(),
        imei: values?.imei,
      };

      gaHandler("check");
      dispatch(getImeiCheck({ payload: py })).then((res: any) => {
        const data = res?.payload?.data?.data;
        const { isValid, make, model } = data || {};
        const modelN = getLastCharacter(model, 5);
        if (
          isValid &&
          make &&
          model
          // EnvConfig.IS_IMEI_DEVICE_MODEL_NEG_CHECK_ENABLED &&
          // modelN &&
          // !modelMapper[modelN]
        ) {
          NotificationCust({
            message: BrandNameUpdate(msgs.imei_noti),
            duration: 2000,
            id: "activationIMEISuccess",
            showIcon: true,
          });

          currentLine?.esim !== true && handleStep(true);
          dispatch(
            getInTouchApi({
              data: hubspotPy,
            })
          );
          updatingCustomer({
            imei: res?.meta?.arg?.payload?.imei.toString(),
            make: data?.make,
            model: data?.model,
            osVer: data?.osVer || "",
            os: data?.os || "",
          });
          setCurrentModal("chooseType");
        } else {
          dispatch(
            getInTouchApi({
              data: {
                ...hubspotPy,
                hubspotMap: {
                  ...hubspotPy.hubspotMap,
                  website_imei_incompatibility_reason: "invalid",
                },
              },
            })
          );
          setModalStatus(true);
        }
        setBtnLoader(false);
      });
    },
    enableReinitialize: true,
  });

  return (
    <ActivationStep1Comp
      sendSimOption={sendSimOption}
      kitBasePrice={kitBasePrice}
      deviceComp={deviceComp}
      setDeviceComp={setDeviceComp}
      currentModal={currentModal}
      setCurrentModal={setCurrentModal}
      formik={formik}
      btnLoader={btnLoader}
      setBtnLoader={setBtnLoader}
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
      imei={imeiData}
      {...props}
    />
  );
};
