import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from "@mui/material";
import {
    Divider,
} from "../../../widgets";
import {
    gaCategory,
} from "../../../../Types/GoogleAnalytics";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
    currentOperator: any;
    styles: any;
    content: any;
    setOpenSupportDialog: any;
    GAAndFBEventsHandler: any;
}

const AccountPinHelperText = (props: Props) => {
    const { currentOperator, styles, content, setOpenSupportDialog, GAAndFBEventsHandler } = props
    const {
        c_num,
        info_heading,
        extra_info,
    } = content;
    return (
        <>
            {currentOperator?.acctNumMsg && (
                <>
                    <Box>
                        <Typography
                            className={styles.btm_title}
                            sx={{ mb: "20px", textAlign: "center" }}
                        >
                            {c_num?.find_txt} {currentOperator.displayName}{" "}
                            {BrandNameUpdate(c_num?.acc_pin_txt)}
                        </Typography>
                        <Divider />
                    </Box>
                    <Box mb="20px">
                        <Typography className={styles.btm_title} sx={{ mb: "5px" }}>
                            {c_num?.acc_num}
                        </Typography>
                        <Typography className={styles.btm_description}>
                            {currentOperator?.acctNumMsg}
                        </Typography>
                    </Box>
                    <Box mb="20px">
                        <Typography className={styles.btm_title} sx={{ mb: "5px" }}>
                            {c_num?.pin}
                        </Typography>
                        <Typography className={styles.btm_description}>
                            {currentOperator.pinMsg.replace(
                                "{0}",
                                currentOperator.pinTel
                            )}
                        </Typography>
                    </Box>
                    {currentOperator?.extraPinInfo === true && (
                        <Box mt="30px">
                            <Typography className={styles.btm_title} sx={{ mb: "20px" }}>
                                {info_heading}
                            </Typography>
                            <Box>
                                {extra_info?.length > 0 &&
                                    extra_info?.map((item: any, index: number) => {
                                        return (
                                            <Accordion
                                                className={styles.accordian_main}
                                                disableGutters
                                                sx={{ boxShadow: "none" }}
                                                key={index}
                                            >
                                                <AccordionSummary
                                                    sx={{ p: "0", mb: "0px" }}
                                                    expandIcon={<ArrowDropDownIcon />}
                                                >
                                                    <Typography className={styles.acdn_title}>
                                                        {item?.title}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ p: "0" }}>
                                                    {item?.points?.length > 0 &&
                                                        item?.points?.map((data: any, ind: number) => {
                                                            return (
                                                                <Typography
                                                                    sx={{ mt: "10px" }}
                                                                    className={styles.btm_description}
                                                                    key={ind}
                                                                >
                                                                    {data?.desc}
                                                                </Typography>
                                                            );
                                                        })}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                            </Box>
                        </Box>
                    )}
                </>
            )}
            {c_num?.res?.faqInstruction ? (
                <Typography
                sx={{
                    display: { sm: "none", md: "block" },
                    mt: "20px"
                }}
                className={styles.btm_description}
                dangerouslySetInnerHTML={{
                    __html: c_num?.res?.faqInstruction,
                }}
                ></Typography>
            ) : null}
            <Box
                mt="20px"
                mb="60px"
                width="fit-content"
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                    setOpenSupportDialog(true);
                    GAAndFBEventsHandler(
                        gaCategory.activation,
                        c_num.stil_need_help
                    );
                }}
            >
                {c_num?.chat_img?.data?.attributes?.url && (<img
                    src={c_num?.chat_img?.data?.attributes?.url}
                    alt={c_num?.chat_img?.data?.attributes?.alternativeText}
                    className={styles.chat_box_img}
                />)}
                <Typography className={styles.btm_title} ml="5px">
                    {c_num?.stil_need_help}
                </Typography>
            </Box>
        </>
    )
}

export default AccountPinHelperText