import Config from "../../../config/app.config.json";
import { useEffect,  useState } from "react";
import { posResultBottomStrapi } from "../../../features/strapi/billCalculatorSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const ComparePosResultBottom = isTemplateExisits(
  Config.BillCalculator.PosResultBottom
)
  ? require(`./${Config.BillCalculator.PosResultBottom.template}_PosResultBottom`)
      .default
  : null;

export const PosResultBottom = () => {
  const [cachePostResultBottom, setCachePostResultBottom] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { posResultBottomData } = useAppSelector(
    (state: any) => state.strapi.billCalculator
  );
  useEffect(() => {
    getCache(
      "PosResultBottomBillCalculator",
      posResultBottomStrapi,
      setCachePostResultBottom,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData(
      "PosResultBottomBillCalculator",
      posResultBottomData
    );
  }, [posResultBottomData]);

  return (posResultBottomData || cachePostResultBottom )? (
    <ComparePosResultBottom
      content={cachePostResultBottom ? cachePostResultBottom : posResultBottomData}
    />
  ) : null;
};
