import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  totalCreditsCard: any;
  errorTotalCreditsCard: any;
  referralCreditsCards: any;
  errorReferralCreditsCards: any;
  pendingCreditCard: any;
  errorPendingCreditCard: any;
  accountPrimaryCard: any;
  errorAccountPrimaryCard:any;
};

const initialState: InitialState = {
  totalCreditsCard: null,
  errorTotalCreditsCard: null,
  referralCreditsCards: null,
  errorReferralCreditsCards: null,
  pendingCreditCard: null,
  errorPendingCreditCard: null,
  accountPrimaryCard: null,
  errorAccountPrimaryCard:null,
};

// Generates pending, fulfilled and rejected action types
export const totalCreditsContent = createAsyncThunk(
  "postActivationAccount/totalCreditsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.ReferralCredits.template}${StrapiApiEndPoints.totalCreditsCard}`,
      isStrapiApi: true,
    });
  }
);

export const referralCreditsContent = createAsyncThunk(
  "postActivationAccount/referralCreditsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.ReferralCredits.template}${StrapiApiEndPoints.referralCreditsCards}`,
      isStrapiApi: true,
    });
  }
);

export const pendingCreditContent = createAsyncThunk(
  "postActivationAccount/pendingCreditContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.ReferralCredits.template}${StrapiApiEndPoints.pendingCreditCard}`,
      isStrapiApi: true,
    });
  }
);

export const accountPrimaryCardContent = createAsyncThunk(
  "postActivationAccount/accountPrimaryCardContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.AccountPrimaryCard.template}${StrapiApiEndPoints.accountPrimaryCard}`,
      isStrapiApi: true,
    });
  }
);

const postActivationAccountSlice = createSlice({
  name: "postActivationAccount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      totalCreditsContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.totalCreditsCard = action.payload?.data?.data?.attributes;
        state.errorTotalCreditsCard = null;
      }
    );
    builder.addCase(totalCreditsContent.rejected, (state, action) => {
      state.totalCreditsCard = null;
      state.errorTotalCreditsCard = action.error || "Something went wrong";
    });
    builder.addCase(
      referralCreditsContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.referralCreditsCards = action.payload?.data?.data?.attributes;
        state.errorReferralCreditsCards = null;
      }
    );
    builder.addCase(referralCreditsContent.rejected, (state, action) => {
      state.referralCreditsCards = null;
      state.errorReferralCreditsCards = action.error || "Something went wrong";
    });
    builder.addCase(
      pendingCreditContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.pendingCreditCard = action.payload?.data?.data?.attributes;
        state.errorPendingCreditCard = null;
      }
    );
    builder.addCase(pendingCreditContent.rejected, (state, action) => {
      state.pendingCreditCard = null;
      state.errorPendingCreditCard = action.error || "Something went wrong";
    });
     builder.addCase(
       accountPrimaryCardContent.fulfilled,
       (state, action: PayloadAction<any>) => {
         state.accountPrimaryCard = action.payload?.data?.data?.attributes;
         state.errorAccountPrimaryCard = null;
       }
     );
     builder.addCase(accountPrimaryCardContent.rejected, (state, action) => {
       state.accountPrimaryCard = null;
       state.errorAccountPrimaryCard = action.error || "Something went wrong";
     });
  },
});

export default postActivationAccountSlice.reducer;
