import { Box, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { format, parse } from "date-fns";
import {
  ButtonCust,
  Divider,
  SearchBoxWithDropdown,
  SelectCust,
  SimpleModal,
  TextFieldCust,
} from "../../../widgets";
import { usaState, countryList } from "../../../../config/USAStateList";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getAppData } from "../../../../features/checkout/checkoutSlice";
import Config from "../../../../config/env.config.json";

import styles from "./CountryList.module.scss";
import GlobalRoamingPack from "../GlobalRoamingPack/GlobalRoamingPack";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import { getCounriesList } from "../../../../features/internationalService/internationalServiceSlice";
import { getAvailableOfferings } from "../../../../features/services/servicesSlice";

const CountryList = (props: any) => {
  const {
    country_list,
    user_data,
    activation_icon,
    country_icon,
    calendar_data,
    back_btn,
    back_arrow,
    no_option,
  } = props.content;

  const { fullName, setIsCountryList } = props;

  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [isCalanderModal, setIsCalanderModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [isGlobalRoamingPack, setIsGlobalRoamingPack] = useState<any>(false);
  const [getCountryList, setGetCountryList] = useState<any>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCounriesList()).then((res) => {
      setGetCountryList(res?.payload?.data?.data?.countriesList);
    });
  }, []);

  const setSelectedCountryHandler = (e: any, val: any) => {
    if (val) {
      setSelectedCountry(val);
      const countryObject = {
        code: val.code,
        name: val.name,
      };
      localStorage.setItem("prRoamingCountry", JSON.stringify(countryObject));
    } else {
      setSelectedCountry(""); // Clear the selected country in your component's state
      localStorage.removeItem("prRoamingCountry");
    }
  };

  const formatDateForUI = (e: any) => {
    const date = new Date(e);
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const formatDateForAPI = (e: any) => {
    if (e) {
      const parsedDate = parse(e, "MMMM dd, yyyy", new Date());
      const formatted = format(parsedDate, "yyyy-MM-dd");
      return formatted;
    }
    return;
  };
  const calanderOnChangeHandler = (e: any) => {
    const formattedForUi = formatDateForUI(e);
    setSelectedDate(formattedForUi);
  };

  const calanderSubmitHandler = () => {
    setIsCalanderModal(false);
    const formattedForApi: any = formatDateForAPI(selectedDate);
    localStorage.setItem("postActivationDate", formattedForApi);
  };

  const continueSubmitHandler = () => {
    setIsGlobalRoamingPack(true);
    const payload = {
      type: "IR",
      countries: [selectedCountry.code],
      operator: "ATT",
    };
    dispatch(getAvailableOfferings(payload));
  };

  const renderOption = (props: any, option: any) => {
    if (props.customRenderOption) {
      return props.customRenderOption(props, option);
    } else {
      return (
        <span
          {...props}
          style={{ fontFamily: "var(--font_family_Medium)", fontSize: "16px" }}
        >
          {option.name}
        </span>
      );
    }
  };

  return (
    <>
      {" "}
      {!isGlobalRoamingPack ? (
        <>
          <CustomDateCalendar
            onClick={calanderSubmitHandler}
            onChange={calanderOnChangeHandler}
            isCalanderModal={isCalanderModal}
            onCloseModal={setIsCalanderModal}
            title={calendar_data.title}
            desc={calendar_data.desc}
            button={calendar_data.btn_1}
            variant={Config.QUATERNARY_BUTTON_TYPE}
          />
          <Box
            className={styles.main_container}
            m={{ xs: "auto", sm: "auto", md: "auto" }}
            width={{ xs: "auto", sm: "auto", md: "60%" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className={styles.back_arrow}
                onClick={() => setIsCountryList(false)}
              >
                {/* Add your arrow icon here */}
                <img
                  src={back_arrow?.data.attributes.url}
                  alt={back_arrow?.data.attributes.alternativeText}
                  width={17.495}
                  height={14.586}
                />
                {back_btn}
              </Typography>
              <Box flex={1} textAlign={"initial"}>
                {/* Your title or heading */}
                <Typography className={styles.heading}>
                  {country_list.title}
                </Typography>
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {/* Your name */}
                <Typography className={styles.full_name}>{fullName}</Typography>
              </Box>
            </Box>
            <SearchBoxWithDropdown
              dropdownList={getCountryList}
              optionLabel={(option: any) => option.name}
              value={selectedCountry}
              onChange={setSelectedCountryHandler}
              placeholder={country_list.country_placeholder}
              onRequest={() => null}
              onCancel={() => setSelectedCountry("")}
              logo={country_icon}
              renderOption={renderOption}
              no_option={no_option}
              sx={{
                "& .MuiFilledInput-input": {
                  fontFamily: "var(--font_family_Medium) !important",
                },
              }}
            />
            <Box onClick={() => setIsCalanderModal(true)}>
              <TextFieldCust
                name="calander"
                value={selectedDate}
                readOnly={true}
                placeholder={country_list.pack_placeholder}
                endAdornment={
                  <img
                    src={activation_icon.data.attributes.url}
                    alt={activation_icon.data.alternativeText}
                    width="18.5px"
                    height="18.5px"
                  />
                }
              />
            </Box>
            <Box textAlign={"center"} mt={"15px"}>
              <ButtonCust
                sx={{ width: "70%" }}
                variantType={Config.QUATERNARY_BUTTON_TYPE}
                onClick={() => continueSubmitHandler()}
              >
                {country_list.button}
              </ButtonCust>
            </Box>
          </Box>
        </>
      ) : (
        <GlobalRoamingPack
          props={props}
          setIsGlobalRoamingPack={setIsGlobalRoamingPack}
          setIsCountryList={setIsCountryList}
        />
      )}
    </>
  );
};

export default CountryList;
