import { iNotification, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./NotificationCust.scss";

interface Props extends iNotification {
  message: any;
  type?: any;
  duration?: number;
  showIcon?: boolean;
  id?: string;
  onScreen?: boolean;
  title?: string;
}

export function NotificationCust(props: Partial<Props>) {
  return Store.addNotification({
    ...props,
    title: props.title,
    message: props.message,
    type: props.type || "success",
    insert: "top",
    container: "top-full",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: props.duration === 0 ? 0 : props.duration || 5000,
      onScreen: props.onScreen || false,
      showIcon: props.showIcon || false,
    },
    id: props.id,
  });
}
