import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  trackOrderContent: any;
  errorTrackOrderContent: any;
};

const initialState: InitialState = {
  trackOrderContent: null,
  errorTrackOrderContent: null,
};

// Generates pending, fulfilled and rejected action types
export const fetchTrackOrderContent = createAsyncThunk(
  "trackOrder/fetchTrackOrderContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.TrackOrder.template}${StrapiApiEndPoints.trackOrder}`,
      isStrapiApi: true,
    });
  }
);
const strapiTrackOrderSlice = createSlice({
  name: "trackOrder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchTrackOrderContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.trackOrderContent = action.payload?.data?.data?.attributes;
        state.errorTrackOrderContent = null;
      }
    );
    builder.addCase(fetchTrackOrderContent.rejected, (state, action) => {
      state.trackOrderContent = null;
      state.errorTrackOrderContent = action.error || "Something went wrong";
    });
  },
});

export default strapiTrackOrderSlice.reducer;
