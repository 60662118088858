import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import Config from "../../config/app.config.json";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";

type InitialState = {
  coverageCheckContent: any;
  errorCoverageContent: any;
};
const initialState: InitialState = {
  coverageCheckContent: null,
  errorCoverageContent: null,
};

export const detailCoverageStrapi = createAsyncThunk(
  "coverageCheckStrapi/detailCoverage",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.CoverageCheck.DetailsCoverage.template}${StrapiApiEndPoints.detailCoverage}`,
      isStrapiApi: true,
    });
  }
);

const coverageCheckSlice = createSlice({
  name: "coverageCheckStrapi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      detailCoverageStrapi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.coverageCheckContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorCoverageContent = null;
      }
    );
    builder.addCase(detailCoverageStrapi.rejected, (state, action) => {
      state.coverageCheckContent = null;
      state.errorCoverageContent =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default coverageCheckSlice.reducer;
