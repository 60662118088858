import { Close } from "@mui/icons-material";
import { Grid, Modal, Typography } from "@mui/material";

import "./Modal.scss";

type iprops = {
  isShowModal: boolean;
  onCloseModal: any;
  showClose: boolean;
  children: any;
  style?: any;
  preview?: boolean;
  width?: string;
  padding?: string;
  left?: string;
  top?: string;
  className?: any;
  styleIcon?: any;
  icon?: any;
  sx?: any;
  closeIconSt?: any;
  isRight?: any;
  iconSize?: any;
};
const currentStyling = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
{
  /* calling and passing props
        <Modal
          isShowModal={coverageModal}
          onCloseModal={closeModalHandler}
          showClose={true}
          ------------ few optinary properties---------------
          sx={{ width: { sm: "90%", md: "70%" }  }} 
          style={{ flexDirection: "row",  borderRadius: "30px" }}
          closeIconSt={{ opacity: "0.5" }}
> */
}

export function SimpleModal(props: iprops) {
  const { style, sx, closeIconSt } = props;
  return (
    <div>
      <Modal
        open={props.isShowModal}
        onClose={props.onCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onBackdropClick={props.onCloseModal}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
        className="Modal"
        sx={{ overflow: "auto" }}
      >
        <Grid
          container
          p={{ xs: 3, md: 3.75 }}
          style={{
            ...currentStyling,
            ...style,
            borderRadius: "6px"
          }}
          sx={{
            position: "absolute",
            top: { xs: "20%" },
            ...sx,
            width: {
              xs: "100%",
              sm: "52%",
              md: "45%",
              lg: "35%",
              xl: "27%",
              ...sx?.width,
            },
          }}
          className={props.className ? props.className : "ModalData"}
        >
          {props.showClose && (
            <Typography
            className={props.isRight ? "closeRight" : "close"}
              onClick={props.onCloseModal}
              style={props.styleIcon ? props.styleIcon : {}}
            >
              {props.icon ? (
                <img src={props.icon} width={
                  props.isRight
                    ? "20px"
                    : props?.iconSize
                    ? props?.iconSize
                    : "12px"
                } alt={""} />
              ) : (
                <Close
                  style={{
                    color: "var(--black)",
                    opacity: "0.8",
                    fontSize: "20px",
                    ...closeIconSt,
                  }}
                />
              )}
            </Typography>
          )}
          {props.children}
        </Grid>
      </Modal>
    </div>
  );
}
