import {  Box,Avatar } from "@mui/material";
import clsx from "clsx";
import styles from "./DeeperStepperCust.module.scss";
type Props = {
  steps: any;
  titleClassName?:any;
  stepperBarClassName?:any;
  avatar?:any;
  firstName?:any;
  currentLevel?:any;
};
export const DeeperStepperCust = (props: Props) => {
    const {steps} = props
  return (
    <Box className={styles.stepperWrapper}>
      <Box className={`${styles.stepperBar} ${props?.stepperBarClassName ? props?.stepperBarClassName : ''} `}>
        {steps?.slice(0, steps.length - 1).map((step: any, i: number) => {
          return (
            <Box className={styles.bars} key={i}>
              <Box className={styles.baseBar}/>
              <Box
                className={clsx(
                  styles.progressBar,
                  step.isFailing ? styles.failed : styles.success,
                )}
                style={{
                  width: `${(100 / step.levels) * step.currentLevel}%`,
                }}
              ></Box>
            </Box>
          );
        })}
      </Box>
      <Box className={styles.stepperContentWrapper}>
        {steps.map((step: any, i: number) => {
          return (
            <Box className={styles.stepperStep} key={i}>
              {
                i === props?.currentLevel &&  props?.avatar && <Avatar className={`${ props?.avatar ? styles.avatar :''}`}>{props?.firstName?.slice(0,1).toUpperCase()}</Avatar>
              }
              <Box className={styles.knob}></Box>
              <Box className={`${styles.stepTitle} ${props?.titleClassName ? props?.titleClassName : ''} `}>{step.title}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
