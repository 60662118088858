import { Grid, Box, Typography } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { ButtonCust, SimpleModal } from "../../../widgets";
import styles from "./ChangePlanDialogue.module.scss";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";
const wowUnlimitedCoupons = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../../config/discountCouponsUnlimited.json")
  : null;
const wowRegularCoupons = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../../config/discountCouponsRegular.json")
  : null;
type ChangePlanDialogueProps = {
  onClose: any;
  open: boolean;
  currentBill1: any;
  currentBill2: any;
  currentPlan: any;
  totalActivitedUsers: any;
  currentPlanCost: any;
  currentPlanTax: any;
  newBillStartMonth: any;
  newBillEndMonth: any;
  selectedPlan: any;
  newPlanCost: any;
  newPlanTax: any;
  newBillDate: any;
  confirmPlanChangeHandler: any;
  planChangeLoader: boolean;
  content?: any;
  discount?: any;
};
let currentdetails: any = localStorage.getItem("customerDetail");
currentdetails = JSON.parse(currentdetails);

export const ChangePlanDialogue = (props: ChangePlanDialogueProps) => {
  const {
    open,
    onClose,
    currentBill1,
    currentBill2,
    currentPlan,
    totalActivitedUsers,
    currentPlanCost,
    currentPlanTax,
    newBillStartMonth,
    newBillEndMonth,
    selectedPlan,
    newPlanCost,
    newPlanTax,
    newBillDate,
    confirmPlanChangeHandler,
    planChangeLoader,
    content,
    discount,
  } = props;
  const TotalCost = parseFloat(
    (newPlanCost + newPlanTax - discount)?.toFixed(2)
  );
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };
  const {
    change_plan,
    current_plan,
    new_plan,
    tax_fees,
    due_date,
    confirm_change,
    for_text,
    note,
    billing_cyle_note,
    plan_review,
    line_charges,
    promo_code,
    sav_txt,
    code_text,
  } = content?.compare_plan;
  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "60%", md: "80%", lg: "35%", xl: "35%" },
        top: "15%",
      }}
    >
      <Box className={styles.account_change_new_plan_content}>
        <Typography className={styles.account_new_current_head}>
          {change_plan}
        </Typography>
        <Box className={styles.confirm_plan_line}></Box>
        <Box>
          <Box>
            <Box className={styles.account_new_current_cont}>
              <Box
                className={styles.account_cur_new_head}
                sx={{ paddingBottom: "15px" }}
              >
                {current_plan}
                <span className={styles.current_bill_date}>
                  &nbsp;({currentBill1} - {currentBill2})
                </span>
              </Box>
              <Box className={styles.account_cur_new_content}>
                <Typography
                  variant="body2"
                  className={styles.account_cur_new_head}
                >
                  {EnvConfig?.NO_EXTRA_LINES ? (
                    <>{currentPlan}</>
                  ) : (
                    <>
                      {currentPlan}&nbsp;{for_text}&nbsp;{totalActivitedUsers}
                    </>
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles.account_cur_new_head}
                >
                  {GetCurrencyFixedToTwo(currentPlanCost)}
                </Typography>
              </Box>
              <Box className={styles.account_cur_new_content}>
                <Typography
                  variant="body2"
                  className={styles.account_cur_new_head}
                >
                  {tax_fees}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles.account_cur_new_head}
                >
                  {GetCurrencyFixedToTwo(currentPlanTax)}
                </Typography>
              </Box>
              <Box className={styles.account_cur_new_border}></Box>
            </Box>
            <Box className={styles.account_new_current_cont}>
              <Box
                className={`${styles.account_cur_new_head} ${styles.bold}`}
                sx={{ paddingBottom: "15px" }}
              >
                {new_plan}
                <span className={styles.current_bill_date}>
                  &nbsp;({newBillStartMonth} - {newBillEndMonth})
                </span>
              </Box>
              <Box
                className={`${styles.account_cur_new_head} ${styles.bold} ${styles.account_cur_new_content}`}
              >
                <Typography
                  variant="body2"
                  className={`${styles.account_cur_new_head} ${styles.bold}`}
                >
                  {EnvConfig?.NO_EXTRA_LINES ? (
                    <>{selectedPlan?.displayName}</>
                  ) : (
                    <>
                      {selectedPlan?.displayName}&nbsp;{for_text}&nbsp;
                      {totalActivitedUsers}
                    </>
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${styles.account_cur_new_head} ${styles.bold}`}
                >
                  {GetCurrencyFixedToTwo(newPlanCost)}
                </Typography>
              </Box>
              {currentdetails?.isWowHSDCustomer && (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ color: "var(--primary_brand_color)" }}
                  >
                    <Typography
                      variant="body2"
                      lineHeight="2.14"
                      fontFamily="var(--font_family_Medium)"
                      fontSize="14px"
                    >
                      {promo_code}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily="var(--font_family_Medium)"
                      lineHeight="2.14"
                    >
                      -{GetCurrencyFixedToTwo(discount)}
                    </Typography>
                  </Box>
                  <Grid
                    border="1px dashed var(--text_color)"
                    borderRadius="5px"
                    p="10px 65px 10px 10px"
                    mb="14px"
                  >
                    <Typography
                      textAlign="left"
                      fontFamily="var(--font_family_Medium)"
                      color="var(--primary_brand_color)"
                      fontSize="14px"
                    >
                      {sav_txt}
                      <br />
                      {promo_code}{" "}
                      {selectedPlan?.isUnlimited
                        ? wowUnlimitedCoupons?.data?.name
                        : wowRegularCoupons?.data?.name}{" "}
                      {code_text}
                    </Typography>
                  </Grid>
                </>
              )}
              <Box className={styles.account_cur_new_content}>
                <Typography
                  variant="body2"
                  className={`${styles.account_cur_new_head} ${styles.bold}`}
                >
                  {tax_fees}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${styles.account_cur_new_head} ${styles.bold}`}
                >
                  {GetCurrencyFixedToTwo(newPlanTax)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "var(--white_1)",
                  color: "var(--primary_brand_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "14px",
                  }}
                >
                  {due_date} {newBillDate}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "14px",
                  }}
                >
                  {TotalCost !== 0 ? GetCurrencyFixedToTwo(TotalCost) : "-"}
                </Typography>
              </Box>

              {EnvConfig.CHANGE_PLAN_NOTE_REQD && (
                <Typography
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "14px",
                    textAlign: "center",
                    py: "20px",
                  }}
                >
                  <Box component="span" fontWeight="bold">
                    {note}&nbsp;
                  </Box>
                  {billing_cyle_note}
                  <Box component="span" fontWeight="bold">
                    ({newBillStartMonth})&nbsp;
                  </Box>
                  {plan_review}
                  <Box component="span" fontWeight="bold">
                    {GetCurrencyFixedToTwo(newPlanCost - discount)}&nbsp;
                  </Box>
                  {line_charges}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                width: "80%",
                margin: "auto",
                marginTop: "8px",
              }}
              width={{ xs: "300px" }}
            >
              <ButtonCust
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  confirmPlanChangeHandler("later");
                  GAAndFBEventsHandler(
                    gaCategory.changePlan,
                    "Confirm Plan Change"
                  );
                }}
                loading={planChangeLoader}
              >
                {confirm_change}
              </ButtonCust>
            </Box>
          </Box>
        </Box>
      </Box>
    </SimpleModal>
  );
};
