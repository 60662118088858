import Config from "../../../config/app.config.json";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
const PaymentButtonsComponent = isTemplateExisits(Config.Checkout.PaymentBtns)
  ? require(`./${Config.Checkout.PaymentBtns.template}_PaymentButtons`).default
  : null;

export type PaymentProps = {
  paymentClicked?: any;
  formik: any;
  navigate?: any;
  submitBtnTxt?: string;
  backButtonTxt?: string;
  content?: any;
  hideBackButton?: any;
  disabled?: any;
};

export const PaymentButtons = (props: PaymentProps) => {
  return (
    <PaymentButtonsComponent
      paymentClicked={props.paymentClicked}
      formik={props.formik}
      navigate={props.navigate}
      submitBtnTxt={props?.content?.submit_btn}
      backButtonTxt={props?.content?.back_btn}
      hideBackButton={props.hideBackButton}
      disabled={props?.disabled}
    />
  );
};
