import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

type InitialState = {
  countries: Array<any>;
  errorInCountries: any;
  isBanner1: boolean;
};
const initialState: InitialState = {
  countries: [],
  errorInCountries: null,
  isBanner1: true,
};

export const getCounriesList = createAsyncThunk(
  "internationalService/getCounriesList",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: ApiEndPoints.countryList,
    });
  }
);

const internationalService = createSlice({
  name: "internationalService",
  initialState,
  reducers: {
    bannerHandler: (state, action) => {
      state.isBanner1 = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCounriesList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.countries = action.payload.data.data.countriesList;
        state.errorInCountries = null;
      }
    );
    builder.addCase(getCounriesList.rejected, (state, action) => {
      state.countries = [];
      state.errorInCountries = action.error || "Something went wrong";
    });
  },
});
export const { bannerHandler } = internationalService.actions;

export default internationalService.reducer;
