import Config from "../../../config/app.config.json";
import { getCache, isTemplateExisits } from "../../../utils/commonFunctions";
import data from "../../../config/data.json";
import { sortByKey } from "../../../utils/commonFunctions/SortByKey";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect, useState } from "react";
import { getAddLine_ChangePlanContent } from "../../../features/strapi/checkoutSlice";

const AddLine_ChangePlanTemplate = isTemplateExisits(
  Config.Checkout.AddLine_ChangePlan
)
  ? require(`./${Config.Checkout.AddLine_ChangePlan.template}_AddLine_ChangePlan`)
      .default
  : null;

export type AddLine_ChangePlanTemplateProps = {
  isShowModal: boolean;
  modalType: any;
  setOpenModal: any;
  formik?: any;
  plandata: any;
  index?: any;
  handleChangePlan?: any;
  handleAddLine?: any;
  content?: any;
  currentPlan?: any;
  amendedReachPlanId?: any;
  initialCount?: any;
  isEsim?: any;
  currentLine?: any;
  isAcount?: any;
  planContent?: any;
};
type Props = {
  formik?: any;
  isShowModal?: any;
  modalType?: any;
  setOpenModal?: any;
  index?: any;
  handleChangePlan?: any;
  handleAddLine?: any;
  currentPlan?: any;
  amendedReachPlanId?: any;
  initialCount?: any;
  isEsim?: any;
  currentLine?: any;
  isAcount?: any;
  planContent?: any;
};

const AddLine_ChangePlan = (props: Props) => {
  const dispatch = useAppDispatch();
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { addLineChangeCardContent } = useAppSelector(
    (state: any) => state.strapi.checkout
  );
  useEffect(() => {
    getCache(
      "AddLine_ChangePlan",
      getAddLine_ChangePlanContent,
      setCacheContent,
      dispatch
    );
  }, []);
  const {
    isShowModal,
    modalType,
    setOpenModal,
    formik,
    index,
    handleChangePlan,
    handleAddLine,
    currentPlan,
    amendedReachPlanId,
    initialCount,
    isEsim,
    currentLine,
    isAcount,
    planContent
  } = props;
  let plandata =
    data && data.data && data.data.length > 0
      ? sortByKey(data && data.data, "planData")
      : [];

  return addLineChangeCardContent || cacheContent ? (
    <AddLine_ChangePlanTemplate
      isShowModal={isShowModal}
      modalType={modalType}
      setOpenModal={setOpenModal}
      formik={formik}
      plandata={plandata}
      index={index}
      handleChangePlan={handleChangePlan}
      handleAddLine={handleAddLine}
      content={cacheContent ? cacheContent : addLineChangeCardContent}
      currentPlan={currentPlan}
      amendedReachPlanId={amendedReachPlanId}
      initialCount={initialCount}
      isEsim={isEsim}
      currentLine={currentLine}
      isAcount={isAcount}
      planContent={planContent}
    />
  ) : null;
};
export default AddLine_ChangePlan;
