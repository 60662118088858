import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
    safeConnectionData: any;
    errorSafeConnectionData: any;
};

const initialState: InitialState = {
    safeConnectionData: null,
    errorSafeConnectionData: null
};

// Generates pending, fulfilled and rejected action types
export const SafeConnectionContent = createAsyncThunk(
    "safeConnection/SafeConnectionContent",
    () => {
        return apiJunction.makeRequest({
            method: "get",
            url: `/${Config.SafeConnection.SafeConnectionOfUse.template}${StrapiApiEndPoints.safecon}`,
            isStrapiApi: true,
        });
    }
);

const strapiTermsSlice = createSlice({
    name: "terms",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(
            SafeConnectionContent.fulfilled,
            (state: InitialState, action: PayloadAction<any>) => {
                state.safeConnectionData = action?.payload?.data?.data?.attributes;
                state.errorSafeConnectionData = null;
            }
        );
        builder.addCase(SafeConnectionContent.rejected, (state: InitialState, action: any) => {
            state.safeConnectionData = null;
            state.errorSafeConnectionData = action.error || "Something went wrong";
        });
    },
});

export default strapiTermsSlice.reducer;