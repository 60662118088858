import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  donations: any;
  errorDonations: any;
  stories: any;
  errorStories: any;
  dataUsage: any;
  errorDataUsage: any;
  dataUsageFailure: any;
  errorDataUsageFailure: any;
  activationModals: any;
};

const initialState: InitialState = {
  donations: null,
  errorDonations: null,
  stories: null,
  errorStories: null,
  dataUsage: null,
  errorDataUsage: null,
  dataUsageFailure: null,
  errorDataUsageFailure: null,
  activationModals: null,
};

// Generates pending, fulfilled and rejected action types
export const donationsContent = createAsyncThunk(
  "postActivation/donationsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PostActivationHome.Donations.template}${StrapiApiEndPoints.donations}`,
      isStrapiApi: true,
    });
  }
);

export const storiesContent = createAsyncThunk(
  "postActivation/storiesContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PostActivationHome.Stories.template}${StrapiApiEndPoints.stories}`,
      isStrapiApi: true,
    });
  }
);

export const getDataUsageContent = createAsyncThunk(
  "postActivation/getDataUsageContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PostActivationHome.DataUsage.template}${StrapiApiEndPoints.PADataUsage}`,
      isStrapiApi: true,
    });
  }
);

export const getDataUsageFailureContent = createAsyncThunk(
  "postActivation/getDataUsageFailureContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.dataUsageFailure}`,
      isStrapiApi: true,
    });
  }
);

export const getActivationModals = createAsyncThunk(
  "postActivation/getActivationModals",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.postActivationModals}`,
      isStrapiApi: true,
    });
  }
);

const postActivationSlice = createSlice({
  name: "postActivation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      donationsContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.donations = action.payload?.data?.data?.attributes;
        state.errorDonations = null;
      }
    );
    builder.addCase(donationsContent.rejected, (state, action) => {
      state.donations = null;
      state.errorDonations = action.error || "Something went wrong";
    });
    builder.addCase(
      storiesContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.stories = action.payload?.data?.data?.attributes;
        state.errorStories = null;
      }
    );
    builder.addCase(storiesContent.rejected, (state, action) => {
      state.stories = null;
      state.errorStories = action.error || "Something went wrong";
    });
    builder.addCase(
      getDataUsageContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.dataUsage = action.payload?.data?.data?.attributes;
        state.errorDataUsage = null;
      }
    );
    builder.addCase(getDataUsageContent.rejected, (state, action) => {
      state.dataUsage = null;
      state.errorDataUsage = action.error || "Something went wrong";
    });
    builder.addCase(
      getDataUsageFailureContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.dataUsageFailure = action.payload?.data?.data?.attributes;
        state.errorDataUsageFailure = null;
      }
    );
    builder.addCase(getDataUsageFailureContent.rejected, (state, action) => {
      state.dataUsageFailure = null;
      state.errorDataUsageFailure = action.error || "Something went wrong";
    });
    builder.addCase(
      getActivationModals.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activationModals = action.payload?.data?.data?.attributes;
      }
    );
    builder.addCase(getActivationModals.rejected, (state) => {
      state.activationModals = null;
    });
  },
});

export default postActivationSlice.reducer;
