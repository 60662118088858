import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import { version } from "../../config/RoutingURLs";
import ApiEndPoints from "../../config/ApiEndPoints";


type InitialState = {
  data: any;
  errorData: any;
};
const initialState: InitialState = {
  data: {},
  errorData: null
};

export const reservePromoCoupon = createAsyncThunk(
  "planpage/reservePromoCoupon",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: ApiEndPoints.reservePromoCoupon,
      body: action.payload.data,
    });
  }
);

const planpageSlice = createSlice({
  name: "planpage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
        reservePromoCoupon.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.errorData = null;
      }
    );
    builder.addCase(reservePromoCoupon.rejected, (state, action) => {
      state.data = {};
      state.errorData = action.error || "Something went wrong";
    });
  },
});

export default planpageSlice.reducer;
