import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../utils/commonFunctions/ValidateEmail";
import { ButtonCust, TextFieldCust, SimpleModal, PopperCust } from "../widgets";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  // Popover
  Paper,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Config from "../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import { updateToHubspot } from "../../utils/commonFunctions/hubspot";
import { scroller } from "react-scroll";
import { Close } from "@mui/icons-material";
import styles from "./ImeiCheckForm.module.scss";
import {
  checkCompatibility,
  getInTouchApi,
} from "../../features/homepage/homepageSlice";
import { compatibilityContent } from "../../features/strapi/imeiCompatibilitySlice";
import { reservePromoCoupon } from "../../features/planpage/planpageSlice";
import firebase from "../../adapters/firebase";
import { PromoCodeComp } from "./PromoCodeComp";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import EnvConfig from "../../config/env.config.json";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import { operators } from "../../enums/Operators";
import { RoutingURLs } from "../../config/RoutingURLs";
import {
  BrandNameUpdate,
  ModelNameUpdate,
  getLastCharacter,
  modelMapper,
} from "../../utils/commonFunctions/BrandNameUpdate";
import FirebaseDBURLs from "../../config/FirebaseDBURLs";

export const ImeiCheckForm = () => {
  const { compatibilityLoading, compatibility } = useAppSelector(
    (state: any) => state.homepage
  );
  const { imeiContent } = useAppSelector(
    (state: any) => state.strapi.imeiCompatibilityPage
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSuccess, setisSuccess] = useState(false);
  const [imeiNumber, setimeiNumber] = useState("");
  const [imeiErr, setimeiErr] = useState("");
  const [checkClick, setcheckClick] = useState(false);
  const [emailCompatability, setemailCompatability] = useState("");
  const [emailErr, setemailErr] = useState("");
  const [haveTrouble, sethaveTrouble] = useState(false);
  const [target, settarget] = useState(null);
  const [name, setname] = useState("");
  const [isFail, setisFail] = useState(false);
  const [imeiPormoCoupon, setimeiPormoCoupon] = useState("");
  const [imeiPormoHeading, setimeiPormoHeading] = useState("");
  const [imeiPormoText, setimeiPormoText] = useState("");
  const [imeiSocialText, setimeiSocialText] = useState("");
  const [imeiMailText, setimeiMailText] = useState("");
  const [share, setShare] = useState(false);
  const [shareTarget, setShareTaget] = useState(null);
  const [modelCompat, setModalCompat] = useState(true);
  const [cacheDataCompatibility, setCacheDataCompatibility] =
    useState<any>(null);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;
  useEffect(() => {
    let loggedUser: any = JSON.parse(localStorage.getItem("currentuser") || "null");
    if (loggedUser?.email) {
      setemailCompatability(loggedUser?.email);
      setemailErr("");
    }
  }, []);

  const imeiHandler = (e: any) => {
    setimeiNumber(e.target.value.replace(/\s/g, ""));
    setimeiErr("");
  };
  const closeTroubleHandler = () => {
    settarget(null);
    sethaveTrouble(false);
  };

  const emailCompatibilityHandler = (e: any) => {
    setemailCompatability(e.target.value);
    setemailErr("");
  };
  const nameHandler = (e: any) => {
    setname(e.target.value);
  };
  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.imeiCompatibilityCheck,
      category: categoryName,
      buttonValue: value,
    });
  };
  const checkHandler = async (e: any) => {
    GAAndFBEventsHandler(gaCategory.CheckCompatibility, button_1);
    if (
      emailCompatability &&
      imeiNumber &&
      emailErr === "" &&
      imeiErr === "" &&
      imeiNumber.length === 15 &&
      validateEmail(emailCompatability) &&
      /^\d+$/.test(imeiNumber)
    ) {
      await setcheckClick(true);
      updateToHubspot(e, emailCompatability);
      dispatch(
        getInTouchApi({
          data: {
            emailId: emailCompatability,
            hubspotMap: {
              email: emailCompatability,
              is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
              is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
              ...(name !== "" && { firstname: name }),
              imei_page_imei_entered: imeiNumber,
            },
          },
        })
      );
      dispatch(checkCompatibility({ imeiNumber: imeiNumber }));
    } else {
      if (emailCompatability === "") {
        setemailErr(email_error2);
      } else if (!validateEmail(emailCompatability)) {
        setemailErr(email_error1);
      }
      if (imeiNumber === "") {
        setimeiErr(imei_error3);
      } else if (imeiNumber !== "" && imeiNumber.length !== 15) {
        setimeiErr(imei_error2);
      } else if (imeiNumber !== "" && !/^\d+$/.test(imeiNumber)) {
        setimeiErr(imei_error1);
      }
    }
  };

  const handleChange = () => {
    window.open(globalVal?.shopPhone, "_blank");
    GAAndFBEventsHandler(gaCategory.CheckCompatibility, button_4);
  };

  const tryAgainHandler = () => {
    GAAndFBEventsHandler(gaCategory.CheckCompatibility, button_5);
    setimeiNumber("");
    setname("");
    setemailCompatability("");
    setisSuccess(false);
    setisFail(false);
    setcheckClick(false);
    setModalCompat(true);
  };

  const backButtonClick = () => {
    setisSuccess(false);
  };

  const closeCompatModal = () => {
    setisSuccess(false);
    setisFail(false);
    setemailCompatability("");
    setimeiNumber("");
    setname("");
  };

  const viewPlanHandler = () => {
    navigate(RoutingURLs.home, { state: "isPlans" });
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        smooth: "easeInOutQuart",
        smooh: "easeOutQuart",
        offset: -100,
      });
    }, 1000);
  };

  function getIMEICouponDetails() {
    firebase
      .database()
      .ref(FirebaseDBURLs.imeiCheckCoupon)
      .once("value")
      .then(function (snapshot) {
        let data = snapshot.val();
        if (data) {
          setimeiPormoCoupon(data.coupon);
          setimeiPormoHeading(data.imeiCheckHead);
          setimeiPormoText(data.imeiCheckText);
          setimeiSocialText(data.socialText);
          setimeiMailText(data.mailText);
        }
      });
  }

  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
      setisSuccess(false);
      if (Config.COMPATIBILITY_CHECK_PROMO) getIMEICouponDetails();
    } else {
      let data = compatibility?.data?.data;
      let modelN: string = getLastCharacter(
        compatibility?.data?.data?.model,
        5
      );
      if (
        Config.IS_IMEI_DEVICE_MODEL_NEG_CHECK_ENABLED &&
        modelN &&
        modelMapper[modelN]
      ) {
        setModalCompat(false);
        setisFail(true);
      } else {
        setModalCompat(true);
        setisFail(false);
        if (data && data.isValid && data.make && data.model) {
          setisSuccess(true);
          setcheckClick(false);
          updateToHubspot(data, emailCompatability);
          dispatch(
            getInTouchApi({
              data: {
                emailId: emailCompatability,
                hubspotMap: {
                  email: emailCompatability,
                  is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
                  is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
                  ...(name !== "" && { firstname: name }),
                  imei_page_imei_entered: data && data.imei,
                  imei_page_supported_carriers:
                    data &&
                    data.supportedCarriers &&
                    data.supportedCarriers.length > 0 &&
                    data.supportedCarriers[0]
                      ? data.supportedCarriers[0]
                      : "",
                },
              },
            })
          );
          if (imeiPormoCoupon) {
            dispatch(
              reservePromoCoupon({
                data: {
                  emailId: emailCompatability,
                  couponId: imeiPormoCoupon,
                  customerName: name,
                },
              })
            );
          }
        } else {
          if (data) {
            setisFail(true);
            setcheckClick(false);
          }
        }
      }
    }
  }, [compatibility]);

  useEffect(() => {
    getCache(
      "DeviceCompatibility",
      compatibilityContent,
      setCacheDataCompatibility,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("DeviceCompatibility", imeiContent);
  }, [imeiContent]);

  const shareHandler = (e: any) => {
    {
      share ? setShare(false) : setShare(true);
    }
    setShareTaget(e.target);
  };
  const closeShareHandler = () => {
    setShare(false);
    setShareTaget(null);
  };
  let data = compatibility?.data?.data;
  const {
    bring_your_phone_main,
    bring_your_phone_content,
    compatibility_plan,
    compatibility_plan_title,
    compatibility_plan_input,
    limit_div,
    above_email_text,
    having_trouble_div,
    phone_trouble_div,
    input_div,
    have_trouble_tool_tip,
    have_trouble_header,
    have_trouble_detail_div,
    close_icon,
    have_trouble_header_text,
    detail_img_div,
    detail_content,
    detail_head,
    detail_subtext,
    add_margin_bottom,
    success_compatibility,
    back_button,
    arrow_icon,
    individual_row,
    text_left,
    text_right,
    view_plan_btn,
    compatibility_plan_bring,
    bummer_div,
    bummer_content,
    shop_phone_button,
    try_again,
    modal_image,
    detail_img,
    heading_accordian,
    accordion_component,
  } = styles;

  const {
    heading,
    imei_place_holder,
    imei_error1,
    imei_error2,
    imei_error3,
    yes_txt,
    no_txt,
    dial_msg,
    having_trouble,
    popover_title,
    popover_subtext1,
    popover_subtext2,
    email_label,
    name_label,
    email_error1,
    button_1,
    button_2,
    success_text1,
    success_text2,
    success_text3,
    success_text4,
    success_text5,
    button_3,
    fail_title,
    button_4,
    button_5,
    accordion_title,
    email_error2,
    popover_img1,
    popover_img2,
    fail_image,
    share_image,
    gift_image,
    twitter_img,
    facebook_img,
    mail_img,
    popover_text1,
    popover_text2,
    accordion,
    carrier_name,
    esim_avail_txt,
    imei_length,
    inv_frds,
    fb_txt,
    twitter_txt,
    mail_txt,
    imei_compatibility_info,
    close_img,
  } = cacheDataCompatibility ? cacheDataCompatibility : imeiContent || {};
  const haveTroubleHandler = async (e: any) => {
    GAAndFBEventsHandler(gaCategory.phoneCompatibility, having_trouble);
    {
      haveTrouble ? sethaveTrouble(false) : sethaveTrouble(true);
    }

    settarget(e.target);
  };
  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      checkClick ? () => null : checkHandler(e);
    }
  };

  return (
    (cacheDataCompatibility || imeiContent) && (
      <Box className={bring_your_phone_main}>
        <Box
          className={bring_your_phone_content}
          sx={{ m: { xs: "0 15px", md: "0 30px", lg: "0 10px" } }}
        >
          <Box className={compatibility_plan} onKeyPress={handleEnter}>
            {!isSuccess ? (
              <Box>
                <Box className={compatibility_plan_title}>{heading}</Box>

                <Box
                  className={compatibility_plan_input}
                  sx={{ width: { sm: "600px" }, margin: "30px auto" }}
                >
                  <TextFieldCust
                    value={imeiNumber}
                    label={imei_place_holder}
                    onChange={(e: any) => {
                      if (
                        /^[0-9 ]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        imeiHandler(e);
                      }
                    }}
                    type="text"
                    error={
                      imeiErr !== "" ||
                      (imeiNumber !== "" && !/^\d+$/.test(imeiNumber)) ||
                      (imeiNumber !== "" && imeiNumber.length !== 15) ||
                      (checkClick && imeiNumber === "")
                    }
                    helperText={
                      imeiErr
                        ? imeiErr
                        : imeiNumber !== "" && !/^\d+$/.test(imeiNumber)
                        ? imei_error1
                        : imeiNumber !== "" && imeiNumber.length !== 15
                        ? imei_error2
                        : checkClick && imeiNumber === ""
                        ? imei_error3
                        : ""
                    }
                    maxlength={15}
                  />

                  <Box className={limit_div}>
                    {imeiNumber && imeiNumber.length ? imeiNumber.length : "0"}
                    {imei_length}
                  </Box>

                  <Box
                    className={phone_trouble_div}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box className={above_email_text}>{dial_msg}</Box>
                    <Box>
                      <Box
                        className={having_trouble_div}
                        id="having-trouble-div"
                        onClick={haveTroubleHandler}
                      >
                        {having_trouble}
                      </Box>
                      <PopperCust
                        id={"having-trouble-div"}
                        open={haveTrouble}
                        anchorEl={target}
                      >
                        {haveTrouble && (
                          <Paper
                            elevation={0}
                            style={{
                              border: "1px solid rgba(0,0,0,.2)",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              className={have_trouble_tool_tip}
                              onClick={closeTroubleHandler}
                            >
                              <Box className={have_trouble_header}>
                                <Close
                                  onClick={closeTroubleHandler}
                                  className={close_icon}
                                />
                                <Box className={have_trouble_header_text}>
                                  {popover_title}
                                </Box>
                              </Box>
                              <Box className={have_trouble_detail_div}>
                                <Box className={detail_img_div}>
                                  <img
                                    src={popover_img1?.data?.attributes?.url}
                                    alt={
                                      popover_img1?.data?.attributes
                                        ?.alternativeText
                                    }
                                    className={detail_img}
                                  />
                                </Box>
                                <Box className={detail_content}>
                                  <Box className={detail_head}>
                                    {popover_text1}
                                  </Box>
                                  <Box
                                    className={`${detail_subtext} ${add_margin_bottom}`}
                                  >
                                    {popover_subtext1}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className={have_trouble_detail_div}>
                                <Box className={detail_img_div}>
                                  <img
                                    src={popover_img2?.data?.attributes?.url}
                                    alt={
                                      popover_img2?.data?.attributes
                                        ?.alternativeText
                                    }
                                    className={detail_img}
                                  />
                                </Box>
                                <Box className={detail_content}>
                                  <Box className={detail_head}>
                                    {popover_text2}
                                  </Box>
                                  <Box className={detail_subtext}>
                                    {popover_subtext2}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Paper>
                        )}
                      </PopperCust>
                    </Box>
                  </Box>

                  <Box className={input_div} sx={{ margin: "20px 0" }}>
                    <TextFieldCust
                      value={emailCompatability}
                      label={email_label}
                      onChange={emailCompatibilityHandler}
                      error={
                        emailErr !== "" ||
                        (emailCompatability !== "" &&
                          !validateEmail(emailCompatability)) ||
                        (checkClick && emailCompatability === "")
                      }
                      helperText={
                        emailErr
                          ? emailErr
                          : emailCompatability !== "" &&
                            !validateEmail(emailCompatability)
                          ? email_error1
                          : checkClick && emailCompatability === ""
                          ? email_error1
                          : ""
                      }
                    />
                  </Box>
                  <Box className={input_div} sx={{ margin: "20px 0" }}>
                    <TextFieldCust
                      value={name}
                      label={name_label}
                      onChange={nameHandler}
                    />
                  </Box>

                  {
                    <ButtonCust
                      sx={{ width: "100%" }}
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      loading={compatibilityLoading}
                      onClick={checkClick ? () => null : checkHandler}
                      disabled={
                        imeiErr !== "" ||
                        (imeiNumber !== "" && !/^\d+$/.test(imeiNumber)) ||
                        (imeiNumber !== "" && imeiNumber.length !== 15) ||
                        imeiNumber === ""
                      }
                    >
                      {button_1}
                    </ButtonCust>
                  }
                </Box>
              </Box>
            ) : (
              <Box>
                <Box
                  margin={"30px 0 0 30px"}
                  className={back_button}
                  onClick={backButtonClick}
                >
                  <ArrowBackIcon className={arrow_icon} />
                  <Box sx={{ fontFamily: "var(--font_family_Bold)" }}>
                    {button_2}
                  </Box>
                </Box>
                <Box
                  className={success_compatibility}
                  sx={{
                    width: { xs: "100%", sm: "600px", md: "700px" },
                    m: "auto",
                  }}
                >
                  <Box className={individual_row}>
                    <Box className={text_left}>{success_text1}</Box>
                    <Box className={text_right}>
                      {data?.supportedCarriers?.map((item: any) => {
                        return EnvConfig.CUSTOM_MOBILE_CARRIER_NAME && item
                          ? `${BrandNameUpdate(carrier_name)} `
                          : `${item} `;
                      })}
                    </Box>
                  </Box>
                  {data && data.make && data.model && (
                    <Box className={individual_row}>
                      <Box className={text_left}>{success_text2}</Box>
                      <Box className={text_right}>{`${data.make} ${data.model
                        .split(" ")
                        .splice(0, 3)
                        .join(" ")}`}</Box>
                    </Box>
                  )}
                  <Box className={individual_row}>
                    <Box className={text_left}>{success_text5}</Box>
                    <Box className={text_right}>{`${data && data.mode}`}</Box>
                  </Box>
                  <Box className={individual_row}>
                    <Box className={text_left}>{success_text3}</Box>
                    <Box className={text_right}>
                      {data?.wifiCalling?.toLowerCase() === "true"
                        ? yes_txt
                        : no_txt}
                    </Box>
                  </Box>
                  {Config.IS_ESIM_SUPPORTED && (
                    <Box className={individual_row}>
                      <Box className={text_left}>{esim_avail_txt}</Box>
                      <Box className={text_right}>
                        {data.esimAvailable ? yes_txt : no_txt}
                      </Box>
                    </Box>
                  )}
                  <Box className={individual_row}>
                    <Box className={text_left}>{success_text4}</Box>
                    <Box className={text_right}>
                      {data.isLostOrStolen?.toUpperCase() === "NA"
                        ? no_txt
                        : data?.isLostOrStolen}
                    </Box>
                  </Box>
                  {Config.COMPATIBILITY_CHECK_PROMO && (
                    <PromoCodeComp
                      imeiPormoCoupon={imeiPormoCoupon}
                      imeiPormoHeading={imeiPormoHeading}
                      imeiPormoText={imeiPormoText}
                      shareHandler={shareHandler}
                      shareImage={share_image?.data?.attributes}
                      closeShareHandler={closeShareHandler}
                      share={share}
                      shareTarget={shareTarget}
                      imeiSocialText={imeiSocialText}
                      imeiMailText={imeiMailText}
                      giftImage={gift_image?.data?.attributes}
                      twitter={twitter_img?.data?.attributes}
                      facebook={facebook_img?.data?.attributes}
                      mail={mail_img?.data?.attributes}
                      inv_frds={inv_frds}
                      fb_txt={fb_txt}
                      twitter_txt={twitter_txt}
                      mail_txt={mail_txt}
                    />
                  )}
                  <Box
                    className={view_plan_btn}
                    sx={{ textAlign: "center", my: "20px" }}
                  >
                    <ButtonCust
                      sx={{ width: { xs: "100%", lg: "200px" } }}
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      onClick={() => {
                        viewPlanHandler();
                        GAAndFBEventsHandler(
                          gaCategory.CheckCompatibility,
                          button_3
                        );
                      }}
                    >
                      {button_3}
                    </ButtonCust>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {!isSuccess && (
            <Box
              className={accordion_component}
              sx={{ width: { xs: "100%", sm: "600px" } }}
            >
              <Box className={heading_accordian}>{accordion_title}</Box>
              <Box>
                {accordion?.map((tab: any, index: any) => (
                  <Accordion
                    key={index}
                    elevation={0}
                    sx={{
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownIcon
                          sx={{ color: "var(--text_color)" }}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        color: "var(--text_color)",
                        cursor: "pointer",
                        height: "40px",
                        margin: { xs: "10px 0px", md: "0px 12px" },
                        padding: { xs: "0px 10px", sm: "0px 0px" },
                        fontSize: "14px",
                        fontWeight: "var(--font_weight_2)",
                      }}
                    >
                      {BrandNameUpdate(tab.heading)}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontSize: "14px",
                        fontWeight: "var(--font_weight_0)",
                        lineHeight: "1.75",
                        letterSpacing: "normal",
                        color: "var(--accordion-body_color)",
                        padding: "1rem 0.7rem",
                        textAlign: "left",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: BrandNameUpdate(tab.description),
                      }}
                    ></AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        {isFail && (
          <SimpleModal
            isShowModal={isFail}
            onCloseModal={closeCompatModal}
            showClose={true}
            className={compatibility_plan_bring}
          >
            {!modelCompat && (
              <>
                <Box
                  component="img"
                  sx={{ width: "40px", height: "40px" }}
                  src={close_img?.data?.attributes?.url}
                ></Box>
                <Typography
                  component="div"
                  sx={{
                    color: "var(--text-color)",
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "14px",
                    margin: "5px 0px",
                  }}
                >
                  {imei_compatibility_info &&
                    ModelNameUpdate(
                      BrandNameUpdate(
                        imei_compatibility_info?.model_compat_fail_msg
                      ),
                      getLastCharacter(compatibility?.data?.data?.model, 5)
                    )}
                </Typography>

                <Box className={try_again} onClick={tryAgainHandler}>
                  {button_5}
                </Box>
              </>
            )}
            {modelCompat && (
              <Box className={bummer_div}>
                <Box
                  component={"img"}
                  alt={fail_image?.data?.attributes?.alternativeText}
                  width="60px"
                  height="100%"
                  className={modal_image}
                  src={fail_image?.data?.attributes?.url}
                />

                <Box className={bummer_content}>
                  {BrandNameUpdate(fail_title)}
                </Box>
                {/* <Box className={shop_phone_button}>
                {button_4 && (
                  <ButtonCust
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    onClick={() => handleChange()}
                  >
                    {button_4}
                  </ButtonCust>
                )}
              </Box> */}
                <Box className={try_again} onClick={tryAgainHandler}>
                  {button_5}
                </Box>
              </Box>
            )}
          </SimpleModal>
        )}
      </Box>
    )
  );
};
