import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiEndPoints from "../../config/ApiEndPoints";
import apiJunction from "../../adapters/api";
import EnvConfig from "../../config/env.config.json";

interface ICustomerState {
  type4Tax: any;
  errorType4Tax: any;
  configInfo: any;
  errorConfigInfo: any;
  tax4Loader: boolean;
  customerTopUp: any;
  customerTopUpLoader: boolean;
  errorCustomerTopUp: any;
  custTopUpStatus: any;
  errorCustTopUpStatus: any;
  groupTopUp: any;
  errorGroupTopUp: any;
  group: any;
  errorGroup: any;
  getCCDetails: any;
  errorGetCCDetails: any;
  emailAvailability: any;
  errorEmailAvailability: any;
  secondaryUpdate: any;
  errorSecondaryUpdate: any;
  planChange: any;
  planChangeLoader: boolean;
  errorPlanChange: any;
  planChangeStatus: any;
  errorPlanChangeStatus: any;
  updateTopUp: any;
  errorUpdateTopUp: any;
  topUpUpdateLoader: boolean;
  referralCredits: any;
  errorReferralCredits: any;
  addLineLoader: boolean;
  topUpPulse: any;
  errorTopUpPulse: any;
  topUpPulseLoader: boolean;
  newLineDetails: {
    firstName: any;
    lastName: any;
    email: any;
    isEmailAvalable: any;
  }[];
}

const initialCustomerState: ICustomerState = {
  type4Tax: null,
  errorType4Tax: null,
  configInfo: null,
  errorConfigInfo: null,
  tax4Loader: false,
  customerTopUp: null,
  customerTopUpLoader: false,
  errorCustomerTopUp: null,
  custTopUpStatus: null,
  errorCustTopUpStatus: null,
  groupTopUp: null,
  errorGroupTopUp: null,
  group: null,
  errorGroup: null,
  getCCDetails: null,
  errorGetCCDetails: null,
  emailAvailability: null,
  errorEmailAvailability: null,
  secondaryUpdate: null,
  errorSecondaryUpdate: null,
  planChange: null,
  planChangeLoader: false,
  errorPlanChange: null,
  planChangeStatus: null,
  errorPlanChangeStatus: null,
  updateTopUp: null,
  errorUpdateTopUp: null,
  topUpUpdateLoader: false,
  referralCredits: null,
  errorReferralCredits: null,
  addLineLoader: false,
  topUpPulse: null,
  errorTopUpPulse: null,
  topUpPulseLoader: false,
  newLineDetails: [],
};

export const customerGroup = createAsyncThunk(
  "account/customerGroup",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.customerGroup}${action.groupId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getCC = createAsyncThunk(
  "account/getCC",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.creditCardDetails}${action.customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const configInfoCall = createAsyncThunk(
  "account/configInfoCall",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.configInfo}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const topUpPulseList = createAsyncThunk(
  "account/topUpPulseList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints?.topupPulse}`,
        tenantId: EnvConfig?.TENANT_ID,
        other1: EnvConfig?.OPERATOR,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const type4TaxCalculation = createAsyncThunk(
  "account/type4TaxCalculation",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.tax4Calculation}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const topUpCustomer = createAsyncThunk(
  "account/topUpCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.topUpCustomer}${
          action.taxInfo && action.taxInfo.customerId
        }`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const topUpCustomerStatus = createAsyncThunk(
  "account/topUpCustomerStatus",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.topUpCustomerStatus}${action.customerId}/topup`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const topUpGroup = createAsyncThunk(
  "account/topUpGroup",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.topUpGroup}${action.groupId}?endDate=${action.endDate}&startDate=${action.startDate}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkEmailAvailability = createAsyncThunk(
  "account/checkEmailAvailability",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.checkEmailAvailability}${action.email}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const ChangePlan = createAsyncThunk(
  "account/ChangePlan",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.changePlan}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const PlanChangeCustomerStatus = createAsyncThunk(
  "account/PlanChangeCustomerStatus",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.PlanChangeCustomerStatus}${action.customerId}/planchange`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const topUpUpdate = createAsyncThunk(
  "account/topUpUpdate",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.topUpUpdate}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const autoTopUp = createAsyncThunk(
  "account/autoTopUp",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.autoTopUp}${action.customerId}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateSecondaryCustomer = createAsyncThunk(
  "account/updateSecondaryCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.secondaryCustomerUpdate}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const customerDataCutOff = createAsyncThunk(
  "account/customerDataCutOff",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.dataCutOff}`,
        body: payload,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReferralCredits = createAsyncThunk(
  "account/getRefferalCredits",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.referralCredits}${action.groupId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
const accountSlice = createSlice({
  name: "account",
  initialState: initialCustomerState,
  reducers: {
    addLineMcLoader: (state, action) => {
      state.addLineLoader = action.payload;
    },
    updateNewLineDetails: (state, action) => {
      state.newLineDetails = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      type4TaxCalculation.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.type4Tax = action.payload.data.data;
        state.errorType4Tax = null;
        state.tax4Loader = false;
      }
    );
    builder.addCase(type4TaxCalculation.pending, (state) => {
      state.tax4Loader = true;
    });
    builder.addCase(
      type4TaxCalculation.rejected,
      (state, action: PayloadAction<any>) => {
        state.type4Tax = null;
        state.errorType4Tax = action.payload;
        state.tax4Loader = false;
      }
    );
    builder.addCase(
      configInfoCall.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.configInfo = action.payload.data.data;
        state.errorConfigInfo = null;
      }
    );
    builder.addCase(
      configInfoCall.rejected,
      (state, action: PayloadAction<any>) => {
        state.configInfo = null;
        state.errorConfigInfo = action.payload;
      }
    );
    builder.addCase(
      topUpCustomer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerTopUp = action.payload.data.data;
        state.errorCustomerTopUp = null;
        state.customerTopUpLoader = false;
      }
    );
    builder.addCase(topUpCustomer.pending, (state) => {
      state.customerTopUpLoader = true;
    });
    builder.addCase(
      topUpCustomer.rejected,
      (state, action: PayloadAction<any>) => {
        state.customerTopUp = null;
        state.customerTopUpLoader = false;
        state.errorCustomerTopUp = action.payload;
      }
    );
    builder.addCase(
      topUpCustomerStatus.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.custTopUpStatus = action.payload.data.data;
        state.errorCustTopUpStatus = null;
      }
    );
    builder.addCase(
      topUpCustomerStatus.rejected,
      (state, action: PayloadAction<any>) => {
        state.custTopUpStatus = null;
        state.errorCustTopUpStatus = action.payload;
      }
    );
    builder.addCase(
      topUpGroup.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.groupTopUp = action.payload.data.data;
        state.errorGroupTopUp = null;
      }
    );
    builder.addCase(
      topUpGroup.rejected,
      (state, action: PayloadAction<any>) => {
        state.groupTopUp = null;
        state.errorGroupTopUp = action.payload;
      }
    );
    builder.addCase(
      customerGroup.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.group = action.payload.data;
        state.errorGroup = null;
      }
    );
    builder.addCase(
      customerGroup.rejected,
      (state, action: PayloadAction<any>) => {
        state.group = null;
        state.errorGroup = action.payload;
      }
    );
    builder.addCase(getCC.fulfilled, (state, action: PayloadAction<any>) => {
      state.getCCDetails = action.payload.data.data;
      state.errorGetCCDetails = null;
    });
    builder.addCase(getCC.rejected, (state, action: PayloadAction<any>) => {
      state.getCCDetails = null;
      state.errorGetCCDetails = action.payload;
    });
    builder.addCase(
      checkEmailAvailability.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.emailAvailability = action.payload.data;
        state.errorEmailAvailability = null;
      }
    );
    builder.addCase(
      checkEmailAvailability.rejected,
      (state, action: PayloadAction<any>) => {
        state.emailAvailability = null;
        state.errorEmailAvailability = action.payload;
      }
    );
    builder.addCase(
      updateSecondaryCustomer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.secondaryUpdate = action.payload.data.data;
        state.errorSecondaryUpdate = null;
      }
    );
    builder.addCase(
      updateSecondaryCustomer.rejected,
      (state, action: PayloadAction<any>) => {
        state.secondaryUpdate = null;
        state.errorSecondaryUpdate = action.payload;
      }
    );
    builder.addCase(
      ChangePlan.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.planChange = action.payload.data;
        state.errorPlanChange = null;
        state.planChangeLoader = false;
      }
    );
    builder.addCase(ChangePlan.pending, (state) => {
      state.planChangeLoader = true;
    });
    builder.addCase(
      ChangePlan.rejected,
      (state, action: PayloadAction<any>) => {
        state.planChange = null;
        state.planChangeLoader = false;
        state.errorPlanChange = action.payload;
      }
    );
    builder.addCase(
      PlanChangeCustomerStatus.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.planChangeStatus = action.payload.data;
        state.errorPlanChangeStatus = null;
      }
    );
    builder.addCase(
      PlanChangeCustomerStatus.rejected,
      (state, action: PayloadAction<any>) => {
        state.planChangeStatus = null;
        state.errorPlanChangeStatus = action.payload;
      }
    );
    builder.addCase(
      topUpUpdate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateTopUp = action.payload.data;
        state.errorUpdateTopUp = null;
        state.topUpUpdateLoader = false;
      }
    );
    builder.addCase(topUpUpdate.pending, (state) => {
      state.topUpUpdateLoader = true;
    });
    builder.addCase(
      topUpUpdate.rejected,
      (state, action: PayloadAction<any>) => {
        state.updateTopUp = null;
        state.topUpUpdateLoader = false;
        state.errorUpdateTopUp = action.payload;
      }
    );
    builder.addCase(
      getReferralCredits.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.referralCredits = action.payload.data;
        state.errorReferralCredits = null;
      }
    );
    builder.addCase(
      getReferralCredits.rejected,
      (state, action: PayloadAction<any>) => {
        state.referralCredits = null;
        state.errorReferralCredits = action.payload;
      }
    );
    builder.addCase(
      topUpPulseList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.topUpPulse = action.payload.data;
        state.topUpPulseLoader = false;
        state.errorTopUpPulse = null;
      }
    );
    builder.addCase(
      topUpPulseList.pending,
      (state, action: PayloadAction<any>) => {
        state.topUpPulseLoader = true;
      }
    );
    builder.addCase(
      topUpPulseList.rejected,
      (state, action: PayloadAction<any>) => {
        state.topUpPulse = null;
        state.topUpPulseLoader = false;
        state.errorTopUpPulse = action.payload;
      }
    );
  },
});

export const { addLineMcLoader, updateNewLineDetails } = accountSlice.actions;
export default accountSlice.reducer;
