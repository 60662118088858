import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { detailCoverageStrapi } from "../../../features/strapi/coverageCheckSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { scroller } from "react-scroll";
import planCardStyle from "../../home/PlanCard/PlanSection.module.scss";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";

const DetailsCoverageComponent = isTemplateExisits(
  Config.CoverageCheck.DetailsCoverage
)
  ? require(`./${Config.CoverageCheck.DetailsCoverage.template}_DetailsCoverage`)
      .default
  : null;
export type DetailsCoverageProps = {
  coverageCheckContent?: any;
  buttonHandler?: any;
  currentdetails?: any;
};

export const DetailsCoverage = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;
  const [cacheCoverageDetails, setCacheCoverageDetails] = useState<any>(null);
  const { coverageCheckContent, errorCoverageContent } = useAppSelector(
    (state: any) => state?.strapi?.coverageCheck || {}
  );
  const { btn_navigation } = coverageCheckContent || {};

  useEffect(() => {
    getCache(
      "DetailsCheckCoverage",
      detailCoverageStrapi,
      setCacheCoverageDetails,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("DetailsCheckCoverage", coverageCheckContent);
  }, [coverageCheckContent]);

  const buttonHandler = (_: any) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.coverageCheck,
      category: gaCategory.detailCoverage,
      buttonValue: coverageCheckContent?.btn_value,
    });
    navigate(btn_navigation);

    setTimeout(function () {
      scroller.scrollTo(planCardStyle.home_page_title, {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }, 1000);
  };

  return coverageCheckContent || cacheCoverageDetails ? (
    <DetailsCoverageComponent
      coverageCheckContent={
        cacheCoverageDetails ? cacheCoverageDetails : coverageCheckContent
      }
      buttonHandler={buttonHandler}
      currentdetails={currentdetails}
    />
  ) : errorCoverageContent ? (
    <Box>{errorCoverageContent}</Box>
  ) : null;
};
