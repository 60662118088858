import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  discoverContent: any;
  errorDiscoverContent: any;
};

const initialState: InitialState = {
    discoverContent: null,
    errorDiscoverContent: null,
};

// Generates pending, fulfilled and rejected action types
export const fetchDiscoverContent = createAsyncThunk(
    "discover/fetchDiscoverContent",
    () => {
      return apiJunction.makeRequest({
        method: "get",
        url: `/${Config.Discover.DiscoverSection.template}${StrapiApiEndPoints.discoverContent}`,
        isStrapiApi: true,
      });
    }
  );
  const strapiDiscoverSlice = createSlice({
    name: "discover",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(
        fetchDiscoverContent.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.discoverContent = action.payload?.data?.data?.attributes;
          state.errorDiscoverContent = null;
        }
      );
      builder.addCase(fetchDiscoverContent.rejected, (state, action) => {
        state.discoverContent = null;
        state.errorDiscoverContent = action.error || "Something went wrong";
      });
    },
  });
  
  export default strapiDiscoverSlice.reducer;
  