import { Box, Typography } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import styles from "./PlanCard.module.scss";
import ENVConfig from "../../../config/env.config.json";
import unlimitedPlanDiscount from "../../../config/discountCouponsUnlimited.json";
import normalPlanDiscount from "../../../config/discountCouponsRegular.json";
import getSymbolFromCurrency from "currency-symbol-map";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { useNonInitialEffect } from "../../../utils/commonFunctions";
import { handleCheckout } from "../../../features/billCalculator/billCalculatorSlice";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { calculateCost, calculateUnlimitedCost } from "../../home/PlanCard/PlanCardsComponent";

export const BillCalculatorPlanCard = (props: any) => {
  const { plan, content, PurchaseJourneyCheck } = props;
  const dispatch = useAppDispatch();
  const { planData, selectedLines, isCheckout } = useAppSelector(
    (state: any) => state.billCalculator
  );
    
  useNonInitialEffect(() => {
    if (isCheckout) {
      const reachSelectedPlanDetail = {
        line: selectedLines,
        plan: planData
      }
      localStorage.setItem("reachSelectedPlanLine", JSON.stringify(selectedLines));
      localStorage.setItem("reachSelectedPlanDetail", JSON.stringify(reachSelectedPlanDetail));
      PurchaseJourneyCheck();
      dispatch(handleCheckout({isCheckout: false}));
    }
  }, [isCheckout])

  let totalPlanCost = 0;
  if(plan?.isUnlimited){
    totalPlanCost = calculateUnlimitedCost(
      planData,
      selectedLines
    )?.planCost
  }else{
    totalPlanCost = calculateCost(
      planData,
      selectedLines
    )?.planCost
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Box textAlign={{ xs: "center" }} className={styles.plan_card}>
          <Box
            sx={{
              fontSize: "20px",
              fontWeight: "var(--font_weight_4)",
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {plan?.displayName}
          </Box>
          {content?.shareable_text &&
            content?.plan_image2?.data?.attributes?.url && (
              <Box className={styles.planData}>
               { ENVConfig.BILLCALCULATOR_PLAN_CARD_SHARABLE_ICON_DATA_REQD && <Box
                  component="img"
                  src={
                    plan?.isUnlimited
                      ? content?.plan_image2?.data?.attributes?.url
                      : content?.plan_image?.data?.attributes?.url
                  }
                  alt={content?.plan_image?.data?.attributes?.alternativeText}
                  className={styles.planImg}
                />}
               { ENVConfig.BILLCALCULATOR_PLAN_CARD_SHARABLE_ICON_DATA_REQD && <Box className={styles.planRate}>
                  {" "}
                  {plan?.planData}
                  {plan?.dataUnit}{" "}
                </Box>}
                <Box className={styles.planText}>
                {ENVConfig.BILLCALCULATOR_PLAN_CARD_SHARABLE_ICON_DATA_REQD && `${" "} - ${" "}`}
                  {plan?.isUnlimited 
                    ? content?.non_shareable_text ? content?.non_shareable_text : ""
                    : content?.shareable_text}{" "}
                </Box>
              </Box>
            )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            textAlign={{ xs: "center" }}
            className={styles.separator}
            style={{ background: "var(--divider_color_2)" }}
          ></Box>
          {content?.plan_desc && (
            <Typography
              textAlign="center"
              fontFamily="var(--font_family_Medium)"
              fontSize="12px"
              px="16px"
              my="8px"
              sx={{ opacity: 0.6 }}
            >
              {BrandNameUpdate(content?.plan_desc)}
            </Typography>
          )}
          <Box
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
            className={styles.planCostData}
          >
            <Box
              sx={{
                fontFamily: "var(--font_family_Bold)",
                color: "var(--text_color)",
                fontSize: "24px",
                fontWeight: "var(--font_weight_4)",
              }}
              component="span"
            >
              {ENVConfig?.IS_DISCOUNT_NEEDED
                ? GetCurrencyFixedToTwo(
                    plan?.isUnlimited
                      ? plan?.baseLinePrice -
                          unlimitedPlanDiscount?.data?.discountInDollar
                      : plan?.baseLinePrice -
                          normalPlanDiscount?.data?.discountInDollar,
                    plan?.currency
                  )
                : GetCurrencyFixedToTwo(totalPlanCost, plan?.currency)}
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_5)",
                }}
                component="span"
              >
                {content?.per_month}&nbsp;{content?.tax_fees}
              </Box>
            </Box>
          </Box>
          {content?.addition_ln && (
            <Box
              px="16px"
              fontFamily="var(--font_family_Medium)"
              color="var(--primary_color)"
              fontSize="14px"
              lineHeight="1.5"
              letterSpacing="0.00938em"
            >
              {plan?.isUnlimited
                ? content?.unl_plan_desc
                : `${content?.addition_ln}: ${getSymbolFromCurrency(
                    plan?.currency
                  )}${
                    plan?.isUnlimited
                      ? plan?.additionalLinePrice -
                        unlimitedPlanDiscount?.data?.secondaryDiscountInDollar
                      : plan?.additionalLinePrice -
                        normalPlanDiscount?.data?.secondaryDiscountInDollar
                  } ${content?.upto} ${plan?.maxLines - 1} ${content?.line}`}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
