import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
    feedbackContent: any;
    errorFeedbackContent: any;
};

const initialState: InitialState = {
    feedbackContent: null,
    errorFeedbackContent: null
};

// Generates pending, fulfilled and rejected action types
export const reviewContent = createAsyncThunk(
    "reviews/reviewContent",
    () => {
      return apiJunction.makeRequest({
        method: "get",
        url: `/${Config.Reviews.Feedback.template}${StrapiApiEndPoints.reviews}`,
        isStrapiApi: true,
      });
    }
);

const strapiReviewSlice = createSlice({
    name: "reviews",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
      builder.addCase(
        reviewContent.fulfilled,
        (state: InitialState, action: PayloadAction<any>) => {
          state.feedbackContent = action?.payload?.data?.data?.attributes;
          state.errorFeedbackContent = null;
        }
      );
      builder.addCase(reviewContent.rejected, (state: InitialState, action: any) => {
        state.feedbackContent = null;
        state.errorFeedbackContent = action.error || "Something went wrong";
      });
    },
});
  
export default strapiReviewSlice.reducer;



