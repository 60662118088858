import { Grid } from "@mui/material";
import { InternalBanner } from "../common";
import HelpCenterM from "./HelpCenter/HelpCenter";
import ForSupport from "./ForSupport/ForSupport";
type Props = {
    strapiContent: any;
    onClickHandler: any;
};

const ContactUsComponent = (props: Props) => {
   
    return (
        <Grid
            container
        >
            <InternalBanner
                pageName={"ContactUs"}
                apiEndPoint={"contactUsBanner"}
            />
            <HelpCenterM content={props?.strapiContent}/>
            <ForSupport content={props?.strapiContent}/>
        </Grid>
    );
};

export default ContactUsComponent;
