import CachedIcon from "@mui/icons-material/Cached";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/env.config.json";
import {
  addRecoveryEmail,
  onSendOtp,
  onSkipAccountLock,
  onVerifyOtp,
  resetAddRecoveryEmail,
  resetVerifyOtpState,
} from "../../../../features/account-lock/accountLockSlice";
import { GetCustomer } from "../../../../features/checkout/checkoutSlice";
import { ACCOUNT_LOCK_STATUS } from "../../../../features/checkout/Types";
import { useNonInitialEffect } from "../../../../utils";
import { emailPattern } from "../../../../utils/regexPatterns";
import {
  ButtonCust,
  NotificationCust,
  SimpleModal,
  TextFieldCust,
} from "../../../widgets";

type Props = {
  display: boolean;
  closeHandler?: any;
  primaryEmail: string;
  recoveryEmail?: string;
  addRecoveryEmailOnly: boolean;
  updateRecoveryEmailModal: boolean;
  recoveryEmailVerified: boolean;
};
enum UI_STATE {
  CONFIRM_LOCK_WITH_RECOVERY_EMAIL,
  CONFIRM_LOCK_WITH_SKIP_RECOVERY_EMAIL,
  ADD_RECOVERY_EMAIL,
  UPDATE_RECOVERY_EMAIL,
  CONFIRM_SKIP_RECOVERY_EMAIL,
  CONFIRM_RECOVERY_EMAIL_OTP,
  PROCEED_WITHOUT_RECOVERY_EMAIL,
  ACCOUNT_LOCKED_WITHOUT_RECOVERY_EMAIL,
  ENTER_TOTP_WITH_RECOVERY_EMAIL,
  RECOVERY_EMAIL_ADDED,
  RECOVERY_EMAIL_UPDATED,
  SEND_OTP_RECOVERY_EMAIL,
  ACCOUNT_UNLOCK_WITH_RECOVERY_EMAIL,
  ACCOUNT_UNLOCK_WITHOUT_RECOVERY_EMAIL,
  CONFIRM_ACCOUNT_UNLOCK,
}
export const maskedEmail = (email: string) => {
  const [username, domain] = email.split("@");
  const maskedUsername =
    username.slice(0, Math.max(username.length / 2, 1)) +
    "*".repeat(username.length - Math.max(username.length / 2, 1));
  return maskedUsername + "@" + domain;
};
export const AccountLockModal = (props: Props) => {
  const {
    display,
    closeHandler,
    primaryEmail,
    recoveryEmail,
    recoveryEmailVerified,
    addRecoveryEmailOnly,
    updateRecoveryEmailModal,
  } = props;
  let customerDetails: any = Config?.IS_SSO_LOGIN
    ? JSON.parse(localStorage.getItem("ssoCustomerDetails") || "null")
    : JSON.parse(localStorage.getItem("customerDetail") || "null");

  const dispatch = useAppDispatch();

  const { fccAccountContent, fccAccountImages } = useAppSelector(
    (state: any) => state?.strapi?.accountLockSlice || {}
  );

  const { configInfo } = useAppSelector((state: any) => state.account);

  const {
    addRecoveryEmailSuccess,
    addRecoveryEmailLoading,
    errorAddRecoveryEmail,
    skipAccountLockLoading,
    sendOtpLoading,
    sendOtpSuccess,
    sendOtpError,
    verifyOtpLoading,
    verifyOtpSuccess,
    verifyOtpError,
  } = useAppSelector((state: any) => state?.accountLock);

  const accountLocked =
    customerDetails &&
    customerDetails.accAccessLock &&
    customerDetails.accAccessLock?.length > 0;
    const [uiState, setUiState] = useState<UI_STATE>(
      accountLocked
        ? UI_STATE.CONFIRM_ACCOUNT_UNLOCK
        : UI_STATE.CONFIRM_LOCK_WITH_SKIP_RECOVERY_EMAIL
    );
  const [resendTimer, setResendTimer] = useState(0);
  const [otpSubmitting, setOtpSubmitting] = useState(false);

  const resendOtpTimeDelay = configInfo?.configs?.RETRY_OTP_AFTER_SECONDS ?? 15;

  useEffect(() => {
    let intervalId: any;
    if (resendTimer > 0) {
      intervalId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [resendTimer]);
  useEffect(() => {
    return () => {
      dispatch(resetVerifyOtpState());
      dispatch(resetAddRecoveryEmail());
    };
  }, []);

  // useNonInitialEffect(() => {
  //   if (!errorAddRecoveryEmail && !addRecoveryEmailLoading) {
  //     setUiState(UI_STATE.SEND_OTP_RECOVERY_EMAIL);
  //   }
  // }, [addRecoveryEmailLoading]);

  useNonInitialEffect(() => {
    if (
      verifyOtpSuccess &&
      uiState !== UI_STATE.SEND_OTP_RECOVERY_EMAIL &&
      uiState !== UI_STATE.RECOVERY_EMAIL_ADDED &&
      uiState !== UI_STATE.CONFIRM_RECOVERY_EMAIL_OTP
    ) {
      if (!verifyOtpError && uiState !== UI_STATE.RECOVERY_EMAIL_UPDATED) {
        showSuccessMessageForLockUnlock();
      } else if (
        !verifyOtpError &&
        uiState === UI_STATE.RECOVERY_EMAIL_UPDATED &&
        updateRecoveryEmailModal
      ) {
        window.scrollTo(0, 0);
        closeHandler();
        NotificationCust({
          message: fccAccountContent?.recEmailUpdate,
          type: "success",
          duration: 5000,
          id: "RecoveryEmailUpdated",
          showIcon: true,
        });
      }
    } else if (
      sendOtpSuccess &&
      !configInfo?.configs?.ENABLE_OTP_VERIFICATION_FOR_ACCOUNT_LOCK
    ) {
      showSuccessMessageForLockUnlock();
    }
  }, [verifyOtpSuccess, verifyOtpError, uiState, sendOtpSuccess, sendOtpError]);

  const showSuccessMessageForLockUnlock = () => {
    window.scrollTo(0, 0);
    closeHandler();
    NotificationCust({
      message: accountLocked
        ? fccAccountContent?.successUnlock
        : fccAccountContent?.successLock,
      type: "success",
      duration: 5000,
      id: "AccountLockSuccess",
      showIcon: true,
    });
  };
  const formik = useFormik({
    initialValues: {
      recoveryEmail: recoveryEmail ?? "",
    },
    validationSchema: object({
      recoveryEmail: string()
        .trim()
        .required(fccAccountContent?.err1)
        .matches(emailPattern, fccAccountContent?.err2),
    }),
    onSubmit: () => {
      handleAddOrUpdateRecoveryEmail();
    },
    enableReinitialize: true,
  });
  const sendOtpFormik = useFormik({
    initialValues: { otp: "" },
    validationSchema: object({
      otp: string().trim().required(fccAccountContent?.err3),
    }),
    onSubmit: () => {
      handleSubmitOtp();
    },
    enableReinitialize: true,
  });
  const handleAddOrUpdateRecoveryEmail = async () => {
    const { recoveryEmail } = formik.values;
    const payload = {
      customerId: customerDetails?.id,
      lockStatus: ACCOUNT_LOCK_STATUS.RECOVERY_MAIL,
      recoveryEmail,
    };
    if (recoveryEmail === customerDetails?.emailId) {
      formik.setErrors({
        recoveryEmail: fccAccountContent?.err4,
      });
    } else {
      await dispatch(addRecoveryEmail(payload));
    }
  };
  const handleSubmitOtp = async () => {
    setOtpSubmitting(true);
    const { otp } = sendOtpFormik.values;
    const payload = {
      customerId: customerDetails?.id,
      lockStatus:
        uiState === UI_STATE.CONFIRM_RECOVERY_EMAIL_OTP
          ? ACCOUNT_LOCK_STATUS.RECOVERY_MAIL
          : accountLocked
          ? ACCOUNT_LOCK_STATUS.ACC_UNLOCK
          : ACCOUNT_LOCK_STATUS.ACC_LOCK,
      otp,
    };
    const res = await dispatch(onVerifyOtp(payload));
    if (res?.payload.data?.data?.msg === "OTP_VERIFIED") {
      await dispatch(
        GetCustomer({
          username: encodeURIComponent(customerDetails?.emailId),
        })
      );
      if (uiState === UI_STATE.CONFIRM_RECOVERY_EMAIL_OTP) {
        if (updateRecoveryEmailModal) {
          setUiState(UI_STATE.RECOVERY_EMAIL_UPDATED);
        } else {
          setUiState(UI_STATE.RECOVERY_EMAIL_ADDED);
        }
      }
    }
    sendOtpFormik.resetForm();
    setOtpSubmitting(false);
  };

  return (
    <SimpleModal
      isShowModal={display}
      onCloseModal={closeHandler}
      showClose={true}
      sx={{ width: { xs: "95%", sm: "50%", md: "45%", lg: "33%" } }}
      style={{ borderRadius: "4px" }}
    >
      {uiState !== UI_STATE.RECOVERY_EMAIL_ADDED &&
        uiState !== UI_STATE.RECOVERY_EMAIL_UPDATED && (
          <Box display="flex" alignItems="center">
            {
              <img
                src={fccAccountImages?.img1?.data?.attributes?.url}
                alt={"account-lock"}
              />
            }
          </Box>
        )}
      {uiState === UI_STATE.CONFIRM_LOCK_WITH_RECOVERY_EMAIL && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.m2h1}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "16px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {configInfo?.configs?.ENABLE_OTP_VERIFICATION_FOR_ACCOUNT_LOCK ? (
                <>
                  {fccAccountContent?.m2d1}{" "}
                  <b
                    style={{
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {recoveryEmail ?? ""}
                  </b>
                  {fccAccountContent?.m2d2}
                </>
              ) : (
                fccAccountContent?.m2d22
              )}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={async () => {
                  const payload = {
                    customerId: customerDetails?.id,
                    lockStatus: ACCOUNT_LOCK_STATUS.ACC_LOCK,
                  };
                  await dispatch(onSendOtp(payload));
                  setResendTimer(resendOtpTimeDelay);
                  setUiState(UI_STATE.ENTER_TOTP_WITH_RECOVERY_EMAIL);
                }}
                loading={sendOtpLoading}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.m2a1}
              </ButtonCust>
            </Box>
          </Box>
        </>
      )}
      {(uiState === UI_STATE.ENTER_TOTP_WITH_RECOVERY_EMAIL ||
        uiState === UI_STATE.CONFIRM_RECOVERY_EMAIL_OTP) && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <form
              onSubmit={sendOtpFormik.handleSubmit}
              autoComplete="off"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <>
                <Typography
                  sx={{
                    fontSize: "22px",
                    color: "var(--text_color)",
                    fontWeight: "var(--font_weight_4)",
                  }}
                >
                  {`${
                    UI_STATE.CONFIRM_RECOVERY_EMAIL_OTP
                      ? fccAccountContent?.m2h2
                      : accountLocked
                      ? fccAccountContent.m2h3
                      : fccAccountContent.m2h4
                  }`}
                </Typography>
                <TextFieldCust
                  type="password"
                  maxlength={6}
                  name="otp"
                  fullWidth
                  autoComplete="off"
                  value={sendOtpFormik.values.otp}
                  onBlur={sendOtpFormik.handleBlur}
                  placeholder="Enter OTP"
                  id="OTP"
                  onChange={(e: any) => {
                    if (verifyOtpError) {
                      dispatch(resetVerifyOtpState());
                    }
                    sendOtpFormik.handleChange(e);
                  }}
                  error={
                    sendOtpFormik.errors?.otp || verifyOtpError ? true : false
                  }
                  helperText={
                    <Typography sx={{ display: "flex" }}>
                      {verifyOtpError && (
                        <InfoIcon
                          style={{
                            color: "var(--danger)",
                            marginRight: "5px",
                          }}
                        />
                      )}{" "}
                      {verifyOtpError
                        ? verifyOtpError
                        : sendOtpFormik.errors?.otp}
                    </Typography>
                  }
                  sx={{ marginTop: "20px" }}
                />
                <Box pt={3} sx={{ width: "100%" }}>
                  <ButtonCust
                    variant="contained"
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    type="submit"
                    loading={verifyOtpLoading || otpSubmitting}
                    sx={{ width: "100%" }}
                  >
                    {fccAccountContent?.m2a2}
                  </ButtonCust>
                </Box>
                {sendOtpLoading || addRecoveryEmailLoading ? (
                  <CachedIcon
                    className="load_icon_gif"
                    fontSize="large"
                    sx={{ mt: "25px" }}
                  />
                ) : (
                  <Typography
                    component="div"
                    sx={{
                      fontSize: { xs: "13px", sm: "16px" },
                      color: "var(--grey)",
                      mt: "25px",
                    }}
                  >
                    {fccAccountContent?.m2d3}{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { xs: "13px", sm: "16px" },
                        color:
                          resendTimer > 0
                            ? "var(--grey)"
                            : "var(--primary_color)",
                        mt: "25px",
                        cursor: resendTimer > 0 ? "" : "pointer",
                      }}
                      onClick={async () => {
                        if (resendTimer <= 0) {
                          dispatch(resetVerifyOtpState());
                          if (uiState === UI_STATE.CONFIRM_RECOVERY_EMAIL_OTP) {
                            handleAddOrUpdateRecoveryEmail();
                          } else {
                            const payload = {
                              customerId: customerDetails?.id,
                              lockStatus: accountLocked
                                ? ACCOUNT_LOCK_STATUS.ACC_UNLOCK
                                : ACCOUNT_LOCK_STATUS.ACC_LOCK,
                            };
                            await dispatch(onSendOtp(payload));
                            setResendTimer(resendOtpTimeDelay);
                          }
                        }
                      }}
                    >
                      <>
                        {resendTimer > 0 ? (
                          <>
                            {fccAccountContent?.m2d4}{" "}
                            <b
                              style={{
                                fontFamily: "var(--font_family_Bold)",
                              }}
                            >
                              {resendTimer} {fccAccountContent?.seconds}
                            </b>
                          </>
                        ) : (
                          fccAccountContent?.m2d5
                        )}
                      </>
                    </Typography>
                  </Typography>
                )}
              </>
            </form>
          </Box>
        </>
      )}
      {uiState === UI_STATE.ADD_RECOVERY_EMAIL && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <>
                <Typography
                  sx={{
                    fontSize: "22px",
                    color: "var(--text_color)",
                    fontWeight: "var(--font_weight_4)",
                  }}
                >
                  {fccAccountContent?.action_add}
                </Typography>
                <TextFieldCust
                  type="text"
                  name="recoveryEmail"
                  fullWidth
                  value={formik.values.recoveryEmail}
                  onBlur={formik.handleBlur}
                  placeholder={fccAccountContent?.m2h4}
                  id="Recovery-email"
                  onChange={(e: any) => {
                    if (errorAddRecoveryEmail) {
                      dispatch(resetAddRecoveryEmail());
                    }
                    formik.handleChange(e);
                  }}
                  error={
                    formik.errors?.recoveryEmail || errorAddRecoveryEmail
                      ? true
                      : false
                  }
                  helperText={
                    <>
                      {errorAddRecoveryEmail
                        ? errorAddRecoveryEmail
                        : formik.errors?.recoveryEmail}
                    </>
                  }
                  sx={{ marginTop: "20px" }}
                />
                <Box pt={3} sx={{ width: "100%" }}>
                  <ButtonCust
                    variant="contained"
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    type="submit"
                    loading={addRecoveryEmailLoading}
                    sx={{ width: "100%" }}
                  >
                    {fccAccountContent?.submit}
                  </ButtonCust>
                </Box>
                {addRecoveryEmailOnly ||
                (customerDetails &&
                  customerDetails.accAccessLock &&
                  customerDetails.accAccessLock?.length > 0) ? null : (
                  <>
                    {/* {skipAccountLockLoading ? (
                      <CachedIcon
                        className="load_icon_gif"
                        fontSize="large"
                        sx={{ mt: "25px" }}
                      />
                    ) : ( */}
                    <Typography
                      component="div"
                      sx={{
                        fontSize: { xs: "13px", sm: "16px" },
                        color: "var(--primary_color)",
                        mt: "25px",
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        const customerId = customerDetails?.id;
                        dispatch(onSkipAccountLock(customerId));
                        setUiState(
                          UI_STATE.CONFIRM_LOCK_WITH_SKIP_RECOVERY_EMAIL
                        );
                      }}
                    >
                      {fccAccountContent?.skip}
                    </Typography>
                    {/* // )} */}
                  </>
                )}
              </>
            </form>
          </Box>
        </>
      )}
      {uiState === UI_STATE.UPDATE_RECOVERY_EMAIL && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "var(--text_color)",
                    fontWeight: "var(--font_weight_4)",
                  }}
                >
                  {fccAccountContent?.m2h5}
                </Typography>
                <TextFieldCust
                  type="text"
                  name="recoveryEmail"
                  fullWidth
                  value={formik.values.recoveryEmail}
                  onBlur={formik.handleBlur}
                  placeholder={fccAccountContent?.m2d6}
                  id="New Recovery email"
                  onChange={(e: any) => {
                    formik.handleChange(e);
                  }}
                  error={formik.errors?.recoveryEmail ? true : false}
                  helperText={<>{formik.errors?.recoveryEmail}</>}
                  sx={{ marginTop: "20px" }}
                />
                <Box pt={3} sx={{ width: "100%" }}>
                  <ButtonCust
                    variant="contained"
                    loading={addRecoveryEmailLoading}
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    type="submit"
                    sx={{ width: "100%" }}
                  >
                    {fccAccountContent?.submit}
                  </ButtonCust>
                </Box>
              </>
            </form>
          </Box>
        </>
      )}
      {uiState === UI_STATE.CONFIRM_LOCK_WITH_SKIP_RECOVERY_EMAIL && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.m2h6}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "16px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {configInfo?.configs?.ENABLE_OTP_VERIFICATION_FOR_ACCOUNT_LOCK ? (
                <>
                  {fccAccountContent?.m2d7}{" "}
                  <b
                    style={{
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {customerDetails?.emailId}
                  </b>
                  {fccAccountContent?.m2d8}
                </>
              ) : (
                fccAccountContent?.m2d21
              )}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={async () => {
                  const payload = {
                    customerId: customerDetails?.id,
                    lockStatus: ACCOUNT_LOCK_STATUS.ACC_LOCK,
                  };
                  await dispatch(onSendOtp(payload));
                  if (
                    configInfo?.configs
                      ?.ENABLE_OTP_VERIFICATION_FOR_ACCOUNT_LOCK
                  ) {
                    setResendTimer(resendOtpTimeDelay);
                    setUiState(UI_STATE.ENTER_TOTP_WITH_RECOVERY_EMAIL);
                  }
                }}
                loading={sendOtpLoading}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.m2a1}
              </ButtonCust>
            </Box>
            <Typography
              component="div"
              sx={{
                fontSize: { xs: "13px", sm: "16px" },
                color: "var(--primary_color)",
                mt: "25px",
                cursor: "pointer",
              }}
              onClick={closeHandler}
            >
              {fccAccountContent?.cancel}
            </Typography>
          </Box>
        </>
      )}
      {uiState === UI_STATE.PROCEED_WITHOUT_RECOVERY_EMAIL && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.action_add}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "14px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {fccAccountContent?.m2d9}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={async () => {
                  const payload = {
                    customerId: customerDetails?.id,
                    lockStatus: ACCOUNT_LOCK_STATUS.ACC_LOCK,
                  };
                  // logic to handle the condition whether otp is needed or not
                  await dispatch(onSendOtp(payload));
                  setResendTimer(resendOtpTimeDelay);
                  setUiState(UI_STATE.ENTER_TOTP_WITH_RECOVERY_EMAIL);
                }}
                loading={sendOtpLoading}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.m2a3}
              </ButtonCust>
            </Box>
            <Typography
              component="div"
              sx={{
                fontSize: { xs: "13px", sm: "16px" },
                color: "var(--primary_color)",
                mt: "25px",
                cursor: "pointer",
              }}
              onClick={closeHandler}
            >
              {fccAccountContent?.cancel}
            </Typography>
          </Box>
        </>
      )}
      {uiState === UI_STATE.ACCOUNT_LOCKED_WITHOUT_RECOVERY_EMAIL && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.m2h7}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "14px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {fccAccountContent?.m2d10} {primaryEmail}{" "}
              {fccAccountContent?.m2d11}
            </Typography>
          </Box>
        </>
      )}
      {uiState === UI_STATE.SEND_OTP_RECOVERY_EMAIL && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: updateRecoveryEmailModal ? "18px" : "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {updateRecoveryEmailModal
                ? fccAccountContent?.action_update
                : fccAccountContent?.action_add}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "16px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {fccAccountContent?.m2d12}{" "}
              <b
                style={{
                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {formik.values?.recoveryEmail ?? ""}
              </b>
              {fccAccountContent?.m2d13}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  setResendTimer(resendOtpTimeDelay);
                  setUiState(UI_STATE.CONFIRM_RECOVERY_EMAIL_OTP);
                }}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.continue}
              </ButtonCust>
            </Box>
          </Box>
        </>
      )}
      {(uiState === UI_STATE.RECOVERY_EMAIL_ADDED ||
        uiState === UI_STATE.RECOVERY_EMAIL_UPDATED) && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <Box display="flex" alignItems="center">
              {
                <img
                  src={fccAccountImages?.img2?.data?.attributes?.url}
                  alt={"recovery-add-success"}
                />
              }
            </Box>
            <Typography
              sx={{
                fontSize: updateRecoveryEmailModal ? "16px" : "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.m2d14}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "14px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {uiState === UI_STATE.RECOVERY_EMAIL_ADDED
                ? fccAccountContent?.m2d15
                : fccAccountContent?.m2d23}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={closeHandler}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.dismiss}
              </ButtonCust>
            </Box>
          </Box>
        </>
      )}
      {uiState === UI_STATE.ACCOUNT_UNLOCK_WITHOUT_RECOVERY_EMAIL && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.m2d16}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "16px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {fccAccountContent?.m2d17}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  setUiState(UI_STATE.ADD_RECOVERY_EMAIL);
                }}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.action_add}
              </ButtonCust>
            </Box>
            <Typography
              component="div"
              sx={{
                fontSize: { xs: "13px", sm: "16px" },
                color: "var(--primary_color)",
                mt: "25px",
                cursor: "pointer",
              }}
              onClick={() => setUiState(UI_STATE.CONFIRM_ACCOUNT_UNLOCK)}
            >
              {fccAccountContent?.m2d18}
            </Typography>
          </Box>
        </>
      )}
      {uiState === UI_STATE.CONFIRM_ACCOUNT_UNLOCK && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            pt={0.5}
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.m2a4}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "16px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {recoveryEmail && recoveryEmailVerified
                ? fccAccountContent?.m2d19
                : fccAccountContent?.m2d20}{" "}
              {!recoveryEmail || !recoveryEmailVerified ? (
                <b
                  style={{
                    fontFamily: "var(--font_family_Bold)",
                  }}
                >
                  {customerDetails.emailId ?? ""}
                </b>
              ) : null}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={async () => {
                  const payload = {
                    customerId: customerDetails?.id,
                    lockStatus: ACCOUNT_LOCK_STATUS.ACC_UNLOCK,
                  };
                  await dispatch(onSendOtp(payload));
                  setResendTimer(resendOtpTimeDelay);
                  setUiState(UI_STATE.ENTER_TOTP_WITH_RECOVERY_EMAIL);
                }}
                loading={sendOtpLoading}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.m2a5}
              </ButtonCust>
            </Box>
          </Box>
        </>
      )}
    </SimpleModal>
  );
};
