import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
import { dataUsageContent } from "../../../../features/strapi/myAccountSlice";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
const DataUsageAccount = isTemplateExisits(Config.MyAccount.DataUsage)
  ? require(`./${Config.MyAccount.DataUsage.template}_DataUsageTable`).default
  : null;

type Props = {};

export const DataUsageTable = (props: Props) => {
  const [cacheDataUsuageTable, setCacheDataUsuageTable] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { UsageContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  const [usageContent, setUsageContent] = useState<any>("");
  useEffect(() => {
    dispatch(dataUsageContent());
    getCache(
      "DataUsuageTable-MyAccount",
      dataUsageContent,
      setCacheDataUsuageTable,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    setUsageContent(UsageContent?.Usage_table);
    addMultipleCacheData("DataUsuageTable-MyAccount", UsageContent?.Usage_table);
  }, [UsageContent]);
  return usageContent || cacheDataUsuageTable ? (
    <DataUsageAccount
      dataUsageContent={
        cacheDataUsuageTable ? cacheDataUsuageTable : usageContent
      }
    />
  ) : null;
};
