import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
    secondaryUserContent: any;
    errorSecondaryUsercontent: any;
};

const initialState: InitialState = {
    secondaryUserContent: null,
    errorSecondaryUsercontent: null,
};

// Generates pending, fulfilled and rejected action types
export const secondaryUserHomeContent = createAsyncThunk(
    "secondarylogin/secondaryUserHomeContent",
    () => {
        return apiJunction.makeRequest({
            method: "get",
            url: `/${Config.SecondaryLogin.template}${StrapiApiEndPoints.secondaryUserLogin}`,
            isStrapiApi: true,
        });
    }
);

const secondaryUserHomeSlice = createSlice({
    name: "secondaryUserHome",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(secondaryUserHomeContent.fulfilled, (state, action: PayloadAction<any>) => {
            state.secondaryUserContent = action.payload?.data?.data?.attributes;
            state.errorSecondaryUsercontent = null;
        });
        builder.addCase(secondaryUserHomeContent.rejected, (state, action) => {
            state.secondaryUserContent = null;
            state.errorSecondaryUsercontent = action.error || "Something went wrong";
        });
    },
});

export default secondaryUserHomeSlice.reducer;
