import { Grid, Typography, Link } from "@mui/material";
import styles from "../S1_ViewCredits.module.scss";
import { v4 as uuid } from "uuid";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import Config from "../../../../../config/env.config.json";

type props = {
  setReminderModal: any;
  viewMore: boolean;
  pendingUsers: any;
  content: any;
};
export const PendingCreditListItem = (props: props) => {
  const { setReminderModal, viewMore, pendingUsers, content } = props;
  let pendingUsersList =
    viewMore && pendingUsers.length > Config?.NUMBER_OF_USERS_PENDING_REFERRALS
      ? pendingUsers.slice(0, Config?.NUMBER_OF_USERS_PENDING_REFERRALS)
      : pendingUsers;
  return pendingUsersList?.map((item: any) => {
    return (
      <Grid
        key={uuid()}
        className={styles.pending_credit_list}
        width={viewMore ? { xs: "100%", md: "90%" } : {}}
      >
        <Grid display="flex">
          <img
            style={{ height: "36px", width: "36px" }}
            src={content?.profile_img?.data?.attributes?.url || ""}
            alt={content?.profile_img?.data?.attributes?.alternativeText || ""}
          ></img>
          &ensp;
          <Grid display="flex" flexDirection="column">
            <Typography
              fontSize="14px"
              pr="10px"
              maxWidth={{ xs: "100%", sm: "340px" }}
              lineHeight="1.3"
              fontFamily="var(--font_family_Medium)"
              textAlign="left"
            >
              <span style={{ fontWeight: "var(--font_weight_2)" }}>
                {item?.name}
              </span>{" "}
              {content?.acptd_txt}
            </Typography>
            <Link
              className={styles.reminder_link}
              onClick={() => {
                setReminderModal({ modal: true, item });
              }}
            >
              {content?.send_btn}
            </Link>
          </Grid>
        </Grid>
        <Grid display="flex" flexDirection="column" alignItems="flex-end">
          <Typography
            fontWeight="var(--font_weight_2)"
            fontFamily="var(--font_family_Bold)"
          >
            {GetCurrencyFixedToTwo(Config.REFERRER_DISC_IN_DOLLAR)}
          </Typography>
          <Typography
            color="var( --grey_shade)"
            fontWeight="var(--font_weight_1)"
            fontFamily="var(--font_family_Medium)"
          >
            {content?.pending_txt}
          </Typography>
        </Grid>
      </Grid>
    );
  });
};
