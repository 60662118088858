// Get browser and operating system details with version
export function getBrowserAndOsDetails() {
  // Get the user agent string
  var userAgent = navigator.userAgent;

  // Set default values
  var browser = "Unknown";
  var browserVersion = "Unknown";
  var operatingSystem = "Unknown";
  var osVersion = "";

  // Detect browser and version
  if (/Firefox\/([0-9.]+)/.test(userAgent)) {
    browser = "mozilla firefox";
    browserVersion = RegExp.$1;
  } else if (/Chrome\/([0-9.]+)/.test(userAgent)) {
    browser = "chrome";
    browserVersion = RegExp.$1;
  } else if (/Version\/([0-9.]+).*Safari/.test(userAgent)) {
    browser = "apple safari";
    browserVersion = RegExp.$1;
  } else if (/Opera\/([0-9.]+)/.test(userAgent)) {
    browser = "opera";
    browserVersion = RegExp.$1;
  } else if (/Edge\/([0-9.]+)/.test(userAgent)) {
    browser = "microsoft edge";
    browserVersion = RegExp.$1;
  } else if (
    /MSIE\s([0-9.]+)/.test(userAgent) ||
    /Trident\/.*rv:([0-9.]+)/.test(userAgent)
  ) {
    browser = "internet explorer";
    browserVersion = RegExp.$1;
  }

  // Detect operating system and version
  if (/Windows NT\s([0-9.]+)/.test(userAgent)) {
    operatingSystem = "windows";
    osVersion = RegExp.$1;
  } else if (/Mac OS X\s([0-9_]+)/.test(userAgent)) {
    operatingSystem = "macOS";
    osVersion = RegExp.$1.replace(/_/g, ".");
  } else if (/Linux/.test(userAgent)) {
    operatingSystem = "linux";
  } else if (/Android\s([0-9.]+)/.test(userAgent)) {
    operatingSystem = "android";
    osVersion = RegExp.$1;
  } else if (/OS\s([0-9_]+).*like\sMac OS X/.test(userAgent)) {
    operatingSystem = "iOS";
    osVersion = RegExp.$1.replace(/_/g, ".");
  }

  // Return an object with browser, browser version, operating system, and OS version details
  return {
    browser: browser,
    browserVersion: browserVersion,
    operatingSystem: operatingSystem,
    osVersion: osVersion,
  };
}
