import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { compareGraphStrapi } from "../../../features/strapi/billCalculatorSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const CompareGraphComponent = isTemplateExisits(
  Config.BillCalculator.CompareGraph
)
  ? require(`./${Config.BillCalculator.CompareGraph.template}_CompareGraph`)
      .default
  : null;
type Props = {
  pageHandler: any;
};
export const CompareGraph = (props: Props) => {
  const { pageHandler } = props;
  const [cacheCompareGraph, setCacheCompareGraph] = useState<any>(null);
  const { graphData } = useAppSelector(
    (state: any) => state.strapi.billCalculator
  );
  const { comparePlanFormValues, planCost } = useAppSelector(
    (state: any) => state.billCalculator
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache(
      "compareGraphBillCalculator",
      compareGraphStrapi,
      setCacheCompareGraph,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("compareGraphBillCalculator", graphData);
  }, [graphData]);


  return (graphData || cacheCompareGraph) ? (
    <CompareGraphComponent
      reachCost={planCost}
      carrierCost={comparePlanFormValues?.currentAmount}
      data={cacheCompareGraph ? cacheCompareGraph : graphData}
      pageHandler={pageHandler}
    />
  ) : null;
};
