import { Box, Typography, ClickAwayListener } from "@mui/material";
import { whereWeGiveProps } from "./WhereWeGive";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import styles from "./S1_WhereWeGive.module.scss";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CustToolTip } from "../../widgets";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../config/env.config.json";
import clsx from "clsx";

const WhereWeGive = (props: whereWeGiveProps) => {
  const { content } = props;
  const geoUrl = EnvConfig.MAP_GEOURL;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.giveBack,
      category: categoryName,
      buttonValue: value,
    });
  };
  const [open, setOpen] = useState(false);
  const openPopper = (data: any) => {
    GAAndFBEventsHandler(gaCategory.whereWeGive, data.place);
    setAnchorEl(data?.place);
    setOpen(true);
  };
  const handleChange = (name: any) => {
    GAAndFBEventsHandler(gaCategory.whereWeGive, name);
  };

  const onClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Box className={styles.tooltip_main_container}>
          <ClickAwayListener onClickAway={onClose}>
            <CloseIcon onClick={onClose} className={styles.close_icon} />
          </ClickAwayListener>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={styles.tooltip_content_div}
          >
            <ClickAwayListener onClickAway={onClose}>
              <Box className={styles.map_image_div}>
                <img
                  src={data && data?.logo?.data?.attributes?.url}
                  alt="image"
                  className={styles.map_image}
                />
              </Box>
            </ClickAwayListener>
            <Typography className={styles.map_location}>
              {data && data?.place}
            </Typography>
            <Typography
              className={clsx(styles.tooltip_description, styles.map_location)}
            >
              <a
                href={data?.link}
                onClick={() => handleChange(data.name)}
                target="_blank"
              >
                {data?.name}&nbsp;
              </a>
              {data?.description}
            </Typography>
          </Box>
        </Box>
      </ClickAwayListener>
    );
  };
  return (
    <>
      <Box
        sx={{
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0",
            sm: "calc(var(--ipad_section_padding)/2) 0",
            md: "calc(var(--desktop_section_padding)/2) 0",
          },
        }}
        className={styles.where_we_give_main_container}
      >
        <Box className={styles.where_we_give_title_container}>
          <Typography variant="h3" className={styles.where_we_give_title}>
            {content?.heading2}
          </Typography>
          <Box
            sx={{ mt: "20px", mx: "auto", mb: { xs: "20px", sm: "40px" } }}
            className={styles.where_we_give_line}
          />
        </Box>
        <Box className={styles.map_component}>
          <ComposableMap projection="geoMercator" viewBox="0 0 800 500">
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    fill="#dddddd"
                    stroke="var(--white)"
                    key={geo.rsmKey}
                    geography={geo}
                  />
                ))
              }
            </Geographies>
            {content &&
              content?.ptr_list &&
              content?.ptr_list.length > 0 &&
              content?.ptr_list.map((data: any) => {
                return (
                  <Marker
                    coordinates={data && [data?.longitude, data?.latitude]}
                    key={data && data?.place}
                    id={data && data?.place}
                    onClick={() => {
                      openPopper(data);
                    }}
                  >
                    <CustToolTip
                      title={PoperOver(data)}
                      open={
                        open ? (anchorEl === data?.place ? true : false) : false
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                    >
                      <svg
                        height={25}
                        width={25}
                        viewBox="0 0 25 25"
                        style={{ borderRadius: "50%" }}
                      >
                        <circle
                          cx="50%"
                          cy="50%"
                          r="4px"
                          className={styles.innerCircle}
                        />
                        <circle
                          cx="50%"
                          cy="50%"
                          r="7px"
                          className={styles.outerCircle}
                        />
                        <circle
                          cx="50%"
                          cy="50%"
                          r="10px"
                          className={`${styles.circleAnimation} ${styles.pulse} ${styles.markers_div}`}
                        />
                      </svg>
                    </CustToolTip>
                  </Marker>
                );
              })}
          </ComposableMap>
        </Box>
      </Box>
    </>
  );
};
export default WhereWeGive;
