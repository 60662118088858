import { Grid, Typography, Box, Skeleton, Card, Divider } from "@mui/material";
import "./T1_PlanItem.scss";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import Config from "../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../Types/GoogleAnalytics";
import { BrandNameUpdate, caclulatePlanCostBasedOnEligibility, generateImageContainer, gtmTagManager, renderTextStyles } from "../../utils/commonFunctions";
import { GetCurrencyFixedToTwo } from "../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useLocation } from "react-router-dom";
import { RoutingURLs } from "../../config/RoutingURLs";
import { NutritionLabel } from "../home/PlanCard/NutritionLabel/NutritionLabel";
import { ButtonCust, SimpleModal } from "../widgets";
import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import clsx from "clsx";
import { KeyboardArrowUp } from "@mui/icons-material";
import _ from "lodash";
import { calculateCost, calculateUnlimitedCost } from "../home/PlanCard/PlanCardsComponent";
import unlimitedPlanDiscount from "../../../src/config/discountCouponsRegular.json";
import EnvConfig from "../../../src/config/env.config.json";

interface T1_PlanItemProps {
  selectedPlan?: any;
  plan?: any;
  activePlanName?: any;
  key?: string | number;
  handlePlanSelection: any;
  content: any;
  currentPlanBorder: boolean;
  user: any;
  planIndex: string | number;
  planContent?: any;
  SelectPlanAndProceedHandler?: any;
  isTopUpInitiated?: any;
  isPlanChangeInitiated?: any;
}
export const T1_PlanItem = ({
  selectedPlan,
  plan,
  activePlanName,
  key,
  handlePlanSelection,
  content,
  currentPlanBorder,
  user,
  planIndex,
  planContent,
  SelectPlanAndProceedHandler,
  isTopUpInitiated,
  isPlanChangeInitiated
}: T1_PlanItemProps) => {
  console.log(selectedPlan)
  const location = useLocation();
  const isCheckoutPage = location.pathname === RoutingURLs.checkout;
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const [selectedPlanDetail, setSelectedPlanDetail] = useState<any>([]);
  const [nlPlan, setNlPlan] = useState<any>();
  const [showNlModal, setShowNlModal] = useState(false);
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };
  const calculateCost = (
    data: any,
    selectedLine: any = 1,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;
    const planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data?.additionalLinePrice;
    let planPerLinesCost = 0;
    if (isRounded) planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
    else planPerLinesCost = planCost / SELECTED_LINE;
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const calculateUnlimitedCost = (
    data: any,
    selectedLine: any = 1,
    isRounded?: boolean
  ) => {
    const SELECTED_LINE = selectedLine;

    let planCost = 0;
    let planPerLinesCost = 0;
    if (data?.addLineSplitPrice) {
      let linePrice1 = 0;
      for (let i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data?.additionalLinePrice;
      planPerLinesCost = planCost / SELECTED_LINE;
    }
    if (isRounded) {
      planCost = Math.ceil(planCost);
      planPerLinesCost = Math.ceil(planPerLinesCost);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const planData = content?.pl_data
    ? content?.pl_data?.replace("{plan}", `${plan?.planData}${plan?.dataUnit}`)
    : null;
  const planFeature = content?.p_i_feature?.find(
    (fItem: any, fIndex: number) => {
      return fIndex === planIndex;
    }
  )?.item;
  const { planCost, planPerLinesCost } = plan?.unlimited
            ? calculateUnlimitedCost(plan)
            : calculateCost(plan);
  return (
    <Box>
      {!user?.reachPlanChangeDate && user?.amendedReachPlanId === plan?.name ? (
        <Box className="move_next_month_label">{content?.move_next_month}</Box>
      ) : activePlanName === plan?.name ? (
        <Box className="active_item_label">{content?.active_label}</Box>
      ) : null}
      <Box
        key={key}
        onClick={
          activePlanName === plan?.name ||
          (!user?.reachPlanChangeDate &&
            user?.amendedReachPlanId === plan?.name)
            ? () => null
            : () => {
                handlePlanSelection(plan);
                GAAndFBEventsHandler(gaCategory.changePlan, plan?.name);
              }
        }
        className={
          activePlanName === plan?.name
            ? "active_plan"
            : !user?.reachPlanChangeDate &&
              user?.amendedReachPlanId === plan?.name
            ? "cursor_block"
            : "in_active_plan"
        }
        id={currentPlanBorder ? "actvie_card_border" : "none"}
      >
        <Box className={
            JSON.stringify(selectedPlan) === JSON.stringify(plan)
              ? "CheckoutCard ActiveCard"
              : "CheckoutCard"
          }>
        <Box
          sx={{
            display: "flex", justifyContent: "space-between"
          }}
        >
          <Box className="leftDiv">
            <Box className="cardTitle">{plan?.displayNameWeb}</Box>
            {Config?.IS_SHOW_PLAN_DATA_IN_CHANGE_PLAN && isCheckoutPage ? (
              <Box className="cardSubTitle">
                {plan?.isUnlimited ? (
                  content?.unlimited
                ) : (
                  <>
                    {plan?.planData}
                    {plan?.dataUnit}
                  </>
                )}
              </Box>
            ) : null}
          </Box>
          <Box className="rightDiv">
            <Box className="gbData">
              {`$${plan?.baseLinePrice.toFixed(2)}`}
              <span
                className={
                  JSON.stringify(selectedPlan) === JSON.stringify(plan)
                    ? "monthDataActive"
                    : "monthData"
                }
              >
                {content?.line_unit}
              </span>
            </Box>
            <Box className="sharable">
              {planFeature ? (
                <Box sx={{ lineHeight: "15px", paddingY: "5px" }}>
                  {planFeature}
                </Box>
              ) : null}
              {content?.person_icon?.data?.attributes?.url &&
                generateImageContainer({
                  data: plan?.isUnlimited
                    ? content?.person_icon?.data?.attributes?.url
                    : content?.ppl_icon?.data?.attributes?.url,
                  attrs: { height: "18px", width: "22px" },
                  overrides: { sx: { mx: "8px" } },
                })}
              {content?.sharable_lbl && plan?.isUnlimited
                ? content?.fixed_lable2
                  ? `${content?.fixed_lable1} ${plan?.maxLines} ${content?.fixed_lable2}`
                  : content?.fixed_lable1
                : content?.sharable_lbl}
              {planData ? <Box>{planData}</Box> : null}
            </Box>
            {content?.add_line_label ? (
              plan?.addLineSplitPrice ? (
                content?.save_text && (
                  <Box className="addLine">
                    {content?.save_text}{" "}
                    <span className="perMonth">{content?.per_line_price}</span>
                    {content?.every_line}
                  </Box>
                )
              ) : (
                <Box className="addLine">
                  {content?.add_line_label}{" "}
                  <span className="perMonth">
                    {GetCurrencyFixedToTwo(plan?.additionalLinePrice)}
                    {content?.line_unit}
                  </span>
                </Box>
              )
            ) : null}
          </Box>
        </Box>
        <Divider
          sx={{
            borderColor: "var(--lead_generation_dividor)",
            mb: "10px",
            mt: "7px",
            mx: { xs: "-20px", sm: "-15px" },
            opacity: 0.5
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mx: "auto",
            gap: "2px",
            cursor: `${showNlModal ? "normal" : "pointer"}`,
            width: "max-content",
          }}
          onClick={() => {
            if (showNlModal) null;
            else {
              setNlPlan(plan);
              setShowNlModal(true);
            }
          }}
        >
          <Typography
            component={"span"}
            sx={_.merge(renderTextStyles(2, "14px", "primary_color", "21px"), {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
            style={{fontFamily: "var(--font_family_Bold)", marginRight: "10px"}}
            className={
              JSON.stringify(selectedPlan) === JSON.stringify(plan)
                ? "ActiveCard"
                : ""
            }
          >
            {planContent?.broadband}
          </Typography>
          {showNlModal ? (
            <KeyboardArrowUp sx={{ color: "var(--primary_color)" }} />
          ) : (
            <Box
              component={"img"}
              width={"11px"}
              alt={planContent?.loader_img?.data?.attributes?.alternativeText}
              src={planContent?.loader_img?.data?.attributes?.url}
            />
          )}
        </Box>
        </Box>
        
          <SimpleModal
            isShowModal={showNlModal}
            onCloseModal={() => setShowNlModal(false)}
            showClose={false}
            sx={{
              width: {
                xs: "270px",
                sm: "70%",
                md: "30%",
                lg: "300px",
              },
              px: "20px !important",
              pt: "0px !important",
            }}
          >
            <>
              <Box>
                <Box className="top_container">
              <Box className="title">{plan.displayName}</Box>
              <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box>
                    {plan?.isUnlimited && planContent?.u_plan_img ? (
                      <>
                        <Box
                          component={"img"}
                          alt={BrandNameUpdate(planContent?.u_plan_img?.data?.attributes?.alternativeText)}
                          src={planContent?.u_plan_img?.data?.attributes?.url}
                        />
                        &nbsp;
                      </>
                    ) : !plan?.isUnlimited && planContent?.s_plan_img ? (
                      <>
                        <Box
                          component={"img"}
                          alt={BrandNameUpdate(planContent?.s_plan_img?.data?.attributes?.alternativeText)}
                          src={planContent?.s_plan_img?.data?.attributes?.url}
                        />
                        &nbsp;
                      </>
                    ) : null}
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        fontSize: "21px",
                        fontWeight: "bold",
                        lineHeight: "25px",
                      }}
                    >
                      {plan?.planData}
                      {plan?.dataUnit}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      color: "var(--grey33-color)",
                      fontSize: "15px",
                      fontWeight: "var(--h4_font_weight)",
                    }}
                  >
                    {plan?.isUnlimited ? (
                      <>&nbsp;{planContent?.u_p_text}</>
                    ) : (
                      <>&nbsp;{planContent?.s_p_text}</>
                    )}
                  </Box>
                </Box>
                <Box className="separator"></Box>
                <Box
                  className="save_money_area"
                  style={{
                    opacity: plan.isBestFitAllowed ? 1 : 0.2,
                    display: "none",
                  }}
                >
                  <Box
                    component={"img"}
                    src={planContent?.shareable_image?.data?.attributes?.url}
                    alt={planContent?.shareable_image?.data?.attributes?.alternativeText}
                    className="save_money_img"
                  />
                  {planContent?.save_money}
                </Box>
                <Box className="plan_cost_data">
                  <Box component={"span"} className="cost">
                    {GetCurrencyFixedToTwo(
                      planCost ? planCost : 0,
                      plan.currency ? plan.currency : EnvConfig.CURRENCY
                    )}
                  </Box>
                  <Box component={"span"} className="cost1">
                    {planContent?.per_money_text}
                  </Box>
                </Box>
                </Box>
                <Box className="bottom_container">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // mb: "30px",
                    minHeight: "var(--plan_card_bottom_section_min_height)"
                      ? "var(--plan_card_bottom_section_min_height)"
                      : "135px",
                  }}
                >
                  {planContent?.icon_offers &&
                        planContent?.icon_offers.map((item: any, featureIndex: any) => {
                          const hideItem = planContent?.hide_feature_item.find(
                            (sItem: any) =>
                              sItem?.name?.toLowerCase() ===
                              item?.offer?.toLowerCase()
                          )
                          return !hideItem?.indexes?.includes(key) ||
                            !hideItem?.name === item?.offer?.toLowerCase() ? (
                            <Box
                              key={featureIndex}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                p: "10px 0px 0px",
                              }}
                            >
                              <Box>
                                <Box
                                  component={"img"}
                                  alt=""
                                  src={item?.image?.data?.attributes?.url}
                                  sx={{ width: "16px" }}
                                />
                              </Box>
                              <Box className="plan_page_offer">{item?.offer}</Box>
                            </Box>
                          ) : null;
                        })
                    }
                </Box>
                </Box>
              </Box>
              {!isPlanChangeInitiated &&
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: "15px",
                  }}
                >
                  <ButtonCust
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    sx={{
                      "&:disabled": {
                        backgroundColor: "var(--primary_color) !important",
                        opacity: "30%",
                        border: "none !important",
                      },
                    }}
                      onClick={SelectPlanAndProceedHandler}
                      disabled={
                        activePlanName === plan?.name
                          ? true
                          : false
                      }
                  >
                    {planContent?.button_text}
                  </ButtonCust>
                </Box>
                }
              <NutritionLabel
                  NLContent={planContent}
                  plandata={plan}
                  planCost={
                    nlPlan?.unlimited
                      ? calculateUnlimitedCost(nlPlan, 1)["planCost"]
                      : calculateCost(nlPlan, 1)["planCost"]
                  }
                  // index={index}
                  showAnswer={showNlModal}
                  // handleAnswer={handleAnswer}
                  isheading={false}
                  />
            </>
          </SimpleModal>
        
      </Box>
    </Box>
  );
};

export default T1_PlanItem;
