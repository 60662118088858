import Config from "../../../config/app.config.json";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ceoLetterContent } from "../../../features/strapi/aboutUsSlice";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
const LetterComponent = isTemplateExisits(Config.AboutUs.Letter)
  ? require(`./${Config.AboutUs.Letter.template}_Letter`).default
  : null;
export type LetterProps = {
  content?: any;
};
export const Letter = () => {
  const [content, setContent] = useState<any>(null);
  const [cacheLetter, setCacheLetter] = useState<any>(null);
  const { letterContent } = useAppSelector(
    (state: any) => state.strapi.aboutUs
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("CEOLetterAboutus", ceoLetterContent, setCacheLetter, dispatch);
  }, []);
  useNonInitialEffect(() => {
    setContent(letterContent);
    addMultipleCacheData("CEOLetterAboutus", letterContent);
  }, [letterContent]);
  return (content || cacheLetter) ? (
    <LetterComponent content={cacheLetter ? cacheLetter : content} />
  ) : null;
};
