import styles from "./S1_BestFitFeatures.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { BestFitFeaturesProps } from "./BestFitFeatures";
const BestFitFeaturesComponent = (props: BestFitFeaturesProps) => {
  const { header, sub_header, bestfit_features } = props.featureContent || {};
  return (
    props.featureContent && (
      <Grid
        container
        display="flex"
        justifyContent="space-around"
        sx={{
          p:{
            xs: "calc(var(--mobile_section_padding)) 0 calc(var(--mobile_section_padding)/2)",
            sm: "calc(var(--ipad_section_padding)) 0 calc(var(--ipad_section_padding)/2)",
            md: "calc(var(--desktop_section_padding)) 0 calc(var(--desktop_section_padding)/2)",
          }
        }}
        className={styles.best_fit_features}
      >
        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px 30px",
          }}
        >
          <Typography variant="h3">{header || ""}</Typography>
          <Box sx={{ my: 2.5 }} className={styles.best_fit_line}></Box>
          <Typography
            className="description_text"
            variant="body1"
            sx={{ mb: { sm: "25px" } }}
          >
            {sub_header || ""}
          </Typography>
        </Grid>
        {bestfit_features &&
          bestfit_features.map((content: any) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              textAlign="center"
              key={content.title}
              alignItems="stretch"
              sx={{
                marginBottom: { md: "0px", xs: "31px" },
                padding: "0px 40px",
              }}
            >
              <Box
                sx={{
                  marginBottom: { xs: "31px", md: "15px" },
                  width: { xs: "100%", lg: "75%" },
                  height: "55px",
                }}
                component="img"
                alt={content?.image?.data?.attributes?.alternativeText}
                src={content?.image?.data?.attributes?.url || ""}
                className={styles.plan_features_image}
                height={"78"}
              ></Box>
              <Typography
                variant="body1"
                className={styles.best_fit_features_title}
                sx={{ marginBottom: "9px" }}
              >
                {content.title}
              </Typography>
              <Typography
                variant="body2"
                className={styles.best_fit_features_sub_title}
                sx={{ marginBottom: "0px" }}
              >
                {content.sub_title}
              </Typography>
            </Grid>
          ))}
      </Grid>
    )
  );
};
export default BestFitFeaturesComponent;
