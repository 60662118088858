import { Box, Typography, Grid, Button } from "@mui/material";
import styles from "./ActivationFailedModal.module.scss";
import { ButtonCust, SimpleModal } from "../../../widgets";
import config from "../../../../config/env.config.json";
import { logout } from "../../../../services/Firebase";
import { brandNames } from "../../../../enums";
import Config from "../../../../config/env.config.json";

type Props = {
  showModal: any;
  openSupportModal?: any;
  content: any;
  currentLine?: any;
  closeActivationFailModal?: any;
};

export const ActivationFailedModal = (props: Props) => {
  const {
    showModal,
    openSupportModal,
    content,
    currentLine,
    closeActivationFailModal,
  } = props;

  const { btn, customer_txt, desc_text, title, m_img } = content?.act_fail;

  return (
    <SimpleModal
      onCloseModal={currentLine.isPrimary ? false : closeActivationFailModal}
      showClose={currentLine.isPrimary ? false : true}
      isShowModal={showModal}
      sx={{
        width: { xs: "100%", sm: "80%", md: "60%", lg: "60%" },
        borderRadius: "6px",
      }}
    >
      <Grid
        container
        display="flex"
        flexDirection="column"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        className={styles.activation_fail}
      >
        {Config.DOMAIN === brandNames.REACH && (
          <Box
            component="img"
            className={styles.activation_fail_icon}
            src={m_img?.data?.attributes?.url}
            alt={m_img?.data?.attributes?.alternativeText}
          />
        )}
        <Typography variant="body2" className={styles.title}>
          {currentLine?.isMNP && Config.DOMAIN === brandNames.WOW
            ? "Porting failed!"
            : title}
        </Typography>
        <Typography variant="body2" className={styles.subtitle}>
          {desc_text}
        </Typography>
        <Box className={styles.button_contact}>
          <ButtonCust
            variantType={config.PRIMARY_BUTTON_TYPE}
            onClick={() => openSupportModal()}
          >
            {customer_txt}
          </ButtonCust>
          {currentLine.isPrimary && (
            <Button className={styles.logout_btn} onClick={() => logout()}>
              {btn}
            </Button>
          )}
        </Box>
      </Grid>
    </SimpleModal>
  );
};
