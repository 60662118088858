import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
import Config from "../../../../config/app.config.json";
import { getWalletHistoryContent } from "../../../../features/strapi/serviceSlice";
import { Box, CircularProgress } from "@mui/material";

const WalletHistoryCallingComp = isTemplateExisits(
  Config.PostActivationServices.WalletHistory
)
  ? require(`./${Config.PostActivationServices.WalletHistory.template}_WalletHistory`)
      .default
  : null;

type Props = {
  setIsViewHistory: any;
  lineDataForWallet: any; // Adjust the type to match the actual data type of historyData
  handleBillCycleChange: any;
};
export const WalletHistory = (props: Props) => {
  const { setIsViewHistory, lineDataForWallet, handleBillCycleChange } = props;
  const dispatch = useAppDispatch();
  const { walletHistoryContent } = useAppSelector(
    (state) => state.strapi.services
  );
  const [cacheDataCalling, setCacheDataCalling] = useState<any>(null);

  useEffect(() => {
    getCache(
      "WalletHistoryCalling",
      getWalletHistoryContent,
      setCacheDataCalling,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("WalletHistoryCalling", walletHistoryContent);
  }, [walletHistoryContent]);

  return cacheDataCalling || walletHistoryContent ? (
    <WalletHistoryCallingComp
      content={cacheDataCalling ? cacheDataCalling : walletHistoryContent}
      setIsViewHistory={setIsViewHistory}
      lineDataForWallet={lineDataForWallet}
      handleBillCycleChange={handleBillCycleChange}
    />
  ) : (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <CircularProgress
        sx={{
          color: "var(--primary_brand_color_dark)",
        }}
      />
    </Box>
  );
};
