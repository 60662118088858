import { useEffect } from "react";
import { DetailsCoverageProps } from "./DetailsCoverage";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import { Grid, Typography, Box } from "@mui/material";
import envConfig from "../../../config/env.config.json";
import styles from "./S1_DetailsCoverage.module.scss";

function DetailsCoverageCmp(props: DetailsCoverageProps) {
  const { coverageCheckContent, buttonHandler, currentdetails } = props;
  const { heading, sub_heading, btn_value, note, iframeLink, iframeTitle } =
    coverageCheckContent;
  const { details_coverage, check_coverage, iframeMapId, note_style } = styles;
  useEffect(() => {
    window.scroll(0, 0);
    let container = document.getElementById(iframeMapId);
    window.addEventListener(
      "message",
      function (e) {
        let message = e.data;
        if (message.map && message.height && container) {
          container.style.height = message.height + 350 + "px";
        }
      },
      false
    );
  }, []);
  return (
    <Grid container className={details_coverage} mt={{ xs: 6.8 }}>
      <Grid
        container
        display="flex"
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems="center"
        px={{ xs: 3, sm: 5, md: 7.4 }}
        py={3.8}
        className={check_coverage}
      >
        <Grid display="flex" flexDirection="column">
          <Grid item>
            <Typography
              variant="h4"
              lineHeight={2.5}
              textAlign={{ xs: "center", sm: "left" }}
            >
              {heading}
            </Typography>
          </Grid>
          <Grid
            item
            fontSize="18px"
            lineHeight={2.1}
            textAlign={{ xs: "center", sm: "left" }}
          >
            {sub_heading}
          </Grid>
        </Grid>

        <Grid item>
          {!currentdetails?.simPaid && (
            <ButtonCust
              sx={{ width: "100px", mt: 1 }}
              variantType={envConfig.PRIMARY_BUTTON_TYPE}
              onClick={(e) => {
                buttonHandler(e);
              }}
            >
              {btn_value}
            </ButtonCust>
          )}
        </Grid>
      </Grid>
      {envConfig?.SHOW_NOTE_IN_DETAILED_COVERAGE && (
        <Grid item px={{ xs: 3, sm: 5, md: 7.4 }} mt={2}>
          <Box
            className={note_style}
            dangerouslySetInnerHTML={{
              __html: note,
            }}
          ></Box>
        </Grid>
      )}
      <Grid container textAlign="center" mb={2.5}>
        <iframe
          id={iframeMapId}
          src={iframeLink}
          title={iframeTitle}
          loading="lazy"
        ></iframe>
      </Grid>
    </Grid>
  );
}

export default DetailsCoverageCmp;
