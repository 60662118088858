import firebase from "./firebase";

const setAuthToken: any = () => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().onAuthStateChanged((user: any) => {
        const token = user?.getIdToken();
        resolve(token);
      });
    } catch {
      reject("api failed");
    }
  });
};

export default setAuthToken;
