import { Box, Typography } from "@mui/material";
import styles from "./S5_Terms.module.scss";
import {
  ReactElement,
  JSXElementConstructor,
  ReactFragment,
  ReactPortal,
} from "react";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
type Props = {
  content: any;
};
const T5_Terms = (props: Props) => {
  const { content } = props;
  return (
    <>
      {content?.terms?.map(
        (each: {
          terms_cont: any;
          terms_block: any;
          main_title: any;
          title: any;
          Terms_block: any[];
          Terms_content: any[];
        }) => {
          return (
            <Box
              className={styles.terms_page_content}
              mt={{ xs: "50px" }}
              pt="50px"
            >
              <Typography className={styles.terms_title}>
                {BrandNameUpdate(each.main_title)}
              </Typography>
              <Typography className={styles.terms_title}>
                {BrandNameUpdate(each.title)}
              </Typography>
              <Box className={styles.terms_line}></Box>
              <Box
                sx={{
                  m: { xs: "0 20px", sm: "0 90px" },
                  p: { xs: "0", lg: "0 50px" },
                }}
              >
                <>
                  {each?.terms_block?.map((block: any, index: any) => {
                    return (
                      <>
                        <Typography
                          className={styles.terms_headings}
                          m="30px 0"
                        >
                          {BrandNameUpdate(block?.blockHeader)}
                        </Typography>
                        <Box
                          className={styles.terms_para}
                          dangerouslySetInnerHTML={{
                            __html: BrandNameUpdate(block?.blockContent),
                          }}
                        ></Box>
                      </>
                    );
                  })}
                  {each.terms_cont?.map((items: any, index: number) => {
                    return (
                      <Box key={index}>
                        {items?.heading && (
                          <Typography
                            className={styles.terms_headings}
                            sx={{ fontSize: "18px !important" }}
                          >
                            {BrandNameUpdate(items?.heading)}
                          </Typography>
                        )}
                        <Box
                          className={styles.terms_para}
                          dangerouslySetInnerHTML={{
                            __html: BrandNameUpdate(items.description),
                          }}
                        ></Box>
                      </Box>
                    );
                  })}
                </>
              </Box>
            </Box>
          );
        }
      )}
    </>
  );
};
export default T5_Terms;
