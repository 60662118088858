import { useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import styles from "./Share.module.scss";
import { PopperCust } from "../../widgets";
import { Close } from "@mui/icons-material";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

type shareprops = {
  share_text: string;
  share_icon: string;
  Share_Icon_alt?: string;
  invite_friends_text: string;
  facebook_icon: string;
  facebook_icon_alt?: string;
  twitter_icon: string;
  twitter_icon_alt?: string;
  mail_icon: string;
  mail_icon_alt?: string;
  facebook_label: string;
  twitter_label: string;
  mail_label: string;
  facebook_action: any;
  twitter_action: any;
  email_action: any;
};

/*
<Share
  share_text={}
  share_icon={}
  invite_friends_text={}
  facebook_icon={}
  twitter_icon={}
  mail_icon={}
  facebook_label={}
  twitter_label={}
  mail_label={} 
  facebook_url={}
  twitter_url={}
/>
*/

export const Share = (props: shareprops) => {
  const {
    share_text,
    share_icon,
    Share_Icon_alt,
    facebook_icon_alt,
    twitter_icon_alt,
    mail_icon_alt,
    invite_friends_text,
    facebook_icon,
    twitter_icon,
    mail_icon,
    facebook_label,
    twitter_label,
    mail_label,
    facebook_action,
    twitter_action,
    email_action,
  } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "fit-content",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        textAlign="center"
        className={styles.share_style}
        onClick={(event) => handleClick(event)}
      >
        <Typography
          fontWeight="var(--font_weight_4)"
          fontSize="20px"
          color="var(--text_color)"
          fontFamily="var(--font_family_Bold)"
        >
          {share_text}
        </Typography>
        <Box
          component="img"
          src={share_icon || ""}
          alt={Share_Icon_alt}
          width="20px"
          height="18px"
          className={styles.share_icon}
        />
      </Box>
      <PopperCust
        open={open}
        anchorEl={anchorEl}
        id={""}
        className={styles.popper}
      >
        <Paper elevation={5} style={{ padding: "10px" }}>
          <Box className={styles.share_popper} onClick={handleClose}>
            <Box sx={{ display: "flex" }}>
              <Close onClick={handleClose} className={styles.close_icon} />
              <Box className={styles.invite_friends_text}>
                {invite_friends_text}
              </Box>
            </Box>

            <Box className={styles.media_icons}>
              <Box className={styles.individual_media_icon}>
                <FacebookShareButton
                  url={facebook_action?.url}
                  quote={facebook_action?.quote}
                >
                  <img
                    src={facebook_icon || ""}
                    alt={facebook_icon_alt}
                    height="50px"
                    width="50px"
                  />
                </FacebookShareButton>
                <Box className={styles.icon_text}>{facebook_label}</Box>
              </Box>
              <Box className={styles.individual_media_icon}>
                <TwitterShareButton
                  url={twitter_action?.url}
                  title={twitter_action?.title}
                >
                  <img
                    src={twitter_icon || ""}
                    alt={twitter_icon_alt}
                    height="50px"
                    width="50px"
                  />
                </TwitterShareButton>
                <Box className={styles.icon_text}>{twitter_label}</Box>
              </Box>
              <Box className={styles.individual_media_icon}>
                <EmailShareButton
                  url={email_action?.url}
                  subject={email_action?.subject}
                  body={email_action?.body}
                >
                  <img
                    src={mail_icon || ""}
                    alt={mail_icon_alt}
                    height="50px"
                    width="50px"
                  />
                </EmailShareButton>
                <Box className={styles.icon_text}>{mail_label}</Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </PopperCust>
    </Grid>
  );
};
