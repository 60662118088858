export enum OrderStatus {
  Ordered = "ORDERED",
  Labelled = "LABELLED",
  Shipped = "SHIPPED",
  Delivered = "DELIVERED",
  OrderReceived = "Order received",
  Cancelled = "CANCELLED",
}

export enum StepperStatus {
  OrderReceived = "Order received",
  InProgress = "In progress",
  OrderShipped = "Order shipped",
  DELIVERED = "Delivered",
  Cancelled = "Order canceled",
}
