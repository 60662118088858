import {
  Box,
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Divider, NotificationCust } from "../..";
import styles from "./S1_SettingsCards.module.scss";
import CommonModal from "./SettingsModals/commonModal";
import WifiAddressModal from "./SettingsModals/WifiAddressModal";
import EnvConfig from "../../../config/env.config.json";
import { SettingsCardsEnum } from "../../../enums";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getSettings } from "../../../features/settings/settingsSlice";
import { useNonInitialEffect } from "../../../utils/commonFunctions";
import { Store } from "react-notifications-component";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const T1_SettingsCards = (props: any) => {
  const {
    card,
    lan_mdl,
    pswrd_mdl,
    dis_wifi,
    dta_saver_mdl,
    dis_dta_sav,
    enbl_mdl,
    upd_adrs,
    lng1,
    lng2,
    enable,
    disable,
    pswd_suc_msg,
    lng_sus_msg,
    dta_sav_suc,
    dta_dis_suc,
    wifi_dis_msg,
    wifi_enbld_msg,
    adrs_upt_msg,
    err_msg,
    wifi_upd_scs,
  } = props.content;
  const [language, setLanguage] = useState<string>("");
  const [wificalling, setWificalling] = useState("Enable");
  const [dataSaving, setDataSaving] = useState("Enable");
  const [commonModal, setCommonModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalContent, setModalContent] = useState<any>(null);
  const [modalValue, setModalValue] = useState<any>(null);
  const [wifiAddressModal, setWifiAddressModal] = useState<any>(false);
  const [wifiAddressContent, setWifiAddressContent] = useState<any>(null);
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<any>(null);
  const [customerNum, setCustomerNum] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const [wifiAddress, setWifiAddress] = useState<any>(null);

  const dispatch = useAppDispatch();
  const { languageData,errorLanguageData, passwordData,errorPasswordData, dataSaverData, errorDataSaverData,wifiUpdateData,errorWifiUpdateData } =
    useAppSelector((state: any) => state.settings);

  useEffect(() => {
    let customerStoreData: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    let customerId = customerStoreData?.id;
    setCustomerId(customerId);
    setCustomerNum(customerStoreData?.reachNumber);
    dispatch(getSettings(customerId)).then((data: any) => {
      setLoading(false);
      const { voicemail, wifiCalling, dataSaver } =
        data?.payload?.data?.data?.settings;
      voicemail?.language === "ES" ? setLanguage(lng2) : setLanguage(lng1);
      wifiCalling?.enabled ? setWificalling(enable) : setWificalling(disable);
      dataSaver?.enabled ? setDataSaving(enable) : setDataSaving(disable);
      wifiCalling?.e911Address
        ? setWifiAddress(wifiCalling?.e911Address)
        : setWifiAddress(null);
    }).catch((err)=>{
      NotificationCust({
        message: err_msg,
        duration:0,
        type: "danger",
        id: "ErrorGettingSettings",
        showIcon: true,
      });
    });
    return ()=>Store.removeNotification("ErrorGettingSettings");
  }, []);

  useNonInitialEffect(() => {
    if (
      languageData?.status === "SUCCESS" ||
      passwordData?.status === "SUCCESS" ||
      dataSaverData?.status === "SUCCESS" ||
      wifiUpdateData?.status === "SUCCESS"
    ) {
      dispatch(getSettings(customerId))
        .then((data: any) => {
          const { voicemail, wifiCalling, dataSaver } =
            data?.payload?.data?.data?.settings;
          voicemail?.language === "ES" ? setLanguage(lng2) : setLanguage(lng1);
          wifiCalling?.enabled
            ? setWificalling(enable)
            : setWificalling(disable);
          dataSaver?.enabled ? setDataSaving(enable) : setDataSaving(disable);
          wifiCalling?.e911Address
            ? setWifiAddress(wifiCalling?.e911Address)
            : setWifiAddress(null);
        })
        .then(() => {
          setCommonModal(false);
          setIsLoadingBtn(false);
          setWifiAddressModal(false);
          if (modalType === SettingsCardsEnum.PASSWORD) {
            NotificationCust({
              message: BrandNameUpdate(pswd_suc_msg),
              type: "info",
              duration: 2000,
              id: "ResetPasswordSettings",
              showIcon: true,
            });
          } else if (modalType === SettingsCardsEnum.LANGUAGE) {
            NotificationCust({
              message: `${BrandNameUpdate(lng_sus_msg)} ${BrandNameUpdate(modalValue)}`,
              type: "info",
              duration: 2000,
              id: "LanguageUpdateSettings",
              showIcon: true,
            });
          } else if (modalType === SettingsCardsEnum.DATA_SAVER) {
            if (modalValue === SettingsCardsEnum.Enable) {
              NotificationCust({
                message: BrandNameUpdate(dta_sav_suc),
                type: "info",
                duration: 3000,
                id: "LanguageUpdateSettings",
                showIcon: true,
              });
            } else {
              NotificationCust({
                message: BrandNameUpdate(dta_dis_suc),
                type: "info",
                duration: 3000,
                id: "LanguageUpdateSettings",
                showIcon: true,
              });
            }
          } else if (modalType === SettingsCardsEnum.WIFI_CALLING) {
            NotificationCust({
              message: BrandNameUpdate(wifi_dis_msg),
              type: "info",
              duration: 2000,
              id: "WifiCallingSettings",
              showIcon: true,
            });
          } else if (modalType === SettingsCardsEnum.WIFI_ADDRESS) {
            setCommonModal(true);
            setModalContent(wifi_upd_scs);
            setModalType(SettingsCardsEnum.ENABLE_WIFI);
            setModalValue(wifi_enbld_msg);
          } else if (modalType === SettingsCardsEnum.WIFI_ADDRESS_UPDATE) {
            NotificationCust({
              message: BrandNameUpdate(adrs_upt_msg),
              type: "info",
              duration: 5000,
              id: "WifiCallingUpdateSettings",
              showIcon: true,
            });
          }
        });
    } else {
      setCommonModal(false);
      setIsLoadingBtn(false);
      setWifiAddressModal(false);
      NotificationCust({
        message: err_msg,
        type: "danger",
        duration: 2000,
        id: "ErrorUpdatingSettings",
        showIcon: true,
      });
    }
  }, [languageData, passwordData, dataSaverData, wifiUpdateData,errorWifiUpdateData,errorDataSaverData,errorPasswordData,errorLanguageData]);
  let formValues = {
    wifiCallingAddress: {
      city: "",
      address1: "",
      address2: "",
      zip: "",
      state: "",
      country: "",
    },
  };
  if (wifiAddress?.hasOwnProperty("zip")) {
    let updateAddress = Object.assign({}, wifiAddress);
    let zip = wifiAddress?.zip.slice(0, 5);
    delete Object.assign(updateAddress, {
      ["address1"]: updateAddress["street1"],
    })["street1"];
    formValues = {
      wifiCallingAddress: { ...updateAddress, country: "USA", zip },
    };
  }

  const languageChange = (e: any, val: string) => {
    if (val != null) {
      setCommonModal(true);
      setModalValue(val);
      setModalType(SettingsCardsEnum.LANGUAGE);
      setModalContent(lan_mdl);
    }
  };
  const passwordRest = () => {
    setCommonModal(true);
    setModalType(SettingsCardsEnum.PASSWORD);
    setModalContent(pswrd_mdl);
  };
  const wifiCallingChange = (e: any, val: string) => {
    if (val != null) {
      if (val.toLocaleLowerCase() === "disable") {
        setCommonModal(true);
        setModalType(SettingsCardsEnum.WIFI_CALLING);
        setModalContent(dis_wifi);
      } else {
        setWifiAddressModal(true);
        setModalType(SettingsCardsEnum.WIFI_ADDRESS);
        setWifiAddressContent(enbl_mdl);
      }
    }
  };
  const dataSavingChange = (e: any, val: string) => {
    if (val != null) {
      if (val.toLocaleLowerCase() === "disable") {
        setCommonModal(true);
        setModalType(SettingsCardsEnum.DATA_SAVER);
        setModalValue(val);
        setModalContent(dis_dta_sav);
      } else {
        setCommonModal(true);
        setModalValue(val);
        setModalType(SettingsCardsEnum.DATA_SAVER);
        setModalContent(dta_saver_mdl);
      }
    }
  };

  const handleUpdateAddress = () => {
    setWifiAddressModal(true);
    setModalType(SettingsCardsEnum.WIFI_ADDRESS_UPDATE);
    setWifiAddressContent(upd_adrs);
  };

  const checkConfig = (name: string, feild: string = "") => {
    const { SETTINGS_CARDS } = EnvConfig;
    switch (name) {
      case SettingsCardsEnum.VOICE_MAIL:
        if (SETTINGS_CARDS.VOICE_MAIL.VOICE_MAIL && !feild) {
          return true;
        } else if (SETTINGS_CARDS.VOICE_MAIL.VOICE_MAIL && feild) {
          if (
            feild.toLocaleLowerCase() === "language" &&
            SETTINGS_CARDS.VOICE_MAIL.LANGUAGE
          ) {
            return true;
          }
          if (
            feild.toLocaleLowerCase() === "password" &&
            SETTINGS_CARDS.VOICE_MAIL.PASSWORD
          ) {
            return true;
          } else return false;
        }
        break;
      case SettingsCardsEnum.WIFI_CALLING:
        if (SETTINGS_CARDS.WIFI_CALLING) return true;
        break;
      case SettingsCardsEnum.DATA_SAVER:
        if (SETTINGS_CARDS.DATA_SAVER) return true;
        break;
      default:
        return false;
    }
  };

  return (
    <Grid container className={styles.container} padding="30px">
      {loading ? (
        <Grid
          width="100%"
          height="500px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Grid>
      ) : (
        <Grid container item className={styles.inner_container}>
          {card.map((item: any, ind: number) => {
            return (
              checkConfig(item.card_category) && (
                <Box key={ind} className={styles.card}>
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--h4_font_weight)",
                    }}
                  >
                    {item?.ttl}
                  </Typography>
                  <Divider className={styles.divider} />
                  {item?.btn1 && checkConfig(item.card_category, item.lb1) && (
                    <Box className={styles.row1}>
                      {item?.lb1 ? (
                        <Typography className={styles.lables}>
                          {item?.lb1}
                        </Typography>
                      ) : (
                        ""
                      )}
                      <ToggleButtonGroup
                        className={styles.toggle_group}
                        value={
                          item.card_category === SettingsCardsEnum.VOICE_MAIL
                            ? language
                            : item.card_category ===
                              SettingsCardsEnum.WIFI_CALLING
                            ? wificalling
                            : dataSaving
                        }
                        onChange={
                          item.card_category === SettingsCardsEnum.VOICE_MAIL
                            ? languageChange
                            : item.card_category ===
                              SettingsCardsEnum.WIFI_CALLING
                            ? wifiCallingChange
                            : dataSavingChange
                        }
                        exclusive
                        fullWidth={true}
                      >
                        <ToggleButton
                          className={styles.toggle_btn}
                          value={item?.btn1}
                        >
                          {item.btn1}
                        </ToggleButton>
                        <ToggleButton
                          className={
                            item.card_category ===
                            SettingsCardsEnum.WIFI_CALLING
                              ? styles.wifi_disable
                              : styles.toggle_btn
                          }
                          value={item?.btn2}
                          disabled={
                            item.card_category ===
                              SettingsCardsEnum.WIFI_CALLING &&
                            wificalling.toLocaleLowerCase() === "disable"
                          }
                        >
                          {item?.btn2}
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {item?.up_adrs && (
                        <Box display="flex" justifyContent="flex-end" mt="15px">
                          <Typography
                            className={
                              wificalling.toLocaleLowerCase() === "disable"
                                ? styles.disable_address
                                : styles.address
                            }
                            onClick={() =>
                              wificalling.toLocaleLowerCase() === "enable"
                                ? handleUpdateAddress()
                                : null
                            }
                          >
                            {item?.up_adrs}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                  {item.btn3 && checkConfig(item.card_category, item.lb2) && (
                    <Box className={styles.row2}>
                      {item?.lb2 ? (
                        <Typography className={styles.lables} mt="16px">
                          {item?.lb2}
                        </Typography>
                      ) : (
                        ""
                      )}
                      <ToggleButton
                        className={styles.reset_btn}
                        value="checked"
                        onClick={passwordRest}
                      >
                        {item?.btn3}
                      </ToggleButton>
                    </Box>
                  )}
                </Box>
              )
            );
          })}
          <CommonModal
            language={language}
            modalValue={modalValue}
            commonModal={commonModal}
            onClose={setCommonModal}
            modalType={modalType}
            content={modalContent}
            customerId={customerId}
            customerNum={customerNum}
            isLoading={isLoadingBtn}
            setIsLoading={setIsLoadingBtn}
            wifiAddress={wifiAddress}
          />
          <WifiAddressModal
            wifiAddressModal={wifiAddressModal}
            content={wifiAddressContent}
            onClose={setWifiAddressModal}
            modalType={modalType}
            formValues={formValues}
            customerId={customerId}
            isLoading={isLoadingBtn}
            setIsLoading={setIsLoadingBtn}
          ></WifiAddressModal>
        </Grid>
      )}
    </Grid>
  );
};
export default T1_SettingsCards;
