import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

const getLocalStorage = (param: string) => {
  let customerDetails: any = JSON.parse(
    localStorage.getItem("currentuser") || "null"
  );
  if (!customerDetails?.isAnonymous) {
    if (param === "userName") return customerDetails?.displayName || "";
    else return customerDetails?.email || "";
  } else return "";
};
type InitialState = {
  hubspotData: any;
  errorHubspot: any;
  planData: any;
  planCost: number | any;
  isExpensive: boolean;
  comparePlanFormValues: any;
  saveAmt: number;
  selectedLines: number;
  isCheckout: boolean;
};
const initialState: InitialState = {
  hubspotData: null,
  errorHubspot: null,
  planData: null,
  planCost: null,
  isExpensive: false,
  comparePlanFormValues: {
    dataUsage: "",
    currentAmount: "",
    mailID: getLocalStorage("email"),
    userName: getLocalStorage("userName"),
    rangeSlider: 1,
  },
  saveAmt: 0,
  selectedLines: 0,
  isCheckout: false,
};

const billCalculatorSlice = createSlice({
  name: "billCalculator",
  initialState,
  reducers: {
    suitablePlanData: (state, action) => {
      state.planData = action.payload.planData;
      state.planCost = action.payload.planCost;
      state.isExpensive = action.payload.isExpensive;
      state.saveAmt = action.payload.saveAmt;
      state.selectedLines = action.payload.selectedLines;
    },
    storeFormData: (state, action) => {
      state.comparePlanFormValues = action.payload;
    },
    handleCheckout: (state, action) => {
      state.isCheckout = action.payload.isCheckout;
    },
  },
  extraReducers: (builder) => {},
});

export const { suitablePlanData, storeFormData, handleCheckout } =
  billCalculatorSlice.actions;

export default billCalculatorSlice.reducer;
