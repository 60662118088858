export function sortByKey(array: any, key: any, key2?: any) {
  let sort = array.slice().sort(function (a: any, b: any) {
    let x = parseFloat(a[key]);
    let y = parseFloat(b[key]);
    if (x < y) return -1;
    else if (x > y) return 1;
    else {
      if (key2) {
        let x1 = a[key2];
        let y1 = b[key2];
        return x1 > y1 ? -1 : x1 < y1 ? 1 : 0;
      } else {
        return 0;
      }
    }
  });
  return sort;
}

export function sortObjectByDates(obj:any) {
  return Object.keys(obj)
    .sort((a:any, b:any) => new Date(a).valueOf() - new Date(b).valueOf())
    .reduce((acc, currentKey) => ({ ...acc, [currentKey]: obj[currentKey] }), {});
}