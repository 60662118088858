import { Grid, Typography, Box } from "@mui/material";
import { MissionProps } from "./Missions";
import styles from "./S1_Missions.module.scss";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";

const MissionsComponent = (props: MissionProps) => {
  const { top_content, title, image_content } = props.content || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  return (
    props.content && (
      <Grid
        container
        sx={{
          p: {
            xs: "calc(var(--mobile_section_padding)) 0 calc(var(--mobile_section_padding)/2)",
            sm: "calc(var(--ipad_section_padding)) 0 calc(var(--ipad_section_padding)/2)",
            md: "calc(var(--desktop_section_padding)) 0 calc(var(--desktop_section_padding)/2)",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: "15px",
          }}
        >
          <Typography
            className={styles.main_content}
            sx={{ textAlign: "center", mb: "15px" }}
          >
            {top_content || ""}
          </Typography>
          <Typography variant="h3" textAlign="center" pb={1}>
            {title || ""}
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            px: { xs: 2, md: 25 },
          }}
        >
          {image_content &&
            image_content.map((content: any) => (
              <Grid
                key={content.id}
                item
                xs={12}
                md={5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Grid item sx={{ position: "relative" }}>
                  {mobile &&
                    generateImageContainer({
                      mulData: content?.image?.data[0],
                      attrs: { height: "100%", width: "100%" },
                      overrides: { sx: { pb: 1 } },
                    })}

                  {ipad &&
                    generateImageContainer({
                      mulData: content?.image?.data[1],
                      attrs: { height: "100%", width: "100%" },
                      overrides: { sx: { pb: 1 } },
                    })}

                  {(largeDesktop || desktop) &&
                    generateImageContainer({
                      mulData: content?.image?.data[2],
                      attrs: { height: "100%", width: "100%" },
                      overrides: { sx: { pb: 1 } },
                    })}
                  <Typography
                    textAlign="left"
                    variant="h3"
                    className={styles.about_us_content}
                  >
                    {content.header || ""}
                  </Typography>
                </Grid>
                <Typography
                  fontWeight="600"
                  px={1}
                  pb={3}
                  className={styles._mission_description}
                >
                  {content.description || ""}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>
    )
  );
};

export default MissionsComponent;
