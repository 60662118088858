import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { hsdContent } from "../../../features/strapi/settingsSlice";

type Props = {
  eligibilityCheckFromPromo?: any;
  setEligibilityCheckFromPromo?:any;
};
const HSDComponent = isTemplateExisits(Config.Settings.HSD)
  ? require(`./${Config.Settings.HSD.template}_HSD`).default
  : null;

export const HSD = (props: Props) => {
  const [cacheHsdData, setCacheHsdData] = useState<any>(null);
  const { hsdData } = useAppSelector((state: any) => state.strapi.settings);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("HSDSettings", hsdContent, setCacheHsdData, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("HSDSettings", hsdData);
  }, [hsdData]);
  return (
    (cacheHsdData || hsdData) && (
      <HSDComponent
        content={cacheHsdData ? cacheHsdData : hsdData}
        eligibilityCheckFromPromo={
          props?.eligibilityCheckFromPromo
            ? props?.eligibilityCheckFromPromo
            : false
        }
        setEligibilityCheckFromPromo={props?.setEligibilityCheckFromPromo ? props?.setEligibilityCheckFromPromo : ()=>{}}
      />
    )
  );
};
