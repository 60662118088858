import styles from "./S1_TeamIntro.module.scss";
import { Box, Typography } from "@mui/material";
import { teamIntroProps } from "./TeamIntro";
import { Divider } from "../../widgets";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
const TeamIntroComponent = (props: teamIntroProps) => {
  const { title, description, image_desktop, image_ipad, image_mobile } =
    props.meetTeamState || {};
  const { largeDesktop, desktop, ipad } = getResolutionDetails();
  return (
    props?.meetTeamState && (
      <Box
        className={styles.team_intro_main}
        sx={{
          p: {
            xs: "calc(var(--mobile_section_padding)) 0 calc(var(--mobile_section_padding)/2)",
            sm: "calc(var(--ipad_section_padding)) 0 calc(var(--ipad_section_padding)/2)",
            md: "calc(var(--desktop_section_padding)) 0 calc(var(--desktop_section_padding)/2 - 25px)",
          },
        }}
      >
        <Box className={styles.team_intro_text_div}>
          <Typography
            variant="h3"
            sx={{ mb: "10px" }}
            className={styles.team_intro_title}
          >
            {title || ""}
          </Typography>
          <Divider className={styles.divider} />
          <Typography className={styles.team_intro_description}>
            {description || ""}
          </Typography>
        </Box>
        <Box className={styles.team_intro_images_div}>
          <Box className={styles.desktop_image_div1}>
            {(desktop || largeDesktop) &&
              generateImageContainer({
                mulData: image_desktop?.data[0],
                className: styles.desktop_image,
              })}
            {(desktop || largeDesktop) &&
              generateImageContainer({
                mulData: image_desktop?.data[3],
                className: styles.desktop_image,
              })}
          </Box>
          <Box className={styles.desktop_image_div2}>
            <Box className={styles.desktop_image_div2_inner}>
              {(desktop || largeDesktop) &&
                generateImageContainer({
                  mulData: image_desktop?.data[1],
                  className: styles.desktop_image,
                  overrides: { sx: { width: "100%" } },
                })}
              {(desktop || largeDesktop) &&
                generateImageContainer({
                  mulData: image_desktop?.data[2],
                  className: styles.desktop_image,
                  overrides: { sx: { width: "100%" } },
                })}
            </Box>
            {(desktop || largeDesktop) &&
              generateImageContainer({
                mulData: image_desktop?.data[4],
                className: styles.desktop_image,
              })}
          </Box>
          <Box className={styles.iPad_images_div}>
            <Box className={styles.iPad_images_inner} marginBottom="30px">
              <Box className={styles.iPad_img_left}>
                {ipad &&
                  generateImageContainer({
                    mulData: image_ipad?.data[0],
                    className: styles.iPad_img,
                  })}
              </Box>
              <Box className={styles.iPad_img_right}>
                {ipad &&
                  generateImageContainer({
                    mulData: image_ipad?.data[3],
                    className: styles.iPad_img,
                  })}
              </Box>
            </Box>
            <Box className={styles.iPad_images_inner}>
              <Box className={styles.iPad_img_left}>
                {ipad &&
                  generateImageContainer({
                    mulData: image_ipad?.data[2],
                    className: styles.iPad_img,
                  })}
              </Box>
              <Box className={styles.iPad_img_right}>
                {ipad &&
                  generateImageContainer({
                    mulData: image_ipad?.data[1],
                    className: styles.iPad_img,
                  })}
              </Box>
            </Box>
            <Box mt="30px" lineHeight={0}>
              {ipad &&
                generateImageContainer({
                  mulData: image_ipad?.data[4],
                  className: styles.iPad_img,
                })}
            </Box>
          </Box>
          <Box className={styles.mobile_img_div}>
            {image_mobile?.data?.length > 0 &&
              image_mobile?.data?.map((item: any, index: number) => {
                return (
                  <Box key={index} lineHeight={0} className={styles.mobile_img}>
                    {generateImageContainer({
                      data: item?.attributes?.url,
                      overrides: { sx: { width: "100%", height: "100%" } },
                    })}
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    )
  );
};
export default TeamIntroComponent;
