import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  comparePlansData: any;
  errorComparePlans: any;
  graphData: any;
  errorGraphData: any;
  negResultTopData: any;
  errorNegResultTop: any;
  posResultBottomData: any;
  errorposResultBottomData: any;
  negResultBottomData: any;
  errorNegResultBottomData: any;
};

const initialState: InitialState = {
  comparePlansData: null,
  errorComparePlans: null,
  graphData: null,
  errorGraphData: null,
  negResultTopData: null,
  errorNegResultTop: null,
  posResultBottomData: null,
  errorposResultBottomData: null,
  negResultBottomData: null,
  errorNegResultBottomData: null,
};

export const comparePlansStrapi = createAsyncThunk(
  "billCalculator/comparePlansStrapi",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.BillCalculator.ComparePlans.template}${StrapiApiEndPoints?.comparePlans}`,
      isStrapiApi: true,
    });
  }
);

export const compareGraphStrapi = createAsyncThunk(
  "billCalculator/compareGraphStrapi",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.BillCalculator.CompareGraph.template}${StrapiApiEndPoints?.compareGraph}`,
      isStrapiApi: true,
    });
  }
);

export const negResultTopStrapi = createAsyncThunk(
  "billCalculator/negResultTopStrapi",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.BillCalculator.NegResultTop.template}${StrapiApiEndPoints?.negResultTop}`,
      isStrapiApi: true,
    });
  }
);
export const posResultBottomStrapi = createAsyncThunk(
  "billCalculator/posResultBottomStrapi",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.BillCalculator.PosResultBottom.template}${StrapiApiEndPoints?.posResultBottom}`,
      isStrapiApi: true,
    });
  }
);

export const negResultBottomStrapi = createAsyncThunk(
  "billCalculator/negResultBottomStrapi",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.BillCalculator.NegResultBottom.template}${StrapiApiEndPoints?.netResultsBottom}`,
      isStrapiApi: true,
    });
  }
);

const billCalculatorSlice = createSlice({
  name: "billCalculator",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      comparePlansStrapi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.comparePlansData =
          action?.payload?.data?.data?.attributes || null;
        state.errorComparePlans = null;
      }
    );
    builder.addCase(comparePlansStrapi.rejected, (state, action) => {
      state.comparePlansData = null;
      state.errorComparePlans = action.error || "Something went wrong";
    });
    builder.addCase(
      compareGraphStrapi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.graphData = action?.payload?.data?.data?.attributes;
        state.errorGraphData = null;
      }
    );
    builder.addCase(compareGraphStrapi.rejected, (state, action) => {
      state.graphData = null;
      state.errorGraphData = action.error || "Something went wrong";
    });
    builder.addCase(
      negResultTopStrapi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.negResultTopData = action?.payload?.data?.data?.attributes;
        state.errorNegResultTop = null;
      }
    );
    builder.addCase(negResultTopStrapi.rejected, (state, action) => {
      state.negResultTopData = null;
      state.errorNegResultTop = action.error || "Something went wrong";
    });
    builder.addCase(
      posResultBottomStrapi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.posResultBottomData =
          action?.payload?.data?.data?.attributes || null;
        state.errorposResultBottomData = null;
      }
    );
    builder.addCase(posResultBottomStrapi.rejected, (state, action) => {
      state.posResultBottomData = null;
      state.errorposResultBottomData = action.error || "Something went wrong";
    });
    builder.addCase(
      negResultBottomStrapi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.negResultBottomData =
          action?.payload?.data?.data?.attributes || null;
        state.errorNegResultBottomData = null;
      }
    );
    builder.addCase(negResultBottomStrapi.rejected, (state, action) => {
      state.negResultBottomData = null;
      state.errorNegResultBottomData = action.error || "Something went wrong";
    });
  },
});

export default billCalculatorSlice.reducer;
