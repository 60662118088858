import { Box } from "@mui/material";

type Props = {
  isGetStatusClicked: boolean;
  getUpdatedStatus: any;
  inProgressNote: any;
  refreshIcon1: any;
  refreshIcon2: any;
};
export const InProgressNote = (props: Props) => {
  const {
    isGetStatusClicked,
    getUpdatedStatus,
    inProgressNote,
    refreshIcon1,
    refreshIcon2,
  } = props;
  return (
    <Box
      className="dataTopInProgress"
      sx={{ m: "15px 0", textAlign: "center" }}
    >
      {!isGetStatusClicked && (
        <Box onClick={getUpdatedStatus}>
          <Box
            component={"img"}
            src={refreshIcon1?.data?.attributes?.url}
            alt={refreshIcon1?.data?.attributes?.alternativeText}
            width={40}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
      )}
      {isGetStatusClicked && (
        <Box>
          <Box
            component={"img"}
            src={refreshIcon2?.data?.attributes?.url}
            alt={refreshIcon2?.data?.attributes?.alternativeText}
            width={40}
          />
        </Box>
      )}
      <Box
        className="dataTopInProgressText"
        sx={{
          color: "var(--primary_color)",
          fontFamily: "var(--font_family_Semibold)",
          fontSize: "14px",
        }}
      >
        {inProgressNote}
      </Box>
    </Box>
  );
};
