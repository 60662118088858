import { useEffect } from "react";
import { HomeBanner } from "../../../common/HomeBanner";
import { inviteFriends, inviteFriendsHandler } from "../../../widgets";
import { ActivationStatus } from "../ActivationStatus/ActivationStatus";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { getActivationModals } from "../../../../features/strapi/postActivationHomeSlice";

export const PAHomeComponent = () => {
  const { getCustomer } = useAppSelector((state: any) => state.checkout || {});
  const { activationModals } = useAppSelector(
    (state: any) => state.strapi.postActivation || {}
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    inviteFriends();
    dispatch(getActivationModals());
  }, []);
  return (
    <>
      <HomeBanner
        pageName={"PostActivationHome"}
        apiEndPoint={"paHomeBanner"}
        onButton1Click={() => inviteFriendsHandler()}
      />
      {getCustomer && activationModals && <ActivationStatus />}
    </>
  );
};
