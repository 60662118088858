import { useContext } from "react";
import { BestFitSavings } from "../../../bestfit/BestFitSavings";
import { DataUsageTable } from "../DataUsageTable";
import { MyAccountWrapper } from "../MyAccountWrapper/MyAccountWrapper";

export const SavingsSection = () => {
  const isdelivered = useContext(MyAccountWrapper);
  return (
    <>{isdelivered?.isdelivered ? <DataUsageTable /> : <BestFitSavings />}</>
  );
};
