import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  deviceConfirmContent: any;
  errorDeviceConfirmContent: any;
};

const initialState: InitialState = {
  deviceConfirmContent: null,
  errorDeviceConfirmContent: null,
};

// Generates pending, fulfilled and rejected action types
export const fetchDeviceCheckContent = createAsyncThunk(
  "deviceCheck/fetchDeviceCheckContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.DeviceCheckConfirm.template}${StrapiApiEndPoints.deviceConfirmCheck}`,
      isStrapiApi: true,
    });
  }
);
const devcieConfirmCheckSlice = createSlice({
  name: "trackOrder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
        fetchDeviceCheckContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deviceConfirmContent = action.payload?.data?.data?.attributes;
        state.errorDeviceConfirmContent = null;
      }
    );
    builder.addCase(fetchDeviceCheckContent.rejected, (state, action) => {
      state.deviceConfirmContent = null;
      state.errorDeviceConfirmContent = action.error || "Something went wrong";
    });
  },
});

export default devcieConfirmCheckSlice.reducer;
