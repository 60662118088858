import styles from "./S1_feedback.module.scss";
import { Grid, Typography } from "@mui/material";
import { TrustPilot, TrustPilotType } from "../../common";
import { feedbackProps } from "./feedback";
const FeedbackComponent = (props: feedbackProps) => {
  const { heading } = props?.content || {};

  return (
    <Grid
      container
      className={styles.feedback}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        px: { md: "40px" },
        mb: "40px",
      }}
    >
      <Grid
        item
        xs={12}
        className={styles.content_section}
        textAlign={"center"}
      >
        <Typography variant="h3" sx={{ p: "40px 0px 14px", mb: "8px" }}>
          {heading || ""}
        </Typography>
        <Grid container xs={12} className={styles.review_section}>
          <TrustPilot type={TrustPilotType.REVIEW} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FeedbackComponent;
