import Config from "../../../../config/app.config.json";
import { useEffect, useState } from "react";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { storiesContent } from "../../../../features/strapi/postActivationHomeSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";

const UserStoriesComponent = isTemplateExisits(
  Config.PostActivationHome.Stories
)
  ? require(`./${Config.PostActivationHome.Stories.template}_UserStories`)
      .default
  : null;

export type StoriesProps = {
  content?: any;
};

export const UserStories = () => {
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { stories } = useAppSelector(
    (state: any) => state.strapi?.postActivation
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache('UserStoriesPAHome', storiesContent, setCacheContent, dispatch)
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData('UserStoriesPAHome', stories)
  }, [stories])
  return (cacheContent || stories) && <UserStoriesComponent content={cacheContent? cacheContent: stories} />;
};
