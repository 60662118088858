import { Grid, Typography, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "../S1_ViewCredits.module.scss";

type Props = {
  setCard?: Function;
  cardName?: string;
  content: any;
};
export const ReferralCreditsHeader = ({
  setCard,
  cardName,
  content,
}: Props) => {
  return (
    <Grid container sx={{ padding: "30px 15px 0px 15px", mb: "20px" }}>
      <Box onClick={() => setCard && cardName && setCard(cardName)}>
        <ArrowBackIcon
          sx={{
            ml: { md: "30px", sm: "10px", xs: "20px" },
            width: "18px",
            cursor: "pointer",
          }}
          className={styles.arrow_icon}
          onClick={() => setCard && cardName && setCard(cardName)}
        />
      </Box>
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          className={styles.title_main}
          sx={{
            fontSize: "20px",
            fontFamily: "var(--font_family_Bold)",
            fontWeight: "var(--font_weight_4)",
            width: "100%",
            lineHeight: "1",
          }}
        >
          {content?.header_ttl}
        </Typography>
      </Grid>
    </Grid>
  );
};
