import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCounriesList } from "../../../../features/internationalService/internationalServiceSlice";
import { useAppDispatch } from "../../../../app/hooks";
import { SearchBoxWithDropdown } from "../../../widgets";
import CountryDataModal from "./CountryDataModal";

const ViewRates = (props: any) => {
  const { view_Rates, back_icon, back_txt, terms_icon } = props.content;

  const [getCountryList, setGetCountryList] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [isCountryModal, setIsCountryModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCounriesList()).then((res) => {
      setGetCountryList(res?.payload?.data?.data?.countriesList);
    });
  }, []);

  const setSelectedCountryHandler = (e: any, val: any) => {
    if (val) {
      setSelectedCountry(val);
      setIsCountryModal(true);
      const countryObject = {
        code: val.code,
        name: val.name,
      };
      localStorage.setItem("prRoamingCountry", JSON.stringify(countryObject));
    } else {
      setSelectedCountry(""); // Clear the selected country in your component's state
      localStorage.removeItem("prRoamingCountry");
    }
  };

  const renderOption = (props: any, option: any) => {
    if (props.customRenderOption) {
      return props.customRenderOption(props, option);
    } else {
      return (
        <span
          {...props}
          style={{ fontFamily: "var(--font_family_Medium)", fontSize: "16px" }}
        >
          {option.name}
        </span>
      );
    }
  };

  return (
    <>
      <CountryDataModal
        isCountryModal={isCountryModal}
        setIsCountryModal={setIsCountryModal}
        selectedCountry={selectedCountry}
        view_Rates={view_Rates}
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          textAlign: "center",
          top: "-50px",
        }}
      >
        {/* Back button */}
        <Box
          sx={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
            fontWeight: "var(--font_weight_3)",
            position: "absolute",
            left: "56px",
            top: "16px",
          }}
          onClick={() => props.setIsViewRates(false)}
        >
          <img
            src={back_icon.data.attributes.url}
            alt={back_icon.data.attributes.alternativeText}
            width={17.495}
            height={14.586}
          />
          {back_txt}
        </Box>
        {/* Centered input field */}
        <Box
          sx={{
            display: "inline-block",
            width: { xs: "200px", md: "600px" },
          }}
        >
          <SearchBoxWithDropdown
            dropdownList={getCountryList}
            optionLabel={(option: any) => option.name}
            value={selectedCountry}
            onChange={setSelectedCountryHandler}
            placeholder={view_Rates.search_placeholder}
            onRequest={() => null}
            onCancel={() => setSelectedCountry("")}
            logo={view_Rates.search_icon}
            renderOption={renderOption}
            no_option={view_Rates.no_option}
            sx={{
              "& .MuiFilledInput-input": {
                fontFamily: "var(--font_family_Medium) !important",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40px",
              gap: "20px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_1)",
                }}
              >
                {view_Rates.subheading}
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  component="img"
                  src={view_Rates?.call_filled?.data.attributes.url}
                  alt={view_Rates?.call_filled?.data.attributes.alternativeText}
                />
                {view_Rates.call_US}
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_1)",
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {view_Rates.premium_qual}
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                fontFamily: "var(--font_family_Medium)",
                flexDirection: "column",
                gap: "15px",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_5)",
                  color: "var(--primary_color)",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {view_Rates?.ask_question}
                <Box
                  component="img"
                  src={terms_icon?.data.attributes.url}
                  alt={terms_icon?.data.attributes.alternativeText}
                />
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_5)",
                  color: "var(--primary_color)",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {view_Rates?.terms_text}
                <Box
                  component="img"
                  src={terms_icon?.data.attributes.url}
                  alt={terms_icon?.data.attributes.alternativeText}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewRates;
