import styles from "./S1_HmBestfit.module.scss";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import Config from "../../../config/env.config.json";
import { Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";

interface HmBestFitComponentProps {
  hmBestfitContent: any;
}

const HmBestFitComponent = (props: HmBestFitComponentProps) => {
  const { hmBestfitContent } = props;
  const { navigation, button_text, description } = hmBestfitContent || {};
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(navigation);
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.bestFit,
      buttonValue: button_text,
    });
  };

  return (
    <Box
      sx={{
        p: {
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        },
        minHeight: { xs: "183px", sm: "137px" },
      }}
    >
      <Grid
        container
        className={styles.home_bestfit_block}
        py={{ xs: 3, sm: 3.75, lg: 3.75 }}
        px={{ xs: 3, sm: 3.75, lg: 7.5 }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={9}
          lg={8}
          display={{ xs: "flex" }}
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center" }}
          justifyContent={{ xs: "center", md: "flex-end" }}
        >
          <Typography
            variant="h5"
            className={styles.home_bestfit_desc}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Grid>
        <Grid
          display="flex"
          justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
          alignItems="center"
          item
          xs={12}
          sm={4}
          md={3}
          lg={4}
          padding={{ xs: 2 }}
        >
          <ButtonCust
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={handleButtonClick}
          >
            {button_text}
          </ButtonCust>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HmBestFitComponent;
