import Styles from "./ILDUserList.module.scss";
import { Box, Typography, CircularProgress } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import Config from "../../../../config/env.config.json";
import { useAppSelector } from "../../../../app/hooks";

const ILDUserList = (props: any) => {
  const { group, handleAddCreditCTA, setIsViewHistory, setLineDataForWallet } =
    props;
  const {
    user_list_container,
    user_name,
    is_primary,
    user_num,
    sim_type,
    view_history_cta,
  } = Styles;
  const {
    amt_spent,
    amt_remaining,
    view_history,
    add_credit,
    esim_txt,
    sim_txt,
    primary_txt,
  } = props.content;
  const { getILDCreditsData } = useAppSelector(
    (state: any) => state?.services || {}
  );

  return (
    <>
      {group?.data?.length > 0 ? (
        group.data
          .filter((data: any) => data.status === 2)
          .sort(
            (a: any, b: any) =>
              new Date(a.createdDate).getTime() -
              new Date(b.createdDate).getTime()
          )
          .map((data: any) => {
            const fullName = `${data.firstName} ${data.lastName}`;
            return (
              <Box className={user_list_container} key={data.id}>
                <Box
                  sx={{
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography className={user_name} pb={1}>
                        {fullName}
                        {data.isPrimary && (
                          <Box component="span" className={is_primary}>
                            {primary_txt}
                          </Box>
                        )}{" "}
                      </Typography>
                      <Typography className={user_num}>
                        {data && data.reachNumber ? (
                          <Box component="span">
                            {`(${data.reachNumber.slice(
                              0,
                              3
                            )}) ${data.reachNumber.slice(
                              3,
                              6
                            )}-${data.reachNumber.slice(6)} `}
                          </Box>
                        ) : data && data.mnpInfo?.oldNumber ? (
                          <Box component="span">
                            {`(${data.mnpInfo?.oldNumber.slice(
                              0,
                              3
                            )}) ${data.mnpInfo?.oldNumber.slice(
                              3,
                              6
                            )}-${data.mnpInfo?.oldNumber.slice(6)} `}
                          </Box>
                        ) : null}
                        <Box component="span" className={sim_type}>
                          {data.esim ? esim_txt : sim_txt}
                        </Box>
                      </Typography>
                    </Box>
                    <ButtonCust
                      variantType={Config.QUATERNARY_BUTTON_TYPE}
                      onClick={() => handleAddCreditCTA(data)}
                    >
                      {add_credit}
                    </ButtonCust>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "20px 0",
                      color: "var(--text_color_11)",
                      fontSize: "18px",
                    }}
                  >
                    <Typography>
                      <Box component="span">{amt_remaining}</Box>{" "}
                      <Box
                        component="span"
                        sx={{
                          color: "var(--primary_brand_color_dark)",
                          fontWeight: "var(--font_weight_5)",
                        }}
                      >
                        {GetCurrencyFixedToTwo(
                          (getILDCreditsData &&
                            getILDCreditsData[data.id]?.availableCredit) ||
                            0
                        )}
                      </Box>
                    </Typography>
                    <Typography>
                      <Box component="span">{amt_spent}</Box>{" "}
                      <Box
                        component="span"
                        sx={{
                          color: "var(--primary_brand_color_dark)",
                          fontWeight: "var(--font_weight_5)",
                        }}
                      >
                        {GetCurrencyFixedToTwo(
                          (getILDCreditsData &&
                            getILDCreditsData[data.id]?.purchaseCredit) ||
                            0
                        )}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  onClick={() => {
                    setIsViewHistory(true);
                    setLineDataForWallet(data);
                  }}
                  className={view_history_cta}
                >
                  {view_history}
                </Box>
              </Box>
            );
          })
      ) : (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default ILDUserList;
