import { Box, CircularProgress, Typography } from "@mui/material";
import styles from "./IRPurchaseHistory.module.scss";
import { ButtonCust, SimpleModal } from "../../../widgets";
import { useState } from "react";
import EnvConfig from "../../../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  deleteFuturePack,
  getAllPacks,
  rescheduleFuturePack,
} from "../../../../features/services/servicesSlice";
import { useNonInitialEffect } from "../../../../utils/commonFunctions";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import Config from "../../../../config/env.config.json";
import { format } from "date-fns";

const IRCancelReschedulePack = (props: any) => {
  const {
    cancel_pack,
    reschedule,
    ir_cancel,
    ir_pack_cancelled,
    reschedule_data,
  } = props.content;
  const { cancel_txt, reschedule_txt } = styles;
  const dispatch = useAppDispatch();
  const {
    deleteFuturePackData,
    deleteFuturePackPending,
    rescheduleFuturePackData,
    rescheduleFuturePackPending,
  } = useAppSelector((state) => state.services);
  const [openModal, setOpenModal] = useState(false);
  const [isPackCancelled, setIsPackCancelled] = useState(false);
  const [isCalanderModal, setIsCalanderModal] = useState(false);
  let customerDetails: any = localStorage.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);

  const handleCancelPack = () => {
    dispatch(deleteFuturePack({ packId: props.packId }));
  };

  const handlePackCancelSuccess = () => {
    dispatch(getAllPacks({ groupId: customerDetails?.groupId }));
    setOpenModal(false);
  };

  useNonInitialEffect(() => {
    if (
      deleteFuturePackData &&
      deleteFuturePackData.data &&
      deleteFuturePackData.data.status === "SUCCESS"
    ) {
      setIsPackCancelled(true);
    }
  }, [deleteFuturePackData]);

  useNonInitialEffect(() => {
    if (
      rescheduleFuturePackData &&
      rescheduleFuturePackData.data &&
      rescheduleFuturePackData.data.status === "SUCCESS"
    ) {
      props.setRescheduleSuccess(true);
    }
  }, [rescheduleFuturePackData]);

  const calanderSubmitHandler = () => {
    dispatch(
      rescheduleFuturePack({
        packId: props.packId,
        data: { tentativeStartDate: props.selectedDate },
      })
    );
  };

  const calanderOnChangeHandler = (e: any) => {
    const formattedForUi = formatDateForUI(e);
    props.setSelectedDate(formattedForUi);
  };

  const formatDateForUI = (e: any) => {
    const date = new Date(e);
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  return (
    <Box component="div">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Box className={cancel_txt} onClick={() => setOpenModal(true)}>
          {cancel_pack}
        </Box>
        <Box
          className={reschedule_txt}
          onClick={() => setIsCalanderModal(true)}
        >
          {reschedule}
        </Box>
      </Box>
      <SimpleModal
        isShowModal={openModal ? true : false}
        onCloseModal={() => setOpenModal(false)}
        showClose={true}
        sx={{
          width: { xs: "90%", sm: "60%", md: "80%", lg: "45%", xl: "35%" },
          borderRadius: "6px",
        }}
      >
        {!isPackCancelled ? (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography
              component="div"
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_5)",
                fontSize: "22px",
                color: "var(--tab_color_2)",
                lineHeight: "22px",
              }}
            >
              {ir_cancel?.title}
            </Typography>
            <Box className={styles.line}></Box>
            <Box
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontWeight: "var(--font_weight_5)",
                fontSize: "14px",
                color: "var(--tab_color_2)",
                lineHeight: "24px",
                margin: "30px 0 40px",
              }}
            >
              {ir_cancel?.text1}
            </Box>
            <Box>
              <ButtonCust
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                sx={{
                  my: "10px",
                  width: {
                    xs: "65%",
                  },
                  height: "45px",
                }}
                onClick={handleCancelPack}
              >
                {deleteFuturePackPending ? (
                  <CircularProgress />
                ) : (
                  ir_cancel?.button9
                )}
              </ButtonCust>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Box
              component="img"
              alt={
                ir_pack_cancelled.addOn_status?.data?.attributes
                  ?.alternativeText || ""
              }
              src={ir_pack_cancelled.addOn_status?.data?.attributes?.url || ""}
            />
            <Typography
              component="div"
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_5)",
                fontSize: "22px",
                color: "var(--tab_color_2)",
                lineHeight: "22px",
                marginTop: "15px",
              }}
            >
              {ir_pack_cancelled?.title}
            </Typography>
            <Box
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontWeight: "var(--font_weight_5)",
                fontSize: "14px",
                color: "var(--tab_color_2)",
                lineHeight: "24px",
                margin: "20px 0 30px",
              }}
            >
              {ir_pack_cancelled?.desc}
            </Box>
            <Box>
              <ButtonCust
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                sx={{
                  my: "10px",
                  width: {
                    xs: "45%",
                  },
                  height: "45px",
                }}
                onClick={handlePackCancelSuccess}
              >
                {ir_pack_cancelled?.button}
              </ButtonCust>
            </Box>
          </Box>
        )}
      </SimpleModal>
      <CustomDateCalendar
        onClick={calanderSubmitHandler}
        onChange={calanderOnChangeHandler}
        isCalanderModal={isCalanderModal}
        onCloseModal={setIsCalanderModal}
        title={reschedule_data.title}
        desc={reschedule_data.desc}
        button={reschedule_data.btn_2}
        variant={Config.QUATERNARY_BUTTON_TYPE}
        rescheduleFuturePackPending={rescheduleFuturePackPending}
      />
    </Box>
  );
};

export default IRCancelReschedulePack;
