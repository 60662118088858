import { PlanFeaturesProps } from "./PlanFeatures";
import styles from "./S3_PlanFeature.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { Divider } from "../../widgets";
const PlanFeaturesComponent = (props: PlanFeaturesProps) => {
  let user: any = JSON.parse(localStorage.getItem("customerDetail") || "null");
  const isSimpaid = user?.simPaid;
  return (
    <Box
      sx={{
        padding: {
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        },
        minHeight: {xs: "1525px", sm: "695px", md: "485px"}
      }}>
      <Grid
        container
        display="flex"
        justifyContent="space-around"
        className={styles.plan_features_container}
        sx={{ backgroundColor: `${isSimpaid ? "var(--lightgrey)" : "var(--lite_background)"}`, borderTop: `${isSimpaid ? "1px solid var(--dusty-orange)" : "none"}` }}
      >
        {isSimpaid ?
          <Box pt="50px">
            <Typography className={styles.acc_feature_header}>Features You Can’t Miss</Typography>
            <Divider styles={{ mt: "20px", mb: "25px" }} />
          </Box> :
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "64px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Box
              component={"h3"}
              className={styles.feature_header}
              sx={{
                p: "20px 0",
                textAlign: "center",
                m: { xs: "-45px 0 0 " },
                width: { sm: "485px", xs: "100%" },
              }}
            >
              {props?.featureContent?.header}
            </Box>
          </Grid>
        }
        <Grid
          container
          display="flex"
          justifyContent="center"
          sx={{ marginBottom: { md: "74px" } }}
          rowSpacing="20px"
        >
          {props?.featureContent &&
            props.featureContent?.plan_feature.map((content: any) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                textAlign="center"
                key={content.title}
                sx={{ marginBottom: { md: "0px", xs: "31px" } }}
              >
                <Box
                  sx={{ marginBottom: { xs: "31px" } }}
                  component="img"
                  alt="feature"
                  src={content?.image?.data?.attributes?.url || ""}
                  className={styles.plan_features_image}
                  height={"78"}
                ></Box>
                <Typography
                  variant="body1"
                  className={isSimpaid ? styles.acc_plan_features_title : styles.plan_features_title}
                  sx={{ marginBottom: "9px" }}
                >
                  {content.title}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};
export default PlanFeaturesComponent;
