import { useContext, useEffect, useState } from "react";
import { HomeBanner } from "../../../common/HomeBanner";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { SimpleModal, ButtonCust } from "../../../widgets";
import EnvConfig from "../../../../config/env.config.json";
import { Box } from "@mui/material";
import Styles from "./MyAccountBanner.module.scss";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { MyAccountWrapper } from "../MyAccountWrapper/MyAccountWrapper";
import { fetchPhoneModalContent } from "../../../../features/strapi/myAccountSlice";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import {
  deleteCartItems,
  getCartItems,
} from "../../../../features/shopWare/shopWareSlice";

export const MyAccountBanner = () => {
  const dispatch = useAppDispatch();
  const isdelivered = useContext(MyAccountWrapper);
  const navigate = useNavigate();
  const [isActivate, setIsActivate] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>("");
  const { phoneUnlockContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  useEffect(() => {
    dispatch(fetchPhoneModalContent());
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      deleteCart();
    }
  }, []);

  useNonInitialEffect(() => {
    setModalContent(phoneUnlockContent);
  }, [phoneUnlockContent]);

  const activateHandler = () => {
    setIsActivate(true);
  };
  const onHide = () => {
    setIsActivate(false);
  };
  const deleteCart = () => {
    let lineItemsInCart: any = localStorage.getItem("lineItems");
    lineItemsInCart = JSON.parse(lineItemsInCart);
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    planDetails = JSON.parse(planDetails);
    let deletionId: any = lineItemsInCart?.find((item: any) => {
      return item?.payload?.planName === planDetails?.plan?.displayName;
    });
    if (deletionId) {
      dispatch(deleteCartItems(deletionId?.id)).then((res: any) => {
        if (res) {
          dispatch(getCartItems());
        }
      });
    }
  };
  const viewTrackingOrders = () => {
    const customerDetails = JSON.parse(
      localStorage?.getItem("customerDetail") || "null"
    );
    customerDetails?.esim
      ? navigate(RoutingURLs.activateEsim, { state: "isTrack" })
      : navigate(RoutingURLs.trackOrder, { state: "isTrack" });
  };
  const { modal_content, border_bottom, head, try_again, sub_head } = Styles;
  const { title, subheading, learn_text, button_text } = modalContent;

  return (
    <>
      {isdelivered !== undefined && isdelivered?.isdelivered ? (
        <HomeBanner
          pageName={"MyAccount"}
          apiEndPoint={"postSimBanner"}
          onButton1Click={activateHandler}
          onButton2Click={viewTrackingOrders}
        />
      ) : null}
      {isdelivered !== undefined && !isdelivered?.isdelivered ? (
        <HomeBanner
          pageName={"MyAccount"}
          apiEndPoint={"preSimBanner"}
          onButton1Click={viewTrackingOrders}
        />
      ) : null}
      {modalContent && (
        <SimpleModal
          isShowModal={isActivate}
          onCloseModal={() => onHide()}
          showClose={true}
        >
          <Box className={modal_content}>
            <Box className={head}>{title}</Box>
            <Box className={border_bottom}></Box>
            <Box className={sub_head}>{BrandNameUpdate(subheading)}</Box>
            <Box
              className={try_again}
              onClick={() =>
                window.open(globalVal?.unlockPhoneBlogURL, "_blank")
              }
            >
              {learn_text}
            </Box>
            <Box>
              <ButtonCust
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  if (EnvConfig?.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED) {
                    navigate(RoutingURLs.deviceConfirmCheck);
                  } else {
                    navigate(RoutingURLs.activation);
                  }
                }}
              >
                {button_text}
              </ButtonCust>
            </Box>
          </Box>
        </SimpleModal>
      )}
    </>
  );
};
