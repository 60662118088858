import { styled, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

export const CustToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "var(--text_color_1)",
    minWidth: "fit-content",
    maxWidth: "none",
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,.2)",
    margin: "0px !important",
    padding: "0px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: "var(--white)",
      boxShadow: "var(--box-shadow_6)",
      border: "1px solid rgba(0,0,0,.2)",
      fontSize: "17px",
    },
  },
}));
