import { ButtonCust, SimpleModal } from "../../../../widgets";
import { Box, Typography } from "@mui/material";
import EnvConfig from "../../../../../config/env.config.json";
import { useEffect, useRef, useState } from "react";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getPlanTaxCharges } from "../../../../../features/checkout/checkoutSlice";
import { getCC } from "../../../../../features/account/accountSlice";

const AddCredits = (props: any) => {
  const { add_credit_details, primary_txt, dec_inc_icon } = props.content;
  const {
    openCreditModal,
    setOpenCreditModal,
    userLineData,
    setIsCreditPayment,
    creditAmount,
    setcreditAmount,
    configKeys,
  } = props;
  const dispatch = useAppDispatch();

  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;

  const addCreditIncDecHandler = (type: string) => {
    if (type === "inc" && creditAmount < Number(configKeys?.ILD_MAX_CREDIT_AMOUNT)) {
      setcreditAmount(
        (prevState: any) =>
          prevState + Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
      );
    } else if (type === "dec" && creditAmount > Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)) {
      setcreditAmount(
        (prevState: any) =>
          prevState - Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
      );
    }
  };
  const handleConfirmPurchase = () => {
    const address = {
      name: `${userLineData.firstName} ${userLineData.lastName}`,
      address1: userLineData?.addresses[0]?.address1,
      city: userLineData?.addresses[0]?.city,
      country: userLineData?.addresses[0]?.country,
      state: userLineData?.addresses[0]?.state,
      type: "billing",
      zip: userLineData?.addresses[0]?.zip,
    };
    dispatch(
      getCC({
        customerId: currentdetails && currentdetails.id,
      })
    );
    dispatch(
      getPlanTaxCharges({
        address,
        amount: creditAmount,
        additionalLines: userLineData?.totalActiveLines,
      })
    );
    setOpenCreditModal(false);
    setIsCreditPayment(true);
  };
  return (
    <SimpleModal
      isShowModal={openCreditModal ? true : false}
      onCloseModal={() => {
        setOpenCreditModal(false);
        setcreditAmount(Number(configKeys?.ILD_INCR_CREDIT_AMOUNT));
      }}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "60%", md: "80%", lg: "40%", xl: "45%" },
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          fontSize: "22px",
          fontWeight: "var(--font_weight_8)",
          color: "var(--tab_color_2)",
          fontFamily: "var(--font_family_medium)",
        }}
      >
        {add_credit_details.title}
      </Box>
      <Box
        sx={{
          width: "80px",
          height: "5px",
          borderRadius: "3px",
          background: "#333",
          margin: "20px auto",
          textAlign: "center",
        }}
      ></Box>
      <Typography
        sx={{
          fontSize: "16px",
          fontFamily: "var(--font_family_medium)",
          margin: "10px 0 20px",
          color: "var(--text_color)",
          fontWeight: "var(--font_weight_1)",
        }}
      >
        <Box
          component="span"
          sx={{
            fontWeight: "var(--font_weight_3)",
          }}
        >
          {add_credit_details.lineTxt}
        </Box>{" "}
        <Box component="span">
          {userLineData?.firstName} {userLineData?.lastName}
        </Box>{" "}
        {userLineData?.isPrimary && (
          <Box
            component="span"
            sx={{
              color: "var(--footer_divider_color)",
            }}
          >
            {primary_txt}
          </Box>
        )}
      </Typography>
      <Box
        sx={{
          fontSize: "20px",
          fontWeight: "var(--font_weight_8)",
          color: "var(--text_color)",
          fontFamily: "var(--font_family_medium)",
        }}
      >
        {add_credit_details.desc}
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          margin: "30px 0 15px",
        }}
      >
        <Box
          component="img"
          alt={dec_inc_icon?.data[0].attributes?.alternativeText || ""}
          src={dec_inc_icon?.data[0].attributes?.url || ""}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => addCreditIncDecHandler("dec")}
        />
        <Typography
          component="div"
          sx={{
            fontSize: "20px",
          }}
        >
          {GetCurrencyFixedToTwo(creditAmount)}
        </Typography>
        <Box
          component="img"
          alt={dec_inc_icon?.data[1].attributes?.alternativeText || ""}
          src={dec_inc_icon?.data[1].attributes?.url || ""}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => addCreditIncDecHandler("inc")}
        />
      </Box>
      <ButtonCust
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        sx={{
          width: "300px",
          mt: "25px",
        }}
        onClick={() => handleConfirmPurchase()}
      >
        {add_credit_details.btnTxt}
      </ButtonCust>
    </SimpleModal>
  );
};

export default AddCredits;
