import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { CustomerStatus } from "../../enums";
import styles from "./S2_Disconnected.module.scss";

const T2_Disconnected = (props: any) => {
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const {content} = props || {};

  const [userStatusMsg, setUserStatusMsg] = useState("")

  useEffect(() => {
    if (getCustomer?.billDue && getCustomer?.status === CustomerStatus.DISCONNECTED) {
      setUserStatusMsg(content?.disconnected_txt2)
      const currentDate: any = new Date();
      const lastDisconnectedDate: any = new Date(getCustomer?.lastDisconnectedDate);
      const diffTime: any = currentDate.getTime() - lastDisconnectedDate?.getTime();
      const no_of_days_after_disconnected = Math.round(diffTime / (1000 * 60 * 60 * 24)) + 61;
      if (no_of_days_after_disconnected > 90) {
        setUserStatusMsg(content?.disconnected_txt1)
      }
    } else if (getCustomer?.billDue && getCustomer?.status === CustomerStatus.SUSPENDED) {
      setUserStatusMsg(content?.suspended_txt)
    }
  }, [getCustomer])

  return (
    <Box
      className={styles.disconnect_container}
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={{
        xs: "calc(var(--mobile_section_padding)) 0 calc(var(--mobile_section_padding))",
        sm: "calc(var(--ipad_section_padding)) 0 calc(var(--ipad_section_padding))",
        md: "calc(var(--desktop_section_padding)) 0 calc(var(--desktop_section_padding))"
      }}
    >
      <Box
        component="img"
        src={content?.cross_icon?.data?.attributes?.url}
        alt={content?.cross_icon?.data?.attributes?.alternativeText}
        sx={{ mb: "10px", width: "39px", height: "39px" }}
      />
      <Box
        className={styles.disconnect_text}
        dangerouslySetInnerHTML={{
          __html: `${userStatusMsg}`,
        }}
      ></Box>
    </Box>
  );
};
export default T2_Disconnected;
