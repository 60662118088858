import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { strapiBannerContent } from "../../../features/strapi/componentSlice";
import { scroller } from "react-scroll";
import { RoutingURLs } from "../../../config/RoutingURLs";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { NotificationCust } from "../../widgets";
import { Store } from "react-notifications-component";
import flatMap from "lodash/flatMap";
import get from "lodash/get";
import { Link, Box } from "@mui/material";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useLocation, useNavigate } from "react-router-dom";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { liveOpsMessagesApi } from "../../../features/homepage/homepageSlice";
import { OpsMsgTypes } from "../../../features/homepage/OpsMsgTypes";
import { shopWareRedirectionHandler } from "../../../utils/commonFunctions/shopWareFunctions";
export type HomeBannerProps = {
  bannerContent: any;
  onButton1Click?: any;
  onButton2Click?: any;
  pageName?: string;
};

type Props = {
  pageName: string;
  apiEndPoint: string;
  onButton1Click?: any;
  onButton2Click?: any;
};

export const HomeBanner = (props: Props) => {
  const { pageName, apiEndPoint, onButton1Click, onButton2Click } = props;
  const selectedLine = JSON.parse(
    localStorage.getItem("reachSelectedPlanDetail")!
  );
  let template = get(Config, `${pageName}.Banner.template`);
  const BannerComponent = isTemplateExisits(get(Config, `${pageName}.Banner`))
    ? require(`./${template}_HomeBanner`).default
    : null;
  const dispatch = useAppDispatch();
  const [bannerContent, setBannerContent] = useState<any>();
  const { BannerContent } = useAppSelector(
    (state: any) => state.strapi.commonPage
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [cacheDataHomeBanner, setCacheDataHomeBanner] = useState<any>(null);
  const { getCartItemsSuccess } = useAppSelector(
    (state: any) => state.shopWare
  );
  const { notification } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  const { liveOpsMessagesSuccess, liveOpsMessagesError } = useAppSelector(
    (state: any) => state.homepage
  );

  useEffect(() => {
    EnvConfig?.IS_MSG_CENTER_ENABLED &&
      dispatch(
        liveOpsMessagesApi({
          limit: OpsMsgTypes.limit,
          partialMatch: true,
          query: "",
          status: [OpsMsgTypes.statusInProgress],
        })
      );
  }, []);

  useEffect(() => {
    // Live Ops Messages
    if (
      EnvConfig?.IS_MSG_CENTER_ENABLED &&
      liveOpsMessagesSuccess?.status === "SUCCESS" &&
      liveOpsMessagesSuccess?.data?.results
    ) {
      liveOpsMessagesSuccess?.data?.results?.forEach(
        (opsMessage: any, index: number) => {
          if (
            opsMessage?.inventory[
              "messaging_center.website.homepage_toast_header"
            ] &&
            (location.pathname === RoutingURLs.home ||
              location.pathname === RoutingURLs.default ||
              location.pathname === RoutingURLs.plans)
          ) {
            NotificationCust({
              message: `${opsMessage.messageType}: ${opsMessage.message}`,
              duration: 0,
              type: "info",
              id: `opsMessage${index}`,
              showIcon: true,
            });
          }
        }
      );
      return () => {
        for (
          let i = 0;
          i < liveOpsMessagesSuccess?.data?.results?.length;
          i++
        ) {
          Store.removeNotification(`opsMessage${i}`);
        }
      };
    }
  }, [liveOpsMessagesSuccess]);

  useEffect(() => {
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      let currentuser: any = localStorage.getItem("currentuser");
      currentuser = JSON.parse(currentuser);
      let notifyType: any = localStorage.getItem("typeNotify");
      if (currentuser && !currentuser.isAnonymous) {
        if (
          selectedLine &&
          selectedLine.plan &&
          selectedLine.plan.name &&
          (location.pathname === RoutingURLs.default ||
            location.pathname === RoutingURLs.home)
        ) {
          if (
            getCartItemsSuccess?.lineItems.length > 0 &&
            notifyType &&
            notifyType === "show"
          ) {
            let notificationMessage: any =
              notification?.pageNotification?.shopwareCart;
            notificationMessage = flatMap(
              notificationMessage?.split("{clickHere}"),
              function (part) {
                return [
                  part,
                  <Link
                    key={part}
                    underline="always"
                    color="inherit"
                    sx={{ fontWeight: "var(--font_weight_3)" }}
                    onClick={cartHandler}
                  >
                    Click here
                  </Link>,
                ];
              }
            );
            notificationMessage.pop();
            notification &&
              NotificationCust({
                message: (
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Semibold)",
                    }}
                  >
                    {notificationMessage}
                  </Box>
                ),
                duration: 0,
                id: "PlanNotification",
                showIcon: true,
              });
            //items in your cart might changed.
          }
        }
      }
    }
  }, [getCartItemsSuccess]);
  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      let apiData: any = {
        pageName: pageName,
        apiEndPoint: apiEndPoint,
      };
      getCache(
        apiEndPoint,
        strapiBannerContent,
        setCacheDataHomeBanner,
        dispatch,
        apiData
      );
      initialRender1.current = false;
    } else {
      let bannerContent = BannerContent;
      setBannerContent(bannerContent);
      addMultipleCacheData(apiEndPoint, BannerContent);
    }
    return () => {
      Store.removeNotification("PlanNotification");
    };
  }, [BannerContent]);

  useEffect(() => {
    let customerDetail1 = localStorage.getItem("customerDetail") || "{}";
    let customerDetail = JSON.parse(customerDetail1);
    if (
      !Object.keys(customerDetail)?.length &&
      !customerDetail.simPaid &&
      EnvConfig.IS_HOME_BANNER_NOTIFICATION_NEEDED
    ) {
      notification &&
        NotificationCust({
          message: notification?.pageNotification?.homePage,
          duration: 0,
          id: "HomeNotification",
          showIcon: true,
        });
      if (selectedLine?.line) {
        let notificationMessage: any =
          notification?.pageNotification?.planNotification
            .replace("{name}", selectedLine?.plan?.name)
            .replace("{line}", selectedLine?.line);
        notificationMessage = flatMap(
          notificationMessage?.split("{clickHere}"),
          function (part) {
            return [
              part,
              <Link
                key={part}
                underline="always"
                color="inherit"
                sx={{ fontWeight: "var(--font_weight_3)" }}
                onClick={() => navigate(RoutingURLs.checkout)}
              >
                Click here
              </Link>,
            ];
          }
        );
        notificationMessage.pop();
        notification &&
          NotificationCust({
            message: (
              <Box
                sx={{
                  fontSize: "14px",
                  fontFamily: "var(--font_family_Semibold)",
                }}
              >
                {notificationMessage}
              </Box>
            ),
            duration: 0,
            id: "PlanNotification",
            showIcon: true,
          });
      }
    }
  }, [notification]);
  useEffect(() => {
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      let searchParams = new URLSearchParams(document.location.search);
      let showPLans: string | null = searchParams.get("show-plans");
      if (showPLans) {
        setTimeout(function () {
          window.scrollTo({
            top: 600,
            behavior: "smooth",
          });
        }, 2000);
      }
    }
    return () => {
      Store.removeNotification("HomeNotification");
    };
  }, []);
  const onButtonClick = () => {
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }, 100);
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.plan,
      buttonValue: cacheDataHomeBanner
        ? cacheDataHomeBanner?.button_text
        : bannerContent?.button_text,
    });
  };
  const cartHandler = () => {
    localStorage.removeItem("typeNotify");
    shopWareRedirectionHandler(EnvConfig.SHOP_DOMAIN, "cart");
  };
  return (
    BannerComponent && (
      <BannerComponent
        bannerContent={
          cacheDataHomeBanner ? cacheDataHomeBanner : bannerContent
        }
        onButton1Click={
          pageName === "Home" || "CellPhonePlans"
            ? onButtonClick
            : onButton1Click
        }
        onButton2Click={onButton2Click}
        {...props}
      />
    )
  );
};
