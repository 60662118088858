import React, { useEffect, useState } from "react";
import WalletCard from "../HistoryCard/WalletCard";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { getTransactionHistory } from "../../../../../features/services/servicesSlice";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { ButtonCust } from "../../../../widgets";
import { format, isWithinInterval, parse } from "date-fns";

const TransactionHistory = (props: any) => {
  const dispatch = useAppDispatch();
  const {
    date,
    amount,
    card,
    card_type,
    view_more,
    view_less,
    less_icon,
    greater_icon,
    no_transaction,
  } = props.content;
  const { getTransactionHistoryData, getTransactionHistoryPending } =
    useAppSelector((state) => state?.services);

  const initialItemsToShow = 3;
  const getStartAndEndDateJSON: any = localStorage.getItem("StartEndDate");
  const getStartAndEndDateData = JSON.parse(getStartAndEndDateJSON);

  function convertDateFormat(inputDate: any) {
    // Create a Date object by parsing the original date string
    const dateObject = new Date(inputDate);

    // Extract year, month, and day components
    const year = dateObject.getFullYear();
    // Months are 0-based in JavaScript, so we add 1 to get the correct month
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    // Create the formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const startDate = convertDateFormat(getStartAndEndDateData.displayStartDate);
  const endDate = convertDateFormat(getStartAndEndDateData.displayEndDate);

  const newFilteredData = getTransactionHistoryData.filter(
    (item: any) => item.billMonth >= startDate && item.billMonth <= endDate
  );

  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);

  const formatDate = (PurchaseDate: any) => {
    const date = new Date(PurchaseDate);
    const options: any = { year: "numeric", month: "short", day: "numeric" };

    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  useEffect(() => {
    const payload = {
      customerID: props.lineDataForWallet.id,
      params: { creditType: "ILD" },
    };
    dispatch(getTransactionHistory(payload));
  }, []);

  const handleViewMoreClick = () => {
    if (itemsToShow === initialItemsToShow) {
      setItemsToShow(getTransactionHistoryData?.length); // Show all items
    } else {
      setItemsToShow(initialItemsToShow); // Show limited items
    }
  };

  const toggleButtonText = () => {
    if (itemsToShow === initialItemsToShow) {
      return view_more;
    } else {
      return view_less;
    }
  };

  const newData: any = [];
  return (
    <div>
      <WalletCard
        lineDataForWallet={props.lineDataForWallet}
        content={props.content}
      >
        {!getTransactionHistoryPending ? (
          newFilteredData?.length === 0 ? (
            <Box p={10}>
              <Typography
                sx={{
                  fontSize: "34px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_5)",
                  opacity: 0.4,
                }}
              >
                {no_transaction}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  onClick={() => {
                    if (
                      getStartAndEndDateData.totalNumberOfKeys > 0 &&
                      getStartAndEndDateData.selectedDateKeyIndex
                    ) {
                      props.handleBillCycleChange("decrement");
                    }
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={less_icon.data.attributes.url}
                    alt={less_icon.data.attributes.alternativeText}
                  />
                </Box>

                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_1)",
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {getStartAndEndDateData?.displayStartDate}
                </Typography>
                <span>-</span>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_1)",
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {getStartAndEndDateData?.displayEndDate}
                </Typography>

                <Box
                  onClick={() => {
                    if (
                      getStartAndEndDateData.totalNumberOfKeys > 0 &&
                      getStartAndEndDateData.selectedDateKeyIndex
                    ) {
                      props.handleBillCycleChange("increment");
                    }
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={greater_icon.data.attributes.url}
                    alt={greater_icon.data.attributes.alternativeText}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: { xs: "40%", md: "85%" },
                  margin: "auto",
                }}
              >
                <Typography
                  sx={{
                    flex: "1", // Allow the first column to grow
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {date}
                </Typography>
                <Typography
                  sx={{
                    flex: "1",
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {amount}
                </Typography>
                <Typography
                  sx={{
                    flex: "1",
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {card}
                </Typography>
              </Box>

              {newFilteredData?.slice(0, itemsToShow).map((data: any) => (
                <Box
                  key={data.uuid}
                  sx={{
                    flex: "1",
                    display: "flex",
                    justifyContent: "space-between",
                    width: { xs: "40%", md: "85%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      flex: "1", // Allow the first column to grow
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_1)",
                    }}
                  >
                    {formatDate(data.purchaseDate)}
                  </Typography>
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_1)",
                    }}
                  >
                    {GetCurrencyFixedToTwo(data.purchasedCredit)}
                  </Typography>
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_1)",
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {data.cardType === "Visa" && (
                      <img
                        src={card_type.data.attributes.url}
                        alt={card_type.data.attributes.alternativeText}
                      />
                    )}
                    xx
                    {data.ccNumberMask}
                  </Typography>
                </Box>
              ))}
              <Divider />
              {newFilteredData?.length >= 3 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    onClick={handleViewMoreClick}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                  >
                    {toggleButtonText()}
                  </Typography>
                </Box>
              )}
            </Box>
          )
        ) : (
          <Box textAlign={"center"}>
            <CircularProgress sx={{ color: "var(--primary_color)" }} />
          </Box>
        )}
      </WalletCard>
    </div>
  );
};

export default TransactionHistory;
