import {
  Box,
  CircularProgress,
  ClickAwayListener,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  ButtonCust,
  CustToolTip,
  Divider,
  RadioCust,
  SimpleModal,
  TextFieldCust,
} from "../../widgets";
import { EsimIMEIComponentProps } from "./EsimIMEI";
import Styles from "./ESimCheck.module.scss";
import {
  digitsOnlyPattern,
  PhoneNoPattern,
} from "../../../utils/regexPatterns";

import { useAppSelector } from "../../../app/hooks";
import {
  BrandNameUpdate,
  ModelNameUpdate,
  getLastCharacter,
} from "../../../utils/commonFunctions/BrandNameUpdate";
import { SimTypes } from "../../../features/checkout/Types";
import { useEffect, useState } from "react";
import EnvConfig from "../../../config/env.config.json";
import { CarrierTypes } from "../../../enums";
import ApnNote from "../../apnSettings/Apn_Note";

const T2_EsimIMEI = (props: EsimIMEIComponentProps) => {
  const {
    showCheckIMEIModal,
    closeCompatModal,
    fromAddLine,
    increaseLine,
    handleEnter,
    isCompatible,
    retry,
    checkHandler,
    imeiNumbers,
    imeiHandler,
    checkClick,
    isImeiUsed,
    errorInCompat,
    imeiNumber,
    onChangeHandler,
    imeiErr,
    inputDisabled,
    moveToCheckoutPage,
    content,
    compatibility,
    setRetry,
    setErrorInCompat,
    setIsCompatible,
    setImeiNumber,
    setInputDisabled,
    currentFieldIndex,
    imeiCheckErrorMsg,
    inputDisabledIndex,
    imeiData,
    isAccountScreen,
    multipleImeiCheck,
    setShowReviewModal1,
    loader,
    modelCompat,
    setModalCompat,
    isEsimAvailabe,
    osdata,
    closeImeiModal,
    displayImeiModal,
    closeCompImeiModal
  } = props;
  const {
    check_device,
    checkIMEI_modal_container,
    limit_div,
    check_imei_input_box,
    compatibility_check_input,
    check_coverage_input_box,
    info_icon,
    tick_icon,
    tick_icon_point,
    ns_link,
    close_icon_imei,
    checkImei_loader,
    info_content_ios,
  } = Styles;
  const {
    heading,
    sub_heading,
    Ph_success_msg,
    failure_msg,
    imei_label,
    imei_err_1,
    imei_err_2,
    imei_err_3,
    imei_err_4,
    limit_imei,
    button_1,
    check_another_device,
    info_img,
    network_settings,
    tooltip_text,
    tooltip_link,
    tick_img,
    close_img,
    proceed_btn,
    secondary_txt,
    skip_text,
    model_compat_fail_msg,
    note_txt,
  } = content || {};
  const [simType, setSimType] = useState(SimTypes.sim);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState("");

  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );

  const openPopper = (data: any) => {
    setOpen(true);
  };
  const handleSimTypeChange = (e: any) => {
    setSimType(e.target.value);
    let simData: any = localStorage.getItem("simPreference");
    simData = JSON.parse(simData);

    if (simData) {
      simData[0]["eSimOpted"] = e.target.value === "Sim" ? false : true;
      simData[0]["eSimCompatible"] = e.target.value === "Sim" ? false : true;
      simData[0]["value"] = e.target.value;
      localStorage.setItem("simPreference", JSON.stringify(simData));
    }
  };
  const onClose = () => {
    setOpen(false);
    setAnchorEl("");
  };
  const resetPrimarySimPref = () => {
    let simPreferencePayload: any = JSON.parse(
      localStorage.getItem("simPreference") || "null"
    );
    const resetPrimaryLine = simPreferencePayload?.map(
      (linePrefItem: any, index: any) => {
        return index === 0
          ? {
              ...linePrefItem,
              imei: "",
              value: "Sim",
              make: "",
              model: "",
              eSimCompatible: false,
              eSimOpted: false,
            }
          : linePrefItem;
      }
    );
    localStorage.setItem("simPreference", JSON.stringify(resetPrimaryLine));
  };
  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Box
          component="a"
          href={tooltip_link}
          target="_blank"
          sx={{
            color: "var(--primary_brand_color)",
            fontFamily: "var(--font_family_Medium)",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "var(--font_weight_2)",
            lineHeight: "22px",
            margin: "5px",
          }}
        >
          {data}
        </Box>
      </ClickAwayListener>
    );
  };

  const renderNetworkSettings = () => {
    if (compatibility !== undefined) {
      return compatibility?.data?.data?.make.toLowerCase() == "apple" ? (
        <>
          <Typography
            component="div"
            sx={{
              color: "var(--text-color)",
              fontFamily: "var(--font_family_Bold)",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "var(--font_weight_2)",
              lineHeight: "22px",
              marginBottom: "8px",
            }}
          >
            {network_settings[0].title}
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "var(--text-color)",
              fontFamily: "var(--font_family_Medium)",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "var(--font_weight_2)",
              lineHeight: "26px",
              margin: "5px 0px",
              textAlign: "justify",
            }}
          >
            <Box component="div">
              {" "}
              {BrandNameUpdate(network_settings?.[0]?.desc_1)}&nbsp;
              <Box
                component="a"
                className={ns_link}
                href={network_settings[0].link1_url}
                target="_blank"
              >
                {network_settings[0].link_1}
              </Box>
              &nbsp;
              {BrandNameUpdate(network_settings?.[0]?.link1_suffix)}
            </Box>
          </Typography>
          {network_settings?.[0]?.desc_2 && (
            <Box>
              <Typography
                component="span"
                className={info_content_ios}
                sx={{
                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {note_txt}
              </Typography>
              &nbsp;
              <Typography
                component="span"
                className={info_content_ios}
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {BrandNameUpdate(network_settings?.[0]?.desc_2)}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <>
          <Typography
            component="div"
            sx={{
              color: "var(--text-color)",
              fontFamily: "var(--font_family_Bold)",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "var(--font_weight_2)",
              lineHeight: "22px",
              marginBottom: "8px",
            }}
          >
            {network_settings[2].title}
          </Typography>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {network_settings?.[2]?.icon?.data?.attributes?.url ? (
              <>
                <Box
                  component="img"
                  className={tick_icon_point}
                  src={network_settings[2].icon?.data?.attributes?.url}
                ></Box>{" "}
              </>
            ) : null}
            <Typography
              component="div"
              sx={{
                color: "var(--text-color)",
                fontFamily: "var(--font_family_Medium)",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "var(--font_weight_2)",
                lineHeight: "26px",
                margin: "5px 0px",
                textAlign: "justify",
              }}
            >
              {BrandNameUpdate(network_settings?.[2]?.desc_1)}&nbsp;
              <Box
                component="a"
                className={ns_link}
                href={network_settings[2].link1_url}
                target="_blank"
              >
                {network_settings[2].link_1}
              </Box>
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {network_settings?.[2]?.icon?.data?.attributes?.url ? (
              <>
                <Box
                  component="img"
                  className={tick_icon_point}
                  src={network_settings[2].icon?.data?.attributes?.url}
                ></Box>{" "}
              </>
            ) : null}
            {/* <Typography
              component="div"
              sx={{
                color: "var(--text-color)",
                fontFamily: "var(--font_family_Medium)",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "var(--font_weight_2)",
                lineHeight: "26px",
                margin: "5px",
              }}
            >
              {BrandNameUpdate(renderData?.[0]?.desc_2)}
              <Box
                component="a"
                className={ns_link}
                href={renderData[0].link2_url}
                target="_blank"
              >
                {renderData[0].link_2}
              </Box>
            </Typography> */}
          </Box>
        </>
      );
    }
  };

  return (
    content && (
      <>
        <SimpleModal
          isShowModal={showCheckIMEIModal}
          onCloseModal={() => closeCompatModal()}
          showClose={true}
          sx={{
            width: {
              xs: "95%",
              sm: "70%",
              md: "80%",
              lg: "45%",
              xl: "32%",
              padding: "30px",
            },
          }}
          style={{ borderRadius: "4px" , display: (displayImeiModal ? "inline" : "none")}}
        >
          {!isCompatible && !errorInCompat && modelCompat ? (
            <Stack
              onKeyDown={handleEnter}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              className={checkIMEI_modal_container}
            >
              <Typography
                component="div"
                sx={{
                  fontSize: "26px",
                  fontFamily: "var(--font_family_Bold)",
                  marginBottom: "20px",
                }}
              >
                {heading}
              </Typography>
              <Divider styles={{ m: "0px 0 15px" }} />
              <Typography
                component="div"
                sx={{
                  fontSize: "16px",
                  fontFamily: "var(--font_family_Medium)",
                  color: "var(--grey_shade)",
                  textAlign: "center",
                  width: "70%",
                  marginBottom: "20px",
                }}
              >
                {BrandNameUpdate(sub_heading)}
              </Typography>

              <Box
                className={compatibility_check_input}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    checkHandler();
                  }
                }}
                sx={{
                  width: { xs: "300px", sm: "430px" },
                }}
              >
                {(props.simPreference || fromAddLine) &&
                !increaseLine &&
                EnvConfig.OPERATOR?.toUpperCase() !==
                  CarrierTypes.ATT.toUpperCase() ? (
                  imeiNumbers.map((line: any, index: number) => (
                    <Box key={index}>
                      <Box className={check_coverage_input_box}>
                        <TextFieldCust
                          value={line.imeiNum}
                          type={window.screen.width <= 600 ? "number" : "text"}
                          label={imei_label}
                          name="imeiNum"
                          onChange={(e: any) => {
                            if (
                              (PhoneNoPattern.test(e.target.value) &&
                                e.target.value.length <= 15) ||
                              e.target.value === ""
                            ) {
                              imeiHandler(e, index);
                            }
                          }}
                          error={
                            line.imeiNumErr !== "" ||
                            (line.imeiNum !== "" &&
                              !digitsOnlyPattern.test(line.imeiNum)) ||
                            (line.imeiNum !== "" &&
                              line.imeiNum.length !== 15) ||
                            (checkClick &&
                              line.imeiNum === "" &&
                              currentFieldIndex === index) ||
                            (isImeiUsed && currentFieldIndex === index)
                          }
                          helperText={
                            line.imeiNumErr
                              ? line.imeiNumErr
                              : line.imeiNum !== "" &&
                                !digitsOnlyPattern.test(line.imeiNum)
                              ? imei_err_1
                              : line.imeiNum !== "" &&
                                line.imeiNum.length !== 15
                              ? imei_err_2
                              : checkClick &&
                                line.imeiNum === "" &&
                                currentFieldIndex === index
                              ? imei_err_3
                              : isImeiUsed && currentFieldIndex === index
                              ? imei_err_4
                              : imeiCheckErrorMsg?.length
                              ? imeiCheckErrorMsg
                              : ""
                          }
                          maxlength={15}
                          disabled={
                            inputDisabledIndex.length > index ? false : true
                          }
                        />
                      </Box>

                      <Stack
                        mb={"12px"}
                        pt="5px"
                        display={"flex"}
                        justifyContent="space-between"
                      >
                        <Typography component="div" className={limit_div}>
                          {line.imeiNum && line.imeiNum.length
                            ? line.imeiNum.length
                            : "0"}
                          {limit_imei}
                        </Typography>
                      </Stack>
                    </Box>
                  ))
                ) : (
                  <>
                    <Box className={check_imei_input_box}>
                      <TextFieldCust
                        value={imeiNumber}
                        type={window.screen.width <= 600 ? "number" : "text"}
                        label={imei_label}
                        onChange={(e: any) => {
                          if (
                            (PhoneNoPattern.test(e.target.value) &&
                              e.target.value.length <= 15) ||
                            e.target.value === ""
                          ) {
                            onChangeHandler(e);
                          }
                        }}
                        error={
                          imeiErr !== "" ||
                          (imeiNumber !== "" &&
                            !digitsOnlyPattern.test(imeiNumber)) ||
                          (imeiNumber !== "" && imeiNumber.length !== 15) ||
                          (checkClick && imeiNumber === "") ||
                          isImeiUsed
                        }
                        helperText={
                          imeiErr
                            ? imeiErr
                            : imeiNumber !== "" &&
                              !digitsOnlyPattern.test(imeiNumber)
                            ? imei_err_1
                            : imeiNumber !== "" && imeiNumber.length !== 15
                            ? imei_err_2
                            : checkClick && imeiNumber === ""
                            ? imei_err_3
                            : isImeiUsed
                            ? imei_err_4
                            : ""
                        }
                        maxlength={15}
                        disabled={inputDisabled}
                      />
                    </Box>
                    <Box className={limit_div}>
                      {imeiNumber && imeiNumber.length
                        ? imeiNumber.length
                        : "0"}
                      {limit_imei}
                    </Box>
                  </>
                )}
              </Box>
              <Stack mt={"25px"}>
                <ButtonCust
                  sx={{ width: { xs: "200px", sm: "300px", md: "430px" } }}
                  onClick={() => {
                    checkHandler();
                  }}
                  variantType={EnvConfig.SECONDARY_BUTTON_TYPE}
                  loading={checkClick}
                >
                  {button_1}
                </ButtonCust>
              </Stack>
              <Typography
                component="div"
                sx={{
                  fontSize: "16px",
                  color: "var(--primary_color)",
                  fontFamily: "var(--font_family_Bold)",
                  mt: "25px",
                  cursor: "pointer",
                }}
                onClick={() => moveToCheckoutPage()}
              >
                {skip_text}
              </Typography>

              {/* {retry && !isCompatible && increaseLine ? (
              <Typography
                component="div"
                className={skip_to_checkout}
                onClick={() => window.open(globalVal.shopPhone, "_blank")}
              >
                {shop_phones}
              </Typography>
            ) : !retry && !fromAddLine && !increaseLine && !fromCheckoutPage ? (
              <Typography
                component="div"
                className={skip_to_checkout}
                onClick={() => {
                  moveToCheckoutPage();
                  setDefaultSimPreference();
                }}
              >
                {skip_checkout}
              </Typography>
            ) : !retry && fromAddLine ? (
              <Typography
                component="div"
                className={skip_to_checkout}
                onClick={() => moveToCheckoutPage()}
              >
                {skip_text}
              </Typography>
            ) : eSimCompatiblity &&
              !fromAddLine &&
              !increaseLine &&
              !fromCheckoutPage ? (
              <Typography
                component="div"
                className={skip_to_checkout}
                onClick={() => moveToCheckoutPage("SIM")}
              >
                {preference_text}
              </Typography>
            ) : null} */}
            </Stack>
          ) : retry && errorInCompat ? (
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <Box
                component="img"
                className={close_icon_imei}
                src={close_img?.data?.attributes?.url}
              ></Box>
              <Typography
                component="div"
                sx={{
                  fontSize: "18px",
                  fontFamily: "var(--font_family_Medium)",
                  color: "var(--text_color)",
                  fontWeight: "var(--font_weight_5)",
                  textAlign: "center",
                  margin: "20px",
                  width: "65%",
                }}
              >
                {BrandNameUpdate(failure_msg)}
              </Typography>

              <ButtonCust
                sx={{
                  width: { xs: "200px", sm: "300px", md: "430px" },
                  marginTop: "20px",
                  alignSelf: { xs: "center", md: "flex-center" },
                }}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  setRetry(false);
                  setErrorInCompat("");
                  setImeiNumber("");
                }}
              >
                {check_another_device}
              </ButtonCust>
            </Box>
          ) : null}

          {isCompatible && modelCompat ? (
            <Box
              sx={{
                marginLeft: { sm: "auto", xs: "15px" },
                textAlign: "center",
              }}
            >
              <Box
                component="img"
                className={tick_icon}
                src={tick_img?.data?.attributes?.url}
              ></Box>
              <Typography
                component="div"
                sx={{
                  fontSize: "18px",
                  fontFamily: "var(--font_family_Medium)",
                  color: "var(--text_color)",
                  fontWeight: "var(--font_weight_8)",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {BrandNameUpdate(Ph_success_msg)}
              </Typography>
              <RadioGroup
                value={simType}
                onChange={(e: any) => handleSimTypeChange(e)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px",
                }}
                row
              >
                {fromAddLine && (
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "18px",
                      margin: "10px 50px",
                      fontFamily: "var(--font_family_Medium)",
                      color: "var(--text_color)",
                      fontWeight: "var(--font_weight_8)",
                      textAlign: "left",
                    }}
                  >
                    {secondary_txt}
                  </Typography>
                )}
                <RadioCust value={SimTypes.sim} label={content?.sim_lbl} />
                <RadioCust
                  value={SimTypes.eSim}
                  label={content?.esim_lbl}
                  radio_btn_style={{ marginRight: "8px" }}
                  disabled={!isEsimAvailabe}
                />

                <CustToolTip
                  title={PoperOver(tooltip_text)}
                  open={open ? true : false}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                >
                  <Box
                    component="img"
                    className={info_icon}
                    src={info_img?.data?.attributes?.url}
                    onClick={() => {
                      openPopper("data");
                    }}
                  ></Box>
                </CustToolTip>
              </RadioGroup>

              {fromAddLine ? (
                <ButtonCust
                  disabled={isCompatible ? false : true}
                  onClick={() => {
                    moveToCheckoutPage();
                    if (isAccountScreen)
                      setShowReviewModal1();
                  }}
                  loading={loader}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                >
                  {proceed_btn}
                </ButtonCust>
              ) : (
                <ButtonCust
                  sx={{
                    width: { xs: "100%", sm: "430px" },
                    marginTop: "20px",
                    alignSelf: { xs: "center", md: "flex-start" },
                  }}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    moveToCheckoutPage();
                  }}
                >
                  {proceed_btn}
                </ButtonCust>
              )}

              <Typography
                component="div"
                className={check_device}
                onClick={() => {
                  setRetry(false);
                  setErrorInCompat("");
                  setIsCompatible(false);
                  setImeiNumber("");
                  setInputDisabled(false);
                  resetPrimarySimPref();
                }}
              >
                {check_another_device}
              </Typography>
              {/* <Box
                sx={{
                  textAlign: "left",
                  backgroundColor: "var(--lite_background)",
                  border: "1px solid var(--primary_brand_color)",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                    padding: "20px",
                  }}
                >
                  {renderNetworkSettings()}
                </Box>
              </Box> */}
              <Box
                sx={{
                  // display: "flex",
                  // justifyContent: { xs: "center", sm: "start" },
                  marginTop: { xs: "10px", sm: "0px" },
                }}
              >
                {EnvConfig?.IS_APN_SETTINGS_ENABLED && osdata?.os && (
                  <ApnNote
                    imeiData={{
                      os: osdata?.os ?? "",
                      osVer: osdata?.osVer ?? 0,
                    }}
                    isShowAsPill={false}
                    currentLine={osdata}
                    closeCompatModal={closeImeiModal}
                    closeCompImeiModal={closeCompImeiModal}
                  />
                )}
              </Box>
            </Box>
          ) : null}
          {!modelCompat ? (
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <Box
                component="img"
                className={close_icon_imei}
                src={close_img?.data?.attributes?.url}
              ></Box>
              <Typography
                component="div"
                sx={{
                  fontSize: "16px",
                  fontFamily: "var(--font_family_Medium)",
                  color: "var(--text_color)",
                  fontWeight: "var(--font_weight_5)",
                  textAlign: "center",
                  margin: "20px",
                  width: "90%",
                }}
              >
                {ModelNameUpdate(
                  BrandNameUpdate(model_compat_fail_msg),
                  getLastCharacter(compatibility?.data?.data?.model, 5)
                )}
              </Typography>

              <ButtonCust
                sx={{
                  width: "430px",
                  marginTop: "20px",
                  alignSelf: { xs: "center", md: "flex-center" },
                }}
                variantType={EnvConfig.SECONDARY_BUTTON_TYPE}
                onClick={() => {
                  setRetry(false);
                  setErrorInCompat("");
                  setIsCompatible(false);
                  setImeiNumber("");
                  setInputDisabled(false);
                  setModalCompat(true);
                  resetPrimarySimPref();
                }}
              >
                {check_another_device}
              </ButtonCust>
            </Box>
          ) : null}
        </SimpleModal>
      </>
    )
  );
};

export default T2_EsimIMEI;
