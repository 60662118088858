import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";

import Config from "../../../../config/app.config.json";
import { useEffect, useState } from "react";
import { getIRPurchaseHistoryContent } from "../../../../features/strapi/serviceSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getAllPacks } from "../../../../features/services/servicesSlice";

export type IRPurchaseHistoryProps = {
  content: any;
  allPacksData: any;
};
const customerDetailsJSON: any = localStorage.getItem("customerDetail");
const customerDetails = JSON.parse(customerDetailsJSON);
const IRPurchaseHistoryTemp = isTemplateExisits(
  Config.PostActivationServices.IRPurchaseHistory
)
  ? require(`./${Config.PostActivationServices.IRPurchaseHistory.template}_IRPurchaseHistory`)
      .default
  : null;
const IRPurchaseHistory = (props: any) => {
  const { IRPurchaseHistoryContent } = useAppSelector(
    (state) => state.strapi.services
  );
  const { allPacksData } = useAppSelector((state) => state.services) || {};
  const [cacheIRPurchaseHistoryContent, setCacheIRPurchaseHistoryContent] =
    useState();
  const dispatch = useAppDispatch();
  let customerDetails: any = localStorage.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);

  useEffect(() => {
    getCache(
      "IRPurchaseHistory",
      getIRPurchaseHistoryContent,
      setCacheIRPurchaseHistoryContent,
      dispatch
    );
  }, []);
  useEffect(() => {
    callAllPacksApi();
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("IRPurchaseHistory", IRPurchaseHistoryContent);
  }, [IRPurchaseHistoryContent]);

  const callAllPacksApi = () => {
    dispatch(getAllPacks({ groupId: customerDetails?.groupId }));
  }

  return cacheIRPurchaseHistoryContent || IRPurchaseHistoryContent ? (
    <IRPurchaseHistoryTemp
      content={
        cacheIRPurchaseHistoryContent
          ? cacheIRPurchaseHistoryContent
          : IRPurchaseHistoryContent
      }
      allPacksData={allPacksData?.data?.data}
      isDataBoostJourney={props.isDataBoostJourney}
      setIsDataBoostJourney={props.setIsDataBoostJourney}
      onClickSwitchTabs={props.onClickSwitchTabs}
      rescheduleSuccess={props.rescheduleSuccess}
      setRescheduleSuccess={props.setRescheduleSuccess}
      callAllPacksApi={callAllPacksApi}
    />
  ) : null;
};

export default IRPurchaseHistory;
