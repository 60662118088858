import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { howItWorksContent } from "../../../features/strapi/giveBackslice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
const HowItWorksCmp = isTemplateExisits(Config.GiveBack.HowItWorks)?
require(`./${Config.GiveBack.HowItWorks.template}_HowItWorks`).default:null;
export type hiwProps = {
    content?: any;
};
export const HowItWorks = () => { 
    const [ cacheDataGbHowItWorks, setCacheDataGbHowItWorks] = useState<any>(null);
    const { howItWorks } = useAppSelector(
        (state: any) => state.strapi.giveBack
    );
    const dispatch = useAppDispatch();
    useEffect(() => {
        getCache("HowItWorksGiveBack", howItWorksContent, setCacheDataGbHowItWorks, dispatch)
    }, []);
    useNonInitialEffect(() => {
        addMultipleCacheData('HowItWorksGiveBack', howItWorks)
    }, [howItWorks])
    return (cacheDataGbHowItWorks || howItWorks) && <HowItWorksCmp content={cacheDataGbHowItWorks ? cacheDataGbHowItWorks : howItWorks}/>; 
}