import { Grid, Box, Typography } from "@mui/material";
import { TeamFeedBackProps } from "./TeamFeedback";
import styles from "./S1_TeamFeedback.module.scss";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const TeamFeedbackComponent = (props: TeamFeedBackProps) => {
  const { feedback_content } = props.teamFeedBackContent || {};
  return (
    props.teamFeedBackContent && (
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          p:{
            xs: "calc(var(--mobile_section_padding)/2) 0 calc(var(--mobile_section_padding))",
            sm: "calc(var(--ipad_section_padding)/2) 0 calc(var(--ipad_section_padding))",
            md: "calc(var(--desktop_section_padding)/2 - 38px) 0 calc(var(--desktop_section_padding))"}
        }}
      >
        {feedback_content &&
          feedback_content.map((content: any) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={(feedback_content?.length < 4) ? 12/feedback_content?.length: 4}
                className={styles.team_feedback}
                sx={{ padding: { xs: "35px", md: "38px" } }}
                key={content.name}
              >
                <Box
                  component="img"
                  alt={content?.profile_icon?.data?.attributes?.alternativeText}
                  src={content?.profile_icon?.data?.attributes?.url || ""}
                  width="70px"
                  height="70px"
                  pb="10px"
                  className={styles.profile_img}
                ></Box>
                <Typography
                  variant="body2"
                  lineHeight="1.83"
                  pb="10px"
                  className={styles.description}
                >
                  {BrandNameUpdate(content.description) || ""}
                </Typography>
                <Typography
                  className={styles.name_designation}
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: content.name,
                  }}
                ></Typography>
              </Grid>
            );
          })}
      </Grid>
    )
  );
};

export default TeamFeedbackComponent;
