import "./SearchBox.scss";
import { TextField, Autocomplete, Box, CircularProgress } from "@mui/material";
import { Search } from "@mui/icons-material";

type Props = {
  value?: string | null;
  onChange?: any;
  label?: string;
  placeholder?: string;
  onRequest?: any;
  style?: any;
  onCancel?: any;
  dropdownList?: any;
  optionLabel?: any;
  logo?: any;
  renderOption?: any;
  sx?: any;
  no_option?: any;
  inputChangeHandler?: any;
  onCloseHandler?: any;
};

export const SearchBoxWithDropdown = (props: Props) => {
  return (
    <Autocomplete
      className="SearchBoxDropdown"
      options={props.dropdownList}
      getOptionLabel={props.optionLabel}
      autoHighlight
      onChange={(e: any, val: any) => props.onChange(e, val)}
      value={props.value ? props.value : null}
      renderOption={props.renderOption}
      onInputChange={(e:any, val:any)=>props.inputChangeHandler(e,val)}
      onClose={(e: any, val: any)=>props.onCloseHandler(e,val)}
      noOptionsText={
        props.dropdownList?.length !== 0 ? (
          props.no_option
        ) : (
          <Box textAlign={"center"}>
            <CircularProgress sx={{ color: "var(--primary_color)" }} />
          </Box>
        )
      }
      popupIcon={
        props.logo ? (
          <img
            src={props.logo.data.attributes.url}
            alt={props.logo.data.attributes.alternativeText}
          />
        ) : (
          <Search color="inherit" height="16px" width="16px" />
        )
      }
      sx={{
        ...props.sx,
        width: "100%",
        "& .MuiAutocomplete-popupIndicator": { transform: "none" },
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            className="SearchBoxTextField"
            placeholder={props.placeholder}
            variant="filled"
            sx={props.sx}
            {...params}
          />
        );
      }}
    />
  );
};
