type iprops = {
  store: string;
  url: string;
  height: number;
  width: number;
  linkStyles?: any;
  linkprops: object;
  className?: string;
  style?: object;
  imagelink?: string;
};

export const MobileStoreButton = (props: iprops) => {
  const { url, height, width, linkStyles, linkprops, imagelink } = props;
  const defaultLinkStyles = {
    background: `url(${imagelink}) no-repeat`,
    backgroundSize: "contain",
    display: "inline-block",
    overflow: "hidden",
    textDecoration: "none",
    height: "100%",
    width: "100%",
    ...linkStyles,
  };

  return (
    <div style={{ height, width, display: "inline-block" }} {...props}>
      <a
        style={defaultLinkStyles}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        {...linkprops}
      >
        &nbsp;
      </a>
    </div>
  );
};
