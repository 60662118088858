import accountLockSlice from "./account-lock/accountLockSlice";
import accountSlice from "./account/accountSlice";
import activationSlice from "./activation/activationSlice";
import billCalculatorSlice from "./billCalculator/billCalculatorSlice";
import billingSlice from "./billing/billingSlice";
import checkoutReducer from "./checkout/checkoutSlice";
import customerSlice from "./customer/customerSlice";
import homepageReducer from "./homepage/homepageSlice";
import internationalServices from "./internationalService/internationalServiceSlice";
import planpageReducer from "./planpage/planpageSlice";
import profileDetailsSlice from "./profile/profileSlice";
import servicesSlice from "./services/servicesSlice";
import settingsSlice from "./settings/settingsSlice";
import shopWareSlice from "./shopWare/shopWareSlice";
import rootStrapiSlice from "./strapi/rootStrapiSlice";
import trackorderSlice from "./trackorder/trackorderSlice";
import walletSlice from "./wallet/walletSlice";

const rootSlice: any = {
  homepage: homepageReducer,
  strapi: rootStrapiSlice,
  planpage: planpageReducer,
  checkout: checkoutReducer,
  trackorder: trackorderSlice,
  internationalService: internationalServices,
  billCalculator: billCalculatorSlice,
  activation: activationSlice,
  customer: customerSlice,
  profileDetails: profileDetailsSlice,
  account: accountSlice,
  billing: billingSlice,
  settings: settingsSlice,
  services: servicesSlice,
  wallet: walletSlice,
  shopWare: shopWareSlice,
  accountLock: accountLockSlice,
};

export default rootSlice;
