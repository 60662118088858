import { Box } from "@mui/material";
import classes from "./Divider.module.scss";
interface DividerProps {
  styles?: any;
  className?: string;
}
export const Divider = (props: DividerProps) => {
  const { styles, className } = props;
  return (
    <Box className={classes.divider_wrapper + " " + className}>
      <Box sx={styles} className={classes.divider} />
    </Box>
  );
};
