import axios from "./axios";
import setAuthToken from "./auth";
import { v4 as uuidv4 } from "uuid";
import axiosStrapi from "./axiosStrapi";
import axiosShopWare from "./axiosShopWare";
import axiosShopWareCustom from "./axiosShopwareCustom";
import EnvConfig from "../config/env.config.json";
import getAnnonymousUser from "./getAnnonymousUser";
import Cookies from "js-cookie";
import { getBrowserAndOsDetails } from "../utils/commonFunctions/getBrowserAndOsDetails";
import sha256 from "crypto-js/sha256";
import { LOGIN_PROVIDER } from "../enums";
type Params = {
  method: string;
  url: string;
  body?: any;
  token?: string;
  params?: any;
  headers?: any;
  auth?: boolean;
  other?: boolean;
  other1?: string;
  contentType?: string;
  isStrapiApi?: boolean;
  tenantId?: any;
  isShopWare?: boolean;
  domain?: string;
  isBearerTokenRequired?: boolean;
};

function XORString(string1: string, string2: string) {
  let result = "";
  for (let i = 0; i < string1.length; i++) {
    result += String.fromCharCode(
      string1.charCodeAt(i) ^ string2.charCodeAt(i % string2.length)
    );
  }
  return result;
}

function getPreSharedKey(): string {
  const key: any = process.env.REACT_APP_EVAL_TOKEN;
  return key.trim();
}
class ApiJunction {
  async makeRequest(params: Params) {
    let customerDetail: any = localStorage.getItem("customerDetail");
    customerDetail = JSON.parse(customerDetail);
    let details = getBrowserAndOsDetails();
    const resToken = await setAuthToken();
    if (params.isStrapiApi) {
      return axiosStrapi.get(params.url, { params: params.params });
    }
    let token = resToken;
    if (!token) {
      token = await getAnnonymousUser();
    }
    if (params.isShopWare) {
      let customAxios: any =
        params?.domain === "shopware" ? axiosShopWare : axiosShopWareCustom;
      let swContextToken = Cookies.get("swContextToken");
      const headers = {
        "sw-access-key": process.env.REACT_APP_SW_ACCESS_KEY,
        ...(swContextToken && { "sw-context-token": swContextToken }),
      };
      if (params.isBearerTokenRequired)
        customAxios.defaults.headers.common["authorization"] = `${token}`;
      if (params.contentType) {
        axios.defaults.headers.post["Content-Type"] = params.contentType;
      } else {
        axios.defaults.headers.common["Content-Type"] = `application/json`;
      }
      if (params.method === "post") {
        return customAxios
          .post(params.url, params.body, {
            headers: headers,
          })
          .then((res: any): any => {
            return res;
          })
          .then((err: any) => {
            return err;
          });
      } else if (params.method === "get") {
        return customAxios.get(params.url, { headers: headers });
      } else if (params.method === "patch") {
        return customAxios.patch(params.url, params.body, {
          headers: headers,
        });
      } else if (params.method === "delete") {
        return customAxios.delete(params.url, {
          headers: headers,
        });
      }
    }
    axios.defaults.headers.common["txnid"] = uuidv4();
    axios.defaults.headers.common["x-reach-mvne"] = EnvConfig.OPERATOR;
    let ssoToken = localStorage.getItem("ssoAccessToken");
    // if(EnvConfig?.IS_SSO_LOGIN) {
    //   let ssoToken = localStorage.getItem('ssoAccessToken');
    //   axios.defaults.headers.common['authorization'] = `Bearer ${true}|${ssoToken}`;
    // } else {
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
    // axios.defaults.headers.common[
    //   "authorization"
    // ] = `fefc0fcf-5fa4-4966-9db1-fd7896c4b3e6`;
    // }
    // axios.defaults.headers.common['authorization'] = EnvConfig.IS_DEV_ENV ? 'fefc0fcf-5fa4-4966-9db1-fd7896c4b3e6' : '2e656744-dc7e-4d8a-bdec-6df49de51bb2';

    if (params.contentType) {
      axios.defaults.headers.post["Content-Type"] = params.contentType;
    } else {
      axios.defaults.headers.common["Content-Type"] = `application/json`;
    }
    if (params.other) {
      axios.defaults.headers.common["x-is-primary"] = params.other
        ? "Yes"
        : "No";
    }
    if (params.other1) {
      axios.defaults.headers.common["x-reach-mvne"] = params.other1
        ? params.other1
        : "";
    }
    if (EnvConfig.TENANT_ID) {
      axios.defaults.headers.common["x-partner-tenant-id"] =
        EnvConfig.TENANT_ID;
    }
    if (EnvConfig?.IS_TOKEN_ID_REQD_IN_HEADERS) {
      const date = Date.now();
      let firstName = customerDetail?.firstName || "";
      let lastName = customerDetail?.lastName || "";
      let emailId = customerDetail?.emailId || "";
      let body = params?.method === "get" ? "" : JSON.stringify(params?.body);
      const xreach = `${firstName ? firstName.toLowerCase() : ""}${
        lastName ? lastName?.toLowerCase() : ""
      }|${emailId ? emailId : ""}|customer|${details.browser}/${
        details.browserVersion
      }|${details.operatingSystem}/${
        details.osVersion ? details.osVersion : ""
      }|${
        EnvConfig?.IS_SSO_LOGIN ? LOGIN_PROVIDER.SSO : LOGIN_PROVIDER.FIREBASE
      }`.trim();
      const headersToHash = JSON.stringify({
        xReachSrc: `web|${xreach.replace(/ /g, "")}`,
        authorization: `Bearer ${
          EnvConfig?.IS_SSO_LOGIN ? ssoToken || token : token
        }`,
        // authorization: `${
        //   EnvConfig?.IS_SSO_LOGIN
        //     ? ssoToken || "fefc0fcf-5fa4-4966-9db1-fd7896c4b3e6"
        //     : "fefc0fcf-5fa4-4966-9db1-fd7896c4b3e6"
        // }`,
        xReachDate: date?.toString(),
      })?.trim();

      const preDefinedKey = getPreSharedKey();
      const queryParams = params?.url.split("?")[1];
      const param = queryParams ? queryParams : "";
      let paramString = "";
      // if (param) {
      //   const paramsToArray = Object.entries(param);
      //   paramsToArray.forEach((el:any, idx:number) => {
      //     if (idx < 1) {
      //       paramString =
      //         paramString +
      //         (el[0]) +
      //         "=" +
      //         (el[1]);
      //     } else {
      //       paramString =
      //         paramString +
      //         "&" +
      //         (el[0]) +
      //         "=" +
      //         (el[1]);
      //     }
      //   });
      // }
      const path = params.url.split("?")[0];
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}${path}`;
      let dataToHash;
      if (body) {
        dataToHash = `${url.replace(/ /g, "")}${headersToHash}${body}${param}`;
      } else {
        dataToHash = `${url.replace(/ /g, "")}${headersToHash}${param}`;
      }
      const dataToHashPostXOR = XORString(dataToHash, preDefinedKey);
      // const hash = createHash("sha256").update(dataToHashPostXOR).digest("hex");
      const hash: any = sha256(dataToHashPostXOR);

      axios.defaults.headers.common["x-reach-tokenid"] = hash;
      axios.defaults.headers.common["x-reach-date"] = date;
      axios.defaults.headers.common[
        params.auth ? "x-account-src" : "x-reach-src"
      ] = params.auth
        ? `reach`
        : `web|${firstName ? firstName.toLowerCase() : ""}${
            lastName ? lastName.toLowerCase() : ""
          }|${emailId ? emailId : ""}|customer|${details.browser}/${
            details.browserVersion
          }|${details.operatingSystem}/${
            details.osVersion ? details.osVersion : ""
          }|${
            EnvConfig?.IS_SSO_LOGIN
              ? LOGIN_PROVIDER.SSO
              : LOGIN_PROVIDER.FIREBASE
          }`;
    }

    if (params.method === "get") {
      return axios.get(params.url, { params: params.params });
    } else if (params.method === "post") {
      return axios
        .post(params.url, params.body)
        .then((res: any): any => {
          return res;
        })
        .then((err) => {
          return err;
        });
    } else if (params.method === "put") {
      return axios.put(params.url, params.body);
    } else if (params.method === "delete") {
      return axios.delete(params.url, params.body);
    } else if (params.method === "patch") {
      return axios.patch(params.url, params.body, params.headers);
    } else {
      return { success: false, msg: "No method provided, get, post?" };
    }
  }

  login(params: Params) {
    return axios.post(params.url, params.body);
  }
}

export default new ApiJunction();
