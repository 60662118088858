import styles from "./S1_BestFit.module.scss";
import { Grid, Typography } from "@mui/material";
import { BFMyAccountProps } from "./BestFit";

const MaBestFitComponent = (props: BFMyAccountProps) => {
  const { ttl, desc } = props?.content || {};

  return (
    <Grid
      container
      textAlign="center"
      sx={{
        p: {
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        },
      }}
    >
      <Grid item xs={12} className={styles.mabf_container}>
        <Typography
          variant="h3"
          sx={{ mb: "15px" }}
          className={styles.mabf_ttl}
        >
          {ttl}
        </Typography>
        <Typography
          sx={{ px: { md: "209px" } }}
          variant="body1"
          className={styles.mabf_desc + " description_text"}
          dangerouslySetInnerHTML={{
            __html: desc,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MaBestFitComponent;
