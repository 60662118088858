import { Grid, Typography, Dialog, DialogContent, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ButtonCust, Divider } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";

interface CardSavingAlertDialogProps {
  display?: any;
  onClose?: any;
  content?: any;
  onConfirmProceed?: any;
  isAutoPayAndSaveCardEnabled?: boolean;
  btnLoading?:any;
  setBtn_loading?:any;
}
const CardSavingAlertDialog = (props: CardSavingAlertDialogProps) => {
  const {
    display,
    onClose,
    content,
    onConfirmProceed,
    isAutoPayAndSaveCardEnabled,
    btnLoading
  } = props;
  return (
    <Dialog
      scroll="body"
      open={display}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Close
          onClick={onClose}
          style={{
            color: "var(--balck)",
            opacity: "0.5",
            fontSize: "20px",
            cursor: "pointer",
          }}
        />
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "cetnter",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_4)",
              fontSize: "22px",
              pb: "16px",
            }}
          >
            {isAutoPayAndSaveCardEnabled?content?.c_title:content?.d_title}
          </Typography>
          <Divider
            styles={{
              mb: "20px",
              backgroundColor: "var( --divider_color) !important",
            }}
          ></Divider>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_1)",
              fontSize: "16px",
              pb: "10px",
              textAlign: "center",
            }}
          >
            {isAutoPayAndSaveCardEnabled?content?.c_dsc:content?.a_d_desc}
          </Typography>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <ButtonCust
              sx={{
                height: "45px !important",
                width: { xs: "80px", sm: "45%" },
                m: "10px",
              }}
              onClick={onClose}
              loading={false}
              variantType={EnvConfig?.SECONDARY_BUTTON_TYPE}
            >
              {content?.canc_btn}
            </ButtonCust>
            <ButtonCust
              variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
              sx={{
                height: "45px !important",
                width: { xs: "80px", sm: "45%" },
                m: "10px",
              }}
              onClick={onConfirmProceed}
              loading={btnLoading}
            >
              {content?.conf_btn}
            </ButtonCust>
          </Stack>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CardSavingAlertDialog;
