import { TextFieldCust } from "../../../";
import CloseIcon from "@mui/icons-material/Close";
import { Paper, Grid, Box, Typography } from "@mui/material";
import { AddLineProps } from "../ManageLines";
import { useEffect, useState } from "react";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { addLineCompContent } from "../../../../features/strapi/accountSlice";
import EnvConfig from "../../../../config/env.config.json";

export const AddLines = (props: AddLineProps) => {
  const dispatch = useAppDispatch();
  const { addLineComp } = useAppSelector((state: any) => state.strapi.account);
  const [addLinesCacheContent, setAddLinesCacheContent] = useState<any>(null);
  const { formik, index, removeFormFields, handleFormikChange, addUserError } =
    props;

  useEffect(() => {
    getCache(
      "AddLineComponent",
      addLineCompContent,
      setAddLinesCacheContent,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("AddLineComponent", addLineComp);
  }, [addLineComp]);
  const content = addLinesCacheContent ? addLinesCacheContent : addLineComp;
  return (
    content && (
      <Paper
        sx={{
          width: { sm: "92%", xs: "92%", md: "84%" },
          mx: "auto",
          flexGrow: 1,
          padding: 2,
          backgroundColor: "var(--lightgrey)",
          boxShadow: "0 2px 4px 0 hsl(0deg 0% 84% / 50%)",
          mt: 1.5,
        }}
      >
        <Grid container spacing={2} justifyContent="end">
          <Grid item xs={1.5} sm={1}>
            <Box
              component="img"
              sx={{ width: "35px", height: "35px" }}
              src={content?.happy_icon?.data?.attributes?.url}
              alt={content?.happy_icon?.data?.attributes?.alternativeText}
            />
          </Grid>
          <Grid item xs={9} sm={5}>
            <TextFieldCust
              type="text"
              name={`${index}.firstName`}
              value={formik.values[index].firstName}
              onChange={(e: any) => handleFormikChange(e)}
              label={content?.first_name}
              error={
                formik.touched?.[index]?.firstName &&
                formik.errors[index]?.firstName
              }
              helperText={
                formik.touched?.[index]?.firstName &&
                formik.errors[index]?.firstName
              }
              disabled={formik.values[index].isEmailAvalable}
            />
          </Grid>
          <Grid
            item
            xs={1.5}
            sm={1}
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <CloseIcon
              sx={{ color: "var(--grey_shade)" }}
              onClick={() => removeFormFields(index)}
            />
          </Grid>
          <Grid item xs={9} sm={5} m={{ xs: "auto", sm: 0 }}>
            <TextFieldCust
              type="text"
              name={`${index}.lastName`}
              value={formik.values[index].lastName}
              onChange={(e: any) => handleFormikChange(e)}
              label={content?.last_name}
              error={
                formik.touched?.[index]?.lastName &&
                formik.errors[index]?.lastName
              }
              helperText={
                formik.touched?.[index]?.lastName &&
                formik.errors[index]?.lastName
              }
              disabled={formik.values[index].isEmailAvalable}
            />
          </Grid>
          <Grid
            item
            xs={1.5}
            sm={1}
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <CloseIcon
              style={{ color: "var(--grey_shade)", cursor: "pointer" }}
              onClick={() => removeFormFields(index)}
            />
          </Grid>
          {EnvConfig?.HIDE_SECONDARY_EMAIL_FIELD ? null:<Grid item xs={9} sm={10}>
            <TextFieldCust
              type="text"
              name={`${index}.email`}
              value={formik.values[index].email}
              onChange={(e: any) => handleFormikChange(e)}
              label={content?.email}
              error={
                formik.touched?.[index]?.email && formik.errors[index]?.email
              }
              helperText={
                formik.touched?.[index]?.email && formik.errors[index]?.email
              }
              disabled={formik.values[index].isEmailAvalable}
            />
          </Grid>}
          <Grid
            item
            xs={1.5}
            sm={1}
            sx={{
              justifyContent: "center",
              // alignSelf: "center",
              // margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary_color)",
                textAlign: "right",
                fontSize: "14px",
                fontFamily: "var(--font_family_Bold)",
                opacity: formik.values[index].isEmailAvalable ? 0.4 : "unset",
                cursor: formik.values[index].isEmailAvalable
                  ? "not-allowed"
                  : "pointer",
              }}
              onClick={() => {
                if (!EnvConfig?.HIDE_SECONDARY_EMAIL_FIELD || !formik.values[index].isEmailAvalable) {
                  formik.handleSubmit();
                }
                if (formik.values[index].isEmailAvalable) {
                  return null;
                }
              }}
            >
              {content?.add_text}
            </Typography>
          </Grid>
        </Grid>

        {formik.values.length === index + 1 && addUserError ? (
          <Grid item xs={12} textAlign="center" my={"12px"}>
            <Typography
              component="div"
              sx={{
                color: "var(--danger)",
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "14px",
              }}
            >
              {addUserError}
            </Typography>
          </Grid>
        ) : null}
      </Paper>
    )
  );
};
