import { Grid, Typography } from "@mui/material";
import styles from "../S1_ViewCredits.module.scss";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

type Props = {
  content: any;
  earnedCredits: any;
};
export const EarnedCredits = ({ content, earnedCredits }: Props) => {
  return (
    <Grid item xs={11} sm={5.75} className={styles.card_item}>
      <Typography className={styles.card_heading}>{content?.ec_ttl}</Typography>
      <Typography className={styles.card_sub_heading}>
        {content?.ec_txt}
      </Typography>
      <Typography className={styles.credit_amount}>
        {GetCurrencyFixedToTwo(earnedCredits)}
      </Typography>
    </Grid>
  );
};
