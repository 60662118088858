import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { bfTpContent } from "../../../features/strapi/bestFitSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
export type BestFitTpProps = {
  content?: any;
  isTitleRequired?: any;
};
const BestFitTpComponent = isTemplateExisits(Config.Home.TrustPilot)
  ? require(`./${Config.Home.TrustPilot.template}_BestFitTp`).default
  : null;
type BestFitTrustProps = {
  isTitleRequired?: any;
};
export const BestFitTp = (props: BestFitTrustProps) => {
  const [cacheDataBestTp, setCacheDataBestTp] = useState<any>(null);
  const { bestFitTpContent } = useAppSelector(
    (state: any) => state.strapi.bestfit
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("BestTpHome", bfTpContent, setCacheDataBestTp, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("BestTpHome", bestFitTpContent);
  }, [bestFitTpContent]);

  const { isTitleRequired } = props;
  return (bestFitTpContent || cacheDataBestTp) ? (
    <BestFitTpComponent
      content={cacheDataBestTp ? cacheDataBestTp : bestFitTpContent}
      isTitleRequired={isTitleRequired}
    />
  ) : null;
};
