import { useEffect,  useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { negResultTopStrapi } from "../../../features/strapi/billCalculatorSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const NegResultTopComponent = isTemplateExisits(
  Config.BillCalculator.NegResultTop
)
  ? require(`./${Config.BillCalculator.NegResultTop.template}_NegResultTop`)
      .default
  : null;
type Props = {
  pageHandler: any;
};
export const NegResultTop = (props: Props) => {
  const [cacheNegResultTop, setCacheNegResultTop] = useState<any>(null);
  const { negResultTopData } = useAppSelector(
    (state: any) => state.strapi.billCalculator
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache(
      "NegResultTopBillCalculator",
      negResultTopStrapi,
      setCacheNegResultTop,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData(
      "NegResultTopBillCalculator",
      negResultTopData
    );
  }, [negResultTopData]);

  return (negResultTopData || cacheNegResultTop) ? (
    <NegResultTopComponent
      data={cacheNegResultTop ? cacheNegResultTop : negResultTopData}
      pageHandler={props.pageHandler}
    />
  ) : null;
};
