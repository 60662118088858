import { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import styles from "./S1_ActivationStep3.module.scss";
import {
  LinearProgressBarCust,
  TextFieldCust,
  ButtonCust,
  PopperCust,
} from "../../../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppDispatch } from "../../../../app/hooks";
import ActivationPayNow from "./PayNow";
import { PayFailureModal } from "./PayFailureModal";
import { ActivationManageCard } from "./ManageCard";
import { PhoneNoPattern } from "../../../../utils/regexPatterns";
import { gaCategory } from "../../../../Types/GoogleAnalytics";
import { ActivationFailedModal } from "../ActivationStep4/ActivationFailedModal";
import { HelpSupportModal } from "../ActivationStep2/SupportModal";
import EsimPaymentSuccess from "./EsimPaymentSuccess";
import Config from "../../../../config/env.config.json";
import { CarrierTypes } from "../../../../enums";

const T1_ActivationStep3 = (props: any) => {
  const {
    currentLine,
    content,
    modalContent,
    step4Content,
    GAAndFBEventsHandler,
    formik,
    displayContent,
    loader,
    payNowConfirm,
    payLoader,
    showActModal,
    payNowFailModal,
    payNowFailedModal,
    handleBack,
    openEsimPaySuccess,
    onCloseEsimPaySuccess,
    activateUser,
    actBtnLoader,
  } = props;
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const dispatch = useAppDispatch();
  const [showCardChangeModal, setShowCardChangeModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const { sp3_title, step_txt, sim_check, msgs, simId_length } = content;
  const [iccidTargetAnchorEl, setIccidTargetAnchorEl] = useState(null);
  const [openICCIDInstructionPopper, setOpenICCIDInstructionPopper] =
    useState<boolean>(false);
  useEffect(() => {
    if (showActModal) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [showActModal]);

  const manageCardFg = () => {
    setShowCardChangeModal(true);
  };

  const showCardChangeModalHandle = () => {
    setShowCardChangeModal(false);
  };
  const closeActivationFailModal = () => {
    setShowModal(false);
  };
  const openSupportModal = () => {
    setShowModal(false);
    setOpenSupportDialog(true);
  };
  const onCloseSupportDialog = () => {
    setOpenSupportDialog(false);
    if (!openEsimPaySuccess) {
      setShowModal(true);
    }
  };
  const onClickICCIDInfoIcon = async (e: any) => {
    setOpenICCIDInstructionPopper(true);
    setIccidTargetAnchorEl(e.target);
  };
  const onCloseInfoDlg = () => {
    setOpenICCIDInstructionPopper(false);
    setIccidTargetAnchorEl(null);
  };
  return (
    <>
      <Box className={styles.step3_main}>
        <Box className={styles.heading_container}>
          <ArrowBackIcon
            sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
            className={styles.arrow_icon}
            onClick={handleBack}
          />
        </Box>

        {displayContent === "main" && (
          <>
            <Box className={styles.heading_container}>
              <Typography className={styles.title_main}>{sp3_title}</Typography>
            </Box>
            <Box className={styles.stepper_content}>
              <Typography className={styles.stepper_txt}>{step_txt}</Typography>
              <Box width={{ xs: "90%", sm: "50%", md: "35%" }} mb="25px">
                <LinearProgressBarCust value={75} variant={"determinate"} />
              </Box>
            </Box>
            <Box width={{ xs: "90%", sm: "48%" }} m="0 auto 40px">
              <Box
                className={styles.inner_container}
                p={{ xs: "30px 15px", sm: "30px 50px" }}
              >
                <Box
                  component={"span"}
                  sx={{ display: "inline-flex", alignItems: "center" }}
                >
                  <Typography className={styles.step3_heading}>
                    {sim_check?.desc}
                  </Typography>
                  <Box className={styles.iccid_popper_container}>
                    {sim_check?.sim_h_ico?.data?.attributes?.url ? (
                      <>
                        <Box
                          component="img"
                          alt={
                            sim_check?.sim_h_ico?.data?.attributes
                              ?.alternativeText
                          }
                          aria-owns={
                            iccidTargetAnchorEl
                              ? "mouse-over-popover"
                              : undefined
                          }
                          aria-haspopup="true"
                          src={
                            sim_check?.sim_h_ico?.data?.attributes?.url || ""
                          }
                          onMouseEnter={onClickICCIDInfoIcon}
                          onMouseLeave={onCloseInfoDlg}
                          sx={{ cursor: "pointer", m: "0px 0px 22px 2px" }}
                        ></Box>
                        <PopperCust
                          open={openICCIDInstructionPopper}
                          anchorEl={iccidTargetAnchorEl}
                          id={"sim_instruction_info_ico"}
                          placement={sim_check?.res?.tooltipPosition}
                        >
                          <Paper sx={{ padding: "10px" }}>
                            <Box display={"flex"}>
                              {sim_check?.sim_instr_desc ? (
                                <Typography
                                  sx={{
                                    fontFamily: "var(--font_family_Medium)",
                                    fontSize: "10px",
                                    width: "82px",
                                    pr: "16px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: sim_check?.sim_instr_desc,
                                  }}
                                />
                              ) : null}
                              {sim_check?.sim_instr?.data?.attributes?.url ? (
                                <Box
                                  component="img"
                                  alt={
                                    sim_check?.sim_instr?.data?.attributes
                                      ?.alternativeText
                                  }
                                  src={
                                    sim_check?.sim_instr?.data?.attributes
                                      ?.url || ""
                                  }
                                  width="auto"
                                  height="auto"
                                  className={styles.profile_img}
                                ></Box>
                              ) : null}
                            </Box>
                          </Paper>
                        </PopperCust>
                      </>
                    ) : null}
                  </Box>
                </Box>
                <Box
                  className={styles.step3_sim_number}
                  width={{ md: "50%", sm: "80%", xs: "95%" }}
                >
                  <TextFieldCust
                    value={formik.values.simId}
                    name="simId"
                    onChange={(e: any) => {
                      if (
                        PhoneNoPattern.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.setFieldValue(e.target.name, e.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    type={window.screen.width <= 600 ? "number" : "text"}
                    label={sim_check?.sim_ph}
                    maxlength={
                      Config.OPERATOR.toLowerCase() === CarrierTypes.ATT
                        ? 20
                        : 19
                    }
                    error={
                      formik.touched?.simId && formik.errors?.simId
                        ? true
                        : false
                    }
                    helperText={formik.touched?.simId && formik.errors?.simId}
                    onKeyUp={(e: any) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                  />
                  <Typography className={styles.step3_limit_div}>
                    {formik.values.simId.length}
                    {simId_length}
                  </Typography>
                </Box>
                <Box className={styles.step3_btn}>
                  <ButtonCust
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    disabled={
                      formik.values?.simId?.length === 0
                        ? true
                        : !formik?.isValid
                    }
                    onClick={() => {
                      formik.handleSubmit();
                      GAAndFBEventsHandler(
                        gaCategory.activation,
                        sim_check?.check_btn
                      );
                    }}
                    loading={loader}
                  >
                    {sim_check?.check_btn}
                  </ButtonCust>
                </Box>
              </Box>
            </Box>
          </>
        )}
        {displayContent === "pay" && (
          <ActivationPayNow
            payNowConfirm={payNowConfirm}
            payBtnLoader={payLoader}
            manageCardFg={manageCardFg}
            content={content}
            {...props}
          />
        )}
        <ActivationManageCard
          showCardChangeModal={showCardChangeModal}
          setShowCardChangeModal={showCardChangeModalHandle}
          isModalRequired={true}
        />
        {payNowFailModal && (
          <PayFailureModal
            modalFg={payNowFailModal}
            closeHandler={() => payNowFailedModal(false)}
            content={content}
          />
        )}
        {showModal && (
          <ActivationFailedModal
            showModal={showModal}
            openSupportModal={openSupportModal}
            closeActivationFailModal={closeActivationFailModal}
            content={step4Content}
            currentLine={currentLine}
          />
        )}
        <HelpSupportModal
          open={openSupportDialog}
          onClose={() => onCloseSupportDialog()}
          title={step4Content?.c_sup_title}
          showClose={false}
          showBackBtn={true}
          content={modalContent}
        />
        <EsimPaymentSuccess
          open={openEsimPaySuccess}
          onClose={() => onCloseEsimPaySuccess()}
          activateUser={activateUser}
          openSupportModal={openSupportModal}
          actBtnLoader={actBtnLoader}
          content={content}
        />
      </Box>
    </>
  );
};
export default T1_ActivationStep3;
