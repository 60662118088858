import { Box, Grid, Typography } from "@mui/material";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import styles from "./ForSupport.module.scss";
import { RoutingURLs } from "../../../config/RoutingURLs";

type Props = {
  content: any;
};
const ForSupport = (props: Props) => {
  const { content } = props;
  const { for_support_ttl, support } = content;
  const SupportDivs = (props: any) => {
    const { image, text, path, key, timing } = props;
    return (
      <Grid item xs={12} sm={4} className={styles.support_div} key={key}>
        <a
          href={path === RoutingURLs.chatbot ? undefined : path}
          onClick={() => {
            if (path === RoutingURLs.chatbot) {
              window.open(RoutingURLs.chatbot, "_self");
              setTimeout(() => {
                window.history.replaceState(null, "", location.pathname);
              }, 1000);
            } else {
              return false;
            }
          }}
          style={{ textDecoration: "none", cursor: "pointer" }}
        >
          <Box component="img" src={image} />
          <Typography className={styles.support_text}>{text}</Typography>
          <Typography className={styles.support_text_1}>{timing}</Typography>
        </a>
      </Grid>
    );
  };
  return (
    <Box className={styles.support_main}>
      <Box className={styles.support_line}>
        <Box className={styles.horizontal_line}></Box>
        <Box className={styles.rectangle}>
          <Typography className={styles.text}>{for_support_ttl}</Typography>
        </Box>
      </Box>
      <Grid
        container
        sx={{
          pt: { xs: 6, sm: 20 },
          mt: "60px",
          mb: { xs: "10px", sm: "60px" },
          textAlign: "center",
        }}
      >
        {support?.map((item: any, index: number) => {
          return (
            <SupportDivs
              image={item?.img?.data?.attributes?.url}
              text={BrandNameUpdate(item?.text)}
              path={BrandNameUpdate(item?.path)}
              key={index}
              timing={BrandNameUpdate(item?.timing)}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default ForSupport;
