import Config from "../../../../config/app.config.json";
import { isTemplateExisits } from "../../../../utils/commonFunctions";

export type PayNowPlanProps = {
  currentLine: any;
  plan: any;
  content: any;
};

const SelectedPlanComp = isTemplateExisits(Config.Activation.PayNowPlan)
  ? require(`./${Config.Activation.PayNowPlan.template}_PayNowPlan`).default
  : null;

export default function PayNowPlan(props: any) {
  return <SelectedPlanComp {...props} />;
}
