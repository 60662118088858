import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
    bestFitFeatures: any;
    errorBestFitFeatures: any;
    bestFitSavings: any;
    errorBestFitSavings: any;
    bestFitPSavingsContent: any;
    errorBestFitPSavingsContent: any;
    bestFitTpContent: any;
    errorBestFitTpContent: any;
};

const initialState: InitialState = {
    bestFitFeatures: null,
    errorBestFitFeatures: null,
    bestFitSavings: null,
    errorBestFitSavings: null,
    bestFitPSavingsContent: null,
    errorBestFitPSavingsContent: null,
    bestFitTpContent: null,
    errorBestFitTpContent: null
};

// Generates pending, fulfilled and rejected action types
export const bestFitFeatureContent = createAsyncThunk(
    "bestFit/bestFitFeatureContent",
    () => {
      return apiJunction.makeRequest({
        method: "get",
        url: `/${Config.BestFit.BestFitFeatures.template}${StrapiApiEndPoints.bestFitFeatures}`,
        isStrapiApi: true,
      });
    }
);

// Generates pending, fulfilled and rejected action types
export const bestFitSavingsContent = createAsyncThunk(
    "bestFit/bestFitSavingsContent",
    () => {
      return apiJunction.makeRequest({
        method: "get",
        url: `/${Config.BestFit.BillSavings.template}${StrapiApiEndPoints.bestFitSavings}`,
        isStrapiApi: true,
      });
    }
);

// Generates pending, fulfilled and rejected action types
export const bestFitPotentialSavingsContent = createAsyncThunk(
  "bestFit/bestFitPotentialSavingsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.BestFit.BestFitSavings.template}${StrapiApiEndPoints.bestFitPotentialSavings}`,
      isStrapiApi: true,
    });
  }
);

// Generates pending, fulfilled and rejected action types
export const bfTpContent = createAsyncThunk(
  "bestFit/bfTpContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.BestFit.TrustPilot.template}${StrapiApiEndPoints.tpBestFit}`,
      isStrapiApi: true,
    });
  }
);

const strapiBestFitSlice = createSlice({
    name: "bestFit",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
      builder.addCase(
        bestFitFeatureContent.fulfilled,
        (state: InitialState, action: PayloadAction<any>) => {
          state.bestFitFeatures = action.payload?.data?.data?.attributes;
          state.errorBestFitFeatures = null;
        }
      );
      builder.addCase(bestFitFeatureContent.rejected, (state: InitialState, action: any) => {
        state.bestFitFeatures = null;
        state.errorBestFitFeatures = action.error || "Something went wrong";
      });
      builder.addCase(
        bestFitSavingsContent.fulfilled,
        (state: InitialState, action: PayloadAction<any>) => {
          state.bestFitSavings =  action.payload?.data?.data?.attributes;
          state.errorBestFitSavings = null;
        }
      );
      builder.addCase(bestFitSavingsContent.rejected, (state: InitialState, action:  any) => {
        state.bestFitSavings = null;
        state.errorBestFitSavings = action.error || "Something went wrong";
      });
      builder.addCase(
        bestFitPotentialSavingsContent.fulfilled,
        (state: InitialState, action: PayloadAction<any>) => {
          state.bestFitPSavingsContent = action.payload?.data?.data?.attributes;
          state.errorBestFitPSavingsContent = null;
        }
      );
      builder.addCase(bestFitPotentialSavingsContent.rejected, (state: InitialState, action:  any) => {
        state.bestFitPSavingsContent = null;
        state.errorBestFitPSavingsContent = action.error || "Something went wrong";
      });
      builder.addCase(
        bfTpContent.fulfilled,
        (state: InitialState, action: PayloadAction<any>) => {
          state.bestFitTpContent = action.payload?.data?.data?.attributes;
          state.errorBestFitTpContent = null;
        }
      );
      builder.addCase(bfTpContent.rejected, (state: InitialState, action:  any) => {
        state.bestFitTpContent = null;
        state.errorBestFitTpContent = action.error || "Something went wrong";
      });
    },
});
  
export default strapiBestFitSlice.reducer;
