import { HmtpProps } from "./HomeTP";
import styles from "./S1_HomeTp.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { TrustPilot, TrustPilotType } from "../../common";
const HmtpComponent = (props: HmtpProps) => {
  const { heading } = props?.content || {};
  return (
    <Grid
      container
      className={styles.hometp}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        xs={12}
        className={styles.trsut_pilot_content}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: "50px",
          textAlign: "center",
        }}
      >
        <Box>
          <Typography
            variant="body1"
            className={styles.trsut_pilot_content_title}
          >
            {heading || ""}
          </Typography>
        </Box>
        <Box
          sx={{ m: "20px 0 40px" }}
          className={styles.trsut_pilot_line}
        ></Box>
      </Grid>
      <Grid item xs={12}>
        <TrustPilot type={TrustPilotType.LIST} />;
      </Grid>
    </Grid>
  );
};
export default HmtpComponent;
