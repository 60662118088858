import { Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  enablePayFormFn,
  showPayFormFn,
  showSaveCardAlertDialog,
} from "../../../../features/activation/activationSlice";
import { getAutoPayCContent } from "../../../../features/strapi/checkoutSlice";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
import AutoPayCard from "../../../checkout/AutoPayCard/AutoPayCard";
import CardSavingAlertDialog from "../../../checkout/CardSavingAlertDialog/CardSavingAlertDialog";
import PaymentForm from "./PaymentForm";
import EnvConfig from "../../../../config/env.config.json";

type Props = {
  styles: any;
  currentLine: any;
  setPayBtnLoader: any;
  content: any;
};

function AutoPaySaveCard(props: Props) {
  const { styles, currentLine, setPayBtnLoader, content } = props;
  const { info_txt2, note_txt } = content?.pay_now || {};
  const { note_txt: note_txt_st, note_pt_txt } = styles || {};
  const [isAutoPay, setIsAutoPay] = useState(true);
  const [isSaveCard, setIsSaveCard] = useState(true);
  const [autoPayCacheContent, setAutoPayCacheContent] = useState();
  const dispatch = useAppDispatch();

  const { autoPayContent } = useAppSelector(
    (state: any) => state.strapi.checkout
  );
  const { displaySaveCardAlertDialog } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const autoPayContentState = autoPayCacheContent
    ? autoPayCacheContent
    : autoPayContent;
  useEffect(() => {
    if (EnvConfig?.IS_AUTOPAY_ENABLED) {
      getCache(
        "autoPayContentCheckout",
        getAutoPayCContent,
        setAutoPayCacheContent,
        dispatch
      );
    }
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("autoPayContentCheckout", autoPayContent);
  }, [autoPayContent]);
  useEffect(() => {
    let fg: boolean = false;
    if (!isAutoPay && !isSaveCard) {
      fg = true;
    } else {
      fg = false;
    }
    dispatch(enablePayFormFn(fg));
  }, [isAutoPay, isSaveCard]);
  const onChangeAutoPayment = (e: any) => {
    setIsAutoPay(e.target.checked);
    if (e.target.checked) {
      setIsSaveCard(true);
    }
  };
  const onChangeSaveCard = (e: any) => {
    if (!isAutoPay) {
      setIsSaveCard(e.target.checked);
    }
  };

  const cardSaveCheck = (fg: boolean) => {
    dispatch(showSaveCardAlertDialog(fg));
  };
  const closeAlertSaveCardDialog = () => {
    cardSaveCheck(false);
  };
  const onRequestConfirmPayment = () => {
    cardSaveCheck(false);
    setPayBtnLoader(true);
    dispatch(showPayFormFn(true));
  };
  return (
    <>
      <Box
        className={styles.row}
        display="flex"
        flexDirection="column"
        p="15px"
        m="10px"
        sx={{
          width: {
            xs: "80%",
            sm: "75%",
            md: "60%",
            lg: "45%",
          },
        }}
      >
        <AutoPayCard
          isAutoPay={isAutoPay}
          isSaveCard={isSaveCard}
          onChangeAutoPayment={onChangeAutoPayment}
          onChangeSaveCard={onChangeSaveCard}
          autoPayContentState={autoPayContentState}
        />
      </Box>
      <CardSavingAlertDialog
        display={displaySaveCardAlertDialog}
        onClose={closeAlertSaveCardDialog}
        content={autoPayContentState}
        onConfirmProceed={onRequestConfirmPayment}
        isAutoPayAndSaveCardEnabled={isAutoPay && isSaveCard}
      />
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          width: {
            xs: "86%",
            sm: "79%",
            md: "64%",
            lg: "46%",
          },
        }}
        alignItems="left"
      >
        <Typography className={note_txt_st}>{note_txt}</Typography>
        <Typography className={note_pt_txt}>{info_txt2}</Typography>
      </Box>
      <PaymentForm
        currentLine={currentLine}
        isAutoPay={isAutoPay}
        isSaveCard={isSaveCard}
      />
    </>
  );
}

export default AutoPaySaveCard;
