import { Box } from "@mui/material";
import classes from "./IRILDServices.module.scss";
import { ButtonCust, Divider } from "../widgets";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { RoutingURLs } from "../../config/RoutingURLs";
import { brandNames, IRILDEnums } from "../../enums";
import EnvConfig from "../../config/env.config.json";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
interface IRILDServicesProps {
  onClickButton: any;
  activeButton: string;
  ISContent: any;
  romingCredits?: any;
  callingCredits?: any;
}
export const IRILDServices = ({
  onClickButton,
  activeButton,
  ISContent,
  romingCredits,
  callingCredits,
}: IRILDServicesProps) => {
  const [servicesRoute, setServicesRoute] = useState<boolean>(false);
  const [isUserActivated, setIsUserActivated] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    let customerStoreData: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    let userActivated = customerStoreData?.activationStatus === 2;
    setIsUserActivated(userActivated);
    if (location?.pathname === RoutingURLs?.services && userActivated) {
      setServicesRoute(true);
    } else {
      setServicesRoute(false);
    }
  }, []);
  const roamingContent =
    activeButton === IRILDEnums.travel
      ? ISContent?.r_type1
      : ISContent?.r_type2;
  const getSecTxtStyle = () => {
    switch (EnvConfig.DOMAIN) {
      case brandNames.GHOST:
        return classes.roamingContent;
      default:
        return classes.description_secondary_txt_int;
    }
  };
  return (
    <div>
      <Box
        className={
          servicesRoute
            ? classes.post_service_btn_container
            : classes.btn_container
        }
      >
        <Box
          onClick={() =>
            onClickButton(servicesRoute ? IRILDEnums.call : IRILDEnums.travel)
          }
          className={
            (
              servicesRoute
                ? activeButton === IRILDEnums.call
                : activeButton === IRILDEnums.travel
            )
              ? `${classes.active_btn} ${classes.left}`
              : classes.inactive_btn
          }
        >
          {servicesRoute ? ISContent?.btn2_text : ISContent?.btn1_text}
        </Box>
        <Box
          onClick={() =>
            onClickButton(servicesRoute ? IRILDEnums.travel : IRILDEnums.call)
          }
          className={
            (
              servicesRoute
                ? activeButton === IRILDEnums.travel
                : activeButton === IRILDEnums.call
            )
              ? `${classes.active_btn} ${classes.right}`
              : `${classes.inactive_btn}`
          }
        >
          {servicesRoute ? ISContent?.btn1_text : ISContent?.btn2_text}
        </Box>
      </Box>
      <Box
        m="auto"
        display="flex"
        justifyContent="center"
        flexDirection={"column"}
      >
        <Box
          className={classes.sub_heading}
          fontSize="18px"
          textAlign="center"
          mt={3}
        >
          {roamingContent?.description}
        </Box>
        <Divider styles={{ margin: "10px auto 20px" }} />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {roamingContent?.roaming_steps?.map((step: any) => (
            <Box
              display="flex"
              justifyContent="center"
              key={step.id}
              width={"fit-content"}
            >
              <Box className={classes.oval}></Box>
              <Box
                key={step.id}
                lineHeight={2.14}
                className={getSecTxtStyle()}
                width={"390px"}
                maxWidth={"calc(100vw - 30px)"}
                dangerouslySetInnerHTML={{
                  __html: BrandNameUpdate(step.item)
                    ?.replace("{_roamingCredits}", romingCredits)
                    .replace("{_callingCredits}", callingCredits),
                }}
              ></Box>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ...(location?.pathname === RoutingURLs.internationalServices && {
                flexDirection: "column-reverse !important",
              }),
            }}
          >
            {roamingContent?.note && (
              <Box
                className={classes.note_text}
                dangerouslySetInnerHTML={{
                  __html: roamingContent.note,
                }}
                style={{paddingBottom : location?.pathname === RoutingURLs?.services && !isUserActivated ? "20px" : "0px"}}
              ></Box>
            )}
            {isUserActivated && EnvConfig.DISPLAY_WALLET_BUTTON && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  ...(servicesRoute && {
                    padding: "16px",
                  }),
                }}
              >
                <ButtonCust
                  sx={{ my: 1, padding: "24px 52px" }}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    navigate(RoutingURLs.wallet);
                  }}
                >
                  {ISContent?.wall_btn}
                </ButtonCust>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
