import React, { useEffect, useState } from "react";
import Config from "../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  isTemplateExisits,
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../utils/commonFunctions";
import { helpIframeContent } from "../../features/strapi/helpSlice";

const HelpComponent = isTemplateExisits(Config.Help.Help)
  ? require(`./${Config.Help.Help.template}_Help`).default
  : null;

export const HelpComp = () => {
  const [cacheData, setCacheData] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { helpIframeData } = useAppSelector(
    (state: any) => state?.strapi?.help || {}
  );
  useEffect(() => {
    getCache("HelpIframe", helpIframeContent, setCacheData, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("HelpIframe", helpIframeData);
  }, [helpIframeData]);

  return (
    (helpIframeData || cacheData) && (
      <HelpComponent strapiContent={cacheData ? cacheData : helpIframeData} />
    )
  );
};
