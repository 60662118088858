import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  landingPlanContent: any;
  errorLandingPlanContent: any;
  ulPromoTermsContent: any;
  errorUlPromoTermsContent: any;
};

const initialState: InitialState = {
  landingPlanContent: null,
  errorLandingPlanContent: null,
  ulPromoTermsContent: null,
  errorUlPromoTermsContent: null,
};

// Generates pending, fulfilled and rejected action types
export const PlanSecContent = createAsyncThunk(
  "landingPage/PlanSecContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.landingPagePlanSec}`,
      isStrapiApi: true,
    });
  }
);

export const ULPromoTermsContent = createAsyncThunk(
  "landingPage/ULPromoTermsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.ULPromo.ULTerms.template}${StrapiApiEndPoints.ulPromoTerms}`,
      isStrapiApi: true,
    });
  }
);

const landingPageSlice = createSlice({
  name: "landingPage",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      PlanSecContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.landingPlanContent = action.payload?.data?.data?.attributes;
        state.errorLandingPlanContent = null;
      }
    );
    builder.addCase(
      PlanSecContent.rejected,
      (state: InitialState, action: any) => {
        state.landingPlanContent = null;
        state.errorLandingPlanContent =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(
      ULPromoTermsContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.ulPromoTermsContent = action.payload?.data?.data?.attributes;
        state.errorUlPromoTermsContent = null;
      }
    );
    builder.addCase(
      ULPromoTermsContent.rejected,
      (state: InitialState, action: any) => {
        state.ulPromoTermsContent = null;
        state.errorUlPromoTermsContent = action.error || "Something went wrong";
      }
    );
  },
});

export default landingPageSlice.reducer;
